import {
  API_SUCCESS,
  API_FAIL,
  GET_LAUNDRY_LIST,
  GET_LAUNDRY_DETAILS,
  TOGGLE_LAUNDRY_ACTIVE,
  TOGGLE_LAUNDRY_PERM,
  DELETE_LAUNDRY_SUCCESS,
  DELETE_LAUNDRY_FAIL
} from "./actionTypes";
import { nullOrEmpty } from "../../helpers/common_helper";


const INIT_STATE = {
  LaundryList: [],
  LaundryResponse: {},
  LaundryDetails: {}
};

const Laundry = (state = INIT_STATE, action) => {
  switch (action.type) {
    case TOGGLE_LAUNDRY_ACTIVE:
      let _LaundryDetails = action.payload;
      _LaundryDetails.active = !_LaundryDetails.active;
      return {
        ...state,
        LaundryDetails: _LaundryDetails
      };

    case TOGGLE_LAUNDRY_PERM:
      let _LaundryDetailsPerm = action.payload.Laundry;
      let _perm = action.payload.perm;
      let _action = action.payload.action;

      if (nullOrEmpty(_LaundryDetailsPerm.permissions)) {
        _LaundryDetailsPerm.permissions = {};
      }

      if (nullOrEmpty(_LaundryDetailsPerm.permissions[_perm])) {
        _LaundryDetailsPerm.permissions[_perm] = {
          create: false,
          search: false,
          update: false
        };
      }
      if (nullOrEmpty(_LaundryDetailsPerm.permissions[_perm][_action])) {
        _LaundryDetailsPerm.permissions[_perm][_action] = true;
      }
      else {
        _LaundryDetailsPerm.permissions[_perm][_action] = !_LaundryDetailsPerm.permissions[_perm][_action];
      }
      return {
        ...state,
        LaundryDetails: _LaundryDetailsPerm
      };
    case API_SUCCESS:
      switch (action.payload.actionType) {
        case GET_LAUNDRY_LIST:
          return {
            ...state,
            LaundryList: action.payload.data
          };

        case GET_LAUNDRY_DETAILS:
          return {
            ...state,
            LaundryDetails: action.payload.data
          };

        default:
          return state;
      }
    case API_FAIL:
      switch (action.payload.actionType) {
        case GET_LAUNDRY_LIST:
          return {
            ...state,
            chartsDataError: action.payload.error
          };

        case GET_LAUNDRY_DETAILS:
          return {
            ...state,
            LaundryResponse: action.payload.error,
          };

        default:
          return state;
      }
    case DELETE_LAUNDRY_SUCCESS:
      return {
        ...state,
        LaundryList: state.LaundryList.filter((item) => item?._id !== action.payload._id),
      }

    // case DELETE_LAUNDRY_FAIL:
    //   return {
    //     ...state,
    //     error: action.payload,
    // }
    default:
      return state;
  }
}


export default Laundry;