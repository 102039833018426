import React from "react"
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap"
import PropTypes from "prop-types"

const ActionModal = props => {
  const onAction = () => {
    props.onAction()
    props.toggle()
  }

  return (
    <Modal isOpen={props.isOpen} toggle={props.toggle} {...props.args}>
      <ModalHeader toggle={props.toggle}>{props.title}</ModalHeader>
      <ModalBody>{props.body}</ModalBody>
      <ModalFooter>
        <Button color="primary" onClick={onAction}>
          {props.actionButtonLabel}
        </Button>
        <Button color="secondary" onClick={props.toggle}>
          Cancel
        </Button>
      </ModalFooter>
    </Modal>
  )
}

ActionModal.propTypes = {
  actionButtonLabel: PropTypes.string.isRequired,
  body: PropTypes.string.isRequired,
  isOpen: PropTypes.bool.isRequired,
  onAction: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  toggle: PropTypes.func.isRequired,
  args: PropTypes.object,
}

export default ActionModal
