import React, { useState } from "react"
import { Row, Col, Card, CardBody, Button, CardText, Input } from "reactstrap"
import MetaTags from 'react-meta-tags'

import { Search } from 'react-bootstrap-table2-toolkit';
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"
import "../../assets/scss/datatables.scss"
import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux"
import {getCustomerDetails, searchUsers } from "../../store/actions"
import 'react-loading-skeleton/dist/skeleton.css'
import Pages404 from "pages/Utility/pages-404";
import toastr from "toastr";
import "toastr/build/toastr.min.css";
import moment from "moment";


const FreshDeskAnalytics = () => {
  const dispatch = useDispatch()
  const { customers, users, customerDetails } = useSelector(state => ({
    customerDetails: state.Customers.customerDetails,
    customers: state.Customers.customers,
    users : state.Users.users
  }))

  const [customerData, setCustomerData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [userData, setUserData] = useState({});
  const [mobile, setMobile] = useState("");

  const phCode = process.env.REACT_APP_PHONE_PREFIX || "+966";
  console.log("Customer Data:: ", customerDetails)

  useEffect(() => {
    dispatch(searchUsers({webPermissions: true}))
  }, [])

  useEffect(() => {
    if(users.length> 0){
      setUserData(users[0]);
    }
  }, [users])

  // save customer details in customerData
  useEffect(() => {
    const _customerDetails = customerDetails;
    if (_customerDetails && _customerDetails?.statusCode == 200) {
      setCustomerData(_customerDetails)
    } else if (customerData != null && _customerDetails?.statusCode == 400) {
      setCustomerData(null);
    }
    setIsLoading(false);
  }, [customerData, customerDetails])

  useEffect(() => {
    const _customers = customers;

    if (_customers?.length) {
      setCustomerData(_customers)
    }
    setIsLoading(false);
  }, [customers])


 const handleSearch = async () => {
    if (mobile) {
      setIsLoading(true);
      dispatch(getCustomerDetails({mobile: `${phCode}${mobile}`}))
      setIsLoading(false);
    } else {
      toastr.error("Please enter a valid customer mobile number");
    }
  }

  const handleChange = (e) => {
    let value = e.target.value;
    // Remove any non-digit characters
    value = value.replace(/[^\d]/g, '');

    if (value.length <= 10) {
      setMobile(value);
    }
  };

  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      handleSearch();
    }
  };

  function timeAgo(date) {
    // Check if date matches the MongoDB ObjectId format
    if (typeof date === 'string' && date.match(/^[0-9a-fA-F]{24}$/)) {
      return "N/A"; // Return "N/A" for MongoDB ObjectId-like strings
    }
  
    // Handle other cases where date is "N/A", undefined, null, or falsy
    if (date == "N/A" || !date || date == undefined || date == null) {
      return "N/A";
    }
    
    const seconds = Math.floor((new Date() - new Date(date)) / 1000);
    let interval = Math.floor(seconds / 31536000);
  
    if (interval > 1) {
      return interval + " years ago";
    }
    interval = Math.floor(seconds / 2592000);
    if (interval > 1) {
      return interval + " months ago";
    }
    interval = Math.floor(seconds / 86400);
    if (interval > 1) {
      return interval + " days ago";
    }
    interval = Math.floor(seconds / 3600);
    if (interval > 1) {
      return interval + " hours ago";
    }
    interval = Math.floor(seconds / 60);
    if (interval > 1) {
      return interval + " minutes ago";
    }
    return "just now";
  }

  const { SearchBar } = Search;
  if(userData?.permissions?.allPermissions==true || userData?.permissions?.orders?.read==true){
  return (
    <React.Fragment>
  <div className="page-content">
    <MetaTags>
      <title>Kleen | Customer Details</title>
    </MetaTags>
    <div className="container-fluid">
      <Breadcrumbs title="Support Tools" breadcrumbItem="Freshdesk Customer Analytics" />

      <Row>
        <Col className="col-12">
          <Card>
            <CardBody>
              <Row className="mb-3">
                <Col sm="6">
                  <div className="me-2 mb-2 d-inline-block">
                    <div className="position-relative d-flex gap-1 justify-content-evenly align-items-center">
                      <CardText className="mb-2 pt-2 fs-5 fw-bold">Customer Mobile: +{phCode}</CardText>
                      <div className="ms-2" style={{ width: "10rem" }}>
                        {/* Input to search customer by Mobile */}
                        <div className="input-group">
                          <Input
                            type="text"
                            placeholder="Search Customer"
                            className="form-control"
                            value={mobile}
                            onChange={handleChange}
                            onKeyPress={handleKeyPress} // Call handleSearch on Enter key press
                          />
                        </div>
                      </div>

                      <Button
                        className="ms-2"
                        color="primary"
                        onClick={handleSearch}
                      >
                        Search
                      </Button>
                    </div>
                  </div>
                </Col>
              </Row>

              {customerData && (
                <div className="customer-details">
                  <h4 className="mb-4">Customer Details</h4>

                  <div className="row">
                    <div className="col-md-6">
                      {/* category */}
                      <div className="detail mb-3">
                        <span className="label fw-bold" style={{fontSize: "0.9rem"}}>Customer ID: </span>
                        <span className="value text-capitalize" style={{ fontSize: '0.9rem', fontWeight: 'bold' }}>
                        {customerData?.customerID ? customerData?.customerID : "N/A"}
                        </span>
                      </div>
                      <div className="detail mb-3">
                        <span className="label fw-bold" style={{fontSize: "0.9rem"}}>Bag No.: </span>
                        <span className="value text-capitalize" style={{ fontSize: '0.9rem', fontWeight: 'bold' }}>
                          {customerData?.bagNo ? customerData?.bagNo : "N/A"}
                        </span>
                      </div>
                      <div className="detail mb-3">
                        <span className="label fw-bold" style={{fontSize: "0.9rem"}}>Category: </span>
                        <span className="value text-capitalize" style={{ fontSize: '0.9rem', fontWeight: 'bold' }}>
                          {customerData?.category? customerData?.category : "N/A"}
                        </span>
                      </div>
                      <div className="detail mb-3">
                        <span className="label fw-bold" style={{fontSize: "0.8rem"}}>Total No. Of Refunds: </span>
                        <span className="value" style={{ fontSize: '0.9rem', fontWeight: 'bold' }}>
                          {customerData?.refundCount ? customerData?.refundCount : 0}
                        </span>
                      </div>
                      <div className="detail mb-3">
                        <span className="label fw-bold" style={{fontSize: "0.8rem"}}>Total Refunded Amount: </span>
                        <span className="value" style={{ fontSize: '0.9rem', fontWeight: 'bold' }}>
                          {customerData?.refundsTotalAmount ? `${customerData?.refundsTotalAmount} SAR` : "0 SAR"}
                        </span>
                      </div>
                      <div className="detail mb-3">
                        <span className="label fw-bold" style={{fontSize: "0.8rem"}}>Total Order Count: </span>
                        <span className="value" style={{ fontSize: '0.9rem', fontWeight: 'bold' }}>
                          {customerData?.orderCount}
                        </span>
                      </div>
                      <div className="detail mb-3">
                        <span className="label fw-bold" style={{fontSize: "0.8rem"}}>Avg. Orders Per Week: </span>
                        <span className="value" style={{ fontSize: '0.9rem', fontWeight: 'bold' }}>
                          {customerData?.averageOrdersPerWeek}
                        </span>
                      </div>
                      <div className="detail mb-3">
                        <span className="label fw-bold" style={{fontSize: "0.8rem"}}>Avg. Basket Size: </span>
                        <span className="value" style={{ fontSize: '0.9rem', fontWeight: 'bold' }}>
                          {customerData?.averageBasketSize}
                        </span>
                      </div>
                      <div className="detail mb-3">
                        <span className="label fw-bold" style={{fontSize: "0.8rem"}}>Total Online Order Amount In SAR: </span>
                        <span className="value" style={{ fontSize: '0.9rem', fontWeight: 'bold' }}>
                          {customerData?.onlineOrdersTotalAmount}
                        </span>
                      </div>
                      <div className="detail mb-3">
                        <span className="label fw-bold" style={{fontSize: "0.8rem"}}>Count Of Online Orders: </span>
                        <span className="value" style={{ fontSize: '0.9rem', fontWeight: 'bold' }}>
                          {customerData?.onlineOrdersCount}
                        </span>
                      </div>
                      <div className="detail mb-3">
                        <span className="label fw-bold" style={{fontSize: "0.8rem"}}>Total Offline Order Amount In SAR: </span>
                        <span className="value" style={{ fontSize: '0.9rem', fontWeight: 'bold' }}>
                          {customerData?.offlineOrdersTotalAmount}
                        </span>
                      </div>
                      <div className="detail mb-3">
                        <span className="label fw-bold" style={{fontSize: "0.8rem"}}>Count Of Offline orders: </span>
                        <span className="value" style={{ fontSize: '0.9rem', fontWeight: 'bold' }}>
                          {customerData?.offlineOrdersCount}
                        </span>
                      </div>
                      <div className="detail mb-3">
                        <span className="label fw-bold" style={{fontSize: "0.8rem"}}>Current Order ID: </span>
                        <span className="value" style={{ fontSize: '0.9rem', fontWeight: 'bold' }}>
                          {customerData?.currentOrderId}
                        </span>
                      </div>
                    </div>

                    <div className="col-md-6">
                      <div className="detail mb-3">
                        <span className="label fw-bold" style={{fontSize: "0.8rem"}}>Current Order Landry: </span>
                        <span className="value" style={{ fontSize: '0.9rem', fontWeight: 'bold' }}>
                          {customerData?.currentOrderLaunry}
                        </span>
                      </div>
                      <div className="detail mb-3">
                        <span className="label fw-bold" style={{fontSize: "0.8rem"}}>Current Driver&apos;s For Current Order: </span>
                        <br />
                        <span className="value" style={{ fontSize: '0.9rem', fontWeight: 'bold' }}>
                          Pickup Driver: {customerData?.currentDrivers?.pickupDriver} <br />
                          Dropoff Driver: {customerData?.currentDrivers?.dropOffDriver}
                        </span>
                      </div>
                      <div className="detail mb-3">
                        <span className="label fw-bold" style={{fontSize: "0.8rem"}}>Registration Date: </span>
                        <span className="value" style={{ fontSize: '0.9rem', fontWeight: 'bold' }}>
                        {
                         customerData?.userRegistrationDate ? moment(customerData.userRegistrationDate).format('YYYY-MM-DD') : ''
                        }
                        </span>
                      </div>
                      <div className="detail mb-3">
                        <span className="label fw-bold" style={{fontSize: "0.8rem"}}>Total Discounts: </span>
                        <span className="value" style={{ fontSize: '0.9rem', fontWeight: 'bold' }}>
                          {customerData?.totalDiscount ? `${customerData?.totalDiscount} SAR` : "0 SAR"}
                        </span>
                      </div>
                      <div className="detail mb-3">
                        <span className="label fw-bold" style={{fontSize: "0.8rem"}}>User Area: </span>
                        <span className="value" style={{ fontSize: '0.9rem', fontWeight: 'bold' }}>
                          {customerData?.area}
                        </span> 
                      </div>
                      <div className="detail mb-3">
                        <span className="label fw-bold" style={{fontSize: "0.8rem"}}>Avg Order Rating: </span>
                        <span className="value" style={{ fontSize: '0.9rem', fontWeight: 'bold' }}>
                          {customerData?.averageRating}
                        </span>
                      </div>
                      <div className="detail mb-3">
                        <span className="label fw-bold" style={{fontSize: "0.8rem"}}>Avg Refund Per Week: </span>
                        <span className="value" style={{ fontSize: '0.9rem', fontWeight: 'bold' }}>
                          {customerData?.averageRefundPerWeek}
                        </span>
                      </div>
                      <div className="detail mb-3">
                        <span className="label fw-bold" style={{fontSize: "0.8rem"}}>User Created: </span>
                        <span className="value" style={{ fontSize: '0.9rem', fontWeight: 'bold' }}>
                          {timeAgo(customerData?.userRegistrationDate)}
                        </span>
                      </div>
                      <div className="detail mb-3">
                        <span className="label fw-bold" style={{fontSize: "0.8rem"}}>User Last Online Order: </span>
                        <span className="value" style={{ fontSize: '0.9rem', fontWeight: 'bold' }}>
                          {customerData?.lastOnlineOrderDate ? timeAgo(customerData.lastOnlineOrderDate) : 'N/A'}
                        </span>
                        {(customerData?.lastOnlineOrderDate != (null || undefined || "N/A")) && (
                          <span className="value" style={{ fontSize: '0.9rem'}}>{" "} ({customerData.lastOnlineOrderDate})</span>
                        )}
                      </div>
                      <div className="detail mb-3">
                        <span className="label fw-bold" style={{fontSize: "0.8rem"}}>User Last Offline Order:</span>
                        <span className="value" style={{ fontSize: '0.9rem', fontWeight: 'bold' }}>
                          {customerData?.lastOfflineOrderDate ? timeAgo(customerData.lastOfflineOrderDate) : 'N/A'}
                        </span>
                        {(customerData?.lastOfflineOrderDate != (null || undefined || "N/A")) && (
                          <span className="value" style={{ fontSize: '0.9rem'}}>{" "} ({customerData.lastOfflineOrderDate})</span>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </CardBody>
          </Card>
        </Col>
      </Row>
    </div>
  </div>
</React.Fragment>

  )
  }
  else{
   return <Pages404/> 
  }
}

export default FreshDeskAnalytics
