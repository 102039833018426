import {
  GET_B2B_PRODUCT_LIST,
  GET_B2B_PRODUCTS_SUCCESS,
  GET_B2B_PRODUCTS_FAIL

} from "./actionTypes"

export const getb2bProducts = (data) => ({
  type: GET_B2B_PRODUCT_LIST,
  payload: data
})

export const getb2bProductsSuccess = (data) => ({
  type: GET_B2B_PRODUCTS_SUCCESS,
  payload: data
})

export const getb2bProductsFail = (data) => ({
  type: GET_B2B_PRODUCTS_FAIL,
  payload: data
})