import {
  LOGIN_USER,
  LOGIN_SUCCESS,
  LOGOUT_USER,
  LOGOUT_USER_SUCCESS,
  API_ERROR,
  ERROR_RESPONSE
} from "./actionTypes"

const initialState = {
  error: "",
  loading: false,
  showToast:false,
  userDetails : {}
}

const login = (state = initialState, action) => {
 
  switch (action.type) {
    case LOGIN_USER:
      state = {
        ...state,
        loading: true,
        showToast:false
      }
      break
      case ERROR_RESPONSE:
      state = {
        ...state,
        loading: false,
        showToast:true,
        error:action.payload
      }
      break

    case LOGIN_SUCCESS:
      state = {
        ...state,
        loading: false,
        showToast:false,
        userDetails: {
          email:action?.payload?.record?.email,
          name:action?.payload?.record?.profile?.name,
          role:action?.payload?.record?.role,
          profile:action?.payload?.record?.profile
        }
      }
      break
      case API_ERROR:
      state = {
        ...state,
        loading: false,
        showToast:true,
        error:action.payload.message
      }
      break
    case LOGOUT_USER:
      state = { ...state }
      break
    case LOGOUT_USER_SUCCESS:
      state = { ...state }
      break
    case API_ERROR:
      state = { ...state,
         error: action.payload,
          loading: false ,
          showToast:true}
      break
    default:
      state = { ...state }
      break
  }
  return state
}

export default login
