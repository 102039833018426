import { GET_RECORD, GET_SEARCH_RECORD, GET_RECORD_FAIL, GET_RECORD_SUCCESS } from "./actionTypes"

export const getRecord = (data) => ({
  type: GET_RECORD,
  payload: data
})

export const getSearchRecord = (data) => ({
  type: GET_SEARCH_RECORD,
  payload: data
})

export const getRecordSuccess = tasks => ({
  type: GET_RECORD_SUCCESS ,
  payload: tasks,
})

export const getRecordFail = error => ({
  type: GET_RECORD_FAIL,
  payload: error,
})
