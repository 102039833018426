import { 
  API_SUCCESS,
  API_FAIL,
  LIST_ANNOUNCEMENT,
  CREATE_ANNOUNCEMENT,
  UPDATE_ANNOUNCEMENT,
} from "./actionTypes"

export const apiSuccess = (actionType, data) => ({
  type: API_SUCCESS,
  payload: { actionType, data },
});

export const apiFail = (actionType, error) => ({
  type: API_FAIL,
  payload: { actionType, error },
});

export const getAnnouncement = (data) => ({
  type: LIST_ANNOUNCEMENT,
  payload: data
})

export const createAnnouncement = (data) => ({
  type: CREATE_ANNOUNCEMENT,
  payload: data
})

export const updateAnnouncement = (data) => ({
  type: UPDATE_ANNOUNCEMENT,
  payload: data
})
