import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"
import MetaTags from "react-meta-tags";
import {
  Container,
  Row,
  Col,
  Button,
  Card,
  CardBody,
  Modal,
  Label,
} from "reactstrap"
import { AvForm, AvField, AvGroup, AvInput } from "availity-reactstrap-validation"
import { getSlider, addNewSlider, updateSlider, deleteSlider } from "../../store/actions"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { withTranslation } from "react-i18next"
import { useSelector, useDispatch } from "react-redux"
import { postArea } from "helpers/LaundryHelper"
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'

const Home = props => {
  const dispatch = useDispatch();
  const [realTimeModal, SetRealTimeModal] = useState(false);
  const [areaList, setAreaList] = useState([]);
  const [sliderName, setSliderName] = useState("");
  const [imageEn, setImageEn] = useState("");
  const [imageAr, setImageAr] = useState("");
  const [areaVal, setAreaVal] = useState([]);
  const [status, setStatus] = useState(false);
  const [SliderList, setSliderList] = useState([]);
  const [SliderListNew, setSliderListNew] = useState([]);
  const [searchArea, setSearchArea] = useState("");
  const [deleteModal, setDeleteModal] = useState(false)
  const [selectedSliderData, setSelectedSliderData] = useState({})
  const [loaderImageAr, setLoaderImageAr] = useState("")
  const [loaderImageEn, setLoaderImageEn] = useState("")

  const { chartsData, realTimeDetails, top4Data, Slider } = useSelector(state => ({
    chartsData: state.Dashboard.chartsData,
    realTimeDetails: state.Dashboard.realTimeDetails,
    top4Data: state.Dashboard.top4Data,
    Slider: state.Slider.Sliders.record
  }))


  useEffect(() => {
    setSliderList(Slider);
    setSliderListNew(Slider);
    setLoaderImageEn("")
    setLoaderImageAr("")
  }, [Slider])

  useEffect(async () => {
    const AreaResponse = await postArea({});
    setAreaList(AreaResponse?.record);
    // console.log("AreaResponse", AreaResponse);
  }, [])

  useEffect(() => {
    const data = {}
    dispatch(getSlider(data))
  }, [])

  const ArrayArea = (id) => {
    let newArr = [...areaVal];
    let indexVal = newArr.indexOf(id);
    if (indexVal !== -1) {
      newArr.splice(indexVal, 1);
    } else {
      newArr.push(id);
    }
    setAreaVal(newArr)
  }

  useEffect(() => {
    if (!realTimeModal) {
      setSelectedSliderData({})
      setAreaVal([])
      setSliderName("")
      setImageEn("")
      setImageAr("")
      setStatus(false)
    }
  }, [realTimeModal])

  const AddSlider = () => {
    SetRealTimeModal(!realTimeModal);
  }

  function toggleRTModal() {
    SetRealTimeModal(!realTimeModal);
    removeBodyCss();
  }

  function removeBodyCss() {
    document.body.classList.add("no_padding");
  }

  const handleValidSubmit = (e, v) => {
    const formData = new FormData();
    formData.append("name", sliderName);
    formData.append("imageEn", imageEn);
    formData.append("imageAr", imageAr);
    formData.append("status", status);
    areaVal.map((item, index) => {
      formData.append("areas", item);
    })
    if (selectedSliderData?._id) {
      formData.append("_id", selectedSliderData._id);
      dispatch(updateSlider(formData))
    } else {
      dispatch(addNewSlider(formData))
    }
    toggleRTModal();
  }

  const handleStatus = (e, item) => {
    const formData = new FormData();
    formData.append("_id", item?._id);
    formData.append("status", e.target.checked);
    dispatch(updateSlider(formData))
  }

  const handleImageArChange = (e, item) => {
    if (e?.target?.files[0]) {
      setLoaderImageAr(item?._id)
      setImageAr(e.target.files[0])
      const formData = new FormData();
      formData.append("_id", item?._id);
      formData.append("imageAr", e.target.files[0]);
      dispatch(updateSlider(formData))
    }
  }

  const handleImageEnChange = (e, item) => {
    if (e?.target?.files[0]) {
      setLoaderImageEn(item?._id)
      setImageEn(e.target.files[0])
      const formData = new FormData();
      formData.append("_id", item?._id);
      formData.append("imageEn", e.target.files[0]);
      dispatch(updateSlider(formData))
    }

  }

  const handleSearchArea = (e) => {
    setSearchArea(e.target.value);
    let newFilterAreaList = areaList.filter(item => (item?.name.toLowerCase()).includes(e.target.value.toLowerCase()))
    let newSliderList = SliderList.map((data) => {
      let areaListIndex = newFilterAreaList.findIndex(data1 => data.areas.includes(data1?._id))
      if (areaListIndex !== -1) {
        return data
      }
    })
    setSliderListNew(newSliderList.filter((data) => data))
  }

  const deleteSliderdata = () => {
    let apiData = {
      _id: selectedSliderData._id
    }
    dispatch(deleteSlider(apiData))
    setTimeout(() => {
      setSelectedSliderData({})
      setDeleteModal(false)
    }, 500);
  }

  const handleDeleteSlider = (item) => {
    setSelectedSliderData(item)
    setDeleteModal(true)
  }

  const handleUpdateSlider = (item) => {
    setSelectedSliderData(item);
    setAreaVal(item.areas)
    setSliderName(item.name)
    setImageEn(item.imageEn)
    setImageAr(item.imageAr)
    setStatus(item.status)
    SetRealTimeModal(true)
  }

  return (
    <React.Fragment>
      <div className="page-content" id="page-content">
        <MetaTags>
          <title>Sliders | Kleen Admin Panel</title>
        </MetaTags>
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumbs
            title={props.t("Home")}
            breadcrumbItem={props.t("Sliders")}
          />

          <Row className="mb-3">
            <Col sm="3" className="text-end-home">
              <input className="form-control" type="text" id="searchArea" placeholder="Search" aria-label="Search" value={searchArea} onChange={(e) => { handleSearchArea(e) }} />
            </Col>
            <Col sm="9" className="text-end" >
              <button type="button" onClick={AddSlider} className="btn-rounded  mb-2 me-2 btn btn-success">
                <i className="mdi mdi-plus me-1"></i>Add Slider
              </button>
            </Col>
          </Row>
          <div id="outer_row_div">
            {SliderListNew?.map((item) => {
              return (
                <div key={item?._id} className="outer_row_div" >
                  <Row>
                    <Col md="6" >
                      <Row className="mrl-10">
                        <Col md="5" className="inner_col_div upload-filess">
                          {
                            loaderImageAr == item?._id ? (
                              <Skeleton count={1} height={130} className="mt-3 mb-4" />
                            ) : (
                              <Card className="mini-stats-wid">
                                {/* <label forHtml="myfile">  +  Select a file</label> */}
                                <div className="mb-3" style={{ backgroundImage: `url(${item?.imageAr})`, width: "190px", height: "80px", backgroundPosition: "center", backgroundRepeat: "no-repeat", backgroundSize: "contain" }}>
                                  {/* <i className="display-4 text-muted bx bxs-cloud-upload" /> */}

                                </div>
                                <div className="change-position-file">
                                  <input
                                    type="file"
                                    id="myfile"
                                    name="myfile"
                                    onChange={(e) => handleImageArChange(e, item)}
                                  // value={item?.imageAr}
                                  >
                                  </input>
                                  <h4>click here to Change.</h4>
                                </div>
                              </Card>
                            )
                          }
                          <h4>Slider in Arabic</h4>
                        </Col>

                        <Col md="5" className="inner_col_div upload-filess mx-1">
                          {
                            loaderImageEn == item?._id ? (
                              <Skeleton count={1} height={130} className="mt-3 mb-4" />
                            ) : (
                              <Card className="mini-stats-wid">
                                <div className="mb-3" style={{ backgroundImage: `url(${item?.imageEn})`, width: "190px", height: "80px", backgroundPosition: "center", backgroundRepeat: "no-repeat", backgroundSize: "contain" }}>
                                  {/* <i className="display-4 text-muted bx bxs-cloud-upload" />
                              <input
                                type="file"
                                id="myfile"
                                name="myfile"

                                onChange={(e) => { handleImageEnChange(e, item) }}
                              // value={item?.imageEn}
                              /> */}
                                </div>

                                <div className="change-position-file">
                                  <input
                                    type="file"
                                    id="myfile"
                                    name="myfile"

                                    onChange={(e) => handleImageEnChange(e, item)}
                                  // value={item?.imageEn}
                                  />
                                  <h4>click here to Change.</h4>
                                </div>
                              </Card>
                            )
                          }

                          <h4>Slider in English</h4>
                        </Col>
                      </Row>
                    </Col>
                    <Col md="6">
                      <div className="text-end mt-1 me-1">
                        <button onClick={() => handleUpdateSlider(item)} className="btn btn-sm btn-warning mx-2">
                          <i className="mdi mdi-pencil font-size-14" id="edittooltip" />
                        </button>
                        <button onClick={() => handleDeleteSlider(item)} className="btn btn-sm btn-danger">
                          <i className="mdi mdi-delete font-size-14" id="deletetooltip" />
                        </button>
                      </div>
                      <h5 className="mt-3">{item?.name}</h5>
                      <h6>Areas</h6>
                      <div className="d-flex-div md-6">
                        {item?.areas?.map((item2, index) => {
                          return areaList.find(data1 => data1?._id === item2) ? (
                            <Button
                              key={index}
                              color="default"
                              className="btn inner_item_flex"
                            >
                              {
                                areaList.find(data1 => data1?._id === item2).name
                              }

                            </Button>
                          ) : null
                        })}
                      </div>
                      <div className="form-check form-switch form-switch-lg mb-3">
                        <label
                          className="form-check-label"
                          htmlFor="customSwitchsizelg"
                        >
                          Status
                        </label>
                        <input
                          type="checkbox"
                          className="form-check-input"
                          id="customSwitchsizelg"
                          defaultChecked={item?.status}
                          // checked={item?.status}
                          // value={item?.status}
                          onChange={(e) => { handleStatus(e, item) }}
                        />

                      </div>
                    </Col>
                  </Row>
                </div>
              )
            })}
          </div>

          <Modal
            size="sm"
            isOpen={deleteModal}
            toggle={() => {
              setDeleteModal(!deleteModal);
            }}
            centered
          >
            <div className="modal-header">
              <h5 className="modal-title mt-0" id="myModalLabel">
                <span >
                  Delete Slider
                </span>
              </h5>
              <button type="button"
                onClick={() => {
                  setDeleteModal(false);
                }}
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <h5>Are you sure to delete?</h5>
            </div>
            <div className="modal-footer">
              <button onClick={deleteSliderdata} className="btn btn-md btn-danger">
                Yes
              </button>
              <button onClick={() => setDeleteModal(false)} className="btn btn-md btn-secondary">
                No
              </button>
            </div>

          </Modal>

          <Modal
            size="lg"
            isOpen={realTimeModal}
            toggle={() => {
              toggleRTModal();
            }}
          >
            <div className="modal-header">
              <h5 className="modal-title mt-0" id="myModalLabel">
                <span >
                  {selectedSliderData?._id ? "Update slider" : "Add New Slider"}
                </span>
              </h5>
              <button
                type="button"
                onClick={() => {
                  SetRealTimeModal(false);
                }}
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <Row>
                <Col lg="12">
                  <Card className="mb-0">
                    <CardBody>
                      <AvForm
                        onValidSubmit={(e, v) => {
                          handleValidSubmit(e, v)
                        }}
                        encType="multipart/form-data"
                      >
                        <div className="row mb-4">
                          <Label
                            htmlFor="horizontal-firstname-Input"
                            className="col-sm-3 col-form-label"
                          >
                            Slider Name
                          </Label>
                          <Col sm={9}>
                            <AvField
                              type="text"
                              onChange={(e) => { setSliderName(e.target.value) }}
                              value={sliderName}
                              className="form-control"
                              id="horizontal-firstname-Input"
                              placeholder="Slider Name"
                              name="name"
                              errorMessage="Please enter Slider Name"
                              validate={{ required: { value: true } }}
                            />
                          </Col>
                        </div>


                        <div className="row mb-4">
                          <Label
                            htmlFor="horizontal-firstname-Input"
                            className="col-sm-3 col-form-label"
                          >
                            Image (English)
                          </Label>
                          <Col sm={9}>
                            <AvField
                              type="file"
                              onChange={(e) => { setImageEn(e.target.files[0]) }}
                              className="form-control"
                              id="horizontal-firstname-Input"
                              placeholder="Image"
                              name="imageEn"
                              errorMessage="Please Upload Image"
                              validate={{ required: { value: !selectedSliderData?._id } }}
                            />
                          </Col>
                        </div>

                        <div className="row mb-4">
                          <Label
                            htmlFor="horizontal-firstname-Input"
                            className="col-sm-3 col-form-label"
                          >
                            Image (Arabic)
                          </Label>
                          <Col sm={9}>
                            <AvField
                              type="file"
                              onChange={(e) => { setImageAr(e.target.files[0]); }}

                              className="form-control"
                              id="horizontal-firstname-Input"
                              placeholder="Image"
                              name="imageAr"
                              errorMessage="Please Upload Image"
                              validate={{ required: { value: !selectedSliderData?._id } }}
                            />
                          </Col>
                        </div>
                        <div style={{ paddingLeft: 0 }} className="row d-flex form-check form-switch form-switch-lg mb-3">
                          <label
                            htmlFor="horizontal-status-Input"
                            className="col-sm-3 col-form-label"
                          >Status</label>
                          <Col sm={9} style={{ paddingLeft: 50 }}>
                            <input
                              type="checkbox"
                              className="form-check-input"
                              id="customSwitchsizelg horizontal-status-Input"
                              defaultChecked={status}
                              onChange={(e) => { setStatus(e.target.checked) }}
                            />
                          </Col>

                        </div>

                        {/* <div className="row mb-4">
                            <Label
                              htmlFor="horizontal-firstname-Input"
                              className="col-sm-3 col-form-label"
                            >
                              Status
                            </Label>
                            <Col sm={9}>
                              <AvField
                                type="checkbox"
                                onChange={(e) => { setStatus(e.target.checked) }}
                                className="form-control"
                          id="customSwitchsizelg"
                                id="horizontal-firstname-Input"
                                placeholder="Image"
                                name="status"
                              />
                            </Col>
                          </div> */}



                        {/* all area selected  */}
                        <div className="row mb-4">
                          <Label
                            htmlFor="horizontal-firstname-Input"
                            className="col-sm-3 col-form-label"
                          >
                            Area
                          </Label>
                          <Col sm={9}>
                            <AvGroup check className="check-box">
                              {areaList?.map((item, index) => {
                                return (
                                  <Label key={index} className="col-md-3">
                                    <AvInput
                                      type="checkbox"
                                      name="areas"
                                      onChange={(e) => { ArrayArea(item?._id) }}
                                      defaultChecked={areaVal.includes(item?._id)}
                                    />
                                    {item?.name}
                                  </Label>
                                )
                              })}
                            </AvGroup>
                          </Col>
                        </div>


                        <div className="row justify-content-end">
                          <Col sm={12} className="col-sm-12 text-end">
                            <div>
                              <Button
                                type="submit"
                                color="primary"
                                className="w-md"

                              >
                                {selectedSliderData?._id ? "Update slider" : "Add Slider"}
                              </Button>

                              <button
                                type="button"
                                onClick={() => {
                                  toggleRTModal();
                                }}
                                className="m-1 btn btn-secondary "
                                data-dismiss="modal"
                              >
                                Close
                              </button>

                            </div>
                          </Col>
                        </div>
                      </AvForm>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </div>

          </Modal>
        </Container>
      </div>
    </React.Fragment>
  )
}

Home.propTypes = {
  t: PropTypes.any,
  chartsData: PropTypes.any,
  //onGetChartsData: PropTypes.func,
}

export default withTranslation()(Home)
