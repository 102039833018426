import { call, put, takeEvery, all, fork } from "redux-saga/effects";

// Crypto Redux States
import { GET_AREA_DETAILS, GET_AREA_LIST, TOGGLE_AREA_ACTIVE} from "./actionTypes";
import { apiSuccess, apiFail } from "./actions";

//Include Both Helper File with needed methods
import {fetchAreaList, getArea} from "../../helpers/areaHelper";

function* getAreas({ }) {
    try {
        const response = yield call(fetchAreaList);
        yield put(apiSuccess(GET_AREA_LIST, response.record));
    } catch (error) {
        yield put(apiFail(GET_AREA_LIST, error));
    }
}

function* getAreadetails({payload: data}) {
    //let _data = {...data, active:true};
    try {
        const response = yield call(getArea, data);
        
        yield put(apiSuccess(GET_AREA_DETAILS, response.record));
    } catch (error) {
        yield put(apiFail(GET_AREA_DETAILS, error));
    }
}

function* toggeAreaActive({payload: data}) {
    console.log("Payload::", payload, data);
    try {
        yield put(TOGGLE_AREA_ACTIVE, data);
    } catch (error) {
        yield put(TOGGLE_AREA_ACTIVE, error);
    }
}





function* AreaSaga() {
    yield takeEvery(GET_AREA_LIST, getAreas)
    yield takeEvery(GET_AREA_DETAILS, getAreadetails)
    
}

export default AreaSaga;
