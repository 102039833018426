import React, { useEffect, useState, useRef } from "react";
import MetaTags from "react-meta-tags";
import PropTypes from "prop-types";
import { withRouter, Link } from "react-router-dom";
import { isEmpty } from "lodash";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
  PaginationListStandalone,
  PaginationProvider,
} from "react-bootstrap-table2-paginator";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit"
import * as moment from "moment";

import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Badge,
  UncontrolledTooltip,
  Modal,
  ModalHeader,
  ModalBody,

 
 
  CardTitle,
 
  Label,
  Input,

} from "reactstrap";

import { AvForm, AvField } from "availity-reactstrap-validation";

//redux
import { useSelector, useDispatch } from "react-redux";

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb";

import {
  getOrders as onGetOrders,

  getPricelist as onGetPricelist,
  getArealist as onGetArealist,
  getLaundrylist as onGetLaundrylist,
  addNewOrder as onAddNewOrder,
  updateOrder as onUpdateOrder,
  deleteOrder as onDeleteOrder,
} from "store/actions";

import EcommerceOrdersModal from "./EcommerceOrdersModal";

const EcommerceOrders = props => {
  const dispatch = useDispatch();

  const { pricelist,laudrylist } = useSelector(state => ({
    pricelist: state.ecommerce.pricelist,
    laudrylist: state.ecommerce.laudrylist,
  }));

  console.log("laudrylistlaudrylist",laudrylist)
  const selectRow = {
    mode: "checkbox",
  };

  const [modal, setModal] = useState(false);
  const [modal1, setModal1] = useState(false);
  const [orderList, setOrderList] = useState([]);
  const [laundrylistMain,setlaundrylistMain]=  useState([]);
  const [isEdit, setIsEdit] = useState(false);

  //pagination customization
  const pageOptions = {
    sizePerPage: 10,
    totalSize: pricelist.length, // replace later with size(orders),
    custom: true,
  };
  const { SearchBar } = Search;

  // const toggleModal = () => {
  //   setModal1(!modal1)
  // }
  const toggleViewModal = () => setModal1(!modal1);

  const EcommerceOrderColumns = toggleModal => [
    // {
    //   dataField: "_id",
    //   text: "Order ID",
    //   sort: true,
    //   // eslint-disable-next-line react/display-name
    //   formatter: (cellContent, row) => (
    //     <Link to="#" className="text-body fw-bold">
    //       {row._id}
    //     </Link>
    //   ),
    // },
    {
      dataField: "cloth.nameEn",
      text: "Cloth English Name",
      sort: true,
      formatter: (cellContent, row) => (
        <Link to="#" className="text-body fw-bold">
          {row.cloth.nameEn}
        </Link>
      ),
    },
    {
      dataField: "cloth.nameAr",
      text: "Cloth Arabic Name",
      sort: true,
    },
    {
      dataField: "cloth.type",
      text: "Cloth type",
      sort: true,
      formatter: (cellContent, row) => (
        <Link to="#" className="text-body fw-bold">
          { row.cloth.type == "pc" ? "pieces" :"meters "}
        </Link>
      ),
    },
    {
      dataField: "dPrice",
      text: "Dry Price",
      sort: true,
    },
    {
      dataField: "iPrice",
      text: "Iron Price",
      sort: true,
    },
    {
      dataField: "wPrice",
      text: "Wash Price",
      sort: true,
    },
  ];

  // useEffect(() => {
  //   if (pricelist && !pricelist.length) {
  //     dispatch(onGetPricelist());
  //   }
  // },[dispatch,pricelist]);

    useEffect(()=>{
      if (laudrylist && !laudrylist.length) {
        dispatch(onGetLaundrylist());   
         }

  
    },[dispatch,laudrylist])

  useEffect(() => {
    setOrderList(pricelist);
  }, [pricelist]);

  useEffect(() => {
    if (!isEmpty(pricelist) && !!isEdit) {
      setOrderList(pricelist);
      setIsEdit(false);
    }
  }, [pricelist]);

  const toggle = () => {
    setModal(!modal);
  };

  const toLowerCase1 = str => {
    return str.toLowerCase();
  };

  const handleOrderClick = arg => {
    const order = arg;

    setOrderList({
      id: order._id,
      orderId: order._id,
      billingName: order.status,
      orderdate: order.status,
      total: order.status,
      paymentStatus: order.status,
      paymentMethod: order.status,
      badgeclass: order.status,
    });

    setIsEdit(true);

    toggle();
  };

  var node = useRef();
  const onPaginationPageChange = page => {
    if (
      node &&
      node.current &&
      node.current.props &&
      node.current.props.pagination &&
      node.current.props.pagination.options
    ) {
      node.current.props.pagination.options.onPageChange(page);
    }
  };

  const handleDeleteOrder = order => {
    if (order._id !== undefined) {
      dispatch(onDeleteOrder(order));
      onPaginationPageChange(1);
    }
  };

  const handleValidOrderSubmit = (e, values) => {
    if (isEdit) {
      const updateOrder = {
        id: orderList.id,
        orderId: values.orderId,
        billingName: values.billingName,
        orderdate: values.orderdate,
        total: values.total,
        paymentStatus: values.paymentStatus,
        paymentMethod: values.paymentMethod,
        badgeclass: values.badgeclass,
      };

      // update order
      dispatch(onUpdateOrder(updateOrder));
    } else {
      const newOrder = {
        id: Math.floor(Math.random() * (30 - 20)) + 20,
        orderId: values["orderId"],
        billingName: values["billingName"],
        orderdate: values["orderdate"],
        total: values["total"],
        paymentStatus: values["paymentStatus"],
        paymentMethod: values["paymentMethod"],
        badgeclass: values["badgeclass"],
      };
      // save new order
      dispatch(onAddNewOrder(newOrder));
    }
    toggle();
  };

  const handleOrderClicks = () => {
    setOrderList("");
    setIsEdit(false);
    toggle();
  };

  const handleValidDate = date => {
      console.log(date)
    const date1 = moment(date).format("DD MMM Y");
    return date1;
  };
  const handleValidTime = date => {
    const date1 = moment(date).format(" h:mm:ss a");
    return date1;
  };

  function tConvert (time) {
    // Check correct time format and split into components
    time = time.toString ().match (/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [time];
  
    if (time.length > 1) { // If time format correct
      time = time.slice (1);  // Remove full string match value
      time[5] = +time[0] < 12 ? ':00 am' : ':00 pm'; // Set AM/PM
      time[0] = +time[0] % 12 || 12; // Adjust hours
    }
    return time.join (''); // return adjusted time or original string
  }

  const defaultSorted = [
    {
      dataField: "orderID",
      order: "desc",
    },
  ];
  const [selectedvalueDrop,setselectedvalueDrop] = useState(1)
const laundryFunction =()=>{
  dispatch(onGetLaundrylist());
  setselectedvalueDrop(1)
}
const areaClickSubmit =()=>{
  dispatch(onGetArealist());
  setselectedvalueDrop(2)
}

const onChangeData = (e)=>{
  var data =""
if(selectedvalueDrop ===1 ){
  data = {"laundry":e.target.value}
}else{
  data = {"area":e.target.value}
}
  dispatch(onGetPricelist(data));

}
  return (
    <React.Fragment>
      <EcommerceOrdersModal isOpen={modal1} toggle={toggleViewModal} />
      <div className="page-content">
        <MetaTags>
          <title>Orders  </title>
        </MetaTags>
        <Container fluid>
          <Breadcrumbs title="Ecommerce" breadcrumbItem="Orders" />
          <Row>
            <Col>
              <Card>
                <CardBody>
                  <CardTitle className="h4">Search Pricelist </CardTitle>
                  <Row className="mb-3">
                  <Col sm="3">
                  <div className="mt-4">
                        {/* <h5 className="font-size-14 mb-4">Form Radios</h5> */}
                        <div className="form-check mb-3">
                          <input
                            className="form-check-input"
                            type="radio"
                            name="exampleRadios"
                            id="exampleRadios1"
                            value="option1"
                            defaultChecked
                            onClick={laundryFunction}
                          />
                          <label
                            className="form-check-label"
                            htmlFor="exampleRadios1"
                          >
                            laundry 
                          </label>
                        </div>
                  
                      </div>
                    </Col>
                    <Col sm="3">
                    <div className="mt-4">
                    <div className="form-check">
                          <input
                            className="form-check-input"
                            type="radio"
                            name="exampleRadios"
                            id="exampleRadios2"
                            value="option2"
                            onClick={areaClickSubmit}
                          />
                          <label
                            className="form-check-label"
                            htmlFor="exampleRadios2"
                          >
                           area 
                          </label>
                        </div>
                        </div>
                    </Col>
                    <Col sm="6">
               
                    <div className="col-md-10">
                      <select className="form-control" style={{"margin-top":"12px"}} onChange={(e)=>onChangeData(e)}  >
                      <option  value="" > --select--</option>
                        {laudrylist ? laudrylist.map((d,i)=>{
                            var dropdownme = ""
                            if(d.nameAr !== undefined){
                              return <option key={i} value={d._id}  > {d.nameAr}</option>
                            }else{
                              return <option key={i} value={d._id}   > {d.name}</option>
                            }
                        }
                           
                        ) :""}
{/*                   
                       <option>Large select</option>
                         <option>Small select</option> */}
                      </select>
                    </div>
                 
                      </Col>
                    
                 
                  </Row>
                 
                  {/* <Row className="mb-3 col-md-3">
                  <Button
                      color="primary"
                      className="btn btn-primary "
                    >
                      Primary
                    </Button>
                    </Row> */}
                
                </CardBody>
              </Card>
            </Col>
          </Row>

          <Row>
         
            <Col xs="12">
              <Card>
                <CardBody>
                  <PaginationProvider
                    pagination={paginationFactory(pageOptions)}
                    keyField="id"
                    columns={EcommerceOrderColumns(toggle)}
                    data={pricelist ? pricelist :""}
                  >
                    {({ paginationProps, paginationTableProps }) => (
                      <ToolkitProvider
                        keyField="id"
                        data={pricelist  ? pricelist :""}
                        columns={EcommerceOrderColumns(toggle)}
                        bootstrap4
                        search
                      >
                        {toolkitProps => (
                          <React.Fragment>
                            <Row className="mb-2">
                              <Col sm="4">
                                <div className="search-box me-2 mb-2 d-inline-block">
                                  <div className="position-relative">
                                    <SearchBar {...toolkitProps.searchProps} />
                                    <i className="bx bx-search-alt search-icon" />
                                  </div>
                                </div>
                              </Col>
                              <Col sm="8">
                                {/* <div className="text-sm-end">
                                  <Button
                                    type="button"
                                    color="success"
                                    className="btn-rounded  mb-2 me-2"
                                    onClick={handleOrderClicks}
                                  >
                                    <i className="mdi mdi-plus me-1" />
                                    Add New Order
                                  </Button>
                                </div> */}
                              </Col>
                            </Row>
                            <Row>
                              <Col xl="12">
                                <div className="table-responsive">
                                  <BootstrapTable
                                    keyField="id"
                                    responsive
                                    bordered={false}
                                    striped={false}
                                    defaultSorted={defaultSorted}
                                    // selectRow={selectRow}
                                    classes={
                                      "table align-middle table-nowrap table-check"
                                    }
                                    headerWrapperClasses={"table-light"}
                                    {...toolkitProps.baseProps}
                                    {...paginationTableProps}
                                    ref={node}
                                  />
                                </div>
                                <Modal isOpen={modal} toggle={toggle}>
                                  <ModalHeader toggle={toggle} tag="h4">
                                    {!!isEdit ? "Edit Order" : "Add Order"}
                                  </ModalHeader>
                                  <ModalBody>
                                    <AvForm
                                      onValidSubmit={handleValidOrderSubmit}
                                    >
                                      <Row form>
                                        <Col className="col-12">
                                          <div className="mb-3">
                                            <AvField
                                              name="orderId"
                                              label="Order Id"
                                              type="text"
                                              errorMessage="Invalid orderId"
                                              validate={{
                                                required: { value: true },
                                              }}
                                              value={orderList.orderId || ""}
                                            />
                                          </div>
                                          <div className="mb-3">
                                            <AvField
                                              name="billingName"
                                              label="Billing Name"
                                              type="text"
                                              errorMessage="Invalid Billing Name"
                                              validate={{
                                                required: { value: true },
                                              }}
                                              value={
                                                orderList.billingName || ""
                                              }
                                            />
                                          </div>
                                          <div className="mb-3">
                                            <AvField
                                              name="orderdate"
                                              label="Date"
                                              type="date"
                                              errorMessage="Invalid Date"
                                              validate={{
                                                required: { value: true },
                                              }}
                                              value={orderList.orderdate || ""}
                                            />
                                          </div>
                                          <div className="mb-3">
                                            <AvField
                                              name="total"
                                              label="Total"
                                              type="text"
                                              errorMessage="Invalid Total"
                                              validate={{
                                                required: { value: true },
                                              }}
                                              value={orderList.total || ""}
                                            />
                                          </div>
                                          <div className="mb-3">
                                            <AvField
                                              name="paymentStatus"
                                              label="Payment Status"
                                              type="select"
                                              className="form-select"
                                              errorMessage="Invalid Payment Status"
                                              validate={{
                                                required: { value: true },
                                              }}
                                              value={
                                                orderList.paymentStatus || ""
                                              }
                                            >
                                              <option>Paid</option>
                                              <option>Chargeback</option>
                                              <option>Refund</option>
                                            </AvField>
                                          </div>
                                          <div className="mb-3">
                                            <AvField
                                              name="badgeclass"
                                              label="Badge Class"
                                              type="select"
                                              className="form-select"
                                              errorMessage="Invalid Badge Class"
                                              validate={{
                                                required: { value: true },
                                              }}
                                              value={orderList.badgeclass || ""}
                                            >
                                              <option>success</option>
                                              <option>danger</option>
                                              <option>warning</option>
                                            </AvField>
                                          </div>
                                          <div className="mb-3">
                                            <AvField
                                              name="paymentMethod"
                                              label="Payment Method"
                                              type="select"
                                              className="form-select"
                                              errorMessage="Invalid Payment Method"
                                              validate={{
                                                required: { value: true },
                                              }}
                                              value={
                                                orderList.paymentMethod || ""
                                              }
                                            >
                                              <option>Mastercard</option>
                                              <option>Visa</option>
                                              <option>Paypal</option>
                                              <option>COD</option>
                                            </AvField>
                                          </div>
                                        </Col>
                                      </Row>
                                      <Row>
                                        <Col>
                                          <div className="text-end">
                                            <button
                                              type="submit"
                                              className="btn btn-success save-user"
                                            >
                                              Save
                                            </button>
                                          </div>
                                        </Col>
                                      </Row>
                                    </AvForm>
                                  </ModalBody>
                                </Modal>
                              </Col>
                            </Row>
                            <Row className="align-items-md-center mt-30">
                              <Col className="pagination pagination-rounded justify-content-end mb-2 inner-custom-pagination">
                                <PaginationListStandalone
                                  {...paginationProps}
                                />
                              </Col>
                            </Row>
                          </React.Fragment>
                        )}
                      </ToolkitProvider>
                    )}
                  </PaginationProvider>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

EcommerceOrders.propTypes = {
  pricelist: PropTypes.array,
  orders: PropTypes.array,
  onGetOrders: PropTypes.func,
  onGetPricelist: PropTypes.func,
  onGetArealist:PropTypes.func,
  onAddNewOrder: PropTypes.func,
  onDeleteOrder: PropTypes.func,
  onUpdateOrder: PropTypes.func,
};

export default withRouter(EcommerceOrders);
