import PropTypes from "prop-types"
import React, { useEffect, useState, useRef } from "react"
import MetaTags from "react-meta-tags";
import { AvForm, AvField, AvGroup, AvInput } from "availity-reactstrap-validation"
import MapsGoogle from "pages/Maps/MapsGoogle";
import MultiSelect from "react-multiple-select-dropdown-lite";
import Pages404 from "pages/Utility/pages-404";
import DataTable from "react-data-table-component";
import {
  Container,
  Row,
  Col,
  Modal,
  ModalHeader,
  ModalBody,
  Button,
  Card,
  CardBody,
  Label,
  Form
} from "reactstrap"
import paginationFactory, {
  PaginationListStandalone,
  PaginationProvider,
} from "react-bootstrap-table2-paginator";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import BootstrapTable from "react-bootstrap-table-next";
import { postAddb2bCustomer, postUpdateb2bCustomer } from "helpers/b2bHelper"
import { searchUsers } from "../../store/actions";
import {getb2bList, getLaundries } from "../../store/actions"
import toastr from "toastr";
import "toastr/build/toastr.min.css";
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { withTranslation } from "react-i18next"
import { useSelector, useDispatch } from "react-redux"
import { useHistory } from 'react-router-dom';
import google_map_logo from '../../assets/images/google_map_logo.png'
import Switch from "react-switch"
import ReactSwitch from "react-switch"
import Select from "react-select"
import { Offsymbol, OnSymbol } from "components/Common/Symbol"
import { postArea } from "helpers/LaundryHelper"

const b2bCustomer = props => {
  const [LaundryModal, SetLaundryModal] = useState(false)
  const [permModal, SetPermModal] = useState(false)
  const [b2bCustomerList, Setb2bCustomerList] = useState([]);
  const [userData, setUserData] = useState({});
  const [isEdit, setIsEdit] = useState(false);
  const [modal, setModal] = useState(false);
  const [b2bNameEn, setb2bNameEn] = useState('');
  const [b2bNameAr, setb2bNameAr] = useState('');
  const [b2bLaundry, setb2bLaundry] = useState('');
  const [statusVal, setStatusVal] = useState(false);
  const [b2bID, setb2bID] = useState("");
  const [searchValue, setSearchValue] = useState("");
  
  

  const { b2blist,laundryList, users } = useSelector(state => ({
    b2blist: state.b2bCustomers.b2blist,
    laundryList: state.Laundries.laundryList,
    users: state.Users.users
  }))

  const [fetchStatusTrue, setFetchStatusTrue] = useState(true)
  const [fetchStatusFalse, setFetchStatusFalse] = useState(true)

  const filterItem = (item) => {
    if(fetchStatusTrue && fetchStatusFalse){
      return true
    } else if(fetchStatusTrue && !fetchStatusFalse){
      return item.status
    } else if(fetchStatusFalse && !fetchStatusTrue){
      return !item.status
    } else {
      return false
    }
  }

  const [selectedLaundry, setSelectedLaundry] = React.useState()

  useEffect(() => {
   if(!users) dispatch(searchUsers({ webPermissions: true }))
    dispatch(getb2bList({web: true}))
    dispatch(getLaundries({status: true}));
  }, [])

  useEffect(() => {
    if(searchValue == ""){
      Setb2bCustomerList(b2blist);
    }
    else{
      handleSearch(searchValue);
    }
    
  }, [b2blist])

  useEffect(() => {
    if (users.length > 0) {
      setUserData(users[0]);
    }
  }, [users])


  const handleSearch = (val) => {
    val = val?.toLowerCase()
    let filterBySearch = b2blist.filter((item) => item?.nameEn?.toLowerCase()?.includes(val) || item?.nameAr?.toLowerCase()?.includes(val));
    if (!val) {
      Setb2bCustomerList(b2blist)
    } else {
      setSearchValue(val);
      Setb2bCustomerList(filterBySearch)
    }
    // console.log("filterBySearch", filterBySearch);
  }


  
  
  var node = useRef();
  const keyField = "id";
  const { SearchBar } = Search;
  const sizePerPage = 10;


  let b2bCustomerColumns = [
    {
      name: "Name (English)",
      sortable: true,
      selector: row => row.nameEn,
      wrap: true,
    }, 
    {
      name: "Name (Arabic)",
      sortable: true,
      selector: row => row.nameAr,
      wrap: true,
    },
    {
      name: "Laundry",
      sortable: true,
      selector: row => row.laundry.shortName?row.laundry.shortName:row.laundry.nameEn,
      center: true,
      wrap: true,
    },
    {
      name: "Status",
      sortable: true,
      selector: row => row.status?"ON":"OFF",
      wrap: true,
    }
  ];

  if ( userData?.permissions?.allPermissions == true || userData?.permissions?.b2bCustomer?.update == true) {
    b2bCustomerColumns.push(
      {
        name: "View",
        center: true,
        selector: row => {
          return <>
            <button onClick={() => UPDATE_B2B(row)} className="btn btn-primary btn-sm" >
              View
            </button>
            {/* <a onClick={() => showEditLaundry(row._id)} >Edit</a>
             &nbsp; &nbsp;
             <a onClick={() => handleDeleteLaundry(row._id)} >Delete</a> */}
            {/* <a href="#" onClick={() => showPerms(row._id)} >Permissions</a> */}
          </>
        }
      });
  }

  function toggleLaundryModal() {
    SetLaundryModal(!LaundryModal);
    removeBodyCss();
  }

  function togglePermModal() {
    SetPermModal(!permModal);
    removeBodyCss();
  }

  function removeBodyCss() {
    document.body.classList.add("no_padding");
  }

  toastr.options = {
    positionClass: "toast-top-right",
    timeOut: 3000,
    extendedTimeOut: 1000,
    closeButton: true,
    debug: true,
    progressBar: true,
    preventDuplicates: true,
    newestOnTop: true,
    showEasing: "swing",
    hideEasing: "linear",
    showMethod: "fadeIn",
    hideMethod: "fadeOut",
    showDuration: 300,
    hideDuration: 1000
  };
 

  const dispatch = useDispatch();

  const toggle = () => {
    setModal(!modal);
  };

  const showAddb2b = () =>{
    setb2bNameEn("");
    setb2bNameAr("");
    setb2bLaundry("");
    setb2bID("");
    setStatusVal(true);
    setIsEdit(false);

    toggle();
  }
  const submitNewb2b = async (event, values) => {
    values.status = statusVal;
    let response;
    
    if(isEdit){
      values._id = b2bID;
      response = await postUpdateb2bCustomer(values);
    }
    else{
      response = await postAddb2bCustomer(values);
    }
    
    if(response.statusCode == 400){
      toastr.warning(response.msg, "Unable to "+ (isEdit?"update":"add") +" b2bCustomer");
    }
    else if(response.statusCode == 200){
      toggle();
      dispatch(getb2bList({web: true}));
      toastr.success("b2bCustomer has been " + (isEdit?"updated":"added"), "Laundry " + (isEdit?"updated":"added"));
    }
    else if(laundryResponse.statusCode == 200){
      toastr.error("Laundry is not " + (isEdit?"updated":"added"), "Error Occured");
    }

  }

  const UPDATE_B2B = (row) => {
    setb2bNameEn(row.nameEn);
    setb2bNameAr(row.nameAr);
    setb2bLaundry(row.laundry._id);
    setStatusVal(row.status);
    setb2bID(row._id);
    setIsEdit(true);
    toggle();
  }
  
  if (userData?.permissions?.allPermissions == true || userData?.permissions?.b2bCustomer?.read == true) {
    return (
      <React.Fragment>
        <div className="page-content">
          <MetaTags>
            <title>LAUNDRY | Kleen Admin Panel</title>
          </MetaTags>
          <Container fluid>
            {/* Render Breadcrumb */}
            <Breadcrumbs
              title={props.t("Home")}
              breadcrumbItem={props.t("b2bCustomer")}
            />
            <Card>
              <CardBody>
                <Row className="mb-2">
                  <Col sm="6">
                    <div className="me-2 mb-2 d-inline-block">
                      <div className="position-relative d-flex">
                        <input
                          id="search-bar-10"
                          type="text"
                          aria-labelledby="search-bar-10-label"
                          className="form-control "
                          placeholder="Search"
                          onChange={(e) => handleSearch(e.target.value)}
                        />
                        <div className="ms-2" style={{ width: "20rem" }}>
                          <Select
                            placeholder="All Laundries"
                            onChange={val => {
                              setSelectedLaundry(val)
                            }}
                            isClearable={true}
                            options={laundryList.map((item, index) => {
                              return { value: item._id, label: item.shortName?item.shortName:item.nameEn }
                            })}
                          />
                        </div>
                      </div>
                    </div>
                  </Col>

                  <Col sm="6" className="d-flex align-items-center justify-content-between">
                    <div className="d-flex align-items-center">
                    <div className="d-flex align-items-center mx-3">
                    <span className={`me-2 ${fetchStatusTrue? "text-success font-weight-semibold": ""}`}>Show Enabled</span>
                        <div className="form-check form-switch form-switch-lg">
                          <input
                            type="checkbox"
                            className="form-check-input form-slider-color-green"
                            defaultChecked={fetchStatusTrue}
                            onChange={(e) => { setFetchStatusTrue(!fetchStatusTrue) }}
                          />
                        </div>
                      </div>
                    <div className="d-flex align-items-center">
                    <span className={`me-2 ${fetchStatusFalse? "text-success font-weight-semibold": ""}`}>Show Disabled</span>
                        <div className="form-check form-switch form-switch-lg">
                          <input
                            type="checkbox"
                            className="form-check-input form-slider-color-green"
                            defaultChecked={fetchStatusTrue}
                            onChange={(e) => { setFetchStatusFalse(!fetchStatusFalse) }}
                          />
                        </div>
                      </div>
                      </div>
                    {(userData?.permissions?.allPermissions == true || userData?.permissions?.b2bCustomer?.create == true) ?
                      <Button
                        type="button"
                        color="success"
                        className="btn-rounded  "
                        onClick={() => showAddb2b()}
                      >
                        <i className="mdi mdi-plus me-1" />
                        Add b2bCustomer 
                      </Button>
                      : null
                    }
                  </Col>
                </Row>
                <Row>
                  <DataTable
                    columns={b2bCustomerColumns}
                    data={b2bCustomerList
                      .filter((item) => {
                        return filterItem(item)
                      }).filter(slot => {
                        if (!selectedLaundry) {
                          return true
                        }
                        console.log(slot, selectedLaundry?.value)
                        return slot.laundry?._id == selectedLaundry?.value
                      })
                    }
                    pagination
                  />
                </Row>
              </CardBody>
            </Card>
            <div>

              {/*Add b2b*/}
              <Modal isOpen={modal} toggle={toggle} size="md">
              <ModalHeader toggle={toggle} tag="h4">
                {!!isEdit ? "Edit b2bCustomer" : "Add b2bCustomer"}
              </ModalHeader>
              <ModalBody>
                <AvForm
                  onValidSubmit={submitNewb2b}
                  encType="multipart/form-data"
                >
                  <Row form>
                    <div className="row mb-2">
                      <Col className="col-12">
                       

                        <div className="mb-3">
                          <AvField
                            name="nameEn"
                            label={<div>Name (English) <span className="mandatory_feild">*</span></div>}
                            type="text"
                            errorMessage="Name in English Required"
                            validate={{
                              required: { value: true },
                            }}
                            onChange={(e) => { setb2bNameEn(e.target.value) }}
                            value={b2bNameEn}
                          />
                        </div>

                        <div className="mb-3">
                          <AvField
                            name="nameAr"
                            label={<div>Name (Arabic) <span className="mandatory_feild">*</span></div>}
                            type="text"
                            errorMessage="Name in Arabic Required"
                            validate={{
                              required: { value: true },
                            }}
                            onChange={(e) => { setb2bNameAr(e.target.value) }}
                            value={b2bNameAr}
                          />
                        </div>
                        
                        <div className="mb-3">
                          <label>Laundry <span className="mandatory_feild">*</span></label>
                          <AvField 
                            type="select"
                            name="laundry"
                            value={b2bLaundry}
                            errorMessage="Select Unit"
                            className="form-control"
                            onChange={(e) =>setb2bLaundry(e.target.value)}
                            validate={{ required: { value: true } }}
                            helpMessage="">
                                      <option value="">Selet Laundry</option>
                                      {
                                      laundryList.map((laundry)=> {
                                        return (
                                          <option key={laundry._id} value={laundry._id}>{laundry.shortName?laundry.shortName:laundry.nameEn}</option>
                                        )
                                      })
                                      }

                            </AvField>
                        </div>

                        <div>
                        <label
                            htmlFor="horizontal-status-Input1"
                            className="status-label mt-2"
                          >Status
                          </label>
                          <div className="">
                            <Switch
                              uncheckedIcon={<Offsymbol />}
                              checkedIcon={<OnSymbol />}
                              className="mb-sm-8 mb-2"
                              onColor="#626ed4"
                              onChange={(e) => setStatusVal(!statusVal)}
                              checked={statusVal}
                            />
                          </div>
                        </div>
                       
                      </Col>
                    </div>
                    <div className="row mb-2">
                      
                    </div>
                  </Row>
                  <Row>
                    <Col>
                      <div className="text-end">
                        <button
                          type="submit"
                          className="btn btn-success save-user"
                        >
                          Save
                        </button>
                      </div>
                    </Col>
                  </Row>
                </AvForm>
              </ModalBody>
            </Modal>
              
            </div>
          </Container>
        </div>

      </React.Fragment>
    )
  } else {

    return <Pages404 />
  }

}
b2bCustomer.propTypes = {
  t: PropTypes.any
}

export default withTranslation()(b2bCustomer)



