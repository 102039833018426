import {
  GET_REASONS_LIST_SUCCESS,
  GET_REASONS_LIST_FAIL,
  DELETE_REASON_SUCCESS,
  DELETE_REASON_FAIL,
  UPDATE_REASON_SUCCESS,
  UPDATE_REASON_FAIL,
  ADD_NEW_REASON_SUCCESS,
  ADD_NEW_REASON_FAIL
} from "./actionTypes"

const INIT_STATE = {
  reasonList: []
}

const Reasons = (state = INIT_STATE, action) => {
  switch (action.type) {

    case GET_REASONS_LIST_SUCCESS:
      return {
        ...state,
        reasonList: action.payload.record,
      }

    case GET_REASONS_LIST_FAIL:
      return {
        ...state,
        error: action.payload,
      }

      // case ADD_NEW_REASON_SUCCESS:
      //   return {
      //     ...state,
      //     driverList: [...state.driverList, action.payload.record],
      //   }
  
      case ADD_NEW_REASON_FAIL:
        return {
          ...state,
          error: action.payload,
        }

      case UPDATE_REASON_SUCCESS:
        return {
          ...state,
          reasonList: state.reasonList.map((item) => {
            if (item._id === action.payload._id)
              return {...item, ...action.payload}
            else
              return item
          })
        };
  
      case UPDATE_REASON_FAIL:
        return {
          ...state,
          error: action.payload,
        }

      case DELETE_REASON_SUCCESS:
        return {
          ...state,
          reasonList: state.reasonList.filter((item) => item._id !== action.payload._id),
        }
  
      case DELETE_REASON_FAIL:
        return {
          ...state,
          error: action.payload,
        }

    default:
      return state
  }
}

export default Reasons
