import axios from "axios"
import { del, get, post, put, postWeb } from "./api_helper"
import * as url from "./url_helper"

const fetchAppRegistartions = () => postWeb(`${url.GET_APP_REGISTRATIONS}`, {  })
const fetchUniqueOrders = () => postWeb(`${url.GET_UNIQUE_ORDERS}`, {  })
const fetchMonthlyKleenOrders = () => postWeb(`${url.GET_MONTHLY_ORDERS}`, {})
const fetchMonthlyLaundryOrders = (laundry) => postWeb(`${url.GET_MONTHLY_ORDERS}`, {laundry:laundry})
const fetchMonthlyKleenGMV = () => postWeb(`${url.GET_MONTHLY_GMV}`, {})
const fetchMonthlyLaundryGMV = (laundry) => postWeb(`${url.GET_MONTHLY_GMV}`, {laundry:laundry})

const fetchDailyLaundryData = (data) => postWeb(`${url.GET_DAILY_LAUNDRY}`, data)
const fetchDailyOnlineData = (data) => postWeb(`${url.GET_DAILY_ONLINE}`, data)

const fetchMonthlyKleenAvgBasket = (data) => postWeb(`${url.GET_MONTHLY_AVGBASKET}`, data)
const fetchMonthlyKleenAvgSubscription = (data) => postWeb(`${url.GET_MONTHLY_AVGSUBSCRIPTION}`, {area:data})
const fetchMonthlyKleenSubscriber = (data) => postWeb(`${url.GET_MONTHLY_SUBSCRIBER}`, {area:data})
const fetchMonthlyLaundryAvgBasket = (laundry) => postWeb(`${url.GET_MONTHLY_AVGBASKET}`, {laundry:laundry})

const fetchMonthlySegment = () => postWeb(`${url.GET_MONTHLY_SEGMENT}`, {})
const fetchSalesGrowth = (data) => postWeb(`${url.GET_SALES_GROWTH}`, data)
const fetchSalesGrowthLaundry = (data) => postWeb(`${url.GET_SALES_GROWTH}`, data)

export {
  fetchAppRegistartions,
  fetchUniqueOrders,
  fetchMonthlyKleenOrders,
  fetchMonthlyLaundryOrders,
  fetchMonthlyKleenGMV,
  fetchMonthlyLaundryGMV, 
  fetchDailyLaundryData, 
  fetchDailyOnlineData,
  fetchMonthlyKleenAvgBasket,
  fetchMonthlyKleenAvgSubscription,
  fetchMonthlyKleenSubscriber,
  fetchMonthlyLaundryAvgBasket,
  fetchMonthlySegment,
  fetchSalesGrowth, 
  fetchSalesGrowthLaundry
}