import {
  GET_B2B_CUSTOMER,
  GET_B2B_CUSTOMER_SUCCESS,
  GET_B2B_CUSTOMER_FAIL,
  GET_CART_DATA,
  GET_CART_DATA_FAIL,
  GET_CART_DATA_SUCCESS,
  GET_ORDERS,
  GET_ORDERS_FAIL,
  GET_ORDERS_SUCCESS,
  ADD_NEW_ORDER,
  ADD_ORDER_SUCCESS,
  ADD_ORDER_FAIL,
  UPDATE_ORDER,
  UPDATE_ORDER_SUCCESS,
  UPDATE_ORDER_FAIL,
  DELETE_ORDER,
  DELETE_ORDER_SUCCESS,
  DELETE_ORDER_FAIL,
  GET_PRODUCTS,
  GET_PRODUCTS_FAIL,
  GET_PRODUCTS_SUCCESS,
  GET_CUSTOMERS,
  GET_CUSTOMERS_FAIL,
  GET_CUSTOMERS_SUCCESS,
  ADD_NEW_CUSTOMER,
  ADD_CUSTOMER_SUCCESS,
  ADD_CUSTOMER_FAIL,
  UPDATE_CUSTOMER,
  UPDATE_CUSTOMER_SUCCESS,
  UPDATE_CUSTOMER_FAIL,
  DELETE_CUSTOMER,
  DELETE_CUSTOMER_SUCCESS,
  DELETE_CUSTOMER_FAIL,
  GET_SHOPS,
  GET_SHOPS_FAIL,
  GET_SHOPS_SUCCESS,
  GET_PRODUCT_DETAIL,
  GET_PRODUCT_DETAIL_FAIL,
  GET_PRODUCT_DETAIL_SUCCESS,
  GET_PRODUCT_COMMENTS,
  GET_PRODUCT_COMMENTS_SUCCESS,
  GET_PRODUCT_COMMENTS_FAIL,
  ON_LIKE_COMMENT,
  ON_LIKE_COMMENT_SUCCESS,
  ON_LIKE_COMMENT_FAIL,
  ON_LIKE_REPLY,
  ON_LIKE_REPLY_SUCCESS,
  ON_LIKE_REPLY_FAIL,
  ON_ADD_REPLY,
  ON_ADD_REPLY_SUCCESS,
  ON_ADD_REPLY_FAIL,

  ON_ADD_COMMENT,
  ON_ADD_COMMENT_SUCCESS,
  ON_ADD_COMMENT_FAIL,

  // PRICELIST
  GET_PRICELIST,
  GET_PRICELIST_SUCCESS,
  GET_PRICELIST_FAIL,
  GET_CLOTHLIST,
  GET_CLOTHLIST_SUCCESS,
  GET_CLOTHLIST_FAIL,
  ADD_PRICE,
  ADD_PRICE_SUCCESS,
  ADD_PRICE_FAIL,
  UPDATE_PRICE,
  UPDATE_PRICE_SUCCESS,
  UPDATE_PRICE_FAIL,
  BULK_UPDATE_PRICE,
  BULK_UPDATE_PRICE_SUCCESS,
  BULK_UPDATE_PRICE_FAIL,
  //PRICELIST LAUNGY LIST
  GET_DRIVER,
  GET_DRIVER_SUCCESS,
  GET_DRIVER_FAIL,

  GET_LAUNDRIES,
  GET_LAUNDRIES_SUCCESS,
  GET_LAUNDRIES_FAIL,
  //PRICELIST AREA
  GET_AREA,
  GET_AREA_SUCCESS,
  GET_AREA_FAIL,
  GET_UPDATE_AREA,
  GET_TIMESLOT,
  GET_TIMESLOT_SUCCESS,
  GET_TIMESLOT_FAIL,

  ADD_NEW_AREA,
  ADD_NEW_AREA_SUCCESS,
  ADD_NEW_AREA_FAIL,

  UPDATE_AREA,
  UPDATE_AREA_SUCCESS,
  UPDATE_AREA_FAIL,

  DELETE_PRICE,
  DELETE_PRICE_SUCCESS,
  DELETE_PRICE_FAIL

} from "./actionTypes"


export const getTimeSlotList = (data) => ({
  type: GET_TIMESLOT,
  payload: data
})

export const fetchTimeSlotListSuccess = (data) => ({
  type: GET_TIMESLOT_SUCCESS,
  payload: data
})

export const fetchTimeSlotListFail = (data) => ({
  type: GET_TIMESLOT_FAIL,
  payload: data
})

export const getProducts = () => ({
  type: GET_PRODUCTS,
})

export const getProductsSuccess = products => ({
  type: GET_PRODUCTS_SUCCESS,
  payload: products,
})

export const getProductsFail = error => ({
  type: GET_PRODUCTS_FAIL,
  payload: error,
})


//addd  new area
export const addNewArea = (data) => ({
  type: ADD_NEW_AREA,
  payload: data
})

export const addNewAreaSuccess = newArea => ({
  type: ADD_NEW_AREA_SUCCESS,
  payload: newArea,
})

export const addNewAreaFail = error => ({
  type: ADD_NEW_AREA_FAIL,
  payload: error,
})


//Update Area
export const updateArea = (data) => ({
  type: UPDATE_AREA,
  payload: data
})

export const updateAreaSuccess = newArea => ({
  type: UPDATE_AREA_SUCCESS,
  payload: newArea,
})

export const updateAreaFail = error => ({
  type: UPDATE_AREA_FAIL,
  payload: error,
})


export const getProductDetail = productId => ({
  type: GET_PRODUCT_DETAIL,
  productId,
})

export const getProductDetailSuccess = products => ({
  type: GET_PRODUCT_DETAIL_SUCCESS,
  payload: products,
})

export const getProductDetailFail = error => ({
  type: GET_PRODUCT_DETAIL_FAIL,
  payload: error,
})

export const getOrders = (data) => ({
  type: GET_ORDERS,
  payload: data
})

export const getOrdersSuccess = orders => ({
  type: GET_ORDERS_SUCCESS,
  payload: orders,
})
//PRICELIST

export const getPricelist = (data) => ({
  type: GET_PRICELIST,
  payload: data
})

export const reloadPricelist = (data) => ({
  type: GET_PRICELIST,
  payload: data
})

export const getPricelistSuccess = pricelist => ({
  type: GET_PRICELIST_SUCCESS,
  payload: pricelist,
})

export const getPricelistFail = error => ({
  type: GET_PRICELIST_FAIL,
  payload: error,
})
//PRICELIST END
export const getDriverlist = (data) => ({
  type: GET_DRIVER,
  payload: data
})

export const getDriverSuccess = laundrylist => ({
  type: GET_DRIVER_SUCCESS,
  payload: laundrylist,
})

export const getDriverFail = error => ({
  type: GET_DRIVER_FAIL,
  payload: error,
})
export const getClothlist = (data) => ({
  type: GET_CLOTHLIST,
  payload: data
})

export const getClothSuccess = data => ({
  type: GET_CLOTHLIST_SUCCESS,
  payload: data,
})

export const getClothFail = error => ({
  type: GET_CLOTHLIST_FAIL,
  payload: error,
})

export const addPriceList = (data, searchData) => ({
  type: ADD_PRICE,
  payload: {
    data : data, 
    searchData: searchData
  }
})

export const addPriceSuccess = data => ({
  type: ADD_PRICE_SUCCESS,
  payload: data,
})

export const addPriceFail = error => ({
  type: ADD_PRICE_FAIL,
  payload: error,
})

export const updatePriceList = (data) => ({
  type: UPDATE_PRICE,
  payload: data
})

export const updatePriceSuccess = data => ({
  type: GET_PRICELIST,
  payload: { web: true },
})

export const updatePriceFail = error => ({
  type: UPDATE_PRICE_FAIL,
  payload: error,
})

export const bulkUpdatePrice = (data, searchData) => ({
  type: BULK_UPDATE_PRICE,
  payload: { data, searchData },
})

export const deletePriceList = (data) => ({
  type: DELETE_PRICE,
  payload: data
})

export const deletePriceSuccess = data => ({
  type: DELETE_PRICE_SUCCESS,
  payload: data,
})

export const deletePriceFail = error => ({
  type: DELETE_PRICE_FAIL,
  payload: error,
})



// PRICELIST LAUDRY LIST

export const getLaundrylist = (data) => ({
  type: GET_LAUNDRIES,
  payload: data
})

export const getLaundrySuccess = laundrylist => ({
  type: GET_LAUNDRIES_SUCCESS,
  payload: laundrylist,
})

export const getLaundryFail = error => ({
  type: GET_LAUNDRIES_FAIL,
  payload: error,
})
// PRICE LIST AREA 

export const getUpdateArealist = (data) => ({
  type: GET_UPDATE_AREA,
  payload: data
})

export const getArealist = (data = {}) => ({
  type: GET_AREA,
  payload: data
})

export const getArealistSuccess = Arealist => ({
  type: GET_AREA_SUCCESS,
  payload: Arealist,
})

export const getArealistFail = error => ({
  type: GET_AREA_FAIL,
  payload: error,
})

export const getB2bCustomer = (data = {}) => ({
  type: GET_B2B_CUSTOMER,
  payload: data
})

export const getB2bCustomerSuccess = Arealist => ({
  type: GET_B2B_CUSTOMER_SUCCESS,
  payload: Arealist,
})

export const getB2bCustomerFail = error => ({
  type: GET_B2B_CUSTOMER_FAIL,
  payload: error,
})

export const getOrdersFail = error => ({
  type: GET_ORDERS_FAIL,
  payload: error,
})

export const addNewOrder = order => ({
  type: ADD_NEW_ORDER,
  payload: order,
})

export const addOrderSuccess = order => ({
  type: ADD_ORDER_SUCCESS,
  payload: order,
})

export const addOrderFail = error => ({
  type: ADD_ORDER_FAIL,
  payload: error,
})

export const updateOrder = order => ({
  type: UPDATE_ORDER,
  payload: order,
})

export const updateOrderSuccess = order => ({
  type: UPDATE_ORDER_SUCCESS,
  payload: order,
})

export const updateOrderFail = error => ({
  type: UPDATE_ORDER_FAIL,
  payload: error,
})

export const deleteOrder = order => ({
  type: DELETE_ORDER,
  payload: order,
})

export const deleteOrderSuccess = order => ({
  type: DELETE_ORDER_SUCCESS,
  payload: order,
})

export const deleteOrderFail = error => ({
  type: DELETE_ORDER_FAIL,
  payload: error,
})

export const getCartData = () => ({
  type: GET_CART_DATA,
})

export const getCartDataSuccess = cartData => ({
  type: GET_CART_DATA_SUCCESS,
  payload: cartData,
})

export const getCartDataFail = error => ({
  type: GET_CART_DATA_FAIL,
  payload: error,
})

export const getCustomers2 = () => ({
  type: GET_CUSTOMERS,
})

export const getCustomersSuccess = customers => ({
  type: GET_CUSTOMERS_SUCCESS,
  payload: customers,
})

export const getCustomersFail = error => ({
  type: GET_CUSTOMERS_FAIL,
  payload: error,
})

export const addNewCustomer = customer => ({
  type: ADD_NEW_CUSTOMER,
  payload: customer,
})

export const addCustomerSuccess = customer => ({
  type: ADD_CUSTOMER_SUCCESS,
  payload: customer,
})

export const addCustomerFail = error => ({
  type: ADD_CUSTOMER_FAIL,
  payload: error,
})

export const updateCustomer = customer => ({
  type: UPDATE_CUSTOMER,
  payload: customer,
})

export const updateCustomerSuccess = customer => ({
  type: UPDATE_CUSTOMER_SUCCESS,
  payload: customer,
})

export const updateCustomerFail = error => ({
  type: UPDATE_CUSTOMER_FAIL,
  payload: error,
})

export const deleteCustomer = customer => ({
  type: DELETE_CUSTOMER,
  payload: customer,
})

export const deleteCustomerSuccess = customer => ({
  type: DELETE_CUSTOMER_SUCCESS,
  payload: customer,
})

export const deleteCustomerFail = error => ({
  type: DELETE_CUSTOMER_FAIL,
  payload: error,
})

export const getShops = () => ({
  type: GET_SHOPS,
})

export const getShopsSuccess = shops => ({
  type: GET_SHOPS_SUCCESS,
  payload: shops,
})

export const getShopsFail = error => ({
  type: GET_SHOPS_FAIL,
  payload: error,
})

export const getProductComments = () => ({
  type: GET_PRODUCT_COMMENTS,
})

export const getProductCommentsSuccess = comments => ({
  type: GET_PRODUCT_COMMENTS_SUCCESS,
  payload: comments,
})

export const getProductCommentsFail = error => ({
  type: GET_PRODUCT_COMMENTS_FAIL,
  payload: error,
})

export const onLikeComment = (commentId, productId) => ({
  type: ON_LIKE_COMMENT,
  payload: { commentId, productId },
})

export const onLikeCommentSuccess = comments => ({
  type: ON_LIKE_COMMENT_SUCCESS,
  payload: comments,
})

export const onLikeCommentFail = error => ({
  type: ON_LIKE_COMMENT_FAIL,
  payload: error,
})

export const onLikeReply = (commentId, productId, replyId) => ({
  type: ON_LIKE_REPLY,
  payload: { commentId, productId, replyId },
})

export const onLikeReplySuccess = comments => ({
  type: ON_LIKE_REPLY_SUCCESS,
  payload: comments,
})

export const onLikeReplyFail = error => ({
  type: ON_LIKE_REPLY_FAIL,
  payload: error,
})

export const onAddReply = (commentId, productId, replyText) => ({
  type: ON_ADD_REPLY,
  payload: { commentId, productId, replyText },
})

export const onAddReplySuccess = comments => ({
  type: ON_ADD_REPLY_SUCCESS,
  payload: comments,
})

export const onAddReplyFail = error => ({
  type: ON_ADD_REPLY_FAIL,
  payload: error,
})

export const onAddComment = (productId, commentText) => ({
  type: ON_ADD_COMMENT,
  payload: { productId, commentText },
})

export const onAddCommentSuccess = comments => ({
  type: ON_ADD_COMMENT_SUCCESS,
  payload: comments,
})

export const onAddCommentFail = error => ({
  type: ON_ADD_COMMENT_FAIL,
  payload: error,
})