export const myParseFloat = (obj) => {
  const result = parseFloat(obj);
  if(isNaN(result)){
    return 0;
  }
  else{
    return result;
  }
}

export const myParseInt = (obj) => {
  const result = parseInt(obj);
  if(isNaN(result)){
    return 0;
  }
  else{
    return result;
  }
}

export const validateNumber = value => {
  return /^\d*$/.test(value)
}

export const validateEmail = (email) => {
  return String(email)
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
};

export const validatePassword = (str) =>
{
    var re = /^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{8,}$/;
    return re.test(str);
}
