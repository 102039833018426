import { GET_PROMOS_CODE, CREATE_PROMOS_CODE, UPDATE_PROMOS_CODE, ALREADY_EXITS, GET_TRANSACTION } from "./actionTypes";

export const apiSuccess = (data, actionType) => ({
  type: actionType,
  payload: data
})

export const promocodeError = () => ({
  type: ALREADY_EXITS,
  payload: "Promocode Already Exits!"
})

export const apiFail = (data, actionType) => ({
  type: actionType,
  payload: data
})

export const createPromoCode = (data) => ({
  type: CREATE_PROMOS_CODE,
  payload: data
})

export const updatePromoCode = (data) => ({
  type: UPDATE_PROMOS_CODE,
  payload: data
})

export const getPromosCode = (data) => ({
  type: GET_PROMOS_CODE,
  payload: data
})

export const getTransactions = (data) => ({
  type: GET_TRANSACTION,
  payload: data
})
