import { call, put, takeEvery } from "redux-saga/effects"
import { postAddUser } from "helpers/userHelper"
import toastr from "toastr";
import "toastr/build/toastr.min.css";

import {
  GET_B2B_LIST,
  GET_B2B_LIST_SUCCESS,
  GET_B2B_LIST_FAIL
} from "./actionTypes"

//Include Both Helper File with needed methods
import {
  fetchb2bList,
  
} from "helpers/b2bHelper"

import {
  getb2bList,
  getb2bListFail,
  getb2bListSuccess
} from "./actions"



function* getb2bCustmerList({ payload: data }) {
  try {
    const response = yield call(fetchb2bList, data)

    yield put(getb2bListSuccess(response))
  } catch (error) {
    yield put(getb2bListFail(error))
  }
}



function* b2bSaga() {
  yield takeEvery(GET_B2B_LIST, getb2bCustmerList)
}

export default b2bSaga
