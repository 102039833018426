import axios from "axios"
import {postWeb} from "./api_helper"
import * as url from "./url_helper"


const fetchPackagesList = (data) => postWeb(`${url.GET_PACKAGES}`, data)
const createPackage = (data) => postWeb(`${url.CREATE_PACKAGE}`, data)
const updatePackage = (data) => postWeb(`${url.UPDATE_PACKAGE}`, data)

export {
  fetchPackagesList,
  createPackage,
  updatePackage  
}