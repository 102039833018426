import { postWeb } from "./api_helper"
import * as url from "./url_helper"

const _getSettings = (data) => postWeb(`${url.GET_SETTINGS}`, data)
const _createSetting = (data) => postWeb(`${url.CREATE_SETTING}`, data)
const _updateSetting = (data) => postWeb(`${url.UPDATE_SETTING}`, data)

export {
  _getSettings,
  _createSetting,
  _updateSetting,
}