import {
  GET_CLOTHES_LIST,
  GET_CLOTHES_LIST_SUCCESS,
  GET_CLOTHES_LIST_FAIL,
  UPDATE_CLOTHES,
  UPDATE_CLOTHES_FAIL,
  ADD_NEW_CLOTHES,
  ADD_NEW_CLOTHES_FAIL,
  DELETE_CLOTHES,
  DELETE_CLOTHES_SUCCESS,
  DELETE_CLOTHES_FAIL,

} from "./actionTypes"

export const getClothesList = (data) => ({
  type: GET_CLOTHES_LIST,
  payload: data
})

export const getClothesListSuccess = (data) => ({
  type: GET_CLOTHES_LIST_SUCCESS,
  payload: data
})

export const getClothesListFail = (data) => ({
  type: GET_CLOTHES_LIST_FAIL,
  payload: data
})

export const addNewClothes = (data) => ({
  type: ADD_NEW_CLOTHES,
  payload: data
})

export const addNewClothSuccess = (data) => ({
  type: GET_CLOTHES_LIST,
  payload: {}
})

export const addNewClothFail = (data) => ({
  type: ADD_NEW_CLOTHES_FAIL,
  payload: data
})

export const updateClothes = (data) => ({
  type: UPDATE_CLOTHES,
  payload: data
})

export const updateClothesSuccess = (data) => ({
  type: GET_CLOTHES_LIST,
  payload: {}
})

export const updateClothesFail = (data) => ({
  type: UPDATE_CLOTHES_FAIL,
  payload: data
})

export const deleteClothess = (data) => ({
  type: DELETE_CLOTHES,
  payload: data
})

export const deleteClothesSuccess = (data) => ({
  type: DELETE_CLOTHES_SUCCESS,
  payload: data
})

export const deleteClothesFail = (data) => ({
  type: DELETE_CLOTHES_FAIL,
  payload: data
})

