import {
  API_SUCCESS,
  API_FAIL,
  GET_REGISTRATION_SUMMARY,
  GET_BAG_CUSTOMERS,
  GET_ORDER_COUNT,
  GET_UNIQUE_CUSTOMERS
} from "./actionTypes";

const INIT_STATE = {
  registrationSummary: {active: 0, inactive: 0},
  bagCustomers: {bag: 0, nonBag: 0},
  orderCounts: {},
  uniqueCustomers: {online: 0, offline: 0},
  apiError: {msg : ""}
};

const Analytics = (state = INIT_STATE, action) => {
  switch (action.type) {
  
    
      case API_SUCCESS:

          switch (action.payload.actionType) {
              case GET_REGISTRATION_SUMMARY:
                  return {
                      ...state,
                      registrationSummary: action.payload.data
                  };

              case GET_BAG_CUSTOMERS:
                return {
                    ...state,
                    bagCustomers: action.payload.data
                };

              case GET_ORDER_COUNT:
                return {
                    ...state,
                    orderCounts: action.payload.data
                };

              case GET_UNIQUE_CUSTOMERS:
                return {
                    ...state,
                    uniqueCustomers: action.payload.data
                };

              default:
                  return state;
          }
      case API_FAIL:
          switch (action.payload.actionType) {
              case GET_REGISTRATION_SUMMARY:
                  return {
                      ...state,
                      apiError: {msg : action.payload.error}
                  };


            

              default:
                  return state;
          }
      default:
          return state;
  }
}


export default Analytics;