import React, { useState, useEffect } from "react"
import Leaflet from "leaflet"
import { getArealist as onGetArealist, } from "store/actions";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from 'react-router-dom';
import "../MapsArea.css"
import Breadcrumbs from "../../../components/Common/Breadcrumb"

import {
  Map,
  TileLayer,
  Popup,
  Circle,
  CircleMarker,
  Polygon,
  Polyline,
  Rectangle,
} from "react-leaflet"
import "leaflet/dist/leaflet.css"

Leaflet.Icon.Default.imagePath = "../node_modules/leaflet"

delete Leaflet.Icon.Default.prototype._getIconUrl

Leaflet.Icon.Default.mergeOptions({
  iconRetinaUrl: require("leaflet/dist/images/marker-icon-2x.png"),
  iconUrl: require("leaflet/dist/images/marker-icon.png"),
  shadowUrl: require("leaflet/dist/images/marker-shadow.png"),
})

// const polyline = [
//   [51.505, -0.09],
//   [51.51, -0.1],
//   [51.51, -0.12],
// ]

// const multiPolyline = [
//   [
//     [51.5, -0.1],
//     [51.5, -0.12],
//     [51.52, -0.12],
//   ],
//   [
//     [51.5, -0.05],
//     [51.5, -0.06],
//     [51.52, -0.06],
//   ],
// ]

// const polygon = [
//   [51.515, -0.09],
//   [51.52, -0.1],
//   [51.52, -0.12],
// ]

const multiPolygon = []

// const rectangle = [
//   [51.49, -0.08],
//   [51.5, -0.06],
// ]


export default function MapVectorLayers(props) {

  const dispatch = useDispatch();
  let history = useHistory();
  const [areacords, setAreaCords] = useState([]);


  const { arealist } = useSelector(state => ({
    arealist: state.ecommerce.laudrylist,
  }));

  const [state, setState] = useState({});

  useEffect(() => {
    if (arealist.length) {
      setState({
        lat: arealist[0]?.location?.coordinates[0][0][0],
        lng: arealist[0]?.location?.coordinates[0][0][1],
        zoom: 12,
        language: 'en',
      })
    }
  }, [arealist])

  useEffect(() => {
    dispatch(onGetArealist());
  }, [dispatch])

  const coordsSend = (item) => {
    history.push("/edit-area", { items: item });
  }

  const position = [state.lng, state.lat]
  return (
    <div>
      <div   className="area_map_breadcrumb"> 
      <h2>All Areas</h2>
           {/* <Breadcrumbs
            title={("Home")}
            breadcrumbItem={("All Areas")}
          /> */}
          </div>
        
  
      {
        arealist?.map((item) => {
          var result = [];
          item?.location?.coordinates[0]?.map((item2) => {
            for (var index = 0; index < item2.length - 1; index++) {

              result.push([
                Number(item2[1]),
                Number(item2[0])
              ]);
            }
            return result;
          })
          multiPolygon?.push(result);
        })
      }

      {
        multiPolygon.length ? (
          <Map
          className="map_vector_leaf"
            center={position}
            zoom={state.zoom}
            style={{ height: "500px" }}
          >
            <TileLayer
              attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
              url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            />
            {/* <Circle center={position} fillColor="blue" radius={200} />
        <CircleMarker center={[51.51, -0.12]} color="red" radius={20}>
          <Popup>Popup in CircleMarker</Popup>
        </CircleMarker> */}
            {/* <Polyline color="lime" positions={polyline} />
        <Polyline color="lime" positions={multiPolyline} />
        <Polygon color="purple" positions={polygon} /> */}
            <Polygon color="purple" positions={multiPolygon} />
            {/* <Rectangle bounds={rectangle} color="black" /> */}
          </Map>
        ) : null
      }
        {/* {
            console.log("arealist" ,arealist)
          } */}
      <ul className="row mt-3">
        {
          arealist?.map((item) => {
            return (
             
                <div className="col-md-2" key={item?._id}>
                  <li className="mapvector_arealist" onClick={() => { coordsSend(item) }} >{item?.name}</li>
                </div>
             
            )

          })
        }
      </ul>
    </div>
  )
}

