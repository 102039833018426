import React, { useEffect, useState, useRef, useCallback } from "react"
import PropTypes from "prop-types"
import toastr from "toastr"
import { Input } from "reactstrap"

import { postWeb } from "helpers/api_helper"

import "toastr/build/toastr.min.css"

const Editable = props => {
  const [label, setLabel] = useState("")
  const [emptyLabel, setEmptyLabel] = useState("")
  const [showLabel, setShowLabel] = useState(true)
  const [selectedItem, setSelectedItem] = useState("")

  useEffect(() => {
    if (props.fieldName == "bagNo") {
      setSelectedItem(props.row.customer?.bagNo)
    }
  }, [])

  useEffect(() => {
    if (props.fieldName == "laundry") {
      setLabel(
        props.row.laundry?.shortName
          ? props.row.laundry?.shortName
          : props.row.laundry?.nameEn
      )
      setEmptyLabel("No Laundry")
    } else if (props.fieldName == "pickup") {
      setLabel(props.row.pickupDriver?.name)
      setEmptyLabel("No Captain")
    } else if (props.fieldName == "delivery") {
      setLabel(props.row.dropoffDriver?.name)
      setEmptyLabel("No Captain")
    } else if (props.fieldName == "bagNo") {
      setLabel(props.row.customer?.bagNo)
      setEmptyLabel("Add Bag")
    }
  }, [props])

  const showEditable = () => {
    setShowLabel(false)
  }

  const handleClose = () => {
    setShowLabel(true)
  }

  const handleSubmit = async () => {
    if (props.fieldName == "bagNo") {
      if (!selectedItem || selectedItem.trim() == "") {
        toastr.error("Please enter bag No ", `Required`)
        return
      }

      if (!props.row?.customer?._id) {
        toastr.error("Invalid customer ", `Required`)
        return
      }

      await postWeb("customers/update", {
        _id: props.row.customer?._id,
        bagNo: selectedItem,
      })

      props.onUpdate.apply(null, [props.row._id, "bagNo", selectedItem])
      toastr.success(`Bag Number Updated Successfully`, `Bag Number Updated`)

      setShowLabel(true)
      return
    }

    if (!selectedItem) {
      toastr.error(
        "Please select " +
          (props.fieldName == "laundry"
            ? props.fieldName
            : props.fieldName + " captain"),
        `Required`
      )

      return
    }
    const data = {
      _id: props.row._id,
    }
    let objectName = ""
    let key = ""
    let value = props.itemlist.find(item => item._id == selectedItem)

    if (props.fieldName == "laundry") {
      data.laundry = selectedItem
      objectName = "Laundry "
      key = "laundry"
    } else if (props.fieldName == "pickup") {
      objectName = "Pickup Driver "
      data.pickupDriver = selectedItem
      key = "pickupDriver"
    }
    if (props.fieldName == "delivery") {
      objectName = "Dropoff Driver "
      data.dropoffDriver = selectedItem
      key = "dropoffDriver"
    }

    const response = await postWeb("orders/update", data)
    props.onUpdate.apply(null, [response.order._id, key, value])
    toastr.success(objectName + ` Updated Successfully`, `Order Updated`)

    setShowLabel(true)
  }

  const onChangeItem = e => {
    setSelectedItem(e.target.value)
  }

  return (
    <>
      {showLabel ? (
        <>
          {label ? (
            <a onClick={showEditable} className="editable editable-click">
              {label}
            </a>
          ) : (
            <a
              onClick={showEditable}
              className="editable editable-click editable-empty text-danger"
            >
              {emptyLabel}
            </a>
          )}
        </>
      ) : (
        <>
          {props.itemlist ? (
            <select
              className="form-control form-select form-select-sm"
              onChange={onChangeItem}
            >
              <option value="">select {props.fieldName}</option>

              {props.itemlist.map(item => {
                if (props.fieldName == "laundry") {
                  return (
                    <option value={item._id} key={item._id}>
                      {item.shortName ? item.shortName : item.nameEn}
                    </option>
                  )
                } else {
                  return (
                    <option value={item._id} key={item._id}>
                      {item.name}
                    </option>
                  )
                }
              })}
            </select>
          ) : (
            <Input
              type="text"
              value={selectedItem}
              onChange={e => setSelectedItem(e.target.value)}
            />
          )}
          <div className="editable-buttons">
            <button
              type="submit"
              onClick={handleSubmit}
              className="btn btn-success editable-submit btn-sm waves-effect waves-light"
            >
              <i className="mdi mdi-check"></i>
            </button>
            <button
              type="button"
              onClick={handleClose}
              className="btn btn-danger editable-cancel btn-sm waves-effect waves-light"
            >
              <i className="mdi mdi-close"></i>
            </button>
          </div>
        </>
      )}
    </>
  )
}

Editable.propTypes = {
  fieldName: PropTypes.any,
  row: PropTypes.any,
  itemlist: PropTypes.any,
  onUpdate: PropTypes.any,
}

export default Editable
