import {
    API_SUCCESS,
    API_FAIL,
    GET_CHARTS_DATA,
    GET_CHARTS_DATA_RADIAL,
    GET_REATIME_DETAILS,
    GET_TOP4_DATA,
    SET_LOADING,
    APP_REGISTRATIONS_MONTHLY,
    UNIQUE_CUSTOMER_ORDERS,
    MONTHLY_KLEEN_ORDERS,
    MONTHLY_LAUNDRY_ORDERS, 
    MONTHLY_KLEEN_GMV,
    MONTHLY_LAUNDRY_GMV,
    DAILY_ONLINE_DATA,
    DAILY_LAUNDRY_DATA,
    MONTHLY_KLEEN_AVGBASKET,
    MONTHLY_KLEEN_AVGSUBSCRIPTION,
    MONTHLY_KLEEN_SUBSCRIBER,
    MONTHLY_LAUNDRY_AVGBASKET,
    MONTHLY_SEGMENT, 
    SALES_GROWTH,
    SALES_GROWTH_LAUNDRY
} from "./actionTypes";

const INIT_STATE = {
    chartsData: [],
    realTimeDetails: [],
    top4Data: [],
    loading: false,
    appRegistrationsData: [],
    uniqueCustomerOrders: [],
    monthlyKleenOrders: [],
    monthlyLaundryOrders: [],
    monthlyKleenGMV: [],
    monthlyLaundryGMV: [],
    dailyLaundryData : [], 
    dailyOnlineData : [],
    monthlyKleenAvgBasket: {months: []},
    monthlyKleenAvgSubscription: {months: []},
    monthlyLaundryAvgBasket : {months: []},
    monthlySegment: {months: []},
    salesGrowth: {online : [], offline:[], all:[]},
    salesGrowthLaundry: {online : [], offline:[], all:[]}
};

const Dashboard = (state = INIT_STATE, action) => {

    switch (action.type) {
        case API_SUCCESS:

            switch (action.payload.actionType) {
                case GET_CHARTS_DATA:
                    return {
                        ...state,
                        chartsData: action.payload.data
                    };
                case GET_CHARTS_DATA_RADIAL:
                    return {
                        ...state,
                        chartsData: action.payload.data
                    };
                case GET_REATIME_DETAILS:
                    
                    let st =  {
                        ...state,
                        realTimeDetails: action.payload.data
                    };
                    console.log("new State", st);
                    return st;
                
                case GET_TOP4_DATA:
                    return {
                        ...state,
                        top4Data: action.payload.data,
                        loading: false
                    };
                
                case SET_LOADING:
                    return {
                        ...state,
                        loading: true
                    };

                case APP_REGISTRATIONS_MONTHLY:
                  return {
                      ...state,
                      appRegistrationsData: action.payload.data
                  };

                case UNIQUE_CUSTOMER_ORDERS:
                  return {
                      ...state,
                      uniqueCustomerOrders: action.payload.data
                  };

                case MONTHLY_KLEEN_ORDERS:
                  return {
                      ...state,
                      monthlyKleenOrders: action.payload.data
                  };

                case MONTHLY_LAUNDRY_ORDERS:
                  return {
                      ...state,
                      monthlyLaundryOrders: action.payload.data
                  };

                case MONTHLY_KLEEN_GMV:
                  return {
                      ...state,
                      monthlyKleenGMV: action.payload.data
                  };

                case MONTHLY_LAUNDRY_GMV:
                  return {
                      ...state,
                      monthlyLaundryGMV: action.payload.data
                  };
                
                case DAILY_LAUNDRY_DATA:
                  return {
                      ...state,
                      dailyLaundryData: action.payload.data
                  };
                
                case DAILY_ONLINE_DATA:
                  return {
                      ...state,
                      dailyOnlineData: action.payload.data
                  };

                case MONTHLY_KLEEN_AVGBASKET:
                  return {
                      ...state,
                      monthlyKleenAvgBasket: action.payload.data
                  };

                case MONTHLY_KLEEN_AVGSUBSCRIPTION:
                    return {
                      ...state,
                      monthlyKleenAvgSubscription: action.payload.data
                  };

                case MONTHLY_KLEEN_SUBSCRIBER:
                    return {
                      ...state,
                      monthlyKleenSubscriber: action.payload.data
                };
                case MONTHLY_LAUNDRY_AVGBASKET:
                  return {
                      ...state,
                      monthlyLaundryAvgBasket: action.payload.data
                  };
                
                case MONTHLY_SEGMENT:
                  return {
                      ...state,
                      monthlySegment: action.payload.data
                  };

                case SALES_GROWTH:
                  return {
                      ...state,
                      salesGrowth: action.payload.data
                  };

                case SALES_GROWTH_LAUNDRY:
                  return {
                      ...state,
                      salesGrowthLaundry: action.payload.data
                  };

                default:
                    return state;
            }
        case API_FAIL:
            switch (action.payload.actionType) {
                case GET_CHARTS_DATA:
                    return {
                        ...state,
                        chartsDataError: action.payload.error
                    };


                default:
                    return state;
            }
        default:
            return state;
    }
}


export default Dashboard;