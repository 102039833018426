import { call, put, takeEvery } from "redux-saga/effects"

// Crypto Redux States
import { LIST_ANNOUNCEMENT, CREATE_ANNOUNCEMENT, UPDATE_ANNOUNCEMENT } from "./actionTypes"
import { apiSuccess, apiFail } from "./actions"

//Include Both Helper File with needed methods
import {
  _getAnnouncement,
  _createAnnouncement,
  _updateAnnouncement,
} from "helpers/announcementHelper"
import toastr from "toastr"

function* getAnnouncement({ payload: data }) {
  try {
    const response = yield call(_getAnnouncement, data)
    yield put(apiSuccess(LIST_ANNOUNCEMENT, response))
  } catch (error) {
    yield put(apiFail(LIST_SETTINGS, error))
  }
}

function* createAnnouncement({ payload: data }) {
  try {
    const response = yield call(_createAnnouncement, data)
    if (response?.statusCode == "200") {
      toastr.success("Setting created successfully")
      yield put(apiSuccess(CREATE_ANNOUNCEMENT, response.record))
    }
  } catch (error) {
    yield put(apiFail(CREATE_SETTING, error))
  }
}

function* updateAnnouncement({ payload: data }) {
  try {
    const response = yield call(_updateAnnouncement, data)
    if (response?.statusCode == "200") {
      toastr.success("Setting updated successfully")
      yield put(apiSuccess(UPDATE_ANNOUNCEMENT, response.record))
    }
  } catch (error) {
    console.log("error", error)
    yield put(apiFail(UPDATE_SETTING, error))
    throw error
  }
}

function* AnnouncementSaga() {
  yield takeEvery(LIST_ANNOUNCEMENT, getAnnouncement)
  yield takeEvery(CREATE_ANNOUNCEMENT, createAnnouncement)
  yield takeEvery(UPDATE_ANNOUNCEMENT, updateAnnouncement)
}

export default AnnouncementSaga
