export const API_SUCCESS = "API_SUCCESS";
export const API_FAIL = "API_FAIL";
export const SEARCH_PERMISSION = "SEARCH_PERMISSION";
export const ALL_MODULES = "ALL_MODULES";
export const UPDATE_PERMISSION = "UPDATE_PERMISSION";
export const CREATE_MODULE = "CREATE_MODULE";
export const UPDATE_MODULE = "UPDATE_MODULE";
export const UPDATE_MODULE_SUCCESS = "UPDATE_MODULE_SUCCESS";
export const UPDATE_MODULE_FAIL = "UPDATE_MODULE_FAIL";
export const CREATE_MODULE_SUCCESS = "CREATE_MODULE_SUCCESS";
export const CREATE_MODULE_FAIL = "CREATE_MODULE_FAIL";
export const UPDATE_PERMISSION_SUCCESS = "UPDATE_PERMISSION_SUCCESS";
export const UPDATE_PERMISSION_FAIL = "UPDATE_PERMISSION_FAIL";
export const CREATE_ROLE = "CREATE_ROLE";
export const CREATE_ROLE_SUCCESS = "CREATE_ROLE_SUCCESS";
export const CREATE_ROLE_FAIL = "CREATE_ROLE_FAIL";
export const CLEAR_ERROR_MSG = "CLEAR_ERROR_MSG";
export const GET_ALL_PERMISSIONS = "GET_ALL_PERMISSIONS";

