import React, { useCallback, useMemo, useState, useContext } from "react"
import { Row, Col, Card, CardBody, Input } from "reactstrap"
import MetaTags from 'react-meta-tags'
import PropTypes from "prop-types"

// datatable related plugins
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory, {
  PaginationProvider, PaginationListStandalone,
  SizePerPageDropdownStandalone
} from 'react-bootstrap-table2-paginator';

import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"
import "../../assets/scss/datatables.scss"
import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux"
import { getCancelledOrders, searchUsers, getReasons } from "../../store/actions"
import {postWeb} from "helpers/api_helper"
import classNames from "classnames"
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import Pages404 from "pages/Utility/pages-404";
import moment from 'moment-timezone';
import toastr from "toastr";
import "toastr/build/toastr.min.css";
import Switch from "react-switch"
import SocketContext from "common/socket";
import { useRef } from "react";

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

const EditNote = props => {
  const [label, setLabel] = useState("");
  const [emptyLabel, setEmptyLabel] = useState("");
  const [showLabel, setShowLabel] = useState(true);
  const [selectedItem, setSelectedItem] = useState("");

  
  
  useEffect(() => {
    setSelectedItem(props.row.customer?.customer_care_note);
  }, [props]);

  useEffect(() => {
    setLabel(props.row.customer?.customer_care_note);
    setEmptyLabel("No Note")  
  }, [props]);

  const showEditable = () => {
    setShowLabel(false);
  }

  const handleClose = () => {
    setShowLabel(true);
  }
  
  const handleSubmit = async () => {
    
      if(!selectedItem || selectedItem.trim() == ""){
        toastr.error("Please enter bag No ", `Required`);
        return;
      }

      if(!props.row?.customer?._id){
        toastr.error("Invalid customer ", `Required`);
        return;
      }

      
      postWeb("customers/update", {
        _id : props.row.customer?._id,
        customer_care_note : selectedItem
      }).then(() => {
        props.onUpdate.apply(null, [props.row._id, selectedItem]);
      });
      
      toastr.success(`Customer Care Note Updated Successfully`, `Customer Care Note Updated`);
      
      setShowLabel(true);
      return;

  }

  const onChangeItem = (e) => {
    setSelectedItem(e.target.value);
  }

  return (
    <>
    {showLabel?
    <>
          {label ? 
            <a onClick={showEditable} className="editable editable-click">{label}</a> : 
            <a onClick={showEditable} className="editable editable-click editable-empty text-danger">{emptyLabel}</a>}
        </> :
        <>
          <textarea 
            onChange={(e) => setSelectedItem(e.target.value)}
            className="form-control"
          >{selectedItem}</textarea>
          <div className="editable-buttons">
            <button type="submit" 
              onClick={handleSubmit}
              className="btn btn-success editable-submit btn-sm waves-effect waves-light">
                <i className="mdi mdi-check">
                </i>
            </button>
            <button 
              type="button"
              onClick={handleClose} 
              className="btn btn-danger editable-cancel btn-sm waves-effect waves-light">
              <i className="mdi mdi-close"></i>
            </button>
          </div>
        </>}
    </>
  );
};

EditNote.propTypes = {
  row: PropTypes.any,
  onUpdate: PropTypes.any
}

const CancelledOrdersList = () => {
  const dispatch = useDispatch()
  const { cancelledOrders, refundReason, users } = useSelector(state => ({
    cancelledOrders: state.Orders.cancelledOrders,
    refundReason: state.Orders.reason,
    users : state.Users.users
  }))

  const reloadList = useRef();

  const [productData, setProductData] = useState([]);
  const [page, setPage] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [userData, setUserData] = useState({});
  const [totalRecords, setTotalRecords] = useState(0);
  const [onlyNew, setOnlyNew] = useState(false);
  
  const socket = useContext(SocketContext);
  const myAudio = new Audio(API_BASE_URL + "/sounds/invoice.mp3");


  const handleApply = () => {
    let apiData = { status: ["cancelled"], page: page, category: "online", sortBy:"cancelledDateTime", sortDir: "desc" };
    
    if(onlyNew){
      apiData.newCustomer = onlyNew;
    }

    dispatch(getCancelledOrders(apiData))
  }

  const multiLineDate = useCallback((_date) => {
    if(!_date) return "";
    const [dt, tm] = _date.split(" ");
      return (dt && tm)? <>
        {dt} <br /> {tm}
      </>:"NA"
  }, []);

  const Nosymbol = () => {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100%",
          fontSize: 12,
          color: "#fff",
          paddingRight: 2
        }}
      >
        {" "}
        No
      </div>
    )
  }
  
  const YesSymbol = () => {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100%",
          fontSize: 12,
          color: "#fff",
          paddingRight: 2
        }}
      >
        {" "}
        Yes
      </div>
    )
  }


  const addTztoDate = (dt) => {
    const targetTime = new Date(dt);
    return moment(dt).tz("Asia/kuwait").format("YYYY-MM-DD");
  };

  const addTztoFullDate = useCallback((_date) => {
    if(!_date) return "";

    const mTime = moment(new Date(_date)).tz("Asia/kuwait")
    const [dt, tm] = [mTime.format("YYYY-MM-DD"), mTime.format("HH:mm:ss")]
      return (dt && tm)? <>
        {dt} <br /> {tm}
      </>:"NA"
  }, []);


  useEffect(() => {
    setProductData([])
    setIsLoading(true);
    handleApply();
  }, [page])


  useEffect(() => {
    if(isLoading){
      return;
    }
    setProductData([])
    setIsLoading(true);
    setTimeout(() => {
      handleApply();
    }, 100)
    

  }, [onlyNew])


  //onlyNew

  useEffect(() => {
    dispatch(getReasons({}))
    if(!users) dispatch(searchUsers({webPermissions: true}))
    myAudio.preload = "auto";
    myAudio.load();

    socket.on("__order_updated__", data => {
      if(data.status == "cancelled"){
        myAudio.play();
        toastr.warning(`Order has been cancelled.`, `Order ID: ${data.orderID}`);
        
        if(page == 0){
          reloadList.current.apply(null, [data]);
        }
        
      }

    });

    return  () => {
      socket.off('__order_updated__');
    }

  }, [])

  useEffect(() => {
    
    const _productData = productData;

    reloadList.current = (data) => {

      if(productData.length > 0){
        let orders = productData;
        const totalOrders = productData.length;
        
        if (orders?.length && orders?.length > 0) {
          if(orders.length < 20){
            orders = [data?.orderData, ...orders];
            setProductData(orders);
            setTotalRecords(totalOrders+1);
          }
          else{
            orders.pop();
            orders = [data?.orderData, ...orders];
            setProductData(orders);
          }
        }
        else{
          setProductData([data?.orderData])
          setTotalRecords(1);
        }
      }
    }
  }, [productData])

  useEffect(() => {
    if(users.length> 0){
      setUserData(users[0]);
    }
  }, [users])

  useEffect(() => {
    const orders = cancelledOrders?.record;
    const totalOrders = cancelledOrders?.total_orders;
    
    if (orders?.length && orders[0]?.status == "cancelled") {
      setProductData(orders);
      setTotalRecords(totalOrders);
      //AllOrdersPaginated
    }
    setIsLoading(false);
    
  }, [cancelledOrders])


  const columns = [{
    dataField: 'orderID',
    text: 'Order',
    sort: false,
    formatter: (cellContent, row) => {
      if (isLoading) {
        return <Skeleton count={1} />
      }
      return row?.orderID || "N/A"
    }
  }, {
    dataField: 'customer.customerID',
    text: 'Customer',
    sort: false,
    formatter: (cellContent, row) => {
      if (isLoading) {
        return (<Skeleton count={1} />)
      }
      return row?.customer?.customerID || "N/A"
    }
  }, {
    dataField: 'customer.mobile',
    text: 'Mobile',
    sort: false,
    formatter: (cellContent, row) => {
      if (isLoading) {
        return (<Skeleton count={1} />)
      }
      return row?.customer?.mobile || "N/A"
    }
  },
  {
    dataField: 'customer.newCustomer',
    text: 'Customer Type',
    sort: false,
    formatter: (cellContent, row) => {
      if (isLoading) {
        return (<Skeleton count={1} />)
      }
      return row.customer?.newCustomer?"New":"Old"
    }
  },
  {
    dataField: 'residanceType',
    text: 'Location Type',
    sort: false,
    formatter: (cellContent, row) => {
      if (isLoading) {
        return (<Skeleton count={1} />)
      }
      return <span className="text-capitalize">{row.location?.type} </span>
    }
  }, 
  {
    dataField: 'startTime',
    text: 'Start Date',
    sort: false,
    formatter: (cellContent, row) => {
      if (isLoading) {
        return (<Skeleton count={1} />)
      }
      return multiLineDate(row?.startTime)    
    }
  }, {
    dataField: 'cancel date',
    text: 'Cancelled Date',
    sort: false,
    formatter: (cellContent, row) => {
      if (isLoading) {
        return (<Skeleton count={1} />)
      }
      return multiLineDate(row?.cancelledTime)
    }
  }, {
    dataField: 'reason',
    text: 'Reason',
    classes: "reason-wrap",
    sort: false,
    formatter: (cellContent, row) => {
      if (isLoading) {
        return (<Skeleton count={1} />)
      }
      let reasonObject = refundReason.find((item) => item._id === row?.orderCancellation?.reason)
      return row?.orderCancellation?.otherReason ? row?.orderCancellation?.otherReason : reasonObject?.reasonEn ? reasonObject?.reasonEn : row?.cancellationReason
    }
  },
  
  {
    dataField: 'totalOrders',
    text: 'Total Orders',
    sort: false,
    formatter: (cellContent, row) => {
      return row?.customer?.totalOrders
    }
  },
  {
    dataField: 'firstOrderDate',
    text: 'First Order',
    sort: false,
    formatter: (cellContent, row) => {

      return (row?.customer?.firstOrderDate)?addTztoFullDate(row.customer.firstOrderDate): ""
    }
  },
  {
    dataField: 'lastOrderDate',
    text: 'Last Order',
    sort: false,
    formatter: (cellContent, row) => {
      return (row?.customer?.lastOrderDate)?addTztoFullDate(row.customer.lastOrderDate): ""
    }
  },
  {
    dataField: 'regDate',
    text: 'Registration',
    sort: false,
    formatter: (cellContent, row) => {
      // let dt = "";
      // if(row?.customer?.registrationDate){
      //   [dt] = row.customer.registrationDate.split(" ");
      // }
      return multiLineDate(row.customer?.registrationDate);
    }
  },
  {
    dataField: 'customer_care_note',
    text: 'Customer Care Note',
    classes: "reason-wrap",
    sort: false,
    formatter: (cellContent, row, i) => {
      if (isLoading) {
        return (<Skeleton count={1} />)
      }
      
      return <>
        
        <EditNote onUpdate={handleApply} row={row} />
      </>
    }
  },
  {
    dataField: 'contacted',
    text: 'Contacted',
    classes: "reason-wrap",
    sort: false,
    formatter: (cellContent, row, i) => {
      if (isLoading) {
        return (<Skeleton count={1} />)
      }
      
      return <>
        <div className="col-sm-7 form-check form-switch custom-toggle-sm">
          <Switch
              uncheckedIcon={<Nosymbol />}
              checkedIcon={<YesSymbol />}
              className="me-1 mb-sm-8 mb-2"
              onColor="#626ed4"
              onChange={e => updateContacted(row?.customer?._id, i, e)}
              checked={row?.customer?.contacted}
            />
        </div>
      </>
    }
  }
];


const updateContacted = (customer_id, i, contacted) => {
  postWeb("customers/update", {
    _id : customer_id,
    contacted : contacted
  }).then(() => {
    handleApply();
  });
  
  toastr.success(`Customer Care Note Updated Successfully`, `Customer Care Note Updated`);
  
}


  const defaultSorted = [{
    dataField: 'id',
    order: 'asc'
  }];

  const pageOptions = {
    sizePerPage: 20,
    totalSize: totalRecords, // replace later with size(customers),
    custom: true,
  }

  // Custom Pagination Toggle
  const sizePerPageList = [
    { text: '5', value: 5 },
    { text: '10', value: 10 },
    { text: '15', value: 15 },
    { text: '20', value: 20 },
    { text: '25', value: 25 },
    { text: 'All', value: totalRecords }];


  // Select All Button operation
  const selectRow = {
    mode: 'checkbox'
  }

  const { SearchBar } = Search;

  if( userData?.permissions?.allPermissions==true || userData?.permissions?.orders?.read==true){
  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Kleen | Cancelled Orders list</title>
        </MetaTags>
        <div className="container-fluid">
          <Breadcrumbs title="Orders" breadcrumbItem="Cancelled orders" />

          <Row id="cancel-order-list">
            <Col className="col-12">
              <Card>
                <CardBody>
                  <Row>
                    <Col>
                      <div className="d-flex align-items-center mb-2">
                        <span className={`me-2 ${onlyNew? "text-success font-weight-semibold": ""}`}>Only New Customers</span>
                        <div className="form-check form-switch form-switch-lg">
                          <input
                            type="checkbox"
                            className="form-check-input form-slider-color-green"
                            defaultChecked={onlyNew}
                            onChange={(e) => { setOnlyNew(!onlyNew) }}
                          />
                        </div>
                      </div>
                    </Col>
                  </Row>
                  {/* <CardTitle className="h4">Cancelled orders </CardTitle> */}
                  {/* <p className="card-title-desc">
                    react-bootstrap-table-next plugin has most features enabled by default,
                    so all you need to do to use it with your own tables is to
                    call the construction function:{" "}
                    <code>react-bootstrap-table-next </code>.
                  </p> */}
                  {
                    isLoading ? (
                      <div className="row">
                        <div className="col-xl-12">
                          <div className="table-responsive">
                            <div className="react-bootstrap-table">
                              <table className="table table align-middle table-nowrap">
                                <thead className="table-light">
                                  <tr>
                                    <th>Order</th>
                                    <th>Customer</th>
                                    <th>Mobile</th>
                                    <th>Start Date</th>
                                    <th>Cancelled Date</th>
                                    <th>Reason</th>
                                    <th>Total Orders</th>
                                    <th>First Order</th>
                                    <th>Last Order</th>
                                    <th>Registration</th>
                                  </tr>
                                </thead>
                                <tbody>
                                {[...Array(10).keys()].map((k) => (
                                    <tr key={k}>
                                      <td><Skeleton count={1} /></td>
                                      <td><Skeleton count={1} /></td>
                                      <td><Skeleton count={1} /></td>
                                      <td><Skeleton count={1} /></td>
                                      <td><Skeleton count={1} /></td>
                                      <td><Skeleton count={1} /></td>
                                      <td><Skeleton count={1} /></td>
                                      <td><Skeleton count={1} /></td>
                                      
                                      <td><Skeleton count={1} /></td>
                                      <td><Skeleton count={1} /></td>
                                      <td><Skeleton count={1} /></td>
                                  </tr>  
                                  ))}
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                      </div>
                    ) : (
                      <PaginationProvider
                        pagination={paginationFactory(pageOptions)}
                        keyField='id'
                        columns={columns}
                        data={productData}
                      >
                        {
                          ({ paginationProps, paginationTableProps }) => (
                            <ToolkitProvider
                              keyField='id'
                              columns={columns}
                              data={productData}
                              search
                            >
                              {toolkitProps => (
                                <React.Fragment>

                                  {/* <Row className="mb-2">
                              <Col md="4">
                                <div className="search-box me-2 mb-2 d-inline-block">
                                  <div className="position-relative">
                                    <SearchBar
                                      {...toolkitProps.searchProps}
                                    />
                                    <i className="bx bx-search-alt search-icon" />
                                  </div>
                                </div>
                              </Col>
                            </Row> */}

                                  <Row>
                                    <Col xl="12">
                                      <div className="table-responsive">
                                        <BootstrapTable
                                          keyField={"id"}
                                          responsive
                                          bordered={false}
                                          striped={false}
                                          defaultSorted={defaultSorted}
                                          // selectRow={selectRow}
                                          classes={
                                            "table align-middle table-nowrap"
                                          }
                                          headerWrapperClasses={"table-light"}
                                          // headerWrapperClasses={"thead-light"}
                                          {...toolkitProps.baseProps}
                                          {...paginationTableProps}
                                        />

                                      </div>
                                    </Col>
                                  </Row>

                                  {/* <Row className="align-items-md-center mt-30">
                              <Col className="inner-custom-pagination d-flex">
                                <div className="d-inline">
                                  <SizePerPageDropdownStandalone
                                    {...paginationProps}
                                  />
                                </div>
                                <div className="text-md-right ms-auto">
                                  <PaginationListStandalone
                                    {...paginationProps}
                                  />
                                </div>
                              </Col>
                            </Row> */}
                                </React.Fragment> 
                              )
                              }
                            </ToolkitProvider>
                          )
                        }</PaginationProvider>
                    )
                  }



                </CardBody>
              </Card>
            </Col>
          </Row>
          <div className="align-items-md-center mt-30 row">
            <div className="inner-custom-pagination d-flex col">
              {/* <div className="d-inline">
                  <span className="react-bs-table-sizePerPage-dropdown dropdown" style={{ visibility: "visible" }}>
                    <button id="pageDropDown" type="button" className="btn btn-default btn-secondary dropdown-toggle" data-toggle="dropdown" aria-expanded="false">
                      10 <span><span className="caret"></span></span>
                    </button>
                    <ul className="dropdown-menu " role="menu" aria-labelledby="pageDropDown">
                      <li role="presentation" className="dropdown-item">
                        <a href="#" tabIndex="-1" role="menuitem" data-page="10">10</a>
                      </li>
                      <li role="presentation" className="dropdown-item">
                        <a href="#" tabIndex="-1" role="menuitem" data-page="25">25</a>
                      </li>
                      <li role="presentation" className="dropdown-item">
                        <a href="#" tabIndex="-1" role="menuitem" data-page="30">30</a>
                      </li>
                      <li role="presentation" className="dropdown-item">
                        <a href="#" tabIndex="-1" role="menuitem" data-page="50">50</a>
                      </li>
                    </ul>
                  </span>
                </div> */}
              <div className="text-md-right ms-auto">
                <ul className="pagination react-bootstrap-table-page-btns-ul">
                  {
                    page > 5 ? (
                      <li className="page-item" title="next page">
                        <a onClick={() => setPage(0)} className="page-link">First</a>
                      </li>
                    ) : null
                  }
                  {
                    [...Array(Math.ceil(totalRecords / 20)).keys()].map(element =>
                      (page >= element - 5 && page <= element + 5) ?
                        (
                          <li key={element} className={classNames("page-item",
                            { "active": element == page }
                          )
                          } title="1">
                            <a onClick={() => setPage(element)} className="page-link">{element + 1}</a>
                          </li>
                        ) : null
                    )
                  }
                  {
                    page < Math.ceil(totalRecords / 20) - 6 ? (
                      <li className="page-item" title="next page">
                        <a onClick={() => setPage(Math.ceil(totalRecords / 20) - 1)} className="page-link">Last</a>
                      </li>
                    ) : null
                  }

                  {/* <li className="page-item" title="2">
                      <a href="#" className="page-link">2</a>
                    </li>
                    <li className="page-item" title="3">
                      <a href="#" className="page-link">3</a>
                    </li> */}
                  {/* <li className="page-item" title="next page">
                      <a href="#" className="page-link">&gt;</a>
                    </li> */}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div >
    </React.Fragment >
  )
  }
  else{
    return <Pages404 />
  }
}

export default CancelledOrdersList;
