import {
  API_SUCCESS,
  API_FAIL,
  GET_SERVICES,
  UPDATE_SERVICE,
} from "./actionTypes"

export const apiSuccess = (actionType, data) => ({
  type: API_SUCCESS,
  payload: { actionType, data },
})

export const apiFail = (actionType, error) => ({
  type: API_FAIL,
  payload: { actionType, error },
})

export const getServices = data => ({
  type: GET_SERVICES,
  payload: data,
})

export const updateService = data => ({
  type: UPDATE_SERVICE,
  payload: data,
})
