import {
  API_SUCCESS,
  API_FAIL,
  LIST_ANNOUNCEMENT,
  CREATE_ANNOUNCEMENT,
  UPDATE_ANNOUNCEMENT,
} from "./actionTypes"

const INIT_STATE = {
  annoucements: []
}

const Annoucement = (state = INIT_STATE, action) => {
  switch (action.type) {
    case API_SUCCESS:
      switch (action.payload.actionType) {
        case LIST_ANNOUNCEMENT:
          return {
            ...state,
            annoucements: action.payload.data.record 
          };

        case CREATE_ANNOUNCEMENT:
          let createAnnoucement = [...state.annoucement]
          createAnnoucement.push(action.payload.data)
          return {
            ...state,
            annoucements: createAnnoucement
          };

        case UPDATE_ANNOUNCEMENT:
          const updateAnnoucement = action.payload.data
          let annoucements = [...state.annoucement]


          let index = annoucements.findIndex(
            announcment => announcment?.type === updateAnnoucement?.type
          )

          if(index === -1) {
            return {
              ...state,
            }
          }

          settings[index] = updatedSetting
          
          return {
            ...state,
            settings
          }


        default:
          return state;
      }

    case API_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    default:
      return state
  }
}

export default Annoucement
