import React from "react"
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Input,
} from "reactstrap"
import PropTypes from "prop-types"

const EditNoteModal = props => {
  const [note, setNote] = React.useState("")

  const toggle = () => {
    props.toggle()
    setNote(props.order.note)
  }

  const onAction = () => {
    props.onAction(note, note)
    toggle()
    setNote("")
  }

  React.useEffect(() => {
    setNote(props.order.note)
  }, [props.order])

  return (
    <Modal isOpen={props.isOpen} toggle={toggle} {...props.args}>
      <ModalHeader toggle={toggle}>
        Order Note (Order ID - {props.order.orderID})
      </ModalHeader>
      <ModalBody>
        <Input
          type="textarea"
          value={note}
          onChange={e => setNote(e.target.value)}
          placeholder="Order Note"
          rows={10}
          disabled={!props.editPermission}
        />
      </ModalBody>
      <ModalFooter>
        <Button
          color="primary"
          onClick={onAction}
          disabled={!props.editPermission}
        >
          Save
        </Button>
        <Button color="secondary" onClick={toggle}>
          Close
        </Button>
      </ModalFooter>
    </Modal>
  )
}

EditNoteModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onAction: PropTypes.func.isRequired,
  toggle: PropTypes.func.isRequired,
  args: PropTypes.object,
  order: PropTypes.object,
  editPermission: PropTypes.bool,
}

export default React.memo(EditNoteModal)
