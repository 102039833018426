import PropTypes from "prop-types"
import React, { useEffect, useState, useRef } from "react"
import MetaTags from "react-meta-tags";
import { AvForm, AvField, AvGroup, AvInput } from "availity-reactstrap-validation"
import MapsGoogle from "pages/Maps/MapsGoogle";
import MultiSelect from "react-multiple-select-dropdown-lite";
import Pages404 from "pages/Utility/pages-404";
import DataTable from "react-data-table-component";
import { myParseFloat, validateEmail, validatePassword } from "../../helpers/validationHelper"
import {
  Container,
  Row,
  Col,
  Modal,
  Button,
  Card,
  CardBody,
  Label,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  CardText,
  Form,
  Input,
  InputGroup, InputGroupText
} from "reactstrap"
import classnames from "classnames"

import paginationFactory, {
  PaginationListStandalone,
  PaginationProvider,
} from "react-bootstrap-table2-paginator";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import BootstrapTable from "react-bootstrap-table-next";
import { postArea } from "helpers/LaundryHelper"
import { createSubscriptionPackage, updateSubscriptionPackage } from "helpers/subscriptionPackage"
import { searchUsers, filterUsers, getRedeemPkgsList, getRedeemPkgDetail } from "../../store/actions";
import toastr from "toastr";
import "toastr/build/toastr.min.css";
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { withTranslation } from "react-i18next"
import { useSelector, useDispatch } from "react-redux"
import { useHistory } from 'react-router-dom';
import google_map_logo from '../../assets/images/google_map_logo.png'
import Switch from "react-switch"
import ReactSwitch from "react-switch"
import Select from "react-select"
import { Offsymbol, OnSymbol } from "components/Common/Symbol"
import { getPackagesList } from "store/packages/actions";
import 'react-dropzone-uploader/dist/styles.css'
import Dropzone from 'react-dropzone-uploader'
import { getToken } from "helpers/jwt-token-access/accessToken"
import { createPackage, updatePackage } from "helpers/packagesHelper";
import { from } from "core-js/fn/array";
import { PACKAGE_TYPES } from "constants/app";



const API_BASE_URL = process.env.REACT_APP_API_BASE_URL
const API_URL = `${API_BASE_URL}/apis/`

const Nosymbol = () => {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100%",
        fontSize: 12,
        color: "#fff",
        paddingRight: 2
      }}
    >
      {" "}
      No
    </div>
  )
}

const YesSymbol = () => {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100%",
        fontSize: 12,
        color: "#fff",
        paddingRight: 2
      }}
    >
      {" "}
      Yes
    </div>
  )
}


const subscriptionPackage = props => {
  const [pkgModal, SetPkgModal] = useState(false)
  const [lat, SetLat] = useState("24.71894638877753")
  const [lng, SetLng] = useState("46.675559402959095")
  const [partnerSubscription, setPartnerSubscription] = useState(true)
  const [carpetWash, setCarpetWash] = useState(true)
  const [clothWash, setClothWash] = useState(true)
  const [status, setStatus] = useState(true)
  const [vatToggle, setVatToggle] = useState(true)
  const [isEdit, setIsEdit] = useState(false)
  const [isEditItem, setIsEditItem] = useState({})
  const [userData, setUserData] = useState({});
  const [listData, setListData] = useState([]);

  const [packageName, setPackageName] = useState("");
  const [areaList, setAreaList] = useState([]);
  const [areaVal, setAreaVal] = useState([]);
  const [areaOption, setAreaOption] = useState([]);
  const [error, setError] = useState({});
  const [activeTab, setactiveTab] = useState("1");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [searchText, setSearchText] = useState("")
  const [priceWithoutVat, setPriceWithoutVat] = useState(0)
  const [vatPer, setVatPer] = useState(0)
  const [vat, setVat] = useState(0)
  const [fromVal, setFromValue] = useState(0)
  const [toVal, setToValue] = useState(0)
  const [totalPrice, setTotalPrice] = useState(0)
  const [clothOrderCount, setClothOrderCount] = useState(0)
  const [maxClothCount, setMaxClothCount] = useState(0)
  const [carwashCount, setCarwashCount] = useState(0)

  const [packageType, setPackageType] = useState("")
  const [imagesEn, setImagesEn] = useState([]);
  const [imagesAr, setImagesAr] = useState([]);
  const [highlight, setHighlight] = useState(false);
  const [imageUploading, setImageUploading] = useState(false);
  const [packageId, setPackageId] = useState();
  const [validity, setValidity] = useState(0);
  const [enableAllAreas, setEnableAllAreas] = useState(false);
  const [enableOffline, setOrderValOffline] = useState(false);
  const [enableOnline, setOrderValOnline] = useState(false);


  const { PackagesList, PackageDetail, users } = useSelector(state => ({
    PackagesList: state.Packages.Packages,
    PackageDetail: state.Packages.PackageDetail,
    users: state.Users.users
  }))

  useEffect(() => {
    if (!users) dispatch(searchUsers({ webPermissions: true }))
    dispatch(getPackagesList({ status: true }))
  }, [])

  useEffect(() => {
    if (users.length > 0) {
      setUserData(users[0]);
    }
  }, [users])


  useEffect(() => {
    setListData(PackagesList)
  }, [PackagesList])

  const handleSearch = (val) => {

    val = val?.toLowerCase()
    let filterBySearch = PackagesList.filter(item => {
      return item?.areas?.findIndex((data) => data?.name?.toLowerCase()?.includes(val)) !== -1
        || (item?.status && val == "on")
        || (item?.name?.toLowerCase().includes(val))
        || (!item?.status && val == "off")
    });

    if (!val) {
      setListData(PackagesList)
    } else {
      setListData(filterBySearch)
    }
    // console.log("filterBySearch", filterBySearch);
  }

  const toggle = tab => {
    if (activeTab !== tab) {
      setactiveTab(tab)
    }
  }


  function showPosition(position) {
    SetLat(position.coords.latitude);
    SetLng(position.coords.longitude);
  }

  useEffect(() => {
    if (!pkgModal) {
      setPackageId(null);
      setPackageName("")
      setPackageType("")
      setPriceWithoutVat(0)
      setVatPer(0);
      setVat(0);
      setTotalPrice(0);
      setClothOrderCount(0);
      setCarwashCount(0);
      setValidity(0);
      setAreaVal("")
      setImagesEn([]);
      setImagesAr([]);
      setEnableAllAreas(false);
      setOrderValOffline(false);
      setOrderValOnline(false);
      setFromValue(0);
      setToValue(0);
      setMaxClothCount(0);
      setError({})
      setStatus(true)
      setIsEdit(false);
    }

  }, [pkgModal])


  // useEffect(async () => {
  //   const AreaResponse = await postArea({});
  //   setAreaList(AreaResponse?.record);
  //   setAreaOption(
  //     AreaResponse?.record?.map((item) => {
  //       return { label: item?.name, value: item?._id }
  //     })
  //   )
  // }, [])

  const handleOnchange = (val) => {
    setAreaVal(val ? val.split(",") : [])
  }

  const openAddPackageModal = async () => {
    setIsEditItem({});
    SetPkgModal(true)
  }

  const UPDATE_PACKAGE = (item) => {
    console.log("item::", item);
    setPackageId(item._id);
    setPackageName(item.name);
    setPackageType(item.type);
    setPriceWithoutVat(item.price_without_vat);
    setVatPer(item.vat_perc);
    setVat(item.vat);
    setTotalPrice(item.total_price);
    setClothOrderCount(item.no_of_orders);
    setCarwashCount(item.carwash_orders);
    setValidity(item.validity);
    setAreaVal(item.areas.map((item2) => item2._id))
    setImagesEn(item.images_en);
    setImagesAr(item.images_ar);
    setEnableAllAreas(item.enableAllAreas);
    setOrderValOffline(item?.orderValue?.offline);
    setOrderValOnline(item?.orderValue?.online);
    setFromValue(item?.orderValue?.fromValue);
    setToValue(item?.orderValue?.toValue);
    setMaxClothCount(item?.max_cloth_per_package);
    setError({})
    setStatus(item.status);
    setIsEdit(true);
    setIsEditItem(item)
    SetPkgModal(true)
  }

  var node = useRef();
  const keyField = "id";
  const { SearchBar } = Search;
  const sizePerPage = 10;

  const pageOptions = {
    sizePerPage: sizePerPage,
    totalSize: listData.length, // replace later with size(users),
    custom: true,
  };

  const defaultSorted = [
    {
      dataField: "id", // if dataField is not match to any column you defined, it will be ignored.
      order: "desc", // desc or asc
    },
  ];

  let ListColumns = [
    {
      name: "Name",
      sortable: true,
      selector: row => row.name,
      wrap: true,
    }, {
      name: "Type",
      sortable: true,
      selector: row => row.type,
      center: true,
      wrap: true,
    },
    {
      name: "Category",
      sortable: true,
      selector: row => row.category,
      center: true,
      wrap: true,
    },
    {
      name: "Validity",
      sortable: true,
      selector: row => `${row?.validity} Days`,
      center: true,
      wrap: true,
    },
    {
      name: "Areas",
      wrap: true,
      sortable: true,
      center: true,
      selector: row => (
        <div>
          {row?.areas?.map((item2, index) => {
            return `${item2?.name} |`
          })}
        </div>
      )
    },
    {
      name: "Status",
      sortable: true,
      center: true,
      selector: row => (
        <div>
          {row?.status ? "On" : "Off"}
        </div>
      ),
    }
  ];

  if (userData?.permissions?.allPermissions == true || userData?.permissions?.packages?.update == true) {
    ListColumns.push(
      {
        name: "View",
        center: true,
        selector: row => {
          return <>
            <button onClick={() => UPDATE_PACKAGE(row)} className="btn btn-primary btn-sm" >
              View
            </button>
          </>
        }
      });
  }

  function togglePkgModal() {
    SetPkgModal(!pkgModal);
    removeBodyCss();
  }


  function removeBodyCss() {
    document.body.classList.add("no_padding");
  }

  toastr.options = {
    positionClass: "toast-top-right",
    timeOut: 3000,
    extendedTimeOut: 1000,
    closeButton: true,
    debug: true,
    progressBar: true,
    preventDuplicates: true,
    newestOnTop: true,
    showEasing: "swing",
    hideEasing: "linear",
    showMethod: "fadeIn",
    hideMethod: "fadeOut",
    showDuration: 300,
    hideDuration: 1000
  };


  const handleValidSubmit = async (event, values) => {
    if (areaVal?.length < 1 && enableAllAreas == false) {
      setError({ area: "Area Required" });
      return
    }

    if (!isEdit) {
      if (myParseFloat(fromVal) >= myParseFloat(toVal)) {
        setError({ "fromVal": "From value should be less than to value" })
        return;
      }
    }

    let PackageResponse;
    let resTitle, resMsg;
    if (isEdit) {
      const updateData = {
        _id: isEditItem._id,
        areas: areaVal,
        images_en: imagesEn,
        images_ar: imagesAr,
        status: status,
        enableAllAreas: enableAllAreas
      }

      PackageResponse = await updatePackage(updateData)
      resMsg = PackageResponse.statusCode == 200 ? "Package Updated" : "Unable to Update Package";
    } else {
      const AddData = {
        name: packageName,
        type: packageType,
        areas: areaVal,
        images_en: imagesEn,
        images_ar: imagesAr,
        price_without_vat: priceWithoutVat,
        vat_perc: vatPer,
        vat: vat,
        fromValue: fromVal,
        toValue: toVal,
        ordervalue_online: enableOnline,
        ordervalue_offline: enableOffline,
        total_price: totalPrice,
        no_of_orders: clothOrderCount,
        carwash_orders: carwashCount,
        max_cloth_per_package: maxClothCount,
        validity: validity,
        status: status,
        enableAllAreas: enableAllAreas
      }
      console.log('data', AddData);
      PackageResponse = await createPackage(AddData);
      resMsg = PackageResponse.statusCode == 200 ? "Package Added" : "Unable to Add Package";
      setIsEdit(true);
    }

    dispatch(getPackagesList());
    if (PackageResponse.statusCode == 200) {
      toastr.success(resMsg, "Success");
    } else {
      toastr.error(resMsg, "Error");
    }
    togglePkgModal();
  }

  const dispatch = useDispatch();

  useEffect(() => {
    if (!email)
      return

    if (!validateEmail(email)) {
      setError({
        ...error,
        email: true
      })
    }
    else {
      setError({
        ...error,
        email: false
      })
    }
  }, [email])


  useEffect(async () => {
    if (PackageDetail?.areas?.length > 0) {
      // setAreaList([]);
      // setAreaVal(PackageDetails.areas)
      const AreaResponse = await postArea({});
      setAreaList(AreaResponse?.record);
    }
  }, [PackageDetail]);

  useEffect(() => {
    handleSearch(searchText);
  }, [searchText])


  const [fetchStatusTrue, setFetchStatusTrue] = useState(true)
  const [fetchStatusFalse, setFetchStatusFalse] = useState(true)

  const filterItem = (item) => {
    if (fetchStatusTrue && fetchStatusFalse) {
      return true
    } else if (fetchStatusTrue && !fetchStatusFalse) {
      return item.status
    } else if (fetchStatusFalse && !fetchStatusTrue) {
      return !item.status
    } else {
      return false
    }
  }

  const [selectedArea, setSelectedArea] = React.useState()
  const [areas, setAreas] = React.useState([])

  React.useEffect(async () => {
    const AreaResponse = await postArea({})
    setAreas(
      AreaResponse?.record?.map(item => {
        return { label: item?.name, value: item?._id, ...item }
      })
    )
    setAreaOption(
      AreaResponse?.record?.map((item) => {
        return { label: item?.name, value: item?._id }
      })
    )
  }, [])

  useEffect(() => {
    let _vat = myParseFloat(priceWithoutVat) * myParseFloat(vatPer) / 100;
    let _totalPrice = myParseFloat(priceWithoutVat) + _vat;
    setVat(_vat.toFixed(2));
    setTotalPrice(_totalPrice.toFixed(2));

  }, [vatPer])



  const getUploadParams = ({ file, meta }) => {

    // specify upload params and url for your files
    const data = new FormData();

    data.append('image', file);
    let response = {
      url: API_URL + 'packages/addimages',
      data,
      fields: { _id: packageId },
      headers: {
        "Authorization": getToken()
      }
    }
    console.log(response);
    return response;
  }

  const removeImage = (path, type) => {
    let images;
    if (type == "en") {
      images = imagesEn;
    }
    else {
      images = imagesAr;
    }

    const index = images.indexOf(path);
    if (index >= 0) {
      const _imgPath = [...images];
      _imgPath.splice(index, 1);
      if (type == "en") {
        setImagesEn(_imgPath);
      }
      else {
        setImagesAr(_imgPath);
      }
    }
  }
  // called every time a file's `status` changes
  const handleChangeStatus = ({ meta, file }, status, allFiles, lang) => {

    if (status == "uploading" || status == "started" || status == "restarted") {
      setImageUploading(true);
    }

    if (status == "done") {
      if (!highlight) {
        setHighlight(true);
      }

      allFiles.forEach(f => {
        if (f?.xhr?.status == 200) {
          const response = JSON.parse(f?.xhr?.response);
          if (response?.path) {
            let fn;
            if (lang == "en") {
              fn = setImagesEn;
            }
            else {
              fn = setImagesAr;
            }
            fn((prev) => {
              if (prev.indexOf(response?.path) === -1) {
                f.remove();
                setImageUploading(false);
                return [...prev, response?.path];
              }
            });
          }
        }

      });


    }
  }


  if (userData?.permissions?.allPermissions == true || userData?.permissions?.redeemPackages?.read == true) {
    return (
      <React.Fragment>
        <div className="page-content">
          <MetaTags>
            <title>Subscription Packages | Kleen Admin Panel</title>
          </MetaTags>
          <Container fluid>
            {/* Render Breadcrumb */}
            <Breadcrumbs
              title={props.t("Home")}
              breadcrumbItem={props.t("Subscription Packages")}
            />
            <Card>
              <CardBody>
                <Row className="mb-2">
                  <Col sm="6">
                    <div className="me-2 mb-2 d-inline-block">
                      <div className="position-relative d-flex">
                        <input
                          id="search-bar-10"
                          type="text"
                          aria-labelledby="search-bar-10-label"
                          className="form-control "
                          placeholder="Search"
                          autoComplete={false}
                          value={searchText}
                          onChange={(e) => setSearchText(e.target.value)}
                        />
                        <div className="ms-2" style={{ width: "20rem" }}>
                          <Select
                            placeholder="All Areas"
                            onChange={val => {
                              setSelectedArea(val)
                            }}
                            isClearable={true}
                            options={areas}
                          />
                        </div>
                      </div>
                    </div>
                  </Col>

                  <Col sm="6" className="text-end d-flex justify-content-between">
                    <div className="d-flex align-items-center">
                      <div className="d-flex align-items-center mx-3">
                        <span className={`me-2 ${fetchStatusTrue ? "text-success font-weight-semibold" : ""}`}>Show Enabled</span>
                        <div className="form-check form-switch form-switch-lg">
                          <input
                            type="checkbox"
                            className="form-check-input form-slider-color-green"
                            defaultChecked={fetchStatusTrue}
                            onChange={(e) => { setFetchStatusTrue(!fetchStatusTrue) }}
                          />
                        </div>
                      </div>
                      <div className="d-flex align-items-center">
                        <span className={`me-2 ${fetchStatusFalse ? "text-success font-weight-semibold" : ""}`}>Show Disabled</span>
                        <div className="form-check form-switch form-switch-lg">
                          <input
                            type="checkbox"
                            className="form-check-input form-slider-color-green"
                            defaultChecked={fetchStatusTrue}
                            onChange={(e) => { setFetchStatusFalse(!fetchStatusFalse) }}
                          />
                        </div>
                      </div>
                    </div>
                    {(userData?.permissions?.allPermissions == true || userData?.permissions?.subscriptionPackages?.create == true) ?
                      <Button
                        type="button"
                        color="success"
                        className="btn-rounded  "
                        onClick={() => openAddPackageModal()}
                      >
                        <i className="mdi mdi-plus me-1" />
                        Add Package
                      </Button>
                      : null
                    }
                  </Col>
                </Row>
                <Row>
                  <DataTable
                    columns={ListColumns}
                    data={listData.filter((item) => {
                      return filterItem(item)
                    }).filter(slot => {
                      if (!selectedArea) {
                        return true
                      }
                      return slot.areas
                        .map(area => area._id)
                        .includes(selectedArea?.value)
                    })}
                    pagination
                  />
                </Row>
              </CardBody>
            </Card>
            <div>
              <Modal
                size="lg"
                isOpen={pkgModal}
                toggle={() => {
                  togglePkgModal();
                }}
                id="subScriptionPkgModal"
              >
                <div className="modal-header">
                  <h5 className="modal-title mt-0" id="myModalLabel">
                    <span >{isEdit ? "Update Package" : "Create Package"}</span>
                  </h5>
                  <button
                    type="button"
                    onClick={() => {
                      SetPkgModal(false);
                    }}
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>

                <div className="modal-body pt-1">

                  <Row>
                    <Col sm="12">
                      <CardText className="mb-0">
                        <AvForm
                          onValidSubmit={(e, v) => {
                            handleValidSubmit(e, v)
                          }}
                        >
                          <div className="row mb-2">
                            <Col sm={6}>
                              <Label htmlFor="horizontal-firstname-Input" className="col-sm-4 col-form-label">
                                Name<span className="mandatory_feild">*</span>
                              </Label>
                              <AvField
                                type="text"
                                onChange={(e) => { setPackageName(e.target.value) }}
                                className="form-control"
                                id="horizontal-firstname-Input"
                                placeholder="Enter package name"
                                name="name"
                                errorMessage="Please Enter Package Name"
                                validate={{ required: { value: true } }}
                                value={packageName}
                                disabled={packageId ? true : false}
                              />
                            </Col>

                            <Col sm={6}>
                              <Label
                                htmlFor="horizontal-firstname-Input"
                                className="col-sm-6 col-form-label"
                              >Package Type <span className="mandatory_feild">*</span></Label>
                              <AvField
                                type="select"
                                onChange={(e) => { setPackageType(e.target.value) }}
                                className="form-control"
                                id="horizontal-firstname-Input"
                                placeholder="packageType"
                                name="packageType"
                                errorMessage="Please Select Package Type"
                                validate={{ required: { value: true } }}
                                value={packageType}
                                disabled={packageId ? true : false}
                              >
                                <option value="">Select Package Type</option>
                                <option value={PACKAGE_TYPES.ONLINE_PACKAGE}>Online Package (Cloth + Car + ClothCout)</option>
                              </AvField>
                            </Col>

                            <Col sm={6}>

                            </Col>
                          </div>

                          <div className="row mb-2">
                            <Col sm={6}>
                              <Label
                                htmlFor="horizontal-firstname-Input"
                                className=" col-form-label"
                              >Price (Without Vat). <span className="mandatory_feild">*</span></Label>
                              <InputGroup>
                                <AvField
                                  type="text"
                                  onChange={(e) => {
                                    let _priceWithoutVat = myParseFloat(e.target.value);
                                    setPriceWithoutVat(_priceWithoutVat)
                                    let _vat = myParseFloat(_priceWithoutVat) * myParseFloat(vatPer) / 100;
                                    _vat = myParseFloat(_vat.toFixed(2));
                                    let _totalPrice = myParseFloat(_priceWithoutVat) + _vat;
                                    setVat(_vat);
                                    setTotalPrice(_totalPrice);
                                  }}
                                  className="form-control"
                                  id="horizontal-firstname-Input"
                                  placeholder="Price Without Vat"
                                  name="priceWithoutVat"
                                  errorMessage="Please Enter Price without Vat"
                                  validate={{ required: { value: true } }}
                                  value={priceWithoutVat}
                                  disabled={packageId ? true : false}
                                />
                                <InputGroupText>SAR</InputGroupText>
                              </InputGroup>
                            </Col>

                            <Col sm={6}>
                              <Label
                                htmlFor="horizontal-firstname-Input"
                                className=" col-form-label"
                              >Vat Percentage <span className="mandatory_feild">*</span></Label>
                              <InputGroup>
                                <AvField
                                  type="text"
                                  onChange={(e) => { setVatPer(e.target.value) }}
                                  className="form-control"
                                  id="horizontal-firstname-Input"
                                  placeholder="Vat Percentage"
                                  name="vatPer"
                                  errorMessage="Please Enter Vat Percentage"
                                  validate={{ required: { value: true } }}
                                  value={vatPer}
                                  disabled={packageId ? true : false}
                                />
                                <InputGroupText>%</InputGroupText>
                              </InputGroup>
                            </Col>
                          </div>

                          <div className="row mb-2">
                            <Col sm={6}>
                              <Label
                                htmlFor="horizontal-firstname-Input"
                                className=" col-form-label"
                              >VAT. <span className="mandatory_feild">*</span></Label>
                              <InputGroup>
                                <AvField
                                  type="text"
                                  className="form-control"
                                  style={{ width: "100%" }}
                                  id="horizontal-firstname-Input"
                                  placeholder="Vat"
                                  name="vat"
                                  disabled
                                  errorMessage="Please Enter Package Value"
                                  value={vat}
                                />
                                <InputGroupText>SAR</InputGroupText>
                              </InputGroup>
                            </Col>
                            <Col sm={6}>
                              <Label
                                htmlFor="horizontal-firstname-Input"
                                className=" col-form-label"
                              >Total Price<span className="mandatory_feild">*</span></Label>
                              <InputGroup>
                                <AvField
                                  type="text"
                                  className="form-control"
                                  id="horizontal-firstname-Input"
                                  placeholder="Total Price"
                                  name="total_price"
                                  disabled={packageId ? true : false}
                                  onChange={(e) => {
                                    let _totalPrice = myParseFloat(e.target.value);
                                    setTotalPrice(_totalPrice);
                                    let _vat = myParseFloat(_totalPrice) * myParseFloat(vatPer) / (100 + myParseFloat(vatPer));
                                    _vat = myParseFloat(_vat.toFixed(2));
                                    let _priceWithoutVat = myParseFloat(_totalPrice) - _vat;
                                    setVat(_vat);
                                    setPriceWithoutVat(_priceWithoutVat);
                                  }}
                                  validate={{ required: { value: true } }}
                                  errorMessage="Please Enter Total Price"
                                  value={totalPrice}
                                />
                                <InputGroupText>SAR</InputGroupText>
                              </InputGroup>
                            </Col>
                          </div>


                          <div className="row mb-2">
                            <Col sm={6}>
                              <Label
                                htmlFor="horizontal-firstname-Input"
                                className=" col-form-label"
                              >Order Value Range (Form) <span className="mandatory_feild">*</span></Label>
                              <InputGroup>
                                <AvField
                                  type="text"
                                  className="form-control"
                                  style={{ width: "100%" }}
                                  id="horizontal-firstname-Input"
                                  placeholder="0"
                                  name="from"
                                  onChange={(e) => { setFromValue(e.target.value) }}
                                  validate={{ required: { value: packageId ? false : true } }}
                                  errorMessage="Please Enter From Order Value"
                                  value={fromVal}
                                  disabled={packageId ? true : false}
                                />
                                <InputGroupText>SAR</InputGroupText>
                              </InputGroup>
                              {error?.fromVal ? <span className="text-danger">{error?.fromVal}</span> : null}
                            </Col>
                            <Col sm={6}>
                              <Label
                                htmlFor="horizontal-firstname-Input"
                                className=" col-form-label"
                              >Order Value Range (To)<span className="mandatory_feild">*</span></Label>
                              <InputGroup>
                                <AvField
                                  type="text"
                                  className="form-control"
                                  id="horizontal-firstname-Input"
                                  placeholder="0"
                                  name="to"
                                  onChange={(e) => { setToValue(e.target.value) }}
                                  validate={{ required: { value: true } }}
                                  errorMessage="Please Enter To Order Value"
                                  value={toVal}
                                  disabled={packageId ? true : false}
                                />
                                <InputGroupText>SAR</InputGroupText>
                              </InputGroup>
                            </Col>
                          </div>
                          <div className="row mb-2">


                            <Col sm={6}>

                              <Label
                                htmlFor="horizontal-password-Input"
                                className="col-form-label"
                              >
                                Include Order Value For :  <span className="mandatory_feild">*</span>
                              </Label>
                              <div className="d-flex align-items-start">
                                <div className="col-6 form-check form-switch col-form-label mb-0 p-0 custom-toggle-sm">
                                  <span className="mt-2 me-3">Online Orders</span>
                                  <Switch
                                    uncheckedIcon={<Nosymbol />}
                                    checkedIcon={<YesSymbol />}
                                    className="me-1 mb-sm-8 mb-2"
                                    onColor="#626ed4"
                                    onChange={() => setOrderValOnline(!enableOnline)}
                                    checked={enableOnline}
                                    disabled={packageId ? true : false}
                                  />
                                </div>
                                <div className="col-6 form-check form-switch col-form-label mb-0 p-0 custom-toggle-sm">
                                  <span className="mt-2 me-3">Offline Orders</span>
                                  <Switch
                                    uncheckedIcon={<Nosymbol />}
                                    checkedIcon={<YesSymbol />}
                                    className="me-1 mb-sm-8 mb-2"
                                    onColor="#626ed4"
                                    onChange={() => setOrderValOffline(!enableOffline)}
                                    checked={enableOffline}
                                    disabled={packageId ? true : false}
                                  />
                                </div>
                              </div>
                            </Col>
                            <Col sm={6}>
                              <Label
                                htmlFor="horizontal-firstname-Input"
                                className=" col-form-label"
                              >Max Cloth Count / Package <span className="mandatory_feild">*</span></Label>
                              <AvField
                                type="text"
                                onChange={(e) => { setMaxClothCount(e.target.value) }}
                                className="form-control"
                                id="horizontal-firstname-Input"
                                placeholder="Max clothes per package"
                                name="max_cloth_per_package"
                                errorMessage="Please Enter Max Cloth Count"
                                validate={{ required: { value: true } }}
                                value={maxClothCount}
                                disabled={packageId ? true : false}
                              />

                            </Col>

                          </div>
                          <div className="row mb-2">
                            <Col sm={6}>
                              <Label
                                htmlFor="horizontal-firstname-Input"
                                className=" col-form-label"
                              >Cloth Orders <span className="mandatory_feild">*</span></Label>
                              <AvField
                                type="text"
                                onChange={(e) => { setClothOrderCount(e.target.value) }}
                                className="form-control"
                                id="horizontal-firstname-Input"
                                placeholder="No Of cloth orders"
                                name="no_of_orders"
                                errorMessage="Please Enter No of cloth orders"
                                validate={{ required: { value: true } }}
                                value={clothOrderCount}
                                disabled={packageId ? true : false}
                              />

                            </Col>

                            <Col sm={6}>
                              <Label
                                htmlFor="horizontal-firstname-Input"
                                className=" col-form-label"
                              >Carwash Orders <span className="mandatory_feild">*</span></Label>

                              <AvField
                                type="text"
                                onChange={(e) => { setCarwashCount(e.target.value) }}
                                className="form-control"
                                id="horizontal-firstname-Input"
                                placeholder="No. of carwash orders"
                                name="carwash_orders"
                                errorMessage="Please Enter No of carwash orders"
                                validate={{ required: { value: true } }}
                                value={carwashCount}
                                disabled={packageId ? true : false}
                              />

                            </Col>
                          </div>





                          <div className="row mb-2">


                            <Col sm={6}>
                              <div className="d-flex align-items-start">
                                <Label
                                  htmlFor="horizontal-password-Input"
                                  className="col-form-label"
                                >
                                  Areas <span className="mandatory_feild">*</span>
                                </Label>
                                <div className="col-sm-7 form-check form-switch col-form-label mb-0 pb-0 custom-toggle-sm">
                                  <span className="mt-2 me-3">Select All</span>
                                  <Switch
                                    uncheckedIcon={<Nosymbol />}
                                    checkedIcon={<YesSymbol />}
                                    className="me-1 mb-sm-8 mb-2"
                                    onColor="#626ed4"
                                    onChange={() => setEnableAllAreas(!enableAllAreas)}
                                    checked={enableAllAreas}
                                  />
                                </div>
                              </div>

                              <MultiSelect
                                defaultValue={areaVal.toString()}
                                className="multi-selects form-selects2 form-controlss areas-box"
                                onChange={handleOnchange}
                                options={areaOption}
                                disabled={enableAllAreas}
                              />
                              {
                                error?.area ? (
                                  <label style={{ color: "red" }}>{error?.area}</label>
                                ) : null
                              }
                            </Col>

                            <Col sm={6}>
                              <Label
                                htmlFor="horizontal-firstname-Input"
                                className=" col-form-label"
                              >Validity <span className="mandatory_feild">*</span></Label>
                              <InputGroup>
                                <AvField
                                  type="text"
                                  onChange={(e) => { setValidity(e.target.value) }}
                                  className="form-control"
                                  id="horizontal-firstname-Input"
                                  placeholder="Validity"
                                  name="validity"
                                  errorMessage="Please Enter Validity"
                                  validate={{ required: { value: true } }}
                                  value={validity}
                                  disabled={packageId ? true : false}
                                />
                                <InputGroupText>Days</InputGroupText>
                              </InputGroup>
                            </Col>
                          </div>

                          <div className="row mb-2">

                            <Col sm={6} className="mt-2">
                              <Label
                                htmlFor="horizontal-password-Input"
                                className="col-form-label col-md-3"
                              >
                                Status
                              </Label>
                              <div className="form-check form-switch ps-0">
                                <Switch
                                  uncheckedIcon={<Offsymbol />}
                                  checkedIcon={<OnSymbol />}
                                  className="me-1 mb-sm-8 mb-2"
                                  onColor="#626ed4"
                                  onChange={(e) => setStatus(!status)}
                                  checked={status}
                                />
                                {/* <AvField
                                  name="status"
                                  type="checkbox"
                                  className="form-check-input"
                                  id="customSwitchsizelg horizontal-status-Input"
                                  defaultChecked={status}
                                  onChange={(e) => { setStatus(e.target.checked) }}
                                /> */}
                              </div>
                            </Col>
                          </div>

                          <Row className="mb-2">
                            <Col>
                              <Label>Images (En): </Label>
                              <br />
                              <span className="text-warning">Maximum image size : <strong>512kb</strong>. Maximum 4 images can be added.</span>
                              {imagesEn.length < 4 ?
                                <Dropzone
                                  getUploadParams={getUploadParams}
                                  onChangeStatus={(obj, status, allFiles) => handleChangeStatus(obj, status, allFiles, "en")}
                                  accept="image/*"
                                  maxSizeBytes={512 * 1024}
                                  maxFiles={4}
                                /> :
                                <div>
                                  <h4 className="text-warning">Maximum 4 files can uploaded</h4>
                                </div>}
                            </Col>
                          </Row>


                          <Row>

                            {
                              imagesEn && imagesEn?.map((path, index) => (
                                <Col key={index} lg={3}>
                                  <div className={`b2b-image ${(highlight && index == imagesEn.length - 1) ? 'added' : ''}`}>
                                    <div>
                                      <img src={path} style={{ height: 100 }} />
                                      <Button
                                        type="button"
                                        color="link"
                                        onClick={() => removeImage(path, "en")}
                                        className="text-danger w-100 p-0 pt-2">
                                        <i className="bx bx-trash-alt" style={{ fontSize: 20 }}></i>
                                      </Button>
                                    </div>

                                  </div>
                                </Col>
                              ))
                            }

                          </Row>


                          <Row className="mb-2 mt-2">
                            <Col>
                              <Label>Images (Ar): </Label>
                              <br />
                              <span className="text-warning">Maximum image size : <strong>512kb</strong></span>

                              <Dropzone
                                getUploadParams={getUploadParams}
                                onChangeStatus={(obj, status, allFiles) => handleChangeStatus(obj, status, allFiles, "ar")}
                                accept="image/*"
                                maxSizeBytes={512 * 1024}
                                maxFiles={4}
                              />
                            </Col>
                          </Row>


                          <Row>

                            {
                              imagesAr && imagesAr?.map((path, index) => (
                                <Col key={index} lg={3}>
                                  <div className={`b2b-image ${(highlight && index == imagesAr.length - 1) ? 'added' : ''}`}>
                                    <div>
                                      <img src={path} style={{ height: 100 }} />
                                      <Button
                                        type="button"
                                        color="link"
                                        onClick={() => removeImage(path, "ar")}
                                        className="text-danger w-100 p-0 pt-2">
                                        <i className="bx bx-trash-alt" style={{ fontSize: 20 }}></i>
                                      </Button>
                                    </div>

                                  </div>
                                </Col>
                              ))
                            }

                          </Row>

                          <div className="row justify-content-end">
                            <Col sm={6} className="col-sm-6 text-end">
                              <div>
                                <Button
                                  type="submit"
                                  color="primary"
                                >
                                  {isEdit ? "Update Package" : "Add Package"}
                                </Button>

                                <button
                                  type="button"
                                  onClick={() => {
                                    togglePkgModal();
                                  }}
                                  className="m-1 btn btn-secondary"
                                  data-dismiss="modal"
                                >
                                  Close
                                </button>

                              </div>
                            </Col>
                          </div>

                        </AvForm>
                      </CardText>
                    </Col>
                  </Row>

                </div>
              </Modal>


            </div>
          </Container>
        </div>

      </React.Fragment>
    )
  } else {

    return <Pages404 />
  }

}
subscriptionPackage.propTypes = {
  t: PropTypes.any
}

export default withTranslation()(subscriptionPackage)