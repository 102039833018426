
export const API_SUCCESS = "API_SUCCESS";
export const API_FAIL = "API_FAIL";

// VIEW REDEEM PACKAGES  
export const GET_HOLIDAY_LIST = "GET_HOLIDAY_LIST";
export const GET_HOLIDAY_LIST_SUCCESS = "GET_HOLIDAY_LIST_SUCCESS";
export const GET_HOLIDAY_LIST_FAIL = "GET_HOLIDAY_LIST_FAIL";
export const GET_HOLIDAY_DETAIL = "GET_HOLIDAY_DETAIL";
export const GET_HOLIDAY_DETAIL_SUCCESS = "GET_HOLIDAY_DETAIL_SUCCESS";
export const GET_HOLIDAY_DETAIL_FAIL = "GET_HOLIDAY_DETAIL_FAIL";