import React, { useEffect, useState } from "react"
import { Row, Col, Card, CardBody, CardTitle } from "reactstrap"
import { Modal, Input, Label } from "reactstrap"
import {
  Button,
  Nav,
  NavItem,
  NavLink,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  ButtonDropdown,
} from "reactstrap";
import MetaTags from 'react-meta-tags'
import { useHistory, Link } from "react-router-dom"
import classnames from "classnames"

// datatable related plugins
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory, {
  PaginationProvider, PaginationListStandalone,
  SizePerPageDropdownStandalone
} from 'react-bootstrap-table2-paginator';

import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"
import "../../assets/scss/datatables.scss"
import { useSelector, useDispatch } from "react-redux"
import { getLaundrys } from "../../store/laundry/actions"
import { getCouponlist, getPricelist, getArealist, getB2bCustomer, getClothlist, addPriceList, deletePriceList, updatePriceList, searchUsers, addCoupon, updateCoupon } from "../../store/actions"
import Switch from "react-switch"
import Pages404 from "pages/Utility/pages-404";
const Offsymbol = () => {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100%",
        fontSize: 12,
        color: "#fff",
        paddingRight: 2
      }}
    >
      {" "}
      Off
    </div>
  )
}

const OnSymbol = () => {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100%",
        fontSize: 12,
        color: "#fff",
        paddingRight: 2
      }}
    >
      {" "}
      On
    </div>
  )
}

const LaundryCoupon = () => {
  const dispatch = useDispatch();
  const history = useHistory();


  const { priceData, areaList, laundryList, clothList, customersList, users, coupons } = useSelector(state => ({
    priceData: state.ecommerce.pricelist,
    clothList: state.ecommerce.clothlist,
    areaList: state.ecommerce.arealist,
    customersList: state.ecommerce.b2bCustomers,
    laundryList: state.Laundry.LaundryList,
    users: state.Users.users,
    coupons: state.Coupon.coupons
  }))

  const [tableDataList, setTableDataList] = useState([])
  const [areas, SetAreas] = useState([])
  const [customers, SetCustomers] = useState([])
  const [laundries, SetLaundries] = useState([])

  const [activeTab1, setactiveTab1] = useState("laundry")
  const [addType, setAddType] = useState("laundry")
  const [addArea, setAddArea] = useState("")
  const [addLaundry, setAddLaundry] = useState("")
  const [addCloth, setAddCloth] = useState("")
  const [drycleanPrice, setDrycleanPrice] = useState("")
  const [washPrice, setWashPrice] = useState("")
  const [ironPrice, setIronPrice] = useState("")
  const [addStatus, setAddStatus] = useState(false)
  const [addModal, setAddModal] = useState(false)
  const [deleteConfirm, setDeleteConfirmModal] = useState(false)
  const [deletePriceData, setDeletePriceData] = useState({})
  const [error, setError] = useState({})
  const [errorFor, setErrorFor] = useState()
  const [laundry, setLaundry] = useState("");
  const [area, setArea] = useState("");
  const [isEdit, setIsEdit] = useState(false);
  const [EditData, setEditData] = useState({});
  const [selectPriceList, setSelectPriceList] = useState({});
  const [priceListData, setPriceListData] = useState([])
  const [addPriceListData, setAddPriceListData] = useState([])
  const [userData, setUserData] = useState({});

  useEffect(() => {
    // dispatch(getPricelist({ web: true }));
    if(!users) dispatch(searchUsers({ webPermissions: true }))
    dispatch(getLaundrys({ status: true }));
    // dispatch(getArealist({ status: true }));
    // dispatch(getB2bCustomer({ status: true }));
    // dispatch(getClothlist({}))
  }, [])

  useEffect(() => {
    setPriceListData(coupons)
    setAddPriceListData([]);
  }, [coupons])

  const handleEditPrice = (row) => {
    let apiData = { [activeTab1]: row.value }
    setSelectPriceList(row);
    dispatch(getCouponlist(apiData));
    setAddModal(true);
    // history.push('/update-price-list', { priceType: activeTab1, data: row });

  }

  useEffect(() => {
    SetAreas(areaList.map((item) => {
      return { label: item.name, value: item._id }
    }));
  }, [areaList])

  useEffect(() => {
    if (users.length > 0) {
      setUserData(users[0]);
    }
  }, [users])

  useEffect(() => {
    SetCustomers(customersList.map((item) => {
      return { label: item.nameEn, value: item._id }
    }));
  }, [customersList])

  useEffect(() => {
    SetLaundries(laundryList.map((item) => {
      return { label: item.nameEn, value: item._id }
    }));
  }, [laundryList])

  const handleDeletePriceList = (item) => {
    setDeletePriceData(item);
    setDeleteConfirmModal(true)
  }

  const handleEditPricelist = (data) => {
    setIsEdit(true)
    setEditData(data)
    setAddModal(true)
    setAddType(data?.laundry ? "laundry" : "area")
    setAddArea(data?.area?._id)
    setAddLaundry(data?.laundry?._id)
    setAddCloth(data?.cloth?._id)
    setDrycleanPrice(data?.dPrice)
    setWashPrice(data?.wPrice)
    setIronPrice(data?.iPrice)
    setAddStatus(data?.status)
  }

  const deletePricelistData = () => {
    const apiData = {
      _id: deletePriceData?._id
    }
    dispatch(deletePriceList(apiData))
    setDeleteConfirmModal(false)
  }

  const columns = [
    //   {
    //   dataField: 'id',
    //   text: 'Id',
    //   sort: true,
    // },
    {
      dataField: 'label',
      classes: "reason-wrap",
      text: 'Name',
      sort: false
    },
    {
      dataField: '',
      text: 'Action',
      sort: false,
      formatter: (cellContent, row) => {
        return (
          <button
            className="btn btn-primary btn-sm"
            onClick={() => handleEditPrice(row)}
          >
            View
          </button>
          // <div className="gap-3">
          //   <Link
          //     to={{
          //       pathname: "/update-price-list",
          //       state: { rowObject: "row", activeTab: "activeTab1" }
          //     }}
          //     target="_blank"
          //     className="btn btn-primary btn-sm"
          //   // onClick={() => handleEditPrice(row)}
          //   >
          //     View
          //   </Link>
          //   <Link
          //     to="#"
          //     className="text-danger"
          //     onClick={() => handleDeletePriceList(row)}
          //   >
          //     <i className="mdi mdi-delete font-size-18" id="deletetooltip" />
          //   </Link>
          // </div>
        )
      }
    }];

  const defaultSorted = [{
    dataField: 'salary',
    order: 'asc'
  }];

  const pageOptions = {
    sizePerPage: 10,
    totalSize: tableDataList.length, // replace later with size(customers),
    custom: true,
  }
  // Custom Pagination Toggle
  const sizePerPageList = [
    { text: '5', value: 5 },
    { text: '10', value: 10 },
    { text: '15', value: 15 },
    { text: '20', value: 20 },
    { text: '25', value: 25 },
    { text: 'All', value: (tableDataList).length }];

  const handleTableDataFilter = () => {
    if (area || laundry) {
      let filterData = priceData.filter((item) => (item?.area?._id === area || item?.laundry?._id === laundry))
      setTableDataList(filterData);
    } else {
      setTableDataList(priceData);
    }
  }

  useEffect(() => {
    if (!addModal) {
      setError({});
      setIronPrice("")
      setWashPrice("")
      setDrycleanPrice("")
      setAddCloth("")
      setAddLaundry("")
      setAddArea("")
      setAddStatus(false)
      setAddType("laundry")
      setIsEdit(false)
      setEditData({})
      setAddPriceListData([])
    }
  }, [addModal])

  useEffect(() => {
    // setAddLaundry("")
    // setAddArea("")
  }, [addType])

  const editPricelistData = (SaveID, oldDataIndex) => {
    let oldData = [...priceListData]
    // let errorExits = false;
    // let allErrors = {}
    // if (!oldData[oldDataIndex].payAmt) {
    //   allErrors['payAmt'] = "Payment Amount is required";
    //   errorExits = true;
    // }
    // if (oldData[oldDataIndex].payAmt < 1) {
    //   allErrors['payAmt'] = "Please enter valid Payment amount";
    //   errorExits = true;
    // }
    // if (!oldData[oldDataIndex].getAmt) {
    //   allErrors['getAmt'] = "Get Amount is required";
    //   errorExits = true;
    // }
    // if (oldData[oldDataIndex].getAmt < 1) {
    //   allErrors['getAmt'] = "Please enter valid Get amount";
    //   errorExits = true;
    // }
    // setError(allErrors)
    // if (errorExits) {
    //   setErrorFor(SaveID)
    //   return;
    // }
    // setError({});
    // let oldDataIndex = oldData.findIndex((item) => item._id === SaveID)

    const apiData = {
      _id: SaveID,
      // payAmt: oldData[oldDataIndex].payAmt,
      // getAmt: oldData[oldDataIndex].getAmt,
      status: oldData[oldDataIndex].status,
      // web: true
    }
    // console.log("apiData", apiData);
    // return;
    dispatch(updateCoupon(apiData));
    // setTimeout(() => {
    //   setAddModal(false)
    // }, 500);
  }

  const onaddPriceList = (oldDataIndex) => {
    let oldData = [...addPriceListData]
    let errorExits = false;
    let allErrors = {}
    if (!oldData[oldDataIndex].payAmt) {
      allErrors['payAmt'] = "Payment Amount is required";
      errorExits = true;
    }
    if (oldData[oldDataIndex].payAmt < 1) {
      allErrors['payAmt'] = "Please enter valid Payment amount";
      errorExits = true;
    }
    if (!oldData[oldDataIndex].getAmt) {
      allErrors['getAmt'] = "Get Amount is required";
      errorExits = true;
    }
    if (oldData[oldDataIndex].getAmt < 1) {
      allErrors['getAmt'] = "Please enter valid Get amount";
      errorExits = true;
    }
    setError(allErrors)
    setErrorFor(oldDataIndex)
    if (errorExits) {
      return;
    }
    // setError({});
    // let oldDataIndex = oldData.findIndex((item) => item._id === SaveID)

    const apiData = oldData[oldDataIndex]
    // console.log("apiData", apiData);
    // return;
    dispatch(addCoupon(apiData));
    // setTimeout(() => {
    //   setAddModal(false)
    // }, 500);
  }

  const handleChangePrice = (val, oldDataIndex, changeOf) => {
    let oldData = [...priceListData]
    // let oldDataIndex = oldData.findIndex((item) => item._id === itemID)
    oldData[oldDataIndex][changeOf] = val;
    setPriceListData(oldData);
  }

  const handlePriceStatus = (val, oldDataIndex) => {
    let oldData = [...priceListData]
    // let oldDataIndex = oldData.findIndex((item) => item._id === itemID)
    oldData[oldDataIndex].status = val;
    setPriceListData(oldData);
  }

  const handleChangeCloth = (val, oldDataIndex) => {
    let oldData = [...priceListData]
    // let oldDataIndex = oldData.findIndex((item) => item._id === itemID)
    oldData[oldDataIndex].cloth = { _id: val };
    setPriceListData(oldData);
  }

  const handleAddChangePrice = (val, itemID, changeOf) => {
    let oldData = [...addPriceListData]
    oldData[itemID][changeOf] = val;
    setAddPriceListData(oldData);
  }

  const handleAddPriceStatus = (val, itemID) => {
    let oldData = [...addPriceListData]
    oldData[itemID].status = val;
    setAddPriceListData(oldData);
  }

  const handleAddChangeCloth = (val, itemID) => {
    let oldData = [...addPriceListData]
    oldData[itemID].cloth = { _id: val };
    setAddPriceListData(oldData);
  }

  const handleAddCloth = () => {
    if (addPriceListData?.length) {
      return
    }
    let oldAddData = [...addPriceListData]
    oldAddData.push({
      laundry: selectPriceList.value,
      status: true,
      payAmt: "",
      getAmt: "",
    })
    setAddPriceListData(oldAddData);
  }

  if (userData?.permissions?.allPermissions != true && userData?.permissions?.laundryCoupons?.read != true) {
    return <Pages404 />
  }
  return (

    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Laundry Coupon / Kleen admin</title>
        </MetaTags>
        <div className="container-fluid">
          <Breadcrumbs title="Home" breadcrumbItem="Laundry Coupon" />

          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>
                  {/* <CardTitle className="h4">Default Datatable </CardTitle>
                  <p className="card-title-desc">
                    react-bootstrap-table-next plugin has most features enabled by default,
                    so all you need to do to use it with your own tables is to
                    call the construction function:{" "}
                    <code>react-bootstrap-table-next </code>.
                  </p> */}



                  {/* 
                  <Row className="mb-2 border-bottom2121 d-flex mb-2">
                    <Col lg="3">
                      <Nav pills className="navtab-bg nav-justified">
                        <NavItem>
                          <NavLink
                            style={{ cursor: "pointer" }}
                            className={classnames("border", {
                              active: activeTab1 === "laundry",
                            })}
                            onClick={() => {
                              setactiveTab1("laundry")
                            }}
                          >
                            Laundry
                          </NavLink>
                        </NavItem>
                        <NavItem>
                          <NavLink
                            style={{ cursor: "pointer" }}
                            className={classnames("border", {
                              active: activeTab1 === "area",
                            })}
                            onClick={() => {
                              setactiveTab1("area")
                            }}
                          >
                            Area
                          </NavLink>
                        </NavItem>
                        <NavItem>
                          <NavLink
                            style={{ cursor: "pointer" }}
                            className={classnames("border", {
                              active: activeTab1 === "b2bCustomer",
                            })}
                            onClick={() => {
                              setactiveTab1("b2bCustomer")
                            }}
                          >
                            B2B
                          </NavLink>
                        </NavItem>
                      </Nav>
                    </Col>

                  </Row> */}

                  {activeTab1 == "area" ?
                    <PaginationProvider
                      pagination={paginationFactory({
                        sizePerPage: 10,
                        totalSize: areas.length, // replace later with size(customers),
                        custom: true,
                      })}
                      keyField='id'
                      columns={columns}
                      data={areas}
                    >
                      {({ paginationProps, paginationTableProps }) => (
                        <ToolkitProvider
                          keyField='id'
                          columns={columns}
                          data={areas}
                          search
                        >

                          {toolkitProps => (
                            <React.Fragment>
                              <Row>

                                <Col xl="12">
                                  <div className="table-responsive">
                                    <BootstrapTable
                                      keyField={"id"}
                                      responsive
                                      bordered={false}
                                      striped={false}
                                      defaultSorted={defaultSorted}
                                      // selectRow={selectRow}
                                      classes={
                                        "table align-middle table-nowrap"
                                      }
                                      headerWrapperClasses={"thead-light"}
                                      {...toolkitProps.baseProps}
                                      {...paginationTableProps}
                                    />

                                  </div>
                                </Col>
                              </Row>

                              <Row className="align-items-md-center mt-30">
                                <Col className="inner-custom-pagination d-flex">
                                  <div className="d-inline">
                                    <SizePerPageDropdownStandalone
                                      {...paginationProps}
                                    />
                                  </div>
                                  <div className="text-md-right ms-auto">
                                    <PaginationListStandalone
                                      {...paginationProps}
                                    />
                                  </div>
                                </Col>
                              </Row>
                            </React.Fragment>
                          )
                          }
                        </ToolkitProvider>
                      )
                      }</PaginationProvider> : null}


                  {activeTab1 == "b2bCustomer" ?
                    <PaginationProvider
                      pagination={paginationFactory({
                        sizePerPage: 10,
                        totalSize: customers.length, // replace later with size(customers),
                        custom: true,
                      })}
                      keyField='id'
                      columns={columns}
                      data={customers}
                    >
                      {({ paginationProps, paginationTableProps }) => (
                        <ToolkitProvider
                          keyField='id'
                          columns={columns}
                          data={customers}
                          search
                        >

                          {toolkitProps => (
                            <React.Fragment>
                              <Row>

                                <Col xl="12">
                                  <div className="table-responsive">
                                    <BootstrapTable
                                      keyField={"id"}
                                      responsive
                                      bordered={false}
                                      striped={false}
                                      defaultSorted={defaultSorted}
                                      // selectRow={selectRow}
                                      classes={
                                        "table align-middle table-nowrap"
                                      }
                                      headerWrapperClasses={"thead-light"}
                                      {...toolkitProps.baseProps}
                                      {...paginationTableProps}
                                    />

                                  </div>
                                </Col>
                              </Row>

                              <Row className="align-items-md-center mt-30">
                                <Col className="inner-custom-pagination d-flex">
                                  <div className="d-inline">
                                    <SizePerPageDropdownStandalone
                                      {...paginationProps}
                                    />
                                  </div>
                                  <div className="text-md-right ms-auto">
                                    <PaginationListStandalone
                                      {...paginationProps}
                                    />
                                  </div>
                                </Col>
                              </Row>
                            </React.Fragment>
                          )
                          }
                        </ToolkitProvider>
                      )
                      }</PaginationProvider> : null}


                  {activeTab1 == "laundry" ?
                    <PaginationProvider
                      pagination={paginationFactory({
                        sizePerPage: 10,
                        totalSize: laundries.length, // replace later with size(customers),
                        custom: true,
                      })}
                      keyField='id'
                      columns={columns}
                      data={laundries}
                    >
                      {({ paginationProps, paginationTableProps }) => (
                        <ToolkitProvider
                          keyField='id'
                          columns={columns}
                          data={laundries}
                          search
                        >

                          {toolkitProps => (
                            <React.Fragment>
                              <Row>

                                <Col xl="12">
                                  <div className="table-responsive">
                                    <BootstrapTable
                                      keyField={"id"}
                                      responsive
                                      bordered={false}
                                      striped={false}
                                      defaultSorted={defaultSorted}
                                      // selectRow={selectRow}
                                      classes={
                                        "table align-middle table-nowrap"
                                      }
                                      headerWrapperClasses={"thead-light"}
                                      {...toolkitProps.baseProps}
                                      {...paginationTableProps}
                                    />

                                  </div>
                                </Col>
                              </Row>

                              <Row className="align-items-md-center mt-30">
                                <Col className="inner-custom-pagination d-flex">
                                  <div className="d-inline">
                                    <SizePerPageDropdownStandalone
                                      {...paginationProps}
                                    />
                                  </div>
                                  <div className="text-md-right ms-auto">
                                    <PaginationListStandalone
                                      {...paginationProps}
                                    />
                                  </div>
                                </Col>
                              </Row>
                            </React.Fragment>
                          )
                          }
                        </ToolkitProvider>
                      )
                      }</PaginationProvider> : null}


                </CardBody>
              </Card>
            </Col>
          </Row>
          <Modal
            size="lg"
            isOpen={addModal}
            toggle={() => setAddModal(!addModal)}
            centered
          >
            <div className="modal-header">
              <h2 className="modal-title mt-0" id="myModalLabel">
                <span>
                  Coupon List - {selectPriceList.label}
                </span>
              </h2>
              <button
                type="button"
                onClick={() => {
                  setAddModal(false);
                }}
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <table className="table table-border">
                <thead>
                  <tr>
                    {/* <th>Cloth</th>
                    <th>Iron</th>
                    <th>Wash</th>
                    <th>Dry Clean</th>
                    <th>Exp Iron</th> */}
                    <th>Pay Amount</th>
                    <th>Get Amount</th>
                    <th>Status</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {
                    priceListData.map((item, index) => {
                      return (
                        <tr key={item?._id}>
                          {/* <td>
                            <select value={item?.cloth?._id} onChange={(e) => handleChangeCloth(e.target.value, index)} className="form-select">
                              <option value={""}>Select Cloth</option>
                              {
                                clothList?.map((item1, index) => {
                                  return (
                                    <option key={index} value={item1?._id}>{item1?.nameEn}</option>
                                  )
                                })
                              }
                            </select>
                          </td> */}
                          {/* <td>
                            <Input
                              name="Iron"
                              type="number"
                              className="form-control"
                              placeholder="Iron price"
                              value={item.iPrice ? item.iPrice : 0}
                              onChange={(val) => handleChangePrice(val.target.value, index, "iPrice")}
                            />
                          </td> */}
                          {/* <td>
                            <Input
                              name="wash"
                              type="number"
                              className="form-control"
                              placeholder="Wash price"
                              value={item.wPrice ? item.wPrice : 0}
                              onChange={(val) => handleChangePrice(val.target.value, index, "wPrice")}
                            />
                          </td> */}
                          {/* <td>
                            <Input
                              name="dryClean"
                              type="number"
                              className="form-control"
                              placeholder="Dry clean Price"
                              value={item.dPrice ? item.dPrice : 0}
                              onChange={(val) => handleChangePrice(val.target.value, index, "dPrice")}
                            />
                          </td> */}
                          {/* <td>
                            <Input
                              name="expIron"
                              type="number"
                              className="form-control"
                              placeholder="Iron Express price"
                              value={item?.iExpressPrice ? item.iExpressPrice : 0}
                              onChange={(val) => handleChangePrice(val.target.value, index, "iExpressPrice")}
                            />
                          </td> */}
                          <td>
                            <Input
                              disabled
                              name="payAmt"
                              type="number"
                              className="form-control"
                              placeholder="Pay Amount"
                              value={item?.payAmt ? item.payAmt : 0}
                              onChange={(val) => handleChangePrice(val.target.value, index, "payAmt")}
                            />
                            {
                              error?.payAmt && errorFor === item?._id ? (
                                <span style={{ color: "red" }}>{error?.payAmt}</span>
                              ) : null
                            }
                          </td>
                          <td>
                            <Input
                              disabled
                              name="getAmt"
                              type="number"
                              className="form-control"
                              placeholder="Get Amount"
                              value={item?.getAmt ? item.getAmt : 0}
                              onChange={(val) => handleChangePrice(val.target.value, index, "getAmt")}
                            />
                            {
                              error?.getAmt && errorFor === item?._id ? (
                                <span style={{ color: "red" }}>{error?.getAmt}</span>
                              ) : null
                            }
                          </td>
                          <td>
                            <Switch
                              uncheckedIcon={<Offsymbol />}
                              checkedIcon={<OnSymbol />}
                              name="status"
                              className="me-1 mb-sm-8 mb-2"
                              onColor="#626ed4"
                              onChange={(e) => handlePriceStatus(!item?.status, index)}
                              checked={item?.status}
                            />

                          </td>
                          <td>
                            {
                              userData?.permissions?.allPermissions == true || userData?.permissions?.laundryCoupons?.update == true ? (
                                <Button
                                  type="submit"
                                  color="primary"
                                  className="w-md"
                                  onClick={() => editPricelistData(item?._id, index)}
                                >
                                  Save
                                </Button>
                              ) : null
                            }
                          </td>
                        </tr>
                      )
                    })
                  }
                  {
                    addPriceListData?.map((item, index) => {
                      return (
                        <tr key={index}>
                          <td>
                            <Input
                              name="payAmt"
                              type="number"
                              className="form-control"
                              placeholder="Pay Amount"
                              value={item?.payAmt}
                              onChange={(val) => handleAddChangePrice(val.target.value, index, "payAmt")}
                            />
                            {
                              error?.payAmt && errorFor === index ? (
                                <span style={{ color: "red" }}>{error?.payAmt}</span>
                              ) : null
                            }
                          </td>
                          <td>
                            <Input
                              name="getAmt"
                              type="number"
                              className="form-control"
                              placeholder="Get Amount"
                              value={item?.getAmt}
                              onChange={(val) => handleAddChangePrice(val.target.value, index, "getAmt")}
                            />
                            {
                              error?.getAmt && errorFor === index ? (
                                <span style={{ color: "red" }}>{error?.getAmt}</span>
                              ) : null
                            }
                          </td>
                          <td>
                            <Switch
                              uncheckedIcon={<Offsymbol />}
                              checkedIcon={<OnSymbol />}
                              name="status"
                              className="me-1 mb-sm-8 mb-2"
                              onColor="#626ed4"
                              onChange={(e) => handleAddPriceStatus(!item?.status, index)}
                              checked={item?.status}
                            />

                          </td>
                          <td>
                            <Button
                              type="submit"
                              color="primary"
                              className="w-md"
                              onClick={() => onaddPriceList(index)}
                            >
                              Save
                            </Button>
                          </td>
                        </tr>
                      )
                    })
                  }
                </tbody>
              </table>
              {
                userData?.permissions?.allPermissions == true || userData?.permissions?.laundryCoupons?.create == true ? (
                  <Button
                    disabled={addPriceListData?.length ? true : false}
                    type="submit"
                    color={addPriceListData?.length ? "secondary" : "primary"}
                    className="w-md"
                    onClick={() => handleAddCloth()}
                  >
                    Add Coupon
                  </Button>
                ) : null
              }
              {/* <Row>
                <Col lg="12">
                  <div className="mb-3">
                    <Label>Select Cloth</Label>
                    <select defaultValue={addCloth} onChange={(e) => setAddCloth(e.target.value)} className="form-select">
                      <option value={""}>Select Cloth</option>
                      {
                        clothList?.map((item, index) => {
                          return (
                            <option key={index} value={item?._id}>{item?.nameEn}</option>
                          )
                        })
                      }
                    </select>
                    {
                      error?.cloth ? (
                        <Label style={{ color: "red" }}>{error.cloth}</Label>
                      ) : null
                    }
                  </div>
                </Col>
                <Col lg="12">
                  <div className="mb-3">
                    <Label>Select Price Type</Label>
                    <select disabled={isEdit} defaultValue={addType} onChange={(e) => setAddType(e.target.value)} className="form-select">
                      <option value="laundry">Laundry</option>
                      <option value="area">Area</option>
                    </select>
                    {
                      error?.laundry ? (
                        <Label style={{ color: "red" }}>{error.laundry}</Label>
                      ) : null
                    }
                  </div>
                </Col>
                {
                  addType == "laundry" ? (
                    <Col lg="12">
                      <div className="mb-3">
                        <Label>Select Laundry</Label>
                        <select disabled={isEdit} defaultValue={addLaundry} onChange={(e) => setAddLaundry(e.target.value)} className="form-select">
                          <option value={""}>Select Laundry</option>
                          {
                            laundryList?.map((item, index) => {
                              return (
                                <option key={index} value={item?._id}>{item?.nameEn}</option>
                              )
                            })
                          }
                        </select>
                        {
                          error?.laundry ? (
                            <Label style={{ color: "red" }}>{error.laundry}</Label>
                          ) : null
                        }
                      </div>
                    </Col>
                  ) : (
                    <Col lg="12">
                      <div className="mb-3">
                        <Label>Select Area</Label>
                        <select disabled={isEdit} defaultValue={addArea} onChange={(e) => setAddArea(e.target.value)} className="form-select">
                          <option value={""}>Select Area</option>
                          {
                            areaList?.map((item, index) => {
                              return (
                                <option key={index} value={item?._id}>{item?.name}</option>
                              )
                            })
                          }
                        </select>
                        {
                          error?.area ? (
                            <Label style={{ color: "red" }}>{error.area}</Label>
                          ) : null
                        }
                      </div>
                    </Col>
                  )
                }
                <Col lg="12">
                  <div className="mb-3">
                    <Label>Dry Clean Price</Label>
                    <Input
                      name="dryClean"
                      type="number"
                      className="form-control"
                      placeholder="Enter Dry clean Price"
                      value={drycleanPrice}
                      onChange={(val) => setDrycleanPrice(val.target.value)}
                    />
                    {
                      error?.dryClean ? (
                        <Label style={{ color: "red" }}>{error.dryClean}</Label>
                      ) : null
                    }
                  </div>
                </Col>
                <Col lg="12">
                  <div className="mb-3">
                    <Label>Wash Price</Label>
                    <Input
                      name="wash"
                      type="number"
                      className="form-control"
                      placeholder="Enter Wash price"
                      value={washPrice}
                      onChange={(val) => setWashPrice(val.target.value)}
                    />
                    {
                      error?.wash ? (
                        <Label style={{ color: "red" }}>{error.wash}</Label>
                      ) : null
                    }
                  </div>
                </Col>
                <Col lg="12">
                  <div className="mb-3">
                    <Label>Iron Price</Label>
                    <Input
                      name="wash"
                      type="number"
                      className="form-control"
                      placeholder="Enter Iron price"
                      value={ironPrice}
                      onChange={(val) => setIronPrice(val.target.value)}
                    />
                    {
                      error?.iron ? (
                        <Label style={{ color: "red" }}>{error.iron}</Label>
                      ) : null
                    }
                  </div>
                </Col>
                <Col lg="12">
                  <div className="mb-3">
                    <Input
                      name="status"
                      type="checkbox"
                      checked={addStatus}
                      // className="form-control"
                      // placeholder="Enter Iron price"
                      value={addStatus}
                      onChange={() => setAddStatus(!addStatus)}
                    /> <Label htmlFor="status">Status</Label>
                  </div>
                </Col>
                <Col lg="12">
                  <Button
                    type="submit"
                    color="primary"
                    className="w-md"
                    onClick={isEdit ? editPricelistData : onaddPriceList}
                  >
                    {isEdit ? "Update Price" : "Add Price"}
                  </Button>
                </Col>
              </Row> */}
            </div>

          </Modal>
          <Modal
            size="md"
            isOpen={deleteConfirm}
            toggle={() => setDeleteConfirmModal(!deleteConfirm)}
            centered
          >
            <div className="modal-header">
              <h5 className="modal-title mt-0" id="myModalLabel">
                <span >
                  Delete Price List
                </span>
              </h5>
              <button
                type="button"
                onClick={() => {
                  setDeleteConfirmModal(false);
                }}
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <h6>Are You sure to delete ?</h6>
            </div>
            <div className="modal-footer">
              <Row>
                <Col lg="12">
                  <Button
                    type="submit"
                    color="primary"
                    className="w-md"
                    onClick={() => { deletePricelistData() }}
                  >
                    Yes
                  </Button>
                  <Button
                    type="submit"
                    className="w-md mx-2"
                    onClick={() => setDeleteConfirmModal(false)}
                  >
                    No
                  </Button>
                </Col>
              </Row>
            </div>
          </Modal>
        </div>
      </div>
    </React.Fragment >
  )
}

export default LaundryCoupon
