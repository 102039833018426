import axios from "axios"
import { del, get, post, postWeb } from "./api_helper"
import * as url from "./url_helper"


const fetchAreaList = (data) => post(`${url.AREA_LIST_ALL}`, { data })
const postAddArea = (data) => post(`${url.POST_ADD_AREA}`, data)
const postUpdateArea = (data) => post(`${url.POST_UPDATE_AREA}`, data)
const getArea = (areaId) => post(`${url.GET_AREA}`, {areaId: areaId})
const postOrganizeArea = (areas) => postWeb(`${url.POST_REORGANIZE_AREA}`, {areas: areas})



export {
    fetchAreaList,
    postAddArea, 
    getArea,
    postUpdateArea,
    postOrganizeArea
}
