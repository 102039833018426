import {
    API_SUCCESS,
    API_FAIL,
    GET_LAUNDRIES_LIST,
    GET_LAUNDRY_DETAILS,
    TOGGLE_LAUNDRY_ACTIVE,
    ALL_LAUNDRY_REVENUE
} from "./actionTypes";
import {nullOrEmpty} from "../../helpers/common_helper";


const INIT_STATE = {
    laundryList: [],
    laundryResponse : {},
    laundryDetails: {}, 
    allLaundryGMV: []
};

const Laundries = (state = INIT_STATE, action) => {
    switch (action.type) {
        case TOGGLE_LAUNDRY_ACTIVE: 
            let _laundryDetails = action.payload;
            _laundryDetails.active = !_laundryDetails.active;
            return {
                ...state,
                laundryDetails: _laundryDetails
            };

       
      
        case API_SUCCESS:

            switch (action.payload.actionType) {
                case GET_LAUNDRIES_LIST:
                    return {
                        ...state,
                        laundryList: action.payload.data
                    };

                case GET_LAUNDRY_DETAILS:
                    return {
                        ...state,
                        laundryDetails: action.payload.data
                    };

                case ALL_LAUNDRY_REVENUE:
                  return {
                      ...state,
                      allLaundryGMV: action.payload.data
                  };
               
                default:
                    return state;
            }
        case API_FAIL:
            switch (action.payload.actionType) {
                case GET_LAUNDRIES_LIST:
                    return {
                        ...state,
                        laundryResponse: action.payload.error
                    };

                case GET_LAUNDRY_DETAILS:
                    return {
                        ...state,
                        laundryResponse: action.payload.error,
                    };

                default:
                    return state;
            }
        default:
            return state;
    }
}


export default Laundries;
