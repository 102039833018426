import { 
  API_SUCCESS,
  API_FAIL,
  LIST_TIMESLOTS,
  CREATE_TIMESLOT,
  UPDATE_TIMESLOT,
  GET_PICKUP_TIMESLOTS,
  GET_DELIVERY_TIMESLOTS
} from "./actionTypes"

export const apiSuccess = (actionType, data) => ({
  type: API_SUCCESS,
  payload: { actionType, data },
});

export const apiFail = (actionType, error) => ({
  type: API_FAIL,
  payload: { actionType, error },
});

export const getTimeSlots = (data) => ({
  type: LIST_TIMESLOTS,
  payload: data
})

export const createTimeSlot = (data) => ({
  type: CREATE_TIMESLOT,
  payload: data
})

export const updateTimeSlot = (data) => ({
  type: UPDATE_TIMESLOT,
  payload: data
})

export const getPickupTimeslots = (data) => ({
  type: GET_PICKUP_TIMESLOTS,
  payload: data
})

export const getDeliveryTimeslots = (data) => ({
  type: GET_DELIVERY_TIMESLOTS,
  payload: data
})


