import {
  GET_LOYALITY_POINTS_SUCCESS,
  UPDATE_LOYALITY_POINTS_SUCCESS,
} from "./actionTypes"

const INIT_STATE = {
  loyalityPoints: [],
}

const LoyalityPoints = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_LOYALITY_POINTS_SUCCESS:
      return {
        ...state,
        loyalityPoints: action.payload,
      }

    case UPDATE_LOYALITY_POINTS_SUCCESS:
      const updatedLoyalityPoints = [...state.loyalityPoints]

      const updatedLoyalityPoint = action.payload

      const updatedLoyalityPointIndex = updatedLoyalityPoints.findIndex(
        loyalityPoint => loyalityPoint._id === updatedLoyalityPoint._id
      )

      if (updatedLoyalityPointIndex > -1) {
        updatedLoyalityPoints[updatedLoyalityPointIndex] = updatedLoyalityPoint
      } else {
        updatedLoyalityPoints.push(updatedLoyalityPoint)
      }

      return {
        ...state,
        loyalityPoints: updatedLoyalityPoints,
      }

    default:
      return state
  }
}

export default LoyalityPoints
