import {
  API_SUCCESS,
  API_FAIL,
  LIST_SETTINGS,
  CREATE_SETTING,
  UPDATE_SETTING,
} from "./actionTypes"

const INIT_STATE = {
  settings: []
}

const Settings = (state = INIT_STATE, action) => {
  switch (action.type) {
    case API_SUCCESS:
      switch (action.payload.actionType) {
        case LIST_SETTINGS:
          return {
            ...state,
            settings: action.payload.data.record 
          };

        case CREATE_SETTING:
          let createSettings = [...state.settings]
          createSettings.push(action.payload.data)
          return {
            ...state,
            settings: createSettings
          };

        case UPDATE_SETTING:
          const updatedSetting = action.payload.data
          let settings = [...state.settings]


          let index = settings.findIndex(
            setting => setting?.type === updatedSetting?.type
          )

          if(index === -1) {
            return {
              ...state,
            }
          }

          settings[index] = updatedSetting
          
          return {
            ...state,
            settings
          }


        default:
          return state;
      }

    case API_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    default:
      return state
  }
}

export default Settings
