import { call, put, takeEvery, takeLatest } from "redux-saga/effects"

// Login Redux States
import { LOGIN_USER, LOGOUT_USER, SOCIAL_LOGIN } from "./actionTypes"
import { apiError, loginSuccess, logoutUserSuccess ,loginError} from "./actions"

//Include Both Helper File with needed methods
import { getFirebaseBackend } from "../../../helpers/firebase_helper"
import {
  postFakeLogin,
  postJwtLogin,
  postSocialLogin,
} from "../../../helpers/fakebackend_helper"
import { users } from "common/data"

const fireBaseBackend = getFirebaseBackend()

function* loginUser({ payload: { user, history } }) {
   try {
    if (process.env.REACT_APP_DEFAULTAUTH === "firebase") {
      const response = yield call(
        fireBaseBackend.loginUser,
        user.email,
        user.password
      )
      yield put(loginSuccess(response))
    } else if (process.env.REACT_APP_DEFAULTAUTH === "jwt") {
     
      const response = yield call(postJwtLogin, {
        email: user.email,
        password: user.password,
        firebaseToken:user.firebaseToken,
      })
      //  debugger
      if( response.statusCode ==="400" || response == undefined ){
 
        yield put(loginError(response.msg));
        // history.push("/dashboard");
      }else if( response.statusCode === "200" && response.record.profile ) {
      
        var userinfo = {"_id":response.record._id,"role":response.record.role, token: response.token}
          localStorage.setItem("authUser", JSON.stringify(userinfo));
          history.push("/analytics");
          yield put(loginSuccess(response))
      }else if(response.statusCode === "200" &&  !response.record.profile ){
        yield put(loginError("Your user account do not have access to panel"));
      }
      

    } else if (process.env.REACT_APP_DEFAULTAUTH === "fake") {
      const response = yield call(postFakeLogin, {
        email: user.email,
        password: user.password,
      });
   

      localStorage.setItem("authUser", JSON.stringify(response))
      yield put(loginSuccess(response))
    }
    // history.push("/dashboard")
  } catch (error) {
    yield put(apiError(error))
    
  }
}

function* logoutUser({ payload: { history } }) {
  try {
    localStorage.removeItem("authUser")

    if (process.env.REACT_APP_DEFAULTAUTH === "firebase") {
      const response = yield call(fireBaseBackend.logout)
      yield put(logoutUserSuccess(response))
    }
    history.push("/login")
  } catch (error) {
    yield put(apiError(error))
  }
}

function* socialLogin({ payload: { data, history, type } }) {
  try {
    if (process.env.REACT_APP_DEFAULTAUTH === "firebase") {
      const fireBaseBackend = getFirebaseBackend()
      const response = yield call(
        fireBaseBackend.socialLoginUser,
        data,
        type,
      )
      localStorage.setItem("authUser", JSON.stringify(response))
      yield put(loginSuccess(response))
    } else {
      const response = yield call(postSocialLogin, data)
      localStorage.setItem("authUser", JSON.stringify(response))
      yield put(loginSuccess(response))
    }
    history.push("/analytics")
  } catch (error) {
    yield put(apiError(error))
  }
}


function* authSaga() {
  yield takeLatest(LOGIN_USER, loginUser)
  yield takeLatest(SOCIAL_LOGIN, socialLogin)
  yield takeEvery(LOGOUT_USER, logoutUser)
}

export default authSaga
