import {
  GET_NOTIFICATION_TEXT_SUCCESS,
  UPDATE_NOTIFICATION_TEXT_SUCCESS,
} from "./actionTypes"

const INIT_STATE = {
  notificationTexts: [],
}

const NotificationTexts = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_NOTIFICATION_TEXT_SUCCESS:
      return {
        ...state,
        notificationTexts: action.payload,
      }

    case UPDATE_NOTIFICATION_TEXT_SUCCESS:
      const updatedNotificationTexts = [...state.notificationTexts]

      const updatedText = action.payload

      const updatedTextIndex = updatedNotificationTexts.findIndex(
        text => text._id === updatedText._id
      )

      if (updatedTextIndex > -1) {
        updatedNotificationTexts[updatedTextIndex] = updatedText
      } else {
        updatedNotificationTexts.push(updatedText)
      }

      return {
        ...state,
        notificationTexts: updatedNotificationTexts,
      }

    default:
      return state
  }
}

export default NotificationTexts
