
export const API_SUCCESS = "API_SUCCESS";
export const API_FAIL = "API_FAIL";

// VIEW REDEEM PACKAGES  
export const GET_REDEEM_PKGS_LIST = "GET_REDEEM_PKGS_LIST";
export const GET_REDEEM_PKGS_LIST_SUCCESS = "GET_REDEEM_PKGS_LIST_SUCCESS";
export const GET_REDEEM_PKGS_LIST_FAIL = "GET_REDEEM_PKGS_LIST_FAIL";
export const GET_REDEEM_PKG_DETAIL = "GET_REDEEM_PKG_DETAIL";
export const GET_REDEEM_PKG_DETAIL_SUCCESS = "GET_REDEEM_PKG_DETAIL_SUCCESS";
export const GET_REDEEM_PKG_DETAIL_FAIL = "GET_REDEEM_PKG_DETAIL_FAIL";