import React, { useState } from "react"
import { Row, Col, Card, CardBody, CardTitle, Button, Modal, ModalHeader, ModalBody, FormGroup, Input, Label } from "reactstrap"
import MetaTags from 'react-meta-tags'
import PropTypes from "prop-types"

// datatable related plugins
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory, {
  PaginationProvider, PaginationListStandalone,
  SizePerPageDropdownStandalone
} from 'react-bootstrap-table2-paginator';

import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"
import "../../assets/scss/datatables.scss"
import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux"
import { getCompletedOrders , searchUsers, getReasons, getPickupTimeslots, getDeliveryTimeslots } from "../../store/actions"
import classNames from "classnames"
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import RatingTooltip from "react-rating-tooltip"
import Pages404 from "pages/Utility/pages-404";
import moment from 'moment-timezone';
import {useHistory} from "react-router-dom";
import Select from "react-select"
import { postWeb } from "helpers/api_helper";
import toastr from "toastr";
import "toastr/build/toastr.min.css";
import { AvField, AvForm } from "availity-reactstrap-validation";
import { nullOrEmpty } from "helpers/common_helper";
import { ThreeDots } from "react-loader-spinner";



const EditNote = props => {
  const [label, setLabel] = useState("");
  const [emptyLabel, setEmptyLabel] = useState("");
  const [showLabel, setShowLabel] = useState(true);
  const [selectedItem, setSelectedItem] = useState("");

  
  
  useEffect(() => {
    setSelectedItem(props.row.customer?.customer_care_note);
  }, [props]);

  useEffect(() => {
    setLabel(props.row.customer?.customer_care_note);
    setEmptyLabel("No Note")  
  }, [props]);

  const showEditable = (e) => {
    e.stopPropagation();
    setShowLabel(false);
  }

  const handleClose = () => {
    setShowLabel(true);
  }
  
  const handleSubmit = async () => {
    
      if(!selectedItem || selectedItem.trim() == ""){
        toastr.error("Please enter customer care note", `Required`);
        return;
      }

      if(!props.row?.customer?._id){
        toastr.error("Invalid customer ", `Required`);
        return;
      }

      
      postWeb("customers/update", {
        _id : props.row.customer?._id,
        customer_care_note : selectedItem
      }).then(() => {
        props.onUpdate.apply(null, [props.row._id, selectedItem]);
      });
      
      toastr.success(`Customer Care Note Updated Successfully`, `Customer Care Note Updated`);
      
      setShowLabel(true);
      return;

  }

  const onChangeItem = (e) => {
    setSelectedItem(e.target.value);
  }

  return (
    <>
    {showLabel?
    <>
          {label ? 
            <a onClick={showEditable} className="editable editable-click">{label}</a> : 
            <a onClick={showEditable} className="editable editable-click editable-empty text-danger">{emptyLabel}</a>}
        </> :
        <>
          <textarea 
            onChange={(e) => {
              e.stopPropagation();
              setSelectedItem(e.target.value)
            } }
            className="form-control"
          >{selectedItem}</textarea>
          <div className="editable-buttons">
            <button type="submit" 
              onClick={handleSubmit}
              className="btn btn-success editable-submit btn-sm waves-effect waves-light">
                <i className="mdi mdi-check">
                </i>
            </button>
            <button 
              type="button"
              onClick={handleClose} 
              className="btn btn-danger editable-cancel btn-sm waves-effect waves-light">
              <i className="mdi mdi-close"></i>
            </button>
          </div>
        </>}
    </>
  );
};

EditNote.propTypes = {
  row: PropTypes.any,
  onUpdate: PropTypes.any
}

const CompletedOrdersList = () => {
  const dispatch = useDispatch()
  const history = useHistory();
  const { CompletedOrdersPaginated, reasons, users, pickupTimeslots, deliveryTimeslots } = useSelector(state => ({
    // Allorders: state.Orders.Allorders,
    CompletedOrdersPaginated: state.Orders.CompletedOrders,
    reasons: state.Orders.reason,
    users : state.Users.users,
    pickupTimeslots: state.Timeslots.pickupTimeslots,
    deliveryTimeslots: state.Timeslots.deliveryTimeslots
  }))

  const [productData, setProductData] = useState([]);
  const [page, setPage] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [userData, setUserData] = useState({});
  const [totalRecords, setTotalRecords] = useState(0);
  const [rating, setRating] = useState(0);
  const [returnModal, setReturnModal] = useState(false);
  const [returnReason, setReturnReason] = useState();
  const [selectedOrder, setSelectedOrder] = useState(null);
  const [customReturnReason, setCustomReturnReason] = useState()
  const [pickupSlots, setPickupslots] = useState([])
  const [deliverySlots, setDeliveryslots] = useState([])
  const [pickupDate, setPickupDate] = useState("")
  const [pickupTimeslot, setPickupTimeslot] = useState("")
  const [deliveryDate, setDeliveryDate] = useState("")
  const [deliveryTimeslot, setDeliveryTimeslot] = useState("")
  const [error, setError] = useState({})
  const [returnProcessing, setReturnProcessing] = useState(false)
  const [deliverySlotsLoading, setDeliverySlotsLoading] = useState(false)

  const customReasonDB = reasons.find(reason => reason.isOther === true && reason.type=="return_reasons");
  const isCustomReason = customReasonDB && returnReason === customReasonDB._id
  const isScheduledOrder = (selectedOrder?.type == "carpet" || selectedOrder?.location?.type == "villa" || (selectedOrder?.location?.type == "apartment" && nullOrEmpty(selectedOrder?.customer?.bagNo)))
  const loaderColor = "#0097CE";
  const starStyle = {}

  const addTztoDate = (dt) => {
    const targetTime = new Date(dt);
    return moment(dt).tz("Asia/kuwait").format("YYYY-MM-DD");
  };


  const handleApply = () => {
    let apiData = { status: ["delivered", "accepted"], page: page, category: ["online"] };
    if(rating){
      apiData.rating = rating;
    }

    dispatch(getCompletedOrders(apiData))
  }


  useEffect(() => {
    dispatch(searchUsers({webPermissions: true}))
    dispatch(getReasons({}))
  }, [])

  useEffect(() => {
    if(users.length> 0){
      setUserData(users[0]);
    }
  }, [users])

  useEffect(() => {
    setProductData([])
    setIsLoading(true);
    handleApply();
  }, [page])

  useEffect(() => {
    if(!nullOrEmpty(returnReason)){
      setError(err => ({...err, reason: false}))
    }
  }, [returnReason])

  useEffect(() => {
    if(!nullOrEmpty(pickupTimeslot)){
      setError(err => ({...err, pickupTimeslot: false}))
    }
  }, [pickupTimeslot])

  useEffect(() => {
    if(deliveryTimeslots){
      setDeliverySlotsLoading(false);
    }
  }, [deliveryTimeslots])


  useEffect(() => {
    const orders = CompletedOrdersPaginated?.record;
    const totalOrders = CompletedOrdersPaginated?.total_orders;

    if (orders?.length && (orders[0]?.status == "accepted" ||orders[0]?.status == "delivered")) {
      setProductData(orders);
      setTotalRecords(totalOrders);
    }
    setIsLoading(false);
  }, [CompletedOrdersPaginated])

  const toggle = () => {
    setReturnModal(!returnModal);
  }

  const columns = [{
    dataField: 'orderID',
    text: 'Order',
    sort: false,
    formatter: (cellContent, row) => {
      return row?.orderID || "N/A"
    }
  }, {
    dataField: 'customer.customerID',
    text: 'Customer',
    sort: false,
    formatter: (cellContent, row) => {
      return row?.customer?.customerID || "N/A"
    }
  }, {
    dataField: 'customer.mobile',
    text: 'Mobile',
    sort: false,
    formatter: (cellContent, row) => {
      return row?.customer?.mobile || "N/A"
    }
  }, {
    dataField: 'startTime',
    text: 'Start Date',
    sort: false,
    formatter: (cellContent, row) => {
      return row?.startTime?.split(" ")[0] || "N/A"
    }
  }, {
    dataField: 'grandTotal',
    text: 'Grand Total',
    sort: false,
    formatter: (cellContent, row) => {
      let totalPrice = row?.grandTotal ? row?.grandTotal : row?.total ? row?.total : 0
      return "SR " + totalPrice
    }
  }, {
    dataField: 'rating',
    text: 'Rating',
    sort: false,
    formatter: (cellContent, row) => {
      if (row?.customerReview?.rating)
        return (
          <div className="d-flex">
            <RatingTooltip
              max={5}
              // onChange={rate => {
              //   setdef(rate)
              // }}
              ActiveComponent={
                <i
                  className="mdi mdi-star text-primary"
                  style={starStyle}
                />
              }
              InActiveComponent={
                <i
                  className="mdi mdi-star-outline text-muted"
                  style={starStyle}
                />
              }
              disabled={true}
              clearRating={true}
              defaultRating={row?.customerReview?.rating ? row?.customerReview?.rating : 0}
            />{" "}
            <span className="badge bg-info rating-badge">{row?.customerReview?.rating}</span>
          </div>
        )
      else return "-"
    }
  }, {
    dataField: 'review',
    text: 'Comments',
    classes: "reason-wrap",
    sort: false,
    formatter: (cellContent, row) => {
      let reasonObject = reasons.find((item) => item._id === row?.customerReview?.review)
      return row?.customerReview?.otherReview ? row?.customerReview?.otherReview : reasonObject?.reasonEn ? reasonObject?.reasonEn : "N/A"
    }
  }, 
  {
    dataField: 'totalOrders',
    text: "Total Orders",
    sort: false,
    formatter: (cellContent, row) => {
      return row?.customer?.totalOrders
    }
  },
  {
    dataField: 'firstOrderDate',
    text: 'First Order',
    sort: false,
    formatter: (cellContent, row) => {
      return (row?.customer?.firstOrderDate)?addTztoDate(row.customer.firstOrderDate): ""
    }
  },
  {
    dataField: 'lastOrderDate',
    text: 'Last Order',
    sort: false,
    formatter: (cellContent, row) => {
      return (row?.customer?.lastOrderDate)?addTztoDate(row.customer.lastOrderDate): ""
    }
  },
  {
    dataField: 'regDate',
    text: 'Registration',
    sort: false,
    formatter: (cellContent, row) => {
      let dt = "";
      if(row?.customer?.registrationDate){
        [dt] = row.customer.registrationDate.split(" ");
      }
      return dt;
    }
  },
  {
    dataField: 'customer_care_note',
    text: `Customer Care 
    Note`,
    classes: "reason-wrap",
    sort: false,
    formatter: (cellContent, row, i) => {
      if (isLoading) {
        return (<Skeleton count={1} />)
      }
      
      return <>
        
        <EditNote onUpdate={handleApply} row={row} />
      </>
    }
  },
  {
    dataField: 'ReturnOrder',
    text: 'Return',
    classes: "reason-wrap",
    sort: false,
    formatter: (cellContent, row, i) => {
      if (isLoading) {
        return (<Skeleton count={1} />)
      }
      
      return <>
        
        <Button 
          color="link"
          onClick={() => returnOrder(row)}
          >Return</Button>
      </>
    }
  },

  // {
  //   dataField: 'view',
  //   text: 'View',
  //   sort: false,
  //   formatter: (cellContent, row) => {
  //     return (
  //       <button className="btn btn-primary btn-sm" >
  //         View
  //       </button>
  //     )
  //   }
  // }
];

  const defaultSorted = [{
    dataField: 'id',
    order: 'asc'
  }];

  const pageOptions = {
    sizePerPage: 20,
    totalSize: totalRecords, // replace later with size(customers),
    custom: true,
  }

  // Custom Pagination Toggle
  const sizePerPageList = [
    { text: '5', value: 5 },
    { text: '10', value: 10 },
    { text: '15', value: 15 },
    { text: '20', value: 20 },
    { text: '25', value: 25 },
    { text: 'All', value: totalRecords }];


    const rowEvents = {
      onClick: (e, row, rowIndex) => {
        
        if(e.target.classList && e.target.classList.value.indexOf("mdi") != -1){
          return;
        }

        if(e.target.type == "textarea" || e.target.type == "button" || e.target.type == "submit"){
          return;
        }

        history.push(`/create-invoice/${row._id}`, {prevPath:'/completed-orders', orderID:row._id});
      }
    };

  const returnOrder = (row) => {
    console.log("Row::", row);
    dispatch(getPickupTimeslots({
      location : row?.location,
      orderType: row?.type
    }))
    setSelectedOrder(row);
    setReturnModal(true);
    setPickupslots([]);
    setReturnReason("")
    setCustomReturnReason("")
    setPickupDate("")
    setPickupTimeslot("")
    setDeliveryDate("")
    setDeliveryTimeslot("")
    setPickupslots([])
    setDeliveryslots([])
  }

  const onPickupDateClick = (timeslot) => {
    setPickupDate(timeslot?.date)
    setPickupTimeslot("");
    setPickupslots(timeslot?.timeslots || []);
    setDeliveryDate("")
    setError(err => ({...err, pickupDate: false}))
  }
  
  const onDeliveryDateClick = (timeslot) => {
    setDeliveryDate(timeslot?.date)
    setDeliveryTimeslot("");
    setDeliveryslots(timeslot?.timeslots || []);
    setError(err => ({...err, pickupDate: false}))
  }


  const submitReturnOrder = () => {
    setReturnProcessing(true);
    let error = false;
    if(nullOrEmpty(returnReason)){
      setError(err => ({...err, reason: true}))
      error = true; 
    }
    
    if(isScheduledOrder){

      if(nullOrEmpty(pickupDate)){
        setError(err => ({...err, pickupDate: true}))
        error = true; 
      }

      if(nullOrEmpty(pickupTimeslot)){
        setError(err => ({...err, pickupTimeslot: true}))
        error = true; 
      }

      if(isCustomReason && nullOrEmpty(customReturnReason)){
        //setError(err => ({...err, returnTimeslot: true}))
        error = true; 
      }

      if(nullOrEmpty(deliveryDate)){
        setError(err => ({...err, deliveryDate: true}))
        error = true; 
      }

      if(nullOrEmpty(deliveryTimeslot)){
        setError(err => ({...err, deliveryTimeslot: true}))
        error = true; 
      }
    }

    if(error == true){
      setReturnProcessing(false);
      return;
    }
    
    const data = {
      _id : selectedOrder?._id,
      returned : {
        returnReason: returnReason
      }
    }

    if(isCustomReason){
      data.returned.otherReason = customReturnReason;
    }

    if(isScheduledOrder){
      data.pickupDate = pickupDate;
      data.deliveryDate = deliveryDate;
      data.pickupTimeslot = pickupTimeslot;
      data.deliveryTimeslot = deliveryTimeslot;
    }

    postWeb("orders/returnOrder", data).then(() => {
      handleApply();
      setReturnProcessing(false);
      setReturnModal(false);
      toastr.success(`Order returned successfully`, `Order Return`);
    });
  

  }

  const onPickupTimeslotClick = (timeslot) => {
    setDeliverySlotsLoading(true);
    setPickupTimeslot(timeslot);
    dispatch(getDeliveryTimeslots({
      orderType: selectedOrder?.type,
      pickupDate: pickupDate,
      pickupTimeslot: timeslot,
      location: selectedOrder?.location?._id
    }))
  }

  const { SearchBar } = Search;
  if(userData?.permissions?.allPermissions==true || userData?.permissions?.orders?.read==true){
  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Kleen | Completed Orders list</title>
        </MetaTags>
        <div className="container-fluid">
          <Breadcrumbs title="Orders" breadcrumbItem="Completed orders" />

          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>

                  {/* <CardTitle className="h4">Completed orders </CardTitle> */}
                  {/* <p className="card-title-desc">
                    react-bootstrap-table-next plugin has most features enabled by default,
                    so all you need to do to use it with your own tables is to
                    call the construction function:{" "}
                    <code>react-bootstrap-table-next </code>.
                  </p> */}
                  <Row className="mb-2">
                    <Col sm="6">
                      <div className="me-2 mb-2 d-inline-block">
                        <div className="position-relative d-flex">
                          
                          <div className="ms-2" style={{ width: "20rem" }}>
                            <Select
                              placeholder="All Ratings"
                              onChange={val => {
                                setRating(val?.value)
                              }}
                              isClearable={true}
                              options={[
                                { value: 1, label: "1 Star Rating" },
                                { value: 2, label: "2 Star Rating" },
                                { value: 3, label: "3 Star Rating" },
                                { value: 4, label: "4 Star Rating" },
                                { value: 5, label: "5 Star Rating" },
                              ]}
                            />
                          </div>
                          <Button
                            className="ms-2"
                            color="primary"
                            onClick={() => {
                              setIsLoading(true);
                              setProductData([]);
                              setTimeout(() => {
                                if(page != 0){
                                  setPage(0);
                                }
                                else{
                                  handleApply()
                                }
                              }, 10)
                            }}
                          >Apply</Button>
                        </div>
                      </div>
                    </Col>
                  </Row>
                  {
                    isLoading ? (
                      <div className="row">
                        <div className="col-xl-12">
                          <div className="table-responsive">
                            <div className="react-bootstrap-table">
                              <table className="table table align-middle table-nowrap">
                                <thead className="table-light">
                                  <tr>
                                    <th>Order</th>
                                    <th>Customer</th>
                                    <th>Mobile</th>
                                    <th>Start Date</th>
                                    <th>Grand Total</th>
                                    <th>Rating</th>
                                    <th>Comments</th>
                                    <th>Total Orders</th>

                                    <th>First Order</th>
                                    <th>Last Order</th>
                                    <th>Registration</th>

                                  </tr>
                                </thead>
                                <tbody>
                                  {[...Array(10).keys()].map((k) => (
                                    <tr key={k}>
                                      <td><Skeleton count={1} /></td>
                                      <td><Skeleton count={1} /></td>
                                      <td><Skeleton count={1} /></td>
                                      <td><Skeleton count={1} /></td>
                                      <td><Skeleton count={1} /></td>
                                      <td><Skeleton count={1} /></td>
                                      <td><Skeleton count={1} /></td>
                                      <td><Skeleton count={1} /></td>
                                      
                                      <td><Skeleton count={1} /></td>
                                      <td><Skeleton count={1} /></td>
                                      <td><Skeleton count={1} /></td>
                                  </tr>  
                                  ))}
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                      </div>
                    ) : (
                      <PaginationProvider
                        pagination={paginationFactory(pageOptions)}
                        keyField='id'
                        columns={columns}
                        data={productData}
                      >
                        {({ paginationProps, paginationTableProps }) => (
                          <ToolkitProvider
                            keyField='id'
                            columns={columns}
                            data={productData}
                            search
                          >
                            {toolkitProps => (
                              <React.Fragment>

                                {/* <Row className="mb-2">
                              <Col md="4">
                                <div className="search-box me-2 mb-2 d-inline-block">
                                  <div className="position-relative">
                                    <SearchBar
                                      {...toolkitProps.searchProps}
                                    />
                                    <i className="bx bx-search-alt search-icon" />
                                  </div>
                                </div>
                              </Col>
                            </Row> */}

                                <Row>
                                  <Col xl="12">
                                    <div className="table-responsive">
                                      <BootstrapTable
                                        keyField={"id"}
                                        responsive
                                        bordered={false}
                                        striped={false}
                                        defaultSorted={defaultSorted}
                                        rowEvents={rowEvents}
                                        // selectRow={selectRow}
                                        classes={
                                          "table align-middle"
                                        }
                                        headerWrapperClasses={"table-light"}
                                        // headerWrapperClasses={"thead-light"}
                                        {...toolkitProps.baseProps}
                                        {...paginationTableProps}
                                      />

                                    </div>
                                  </Col>
                                </Row>

                                {/* <Row className="align-items-md-center mt-30">
                              <Col className="inner-custom-pagination d-flex">
                                <div className="d-inline">
                                  <SizePerPageDropdownStandalone
                                    {...paginationProps}
                                  />
                                </div>
                                <div className="text-md-right ms-auto">
                                  <PaginationListStandalone
                                    {...paginationProps}
                                  />
                                </div>
                              </Col>
                            </Row> */}
                              </React.Fragment>
                            )
                            }
                          </ToolkitProvider>
                        )
                        }</PaginationProvider>
                    )
                  }

                </CardBody>
              </Card>
            </Col>
          </Row>
          <div className="align-items-md-center mt-30 row">
            <div className="inner-custom-pagination d-flex col">
              {/* <div className="d-inline">
                  <span className="react-bs-table-sizePerPage-dropdown dropdown" style={{ visibility: "visible" }}>
                    <button id="pageDropDown" type="button" className="btn btn-default btn-secondary dropdown-toggle" data-toggle="dropdown" aria-expanded="false">
                      10 <span><span className="caret"></span></span>
                    </button>
                    <ul className="dropdown-menu " role="menu" aria-labelledby="pageDropDown">
                      <li role="presentation" className="dropdown-item">
                        <a href="#" tabIndex="-1" role="menuitem" data-page="10">10</a>
                      </li>
                      <li role="presentation" className="dropdown-item">
                        <a href="#" tabIndex="-1" role="menuitem" data-page="25">25</a>
                      </li>
                      <li role="presentation" className="dropdown-item">
                        <a href="#" tabIndex="-1" role="menuitem" data-page="30">30</a>
                      </li>
                      <li role="presentation" className="dropdown-item">
                        <a href="#" tabIndex="-1" role="menuitem" data-page="50">50</a>
                      </li>
                    </ul>
                  </span>
                </div> */}
              <div className="text-md-right ms-auto">
                <ul className="pagination react-bootstrap-table-page-btns-ul">
                  {
                    page > 5 ? (
                      <li className="page-item" title="next page">
                        <a onClick={() => setPage(0)} className="page-link">First</a>
                      </li>
                    ) : null
                  }
                  {
                    [...Array(Math.ceil(totalRecords / 20)).keys()].map(element =>
                      (page >= element - 5 && page <= element + 5) ?
                        (
                          <li key={element} className={classNames("page-item",
                            { "active": element == page }
                          )
                          } title="1">
                            <a onClick={() => setPage(element)} className="page-link">{element + 1}</a>
                          </li>
                        ) : null
                    )
                  }
                  {
                    page < Math.ceil(totalRecords / 20) - 6 ? (
                      <li className="page-item" title="next page">
                        <a onClick={() => setPage(Math.ceil(totalRecords / 20) - 1)} className="page-link">Last</a>
                      </li>
                    ) : null
                  }

                  {/* <li className="page-item" title="2">
                      <a href="#" className="page-link">2</a>
                    </li>
                    <li className="page-item" title="3">
                      <a href="#" className="page-link">3</a>
                    </li> */}
                  {/* <li className="page-item" title="next page">
                      <a href="#" className="page-link">&gt;</a>
                    </li> */}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      
      <Modal isOpen={returnModal} toggle={toggle} size="lg">
        <ModalHeader toggle={toggle} tag="h4">
          Return Order
        </ModalHeader>
        <ModalBody>
          <AvForm
            onValidSubmit={() => {}}
          >
            <Row form>
              <Col>
                <Row>
                  <Col lg="6">
                    <div className="mb-3">
                      
                    <div>
                      <div className="mb-4 h5">Please select a reason for return</div>
                      {reasons.map(reason => {
                        if(reason?.type != "return_reasons") 
                          return null;
                        
                          return (
                          <FormGroup check key={reason._id} className="mb-2">
                            <Input
                              type="radio"
                              value={reason._id}
                              id={"reason-ongoing-" + reason._id}
                              checked={returnReason === reason._id}
                              onChange={e => {
                                setReturnReason(e.target.value)
                              }}
                            />
                            <Label htmlFor={"reason-ongoing-" + reason._id} check>{reason.reasonEn}</Label>
                          </FormGroup>
                        )
                      })}
                      {isCustomReason && (
                        <Input
                          value={customReturnReason}
                          onChange={e => setCustomReturnReason(e.target.value)}
                          placeholder="Please enter your reason"
                          invalid={customReturnReason === ""}
                        />
                      )}
                      {error?.reason?<span className="text-danger">Please select reason</span>:null}
                    </div>
                    </div>
                  </Col>
                </Row>
                {isScheduledOrder?
                <>
                <Row>
                  <Col>
                        <Label>Select Pickup Date: </Label>
                        <div className="d-flex justify-content-start">
                          {pickupTimeslots.map((timeslot, i) => (
                            <div key={i} className="p-2">
                              <Button 
                                color="info"
                                outline={timeslot?.date != pickupDate} 
                                onClick={() => onPickupDateClick(timeslot)}
                              >{timeslot?.date}</Button>
                            </div>
                          ))}
                          
                        </div>
                        {error?.pickupDate?<span className="text-danger">Please select pickup date</span>:null}
                  </Col>
                </Row>
                {pickupDate?
                <Row>
                  <Col>
                    <Label className="mt-2">Select Pickup Timeslot: </Label>
                    <div className="d-flex justify-content-start">
                    {pickupSlots.map((timeslot, i) => (
                      <div key={i} className="p-2">
                        <Button 
                          className="in"
                          color="info" 
                          outline={pickupTimeslot != timeslot?._id}
                          onClick={() => onPickupTimeslotClick(timeslot?._id)}
                          >{timeslot?.start} - {timeslot?.end}</Button>
                      </div>
                    ))}
                    
                    </div>
                    {error?.pickupTimeslot?<span className="text-danger">Please select pickup timeslot</span>:null}
                  </Col>
                </Row>:null}
                {pickupDate && pickupTimeslot?
                <Row>
                <Col>
                  <Label>Select Delivery Date: </Label>
                    
                    <ThreeDots
                      height="80"
                      width="80"
                      radius="9"
                      color={loaderColor}
                      ariaLabel="three-dots-loading"
                      wrapperStyle={{}}
                      wrapperClassName=""
                      visible={deliverySlotsLoading}
                    />
                    {!deliverySlotsLoading?
                      <div className="d-flex justify-content-start">
                        {deliveryTimeslots.map((timeslot, i) => (
                          <div key={i} className="p-2">
                            <Button 
                              color="info"
                              outline={timeslot?.date != deliveryDate} 
                              onClick={() => onDeliveryDateClick(timeslot)}
                            >{timeslot?.date}</Button>
                          </div>
                        ))}
                        
                      </div>: null}
                      {error?.deliveryDate?<span className="text-danger">Please select delivery date</span>:null}
                </Col>
                </Row>: null}
                </>
                : null}
                {deliveryDate && isScheduledOrder ?
                <Row>
                  <Col>
                    <Label className="mt-2">Select Delivery Timeslot: </Label>
                    <div className="d-flex justify-content-start">
                    {deliverySlots.map((timeslot, i) => (
                      <div key={i} className="p-2">
                        <Button 
                          className="in"
                          color="info" 
                          outline={deliveryTimeslot != timeslot?._id}
                          onClick={() => setDeliveryTimeslot(timeslot?._id)}
                          >{timeslot?.start} - {timeslot?.end}</Button>
                      </div>
                    ))}
                    
                    </div>
                    {error?.deliveryTimeslot?<span className="text-danger">Please select delivery timeslot</span>:null}
                  </Col>
                </Row>:null}
                
              </Col>
            </Row>
            <Row>
              <Col>
                  <div className="d-flex justify-content-end">
                    <Button 
                      className="m-2"
                      onClick={() => setReturnModal(false)}
                    >Close</Button>
                    {returnProcessing?<Button 
                      className="m-2" 
                      color="primary"
                      disabled
                      >Processing...</Button>
                      :
                      <Button 
                      className="m-2" 
                      color="primary"
                      onClick={submitReturnOrder}
                      >Return Order</Button>
                    }
                  </div>
              </Col>
            </Row>
          </AvForm>
          
        </ModalBody>
      </Modal>
    </React.Fragment>
  )
  }
  else{
   return <Pages404/> 
  }
}

export default CompletedOrdersList
