import { call, put, takeEvery, takeLatest } from "redux-saga/effects"

// Ecommerce Redux States
import {
  GET_B2B_CUSTOMER,
  GET_CART_DATA,
  GET_CUSTOMERS,
  GET_ORDERS,
  GET_PRODUCT_DETAIL,
  GET_PRODUCTS,
  GET_SHOPS,
  ADD_NEW_ORDER,
  DELETE_ORDER,
  UPDATE_ORDER,
  ADD_NEW_CUSTOMER,
  DELETE_CUSTOMER,
  UPDATE_CUSTOMER,
  GET_PRODUCT_COMMENTS,
  ON_LIKE_COMMENT,
  ON_LIKE_REPLY,
  ON_ADD_REPLY,
  ON_ADD_COMMENT,
  // pricelist
  GET_PRICELIST,
  GET_CLOTHLIST,
  ADD_PRICE,
  UPDATE_PRICE,
  BULK_UPDATE_PRICE,
  DELETE_PRICE,
  //laudrylist
  GET_LAUNDRIES,
  GET_AREA,
  GET_UPDATE_AREA,
  GET_DRIVER,
  GET_TIMESLOT,
  //add new area
  ADD_NEW_AREA,
  UPDATE_AREA
} from "./actionTypes"
import {
  getCartDataFail,
  getCartDataSuccess,
  getCustomersFail,
  getCustomersSuccess,
  getOrdersFail,
  getOrdersSuccess,
  getProductDetailFail,
  getProductDetailSuccess,
  getProductsFail,
  getProductsSuccess,
  getShopsFail,
  getShopsSuccess,
  addOrderFail,
  addOrderSuccess,
  updateOrderSuccess,
  updateOrderFail,
  deleteOrderSuccess,
  deleteOrderFail,
  addCustomerFail,
  addCustomerSuccess,
  updateCustomerSuccess,
  updateCustomerFail,
  deleteCustomerSuccess,
  deleteCustomerFail,
  getProductCommentsSuccess,
  getProductCommentsFail,
  onLikeCommentSuccess,
  onLikeCommentFail,
  onLikeReplySuccess,
  onLikeReplyFail,
  onAddReplySuccess,
  onAddReplyFail,
  onAddCommentSuccess,
  onAddCommentFail,
  getPricelistSuccess,
  getPricelistFail,
  getClothSuccess,
  getClothFail,
  addPriceSuccess,
  addPriceFail,
  updatePriceSuccess,
  updatePriceFail,
  deletePriceSuccess,
  deletePriceFail,
  //for laudrylist
  getLaundrySuccess,
  getLaundryFail,
  //fro area
  getArealistSuccess,
  getArealistFail,
  getB2bCustomerSuccess,
  getB2bCustomerFail,
  //add new area 
  addNewAreaSuccess,
  addNewAreaFail,
  getDriverSuccess,
  getDriverFail,
  fetchTimeSlotListSuccess,
  fetchTimeSlotListFail,
  updateAreaSuccess,
  updateAreaFail,
  reloadPricelist
} from "./actions"

//Include Both Helper File with needed methods
import {
  getCartData,
  getOrders,
  getPricelist,
  getlaundrylist,
  getArealist,
  updateArealist,
  getProducts,
  getShops,
  getProductDetail,
  addNewOrder,
  updateOrder,
  deleteOrder,
  addNewCustomer,
  updateCustomer,
  deleteCustomer,
  addNewArea,
  getClothlist,
  addPricelist,
  updatePricelist,
  _bulkUpdatePrice,
  deletePricelist,
  getProductComents as getProductComentsApi,
  onLikeComment as onLikeCommentApi,
  onLikeReply as onLikeReplyApi,
  onAddReply as onAddReplyApi,
  onAddComment as onAddCommentApi,
  getDriverlist,
  getTimeSlotList,
  getB2bCustomerlist
} from "helpers/fakebackend_helper"

import toastr from "toastr";
import "toastr/build/toastr.min.css";

function* fetchProducts() {
  try {
    const response = yield call(getProducts)
    yield put(getProductsSuccess(response))
  } catch (error) {
    yield put(getProductsFail(error))
  }
}

function* fetchCloth({ payload: data }) {
  try {
    const response = yield call(getClothlist, data)
    yield put(getClothSuccess(response.record))
  } catch (error) {
    yield put(getClothFail(error))
  }
}

function* addPrice({ payload: data }) {
  try {
    const response = yield call(addPricelist, data.data)
    if(response?.statusCode == 200){
      toastr.success("Pricelist Added successfully", "Pricelist");
      if(data.searchData.abortSearch){
        yield put(addPriceSuccess(response.record))
        return
      }
      yield put(reloadPricelist(data.searchData))
      
    }else{
      toastr.error(response.msg, "Pricelist");
    }
  } catch (error) {
    toastr.error("Pricelist add failed", "Pricelist");
    yield put(addPriceFail(error))
  }
}

function* updatePrice({ payload: data }) {
  try {
    const response = yield call(updatePricelist, data)
    if(response?.statusCode == 200){
      toastr.success("Pricelist Updated successfully", "Pricelist");
      // yield put(updatePriceSuccess(response.record))
    }else{
      toastr.error("Pricelist Update Failed", "Pricelist");
    }
  } catch (error) {
    toastr.error("Pricelist Update Failed", "Pricelist");
    // yield put(updatePriceFail(error))
  }
}

function* bulkUpdatePrice({ payload: data }) {
  try {
    const response = yield call(_bulkUpdatePrice, data.data)
    if(response?.statusCode == 200){
      toastr.success("Pricelist Updated successfully", "Pricelist");
      yield put(reloadPricelist(data.searchData))
    } else {
      if(response?.msg){
        toastr.error(response?.msg, "Pricelist");
      }
      else{
        toastr.error("Pricelist Update Failed", "Pricelist");
      }
      
    }
  } catch (error) {
    toastr.error("Pricelist Update Failed", "Pricelist");
  }
}

function* deletePrice({ payload: data }) {
  try {
    const response = yield call(deletePricelist, data)
    if(response?.statusCode == 200){
      toastr.success("Pricelist Deleted successfully", "Pricelist");
      yield put(deletePriceSuccess(data))
    }else{
      toastr.error("Pricelist Delete Failed", "Pricelist");
    }
  } catch (error) {
    toastr.error("Pricelist Delete Failed", "Pricelist");
    yield put(deletePriceFail(error))
  }
}

function* fetchProductDetail({ productId }) {
  try {
    const response = yield call(getProductDetail, productId)
    yield put(getProductDetailSuccess(response))
  } catch (error) {
    yield put(getProductDetailFail(error))
  }
}

function* fetchOrders({ payload: data }) {
  try {
    const response = yield call(getOrders, data)
    yield put(getOrdersSuccess(response))
  } catch (error) {
    yield put(getOrdersFail(error))
  }
}

// pricelist fetch
function* fetchPricelist({ payload: data }) {
  try {
    const response = yield call(getPricelist, data)
    yield put(getPricelistSuccess(response))
  } catch (error) {
    yield put(getPricelistFail(error))
  }
}

// priclist end

function* fetchDriverlist({ payload: data }) {
  try {
    const response = yield call(getDriverlist, data)
    yield put(getDriverSuccess(response))
  } catch (error) {
    yield put(getDriverFail(error))
  }
}

function* fetchLaundrylist({ payload: record }) {
  try {
    const response = yield call(getlaundrylist, record)
    yield put(getLaundrySuccess(response))
  } catch (error) {
    yield put(getLaundryFail(error))
  }
}

// area list

function* fetchArealist({ payload: record }) {
  try {
    const response = yield call(getArealist, record)
    yield put(getArealistSuccess(response))
  } catch (error) {
    yield put(getArealistFail(error))
  }
}

function* fetchB2bCustomerlist({ payload: record }) {
  try {
    const response = yield call(getB2bCustomerlist, record)
    yield put(getB2bCustomerSuccess(response.record))
  } catch (error) {
    yield put(getB2bCustomerFail(error))
  }
}

function* fetchupdateArealist({ payload: record }) {
  try {
    const response = yield call(updateArealist, record)
    yield put(getArealistSuccess(response))
  } catch (error) {
    // yield put(getArealistFail(error))
  }
}

function* AddNewAreaList({ payload: data }) {
  try {
    const response = yield call(addNewArea, data)
    yield put(addNewAreaSuccess(response))
  } catch (error) {
    yield put(addNewAreaFail(error))
  }
}

function* UpdateArea({ payload: data }) {
  try {
    const response = yield call(updateArealist, data)
    yield put(updateAreaSuccess(response))
  } catch (error) {
    yield put(updateAreaFail(error))
  }
}

function* fetchCartData() {
  try {
    const response = yield call(getCartData)
    yield put(getCartDataSuccess(response))
  } catch (error) {
    yield put(getCartDataFail(error))
  }
}

function* fetchCustomers() {
  try {
    const response = yield call(getCustomers)
    yield put(getCustomersSuccess(response))
  } catch (error) {
    yield put(getCustomersFail(error))
  }
}

function* onUpdateCustomer({ payload: customer }) {
  try {
    const response = yield call(updateCustomer, customer)
    yield put(updateCustomerSuccess(response))
  } catch (error) {
    yield put(updateCustomerFail(error))
  }
}

function* onDeleteCustomer({ payload: customer }) {
  try {
    const response = yield call(deleteCustomer, customer)
    yield put(deleteCustomerSuccess(response))
  } catch (error) {
    yield put(deleteCustomerFail(error))
  }
}

function* onAddNewCustomer({ payload: customer }) {
  try {
    const response = yield call(addNewCustomer, customer)

    yield put(addCustomerSuccess(response))
  } catch (error) {
    yield put(addCustomerFail(error))
  }
}

function* fetchShops() {
  try {
    const response = yield call(getShops)
    yield put(getShopsSuccess(response))
  } catch (error) {
    yield put(getShopsFail(error))
  }
}

function* onUpdateOrder({ payload: order }) {
  try {
    const response = yield call(updateOrder, order)
    yield put(updateOrderSuccess(response))
  } catch (error) {
    yield put(updateOrderFail(error))
  }
}

function* onDeleteOrder({ payload: order }) {
  try {
    const response = yield call(deleteOrder, order)
    yield put(deleteOrderSuccess(response))
  } catch (error) {
    yield put(deleteOrderFail(error))
  }
}

function* onAddNewOrder({ payload: order }) {
  try {
    const response = yield call(addNewOrder, order)
    yield put(addOrderSuccess(response))
  } catch (error) {
    yield put(addOrderFail(error))
  }
}

function* getProductComents() {
  try {
    // todo - add product Id to the payload and api
    const response = yield call(getProductComentsApi)
    yield put(getProductCommentsSuccess(response))
  } catch (error) {
    yield put(getProductCommentsFail(error))
  }
}

function* onLikeComment({ payload: { commentId, productId } }) {
  try {
    // todo - add product Id to the payload and api
    const response = yield call(onLikeCommentApi, commentId, productId)
    yield put(onLikeCommentSuccess(response))
  } catch (error) {
    yield put(onLikeCommentFail(error))
  }
}

function* onLikeReply({ payload: { commentId, productId, replyId } }) {
  try {
    // todo - add product Id to the payload and api
    const response = yield call(onLikeReplyApi, commentId, productId, replyId)
    yield put(onLikeReplySuccess(response))
  } catch (error) {
    yield put(onLikeReplyFail(error))
  }
}

function* onAddReply({ payload: { commentId, productId, replyText } }) {
  try {
    const response = yield call(onAddReplyApi, commentId, productId, replyText)
    yield put(onAddReplySuccess(response))
  } catch (error) {
    yield put(onAddReplyFail(error))
  }
}

function* onAddComment({ payload: { productId, commentText } }) {
  try {
    const response = yield call(onAddCommentApi, productId, commentText)
    yield put(onAddCommentSuccess(response))
  } catch (error) {
    yield put(onAddCommentFail(error))
  }
}

function* fetchTimeSlotList({ payload: data }) {
  try {
    const response = yield call(getTimeSlotList, data)
    yield put(fetchTimeSlotListSuccess(response.record))
  } catch (error) {
    yield put(fetchTimeSlotListFail(error))
  }
}

function* ecommerceSaga() {

  yield takeEvery(GET_B2B_CUSTOMER, fetchB2bCustomerlist)
  yield takeEvery(GET_PRODUCTS, fetchProducts)
  yield takeEvery(GET_PRODUCT_DETAIL, fetchProductDetail)
  yield takeEvery(GET_ORDERS, fetchOrders)
  yield takeEvery(GET_CART_DATA, fetchCartData)
  yield takeEvery(GET_CUSTOMERS, fetchCustomers)
  yield takeEvery(ADD_NEW_CUSTOMER, onAddNewCustomer)
  yield takeEvery(UPDATE_CUSTOMER, onUpdateCustomer)
  yield takeEvery(DELETE_CUSTOMER, onDeleteCustomer)
  yield takeEvery(GET_SHOPS, fetchShops)
  yield takeEvery(ADD_NEW_ORDER, onAddNewOrder)
  yield takeEvery(UPDATE_ORDER, onUpdateOrder)
  yield takeEvery(DELETE_ORDER, onDeleteOrder)
  yield takeEvery(GET_PRODUCT_COMMENTS, getProductComents)
  yield takeEvery(ON_LIKE_COMMENT, onLikeComment)
  yield takeEvery(ON_LIKE_REPLY, onLikeReply)
  yield takeEvery(ON_ADD_REPLY, onAddReply)
  yield takeEvery(ON_ADD_COMMENT, onAddComment)
  yield takeEvery(DELETE_PRICE, deletePrice)
  yield takeLatest(GET_PRICELIST, fetchPricelist)
  yield takeLatest(GET_CLOTHLIST, fetchCloth)
  yield takeLatest(ADD_PRICE, addPrice)
  yield takeLatest(UPDATE_PRICE, updatePrice)
  yield takeLatest(BULK_UPDATE_PRICE, bulkUpdatePrice)

  yield takeLatest(GET_LAUNDRIES, fetchLaundrylist)
  // area 
  yield takeLatest(GET_AREA, fetchArealist)
  yield takeLatest(GET_UPDATE_AREA, fetchupdateArealist)
  // area 
  yield takeLatest(ADD_NEW_AREA, AddNewAreaList)
  yield takeLatest(UPDATE_AREA, UpdateArea)
  yield takeLatest(GET_DRIVER, fetchDriverlist)
  yield takeEvery(GET_TIMESLOT, fetchTimeSlotList)
}

export default ecommerceSaga
