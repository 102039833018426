import { call, put, takeEvery } from "redux-saga/effects"

import { GET_NOTIFICATION_TEXT, UPDATE_NOTIFICATION_TEXT } from "./actionTypes"

import {
  getNotificationTextSuccess,
  updateNotificationTextSuccess,
} from "./actions"

import {
  _getNotificationTexts,
  _updateNotificationTexts,
} from "helpers/notificationTextHelper"

import toastr from "toastr"

function* getNotificationText({ payload: data }) {
  try {
    const response = yield call(_getNotificationTexts, data)
    if (response?.statusCode == 200) {
      yield put(getNotificationTextSuccess(response.record))
    } else {
      toastr.error(response.msg, "Notification Texts")
    }
  } catch (error) {
    toastr.error(error.msg, "Notification Texts")
  }
}

function* updateNotificationText({ payload: data }) {
  try {
    const response = yield call(_updateNotificationTexts, data)
    console.log("response", response);

    if (response?.statusCode == 200) {
      yield put(updateNotificationTextSuccess(response.record))
      toastr.success("Updated", "Notification Texts")
    } else {
      toastr.error(response.msg, "Notification Texts")
    }
  } catch (error) {
    toastr.error(error.msg, "Notification Texts")
  }
}

function* NotificationText() {
  yield takeEvery(GET_NOTIFICATION_TEXT, getNotificationText)
  yield takeEvery(UPDATE_NOTIFICATION_TEXT, updateNotificationText)
}

export default NotificationText
