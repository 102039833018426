const nullOrEmpty = (object) => {
    if(object == undefined || object == null || object == ""){
      return true;
    }
    else{
      return false;
    }
}

const removeMobilePrefix = (mobile) => {
  if(mobile){
    console.log("mobile", mobile.substring(0,3))
    if(mobile.substring(0,3) == 966){
      return "0" + mobile.substring(3,mobile.length-1);
    }
    else if(mobile.substring(0,2) == 91){
      return "0" + mobile.substring(2,mobile.length-1);
    }
    else if(mobile.charAt(0) != "0"){
      return "0" + mobile;
    }
    else{
      return mobile;
    }
  }
}

export {
    nullOrEmpty,
    removeMobilePrefix
}