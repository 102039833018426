import { call, put, takeEvery, all, fork } from "redux-saga/effects";

// Crypto Redux States
import { GET_CHARTS_DATA, GET_REATIME_DETAILS, GET_TOP4_DATA, SET_LOADING, 
    APP_REGISTRATIONS_MONTHLY, UNIQUE_CUSTOMER_ORDERS,MONTHLY_KLEEN_ORDERS, 
    MONTHLY_LAUNDRY_ORDERS, MONTHLY_KLEEN_GMV, MONTHLY_LAUNDRY_GMV, DAILY_LAUNDRY_DATA, 
    DAILY_ONLINE_DATA, MONTHLY_KLEEN_AVGBASKET, MONTHLY_LAUNDRY_AVGBASKET, MONTHLY_SEGMENT, SALES_GROWTH, SALES_GROWTH_LAUNDRY, MONTHLY_KLEEN_AVGSUBSCRIPTION, MONTHLY_KLEEN_SUBSCRIBER } from "./actionTypes";
import { apiSuccess, apiFail } from "./actions";



//Include Both Helper File with needed methods
import { realTimeDetails, fetchTop4Data } from "../../helpers/orderHelper";
import {
  fetchAppRegistartions, fetchUniqueOrders, fetchMonthlyKleenOrders,
  fetchMonthlyLaundryOrders, fetchMonthlyKleenGMV, fetchMonthlyLaundryGMV,
  fetchDailyLaundryData, fetchDailyOnlineData,fetchMonthlyKleenAvgBasket,fetchMonthlyKleenAvgSubscription,fetchMonthlyKleenSubscriber,
  fetchMonthlyLaundryAvgBasket, fetchMonthlySegment, fetchSalesGrowth, fetchSalesGrowthLaundry } from "../../helpers/dashboardHelper";


// function* getChartsData({ payload: periodType }) {
//     try {
//         var response =[];
//         // if (periodType == "monthly") {
//         //     response = yield call(getWeeklyData, periodType);
//         // }
//         // if (periodType == "yearly") {
//         //     response = yield call(getYearlyData, periodType);
//         // }
//         // if (periodType == "weekly") {
//         if (periodType != "") {
//             response = yield call(getMonthlyData, periodType);
//         }

//         yield put(apiSuccess(GET_CHARTS_DATA, response.record));
//     } catch (error) {
//         yield put(apiFail(GET_CHARTS_DATA, error));
//     }
// }

function* getRealTimeDetails({ payload: chartType }) {
  try {

    const response = yield call(realTimeDetails, chartType);
    yield put(apiSuccess(GET_REATIME_DETAILS, response.orders));
  } catch (error) {
    yield put(apiFail(GET_REATIME_DETAILS, error));
  }
}

function* getTop4Data({ payload: data }) {
  try {

    const response = yield call(fetchTop4Data, data);
    yield put(apiSuccess(GET_TOP4_DATA, response));
  } catch (error) {
    yield put(apiFail(GET_TOP4_DATA, error));
  }
}

export function* watchGetChartsData() {
  yield takeEvery(GET_CHARTS_DATA, getChartsData);
}

export function* setTop4Loading() {
  yield put(apiSuccess(SET_LOADING, true));
}

export function* appRegistartions() {
  const response = yield call(fetchAppRegistartions, {});
  yield put(apiSuccess(APP_REGISTRATIONS_MONTHLY, response?.data));
}

export function* getUniqueCustomerOrders() {
  const response = yield call(fetchUniqueOrders, {});
  yield put(apiSuccess(UNIQUE_CUSTOMER_ORDERS, response?.data));
}

export function* getMonthlyKleenOrders() {
  const response = yield call(fetchMonthlyKleenOrders, {});
  yield put(apiSuccess(MONTHLY_KLEEN_ORDERS, response?.data));
}

export function* getMonthlyLaundryOrders({ payload: data }) {
  if (data != undefined && data != "") {
    const response = yield call(fetchMonthlyLaundryOrders, data);
    yield put(apiSuccess(MONTHLY_LAUNDRY_ORDERS, response?.data));
  }
  else {
    yield put(apiSuccess(MONTHLY_LAUNDRY_ORDERS, []));
  }
}

export function* getMonthlyKleenGMV() {
  const response = yield call(fetchMonthlyKleenGMV, {});
  yield put(apiSuccess(MONTHLY_KLEEN_GMV, response?.data));
}

export function* getMonthlyLaundryGMV({ payload: data }) {
  if (data != undefined && data != "") {
    const response = yield call(fetchMonthlyLaundryGMV, data);
    yield put(apiSuccess(MONTHLY_LAUNDRY_GMV, response?.data));
  }
  else {
    yield put(apiSuccess(MONTHLY_LAUNDRY_GMV, []));
  }
}

export function* getMonthlyKleenAvgBasket() {
  try {
    const response = yield call(fetchMonthlyKleenAvgBasket, {});
    yield put(apiSuccess(MONTHLY_KLEEN_AVGBASKET, response?.data));
  } catch (error) {
    yield put(apiFail(MONTHLY_KLEEN_AVGBASKET, error));
  }

}

export function* getMonthlyKleenAvgSubscription({ payload: data }) {
  try {
    const response = yield call(fetchMonthlyKleenAvgSubscription, data);
    yield put(apiSuccess(MONTHLY_KLEEN_AVGSUBSCRIPTION, response?.data));
  } catch (error) {
    yield put(apiFail(MONTHLY_KLEEN_AVGSUBSCRIPTION, error));
  }

}

export function* getMonthlySubscriber({ payload: data }) {
  try {
    const response = yield call(fetchMonthlyKleenSubscriber, data);
    yield put(apiSuccess(MONTHLY_KLEEN_SUBSCRIBER, response?.data));
  } catch (error) {
    yield put(apiFail(MONTHLY_KLEEN_SUBSCRIBER, error));
  }

}

export function* getMonthlyLaundryAvgBasket({ payload: data }) {
  if (data != undefined && data != "") {
    const response = yield call(fetchMonthlyLaundryAvgBasket, data);
    yield put(apiSuccess(MONTHLY_LAUNDRY_AVGBASKET, response?.data));
  }
  else {
    yield put(apiSuccess(MONTHLY_LAUNDRY_AVGBASKET, []));
  }
}

export function* getDailyLaundryData({ payload: data }) {
  const response = yield call(fetchDailyLaundryData, data);
  yield put(apiSuccess(DAILY_LAUNDRY_DATA, response?.data));
}

export function* getDailyOnlineData({ payload: data }) {
  const response = yield call(fetchDailyOnlineData, data);
  yield put(apiSuccess(DAILY_ONLINE_DATA, response?.data));
}

export function* getMonthlySegment() {
  try {
    const response = yield call(fetchMonthlySegment);
    yield put(apiSuccess(MONTHLY_SEGMENT, response?.data));
  } catch (error) {
    yield put(apiFail(MONTHLY_SEGMENT, error));
  }
}

export function* getSalesGrowth({payload: data}) {
  const response = yield call(fetchSalesGrowth, data);
  yield put(apiSuccess(SALES_GROWTH, response?.data));
}

export function* getSalesGrowthLaundry({payload: data}) {
  console.log("data.laundry::", data.laundry);
  if(data.laundry){
    const response = yield call(fetchSalesGrowthLaundry, data);
    yield put(apiSuccess(SALES_GROWTH_LAUNDRY, response?.data));
  }
  else{
    yield put(apiSuccess(SALES_GROWTH_LAUNDRY, []));
  }
}



function* dashboardSaga() {
    yield takeEvery(GET_REATIME_DETAILS, getRealTimeDetails)
    yield takeEvery(GET_TOP4_DATA, getTop4Data)
    yield takeEvery(SET_LOADING, setTop4Loading)
    yield takeEvery(APP_REGISTRATIONS_MONTHLY, appRegistartions)
    yield takeEvery(UNIQUE_CUSTOMER_ORDERS, getUniqueCustomerOrders)
    yield takeEvery(MONTHLY_KLEEN_ORDERS, getMonthlyKleenOrders)
    yield takeEvery(MONTHLY_LAUNDRY_ORDERS, getMonthlyLaundryOrders)
    yield takeEvery(MONTHLY_KLEEN_GMV, getMonthlyKleenGMV)
    yield takeEvery(MONTHLY_LAUNDRY_GMV, getMonthlyLaundryGMV)
    yield takeEvery(DAILY_LAUNDRY_DATA, getDailyLaundryData)
    yield takeEvery(DAILY_ONLINE_DATA, getDailyOnlineData)
    yield takeEvery(MONTHLY_KLEEN_AVGBASKET, getMonthlyKleenAvgBasket)
    yield takeEvery(MONTHLY_KLEEN_AVGSUBSCRIPTION, getMonthlyKleenAvgSubscription)
    yield takeEvery(MONTHLY_KLEEN_SUBSCRIBER, getMonthlySubscriber)
    yield takeEvery(MONTHLY_LAUNDRY_AVGBASKET, getMonthlyLaundryAvgBasket)
    yield takeEvery(MONTHLY_SEGMENT, getMonthlySegment)
    yield takeEvery(SALES_GROWTH, getSalesGrowth)
    yield takeEvery(SALES_GROWTH_LAUNDRY, getSalesGrowthLaundry)
    
}

export default dashboardSaga;
