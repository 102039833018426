import { GET_PROMOS_CODE_SUCCESS, ALREADY_EXITS, CREATE_PROMOS_CODE, UPDATE_PROMOS_CODE, GET_TRANSACTION_SUCCESS } from "./actionTypes"

const INIT_STATE = {
  promoCodes: [],
  transactions: [],
  error: {},
  totalCount: 0
}

const promocodes = (state = INIT_STATE, action) => {

  switch (action.type) {
    case GET_PROMOS_CODE_SUCCESS:
      return { ...state, promoCodes: action.payload.record, totalCount: action.payload.totalCount, error: {} }
    case UPDATE_PROMOS_CODE:
      return { ...state, error: { promocode: "Processing" } }
    case CREATE_PROMOS_CODE:
      return { ...state, error: { promocode: "Processing" } }
    case ALREADY_EXITS:
      return { ...state, error: { promocode: action.payload } }
    case GET_TRANSACTION_SUCCESS:
      return { ...state, transactions: action.payload }
    default:
      return state
  }
}

export default promocodes