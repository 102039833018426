import React from "react"
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  FormGroup,
  Input,
  Label,
} from "reactstrap"
import PropTypes from "prop-types"

const CancelOrderModal = props => {
  const [reasonID, setReasonID] = React.useState("")
  const customReasonDB = props.reasons.find(reason => reason.isOther === true);
  const isCustomReason = customReasonDB && reasonID === customReasonDB._id
  const [customReason, setCustomReason] = React.useState()

  const toggle = () => {
    setReasonID("")
    setCustomReason()
    props.toggle()
  }

  const onAction = () => {
    if (isCustomReason && !customReason) {
      setCustomReason("")
      return
    }
    setCustomReason()
    props.onAction(reasonID, customReason)
    toggle()
  }

  const isSubmitDisabled = !reasonID || (isCustomReason && !customReason)

  return (
    <Modal isOpen={props.isOpen} toggle={toggle} {...props.args}>
      <ModalHeader toggle={toggle}>Cancel Order (Order ID - {props.order.orderID})</ModalHeader>
      <ModalBody>
        <div>
          <div className="mb-4 h5">Please select a reason for cancellation</div>
          {props.reasons.map(reason => {
            return (
              <FormGroup check key={reason._id} className="mb-2">
                <Input
                  type="radio"
                  value={reason._id}
                  checked={reasonID === reason._id}
                  onChange={e => {
                    setReasonID(e.target.value)
                  }}
                />
                <Label check>{reason.reasonEn}</Label>
              </FormGroup>
            )
          })}
          {isCustomReason && (
            <Input
              value={customReason}
              onChange={e => setCustomReason(e.target.value)}
              placeholder="Please enter your reason"
              invalid={customReason === ""}
            />
          )}
        </div>
      </ModalBody>
      <ModalFooter>
        <Button color="primary" onClick={onAction} disabled={isSubmitDisabled}>
          Submit
        </Button>
        <Button color="secondary" onClick={toggle}>
          Close
        </Button>
      </ModalFooter>
    </Modal>
  )
}

CancelOrderModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onAction: PropTypes.func.isRequired,
  toggle: PropTypes.func.isRequired,
  args: PropTypes.object,
  reasons: PropTypes.array,
  order: PropTypes.object,
}

export default React.memo(CancelOrderModal)
