import {
  GET_HOLIDAY_LIST,
  GET_HOLIDAY_DETAIL,
  GET_HOLIDAY_LIST_SUCCESS,
  GET_HOLIDAY_LIST_FAIL,
  GET_HOLIDAY_DETAIL_SUCCESS,
  GET_HOLIDAY_DETAIL_FAIL,
} from "./actionTypes"

export const getHolidaysList = (data) => ({
  type: GET_HOLIDAY_LIST,
  payload: data
})

export const getHolidayDetail = (data) => ({
  type: GET_HOLIDAY_DETAIL,
  payload: data
})

export const getHolidayDetailSuccess = (data) => ({
  type: GET_HOLIDAY_DETAIL_SUCCESS,
  payload: data
})

export const getHolidayDetailFail = (data) => ({
  type: GET_HOLIDAY_DETAIL_FAIL,
  payload: data
})

export const getHolidaysListSuccess = (data) => ({
  type: GET_HOLIDAY_LIST_SUCCESS,
  payload: data
})

export const getHolidaysListFail = (data) => ({
  type: GET_HOLIDAY_LIST_FAIL,
  payload: data
})

