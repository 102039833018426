/** Get Dashboard Chart data */
export const API_SUCCESS = "API_SUCCESS";
export const API_FAIL = "API_FAIL";
export const GET_LAUNDRY_LIST = "GET_LAUNDRY_LIST";
export const GET_LAUNDRY_DETAILS = "GET_LAUNDRY_DETAILS";
export const TOGGLE_LAUNDRY_ACTIVE = "TOGGLE_LAUNDRY_ACTIVE";
export const TOGGLE_LAUNDRY_PERM = "TOGGLE_LAUNDRY_PERM";
export const ADD_LAUNDRY_PERM = "ADD_LAUNDRY_PERM";
export const UPDATE_LAUNDRY_PERM = "UPDATE_LAUNDRY_PERM";
 //DELETE LAUNDRY
export const DELETE_LAUNDRY = "DELETE_LAUNDRY"
export const DELETE_LAUNDRY_SUCCESS = "DELETE_LAUNDRY_SUCCESS"
export const DELETE_LAUNDRY_FAIL = "DELETE_LAUNDRY_FAIL"
