import { call, put, takeEvery } from "redux-saga/effects"
import { postAddUser } from "helpers/userHelper"
import toastr from "toastr";
import "toastr/build/toastr.min.css";

import {
  GET_REASONS_LIST,
  GET_REASONS_LIST_SUCCESS,
  GET_REASONS_LIST_FAIL,
  ADD_NEW_REASON,
  UPDATE_REASON,
  DELETE_REASON
} from "./actionTypes"

//Include Both Helper File with needed methods
import {
  fetchReasonsList
} from "helpers/reasonsHelper"

import { 
  getReasonsListSuccess,
  getReasonsListFail
} from "./actions"


function* getReasonsList({ payload: data }) {
  try {
    const response = yield call(fetchReasonsList, data)

    yield put(getReasonsListSuccess(response))
  } catch (error) {
    yield put(getReasonsListFail(error))
  }
}


function* reasonsSaga() {
  yield takeEvery(GET_REASONS_LIST, getReasonsList)
}

export default reasonsSaga
