import { call, put, takeEvery } from "redux-saga/effects"
import { postAddUser } from "helpers/userHelper"
import toastr from "toastr";
import "toastr/build/toastr.min.css";

import {
  GET_PACKAGES_LIST,
} from "./actionTypes"

//Include Both Helper File with needed methods
import {
  fetchPackagesList  
} from "helpers/packagesHelper"

import {
  getPackagesListSuccess,
  getPackagesListFail
} from "./actions"



function* getPackages({ payload: data }) {

  try {
    const response = yield call(fetchPackagesList, data)
    yield put(getPackagesListSuccess(response))
  } catch (error) {
    yield put(getPackagesListFail(error))
  }
}

function* packagesSaga() {
  yield takeEvery(GET_PACKAGES_LIST, getPackages)
  
}

export default packagesSaga;