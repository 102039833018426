import {
    API_SUCCESS,
    API_FAIL,
    GET_LAUNDRY_LIST,
    GET_LAUNDRY_DETAILS,
    TOGGLE_LAUNDRY_ACTIVE,
    TOGGLE_LAUNDRY_PERM,
    ADD_LAUNDRY_PERM,
    UPDATE_LAUNDRY_PERM,
    DELETE_LAUNDRY,
    DELETE_LAUNDRY_SUCCESS,
    DELETE_LAUNDRY_FAIL
} from "./actionTypes";

export const apiSuccess = (actionType, data) => ({
    type: API_SUCCESS,
    payload: { actionType, data },
});

export const apiFail = (actionType, error) => ({
    type: API_FAIL,
    payload: { actionType, error },
});

export const getLaundrys = (data) => ({
    type: GET_LAUNDRY_LIST,
    payload: data
});
export const addLaundry = (data) => ({
    type: ADD_LAUNDRY_PERM,
    payload: data
});

export const updateLaundry = (data) => ({
    type: UPDATE_LAUNDRY_PERM,
    payload: data
});

export const getLaundrydetails = (LaundryId) => ({
    type: GET_LAUNDRY_DETAILS,
    payload: LaundryId
});

export const toggleLaundryActive = (LaundryDetails) => ({
    type: TOGGLE_LAUNDRY_ACTIVE,
    payload: LaundryDetails
});

export const toggleLaundryPermission = (LaundryDetails) => ({
    type: TOGGLE_LAUNDRY_PERM,
    payload: LaundryDetails
});

export const deleteLaundry = data => ({
  type: DELETE_LAUNDRY,
  payload: data,
})

export const deleteLaundrySuccess = data => ({
  type: DELETE_LAUNDRY_SUCCESS,
  payload: data,
})

export const deleteLaundryFail = error => ({
  type: DELETE_LAUNDRY_FAIL,
  payload: error,
})
