import {
    API_SUCCESS,
    API_FAIL,
    GET_AREA_LIST,
    GET_AREA_DETAILS,
    TOGGLE_AREA_ACTIVE,
    GET_LAUNDRIES_LIST
} from "./actionTypes";
import {nullOrEmpty} from "../../helpers/common_helper";


const INIT_STATE = {
    AreaList: [],
    AreaResponse : {},
    AreaDetails: {}
};

const Laundries = (state = INIT_STATE, action) => {
    switch (action.type) {
        case TOGGLE_AREA_ACTIVE: 
            let _AreaDetails = action.payload;
            _AreaDetails.active = !_AreaDetails.active;
            return {
                ...state,
                AreaDetails: _AreaDetails
            };

       
      
        case API_SUCCESS:

            switch (action.payload.actionType) {
                case GET_LAUNDRIES_LIST:
                    return {
                        ...state,
                        AreaList: action.payload.data
                    };

                case GET_AREA_DETAILS:
                    return {
                        ...state,
                        AreaDetails: action.payload.data
                    };
               
                default:
                    return state;
            }
        case API_FAIL:
            switch (action.payload.actionType) {
                case GET_LAUNDRIES_LIST:
                    return {
                        ...state,
                        AreaResponse: action.payload.error
                    };

                case GET_AREA_DETAILS:
                    return {
                        ...state,
                        AreaResponse: action.payload.error,
                    };

                default:
                    return state;
            }
        default:
            return state;
    }
}


export default Laundries;