import axios from "axios"
import { del, get, postWeb, put } from "./api_helper"
import * as url from "./url_helper"
const filter = JSON.parse(sessionStorage.getItem("filter"));

const fetchb2bProductList = (data) => postWeb(`${url.GET_B2B_PRODUCTS}`, data);
const postAddb2bProduct = (data) => postWeb(`${url.POST_ADD_B2BPRODUCT}`, data)
const postUpdateb2bProduct = (data) => postWeb(`${url.POST_UPDATE_B2BPRODUCT}`, data)



export {
  fetchb2bProductList,
  postAddb2bProduct,
  postUpdateb2bProduct
}
