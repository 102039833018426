import React from "react"
import PropTypes from "prop-types"
import moment from "moment-timezone";

const ActivityItem = props => {
  if(props.eventDate)
  return (
    <li className={"event-list " + (props.active?"active":"")}>
      <div className="event-timeline-dot">
        <i className={"bx  font-size-18 " + (props.active?"bxs-right-arrow-circle bx-fade-right":"bx-right-arrow-circle")}></i>
      </div>
      <div className="media">
        <div className="me-3">
          <h5 className="font-size-14">
            {moment(new Date(props.eventDate)).format("D MMM")}{" "}
            <i className="bx bx-right-arrow-alt font-size-16 text-primary align-middle ms-2"></i>
          </h5>
        </div>
        <div className="media-body">
          <div>
            {props.title}
          </div>
        </div>
      </div>
    </li>
  )
  else
    return <></>
}
ActivityItem.propTypes = {
  eventDate: PropTypes.string,
  title: PropTypes.string,
  active : PropTypes.bool
}

export default ActivityItem;