import {
  REPORT_DATA, API_SUCCESS, API_FAIL
} from "./actionTypes";

const INIT_STATE = {
  reportData: [],
  resp:"",
  // filePath:{}
};

const Permission = (state = INIT_STATE, action) => {
  switch (action.type) {
    case API_SUCCESS:
      switch (action.payload.actionType) {
        case REPORT_DATA:
          return {
            ...state,
            reportData: action.payload.data,
            resp:"success",
            // filePath:action.payload.data
          };
        default:
          return state;
      }
    case API_FAIL:
      switch (action.payload.actionType) {
        case REPORT_DATA:
          return {
            ...state,
            reportData: [],
            resp:"fail",
            filePath:action.payload.data
          };
        default:
          return state;
      }
    default:
      return state;
  }
}


export default Permission;