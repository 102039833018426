import {
    API_SUCCESS,
    API_FAIL,
    GET_AREA_LIST,
    GET_AREA_DETAILS,
    TOGGLE_AREA_ACTIVE
} from "./actionTypes";

export const apiSuccess = (actionType, data) => ({
    type: API_SUCCESS,
    payload: { actionType, data },
});

export const apiFail = (actionType, error) => ({
    type: API_FAIL,
    payload: { actionType, error },
});

export const getAreas = (data) => ({
    type: GET_AREA_LIST,
    payload: data
});

export const getAreadetails = (areaId) => ({
    type: GET_AREA_DETAILS,
    payload: areaId
});

export const toggleAreaActive = (AreaDetails) => ({
    type: TOGGLE_AREA_ACTIVE,
    payload: AreaDetails
});


