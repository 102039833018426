import {
  GET_CLOTHES_LIST_SUCCESS,
  GET_CLOTHES_LIST_FAIL,
  DELETE_CLOTHES_SUCCESS,
  DELETE_CLOTHES_FAIL,
  UPDATE_CLOTHES_SUCCESS,
  UPDATE_CLOTHES_FAIL,
  ADD_NEW_CLOTHES_FAIL
} from "./actionTypes"

const INIT_STATE = {
  clothesList: []
}

const Clothes = (state = INIT_STATE, action) => {
  switch (action.type) {

    case GET_CLOTHES_LIST_SUCCESS:
      return {
        ...state,
        clothesList: action.payload.record,
      }

    case GET_CLOTHES_LIST_FAIL:
      return {
        ...state,
        error: action.payload,
      }
  
      case ADD_NEW_CLOTHES_FAIL:
        return {
          ...state,
          error: action.payload,
        }

      case UPDATE_CLOTHES_SUCCESS:
        return {
          ...state,
          clothesList: state.clothesList.map((item) => {
            if (item._id === action.payload._id)
              return {...item, ...action.payload}
            else
              return item
          })
        };
  
      case UPDATE_CLOTHES_FAIL:
        return {
          ...state,
          error: action.payload,
        }

      case DELETE_CLOTHES_SUCCESS:
        return {
          ...state,
          clothesList: state.clothesList.filter((item) => item._id !== action.payload._id),
        }
  
      case DELETE_CLOTHES_FAIL:
        return {
          ...state,
          error: action.payload,
        }

    default:
      return state
  }
}

export default Clothes
