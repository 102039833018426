
export const API_SUCCESS = "API_SUCCESS";
export const API_FAIL = "API_FAIL";

// VIEW REDEEM PACKAGES  
export const GET_PACKAGES_LIST = "GET_PACKAGES_LIST";
export const GET_PACKAGES_LIST_SUCCESS = "GET_PACKAGES_LIST_SUCCESS";
export const GET_PACKAGES_LIST_FAIL = "GET_PACKAGES_LIST_FAIL";
export const GET_PACKAGE_DETAIL = "GET_PACKAGE_DETAIL";
export const GET_PACKAGE_DETAIL_SUCCESS = "GET_PACKAGE_DETAIL_SUCCESS";
export const GET_PACKAGE_DETAIL_FAIL = "GET_PACKAGE_DETAIL_FAIL";