import {
    API_SUCCESS,
    API_FAIL,
    GET_CHARTS_DATA,
    GET_CHARTS_DATA_RADIAL,
    GET_REATIME_DETAILS,
    GET_TOP4_DATA,
    SET_LOADING,
    APP_REGISTRATIONS_MONTHLY,
    UNIQUE_CUSTOMER_ORDERS,
    MONTHLY_KLEEN_ORDERS,
    MONTHLY_LAUNDRY_ORDERS,
    MONTHLY_KLEEN_GMV,
    MONTHLY_LAUNDRY_GMV,
    DAILY_ONLINE_DATA,
    DAILY_LAUNDRY_DATA,
    MONTHLY_KLEEN_AVGBASKET,
    MONTHLY_LAUNDRY_AVGBASKET,
    MONTHLY_SEGMENT,
    SALES_GROWTH,
    SALES_GROWTH_LAUNDRY,
    MONTHLY_KLEEN_AVGSUBSCRIPTION,
    MONTHLY_KLEEN_SUBSCRIBER
} from "./actionTypes";

export const apiSuccess = (actionType, data) => ({
    type: API_SUCCESS,
    payload: { actionType, data },
});

export const apiFail = (actionType, error) => ({
    type: API_FAIL,
    payload: { actionType, error },
});

// charts data
export const getChartsData = (periodType) => ({
    type: GET_CHARTS_DATA,
    payload: periodType
});

export const getRealTimeDetails = (chartType) => ({
    type: GET_REATIME_DETAILS,
    payload: chartType
});

export const getTop4Data = (data) => ({
    type: GET_TOP4_DATA,
    payload: data
});

export const setTop4Loading = (data) => ({
    type: SET_LOADING,
    payload: data
});



export const getChartsDataRadial = (periodType) => ({
    type: GET_CHARTS_DATA_RADIAL,
    payload: periodType
});

export const appRegistartions = () => ({
  type: APP_REGISTRATIONS_MONTHLY,
  payload: {}
});

export const getUniqueCustomerOrders = () => ({
  type: UNIQUE_CUSTOMER_ORDERS,
  payload: {}
});

export const getMonthlyKleenOrders = () => ({
  type: MONTHLY_KLEEN_ORDERS,
  payload: {}
});

export const getMonthlyLaundryOrders = (laundry) => ({
  type: MONTHLY_LAUNDRY_ORDERS,
  payload: laundry
});


export const getMonthlyKleenGMV = () => ({
  type: MONTHLY_KLEEN_GMV,
  payload: {}
});

export const getMonthlyLaundryGMV = (laundry) => ({
  type: MONTHLY_LAUNDRY_GMV,
  payload: laundry
});

export const getMonthlyKleenAvgBasket = () => ({
  type: MONTHLY_KLEEN_AVGBASKET,
  payload: {}
});

export const getMonthlyKleenAvgSubscription = (area) => ({
  type: MONTHLY_KLEEN_AVGSUBSCRIPTION,
  payload: area
});

export const getMonthlySubscriber = (area) => ({
  type: MONTHLY_KLEEN_SUBSCRIBER,
  payload: area
});

export const getMonthlyLaundryAvgBasket = (laundry) => ({
  type: MONTHLY_LAUNDRY_AVGBASKET,
  payload: laundry
});

export const getMonthlySegment = () => ({
  type: MONTHLY_SEGMENT,
  payload: {}
});

export const getSalesGrowth = (data) => ({
  type: SALES_GROWTH,
  payload: data
});

export const getSalesGrowthLaundry = (data) => ({
  type: SALES_GROWTH_LAUNDRY,
  payload: data
});

export const getDailyLaundryData = (data) => ({
  type: DAILY_LAUNDRY_DATA,
  payload: data
});

export const getDailyOnlineData = (data) => ({
  type: DAILY_ONLINE_DATA,
  payload: data
});
