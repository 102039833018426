import React, { useEffect } from "react"
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Input,
  FormGroup,
  Label,
  FormFeedback,
} from "reactstrap"
import { useFormik } from "formik"
import * as Yup from "yup"
import DatePicker from "react-datepicker"
import PropTypes from "prop-types"
import MultiSelect from "react-multiple-select-dropdown-lite"
import Switch from "react-switch"
import { postArea } from "helpers/LaundryHelper"
import { is } from "immutable"

const Offsymbol = () => {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100%",
        fontSize: 12,
        color: "#fff",
        paddingRight: 2,
      }}
    >
      {" "}
      Off
    </div>
  )
}

const OnSymbol = () => {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100%",
        fontSize: 12,
        color: "#fff",
        paddingRight: 2,
      }}
    >
      {" "}
      On
    </div>
  )
}

const EditTimeSlotModal = props => {
  const onAction = async () => {
    props.onAction(formik.values, props.timeSlot?._id)
   
  }

  const formik = useFormik({
    initialValues: {
      start: "00:00",
      end: "00:00",
      closingTime: "00:00",
      areas: [],
      status: true,
    },
    onSubmit: onAction,
    validationSchema: Yup.object({
      start: Yup.string().required("Required"),
      end: Yup.string()
        .required("Required")
        .test({
          message: "End time should be after the start time",
          test: (value, context) => {
            return context.parent.start < value
          },
        }),
      closingTime: Yup.string()
        .required("Required")
        .required("Required")
        .test({
          message: "Closing time should be between start and end times",
          test: (value, context) => {
            const isAfterStart = context.parent.start <= value
            const isBeforeEnd = context.parent.end >= value
            return isAfterStart && isBeforeEnd
          },
        }),
      areas: Yup.array().min(1).required("Required"),
      category: Yup.string().required("Required"),
      status: Yup.boolean(),
    }),
  })

  const selectedDate = timeString => {
    const date = new Date()
    let [hours, minutes] = timeString.split(":")
    date.setHours(parseInt(hours))
    date.setMinutes(parseInt(minutes))
    return date
  }

  React.useEffect(() => {
    formik.resetForm()
    if (props.timeSlot) {
      formik.setFieldValue("start", props.timeSlot.start || "00:00")
      formik.setFieldValue("end", props.timeSlot.end || "00:00")
      formik.setFieldValue("closingTime", props.timeSlot.closingTime || "00:00")
      formik.setFieldValue(
        "areas",
        props.timeSlot.areas.map(area => area._id)
      )
      formik.setFieldValue("status", props.timeSlot.status)
      formik.setFieldValue("enableAllAreas", props.timeSlot.enableAllAreas)
      formik.setFieldValue("category", props.timeSlot.category)

    }
  }, [props.timeSlot])

  const [areas, setAreas] = React.useState([])

  React.useEffect(async () => {
    formik.resetForm()
    const AreaResponse = await postArea({})
    setAreas(
      AreaResponse?.record?.map(item => {
        return { label: item?.name, value: item?._id, ...item }
      })
    )
  }, [])

  useEffect(() => {
    if(props.toggle == false){
      formik.resetForm()
    }
  }, [props.toggle])

  return (
    <Modal isOpen={props.isOpen} toggle={props.toggle} {...props.args}>
      <ModalHeader toggle={props.toggle}>
        {props.timeSlot ? "Edit" : "Add"} Time Slot
      </ModalHeader>
      <ModalBody>
        <form onSubmit={formik.handleSubmit}>
        <FormGroup>
          <Label>Start Time</Label>
          <DatePicker
            id="start"
            selected={selectedDate(formik.values.start)}
            onChange={val => {
              const hours = val?val.getHours().toString().padStart(2, 0):0
              const minutes = val?val.getMinutes().toString().padStart(2, 0):0
              const timeString = `${hours}:${minutes}`
              formik.setFieldValue("start", timeString)
              formik.setFieldTouched("start")
            }}
            showTimeSelect
            showTimeSelectOnly
            timeIntervals={15}
            timeCaption="Time"
            timeFormat="HH:mm"
            dateFormat="HH:mm"
            className="form-control"
          />
          <Input
            className="d-none"
            invalid={!!formik.touched.start && !!formik.errors.start}
          />
          {/* {formik.errors.start ? ( */}
          <FormFeedback>{formik.errors.start}</FormFeedback>
          {/* ) : null} */}
        </FormGroup>
        <FormGroup className="mt-3">
          <Label>End Time</Label>
          <DatePicker
            id="end"
            selected={selectedDate(formik.values.end)}
            onChange={val => {
              const hours = val?val.getHours().toString().padStart(2, 0):0
              const minutes = val?val.getMinutes().toString().padStart(2, 0):0
              const timeString = `${hours}:${minutes}`
              formik.setFieldTouched("end")
              formik.setFieldValue("end", timeString)
            }}
            showTimeSelect
            showTimeSelectOnly
            timeIntervals={15}
            timeCaption="Time"
            timeFormat="HH:mm"
            dateFormat="HH:mm"
            className="form-control"
          />
          <Input
            className="d-none"
            invalid={!!formik.touched.end && !!formik.errors.end}
          />
          <FormFeedback>{formik.errors.end}</FormFeedback>
        </FormGroup>
        <FormGroup className="mt-3">
          <Label>Closing Time</Label>
          <DatePicker
            id="start"
            selected={selectedDate(formik.values.closingTime)}
            onChange={val => {
              const hours = val?val.getHours().toString().padStart(2, 0):0
              const minutes = val?val.getMinutes().toString().padStart(2, 0):0
              const timeString = `${hours}:${minutes}`
              formik.setFieldTouched("closingTime")
              formik.setFieldValue("closingTime", timeString)
            }}
            showTimeSelect
            showTimeSelectOnly
            timeIntervals={15}
            timeCaption="Time"
            timeFormat="HH:mm"
            dateFormat="HH:mm"
            className="form-control"
          />
          <Input
            className="d-none"
            invalid={
              !!formik.touched.closingTime && !!formik.errors.closingTime
            }
          />
          <FormFeedback invalid>{formik.errors.closingTime}</FormFeedback>
        </FormGroup>
        <FormGroup className="mt-3">
          <Label>Areas</Label>
          <MultiSelect
            defaultValue={formik.values.areas.join(",")}
            className="multi-selects form-selects2 form-controlss areas-box"
            onChange={val => {
              formik.setFieldTouched("areas")
              formik.setFieldValue("areas", !!val ? val.split(",") : [])
            }}
            options={areas}
          />
          <Input
            className="d-none"
            invalid={!!formik.touched.areas && !!formik.errors.areas}
          />
          <FormFeedback>{formik.errors.areas}</FormFeedback>
        </FormGroup>
        <FormGroup className="mt-3">
          <Label>Category</Label>
          <Input
            value={formik.values.category}
            className="form-control"
            type="select"
            required
            onChange={e => {
              formik.setFieldTouched("category")
              formik.setFieldValue("category", e.target.value)
            }}
            invalid={!!formik.touched.category && !!formik.errors.category}   
          >
            <option value="">Select Category</option>
            <option value="clothes">Clothes</option>
            <option value="carwash">Carwash</option>
          </Input>
                    <FormFeedback>{formik.errors.areas}</FormFeedback>
        </FormGroup>
        
        <FormGroup className="mt-3">
          <Label style={{ marginRight: "10px" }}>Status</Label>
          <Switch
            uncheckedIcon={<Offsymbol />}
            checkedIcon={<OnSymbol />}
            onColor="#626ed4"
            onChange={() =>
              formik.setFieldValue("status", !formik.values.status)
            }
            checked={formik.values.status}
          />
        </FormGroup>
        <FormGroup className="mt-3">
          <Label style={{ marginRight: "10px" }}>All Areas</Label>
          <Switch
            uncheckedIcon={<Offsymbol />}
            checkedIcon={<OnSymbol />}
            onColor="#626ed4"
            onChange={() =>
              formik.setFieldValue("enableAllAreas", !formik.values.enableAllAreas)
            }
            checked={formik.values.enableAllAreas}
          />
        </FormGroup>
        <div className="mt-2 d-flex flex-row-reverse">
          <Button className="ms-2" color="secondary" onClick={props.toggle}>
            Close
          </Button>

          <Button
            color="primary"
            type="submit"
          >
            Save
          </Button>
          
        </div>
        </form>
      </ModalBody>
      <ModalFooter>
        
      </ModalFooter>
    </Modal>
  )
}

EditTimeSlotModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onAction: PropTypes.func.isRequired,
  toggle: PropTypes.func.isRequired,
  args: PropTypes.object,
  timeSlot: PropTypes.object,
}

export default React.memo(EditTimeSlotModal)
