import {
  API_SUCCESS,
  API_FAIL,
  GET_COUPON,
  UPDATE_COUPON,
  ADD_COUPON
} from "./actionTypes";

export const apiSuccess = (actionType, data) => ({
  type: actionType,
  payload: data,
});

export const apiFail = (actionType, error) => ({
  type: actionType,
  payload: error,
});

export const getCouponlist = (data) => ({
  type: GET_COUPON,
  payload: data,
});

export const updateCoupon = (data) => ({
  type: UPDATE_COUPON,
  payload: data,
});

export const addCoupon = (data) => ({
  type: ADD_COUPON,
  payload: data,
});
