import {
    API_SUCCESS,
    API_FAIL,
    UPDATE_CUSTOMERS_BAG,
    REFUND_AMOUNT,
    GET_REASONS,
    GET_ONGOING_ORDERS,
    GET_HISTORY_ORDERS,
    GET_ALL_ORDERS,
    GET_ALL_ORDERS_SEARCH,
    UPDATE_ORDER_LIST,
    UPDATE_ORDERS_INVOICE,
    UPDATE_ORDER_PAY_WITH_CASH,
    SET_ACTIVE_TAB,
    ONGOING_ORDERS_LOADED, 
    SEARCH_ORDERS,
    GET_ORDER_DETAILS,
    GET_REFUND_ORDERS,
    GET_ALL_ORDERS_PAGINATED,
    GET_FILTER_BYSEARCH,
    REMOVE_BAG_FEE,
    GET_CANCELLED_ORDERS,
    GET_COMPLETED_ORDERS,
    GET_RETURNED_ORDERS,
    GET_WHATSAPP_ORDERS
} from "./actionTypes";

export const apiSuccess = (actionType, data) => ({
    type: API_SUCCESS,
    payload: { actionType, data },
});

export const apiFail = (actionType, error) => ({
    type: API_FAIL,
    payload: { actionType, error },
});

export const refund = (data) => ({
  type: REFUND_AMOUNT,
  payload: data
});

export const getReasons = (data) => ({
  type: GET_REASONS,
  payload: data
});

// charts data
export const getOngoingOrders = (data) => ({
    type: GET_ONGOING_ORDERS,
    payload: data
});

export const getAllOrders = (data) => ({
    type: GET_ALL_ORDERS,
    payload: data
});

export const searchOrders = (data) => ({
  type: SEARCH_ORDERS,
  payload: data
});

export const getAllOrdersSearch = (data) => ({
    type: GET_ALL_ORDERS_SEARCH,
    payload: data
});

export const setOrdersActiveTab = (data) => ({
  type: SET_ACTIVE_TAB,
  payload: data
});

export const updateOrderList = (data) => ({
  type: UPDATE_ORDER_LIST,
  payload: data
});



export const updateOrdersInvoice = (data) => ({
    type: UPDATE_ORDERS_INVOICE,
    payload: data
});

export const getHistoryOrders = (dates) => ({
    type: GET_HISTORY_ORDERS,
    payload: dates
});

export const updateCustomers = (data) => ({
  type: UPDATE_CUSTOMERS_BAG,
  payload: data
});

export const onGoingOrderLoaded = (isLoaded) => ({
  type: ONGOING_ORDERS_LOADED,
  payload: isLoaded
});

export const getOrderDetails = (_id) => ({
  type: GET_ORDER_DETAILS,
  payload: _id
});

export const getRefundOrders = (data) => ({
  type: GET_REFUND_ORDERS,
  payload: data
});

export const getAllOrdersPaginated = (data) => ({
  type: GET_ALL_ORDERS_PAGINATED,
  payload: data
});

export const getCompletedOrders = (data) => ({
  type: GET_COMPLETED_ORDERS,
  payload: data
});

export const getReturnedOrders = (data) => ({
  type: GET_RETURNED_ORDERS,
  payload: data
});

export const getWhatsappOrders = (data) => ({
  type: GET_WHATSAPP_ORDERS,
  payload: data
});

export const updateOrderPayWithCash = (data) => ({
  type: UPDATE_ORDER_PAY_WITH_CASH,
  payload: data
});

export const getFilterBySearch = (data) => ({
  type: GET_FILTER_BYSEARCH,
  payload: data
});

export const removeBagFee = (data) => ({
  type: REMOVE_BAG_FEE,
  payload: data
});

export const getCancelledOrders = (data) => ({
  type: GET_CANCELLED_ORDERS,
  payload: data
});
