import {
  GET_PACKAGES_LIST_SUCCESS,
  GET_PACKAGES_LIST_FAIL,
  GET_PACKAGE_DETAIL_SUCCESS,
  GET_PACKAGE_DETAIL_FAIL
} from "./actionTypes"

const INIT_STATE = {
  Packages: []
}

const Packages = (state = INIT_STATE, action) => {
  switch (action.type) {

    case GET_PACKAGES_LIST_SUCCESS:
      return {
        ...state,
        Packages: action.payload.record,
      }
    
    case GET_PACKAGE_DETAIL_SUCCESS:
      return {
        ...state,
        PackageDetails: action.payload.record,
      }

    case GET_PACKAGES_LIST_FAIL:
    case GET_PACKAGE_DETAIL_FAIL : 
      return {
        ...state,
        error: action.payload,
      }
  

    default:
      return state
  }
}

export default Packages
