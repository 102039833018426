import {
  API_SUCCESS,
  API_FAIL,
  LIST_TIMESLOTS,
  CREATE_TIMESLOT,
  UPDATE_TIMESLOT,
  GET_PICKUP_TIMESLOTS,
  GET_DELIVERY_TIMESLOTS,
} from "./actionTypes"

const INIT_STATE = {
  timeslots: [],
  pickupTimeslots: [],
  deliveryTimeslots: []
}

const Timeslots = (state = INIT_STATE, action) => {
  switch (action.type) {
    case API_SUCCESS:
      switch (action.payload.actionType) {
        case LIST_TIMESLOTS:
          return {
            ...state,
            timeslots: action.payload.data.record 
          };
        
        case GET_PICKUP_TIMESLOTS:
          return {
            ...state,
            pickupTimeslots: action.payload.data.record 
          };
        
        case GET_DELIVERY_TIMESLOTS:
          return {
            ...state,
            deliveryTimeslots: action.payload.data.record 
          };

        case CREATE_TIMESLOT:
          let createTimeslots = [...state.timeslots]
          createTimeslots.push(action.payload.data)
          return {
            ...state,
            timeslots: createTimeslots
          };

        case UPDATE_TIMESLOT:
          console.log("state", state)
          const updatedTimeslot = action.payload.data
          let timeslots = [...state.timeslots]

          let index = timeslots.findIndex(
            timeslot => timeslot?._id === updatedTimeslot?._id
          )

          if(index === -1) {
            return {
              ...state,
            }
          }

          timeslots[index] = updatedTimeslot

          return {
            ...state,
            timeslots
          }


        default:
          return state;
      }

    case API_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    default:
      return state
  }
}

export default Timeslots
