import React, { useState, useRef } from "react"
import MetaTags from 'react-meta-tags';
import { Button, Card, CardBody, Col, Container, Modal, Input, Label, Row, InputGroup, Form, FormGroup, ButtonGroup } from "reactstrap"
import "@vtaits/react-color-picker/dist/index.css"
import "react-datepicker/dist/react-datepicker.css"
//Import Flatepicker
import "flatpickr/dist/themes/material_blue.css"
import Flatpickr from "react-flatpickr"
import moment from "moment";
import "assets/css/Allorders.css";
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table"

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux"
import {
  dataReport
} from "store/actions"
import PropTypes from "prop-types";
import toastr from "toastr";
import "toastr/build/toastr.min.css";
import { CSVLink, CSVDownload } from "react-csv";


const RolePermission = () => {
  const csvLink = useRef()
  const dispatch = useDispatch();
  const [error, setError] = useState({});
  const [start, setStart] = useState("");
  const [end, setEnd] = useState("");
  const [exportReportData, setExportReportData] = useState([]);
  const [fileName, SetFileName] = useState("")
  const [loader, SetLoader] = useState()

  const { reportData, resp, filePath } = useSelector(state => ({
    reportData: state.dataReports.reportData,
    resp: state?.records,
    filePath: state.dataReports.filePath
  }))


  const getHoursBetweenTwoDates = (toDate, fromDate) => {
    let endDate = new Date(toDate);
    let startDate = new Date(fromDate);
    let distance = endDate - startDate;
    let hours = Math.floor(distance / (1000 * 60 * 60));
    let totalDays = hours / 24;
    return totalDays.toFixed(2);
  }

  const setOrderCsvData = (reportData) => {
    let exportData = []
    // for (let index = 0; index < reportArr.length; index++) {
    //   const element = reportArr[index];
    //   let customerId = element?.customer?.customerID ? element.customer.customerID : "-";
    //   // let orderId = element.orderID.toString();
    //   let orderId = element.orderID;
    //   let category = element.category == "offline" ? "Offline" : "Online";
    //   let bagNo = element?.customer?.bagNo ? element.customer.bagNo : "-";
    //   let areaName = element?.location?.area?.name ? element.location.area.name : "-";
    //   let pickupDriver = element?.pickupDriver?.name ? element.pickupDriver.name : "-";
    //   let dropOffDriver = element?.dropoffDriver?.name ? element.dropoffDriver.name : "-";
    //   let qty = element.clothes.length ? element.clothes.reduce((accumulator, object) => {
    //     return accumulator + (object.iCount + object.wCount + object.dCount);
    //   }, 0) : 0.0;
    //   let orderDate = element?.startTime ? element.startTime : "";
    //   let deliveryDate = element?.endTime ? element.endTime : "";
    //   let laundryInDate = element?.invoiceTime ? element.invoiceTime : "";
    //   let laundryOutDate = element?.readyToDeliveryTime ? element.readyToDeliveryTime : "";
    //   let paymentTime = element?.paymentTime ? element.paymentTime : "-";

    //   let billNo = [];
    //   let laundries = [];

    //   let totalBillAmt = 0.0;
    //   let totalBillVat = 0.0;
    //   let totalCommAmt = 0.0;
    //   let commissions = [];
    //   let commissionPercentages = [];
    //   element?.bills?.length && element.bills.map((object) => {
    //     let billAmt = object.billAmount
    //     let billVat = object.vatAmt
    //     let commissionAmt = (billAmt * object.commission) / 100

    //     billNo.push(object.billNo);
    //     laundries.push(object.laundry.nameEn);
    //     commissionPercentages.push(object.commission);
    //     commissions.push(commissionAmt);

    //     totalBillAmt += billAmt
    //     totalBillVat += billVat
    //     totalCommAmt += commissionAmt
    //   })
    //   laundries = laundries.join(" - ");
    //   billNo = billNo.join(" - ");
    //   commissions = commissions.join(" - ");
    //   commissionPercentages = commissionPercentages.join(" - ");
    //   let markUp = element.total - totalBillAmt;
    //   let accurateBillAmt = totalBillAmt - totalCommAmt
    //   let clothType = element?.type == "carpet" ? "Carpet" : "Clothes";
    //   let amount = element?.total ? element?.total : 0;
    //   let paymentMethod = element?.payments?.length ? element.payments.map((object) => {
    //     return object.method;
    //   }).join(" - ") : "";
    //   let leadTime = (element?.endTime && element?.startTime) ? getHoursBetweenTwoDates(element.endTime, element.startTime) : 0;
    //   let cycleTime = (element.outLaundryTime && element.invoiceTime) ? getHoursBetweenTwoDates(element.outLaundryTime, element.invoiceTime) : 0;
    //   let totalAmount = element.total ? element.total : 0;
    //   let discountAmount = element.discount ? element.discount : 0;
    //   let valAmount = element.vatAmt ? element.vatAmt : 0;
    //   let grandTotal = element.grandTotal ? element.grandTotal : 0;
    //   let refundAmount = element?.refund ? element.refund : 0;

    //   let pushObject = {
    //     "Customer ID": customerId,
    //     "Order ID": orderId,
    //     "Category": category,
    //     "Bag No": bagNo,
    //     "Area": areaName,
    //     "Pickup Driver": pickupDriver,
    //     "Drop Off Driver": dropOffDriver,
    //     "Total Pieces": qty,
    //     "Order Time": orderDate ? moment(orderDate).format("h:mm a") : "-",
    //     "Order Date": orderDate ? orderDate.split(" ")[0] : "-",
    //     "MonthYear": orderDate ? moment(orderDate).format("MMM-YY") : "-",
    //     "Delivery Time": deliveryDate ? moment(deliveryDate).format("h:mm a") : "-",
    //     "Delivery Date": deliveryDate ? deliveryDate.split(" ")[0] : "-",
    //     "Laundry In Time": laundryInDate ? moment(laundryInDate).format("h:mm a") : "-",
    //     "Laundry In Date": laundryInDate ? laundryInDate.split(" ")[0] : "-",
    //     "Laundry Out Time": laundryOutDate ? moment(laundryOutDate).format("h:mm a") : "-",
    //     "Laundry Out Date": laundryOutDate ? laundryOutDate.split(" ")[0] : "-",
    //     "Payment Time": paymentTime,
    //     "Laundry Name": laundries,
    //     "Bill No": billNo,
    //     "Cloth Type": clothType,
    //     "Amount": amount,
    //     "Payment Method": paymentMethod,
    //     "Lead Time": leadTime,
    //     "Laundry Cycle": cycleTime,
    //     "Total": totalAmount,
    //     "Discount": discountAmount,
    //     "VAT Amount": valAmount,
    //     "Grand Total": grandTotal,
    //     "Refund Amount": refundAmount
    //   }

    //   let discountType = element?.promocode?.type ? element.promocode.type : "-";
    //   let cashBack = element?.promocode?.cashback ? element.promocode.cashback : "0";
    //   let refund = element?.refund?.amount ? element.refund.amount : "0";
    //   let refundMethod = element?.refund?.method ? element.refund.method : "-";
    //   let comments = element?.refund?.comments ? element.refund.comments : "-";

    //   let pushFinancialData = {
    //     "Order ID": orderId,
    //     "Category": category,
    //     "Customer ID": customerId,
    //     "Start Date": orderDate ? orderDate.split(" ")[0] : "-",
    //     "Invoice Date": laundryInDate ? laundryInDate.split(" ")[0] : "-",
    //     "Invoice No": element?.invoiceNo ? element.invoiceNo : "-",
    //     "Payment Time": paymentTime,
    //     "Quantity": qty,
    //     "Laundry Name": laundries,
    //     "Bill No": billNo,
    //     "PaymentMethod": paymentMethod,
    //     "Discount": discountAmount,
    //     "Discount Type": discountType,
    //     "Cashback": cashBack,
    //     "Service Total": element?.subTotal ? element.subTotal : 0,
    //     "Bag Price": element?.bagFee ? element?.bagFee : 0,
    //     "Delivery Fee": element?.delFee ? element.delFee : 0,
    //     "Total Without VAT": "SR " + element.total,
    //     "VAT": element?.vatAmt ? element.vatAmt : 0,
    //     "Grand Total": element?.grandTotal ? element.grandTotal : 0,
    //     "Bill Amount Without VAT": totalBillAmt,
    //     "Commission %": commissionPercentages,
    //     "Commission": commissions,
    //     "Markup": markUp,
    //     "Accurate Bill": accurateBillAmt,
    //     "Refund": refund,
    //     "Refund Method": refundMethod,
    //     "Comment": comments
    //   }
    //   // if (fileName == "order")
    //   //   exportData.push(pushObject);
    //   // else
    //   //   exportData.push(pushFinancialData);
    // }
    downloadDataReport(reportData);
  }

  function download(REACT_APP_API_BASE_URL, fileName) {
    var a = document.createElement("a");
    a.href = REACT_APP_API_BASE_URL;
    a.setAttribute("download", fileName);
    a.click();
  }

  const downloadDataReport = (reportData) => {
    setExportReportData(reportData)
    csvLink.current.link.click();
    setTimeout(() => {
      SetLoader(false)
    }, 500);
  }

  useEffect(() => {
    if (reportData?.length) {
      // if (fileName == "order") {
      setOrderCsvData(reportData);
      // }
    }
  }, [reportData])

  const handleStartDate = (selectedDate) => {
    let startDate = moment(selectedDate[0]).format("YYYY-MM-DD");
    setStart(startDate);
  }

  const handleEndDate = (selectedDate) => {
    let endDate = moment(selectedDate[0]).format("YYYY-MM-DD");
    setEnd(endDate);
  }

  const handleDataReport = (type) => {
    let apiData = { start, end, type, deviceType: "Web" };
    if (!start && !end) {
      setError({ start: "Select a date", end: "Select a date" })
      return;
    }
    if (!start) {
      setError({ start: "Select a date" })
      return;
    }
    if (!end) {
      setError({ end: "Select a date" })
      return;
    }
    SetFileName(type)
    SetLoader(true)
    dispatch(dataReport(apiData))
    
    
    
    // if (resp === "success" || filePath) {
    //   // const newFilePath = filePath.replaceAll(' ','')
    //   console.log("http://localhost:3000"+"/"+filePath);
    //   var a = document.createElement("a");
    //   a.href = "http://localhost:3000"+"/"+filePath ;
    //   a.target="_blank"
    //   a.setAttribute("download", type);
    //   a.click();
    // }
    // console.log("resp",resp);
    // console.log("filePath",filePath);
  }



  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Data Reports</title>
        </MetaTags>
        <Container fluid={true}>
          <Breadcrumbs title="Home" breadcrumbItem="Data Reports" />

          <Row>
            <Col lg="12">
              <Card>
                <CardBody>
                  <h4 className="card-title">Data Reports</h4>
                  {/* <p className="card-title-desc">
                    Data Reports
                  </p> */}
                  <Row>
                    <Col lg="6">
                      <Label>Start Date</Label>
                      <InputGroup>
                        <Flatpickr
                          className="form-control d-block"
                          placeholder="Select Date"
                          options={{
                            altInput: true,
                            altFormat: "F j, Y",
                            dateFormat: "Y-m-d"
                          }}
                          onChange={handleStartDate}
                        />
                      </InputGroup>
                      {
                        error.start ? (
                          <Label style={{ color: "red" }}>{error.start}</Label>
                        ) : null
                      }
                    </Col>
                    <Col lg="6">
                      <Label>End Date</Label>
                      <InputGroup>
                        <Flatpickr
                          className="form-control d-block"
                          placeholder="Select Date"
                          options={{
                            altInput: true,
                            altFormat: "F j, Y",
                            dateFormat: "Y-m-d"
                          }}
                          onChange={handleEndDate}
                        />
                      </InputGroup>
                      {
                        error.end ? (
                          <Label style={{ color: "red" }}>{error.end}</Label>
                        ) : null
                      }
                    </Col>
                  </Row>
                  <Row className="mt-3 ">
                    <CSVLink
                      data={exportReportData}
                      filename={fileName + "-data-report.csv"}
                      className='hidden'
                      ref={csvLink}
                      target='_blank'
                    />
                    <Col lg="4">
                      &nbsp;
                    </Col>
                    <Col lg="3">
                      <Button onClick={() => handleDataReport("finance")} type="button" color="primary" className="w-100">
                        {loader === true && fileName === "finance" ? <div className="loaderOfSave">
                          <div className="spinner-border m-1" role="status">
                            <span className="sr-only">Loading...</span>
                          </div>
                        </div> :  "Generate Financial Report"
                        }
                      </Button>
                    </Col>
                    <Col lg="2">
                      <Button onClick={() => handleDataReport("order")} type="button" color="primary">
                      {loader === true && fileName === "order"? <div className="loaderOfSave">
                          <div className="spinner-border m-1" role="status">
                            <span className="sr-only">Loading...</span>
                          </div>
                        </div> :  "Export Data Report"
                        }
                      </Button>
                    </Col>
                    <Col lg="3">
                      <Button onClick={() => handleDataReport("sales")} type="button" className="w-100" color="primary">
                      {loader === true && fileName === "sales" ? <div className="loaderOfSave">
                          <div className="spinner-border m-1" role="status">
                            <span className="sr-only">Loading...</span>
                          </div>
                        </div> :  "Generate Sales Report"
                        }
                      </Button>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

RolePermission.propTypes = {
  getPermission: PropTypes.func
}


export default RolePermission   
