import { takeEvery, put, call } from "redux-saga/effects"
import { GET_PROMOS_CODE_SUCCESS, GET_PROMOS_CODE_FAIL, GET_PROMOS_CODE, CREATE_PROMOS_CODE, UPDATE_PROMOS_CODE, GET_TRANSACTION, GET_TRANSACTION_SUCCESS, GET_TRANSACTION_FAIL } from "./actionTypes";
import { apiSuccess, apiFail, promocodeError } from './actions';
import { PromoCodeList, addNewPromos, updatePromos, getTransactions } from "helpers/fakebackend_helper";
import toastr from "toastr";
import "toastr/build/toastr.min.css";

function* onAddNewPromos({ payload: record }) {
  try {
    const response = yield call(addNewPromos, record);
    if (response.statusCode == "200") {
      toastr.success("PromoCode Added successfully", "PromoCode");
      yield put(apiSuccess({ webPermission: true }, GET_PROMOS_CODE))
    } else if (response.statusCode == "400" && response.msg == "Invalid Promo Code!") {
      yield put(promocodeError())
    } else {
      toastr.error(response.msg, "PromoCode");
    }
  } catch (error) {
    toastr.error("PromoCode Add Failed, Try Again!", "PromoCode");
  }
}

function* onUpdatePromos({ payload: record }) {
  try {
    let checkstatus = record?.checkstatus1
    let checkstatus2 = record?.checkstatus2
    delete record.checkstatus1
    delete record.checkstatus2
    const response = yield call(updatePromos, record);
    if (response.statusCode == "200") {
      toastr.success("PromoCode Updated successfully", "PromoCode");
      if(checkstatus){
        checkstatus(!checkstatus2)
      }
      // yield put(apiSuccess({ webPermission: true, ...updateObj }, GET_PROMOS_CODE))
    } else {
      toastr.error(response.msg, "PromoCode");
    }
  } catch (error) {
    toastr.error("PromoCode Update Failed", "PromoCode");
  }
}

function* fetchPromoCode({ payload }) {
  try {
    const response = yield call(PromoCodeList,payload);
    yield put(apiSuccess(response, GET_PROMOS_CODE_SUCCESS));
  } catch (error) {
    yield put(apiFail(error, GET_PROMOS_CODE_FAIL));
  }
}

function* fetchTransactions({ payload: record }) {
  try {
    const response = yield call(getTransactions, record)
    yield put(apiSuccess(response.record, GET_TRANSACTION_SUCCESS))
  } catch (error) {
    yield put(apiFail(error, GET_TRANSACTION_FAIL))
  }
}

function* promocodeSaga() {
  yield takeEvery(CREATE_PROMOS_CODE, onAddNewPromos)
  yield takeEvery(UPDATE_PROMOS_CODE, onUpdatePromos)
  yield takeEvery(GET_PROMOS_CODE, fetchPromoCode)
  yield takeEvery(GET_TRANSACTION, fetchTransactions)
}

export default promocodeSaga