import React, { useEffect, useState, useRef } from "react";
import PropTypes from "prop-types"
import { Row, Col, Button } from "reactstrap";
import DataTable from "react-data-table-component";
import Switch from "react-switch";



const AnnoucementText = props => {

  const [notificationData, setannoucementData] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [online, setOnline] = useState(true);
  const [offline, setOffline] = useState(true);

  let TextColumns = [
    {
      name: "Title (English)",
      sortable: true,
      selector: row => row.enTitle,
      wrap: true,
    },
    {
      name: "Description (English)",
      sortable: true,
      selector: row => row.enDescription,
      wrap: true,
    },
    {
      name: "Title (Arabic)",
      sortable: true,
      selector: row => row.arTitle,
      wrap: true,
    },
    {
      name: "Description (Arabic)",
      sortable: true,
      selector: row => row.arDescription,
      wrap: true,
    }

  ];

  if (props.userData?.permissions?.allPermissions == true || props.userData?.permissions?.announcement.update == true) {
    TextColumns.push({
      name: "View",
      center: true,
      selector: row => {
        return <>
          <button className="btn btn-primary btn-sm" onClick={() => props.onViewClick(row, "edit")}>
            View
          </button>
        </>
      }
    },
    {
      name: "Status",
      center: true,
      width: '90px',
      selector: row => {
        return <>
         <Switch onChange={() => props.onStatusClick({_id:row._id,status:!row.status}, "edit")} checked={row?.status} />
        </>
      }
    })
  }




  return (
    <React.Fragment>
      <Row >
        <Col sm="12" style={{display: 'flex','justify-content': 'flex-end'}} >
          <div className="me-2 mb-2 d-inline-block">
            <div className="position-relative">
              <Button
                type="button"
                color="success"
                className="btn-rounded  mt-3 me-2 "
                onClick={() => props.onAddClick("add")}
              >
                <i className="mdi mdi-plus me-1" />
                Add Annoucement
              </Button>
            </div>
          </div>
        </Col>
      </Row>
      <Row>
        <DataTable
          columns={TextColumns}
          data={props.notificationData}
          pagination
        />
      </Row>
    </React.Fragment>
  );

}

AnnoucementText.propTypes = {
  notificationData: PropTypes.array,
  userData: PropTypes.object,
  onViewClick: PropTypes.func,
  onAddClick: PropTypes.func,
  onStatusClick:PropTypes.func
};

export default AnnoucementText;
