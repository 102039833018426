import React, { useState } from "react"
import MetaTags from 'react-meta-tags';
import {
  Button, Card, CardBody, Col, Container, Modal, Input, Label, Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
  InputGroup
} from "reactstrap"
import "@vtaits/react-color-picker/dist/index.css"
import "react-datepicker/dist/react-datepicker.css"
import Select from "react-select"
//Import Flatepicker
import "flatpickr/dist/themes/material_blue.css"
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table"
import classNames from "classnames"
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux"
import {
  createRole, searchUsers, getPermission, getAllModules, permissionUpdate, clearErrorMsg, getAllPermissions
} from "store/actions"
import PropTypes from "prop-types";
import toastr from "toastr";
import "toastr/build/toastr.min.css";
import Pages404 from "pages/Utility/pages-404";
import { helpers } from "chart.js";
import MultiSelect from "react-multiple-select-dropdown-lite";
import { myParseFloat } from "helpers/validationHelper";

const RolePermission = () => {
  const dispatch = useDispatch();
  const [selectedGroup, setselectedGroup] = useState({ label: "Select Role", value: "" });
  const [optionGroup, setOptionGroup] = useState([]);
  const [rolesAdmin, setRolesAdmin] = useState({});
  const [newRoles, setNewRoles] = useState({});
  const [allModules, setAllModules] = useState([]);
  const [moduleModal, setModuleModal] = useState(false);
  const [addRoleModal, setAddRoleModal] = useState(false);
  const [roleName, setRoleName] = useState("");
  const [roleDisplayName, setRoleDisplayName] = useState("");
  const [permissions, setPermissions] = useState([]);
  const [apiProcessing, setApiProcessing] = useState(false);
  const [userData, setUserData] = useState({});

  //Add module
  const [moduleOption, setModuleOption] = useState([]);
  const [selectedModuleOption, setSelectedModuleOption] = useState({});
  const [moduleAddPermission, setModuleAddPermission] = useState([]);
  const [selectedModulePermission, setSelectedModulePermission] = useState([])
  const [activeTab, setactiveTab] = useState("api")
  const [roleValue, setRoleValue] = useState([])
  const [maxOnlineDisPer, setMaxOnlineDisPer] = useState(0)
  const [maxOnlineWalletAmt, setMaxOnlineWalletAmt] = useState(0)
  const [maxOfflineDisPer, setMaxOfflineDisPer] = useState(0)
  const [maxRefundPer, setMaxRefundPer] = useState(0)
  const [maxAreaDiscount,setMaxAreaDiscount]= useState(0)
  const [maxAreaRechargeWallet,setMaxAreaRechargeWallet]= useState(0)

  // set error
  const [error, setError] = useState({});
  const { roles, allPermissions, apiErrorMsg, apiSuccessMsg, users } = useSelector(state => ({
    roles: state.permission.permissions,
    allPermissions: state.permission.allPermissions,
    apiErrorMsg: state.permission.apiErrorMsg,
    apiSuccessMsg: state.permission.apiSuccessMsg,
    users: state.Users.users

  }))

  const toggle = tab => {
    if (activeTab !== tab) {
      setactiveTab(tab)
    }
  }

  function handleSelectGroup(_selectedGroup) {
    setAllModules([]);
    setMaxOnlineDisPer(0);
    setMaxOnlineWalletAmt(0);
    setMaxOfflineDisPer(0);
    setselectedGroup(_selectedGroup);
  }

  useEffect(() => {
    //dispatch(clearErrorMsg());
    dispatch(getAllPermissions({}))
    dispatch(getPermission({}))
    if(!users) dispatch(searchUsers({ webPermissions: true }))
  }, [])


  useEffect(() => {
    if (users.length > 0) {
      setUserData(users[0]);
    }
  }, [users])

  useEffect(() => {
    
    if(apiErrorMsg.msg != "" && apiProcessing){
      toastr.error(apiErrorMsg.msg, "Error Occured");
      setApiProcessing(false);
    }
  }, [apiErrorMsg])

  useEffect(() => {
    
    if(apiSuccessMsg.msg != "" && apiProcessing){
      toastr.success(apiSuccessMsg.msg, apiSuccessMsg.title);
      setApiProcessing(false);
    }
  }, [apiSuccessMsg])


  useEffect(() => {
    let options = roles?.map((item) => {
      if (item._id != "637cb53f63f5416964433181") {
        return { label: item.name, value: item._id }
      }
    })
    setOptionGroup(options.filter((item) => item?.value))
  }, [roles])

  useEffect(() => {
    // console.log("allModule", allModule)
    // if (allModule?.length) {
    //   // let filterApiModule = allModule.filter((item) => item.moduleType == "api")
    //   setAllModules(allModule);
    // }
    if (roles?.length) {
      setPermissionsRole(selectedGroup.value)
    }
  }, [selectedGroup])

  useEffect(() => {
    if (allPermissions?.length) {
      // let filterApiModule = allModule.filter((item) => item.moduleType == "api")
      setAllModules(allPermissions);
    }
  }, [allPermissions])

  const setPermissionsRole = (role) => {
    let rolePermissionObj = roles?.find((item) => item._id === role);
    let selectedPermissions = rolePermissionObj?.permissions;
    let _permissions = [];
    for (const item of allPermissions) {
      let _perm = {
        permission: item._id,
        name: item.name,
        create: false,
        read: false,
        update: false,
      }


      if (selectedPermissions && selectedPermissions.length > 0) {
        let objPermission = selectedPermissions.find((permission) => permission?.permission == item._id?.toString());
        if (objPermission != undefined) {
          if (item?.name?.toLowerCase()?.includes("users") && (objPermission?.create || objPermission?.update) && objPermission?.validations) {
            setRoleValue(objPermission?.validations?.createRoles)
          }
          if (objPermission.read == true) {
            _perm.read = true;
          }
          if (objPermission.create == true) {
            _perm.create = true;
          }
          if (objPermission.update == true) {
            _perm.update = true;
          }
          if (item?.name?.toLowerCase()?.includes("promocode") && (objPermission?.create || objPermission?.update) && objPermission?.validations) {
            setMaxOnlineDisPer(objPermission?.validations?.maxOnlineDiscount ? objPermission?.validations?.maxOnlineDiscount : 0);
            setMaxOnlineWalletAmt(objPermission?.validations?.maxWalletAmount ? objPermission?.validations?.maxWalletAmount : 0);
            setMaxOfflineDisPer(objPermission?.validations?.maxOfflineDiscount ? objPermission?.validations?.maxOfflineDiscount : 0);
            setMaxAreaRechargeWallet(objPermission?.validations?.maxAreaFreeRechargeWalletAmount ? objPermission?.validations?.maxAreaFreeRechargeWalletAmount : 0);
            setMaxAreaDiscount(objPermission?.validations?.maxAreaDiscount ? objPermission?.validations?.maxAreaDiscount : 0);
          }
          if (item?.name?.toLowerCase()?.includes("refund") && (objPermission?.create || objPermission?.update) && objPermission?.validations) {
            setMaxRefundPer(objPermission?.validations?.maxRefundPer ? objPermission?.validations?.maxRefundPer : 0);
          }

        }
      }
      _permissions.push(_perm);

    };

    setPermissions(_permissions);
  }

  const handleChangePermission = (event, permission, id) => {

    setPermissions((prevItems) => {
      // Create a new array of items based on the previous state
      return prevItems.map((item) => {
       
        if (item.name == id) {
            let newPermission = item[permission] === true ? false : true
            let newObj ={
              ...item,
              [permission]:newPermission
            }
            console.log(newObj,"new obj")
          return newObj;
        }
        return item;
      });
    });
  }

  const handleEditPermission = () => {
    let errObj = {}
    let errorExits = false;
    let usersPermissionIndex = permissions.findIndex((item) => item.permission === "637cacc3ef115a1f249bdde7");
    if (usersPermissionIndex !== -1 && (permissions[usersPermissionIndex]?.create || permissions[usersPermissionIndex]?.update) && !roleValue?.length) {
      errObj['roleValue'] = "Role is Required";
      errorExits = true;
    }

    let promoCodeIndex = permissions.findIndex((item) => item?.name?.toLowerCase()?.includes("promocode") && (item?.create || item?.update))
    if (promoCodeIndex !== -1) {
      if (maxOnlineDisPer > 100 || maxOnlineDisPer < 1) {
        errObj['maxOnlineDisPer'] = "Enter valid 1-100 Discount Percentage";
        errorExits = true;
      }
      if (!maxOnlineDisPer) {
        errObj['maxOnlineDisPer'] = "Online Discount Percentage Required";
        errorExits = true;
      }
      if (!maxOnlineWalletAmt) {
        errObj['maxOnlineWalletAmt'] = "Online Wallet Amount Required";
        errorExits = true;
      }
      if (maxOnlineWalletAmt < 1) {
        errObj['maxOnlineWalletAmt'] = "Enter Valid Wallet Amount";
        errorExits = true;
      }
      if (maxOfflineDisPer > 100 || maxOfflineDisPer < 1) {
        errObj['maxOfflineDisPer'] = "Enter valid 1-100 Discount Percentage";
        errorExits = true;
      }
      if (!maxOfflineDisPer) {
        errObj['maxOfflineDisPer'] = "Offline Discount Percentage Required"
        errorExits = true;
      }
      permissions[promoCodeIndex]["validations"] = {
        maxOnlineDiscount: maxOnlineDisPer,
        maxWalletAmount: maxOnlineWalletAmt,
        maxOfflineDiscount: maxOfflineDisPer,
        maxAreaDiscount:maxAreaDiscount,
        maxAreaFreeRechargeWalletAmount:maxAreaRechargeWallet
      }
    }
    
    let refundIndex = permissions.findIndex((item) => item?.name?.toLowerCase()?.includes("refund") && (item?.create || item?.update))
    if(refundIndex !== -1){
      let _maxRefundPer =  myParseFloat(maxRefundPer);
      if(_maxRefundPer <= 0){
        errObj['maxRefundPer'] = "Max Refund Percentage Required"
        errorExits = true;
      }

      permissions[refundIndex]["validations"] = {
        maxRefundPer: maxRefundPer
      }


    }

    setError(errObj);
    if (errorExits) {
      return
    }

    let rolePermissionIndex = roles?.findIndex((item) => item._id === selectedGroup.value);
    roles[rolePermissionIndex].permissions = permissions;
    setApiProcessing(true);
    dispatch(permissionUpdate({
      _id: selectedGroup.value,
      permissions: permissions
    }))
  }

  const handleModuleOption = (module) => {
    let selectedPermission = selectedGroup.value == "admin" ? ["All"] : [];
    setSelectedModulePermission(selectedPermission)
    setSelectedModuleOption(module);
    let permissionOfSelectModule = allModule.find((item) => item.moduleType == activeTab && item.moduleName == module.value)
    setModuleAddPermission(permissionOfSelectModule.permissions);
  }

  useEffect(() => {
    if (!moduleModal) {
      setSelectedModuleOption({});
      setModuleAddPermission([]);
      setSelectedModulePermission([]);
      setError({});
    } else {
      let filterModuleTypeData = allModule.filter((item) => item.moduleType == activeTab);
      let objectOfModuleType = activeTab == "api" ? roles : rolesAdmin;
      let remainingModules = filterModuleTypeData.map((item) => {
        if (objectOfModuleType[item.moduleName]) {
          return false;
        } else {
          return { label: item.moduleName, value: item.moduleName };
        }
      })
      remainingModules = remainingModules.filter((data) => data);
      setModuleOption(remainingModules);

    }
  }, [moduleModal])

  const handleModulePermission = (permission) => {
    let newselectedModulePermission = [...selectedModulePermission]
    let indexOfPermission = newselectedModulePermission.indexOf(permission);
    let indexOfPermissionAll = newselectedModulePermission.indexOf("All");
    if (indexOfPermissionAll !== -1) {
      let newArr = [...moduleAddPermission];
      if (newArr.indexOf(permission) !== -1) {
        newArr.splice(newArr.indexOf(permission), 1)
      }
      newselectedModulePermission = newArr;
    } else {
      if (indexOfPermission !== -1) {
        newselectedModulePermission.splice(indexOfPermission, 1)
      } else {
        newselectedModulePermission.push(permission);
      }
    }
    if (newselectedModulePermission.length == moduleAddPermission.length) {
      newselectedModulePermission = ["All"]
    }
    setSelectedModulePermission(newselectedModulePermission)

  }

  const addNewModule = () => {
    if (!selectedModuleOption?.value) {
      setError({ moduleName: "Module Name is Required" })
      return
    }
    if (!selectedModulePermission?.length) {
      setError({ modulePermission: "Module Permission is Required" })
      return
    }

    let rolePermissionObj = permissions?.find((item) => item.role === selectedGroup.value);
    let newObj = activeTab == "api" ? rolePermissionObj.apiPermissions : rolePermissionObj.panelPermissions;
    let updateObject = { ...newObj, [selectedModuleOption.value]: selectedModulePermission }
    if (activeTab == "api") {
      rolePermissionObj.apiPermissions = updateObject
    } else {
      rolePermissionObj.panelPermissions = updateObject
    }
    dispatch(permissionUpdate(rolePermissionObj))
    setModuleModal(!moduleModal);
    setTimeout(() => {
      toastr.success("Module added Successfully", "Module permission");
    }, 500);
  }

  const addNewRole = () => {

    if (!roleName) {
      setError({ roleName: "Role Required" })
      return;
    }
    let apiData = {
      name: roleName
    }
    setApiProcessing(true);
    dispatch(createRole(apiData));
    setAddRoleModal(false)
  }

  var data = JSON.parse(localStorage.getItem("authUser"));
  const [fill, setFill] = useState({});
  const { permission } = useSelector(state => ({
    permission: state.permission.permissions
  }))

  const handleOnchangeRole = (val, id) => {
    if (val) {
      let _permission = permissions;
      let indexOfSelect = _permission.findIndex((item) => item?.permission === id);
      _permission[indexOfSelect].validations = { createRoles: val.split(",") }
      setRoleValue(val.split(","))
      setPermissions(_permission)
    } else {
      setRoleValue([])
    }
  }

  useEffect(() => {
    const filterProduct = permission?.filter((item) => item?.role === data?.role)
    setFill(filterProduct[0])
  }, [permission])


  if (userData?.permissions?.allPermissions == true) {
    return (
      <React.Fragment>
        <div className="page-content" style={{ minHeight: "600px" }}>
          <MetaTags>
            <title>Roles</title>
          </MetaTags>
          <Container fluid={true}>
            <Breadcrumbs title="Home" breadcrumbItem="Roles" />
            <Row>
              <Col lg="12">
                <Card>
                  <CardBody>
                    <Row>
                      <Col lg="5">
                        <h4 className="card-title">Roles</h4>
                      </Col>
                      <Col lg="7" >
                        <div className="d-flex  justify-content-end">
                          {
                            (data?.role === "admin" || fill?.panelPermissions?.permission?.includes("create")) ?
                              <Button className="btn btn-success" onClick={() => setAddRoleModal(true)}>Add Role</Button> : null
                          }
                        </div>

                      </Col>
                    </Row>



                    <Row>
                      <Col lg="5">
                        <div className="mb-3">
                          <Label>Select Role</Label>
                          <Select
                            value={selectedGroup}
                            onChange={handleSelectGroup}
                            options={optionGroup}
                            classNamePrefix="select2-selection"
                          />
                        </div>
                      </Col>


                    </Row>

                    <Row >
                      <Col lg="12" className="mt-4" style={{ textAlign: "right" }}>
                        <div className="align-content-end p-2">
                          {
                            selectedGroup.value !== "" && selectedGroup.value !== "admin" ? (
                              apiProcessing ? <Button color="primary" disabled className="btn btn-sm">Processing...</Button> : <Button color="primary" className="btn btn-sm" onClick={handleEditPermission} style={{ marginRight: "10px" }}>Update Permission</Button>
                            ) : null
                          }

                        </div>
                      </Col>
                    </Row>
                    {selectedGroup.value !== "" ?
                      <Row>

                        <Col sm="12">
                          <div className="table-rep-plugin">
                            <div
                              className="mb-0"
                              data-pattern="priority-columns"
                            >
                              <Table
                                id="tech-companies-1"
                                className="table table-striped table-bordered"
                              >
                                <Thead>
                                  <Tr>
                                    <Th>Module</Th>
                                    <Th>Permission</Th>
                                  </Tr>
                                </Thead>
                                <Tbody>
                                  {
                                    permissions.map((item) =>
                                      <Tr key={item._id}>
                                        <Td>{item.name}</Td>
                                        <Td>
                                          <div className="check-box form-check">

                                            <Label className="form-check-label" >
                                              <Input
                                                checked={item?.create}
                                                type="checkbox"
                                                className="is-touched is-dirty av-valid form-check-input"
                                                value="1"
                                                onChange={(e) => handleChangePermission(e, 'create', item.name)} /> Create
                                            </Label>

                                            <Label className="form-check-label" >
                                              <Input
                                                checked={item?.read}
                                                name="read"
                                                type="checkbox"
                                                className="is-touched is-dirty av-valid form-check-input"
                                                value="1"
                                                onChange={(e) => handleChangePermission(e, 'read', item.name)} /> Read
                                            </Label>

                                            <Label className="form-check-label" >
                                              <Input
                                                checked={item?.update}
                                                name="update"
                                                type="checkbox"
                                                className="is-touched is-dirty av-valid form-check-input"
                                                value="1"
                                                onChange={(e) => handleChangePermission(e, 'update', item.name)} /> Update
                                            </Label>
                                            {
                                              item?.name?.toLowerCase()?.includes("users") && (item?.create || item?.update) ? (
                                                <div className="text-start" style={{ width: "80%" }}>
                                                  <Label>Select Role</Label>
                                                  <MultiSelect
                                                    defaultValue={roleValue?.toString()}
                                                    className="multi-selects form-selects2 form-controlsss areas-box"
                                                    onChange={(e) => handleOnchangeRole(e, item.permission)}
                                                    options={optionGroup}
                                                  />
                                                  {
                                                    error?.roleValue ? (
                                                      <span style={{ color: "red" }}>{error.roleValue}</span>
                                                    ) : null
                                                  }
                                                </div>
                                              ) : null
                                            }
                                          </div>
                                          {
                                            item?.name?.toLowerCase()?.includes("promocode") && (item?.create || item?.update) ? (
                                              <div className="d-flex text-end">
                                                <div className="text-start">
                                                  <Label>Maximum Online Discount</Label>
                                                  <InputGroup>
                                                    <Input
                                                      onChange={(e) => setMaxOnlineDisPer(e.target.value)}
                                                      value={maxOnlineDisPer}
                                                      name="maxOnlineDisPer"
                                                      type="number"
                                                      placeholder="Online Discount Percentage"
                                                      required
                                                    />
                                                    <div className="input-group-append">
                                                      <span className="input-group-text">
                                                        %
                                                      </span>
                                                    </div>
                                                  </InputGroup>
                                                  {
                                                    error?.maxOnlineDisPer ? (
                                                      <span style={{ color: "red" }}>{error.maxOnlineDisPer}</span>
                                                    ) : null
                                                  }
                                                </div>
                                                <div className="mx-3 text-start">
                                                  <Label>Maximum Online Wallet Amount</Label>
                                                  <InputGroup>
                                                    <Input
                                                      onChange={e => setMaxOnlineWalletAmt(e.target.value)}
                                                      value={maxOnlineWalletAmt}
                                                      name="maxOnlinewalletAmt"
                                                      type="number"
                                                      placeholder="Online Wallet Amount"
                                                      required
                                                    />
                                                    <div className="input-group-append">
                                                      <span className="input-group-text">
                                                        SR
                                                      </span>
                                                    </div>
                                                  </InputGroup>
                                                  {
                                                    error?.maxOnlineWalletAmt ? (
                                                      <span style={{ color: "red" }}>{error.maxOnlineWalletAmt}</span>
                                                    ) : null
                                                  }
                                                </div>
                                                <div className="mx-3 text-start">
                                                  <Label>Maximum Offline Discount</Label>
                                                  <InputGroup>
                                                    <Input
                                                      onChange={e => setMaxOfflineDisPer(e.target.value)}
                                                      value={maxOfflineDisPer}
                                                      name="maxOfflineDisPer"
                                                      type="number"
                                                      placeholder="Offline Discount Percentage"
                                                      required
                                                    />
                                                    <div className="input-group-append">
                                                      <span className="input-group-text">
                                                        %
                                                      </span>
                                                    </div>
                                                  </InputGroup>
                                                  {
                                                    error?.maxOfflineDisPer ? (
                                                      <span style={{ color: "red" }}>{error.maxOfflineDisPer}</span>
                                                    ) : null
                                                  }
                                                </div>
                                                <div className="text-start mx-3">
                                                  <Label>Maximum Area Free Recharge Wallet Amount</Label>
                                                  <InputGroup>
                                                    <Input
                                                      onChange={e => setMaxAreaRechargeWallet(e.target.value)}
                                                      value={maxAreaRechargeWallet}
                                                      name="maxAreaRechargeWallet"
                                                      type="number"
                                                      placeholder="Maximum Area Recharge Wallet Amount"
                                                      required
                                                    />
                                                    <div className="input-group-append">
                                                      <span className="input-group-text">
                                                     SR
                                                      </span>
                                                    </div>
                                                  </InputGroup>
                                                  {
                                                    error?.maxAreaRechargeWallet ? (
                                                      <span style={{ color: "red" }}>{error.maxAreaRechargeWallet}</span>
                                                    ) : null
                                                  }
                                                </div>
                                                <div className="text-start mx-3">
                                                  <Label>Maximum Area Discount</Label>
                                                  <InputGroup>
                                                    <Input
                                                      onChange={e => setMaxAreaDiscount(e.target.value)}
                                                      value={maxAreaDiscount}
                                                      name="maxAreaDiscount"
                                                      type="number"
                                                      placeholder="Maximum Area Discount"
                                                      required
                                                    />
                                                    <div className="input-group-append">
                                                      <span className="input-group-text">
                                                        %
                                                      </span>
                                                    </div>
                                                  </InputGroup>
                                                  {
                                                    error?.maxAreaDiscount ? (
                                                      <span style={{ color: "red" }}>{error.maxAreaDiscount}</span>
                                                    ) : null
                                                  }
                                                </div>
                                              </div>
                                            ) : null
                                          }
                                          {
                                            item?.name?.toLowerCase()?.includes("refund") && (item?.create || item?.update) ? (
                                              <div className="d-flex text-end">
                                                <div className="text-start">
                                                  <Label>Maximum Refund Percentage</Label>
                                                  <InputGroup>
                                                    <Input
                                                      onChange={(e) => setMaxRefundPer(e.target.value)}
                                                      value={maxRefundPer}
                                                      name="maxRefundPer"
                                                      type="number"
                                                      placeholder="Max. Refund Percentage"
                                                      required
                                                    />
                                                    <div className="input-group-append">
                                                      <span className="input-group-text">
                                                        %
                                                      </span>
                                                    </div>
                                                  </InputGroup>
                                                  {
                                                    error?.maxRefundPer ? (
                                                      <span style={{ color: "red" }}>{error.maxRefundPer}</span>
                                                    ) : null
                                                  }
                                                </div>
                                                
                                              </div>
                                            ) : null
                                          }
                                        </Td>
                                      </Tr>
                                    )
                                  }

                                </Tbody>
                              </Table>
                            </div>
                          </div>
                        </Col>
                      </Row>
                      : null}


                    <Modal
                      size="lg"
                      isOpen={moduleModal}
                      toggle={() => setModuleModal(!moduleModal)}
                    >
                      <div className="modal-header">
                        <h5 className="modal-title mt-0" id="myModalLabel">
                          <span >
                            Add New Module
                          </span>
                        </h5>
                        <button
                          type="button"
                          onClick={() => {
                            setModuleModal(false);
                          }}
                          className="close"
                          data-dismiss="modal"
                          aria-label="Close"
                        >
                          <span aria-hidden="true">&times;</span>
                        </button>
                      </div>
                      <div className="modal-body">
                        <Row>
                          <Col lg="12">
                            <div className="mb-3">
                              <Label>Select Module</Label>
                              <Select
                                value={selectedModuleOption}
                                onChange={handleModuleOption}
                                options={moduleOption}
                                classNamePrefix="select2-selection"
                              />
                              {
                                error.moduleName ? (
                                  <Label style={{ color: "red" }}>{error.moduleName}</Label>
                                ) : null
                              }
                            </div>
                          </Col>
                          <Col lg="12">
                            <div className="mb-3">
                              <Label>Select Module Permission</Label>
                              <div className="check-box form-check">
                                {
                                  moduleAddPermission.map((data) => {
                                    return (
                                      <Label className="form-check-label" key={data}>
                                        <Input
                                          disabled={selectedGroup.value === "admin"}
                                          defaultChecked={selectedModulePermission.includes(data) || selectedModulePermission.includes("All")}
                                          name="areas" id="areas" type="checkbox" className="is-touched is-dirty av-valid form-check-input"
                                          value={data}
                                          onChange={() => handleModulePermission(data)}
                                        /> {data}</Label>
                                    )
                                  })
                                }
                              </div>
                              {
                                error.modulePermission ? (
                                  <Label style={{ color: "red" }}>{error.modulePermission}</Label>
                                ) : null
                              }
                            </div>
                          </Col>
                          <Col lg="12">
                            <Button
                              type="submit"
                              color="primary"
                              className="w-md"
                              onClick={addNewModule}
                            >
                              Add Module
                            </Button>
                          </Col>
                        </Row>
                      </div>

                    </Modal>
                    <Modal
                      size="lg"
                      isOpen={addRoleModal}
                      toggle={() => setAddRoleModal(!addRoleModal)}
                    >
                      <div className="modal-header">
                        <h5 className="modal-title mt-0" id="myModalLabel">
                          <span >
                            Add New Role
                          </span>
                        </h5>
                        <button
                          type="button"
                          onClick={() => {
                            setAddRoleModal(false);
                          }}
                          className="close"
                          data-dismiss="modal"
                          aria-label="Close"
                        >
                          <span aria-hidden="true">&times;</span>
                        </button>
                      </div>
                      <div className="modal-body">
                        <Row>


                          <Col lg="12">
                            <div className="mb-3">
                              <Label>Enter Role Keyword</Label>
                              <Input
                                name="roleName"
                                type="text"
                                className="form-control"
                                placeholder="Enter Role Keyword"
                                value={roleName}
                                onChange={(val) => setRoleName(val.target.value)}
                              />
                              {
                                error.roleName ? (
                                  <Label style={{ color: "red" }}>{error.roleName}</Label>
                                ) : null
                              }
                            </div>
                          </Col>

                          <Col lg="12">
                            <Button
                              type="submit"
                              color="primary"
                              className="w-md"
                              onClick={addNewRole}
                            >
                              Add Role
                            </Button>
                          </Col>
                        </Row>
                      </div>

                    </Modal>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    )
  } else {
    return <Pages404 />
  }



}

RolePermission.propTypes = {
  getPermission: PropTypes.func
}


export default RolePermission   
