import {
    API_SUCCESS,
    API_FAIL,
    GET_LAUNDRIES_LIST,
    GET_LAUNDRY_DETAILS,
    TOGGLE_LAUNDRY_ACTIVE,
    ALL_LAUNDRY_REVENUE
} from "./actionTypes";

export const apiSuccess = (actionType, data) => ({
    type: API_SUCCESS,
    payload: { actionType, data },
});

export const apiFail = (actionType, error) => ({
    type: API_FAIL,
    payload: { actionType, error },
});

export const getLaundries = (data) => ({
    type: GET_LAUNDRIES_LIST,
    payload: data
});

export const getLaundrydetails = (userId) => ({
    type: GET_LAUNDRY_DETAILS,
    payload: userId
});

export const toggleLaundryActive = (laundryDetails) => ({
    type: TOGGLE_LAUNDRY_ACTIVE,
    payload: laundryDetails
});

export const allLaundryRevenue = (data) => ({
  type: ALL_LAUNDRY_REVENUE,
  payload: data
});
