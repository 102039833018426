import { call, put, takeEvery } from "redux-saga/effects"

import { GET_LOYALITY_POINTS, UPDATE_LOYALITY_POINTS } from "./actionTypes"

import {
  getLoyalityPointsSuccess,
  updateLoyalityPointsSuccess,
} from "./actions"

import {
  _getLoyalityPoints,
  _updateLoyalityPoints,
} from "helpers/loyalityPointsHelper"

import toastr from "toastr"

function* getLoyalityPoints({ payload: data }) {
  try {
    const response = yield call(_getLoyalityPoints, data)
    if (response?.statusCode == 200) {
      yield put(getLoyalityPointsSuccess(response.records))
    } else {
      toastr.error(response.msg, "Loyality Points")
    }
  } catch (error) {
    toastr.error(error.msg, "Loyality Points")
  }
}

function* updateLoyalityPoints({ payload: data }) {
  try {
    const response = yield call(_updateLoyalityPoints, data)
    if (response?.statusCode == 200) {
      yield put(updateLoyalityPointsSuccess(response.record))
      toastr.success("Updated", "Car Wash Order")
    } else {
      toastr.error(response.msg, "Loyality Points")
    }
  } catch (error) {
    toastr.error(error.msg, "Loyality Points")
  }
}

function* ecommerceSaga() {
  yield takeEvery(GET_LOYALITY_POINTS, getLoyalityPoints)
  yield takeEvery(UPDATE_LOYALITY_POINTS, updateLoyalityPoints)
}

export default ecommerceSaga
