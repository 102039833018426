// SLIDER 

export const GET_SLIDER = "GET_SLIDER"
export const GET_SLIDER_SUCCESS = "GET_SLIDER_SUCCESS"
export const GET_SLIDER_FAIL = "GET_SLIDER_FAIL"

// ADD NEW SLIDER 

export const ADD_NEW_SLIDER = "ADD_NEW_SLIDER"
export const ADD_NEW_SLIDER_SUCCESS = "ADD_NEW_SLIDER_SUCCESS"
export const ADD_NEW_SLIDER_FAIL = "ADD_NEW_SLIDER_FAIL"


// UPDATE SLIDER

export const UPDATE_SLIDER = "UPDATE_SLIDER"
export const UPDATE_SLIDER_SUCCESS = "UPDATE_SLIDER_SUCCESS"
export const UPDATE_SLIDER_FAIL = "UPDATE_SLIDER_FAIL"

// DELETE SLIDER

export const DELETE_SLIDER = "DELETE_SLIDER"
export const DELETE_SLIDER_SUCCESS = "DELETE_SLIDER_SUCCESS"
export const DELETE_SLIDER_FAIL = "DELETE_SLIDER_FAIL"