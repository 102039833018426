import React from "react"
import { Link } from "react-router-dom"

import {
  Card,
  CardBody,
  Col,
  UncontrolledDropdown,
  DropdownMenu,
  DropdownToggle,
} from "reactstrap"
import PropTypes from "prop-types"

//SimpleBar
import SimpleBar from "simplebar-react"
import ActivityItem from "./ActivityItem"


const Activity = props => {
  return (
    <React.Fragment>
      <Col>
        
            
              <div className="mt-2">
                <ul className="verti-timeline list-unstyled order-activity">
                  
                  <ActivityItem 
                    eventDate={props.data.endDateTime}
                    title="Delivered"
                    
                  />

                  <ActivityItem 
                    eventDate={props.data.outLaundryDateTime}
                    title="Laundry Out"
                    active={props.data.status == "out_laundry"}
                  />

                  <ActivityItem 
                    eventDate={props.data.readyDateTime}
                    title="Ready"
                    active={props.data.status == "ready"}
                  />

                  <ActivityItem 
                    eventDate={props.data.invoiceDateTime}
                    title="Invoice Created"
                    active={props.data.status == "washing"}
                  />

                  <ActivityItem 
                    eventDate={props.data.inLaundryDateTime}
                    title="Laundry In"
                    active={props.data.status == "in_laundry"}
                  />

                <ActivityItem 
                    eventDate={props.data.pickupDateTime}
                    title="Picked Up"
                    active={props.data.status == "picked"}
                  />

                <ActivityItem 
                    eventDate={props.data.startDateTime}
                    title="Created"
                    active={props.data.status == "created"}
                  />

                  
                </ul>
              </div>
            
            
      </Col>
    </React.Fragment>
  )
}


Activity.propTypes = {
  data: PropTypes.any
}

export default Activity
