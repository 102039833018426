import { call, put, takeEvery } from "redux-saga/effects"
import { postAddUser } from "helpers/userHelper"
import toastr from "toastr";
import "toastr/build/toastr.min.css";

import {
  GET_HOLIDAY_LIST,
} from "./actionTypes"

//Include Both Helper File with needed methods
import {
  fetchHolidayList
} from "helpers/holiday"

import {
  getHolidaysListSuccess,
  getHolidaysListFail,
} from "./actions"


function* getHolidays({ payload: data }) {

  try {

    const response = yield call(fetchHolidayList, data)

    yield put(getHolidaysListSuccess(response))
  } catch (error) {
    yield put(getHolidaysListFail(error))
  }
}

function* holidaySaga() {
  yield takeEvery(GET_HOLIDAY_LIST, getHolidays)
}

export default holidaySaga;