import {
  API_SUCCESS,
  API_FAIL,
  GET_SERVICES,
  UPDATE_SERVICE,
} from "./actionTypes"
import { unionWith } from "lodash"

const INIT_STATE = {
  services: [],
}

const Services = (state = INIT_STATE, action) => {
  switch (action.type) {
    case API_SUCCESS:
      switch (action.payload.actionType) {
        case GET_SERVICES: {
          // const updatedServices = action.payload.data.toSorted(
          //   (a, b) => b._id - a._id
          // )
            // console.log('updatedServices get', updatedServices)
          return {
            ...state,
            services: action.payload.data,
          }
        }

        case UPDATE_SERVICE: {
          const updatedServices = [...state.services]

          const updatedService = action.payload.data

          const updateServiceIndex = updatedServices.findIndex(
            service => service._id === updatedService._id
          )

          if (updateServiceIndex > -1) {
            updatedServices[updateServiceIndex] = action.payload.data
          } else {
            updatedServices.push(action.payload.data)
          }

          // const updatedServices = unionWith(
          //   [action.payload.data],
          //   state.services,
          //   (a, b) => a._id === b._id
          // ).toSorted((a, b) => b._id - a._id)
          // console.log('updatedServices update', updatedServices)

          return {
            ...state,
            services: updatedServices,
          }
        }

        default:
          return state
      }
    case API_FAIL:
      switch (action.payload.actionType) {
        case GET_SERVICES:
          return {
            ...state,
            chartsDataError: action.payload.error,
          }

        default:
          return state
      }
    default:
      return state
  }
}

export default Services
