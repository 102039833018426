
export const API_SUCCESS = "API_SUCCESS";
export const API_FAIL = "API_FAIL";

// VIEW DRIVER 
export const GET_REASONS_LIST = "GET_REASONS_LIST";
export const GET_REASONS_LIST_SUCCESS = "GET_REASONS_LIST_SUCCESS";
export const GET_REASONS_LIST_FAIL = "GET_REASONS_LIST_FAIL";


// EDIT DRIVER 
export const UPDATE_REASON = "UPDATE_REASON_LIST";
export const UPDATE_REASON_SUCCESS = "UPDATE_REASON_LIST_SUCCESS";
export const UPDATE_REASON_FAIL = "UPDATE_REASON_LIST_FAIL";


// ADD NEW dRIVER 
export const ADD_NEW_REASON = "ADD_NEW_REASON";
export const ADD_NEW_REASON_SUCCESS = "ADD_NEW_REASON_SUCCESS";
export const ADD_NEW_REASON_FAIL = "ADD_NEW_REASON_FAIL";


// DELETE DRIVER 
export const DELETE_REASON = "DELETE_REASON_LIST";
export const DELETE_REASON_SUCCESS = "DELETE_REASON_LIST_SUCCESS";
export const DELETE_REASON_FAIL = "DELETE_REASON_LIST_FAIL";