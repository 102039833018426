import { call, put, takeEvery } from "redux-saga/effects"
import { postAddUser } from "helpers/userHelper"
import toastr from "toastr";
import "toastr/build/toastr.min.css";

import {
  GET_B2B_PRODUCT_LIST,
  GET_B2B_LIST_PRODUCT_SUCCESS,
  GET_B2B_PRODUCT_LIST_FAIL
} from "./actionTypes"

//Include Both Helper File with needed methods
import {
  fetchb2bProductList,
  
} from "helpers/b2bProductHelper"

import {
  getb2bProductsFail,
  getb2bProductsSuccess
} from "./actions"



function* getb2bProductList({ payload: data }) {
  try {
    const response = yield call(fetchb2bProductList, data)

    yield put(getb2bProductsSuccess(response))
  } catch (error) {
    yield put(getb2bProductsFail(error))
  }
}



function* b2bProductsSaga() {
  yield takeEvery(GET_B2B_PRODUCT_LIST, getb2bProductList)
}

export default b2bProductsSaga
