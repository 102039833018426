import { call, put, takeEvery, all, fork } from "redux-saga/effects";

// Crypto Redux States
import { GET_REGISTRATION_SUMMARY, GET_BAG_CUSTOMERS, GET_ORDER_COUNT, GET_UNIQUE_CUSTOMERS} from "./actionTypes";
import { apiSuccess, apiFail } from "./actions";

//Include Both Helper File with needed methods
import {fetchRegistrationSummary, fetchBagCustomers, fetchOrderCounts,  fetchUniqueCustomers} from "../../helpers/analyticsHelper";

function* getRegistrationSummary({payload : data }) {
    try {
        console.log("Data::", data);
        const response = yield call(fetchRegistrationSummary, data);
        yield put(apiSuccess(GET_REGISTRATION_SUMMARY, response.record));
    } catch (error) {
        yield put(apiFail(GET_REGISTRATION_SUMMARY, error));
    }
}


function* getBagCustomers({payload : data }) {
  try {
      console.log("Data::", data);
      const response = yield call(fetchBagCustomers, data);
      yield put(apiSuccess(GET_BAG_CUSTOMERS, response.record));
  } catch (error) {
      yield put(apiFail(GET_BAG_CUSTOMERS, error));
  }
}

function* getOrderCount({payload : data }) {
  try {
      const response = yield call(fetchOrderCounts, data);
      yield put(apiSuccess(GET_ORDER_COUNT, response.data));
  } catch (error) {
      yield put(apiFail(GET_ORDER_COUNT, error));
  }
}

function* getUniqueCustomers({payload : data }) {
  try {
      const response = yield call(fetchUniqueCustomers, data);
      yield put(apiSuccess(GET_UNIQUE_CUSTOMERS, response.data));
  } catch (error) {
      yield put(apiFail(GET_UNIQUE_CUSTOMERS, error));
  }
}

function* AnalyticsSaga() {
    yield takeEvery(GET_REGISTRATION_SUMMARY, getRegistrationSummary)    
    yield takeEvery(GET_BAG_CUSTOMERS, getBagCustomers)
    yield takeEvery(GET_ORDER_COUNT, getOrderCount)
    yield takeEvery(GET_UNIQUE_CUSTOMERS, getUniqueCustomers)

        
}

export default AnalyticsSaga;
