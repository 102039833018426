
const authUser = localStorage.getItem("authUser");
let accessToken = "";
if(authUser && authUser != undefined){
   accessToken ="Bearer " + authUser.token;
}

export const getToken = () => {
  let authUser = localStorage.getItem("authUser");
  
  let accessToken = "";
  if(authUser && authUser != undefined){
    authUser = JSON.parse(authUser);
    accessToken ="Bearer " + authUser.token;
  }
  return accessToken;
}

//"Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJzdWIiOiIxMjM0NTY3ODkwIiwibmFtZSI6ImFkbWluIiwiYWRtaW4iOnRydWUsImp0aSI6ImQ2MTEwYzAxLWMwYjUtNDUzNy1iNDZhLTI0NTk5Mjc2YjY1NiIsImlhdCI6MTU5MjU2MDk2MCwiZXhwIjoxNTkyNTY0NjE5fQ.QgFSQtFaK_Ktauadttq1Is7f9w0SUtKcL8xCmkAvGLw"
  
export default accessToken
