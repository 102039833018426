import React, { useEffect, useState, useRef } from "react";
import MetaTags from "react-meta-tags";
import PropTypes from "prop-types";
import { withRouter, Link } from "react-router-dom";
import DataTable from "react-data-table-component";
import MultiSelect from "react-multiple-select-dropdown-lite";
import "react-multiple-select-dropdown-lite/dist/index.css";
import toastr from "toastr";
import "toastr/build/toastr.min.css";
import Switch from "react-switch"
import { Map, Marker, GoogleApiWrapper} from "google-maps-react"
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton'
import * as moment from "moment";

import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Label,
  Input,
  Badge,
  UncontrolledTooltip,
  Modal,
  ModalHeader,
  ModalBody,
} from "reactstrap";
import { AvForm, AvField, Lable } from "availity-reactstrap-validation";
import { getLaundry, fetchLaundryList, postAddLaundry, postUpdateLaundry, postArea } from "helpers/LaundryHelper"
import { postWeb } from "helpers/api_helper";
import { 
  getLaundries, 
  searchUsers,
  getTimeSlots,
  getCustomersLocation,
  getWhatsappOrders
 } from "../../store/actions"

//redux
import { useSelector, useDispatch } from "react-redux";

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb";

import Pages404 from "pages/Utility/pages-404";
import { map } from "lodash";

// import EcommerceOrdersModal from "./EcommerceOrdersModal";
const Offsymbol = () => {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100%",
        fontSize: 12,
        color: "#fff",
        paddingRight: 2
      }}
    >
      {" "}
      Off
    </div>
  )
}

const OnSymbol = () => {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100%",
        fontSize: 12,
        color: "#fff",
        paddingRight: 2
      }}
    >
      {" "}
      On
    </div>
  )
}



const phonePrefix = process.env.REACT_APP_PHONE_PREFIX;
const phoneMaxLen = phonePrefix==91?11:10;
const defaultLat = "24.71894638877753";
const defaultLng = "46.675559402959095";

const CreateOnlineOrder = props => {
  const dispatch = useDispatch();
  const [modal, setModal] = useState(false);
  const [locationType, setLocationType] = useState("");
  const [customerMobile, setCustomerMobile] = useState("");
  const [mapURL, setMapURL] = useState("");
  const [unit, setUnit] = useState("");
  const [clothType, setClothType] = useState("");
  const [areaName, setAreaName] = useState([])
  const [isEdit, setIsEdit] = useState(false)
  const [editableItem, setEditableItem] = useState({})
  const [error, setError] = useState({
    location : false,
    mobile : false
  })
  const [userData, setUserData] = useState({});
  const [clothesDataList, setClothesDataList] = useState([]);
  const [lat, SetLat] = useState(defaultLat);
  const [lng, SetLng] = useState(defaultLng);
  const [loading, SetLoading] = useState(false);
  const [hasBag, setHasBag] = useState(false);
  const [hasPlan, setHasPlan] = useState(false);
  const [pageLoading, setPageLoading ] = useState(false);
  
  

  const map = useRef();
  const orderForm = useRef();

  

  
  const [initialCenter, setInitialCenter] = useState({
    lat: lat,
    lng: lng
  });

  const [searchText, setSearchText] = useState("");


  const { timeslots, location, Allorders, users } = useSelector(state => ({
    timeslots: state.Timeslots.timeslots,
    location: state.Customers.location,
    Allorders: state.Orders.WhatsappOrders, 
    users: state.Users.users
  }));

  const Weeks = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];

  useEffect(() => {
    if (!modal) {
      
      setLocationType("")
      setIsEdit(false)
      setEditableItem({})
    }
  }, [modal])

  useEffect(() => {
    const timeoutPointer = setTimeout(() => {
      //Extract Lat and Lng 
      //const result = mapURL.match(/\/place\/.*?@/);
      if(!mapURL){
        return;
      }

      if(!mapURL.includes(",")){
        setError({
          location: true
        })
        return;
      }

      setError({
        location: false
      })

      let [_lat, _lng] = mapURL.split(',');
      _lat = _lat.trim();
      _lng = _lng.trim();
      if(_lat && _lng){

        postWeb("areas/checkAvailability", {
          longitude: _lng, 
          latitude: _lat
        })
        .then((response) => {
          if(response.statusCode == 200){
            SetLat(_lat);
            SetLng(_lng);
            setTimeout(() => {
              map.current.setCenter(new google.maps.LatLng(_lat, _lng));
            }, 100)
          }
          else{
            locationNotAvailable();
            toastr.error("Service not available in given area", "Service Unavailable");
          }
          
        });

        
      }
      else{
        locationNotAvailable();
      }
      
    }, 100);
    
    return () => {
      clearTimeout(timeoutPointer);
    }
  }, [mapURL]);

  const locationNotAvailable = () => {
    SetLat(defaultLat);
    SetLng(defaultLng);
  }

  const formatMobileNumber = (mb) => {
    let mobile = mb;
    if(mb.charAt(0) === '0')
    {
      mobile = mb.substring(1);
    }
    return mobile;
  }

  useEffect(() => {
    const timeoutPointer = setTimeout(() => {
       
      if(customerMobile.length == phoneMaxLen){
        const chars = Array.from(customerMobile);
        if(chars[0] != "0"){
          setError({
            mobile : true
          });
          return;
        }
        setError({
          mobile : false
        });
        
        let mobile = formatMobileNumber(customerMobile);

        dispatch(getCustomersLocation({
          mobile: phonePrefix + mobile
        }))
      }
      else{
        locationNotAvailable();
        setHasBag(false);
        setHasPlan(false);
        
      }
    }, 400);

    return () => {
      clearTimeout(timeoutPointer);
    }

  }, [customerMobile]);

  useEffect(() => {
    if(location){
      if(location.customer){
        if(location.customer.bagNo){
          setHasBag(true);
        }
        else{
          setHasBag(false);
        }
        if(location.customer.plan){
          setHasPlan(true);
        }
        else{
          setHasPlan(false);
        }
      }
      else{
        setHasBag(false);
        setHasPlan(false);
      }
      
      if(location.type){
        setLocationType(location.type);
      }
      

      if(location.latitude && location.longitude){
        
        SetLat(location.latitude);
        SetLng(location.longitude);
        
        setTimeout(() => {
          if(map.current){
            map.current.setCenter(new google.maps.LatLng(location.latitude, location.longitude));
          }
        }, 100);
      }
      else{
        
        SetLat(defaultLat);
        SetLng(defaultLng);

        if(map.current){
          map.current.setCenter(new google.maps.LatLng(defaultLat, defaultLng));
        }
      }
    }
  }, [location])

  const filterClothes = (q) => {  
    return clothes.filter((item) => item?.nameEn?.toLowerCase()?.includes(q) || item?.type?.toLowerCase()?.includes(q))
  }
  const handleSearch = (val) => {
    val = val?.toLowerCase()
    setSearchText(val);
    let filterBySearch = filterClothes(val);
    if (!val) {
      setClothesDataList(clothes)
    } else {
      setClothesDataList(filterBySearch)
    }
    // console.log("filterBySearch", filterBySearch);
  }

  

  useEffect(() => {
    dispatch(getTimeSlots({status : true}));
    dispatch(getWhatsappOrders())

  }, [])

  useEffect(() => {
    const data = {}
   if(!users) dispatch(searchUsers({ webPermissions: true }))
    dispatch(getLaundries(data))
  }, [])

  useEffect(() => {
    if (users.length > 0) {
      setUserData(users[0]);
    }
  }, [users])


  useEffect(() => {
    setPageLoading(false);
  }, [Allorders])

  
  const selectRow = {
    mode: "checkbox",
  };

  //pagination customization
  const pageOptions = {
    sizePerPage: 10,
    totalSize: clothesDataList.length, // replace later with size(orders),
    custom: true,
  };
  

  const handleEditCloth = (item) => {
    setEditableItem(item)
    setIsEdit(true);
    
    setModal(true)
  }

  useEffect(() => {
    if (!modal) {
      setError({})
    }
  }, [modal])

  const orderListColumns = [
    {
      name: "Order",
      selector: row => {
        return <>
         <div className="p-1">
          {row.orderID}
          <br />
         <span className="text-capitalize f-3">{row.type}</span>

         </div>
        </>
      },
      sortable: true,
    },
    {
      name: "Customer",
      maxWidth : 30,
      width: 30,
      grow: 0,
      selector: row => {
        return <>
         <div className="p-1">
          {row.customer?.customerID} <br /> {row.customer?.mobile}
          <br />
         <span className="text-capitalize f-3">{row?.location?.area?.name}</span>

         </div>
        </>
      }      // eslint-disable-next-line react/display-name
    },
    {
      name: "Date",
      maxWidth : "210px",
      width: "210px",
      grow: 0,
      selector: row => {
        return <>
         <div className="p-1">
          {row.pickupDate?
          <>P: {moment(row.pickupDate + " " + row.pickupTimeslot?.start).format("DD/MM/YYYY hh:mm A")}  <br /></>:null}
          
          {row.deliveryDate?
          <>D: {moment(row.deliveryDate + " " + row.pickupTimeslot?.end).format("DD/MM/YYYY hh:mm A")} </>: 
          <>S: {moment(row.startTime).format("DD/MM/YYYY hh:mm A")}</>
          }

         </div>
        </>
      }
    },
    {
      name: "Bag",
      maxWidth : 10,
      width: 10,
      grow: 0,
      selector: row =>  row.customer?.bagNo
    },
    {
      name: "Laundry",
      sort: true,
      wrap: true,
      selector: row => (row.laundry?.shortName)?(row.laundry?.shortName):(row.laundry?.nameEn)
    },
    {
      name: "Pickup",
      sort: true,
      wrap: true,
      selector: row => {
        return <>
            <span>{row.pickupDate} <br /> {row.pickupTimeslot?<>{row.pickupTimeslot?.start} - {row.pickupTimeslot?.end}</>: null}</span>
        </>
      }
    },
    {
      name: "Delivery",
      sort: true,
      wrap: true,
      selector: row => {
         return <>
          <span>{row.deliveryDate} <br /> {row.deliveryTimeslot?<>{row.deliveryTimeslot?.start} - {row.deliveryTimeslot?.end}</>: null}</span>
        </>
      }
    },
    {
      name: "Status",
      maxWidth : "150px",
      width: "150px",
      grow: 0,
      selector: row => {
        return <div>
          
            <Badge 
            className={` text-capitalize font-size-10 badge-soft-primary mb-1 rounded-pill`}
            color="primary">{row.status}</Badge>
            
            {row.paid == true?
              <Badge 
              className={` text-capitalize font-size-10 badge-soft-success mb-1 rounded-pill`}
              color="success">PAID</Badge>
              :
              <Badge 
              className={` text-capitalize font-size-10 badge-soft-danger mb-1 rounded-pill`}
              color="danger">UNPAID</Badge>
            }
          
          </div>
      },
    }
    
  ];



  const toggle = () => {
    setModal(!modal);
  };

  const submitOrder = (event, values) => {
    
    if(lat == defaultLat || lng == defaultLng){
      setError({
        location : true
      })
      toastr.error("Please enter customer location coordinates ", `Required`);
      return;
    }
    
    const data = {
      mobile : phonePrefix + formatMobileNumber(values.mobile),
      latitude: lat, 
      longitude: lng, 
      type: values.clothType,
      locationType : values.locationType
    };

    if(values.pickupDate && values.pickupTimeslot){
      data.pickupDate = values.pickupDate;
      data.pickupTimeslot = values.pickupTimeslot;
    }

    if(values.deliveryDate && values.deliveryTimeslot){
      data.deliveryDate = values.deliveryDate;
      data.deliveryTimeslot = values.deliveryTimeslot;
    }

    SetLoading(true);
    setPageLoading(true);
    postWeb("orders/createWhatsappOrder", data)
    .then((response) => {
      orderForm.current.reset();
      setCustomerMobile("");
      setLocationType("");
      setMapURL("");

      SetLoading(false);
      toggle();
      if(response && response.statusCode == 200){
        
        dispatch(getWhatsappOrders());
        
        toastr.success("Order created successfully.", "Order Created. Order ID " + response.orderID);
        SetLat(defaultLat);
        SetLng(defaultLng);
        
        if(map.current){
          map.current.setCenter(new google.maps.LatLng(defaultLat, defaultLng));
        }
      }
      
    });
  }

  
  const handleOrderClicks = () => {
    toggle();
  };

  const defaultSorted = [
    {
      dataField: "orderID",
      order: "desc",
    },
  ];

  const extractLatLong = (url) => {



  }

  const onPointerChange = (data) => {
    SetLat(data.latLng.lat())
    SetLng(data.latLng.lng())
  }

  const _mapLoaded = (mapOptions, _map) => {
    map.current = _map;
  }

  const handleAddOrder = () => {
    setModal(true);
  }

  if (userData?.permissions?.allPermissions == true || userData?.permissions?.whatsappOrders?.read == true) {
    return (
      <React.Fragment>
        {/* <EcommerceOrdersModal isOpen={modal1} toggle={toggleViewModal} /> */}
        <div className="page-content">
          <MetaTags>
            <title>Create Online Order</title>
          </MetaTags>
          <Container fluid>
            <Breadcrumbs title="Home" breadcrumbItem="Whatsapp Orders" />
            <Card>
              <CardBody>
              <Modal isOpen={modal} toggle={toggle} size="xl">
                <ModalHeader toggle={toggle} tag="h4">
                  Add Whatsapp Order
                </ModalHeader>
                <ModalBody>
                  <Row>
                  <Col >
                  <AvForm
                    onValidSubmit={submitOrder}
                    ref={orderForm}
                  >
                    <Row form>
                      <div className="row mb-2">
                        <Col className="col-6">


                          <div className="mb-3">
                            <AvField
                              name="mobile"
                              label={<div>Customer Mobile <span className="mandatory_feild">*</span></div>}
                              type="text"
                              
                              validate={{
                                required: {value: true , errorMessage:"Enter valid customer mobile"},
                                pattern: {value: '^[0-9]+$', errorMessage: 'Mobile number must only contain numbers'},
                                minLength: {value: phoneMaxLen, errorMessage:"Enter valid mobile number"},
                                maxLength : {value: phoneMaxLen, errorMessage:"Enter valid mobile number"}
                              }}
                              onChange={(e) => { setCustomerMobile(e.target.value) }}
                              value={customerMobile}
                            />
                            {error.mobile?<span className="text-danger">Please enter valid mobile number</span>:null}

                          </div>
                        </Col>
                        <Col lg="6">

                          <div className="mb-3">
                            <AvField
                              name="location"
                              label={<div>Customer Location <span className="mandatory_feild">*</span></div>}
                              type="text"
                              placeholder="Paste Coordinates here"
                              
                              onChange={(e) => { setMapURL(e.target.value) }}
                              value={mapURL}
                            />
                            {error.location?<span className="text-danger">Please enter validation coordinates for customer location</span>:null}
                          </div>
                        </Col>
                      </div>
                    </Row>
                    <Row>
                      <Col lg="12">
                        <div className="map-space">
                        <div
                          id="gmaps-markers"
                          className="gmaps"
                          style={{ position: "relative", width: "100%" }}
                        >
                          {lat==defaultLat && lng == defaultLng?
                          <div style={{height:"200px"}} className="p-3 text-center">
                            <h3 className="text-warning">Location not available.</h3>
                          </div>:
                          <Map
                            google={google}
                            style={{ width: "100%", height: "100%" }}
                            zoom={16}
                            initialCenter={initialCenter}
                            onReady={(mapProps, map) => _mapLoaded(mapProps, map)}
                          >
                            <Marker
                              position={{
                                lat: lat,
                                lng: lng
                              }}
                              draggable={true}
                              onDragend={(t, map, coord) => onPointerChange(coord)}
                              name={"locationMap"}
                            />
                            
                          </Map>}
                        </div>
                        </div>
                      </Col>                       
                    </Row>
                    <Row className="mt-3">
                        <Col lg="6" >
                         <AvField
                              name="locationType"
                              label={<div>Type Of Location <span className="mandatory_feild">*</span></div>}
                              type="select"
                              errorMessage="Select type of location"
                              validate={{
                                required: true,
                              }}
                              onChange={(e) => { setLocationType(e.target.value) }}
                              value={locationType}
                            >
                              <option value="">Select Location Type</option>  
                              <option value="apartment">Apartment</option>  
                              <option value="villa">Villa</option>  
                            </AvField>
                         
                        </Col>
                        <Col lg="6">
                           <AvField
                              name="clothType"
                              label={<div>Type of Cloth <span className="mandatory_feild">*</span></div>}
                              type="select"
                              errorMessage="Select cloth type"
                              validate={{
                                required: true,
                              }}
                              onChange={(e) => { setClothType(e.target.value) }}
                              value={clothType}
                            >
                              <option value="">Select Cloth Type</option>
                              <option value="clothes">Cloth</option>  
                              <option value="carpet">Carpet</option>  
                            </AvField>
                        </Col>
                    </Row>
                    
                    {!hasBag  || locationType == "villa" || clothType == "carpet"?
                    <Row>
                        <Col lg="6" className="mt-3">
                         <AvField
                              name="pickupDate"
                              label={<div>Pickup Date <span className="mandatory_feild">*</span></div>}
                              type="date"
                              errorMessage="Select pickup date"
                              validate={{
                                required: true,
                              }}
                              onChange={(e) => {  }}
                              value=""
                            />
                         
                        </Col>
                        <Col lg="6" className="mt-3">
                           <AvField
                              name="pickupTimeslot"
                              label={<div>Pickup Timeslot <span className="mandatory_feild">*</span></div>}
                              type="select"
                              errorMessage="Select pickup timeslots"
                              validate={{
                                required: true,
                              }}
                              onChange={(e) => {  }}
                              value=""
                            >
                              <option value="">Select Pickup Timeslot</option>
                              {timeslots.map((timeslot) => (
                                <option key={timeslot._id} value={timeslot._id}>{timeslot.start} - {timeslot.end}</option>
                              ))}
                            </AvField>
                        </Col>
                    </Row>: 
                    <Row>
                        <Col lg="6" className="mt-3">
                                <Label>Bag No: </Label> {location?.customer?.bagNo}
                        </Col>
                        <Col lg="6" className="mt-3">
                                <Label>Plan: </Label> {(location?.customer?.plan)?"Yes":"No"}
                        </Col>
                    </Row>
                    }
                    
                    {!hasBag  || locationType == "villa" || clothType == "carpet"? 
                    <Row>
                        <Col lg="6" className="mt-3">
                         <AvField
                              name="deliveryDate"
                              label={<div>Delivery Date <span className="mandatory_feild">*</span></div>}
                              type="date"
                              errorMessage="Select delivery date"
                              validate={{
                                required: true,
                              }}
                              onChange={(e) => {  }}
                              value=""
                            />
                         
                        </Col>
                        <Col lg="6" className="mt-3">
                           <AvField
                              name="deliveryTimeslot"
                              label={<div>Delivery Timeslot <span className="mandatory_feild">*</span></div>}
                              type="select"
                              errorMessage="Select delivery timeslot"
                              validate={{
                                required: true,
                              }}
                              onChange={(e) => {  }}
                              value={""}
                            >
                              <option value="">Select Delivery Timeslot</option>
                              {timeslots.map((timeslot) => (
                                <option key={timeslot._id} value={timeslot._id}>{timeslot.start} - {timeslot.end}</option>
                              ))}  
                            </AvField>
                        </Col>
                    </Row>: null}

                               
                    <Row>
                      <Col lg="12">
                        <div className="text-end p-2">
                          <button 
                            className="btn btn-secondary mr-2"
                            onClick={() => toggle()}
                            >
                                Close
                          </button>
                          {loading?
                          <button className="btn btn-success" disabled><i className="fas fa fa-spinner fa-spin"></i> Creating Order...</button>:
                          <button
                            type="submit"
                            className="btn btn-success save-user"
                          >
                            Create Order
                          </button>}
                        </div>
                      </Col>
                    </Row>

                  </AvForm>
                  </Col>
                </Row>
                </ModalBody>
                </Modal>


                <Row className="mb-2">
                  <Col sm="4">
                    
                  </Col>
                  <Col sm="8">
                    <div className="text-sm-end">
                      {(userData?.permissions?.allPermissions == true || userData?.permissions?.whatsappOrders?.create == true) ?
                        <Button
                          type="button"
                          color="success"
                          className="btn-rounded  mb-2 me-2"
                          onClick={handleAddOrder}
                        >
                          <i className="mdi mdi-plus me-1" />
                          Add Whatsapp Order
                        </Button> : null}
                    </div>
                  </Col>
                </Row> 
                <Row>
                  <Col className="mt-3">
                      <h3>Whatsapp Orders</h3>
                  </Col>
                </Row>
                <Row>
                  <Col xl="12">
                    <div className="table-responsive table-ongoing-orders">
                      {pageLoading?
                      <table className="table table align-middle table-nowrap">                       
                        <tbody>
                          {[...Array(10).keys()].map((i) => 
                            <tr key={i}>
                              <td><Skeleton count={1} /></td>
                              <td><Skeleton count={1} /></td>
                              <td><Skeleton count={1} /></td>
                              <td><Skeleton count={1} /></td>
                              <td><Skeleton count={1} /></td>
                              <td><Skeleton count={1} /></td>
                              <td><Skeleton count={1} /></td>
                              <td><Skeleton count={1} /></td>
                            </tr>
                          )}
                        </tbody>
                      </table>:
                      <DataTable
                        columns={orderListColumns}
                        data={Allorders}
                        pagination
                        paginationPerPage={30}
                        customStyles={{
                          headRow: {
                            style : {
                              minHeight: "30px"
                            }
                          },
                          rows:{
                            style: {
                              minHeight:"30px"
                            }
                          },
                          headCells: {
                            style:{
                              fontSize :"10px"
                            }
                          },
                          cells:{
                            style:{
                              fontSize :"10px"
                            }
                          }
                        }}
                      />}

                    </div>
                  </Col>
                </Row>

                
              </CardBody>
            </Card>
            

          </Container>
        </div>
      </React.Fragment>
    );
  }
  else {
    return <Pages404 />
  }
};

CreateOnlineOrder.propTypes = {

};

export default withRouter(GoogleApiWrapper({
  apiKey: "AIzaSyAvZvBwMKrllZNCXdikzvCDOzu8rwqv-0c"
})(CreateOnlineOrder));
