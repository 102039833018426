import { call, put, takeEvery } from "redux-saga/effects"

// Crypto Redux States
import { GET_RECORD, GET_SEARCH_RECORD, GET_CUSTOMERS_LOCATION, GET_CUSTOMERS_LIST, GET_CUSTOMERS_OTPLIST, GET_OTP, GET_CUSTOMER_DETAILS, GET_CUSTOMER_DETAILS_UI } from "./actionTypes"
import { apiSuccess, apiFail } from "./actions"
import toastr from "toastr";
import "toastr/build/toastr.min.css";

//Include Both Helper File with needed methods
import { fetchCustomersLocation, fetchCustomersList,fetchCustomersOTPList, fetchOTP, fetchCustomers, fetchCustomerDetails } from "helpers/customersHelper"

function* getCustomersLocation({ payload: data }) {
  try {
    const response = yield call(fetchCustomersLocation, data)
    yield put(apiSuccess(GET_CUSTOMERS_LOCATION, response))
  } catch (error) {
    yield put(apiFail(GET_CUSTOMERS_LOCATION, error))
  }
}

function* getCustomerList({ payload: data }) {
  try {
    const response = yield call(fetchCustomersList, data)
    yield put(apiSuccess(GET_CUSTOMERS_LIST, response))
  } catch (error) {
    yield put(apiFail(GET_CUSTOMERS_LIST, error))
  }
}
function* getCustomerOTPList({ payload: data }) {
  try {
    const response = yield call(fetchCustomersOTPList, data)
    yield put(apiSuccess(GET_CUSTOMERS_OTPLIST, response))
  } catch (error) {
    yield put(apiFail(GET_CUSTOMERS_OTPLIST, error))
  }
}
function* getOTP({ payload: data }) {
  try {
    const response = yield call(fetchOTP, data)
    yield put(apiSuccess(GET_OTP, response))
    
  } catch (error) {
    reject(error)
    yield put(apiFail(GET_OTP, error))
  }
}

function* getCustomerDetails({ payload: data }) {
  try {
    const response = yield call(fetchCustomers, data);
    console.log("getCustomerDetails response from SAGA::::: ", response, response.data, response.statusCode);
    if (response.statusCode == 400) {
      toastr.error("No customer found with this mobile number.");
    }
    yield put(apiSuccess(GET_CUSTOMER_DETAILS, response));
  } catch (error) {
    yield put(apiFail(GET_CUSTOMER_DETAILS, error));
  }
}

function* getCustomerDetailsUI({ payload: data }) {
  try {
    const response = yield call(fetchCustomerDetails, data);
    yield put(apiSuccess(GET_CUSTOMER_DETAILS_UI, response));
  } catch (error) {
    yield put(apiFail(GET_CUSTOMER_DETAILS_UI, error));
  }
}

function* customersSaga() {
  yield takeEvery(GET_CUSTOMERS_LOCATION, getCustomersLocation)
  yield takeEvery(GET_CUSTOMERS_LIST, getCustomerList)
  yield takeEvery(GET_CUSTOMERS_OTPLIST, getCustomerOTPList)
  yield takeEvery(GET_OTP, getOTP),
  yield takeEvery(GET_CUSTOMER_DETAILS, getCustomerDetails)
  yield takeEvery(GET_CUSTOMER_DETAILS_UI, getCustomerDetailsUI)
}

export default customersSaga
