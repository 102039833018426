import React, { useState } from "react"
import { Row, Col, Card, CardBody, Spinner } from "reactstrap"
import MetaTags from 'react-meta-tags'

// datatable related plugins
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory, {
  PaginationListStandalone,
  PaginationProvider
} from 'react-bootstrap-table2-paginator';

import ToolkitProvider, {Search} from 'react-bootstrap-table2-toolkit';
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"
import "../../assets/scss/datatables.scss"
import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux"
import { getCustomerOTPList, getOTP } from "../../store/actions"
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import Pages404 from "pages/Utility/pages-404";
import moment from 'moment-timezone';
import { useHistory } from "react-router-dom";
import "toastr/build/toastr.min.css";



const CustomersOTPList = () => {
  const dispatch = useDispatch()
  const [state, setState] = useState(false);
  const history = useHistory();
  const { customers, users, otp } = useSelector(state => ({
    customers: state.Customers.customers,
    users: state.Users.users,
    otp: state.Customers.otp
  }))
  const [customerData, setCustomerData] = useState([]);
  const [showIcon, setshowIcon] = useState("")
  const [isLoading, setIsLoading] = useState(false);
  const [spinner, setSpinner] = useState(false);
  const [userData, setUserData] = useState({});
  const [totalRecords, setTotalRecords] = useState(0);

  const handleIcon = async (e, row) => {
    setIsLoading(true)
    if(!showIcon || showIcon != row._id){
      await dispatch(getOTP({ webPermissions: true, customerId :row._id }))
    }
    if (showIcon && row._id == showIcon) {
        setshowIcon("")
        setIsLoading(false)
        return
      }
    setshowIcon(row._id)
  }

  useEffect(() => {
    dispatch(getCustomerOTPList({ webPermissions: true }))
  }, [])

  useEffect(() => {
    if (users.length > 0) {
      setUserData(users[0]);
    }
  }, [users])

  useEffect(() => {
    const _customers = customers?.data;
   
    if (_customers?.length) {
      setCustomerData(_customers)
    }
    setIsLoading(false);
  }, [customers, otp])

  const columns = [{
    dataField: 'customerID',
    text: 'Customer ID',
    sort: false
  }, {
    dataField: 'name',
    text: 'Name',
    sort: false
  },
  {
    dataField: 'email',
    text: 'Email',
    sort: false,
    formatter: (cellContent, row, i) => {
      return <>
        {row?.email ? row.email : <span className="text-danger"> N/A</span>}
      </>
    }
  },
  {
    dataField: 'mobile',
    text: 'Mobile',
    sort: false,
    formatter: (cellContent, row, i) => {
      return <>
        {row?.mobile ? row.mobile : <span className="text-danger"> N/A</span>}
      </>
    }
  },
  {
    dataField: 'lastOTPSentTime',
    text: 'Last OTP Sent Time',
    sort: false,
    formatter: (cellContent, row, i) => {
      return <>
        {row?.lastOTPSentTime ? moment(row?.lastOTPSentTime).format("lll") : null}
      </>
    }
  },
  {
    dataField: 'otp',
    text: 'OTP',
    sort: false,
    formatter: (cell, row, rowIndex, columnIndex) => {
      return <div className="flex">
        {showIcon != row._id ?
          <span style={{ fontSize: "16px" }} onClick={(e) => handleIcon(e, row, columnIndex, rowIndex)}><i className="fas fa-eye" ></i></span >
          :
          <span onClick={(e) => handleIcon(e, row, columnIndex, rowIndex)} style={{ fontSize: "16px" }}>
            <i className="fas fa-eye-slash" ></i> </span>}
        {
         isLoading && <span> <Spinner size="sm" color="success">
            Loading...
          </Spinner></span>
        }
        {/* {
          row._id == showIcon && spinner == true && <span> <Spinner size="sm" color="success">
            Loading...
          </Spinner></span>
        } */}

        <span style={{ marginLeft: "10px" }}> {showIcon == row._id ? otp && otp?.otp : "* * * *"}</span>
      </div>
    }
  },

  ];

  const defaultSorted = [{
    dataField: 'id',
    order: 'asc'
  }];

  const pageOptions = {
    sizePerPage: 20,
    totalSize: customerData.length, // replace later with size(customers),
    custom: true,
  }
  const { SearchBar } = Search

  // Custom Pagination Toggle
  const sizePerPageList = [
    { text: '5', value: 5 },
    { text: '10', value: 10 },
    { text: '15', value: 15 },
    { text: '20', value: 20 },
    { text: '25', value: 25 },
    { text: 'All', value: customerData?.length }];

  if (userData?.permissions?.allPermissions == true || userData?.permissions?.customerOTP?.read == true) {
    return (
      <React.Fragment>
        <div className="page-content">
          <MetaTags>
            <title>Kleen | Customers</title>
          </MetaTags>
          <div className="container-fluid">
            <Breadcrumbs title="Customers" breadcrumbItem="Customers OTP List (Last 24 Hours)" />

            <Row>
              <Col className="col-12">
                <Card>
                  <CardBody>
                    <Row className="mb-2">
                    </Row>
                    {
                      isLoading ? (
                        <div className="row">
                          <div className="col-xl-12">
                            <div className="table-responsive">
                              <div className="react-bootstrap-table">
                                <table className="table table align-middle table-nowrap">
                                  <thead className="table-light">
                                    <tr>
                                      <th>Customer ID</th>
                                      <th>Name</th>
                                      <th>Email</th>
                                      <th>Registration Date</th>
                                      <th>OTP</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {customerData?.map((k) => (
                                      <tr key={k}>
                                        <td><Skeleton count={1} /></td>
                                        <td><Skeleton count={1} /></td>
                                        <td><Skeleton count={1} /></td>
                                        <td><Skeleton count={1} /></td>
                                        <td><Skeleton count={1} /></td>
                                      </tr>
                                    ))}
                                  </tbody>
                                </table>
                              </div>
                            </div>
                          </div>
                        </div>
                      ) : (
                        <PaginationProvider
                          pagination={paginationFactory(pageOptions)}
                          keyField='id'
                          columns={columns}
                          data={customerData}
                        >
                          {({ paginationProps, paginationTableProps }) => (
                            <ToolkitProvider
                              keyField='id'
                              columns={columns}
                              data={customerData}
                              search
                            >
                              {toolkitProps => (
                                <React.Fragment>
                                  
                                  <Row className="mb-2">
                                    <Col sm="4">
                                      <div className="search-box me-2 mb-2 d-inline-block">
                                        <div className="position-relative">
                                          <SearchBar {...toolkitProps.searchProps} />
                                          <i className="bx bx-search-alt search-icon" />
                                        </div>
                                      </div>
                                    </Col>
                                    <Col sm="8">
                                      
                                    </Col>
                                  </Row>
                                  <Row>
                                    <Col xl="12">
                                      <div className="table-responsive">
                                        <BootstrapTable
                                          keyField={"id"}
                                          key={showIcon}
                                          responsive
                                          bordered={false}
                                          striped={false}
                                          defaultSorted={defaultSorted}
                                          // rowEvents={rowEvents}
                                          // selectRow={selectRow}
                                          classes={
                                            "table align-middle table-nowrap"
                                          }
                                          headerWrapperClasses={"table-light"}
                                          // headerWrapperClasses={"thead-light"}
                                          {...toolkitProps.baseProps}
                                          {...paginationTableProps}
                                        />

                                      </div>
                                    </Col>
                                  </Row>
                                  
                                  <Row className="align-items-md-center mt-30">
                                    <Col className="pagination pagination-rounded justify-content-end mb-2 inner-custom-pagination">
                                      <PaginationListStandalone
                                        {...paginationProps}
                                      />
                                    </Col>
                                  </Row>
                                </React.Fragment>
                              )
                              }
                            </ToolkitProvider>
                          )
                          }</PaginationProvider>
                      )
                    }

                  </CardBody>
                </Card>
              </Col>
            </Row>

          </div>
        </div>
      </React.Fragment>
    )
  }
  else {
    return <Pages404 />
  }
}

export default CustomersOTPList
