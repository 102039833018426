import axios from "axios"
import accessToken from "./jwt-token-access/accessToken"




//pass new generated access token here
const token = accessToken

//apply base url for axios

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL

const API_URL = `${API_BASE_URL}/apis`


const WEB_API_URL = `${API_BASE_URL}/apis`

const axiosApi = axios.create({
  baseURL: API_URL,
  withCredentials: false,
  headers: {
    'Access-Control-Allow-Origin': '*',
    "Content-Type": "application/json"
  }
})

const axiosApiWeb = axios.create({
  baseURL: WEB_API_URL,
  withCredentials: false,
  headers: {
    'Access-Control-Allow-Origin': '*',
    "Content-Type": "application/json"
  }
})


// axios.defaults.headers.post['Access-Control-Allow-Origin'] = '*';
axiosApi.defaults.headers.common["Authorization"] = token

axiosApiWeb.defaults.headers.common["Authorization"] = token


axiosApi.interceptors.response.use(
  response => response,
  error => Promise.reject(error)
)

axiosApiWeb.interceptors.response.use(
  response => response,
  error => Promise.reject(error)
)

export async function get(url, config = {}) {

  return await axiosApi.get(url, { ...config }).then(response => response.data)
}

export async function getWeb(url, config = {}) {

  return await axiosApiWeb.get(url, { ...config }).then(response => response.data)
}


export function post(url, params, content = false) {
  let authUser = localStorage.getItem("authUser");
  authUser = JSON.parse(authUser);
  let accessToken = "";
  if (authUser && authUser != undefined) {
    accessToken = "Bearer " + authUser.token;
  }

  const config = {
    headers: {
      "Authorization": accessToken,
      "content-type": content ? "multipart/form-data" : "application/json",
    }
  };
  //  return axiosApi.post(url, data, { ...config }).catch((error) => error ).then(response => response.data )
  const promise = axiosApi.post(url, params, { ...config });
  const dataPromise = promise
    .catch((error) => {
      if (error.response.status == 401) {
        window.location.href = "/logout";
      }
    })
    .then((response) => response.data);

  return dataPromise;
}


export function postWeb(url, params) {
  let authUser = localStorage.getItem("authUser");
  authUser = JSON.parse(authUser);
  let accessToken = "";
  if (authUser && authUser != undefined) {
    accessToken = "Bearer " + authUser.token;
  }

  const config = {
    headers: {
      "Authorization": accessToken
    }
  };
  //  return axiosApi.post(url, data, { ...config }).catch((error) => error ).then(response => response.data )
  const promise = axiosApiWeb.post(url, params, { ...config });
  const dataPromise = promise
    .catch((error) => {
      if (error.response.status == 401) {
        window.location.href = "/logout";
      }
    })
    .then((response) => response.data);

  return dataPromise;
}

export async function put(url, data, config = {}) {
  return axiosApi
    .put(url, { ...data }, { ...config })
    .then(response => response.data)
}

export async function del(url, config = {}) {
  return await axiosApi
    .delete(url, { ...config })
    .then(response => response.data)
}
