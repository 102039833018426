import PropTypes from "prop-types"
import React, { useEffect, useState, useRef } from "react"
import MetaTags from "react-meta-tags";
import { Formik, Field, Form } from 'formik';

import {
  Container,
  Row,
  Col,
  
  Badge
} from "reactstrap"

import paginationFactory, {
  PaginationListStandalone,
  PaginationProvider,
} from "react-bootstrap-table2-paginator";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import BootstrapTable from "react-bootstrap-table-next";
import * as moment from "moment";


import {get, post} from "helpers/api_helper"


//import action
import { getHistoryOrders } from "../../store/actions"

import modalimage1 from "../../assets/images/product/img-7.png"
import modalimage2 from "../../assets/images/product/img-4.png"


import CardUser from "../Dashboard-Blog/CardUser";
import Pie from "../AllCharts/echart/piechart"
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"
import io from "socket.io-client";

//i18n
import { withTranslation } from "react-i18next"

//redux
import { useSelector, useDispatch } from "react-redux"
import { values } from "lodash";

const OrderHistory = props => {
  const [modal, setmodal] = useState(false)

  const { orders } = useSelector(state => ({
    orders: state.Orders.orderHistory,
  }))

  var node = useRef();
  const keyField = "id";
  const { SearchBar } = Search;
  const sizePerPage = 10;
  const pageOptions = {
    sizePerPage: sizePerPage,
    totalSize: orders.length, // replace later with size(users),
    custom: true,
  };
  const defaultSorted = [
    {
      dataField: "id", // if dataField is not match to any column you defined, it will be ignored.
      order: "desc", // desc or asc
    },
  ];

  const selectRow = {
    mode: "checkbox",
  };

  const orderListColumns = [
    {
      text: "id",
      dataField: "id",
      sort: true,
      hidden: true,
      // eslint-disable-next-line react/display-name
      formatter: row => <>{row._id}</>,
    },
    {
      dataField: "orderID",
      text: "order ID",
      sort: true,
    },
    {
      text: "customer ID",
      dataField: "customer.customerID",
      sort: true,
      // eslint-disable-next-line react/display-name
    },
    {
      text: "start Time",
      dataField: "startTime",
      sort: true,
      formatter: field => <>{moment(field).format("DD/MM/YYYY hh:mm:ss A")}</>,
      //;
    },
    {
      text: "Pickup Date",
      dataField: "pickupDate"
    },
    {
      text: "Delivery Date",
      dataField: "deliveryDate"
    },
    {
      text: "Status",
      dataField: "status",
      sort: true,
      formatter: (cell, row) => {
        return <>
          <Badge 
          className={`text-capitalize font-size-12 badge-soft-${getStatusColor(row.status)}`}
          pill
          color="primary">{row.status}</Badge>
          &nbsp;
          <Badge 
          className={`font-size-12 badge-soft-${getStatusColor(row.paid?'paid':'unpaid')}`}
          pill
          color="primary">{row.paid?'Paid':'Unpaid'}</Badge>
        </>
      },
    },
    
  ];



  const options = null;
 const initialData = {
  start :"2022-01-01",
  end :"2022-04-30"
 };



  function getStatusColor(status){
    switch(status){
      case 'paid':
        return 'success';
      case 'unpaid':
      case 'cancelled':
        return 'warning';
      default:
        return 'primary';
    }
  }

  

  
  
  

  


  const dispatch = useDispatch();

  useEffect(() => {
    console.log("Use effevvt");
    dispatch(getHistoryOrders())
  }, [dispatch]);

  
  

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Order History | Kleen Admin Panel</title>
        </MetaTags>
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumbs
            title={props.t("Orders History")}
            breadcrumbItem={props.t("Order History")}
          />
          <Row>
            <Col>
            <PaginationProvider
                    pagination={paginationFactory(pageOptions)}
                    keyField={keyField}
                    columns={orderListColumns}
                    data={orders}
                  >
                    {({ paginationProps, paginationTableProps }) => {
                      return (
                        <ToolkitProvider
                          keyField={keyField}
                          data={orders}
                          columns={orderListColumns}
                          bootstrap4
                          search
                        >
                          {toolkitProps => (
                            <React.Fragment>
                              <Row className="mb-2">
                                <Col sm="4">
                                  <div className="search-box ms-2 mb-2 d-inline-block">
                                    <div className="position-relative">
                                      <SearchBar {...toolkitProps.searchProps} />
                                      <i className="bx bx-search-alt search-icon" />
                                    </div>
                                  </div>
                                </Col>
                               
                              </Row>
                              <Row>
                                <Col xl="12">
                                  <div className="table-responsive">
                                    <BootstrapTable
                                      keyField={keyField}
                                      {...toolkitProps.baseProps}
                                      {...paginationTableProps}
                                      
                                      defaultSorted={defaultSorted}
                                      classes={
                                        "table align-middle table-nowrap table-hover"
                                      }
                                      bordered={false}
                                      striped={false}
                                      responsive
                                      ref={node}
                                    />

                                  </div>
                                </Col>
                              </Row>
                              <Row className="align-items-md-center mt-30">
                                <Col className="pagination pagination-rounded justify-content-end mb-2">
                                  <PaginationListStandalone
                                    {...paginationProps}
                                  />
                                </Col>
                              </Row>
                            </React.Fragment>
                          )}
                        </ToolkitProvider>
                      );
                    }}
                  </PaginationProvider>
            </Col>

          </Row>
        </Container>
      </div>
  
    </React.Fragment>
  )
}

OrderHistory.propTypes = {
  t: PropTypes.any
}

export default withTranslation()(OrderHistory)
