import { call, put, takeEvery, all, fork } from "redux-saga/effects";

// Crypto Redux States
import { GET_COUPON, GET_COUPON_SUCCESS, GET_COUPON_FAIL, UPDATE_COUPON, ADD_COUPON } from "./actionTypes";
import { apiSuccess, apiFail } from "./actions";

//Include Both Helper File with needed methods
import { getCoupon, updateCoupons, addCoupons } from "helpers/fakebackend_helper";
import toastr from "toastr";
import "toastr/build/toastr.min.css";

function* fetchCoupon({ payload: data }) {
  try {
    const response = yield call(getCoupon, data);
    yield put(apiSuccess(GET_COUPON_SUCCESS, response.record));
  } catch (error) {
    yield put(apiFail(GET_COUPON_FAIL, error));
  }
}

function* updateCouponList({ payload: data }) {
  try {
    const response = yield call(updateCoupons, data);
    if (response?.statusCode == 200) {
      // yield put(apiSuccess(GET_COUPON, { laundry: data.laundry }));
      toastr.success("Coupon updated successfully", "Coupon");
    } else {
      toastr.error(response.msg, "Coupon");
    }
  } catch (error) {
    toastr.error("Coupon Update failed", "Coupon");
  }
}

function* addCouponList({ payload: data }) {
  try {
    const response = yield call(addCoupons, data);
    if (response?.statusCode == 200) {
      yield put(apiSuccess(GET_COUPON, { laundry: data.laundry }));
      toastr.success("Coupon Added successfully", "Coupon");
    } else {
      toastr.error(response.msg, "Coupon");
    }
  } catch (error) {
    toastr.error("Coupon Add failed", "Coupon");
  }
}



function* AnalyticsSaga() {
  yield takeEvery(GET_COUPON, fetchCoupon)
  yield takeEvery(UPDATE_COUPON, updateCouponList)
  yield takeEvery(ADD_COUPON, addCouponList)
}

export default AnalyticsSaga;
