import {
  GET_CARWASH_ORDERS,
  GET_CARWASH_ORDERS_SUCCESS,
  UPDATE_CARWASH_ORDERS,
  UPDATE_CARWASH_ORDERS_SUCCESS,
} from "./actionTypes"

export const getCarwashOrders = data => ({
  type: GET_CARWASH_ORDERS,
  payload: data,
})

export const getCarwashOrdersSuccess = data => ({
  type: GET_CARWASH_ORDERS_SUCCESS,
  payload: data,
})

export const updateCarwashOrders = data => ({
  type: UPDATE_CARWASH_ORDERS,
  payload: data,
})

export const updateCarwashOrdersSuccess = data => ({
  type: UPDATE_CARWASH_ORDERS_SUCCESS,
  payload: data,
})
