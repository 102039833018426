import React from "react"
import { Link } from "react-router-dom"
import { Container, Row, Col } from "reactstrap"
import MetaTags from 'react-meta-tags';

//Import Images
import error from "../../assets/images/error-img.png"

const WelcomePage = () => {
  return (
    <React.Fragment>
      <div className="account-pages my-5 pt-5">
        <MetaTags>
          <title>Welcome- React Admin & Dashboard Template</title>
        </MetaTags>
        <Container>
          <Row className="d-flex">
            <Col lg="12">
              <div className="text-center mb-5 mt-5 ">

                <h1 className="text-uppercase mt-5 text-decoration-underline text-primary">Welcome to Kleen Admin Panel</h1>
               
              </div>
            </Col>
          </Row>
         
        </Container>
      </div>
    </React.Fragment>
  )
}

export default WelcomePage
