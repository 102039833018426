/* global google */
import React from "react";
import { withGoogleMap, GoogleMap, withScriptjs } from "react-google-maps";
import PropTypes from "prop-types"

import {
  MarkerWithLabel
}  from "react-google-maps/lib/components/addons/MarkerWithLabel";

const Markers = props => {
  const labelSize = { width: 80 };
  const labelPadding = 2;

  const shortenName = (name) => {
    return name.length>20?name.substring(1,20):name
  }
  

  return (
    <GoogleMap defaultZoom={props.zoom} defaultCenter={props.center}>
      {props.places.map(place => {
        if(place?.latitude && place?.longitude){
          const highLight = {};
          if(props.updatedLaundry == place._id.toString()){
            highLight.animation =  "blinker2 1s linear 15"
            highLight.backgroundColor = "yellow"
          }
          return (
            <MarkerWithLabel
              labelStyle={{
                textAlign: "center",
                width: labelSize.width + "px",
                boxSizing:"border-box",
                backgroundColor: "white",
                fontSize: "11px",
                fontWeight: "bold",
                padding: labelPadding + "px",
                border: "1px solid #999",
                color: "#ea4335",
                ...highLight
              }}
              labelClass="map-label"
              labelAnchor={{ x: labelSize.width / 2, y: -2 }}
              key={place._id}
              position={{ lat: place?.latitude, lng: place?.longitude }}
            >
              <>
              <span>{shortenName(place?.shortName?place?.shortName:place?.nameEn)}</span><br />
              <span style={{color:"green"}}>{place.totalGMV.toFixed(2)} SR</span>
              </>
            </MarkerWithLabel>
          );
          }
      })}
    </GoogleMap>
  );
};

Markers.propTypes = {
  center: PropTypes.object,
  zoom: PropTypes.number,
  places: PropTypes.array,
  updatedLaundry: PropTypes.any
}

export default withGoogleMap(Markers);
