import { 
  API_SUCCESS,
  API_FAIL,
  LIST_SETTINGS,
  CREATE_SETTING,
  UPDATE_SETTING,
} from "./actionTypes"

export const apiSuccess = (actionType, data) => ({
  type: API_SUCCESS,
  payload: { actionType, data },
});

export const apiFail = (actionType, error) => ({
  type: API_FAIL,
  payload: { actionType, error },
});

export const getSettings = (data) => ({
  type: LIST_SETTINGS,
  payload: data
})

export const createSetting = (data) => ({
  type: CREATE_SETTING,
  payload: data
})

export const updateSetting = (data) => ({
  type: UPDATE_SETTING,
  payload: data
})
