import PropTypes from "prop-types"
import React, { useEffect, useState, useRef } from "react"
import MetaTags from "react-meta-tags";
import { Formik, Field } from 'formik';
import { AvForm, AvField } from "availity-reactstrap-validation"
import { Link } from "react-router-dom"

import {
  Container,
  Row,
  Col,
  Modal,
  Button,
  Card,
  CardBody,
  CardTitle,
  Label,
  Input,
  Form
} from "reactstrap"

import paginationFactory, {
  PaginationListStandalone,
  PaginationProvider,
} from "react-bootstrap-table2-paginator";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import BootstrapTable from "react-bootstrap-table-next";
import * as moment from "moment";


import {postAddLaundry, postUpdateLaundry } from "helpers/LaundryHelper"


//import action
import { getLaundries, getLaundrydetails, toggleLaundryActive} from "../../store/actions"

import modalimage1 from "../../assets/images/product/img-7.png"
import modalimage2 from "../../assets/images/product/img-4.png"


import toastr from "toastr";
import "toastr/build/toastr.min.css";
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"

//i18n
import { withTranslation } from "react-i18next"

//redux
import { useSelector, useDispatch } from "react-redux"
import { values } from "lodash";

const Laundries = props => {
  const [modal, setmodal] = useState(false)
  const [laundryModal, SetLaundryModal] = useState(false)
  const [editLaundryModal, SetEditLaundryModal] = useState(false)

  const { laundryList } = useSelector(state => ({
    laundryList: state.Laundries.laundryList
  }))

  const { laundryDetails } = useSelector(state => ({
    laundryDetails: state.Laundries.laundryDetails
  }))



  
  var node = useRef();
  const keyField = "id";
  const { SearchBar } = Search;
  const sizePerPage = 10;
  const pageOptions = {
    sizePerPage: sizePerPage,
    totalSize: laundryList.length, // replace later with size(users),
    custom: true,
  };
  const defaultSorted = [
    {
      dataField: "id", // if dataField is not match to any column you defined, it will be ignored.
      order: "desc", // desc or asc
    },
  ];

  const selectRow = {
    mode: "checkbox",
  };

  const laundryListColumns = [
    {
      text: "id",
      dataField: "id",
      sort: true,
      hidden: true,
      // eslint-disable-next-line react/display-name
      formatter: row => <>{row._id}</>,
    },
    {
      dataField: "nameEn",
      text: "Name (English)",
      sort: true,
    },
    {
      dataField: "nameAr",
      text: "Name (Arabic)",
      sort: true,
    },
    {
      text: "Action",
      formatter: (cell, row) => {
        return <>
         <a onClick={() => showEditLaundry(row._id)} >Edit</a>
        </>
      }
    }    
  ];

  
  function toggleLaundryModal() {
    SetLaundryModal(!laundryModal);
    removeBodyCss();
  }

  function toggleEditLaundryModal() {
    SetEditLaundryModal(!editLaundryModal);
    removeBodyCss();
  }

  

  function showEditLaundry(laundryId){
    SetEditLaundryModal(true);
    dispatch(getLaundrydetails(laundryId));
  }

  function removeBodyCss() {
    document.body.classList.add("no_padding");
  }

  const options = null;
  const initialData = {
    start :"2022-01-01",
    end :"2022-04-30"
  };

  toastr.options = {
    positionClass: "toast-top-right",
    timeOut: 3000,
    extendedTimeOut: 1000,
    closeButton: true,
    debug: true,
    progressBar: true,
    preventDuplicates: true,
    newestOnTop: true,
    showEasing: "swing",
    hideEasing: "linear",
    showMethod: "fadeIn",
    hideMethod: "fadeOut",
    showDuration: 300,
    hideDuration: 1000
  };


  const  handleValidSubmit = async(event, values) => {
    const laundryResponse = await postAddLaundry(values);
    
    if(laundryResponse.statusCode == 400){
      toastr.warning(laundryResponse.msg, "Unable to add Laundry");
    }
    else if(laundryResponse.statusCode == 200){
      SetLaundryModal(false);
      dispatch(getLaundries());
      toastr.success("Laundry has been added", "Laundry Added");
    }
    else if(laundryResponse.statusCode == 200){
      toastr.error("Laundry is not added", "Error Occured");
    }
  }

  const  handleEditLaundrySubmit = async(event, values) => {
    values._id = laundryDetails._id;
    const laundryResponse = await postUpdateLaundry(values);
    
    if(laundryResponse.statusCode == 400){
      toastr.warning(laundryResponse.msg, "Unable to update Laundry");
    }
    else if(laundryResponse.statusCode == 200){
      SetEditLaundryModal(false);
      dispatch(getLaundries());
      toastr.success("Laundry has been updated", "Laundry Updated");
    }
    else if(laundryResponse.statusCode == 200){
      toastr.error("Error occurred while adding laundry.", "Error Occured");
    }
  }
  

  

  const toggleActive = (_laundryDetails) => {
    dispatch(toggleLaundryActive(_laundryDetails));
  }


  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getLaundries())
  }, [dispatch]);

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Laundries | Kleen Admin Panel</title>
        </MetaTags>
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumbs
            title={props.t("Home")}
            breadcrumbItem={props.t("Laundries")}
          />
          <Row>
            <Col>
            <PaginationProvider
                    pagination={paginationFactory(pageOptions)}
                    keyField={keyField}
                    columns={laundryListColumns}
                    data={laundryList}
                  >
                    {({ paginationProps, paginationTableProps }) => {
                      return (
                        <ToolkitProvider
                          keyField={keyField}
                          data={laundryList}
                          columns={laundryListColumns}
                          bootstrap4
                          search
                        >
                          {toolkitProps => (
                            <React.Fragment>
                              <Row className="mb-2">
                                <Col sm="10">
                                  <div className="search-box ms-2 mb-2 d-inline-block">
                                    <div className="position-relative">
                                      <SearchBar {...toolkitProps.searchProps} />
                                      <i className="bx bx-search-alt search-icon" />
                                    </div>
                                  </div>
                                </Col>

                                <Col sm="2">
                                  <Button
                                    color="primary"
                                    onClick={() => SetLaundryModal(true)}
                                    >Add Laundry</Button>
                                </Col>
                               
                              </Row>
                              <Row>
                                <Col xl="12">
                                  <div className="table-responsive">
                                    <BootstrapTable
                                      keyField={keyField}
                                      {...toolkitProps.baseProps}
                                      {...paginationTableProps}
                                      
                                      defaultSorted={defaultSorted}
                                      classes={
                                        "table align-middle table-nowrap table-hover"
                                      }
                                      bordered={false}
                                      striped={false}
                                      responsive
                                      ref={node}
                                    />

                                  </div>
                                </Col>
                              </Row>
                              <Row className="align-items-md-center mt-30">
                                <Col className="pagination pagination-rounded justify-content-end mb-2">
                                  <PaginationListStandalone
                                    {...paginationProps}
                                  />
                                </Col>
                              </Row>
                            </React.Fragment>
                          )}
                        </ToolkitProvider>
                      );
                    }}
                  </PaginationProvider>
            </Col>

          </Row>
          <div>
          <Modal
            size="lg"
            isOpen={laundryModal}
            toggle={() => {
              toggleLaundryModal();
            }}
          >
            <div className="modal-header">
              <h5 className="modal-title mt-0" id="myModalLabel">
                Add New Laundry
              </h5>
              <button
                type="button"
                onClick={() => {
                  SetLaundryModal(false);
                }}
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <Row>
                <Col lg="12">
                  <Card>
                  <CardBody>
                    <AvForm
                      onValidSubmit={(e, v) => {
                        handleValidSubmit(e, v)
                      }}
                    >
                      <div className="row mb-4">
                        <Label
                          htmlFor="horizontal-firstname-Input"
                          className="col-sm-3 col-form-label"
                        >
                          Laundry Name (English)
                        </Label>
                        <Col sm={9}>
                          <AvField
                            type="text"
                            className="form-control"
                            id="horizontal-firstname-Input"
                            placeholder="Display Name"
                            name="nameEn"
                            errorMessage="Please enter laundry english name"
                            validate={{ required: { value: true } }}
                          />
                        </Col>
                      </div>

                      <div className="row mb-4">
                        <Label
                          htmlFor="horizontal-firstname-Input"
                          className="col-sm-3 col-form-label"
                        >
                          Laundry Name (Arabic)
                        </Label>
                        <Col sm={9}>
                          <AvField
                            type="text"
                            className="form-control"
                            id="horizontal-firstname-Input"
                            placeholder="Display Name"
                            name="nameAr"
                            errorMessage="Please enter laundry arabic name"
                            validate={{ required: { value: true } }}
                          />
                        </Col>
                      </div>

                      
                      

                      <div className="row justify-content-end">
                        <Col sm={9}>
                          

                          <div>
                            <Button
                              type="submit"
                              color="primary"
                              className="w-md"
                            >
                              Add Laundry
                            </Button>

                            <button
                              type="button"
                              onClick={() => {
                                toggleLaundryModal();
                              }}
                              className="m-1 btn btn-secondary "
                              data-dismiss="modal"
                            >
                              Close
                            </button>

                          </div>
                        </Col>
                      </div>
                    </AvForm>
                  </CardBody>
                </Card>
                </Col>
              </Row>
            </div>
            <div className="modal-footer">
              
          
            </div>
          </Modal>

          {/** Edit User Modal */}                    
          <Modal
            size="lg"
            isOpen={editLaundryModal}
            toggle={() => {
              toggleEditLaundryModal();
            }}
          >
            <div className="modal-header">
              <h5 className="modal-title mt-0" id="myModalLabel">
                Edit Laundry
              </h5>
              <button
                type="button"
                onClick={() => {
                  SetEditLaundryModal(false);
                }}
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <Row>
                <Col lg="12">
                  <Card>
                  <CardBody>
                    <AvForm
                      onValidSubmit={(e, v) => {
                        handleEditLaundrySubmit(e, v)
                      }}
                    >
                      <div className="row mb-4">
                        <Label
                          htmlFor="horizontal-firstname-Input"
                          className="col-sm-3 col-form-label"
                        >
                          Name (English)
                        </Label>
                        <Col sm={9}>
                          <AvField
                            type="text"
                            className="form-control"
                            id="horizontal-firstname-Input"
                            placeholder="Display Name"
                            name="nameEn"
                            value={laundryDetails?laundryDetails.nameEn:null}
                            errorMessage="Please enter name"
                            validate={{ required: { value: true } }}
                          />
                        </Col>
                      </div>

                      <div className="row mb-4">
                        <Label
                          htmlFor="horizontal-firstname-Input"
                          className="col-sm-3 col-form-label"
                        >
                          Name (Arabic)
                        </Label>
                        <Col sm={9}>
                          <AvField
                            type="text"
                            className="form-control"
                            id="horizontal-firstname-Input"
                            placeholder="Display Name"
                            name="nameAr"
                            value={laundryDetails?laundryDetails.nameAr:null}
                            errorMessage="Please enter name"
                            validate={{ required: { value: true } }}
                          />
                        </Col>
                      </div>

                     

                      <div className="row justify-content-end">
                      <Label
                          htmlFor="horizontal-password-Input"
                          className="col-sm-3 col-form-label"
                        >
                          Active
                        </Label>
                        <Col sm={9}>
                        <div
                            className="form-check form-switch form-switch-md mb-3"

                          >
                            <AvField
                              type="checkbox"
                              className="form-check-input"
                              id="customSwitchsizemd"
                              name="active"
                              checked={laundryDetails.active==true}
                              onChange={() => toggleActive(laundryDetails)}
                            />
                            <label
                              className="form-check-label"
                              htmlFor="customSwitchsizemd"
                            >
                            
                            </label>
                          </div>
                          </Col>
                      </div>

                      <div className="row justify-content-end">
                        <Col sm={9}>
                          

                          <div>
                            <Button
                              type="submit"
                              color="primary"
                              className="w-md"
                            >
                              Update Laundry
                            </Button>

                            <button
                              type="button"
                              onClick={() => {
                                toggleEditLaundryModal();
                              }}
                              className="m-1 btn btn-secondary "
                              data-dismiss="modal"
                            >
                              Close
                            </button>

                          </div>
                        </Col>
                      </div>
                    </AvForm>
                  </CardBody>
                </Card>
                </Col>
              </Row>
            </div>
            <div className="modal-footer">
              
          
            </div>
          </Modal>

          </div>
        </Container>
      </div>
  
    </React.Fragment>
  )
}

Laundries.propTypes = {
  t: PropTypes.any
}

export default withTranslation()(Laundries)
