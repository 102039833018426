import {
  API_SUCCESS,
  API_FAIL,
  UPDATE_CUSTOMERS_BAG,
  UPDATE_CUSTOMERS_BAG_SUCCESS,
  REFUND_FAIL,
  REFUND_SUCCESS,
  GET_REASON_SUCCESS,
  GET_REASON_FAIL,
  GET_ONGOING_ORDERS,
  GET_HISTORY_ORDERS,
  GET_ALL_ORDERS,
  GET_ALL_ORDERS_SEARCH,
  UPDATE_ORDER_LIST,
  SET_ACTIVE_TAB,
  REFUND_AMOUNT,
  ONGOING_ORDERS_LOADED, 
  SEARCH_ORDERS,
  GET_ORDER_DETAILS,
  GET_REFUND_ORDERS,
  GET_ALL_ORDERS_PAGINATED,
  UPDATE_ORDER_PAY_WITH_CASH,
  GET_FILTER_BYSEARCH,
  GET_CANCELLED_ORDERS,
  GET_COMPLETED_ORDERS,
  GET_RETURNED_ORDERS,
  GET_WHATSAPP_ORDERS
} from "./actionTypes";

const INIT_STATE = {
  orders: [],
  Allorders: [],
  pickup_waiting_orders: 0,
  delivery_waiting_orders: 0,
  laundry_delayed_orders: 0,
  delivery_inprogress_orders: 0,
  orderHistory: [],
  reason: [],
  refund: "",
  storeActiveTab: 'all-order',
  all_count: 0,
  customerUpdate: "",
  isLoaded: false,
  filteredOrders : [],
  refundOrders: [],
  AllOrdersPaginated: {
    record: [],
    total_orders: 0
  },
  searchedOrders : [],
  cancelledOrders : [],
  CompletedOrders: [],
  ReturnedOrders:[],
  WhatsappOrders: []
};

const Orders = (state = INIT_STATE, action) => {
  switch (action.type) {
    case REFUND_AMOUNT:
      return {
        ...state,
        refund: ""
      };
    case UPDATE_CUSTOMERS_BAG:
      return {
        ...state,
        customerUpdate: ""
      };
    case ONGOING_ORDERS_LOADED:
      console.log("Action::", action);
      return {
        ...state,
        isLoaded: action.payload
      };
    case API_SUCCESS:
      switch (action.payload.actionType) {
        case UPDATE_CUSTOMERS_BAG_SUCCESS:
          return {
            ...state,
            customerUpdate: "Success"
          };
        case GET_ONGOING_ORDERS:
          return {
            ...state,
            orders: action.payload.data
          };

        case GET_REFUND_ORDERS:
          return {
            ...state,
            refundOrders: action.payload.data
          };
          
        case REFUND_SUCCESS:
          return {
            ...state,
            // Allorders: state.Allorders.splice(state.Allorders.findIndex((item) => item._id == action.payload.data._id), 1),
            refund: "success"
          };
        case GET_REASON_SUCCESS:
          return {
            ...state,
            reason: action.payload.data
          };
        
        case GET_ORDER_DETAILS:
          
          return {
            ...state,
            orderDetails: action.payload.data && action.payload.data[0]
          };

        case GET_HISTORY_ORDERS:
          return {
            ...state,
            orderHistory: action.payload.data
          };
        case SEARCH_ORDERS:
            return {
                ...state,
                filteredOrders: action.payload.data
            };
        case GET_ALL_ORDERS_SEARCH:
          return {
            ...state,
            Allorders: action.payload.data.record,
            pickup_waiting_orders: action.payload.data.pickup_waiting,
            delivery_waiting_orders: action.payload.data.delivery_waiting,
            laundry_delayed_orders: action.payload.data.laundry_delayed,
            delivery_inprogress_orders: action.payload.data.delivery_inprogress,
            all_count: action.payload.data.all_count
          };

        case GET_FILTER_BYSEARCH:
          return {
            ...state,
            searchedOrders : action.payload.data
          }
        
        case GET_ALL_ORDERS_PAGINATED:
          return {
            ...state, 
            AllOrdersPaginated : action.payload.data
          };

        case GET_COMPLETED_ORDERS:
          return {
            ...state, 
            CompletedOrders : action.payload.data
          };
        
        case GET_RETURNED_ORDERS:
          return {
            ...state, 
            ReturnedOrders : action.payload.data
          };

        case GET_WHATSAPP_ORDERS:
          return {
            ...state, 
            WhatsappOrders : action.payload.data?.record
          };
          

          
        
        case GET_CANCELLED_ORDERS:
          return {
            ...state, 
            cancelledOrders: action.payload.data
          };

        case SET_ACTIVE_TAB:
          return {
            ...state,
            storeActiveTab: action.payload.data,
          };


        case UPDATE_ORDER_LIST:
          let newOrderList = state.Allorders.map(function (e) { return e; });
          const newOrder = action.payload.data.newOrder;
          const tab = state.storeActiveTab;
          const orderData = action.payload.data.data;
          let pickup_waiting_orders = state.pickup_waiting_orders;
          let delivery_waiting_orders = state.delivery_waiting_orders;
          let laundry_delayed_orders = state.laundry_delayed_orders;
          let delivery_inprogress_orders = state.delivery_inprogress_orders;
          let all_count = state.all_count;


          if (newOrder == true) {
            if (tab == 'all-order' || tab == 'waiting-pickup') {
              newOrderList.push(orderData);
            }
            all_count++;
            pickup_waiting_orders++;
          }
          else {
            const orderIndex = newOrderList.map(object => object._id).indexOf(orderData._id);

            console.log("orderIndex::", orderIndex);
            console.log("orderData::", orderData);

            if (orderIndex >= 0) {
              const oldData = newOrderList[orderIndex];
              console.log("oldData::", oldData);

              if (oldData.status == "created") {
                pickup_waiting_orders--;
              }

              if (oldData.status == "ready" && oldData.paid == true) {
                delivery_waiting_orders--;
              }

              if (oldData.status == "out_laundry") {
                delivery_inprogress_orders--;
              }

              console.log("orderData::", orderData);


              if (orderData.status == "cancelled" || orderData.status == "delivered" || orderData.status == "accepted") {
                newOrderList.splice(orderIndex, 1)
                all_count--;
              }
              else {


                if (orderData.status == "created") {
                  pickup_waiting_orders++;
                }

                if (orderData.status == "ready" && orderData.paid == true) {
                  delivery_waiting_orders++;
                }

                if (orderData.status == "out_laundry") {
                  delivery_inprogress_orders++;
                }
                console.log("Tab::", tab);
                console.log("oldData::", oldData);
                console.log("orderData::", orderData);

                if (tab == 'all-order') {
                  newOrderList[orderIndex] = orderData;
                }
                else if (oldData.status != orderData.status) {
                  if (
                    (oldData.status == 'created' && tab == 'waiting-pickup') ||
                    (oldData.status == 'ready' && oldData.paid == true && tab == 'waiting-delivery') ||
                    (oldData.status == 'out_laundry' && tab == 'delivery-in-progress')
                  ) {
                    newOrderList.splice(orderIndex, 1);
                  }
                }

              }
            }



          }

          console.log("delivery_inprogress_orders::", delivery_inprogress_orders);

          return {
            ...state,
            Allorders: newOrderList,
            pickup_waiting_orders: pickup_waiting_orders,
            delivery_waiting_orders: delivery_waiting_orders,
            laundry_delayed_orders: laundry_delayed_orders,
            delivery_inprogress_orders: delivery_inprogress_orders,
            all_count: all_count
          };

        case UPDATE_ORDER_PAY_WITH_CASH:
          return {
            ...state,
            cashOnDeliveryOrder: action.payload.data,
          }
        default:
          return state;
      }
    case API_FAIL:
      switch (action.payload.actionType) {
        case GET_ONGOING_ORDERS:
        case GET_HISTORY_ORDERS:
        case GET_ALL_ORDERS:
        case GET_ALL_ORDERS_SEARCH:
          return {
            ...state,
            orderError: action.payload.error
          };
        case REFUND_FAIL:
          return {
            ...state,
            refund: "fail"
          };
        case UPDATE_CUSTOMERS_BAG:
          return {
            ...state,
            customerUpdate: "Fail"
          };
        default:
          return state;
      }
    default:
      return state;
  }
}


export default Orders;