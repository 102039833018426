import {
  API_SUCCESS,
  API_FAIL,
  GET_USER_LIST,
  GET_USER_DETAILS,
  TOGGLE_USER_ACTIVE,
  TOGGLE_USER_PERM,
  SEARCH_USERS,
  FILTER_USERS
} from "./actionTypes";
import { nullOrEmpty } from "../../helpers/common_helper";


const INIT_STATE = {
  userList: [],
  userResponse: {},
  userDetails: {},
  users: [],
  userSearch: [],
  filteredUsers: []
};

const Users = (state = INIT_STATE, action) => {
  switch (action.type) {
    case TOGGLE_USER_ACTIVE:
      let _userDetails = action.payload;
      _userDetails.active = !_userDetails.active;
      return {
        ...state,
        userDetails: _userDetails
      };

    case TOGGLE_USER_PERM:
      let _userDetailsPerm = action.payload.user;
      let _perm = action.payload.perm;
      let _action = action.payload.action;

      if (nullOrEmpty(_userDetailsPerm.permissions)) {
        _userDetailsPerm.permissions = {};
      }

      if (nullOrEmpty(_userDetailsPerm.permissions[_perm])) {
        _userDetailsPerm.permissions[_perm] = {
          create: false,
          search: false,
          update: false
        };
      }
      if (nullOrEmpty(_userDetailsPerm.permissions[_perm][_action])) {
        _userDetailsPerm.permissions[_perm][_action] = true;
      }
      else {
        _userDetailsPerm.permissions[_perm][_action] = !_userDetailsPerm.permissions[_perm][_action];
      }
      console.log("_userDetailsPerm", _userDetailsPerm);
      return {
        ...state,
        userDetails: _userDetailsPerm
      };
    case API_SUCCESS:

      switch (action.payload.actionType) {
        case SEARCH_USERS:
          let _users = action.payload.data;
          //for single user result set permission object
          if (_users && _users.length && _users.length == 1) {
            let _permissions = {};
            let userData = _users[0];
            let allPermissions = userData?.profile?.role?.allPermissions;
            let perms = userData?.profile?.role?.permissions;
            if (allPermissions == true) {
              _permissions.allPermissions = true;
            }
            for (const p of perms) {
              if (p.permission?.name) {
                _permissions[p.permission.name] = p;
              }
            };

            userData.permissions = _permissions;
            _users[0] = userData;
            //setPermissions
          }
          // console.log("Users::", _users);
          return {
            ...state,
            users: _users
          };
       
        case GET_USER_LIST:
          return {
            ...state,
            userList: action.payload.data
          };

        case GET_USER_DETAILS:
          return {
            ...state,
            userDetails: action.payload.data
          };

        case FILTER_USERS:
          return {
            ...state,
            filteredUsers: action.payload.data
          };


        default:
          return state;
      }
    case API_FAIL:
      switch (action.payload.actionType) {
        case GET_USER_LIST:
          return {
            ...state,
            chartsDataError: action.payload.error
          };

        case GET_USER_DETAILS:
          return {
            ...state,
            userResponse: action.payload.error,
          };

        default:
          return state;
      }
    default:
      return state;
  }
}


export default Users;