import axios from "axios"
import { del, get, post, put } from "./api_helper"
import * as url from "./url_helper"


const fetchClothesList = (data) => post(`${url.GET_CLOTHES}`, data)
const createCloth = (data) => post(`${url.CREATE_CLOTH}`, data)
const updateCloth = (data) => post(`${url.UPDATE_CLOTH}`, data)

export {
    fetchClothesList,
    createCloth,
    updateCloth  
}