import { call, put, takeEvery } from "redux-saga/effects"

// Crypto Redux States
import { LIST_SETTINGS, CREATE_SETTING, UPDATE_SETTING } from "./actionTypes"
import { apiSuccess, apiFail } from "./actions"

//Include Both Helper File with needed methods
import {
  _getSettings,
  _createSetting,
  _updateSetting,
} from "helpers/settingsHelper"
import toastr from "toastr"

function* listSettings({ payload: data }) {
  try {
    const response = yield call(_getSettings, data)
    yield put(apiSuccess(LIST_SETTINGS, response))
  } catch (error) {
    yield put(apiFail(LIST_SETTINGS, error))
  }
}

function* createSetting({ payload: data }) {
  try {
    const response = yield call(_createSetting, data)
    if (response?.statusCode == "200") {
      toastr.success("Setting created successfully")
      yield put(apiSuccess(CREATE_SETTING, response.record))
    }
  } catch (error) {
    yield put(apiFail(CREATE_SETTING, error))
  }
}

function* updateSetting({ payload: data }) {
  try {
    const response = yield call(_updateSetting, data)
    if (response?.statusCode == "200") {
      toastr.success("Setting updated successfully")
      //yield put(apiSuccess(UPDATE_SETTING, response.record))
    }
  } catch (error) {
    console.log("error", error)
    yield put(apiFail(UPDATE_SETTING, error))
    throw error
  }
}

function* settingsSaga() {
  yield takeEvery(LIST_SETTINGS, listSettings)
  yield takeEvery(CREATE_SETTING, createSetting)
  yield takeEvery(UPDATE_SETTING, updateSetting)
}

export default settingsSaga
