import {postWeb} from "./api_helper"
import * as url from "./url_helper"


const _getLoyalityPoints = (data) => postWeb(`${url.GET_LOYALITY_POINTS}`, data)
const _updateLoyalityPoints = (data) => postWeb(`${url.UPDATE_LOYALITY_POINTS}`, data)

export {
  _getLoyalityPoints,
  _updateLoyalityPoints
}
