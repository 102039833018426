import React, { useEffect, useState } from "react"
import MetaTags from "react-meta-tags"
import { withRouter } from "react-router-dom"
import DataTable from "react-data-table-component"
import DatePicker from "react-datepicker"
import {
  Card,
  CardBody,
  Col,
  Container,
  FormGroup,
  Input,
  Label,
  Row,
  Modal,
  ModalBody,
  ModalHeader
} from "reactstrap"
import {
  getCarwashOrders,
  getTimeSlots,
  searchUsers,
  updateCarwashOrders,
} from "../../store/actions"

import { useSelector, useDispatch } from "react-redux"

import Breadcrumbs from "components/Common/Breadcrumb"

import Pages404 from "pages/Utility/pages-404"
import Select from "react-select"
import toastr from "toastr"
import moment from "moment"
import MapsGoogle from "pages/Maps/MapsGoogle";
import FileSaver from "file-saver"



const CarWashOrders = props => {
  const dispatch = useDispatch()

  const [userData, setUserData] = useState({})
  const [selectedOrder, setSelectedOrder] = useState({})
  const [modal, setModal] = useState(false)
  const [mapModal, setMapModal] = useState(false)

  const { users, carwashOrders, timeslots } = useSelector(state => ({
    users: state.Users.users,
    carwashOrders: state.CarwashOrders.carwashOrders,
    timeslots: state.Timeslots.timeslots,
  }))

  const toggle = () => {
    setModal(!modal);
  }
  
  const toggleMapModal = () => {
    setMapModal(!mapModal);
  }

  useEffect(() => {
    if(!users) dispatch(searchUsers({ webPermissions: true }))
    dispatch(getTimeSlots({
      category: "carwash",
      status : true
    }))
    dispatch(getCarwashOrders())
  }, [])

  useEffect(() => {
    if (users.length > 0) {
      setUserData(users[0])
    }
  }, [users])

  const carWashColumns = [
    {
      name: "ID",
      // width: "7rem",
      compact: true,
      selector: row => row.carwashOrderID,
    },
    {
      name: "Customer ID",
      // width: "7rem",
      compact: true,
      selector: row => row.customer?.customerID,
    },
    {
      name: "Name",
      compact: true,
      selector: row => row.customer?.name,
    },
    {
      name: "Mobile",
      compact: true,
      selector: row => {
        if (row.customer?.mobile.startsWith("966")) {
          return row.customer?.mobile.slice(3)
        } else if (row.customer?.mobile.startsWith("91")) {
          return row.customer?.mobile.slice(2)
        }
        
      },
    },
    {
      id: "carWashOrdersDateTimeTableCell",
      name: "Scheduled Date",
      compact: true,
      width: "12rem",
      allowOverflow: true,
      selector: row => {
        const defaultScheduledDate = row.scheduledDate
          ? new Date(row.scheduledDate)
          : new Date()
        const [isEditing, setIsEditing] = React.useState(false)
        const [scheduledDate, setScheduledDate] = useState()

        React.useEffect(() => {
          if (row.scheduledDate) {
            setScheduledDate(new Date(row.scheduledDate))
          }
        }, [row.scheduledDate])

        return ((userData?.permissions?.allPermissions == true ||
        userData?.permissions?.carwashOrders?.update == true) && row?.status != "completed" && row?.status != "accepted") ? (
          <>
            {!isEditing ? (
              <a
                onClick={() => setIsEditing(true)}
                className="editable-click"
                style={scheduledDate ? {} : { color: "#F46A6A" }}
              >
                {scheduledDate
                  ? scheduledDate.toLocaleString(undefined, {
                      dateStyle: "long"
                    })
                  : "Add date"}
              </a>
            ) : (
              <div className="my-2">
                <div style={{ width: "10rem" }}>
                  <DatePicker
                    selected={scheduledDate || new Date()}
                    onChange={date => setScheduledDate(date)}
                    timeInputLabel="Time:"
                    dateFormat="MM/dd/yyyy"
                    filterDate={(date) => {
                      return date > Date.now()
                    }}
                  />
                </div>
                <div className="">
                  <button
                    type="submit"
                    onClick={() => {
                      dispatch(
                        updateCarwashOrders({
                          _id: row._id,
                          scheduledDate: moment(scheduledDate).format("YYYY-MM-DD"),
                        })
                      )
                      setIsEditing(false)
                    }}
                    className="btn btn-success editable-submit btn-sm waves-effect waves-light"
                  >
                    <i className="mdi mdi-check"></i>
                  </button>
                  <button
                    type="button"
                    onClick={() => setIsEditing(false)}
                    className="btn btn-danger editable-cancel btn-sm waves-effect waves-light"
                  >
                    <i className="mdi mdi-close"></i>
                  </button>
                </div>
              </div>
            )}
          </>
        ): row?.scheduledDate? new Date(row.scheduledDate).toLocaleString(undefined, {
          dateStyle: "short",
          timeStyle: "short",
          hourCycle: "h12",
        }): null
      },
    },
    
    {
      id: "carWashOrdersTimeSlot",
      name: "Timeslot",
      compact: true,
      width: "12rem",
      allowOverflow: true,
      selector: row => {
        const defaultTimeslot = row.timeslot 
          ? row.timeslot
          : {}
        const [isEditing, setIsEditing] = React.useState(false)
        const [timeslot, setTimeslot] = useState(defaultTimeslot)
        const [filteredTimeslots, setFilteredTimeslots] = useState([])
        
        React.useEffect(() => {
          if (row.timeslot) {
            setTimeslot(row.timeslot)
          }
        }, [row.timeslot])

        useEffect(() => {
          const _timeslots = timeslots.filter(t => t?.area == row.customer?.location?.area || t?.enableAll == true)
          console.log("Timeslots", _timeslots, row.customer?.location?.area);
        }, [timeslots])

        const _setTimeslot = _timeslot => {
          const found = timeslots.filter(t => t._id.toString() == _timeslot);
          if(found && found.length > 0){
            setTimeslot(found[0]);
          }
        }

        return ((userData?.permissions?.allPermissions == true ||
        userData?.permissions?.carwashOrders?.update == true) && row?.status != "completed" && row?.status != "accepted") ? (
          <>
            {!isEditing ? (
              <a
                onClick={() => setIsEditing(true)}
                className="editable-click"
                style={row.timeslot ? {} : { color: "#F46A6A" }}
              >
                
                {timeslot
                  ? `${timeslot?.start} - ${timeslot?.end}`
                  : "Select Timeslot"}
              </a>
            ) : (
              <div className="my-2">
                <div style={{ width: "10rem" }}>
                  <select
                   className="form-control"
                   onChange={e => _setTimeslot(e.target.value)}
                  >
                    <option value="">Select Timeslot</option>
                    {timeslots.map( t => (
                      <option key={t._id} value={t._id}>{t.start} - {t.end}</option>
                    ))}
                  </select>
                </div>
                <div className="">
                  <button
                    type="submit"
                    onClick={() => {
                      dispatch(
                        updateCarwashOrders({
                          _id: row._id,
                          timeslot: timeslot?._id
                        })
                      )
                      setIsEditing(false)
                    }}
                    className="btn btn-success editable-submit btn-sm waves-effect waves-light"
                  >
                    <i className="mdi mdi-check"></i>
                  </button>
                  <button
                    type="button"
                    onClick={() => setIsEditing(false)}
                    className="btn btn-danger editable-cancel btn-sm waves-effect waves-light"
                  >
                    <i className="mdi mdi-close"></i>
                  </button>
                </div>
              </div>
            )}
          </>
        ): row?.scheduledDateTime? new Date(row.scheduledDateTime).toLocaleString(undefined, {
          dateStyle: "short",
          timeStyle: "short",
          hourCycle: "h12",
        }): null
      },
    },
    {
      id:"area", 
      name : "Area",
      selector : row => row?.area?.name,
      allowOverflow: true,
    },
    {
      id:"images", 
      name : "Images",
      selector : row => {
        return (
          <button className="btn btn-link"
           onClick={() => {
              setSelectedOrder(row);
              setModal(true);
           }}
           >Images</button>
        )
      }
    },
    {
      id:"carModel", 
      name : "Car Model",
      selector : row => row?.carModel,
      allowOverflow: true,
    },
    {
      id:"location", 
      name : "Location",
      selector : row => {
        return (
          <a 
            className="btn btn-link"
            target="_blank"
            rel="noreferrer"
            href={`https://maps.google.com/?q=${row.lat||""},${row.lng||""}`}
           >Location</a>
        )
      }
    },
    {
      name: "Note",
      // width: "7rem",
      selector: row => {
        const [isEditing, setIsEditing] = React.useState(false)
        const [note, setNote] = React.useState(row.note || "")
        return userData?.permissions?.allPermissions == true ||
        userData?.permissions?.carwashOrders?.update == true ?(
          <>
            {!isEditing ? (
              <a
                onClick={() => setIsEditing(true)}
                className="editable-click"
                style={row.note ? {} : { color: "#F46A6A" }}
              >
                {note || "Add note"}
              </a>
            ) : (
              <div className="my-2">
                <Input
                  type="textarea"
                  value={note}
                  onChange={e => setNote(e.target.value)}
                />
                <div className="">
                  <button
                    type="submit"
                    onClick={() => {
                      dispatch(
                        updateCarwashOrders({ _id: row._id, note: note })
                      )
                      setIsEditing(false)
                    }}
                    className="btn btn-success editable-submit btn-sm waves-effect waves-light"
                  >
                    <i className="mdi mdi-check"></i>
                  </button>
                  <button
                    type="button"
                    onClick={() => setIsEditing(false)}
                    className="btn btn-danger editable-cancel btn-sm waves-effect waves-light"
                  >
                    <i className="mdi mdi-close"></i>
                  </button>
                </div>
              </div>
            )}
          </>
        ): row.note
      },
    },
    {
      name: "Status",
      selector: row => {
        return userData?.permissions?.allPermissions == true ||
          userData?.permissions?.carwashOrders?.update == true ? (
          <FormGroup
            style={{
              width: "7rem",
            }}
          >
            {row.status== "accepted"?
            <span>Accepted</span>:
            <Input
              type="select"
              value={row.status || "new"}
              onChange={e => {
                e.preventDefault()
                const isOtherStatus =
                  e.target.value == "completed" || e.target.value == "scheduled"
                const isValidDateTime = row.scheduledDate
                if (isOtherStatus && !isValidDateTime) {
                  toastr.error("Datetime is required to update the status")
                  return
                }
                dispatch(
                  updateCarwashOrders({ _id: row._id, status: e.target.value })
                )
              }}
            >
              <option value={"new"}>New</option>
              <option value={"scheduled"}>Scheduled</option>
              <option value={"completed"}>Completed</option>
              <option value={"cancelled"}>Cancelled</option>
             
            </Input>}
          </FormGroup>
        ) : (
          row.status || "new"
        )
      },
    },
  ]

  React.useEffect(() => {
    
  }, [carwashOrders])

  const [selectedStatus, setSelectedStatus] = useState()
  const [searchValue, setSearchValue] = useState("")

  const carwashOrdersFilter = carwashOrders => {
    let isStatusMatched = true
    let isSearchMatched = true
    if (selectedStatus) {
      console.log("carwashOrders.status::", selectedStatus, carwashOrders.status)
      if (!carwashOrders.status) {
        isStatusMatched = selectedStatus.value === "new"
      } else {
        isStatusMatched = carwashOrders.status === selectedStatus.value
      }
    }
    if (searchValue) {
      const isNameMatched = carwashOrders.customer?.name
        ?.toLowerCase()
        .includes(searchValue.toLowerCase())
      const isMobileMatched =
      carwashOrders.customer?.mobile?.includes(searchValue)
      const isCustomerIDMatched = carwashOrders.customer?.customerID
        ?.toString()
        .includes(searchValue)
      const isNoteMatched = carwashOrders.note
        ?.toLowerCase()
        .includes(searchValue.toLowerCase())
      isSearchMatched =
        isNameMatched || isMobileMatched || isCustomerIDMatched || isNoteMatched
    }
    return isStatusMatched && isSearchMatched
  }

  if (
    userData?.permissions?.allPermissions == true ||
    userData?.permissions?.carwashOrders?.read == true
  ) {
    return (
      <React.Fragment>
        <div className="page-content">
          <MetaTags>
            <title>Car Wash Orders</title>
          </MetaTags>
          <Container fluid>
            <Breadcrumbs title="Home" breadcrumbItem="Car Wash Orders (Subscription)" />
            <Card>
              <CardBody>
                <Row className="mb-2">
                  <Col sm="6">
                    <div className="me-2 mb-2 d-inline-block">
                      <div className="position-relative d-flex">
                        <input
                          id="search-bar-10"
                          type="text"
                          aria-labelledby="search-bar-10-label"
                          className="form-control "
                          placeholder="Search"
                          onChange={e => setSearchValue(e.target.value)}
                        />
                        <div className="ms-2" style={{ width: "20rem" }}>
                          <Select
                            placeholder="All Status"
                            onChange={val => {
                              setSelectedStatus(val)
                            }}
                            isClearable={true}
                            options={[
                              { value: "new", label: "New" },
                              { value: "scheduled", label: "Scheduled" },
                              { value: "completed", label: "Completed" },
                              { value: "cancelled", label: "Cancelled" },
                              { value: "accepted", label: "Accpted By Customer" },
                            ]}
                          />
                        </div>
                      </div>
                    </div>
                  </Col>
                </Row>
                <Row style={{minHeight:"110vh"}}>
                  <DataTable
                    columns={carWashColumns}
                    data={carwashOrders.filter(carwashOrdersFilter)}
                    pagination
                    
                    
                  />
                </Row>
              </CardBody>
            </Card>
          </Container>
          <Modal isOpen={modal} toggle={toggle} size="lg">
              <ModalHeader toggle={toggle} tag="h4">
                Carwashorder #{selectedOrder.carwashOrderID} Images 
              </ModalHeader>
              <ModalBody>
                 <div style={{height:"90vh", overflow:"auto"}}>
                  <div className="row">
                    {selectedOrder?.images?.map((image, i) => (
                      <div key={i} className="col-md-4">
                        <div style={{height:300}} className="imageBox flex-centre">
                          <img src={image} width="auto" height="200"  style={{maxHeight:"300px"}} />
                        </div>
                       
                        <button
                          className="btn btn-outline-success mt-2 mb-2"
                          onClick={() => {
                            FileSaver(image, "Car-image.jpg");
                          }}
                        >Download</button>
                      </div>
                    ))}
                  </div>
                 </div>
              </ModalBody>
            </Modal>

            <Modal
                size="lg"
                isOpen={mapModal}
                toggle={() => {
                  toggleMapModal();
                }}
              >
                <div className="modal-header">
                  <h5 className="modal-title mt-0" id="myModalLabel">
                    Car Location
                  </h5>
                  <button
                    type="button"
                    onClick={() => {
                      setMapModal(false);
                    }}
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div className="modal-body">
                  <Row>
                    <Col lg="12">
                      <Card className="mb-0">
                        <CardBody>
                          <div className="carwash-map">
                            <MapsGoogle lat={selectedOrder?.lat} lng={selectedOrder?.lng} draggable={false}  />
                          </div>
                          <div className="d-flex justify-content-end">
                            <div>
                              <div>
                                <button
                                  type="button"
                                  onClick={() => {
                                    toggleMapModal();
                                  }}
                                  className="m-1 btn btn-secondary "
                                  data-dismiss="modal"
                                >
                                  Close
                                </button>
                              </div>
                            </div>
                          </div>
                        </CardBody>
                      </Card>
                    </Col>
                  </Row>
                </div>
                <div className="modal-footer">


                </div>
              </Modal>

        </div>
      </React.Fragment>
    )
  } else {
    return <Pages404 />
  }
}

export default withRouter(CarWashOrders)
