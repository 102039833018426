import PropTypes from "prop-types"
import React, { useEffect, useState, useRef } from "react"
import MetaTags from "react-meta-tags";
import { Formik, Field } from 'formik';
import { AvForm, AvField, AvGroup, AvInput } from "availity-reactstrap-validation"
import { Link } from "react-router-dom"
import MapVectorLayers from "pages/Maps/LeafletMap/MapVectorLayers";
import {
  Container,
  Row,
  Col,
  Modal,
  Button,
  Card,
  CardBody,
  CardTitle,
  Label,
  Input,
  Form
} from "reactstrap"
const AllAreaPolygon = () => {
  return (
    <div>   <div className="modal-body">
    <Row>
      <Col lg="12">
        <Card className="mb-0">
          <CardBody>
            <AvForm
              onValidSubmit={(e, v) => {
                handleValidSubmit(e, v)
              }}
            >
              <div className="map-space">
                <MapVectorLayers  />
                {/* <GoogleMapComponent lat={lat} lng={lng} SetLat={SetLat} SetLng={SetLng} /> */}
                
              </div>
              <div className="row justify-content-end">
                <Col sm={12} className="col-sm-12 text-end">
                  <div>
                    {/* <Button
                      type="submit"
                      color="primary"
                      className="w-md"
                    // onClick={ADD_NEW_LAUNDRY}
                    >
                      Add Laundry
                    </Button> */}

                    {/* <button
                      type="button"
                     
                      className="m-1 btn btn-secondary "
                      data-dismiss="modal"
                    >
                      Close
                    </button> */}

                  </div>
                </Col>
              </div>
            </AvForm>
          </CardBody>
        </Card>
      </Col>
    </Row>
  </div></div>
  )
}

export default AllAreaPolygon