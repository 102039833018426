import {
  API_SUCCESS,
  API_FAIL,
  GET_REGISTRATION_SUMMARY,
  GET_BAG_CUSTOMERS,
  GET_REGISTRATION_GROWTH,
  GET_ORDER_COUNT,
  GET_UNIQUE_CUSTOMERS
} from "./actionTypes";

export const apiSuccess = (actionType, data) => ({
  type: API_SUCCESS,
  payload: { actionType, data },
});

export const apiFail = (actionType, error) => ({
  type: API_FAIL,
  payload: { actionType, error },
});

export const getRegistrationSummary = (data) => ({
  type: GET_REGISTRATION_SUMMARY,
  payload:  data,
});

export const getBagCustomers = (data) => ({
  type: GET_BAG_CUSTOMERS,
  payload:  data,
});

export const getRegGrowth = (data) => ({
  type: GET_REGISTRATION_GROWTH,
  payload:  data,
});

export const getOrderCount = (data) => ({
  type: GET_ORDER_COUNT,
  payload:  data,
});

export const getUniqueCustomers = (data) => ({
  type: GET_UNIQUE_CUSTOMERS,
  payload:  data,
});


