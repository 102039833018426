import {
  CREATE_ROLE, SEARCH_PERMISSION, API_SUCCESS, 
  API_FAIL, ALL_MODULES, UPDATE_PERMISSION, 
  CREATE_MODULE, UPDATE_MODULE, CLEAR_ERROR_MSG,
  GET_ALL_PERMISSIONS
} from "./actionTypes"

export const createRole = (data) => ({
  type: CREATE_ROLE,
  payload: data
});

export const apiSuccess = (actionType, data) => ({
  type: API_SUCCESS,
  payload: { actionType, data }
});

export const apiFail = (actionType, error) => ({
  type: API_FAIL,
  payload: { actionType, error }
});

export const getPermission = (data) => ({
  type: SEARCH_PERMISSION,
  payload: data
})

export const getAllPermissions = (data) => ({
  type: GET_ALL_PERMISSIONS,
  payload: data
})

export const getAllModules = (data) => ({
  type: ALL_MODULES,
  payload: data
})

export const permissionUpdate = (data) => ({
  type: UPDATE_PERMISSION,
  payload: data
})

export const createModules = (data) => ({
  type:CREATE_MODULE,
  payload:data
})

export const updateModules = (data) => ({
  type:UPDATE_MODULE,
  payload:data
})

export const clearErrorMsg = () => ({
  type:CLEAR_ERROR_MSG,
  payload:{}
})

