import { call, put, takeEvery, all, fork } from "redux-saga/effects";
// Crypto Redux States
import { GET_USER_DETAILS, GET_USER_LIST, 
  TOGGLE_USER_ACTIVE, SEARCH_USERS, FILTER_USERS} from "./actionTypes";
import { apiSuccess, apiFail } from "./actions";
//Include Both Helper File with needed methods
import {fetchUserList, getUser, fetchUsers, userSearch} from "../../helpers/userHelper";

function* getUsers({ }) {
  try {
    const response = yield call(fetchUserList);
    yield put(apiSuccess(GET_USER_LIST, response.record));
  } catch (error) {
    yield put(apiFail(GET_USER_LIST, error));
  }
}

function* searchUsers({payload: data}) {
  try {
      const response = yield call(fetchUsers, data);
      yield put(apiSuccess(SEARCH_USERS, response.record));
  } catch (error) {
      yield put(apiFail(SEARCH_USERS, error));
  }
}

function* filterUsers({payload: data}) {
  try {
      const response = yield call(fetchUsers, data);
      yield put(apiSuccess(FILTER_USERS, response.record));
  } catch (error) {
      yield put(apiFail(FILTER_USERS, error));
  }
}

function* getUserdetails({ payload: data }) {
  //let _data = {...data, active:true};
  try {
    const response = yield call(getUser, data);
    console.log("Add User reponse", response);

    yield put(apiSuccess(GET_USER_DETAILS, response.record));
  } catch (error) {
    yield put(apiFail(GET_USER_DETAILS, error));
  }
}

function* toggleUserActive({ payload: data }) {
  try {
    yield put(TOGGLE_USER_ACTIVE, data);
  } catch (error) {
    yield put(TOGGLE_USER_ACTIVE, error);
  }
}

function* usersSaga() {
    yield takeEvery(GET_USER_LIST, getUsers)
    yield takeEvery(GET_USER_DETAILS, getUserdetails)
    yield takeEvery(SEARCH_USERS, searchUsers)
    yield takeEvery(FILTER_USERS, filterUsers)    
    yield takeEvery(GET_USER_DETAILS, getUserdetails)
}

export default usersSaga;
