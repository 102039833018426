import {postWeb} from "./api_helper"
import * as url from "./url_helper"


const _getCarwashOrders = (data) => postWeb(`${url.GET_CARWASH_ORDERS}`, data)
const _updateCarwashOrders = (data) => postWeb(`${url.UPDATE_CARWASH_ORDERS}`, data)

export {
  _getCarwashOrders,
  _updateCarwashOrders
}
