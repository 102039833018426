import React, { useEffect, useState } from "react"
import MetaTags from "react-meta-tags"
import { withRouter } from "react-router-dom"
import DataTable from "react-data-table-component"
import DatePicker from "react-datepicker"
import {
  Card,
  CardBody,
  Col,
  Container,
  FormGroup,
  Input,
  Label,
  Row,
} from "reactstrap"
import {
  getLoyalityPoints,
  searchUsers,
  updateLoyalityPoints,
} from "../../store/actions"

import { useSelector, useDispatch } from "react-redux"

import Breadcrumbs from "components/Common/Breadcrumb"

import Pages404 from "pages/Utility/pages-404"
import Select from "react-select"
import toastr from "toastr"

const CarWashRedeem = props => {
  const dispatch = useDispatch()

  const [userData, setUserData] = useState({})

  const { users, loyalityPoints } = useSelector(state => ({
    users: state.Users.users,
    loyalityPoints: state.LoyalityPoints.loyalityPoints,
  }))

  useEffect(() => {
    if (!users) dispatch(searchUsers({ webPermissions: true }))
    dispatch(getLoyalityPoints())
  }, [])

  useEffect(() => {
    if (users.length > 0) {
      setUserData(users[0])
    }
  }, [users])

  const carWashColumns = [

    {
      name: "Customer ID",
      // width: "7rem",
      compact: true,
      selector: row => row.customer?.customerID,
    },
    {
      name: "Name",
      compact: true,
      selector: row => row.customer?.name,
    },
    {
      name: "Mobile",
      compact: true,
      selector: row => {
        if (row.customer?.mobile.startsWith("966")) {
          return row.customer?.mobile.slice(3)
        } else if (row.customer?.mobile.startsWith("91")) {
          return row.customer?.mobile.slice(2)
        }
      },
    },
    {
      id: "carWashOrdersDateTimeTableCell",
      name: "Datetime",
      compact: true,
      width: "12rem",
      allowOverflow: true,
      selector: row => {
        const defaultScheduledDateTime = row.scheduledDateTime
          ? new Date(row.scheduledDateTime)
          : new Date()
        const [isEditing, setIsEditing] = React.useState(false)
        const [scheduledDateTime, setScheduledDateTime] = useState(new Date())

        React.useEffect(() => {
          if (row.scheduledDateTime) {
            setScheduledDateTime(new Date(row.scheduledDateTime))
          }
        }, [row.scheduledDateTime])

        return userData?.permissions?.allPermissions == true ||
          userData?.permissions?.loyaltyPoints?.update == true ? (
          <>
            {!isEditing ? (
              <a
                onClick={() => setIsEditing(true)}
                className="editable-click"
                style={row.scheduledDateTime ? {} : { color: "#F46A6A" }}
              >
                {row.scheduledDateTime
                  ? defaultScheduledDateTime.toLocaleString(undefined, {
                    dateStyle: "short",
                    timeStyle: "short",
                    hourCycle: "h12",
                  })
                  : "Add date"}
              </a>
            ) : (
              <div className="my-2">
                <div style={{ width: "10rem" }}>
                  <DatePicker
                    selected={scheduledDateTime}
                    onChange={date => setScheduledDateTime(date)}
                    timeInputLabel="Time:"
                    dateFormat="MM/dd/yyyy h:mm aa"
                    showTimeInput
                    filterDate={(date) => {
                      return date > Date.now()
                    }}
                  />
                </div>
                <div className="">
                  <button
                    type="submit"
                    onClick={() => {
                      dispatch(
                        updateLoyalityPoints({
                          id: row._id,
                          scheduledDateTime,
                        })
                      )
                      setIsEditing(false)
                    }}
                    className="btn btn-success editable-submit btn-sm waves-effect waves-light"
                  >
                    <i className="mdi mdi-check"></i>
                  </button>
                  <button
                    type="button"
                    onClick={() => setIsEditing(false)}
                    className="btn btn-danger editable-cancel btn-sm waves-effect waves-light"
                  >
                    <i className="mdi mdi-close"></i>
                  </button>
                </div>
              </div>
            )}
          </>
        ) : row?.scheduledDateTime ? new Date(row.scheduledDateTime).toLocaleString(undefined, {
          dateStyle: "short",
          timeStyle: "short",
          hourCycle: "h12",
        }) : null
      },
    },
    {
      name: "Note",
      // width: "7rem",
      selector: row => {
        const [isEditing, setIsEditing] = React.useState(false)
        const [note, setNote] = React.useState(row.note || "")
        return userData?.permissions?.allPermissions == true ||
          userData?.permissions?.loyaltyPoints?.update == true ? (
          <>
            {!isEditing ? (
              <a
                onClick={() => setIsEditing(true)}
                className="editable-click"
                style={row.note ? {} : { color: "#F46A6A" }}
              >
                {note || "Add note"}
              </a>
            ) : (
              <div className="my-2">
                <Input
                  type="text"
                  value={note}
                  onChange={e => setNote(e.target.value)}
                />
                <div className="">
                  <button
                    type="submit"
                    onClick={() => {
                      dispatch(
                        updateLoyalityPoints({ id: row._id, note: note })
                      )
                      setIsEditing(false)
                    }}
                    className="btn btn-success editable-submit btn-sm waves-effect waves-light"
                  >
                    <i className="mdi mdi-check"></i>
                  </button>
                  <button
                    type="button"
                    onClick={() => setIsEditing(false)}
                    className="btn btn-danger editable-cancel btn-sm waves-effect waves-light"
                  >
                    <i className="mdi mdi-close"></i>
                  </button>
                </div>
              </div>
            )}
          </>
        ) : row.note
      },
    },
    {
      name: "Status",
      selector: row => {
        return userData?.permissions?.allPermissions == true ||
          userData?.permissions?.loyaltyPoints?.update == true ? (
          <FormGroup
            style={{
              width: "7rem",
            }}
          >
            <Input
              type="select"
              value={row.status || "new"}
              onChange={e => {
                e.preventDefault()
                const isOtherStatus =
                  e.target.value == "completed" || e.target.value == "scheduled"
                const isValidDateTime = row.scheduledDateTime
                if (isOtherStatus && !isValidDateTime) {
                  toastr.error("Datetime is required to update the status")
                  return
                }
                dispatch(
                  updateLoyalityPoints({ id: row._id, status: e.target.value })
                )
              }}
            >
              <option value={"new"}>New</option>
              <option value={"scheduled"}>Scheduled</option>
              <option value={"completed"}>Completed</option>
            </Input>
          </FormGroup>
        ) : (
          row.status || "new"
        )
      },
    },
  ]

  React.useEffect(() => {
    console.log(loyalityPoints)
  }, [loyalityPoints])

  const [selectedStatus, setSelectedStatus] = useState()
  const [searchValue, setSearchValue] = useState("")

  const loyalityPointFilter = loyalityPoint => {
    let isStatusMatched = true
    let isSearchMatched = true
    if (selectedStatus) {
      console.log("loyalityPoint.status::", selectedStatus, loyalityPoint.status)
      if (!loyalityPoint.status) {
        isStatusMatched = selectedStatus.value === "new"
      } else {
        isStatusMatched = loyalityPoint.status === selectedStatus.value
      }
    }
    if (searchValue) {
      const isNameMatched = loyalityPoint.customer?.name
        ?.toLowerCase()
        .includes(searchValue.toLowerCase())
      const isMobileMatched =
        loyalityPoint.customer?.mobile?.includes(searchValue)
      const isCustomerIDMatched = loyalityPoint.customer?.customerID
        ?.toString()
        .includes(searchValue)
      const isNoteMatched = loyalityPoint.note
        ?.toLowerCase()
        .includes(searchValue.toLowerCase())
      isSearchMatched =
        isNameMatched || isMobileMatched || isCustomerIDMatched || isNoteMatched
    }
    return isStatusMatched && isSearchMatched
  }

  if (
    userData?.permissions?.allPermissions == true ||
    userData?.permissions?.loyaltyPoints?.read == true
  ) {
    return (
      <React.Fragment>
        <div className="page-content">
          <MetaTags>
            <title>Car Wash Orders</title>
          </MetaTags>
          <Container fluid>
            <Breadcrumbs title="Home" breadcrumbItem="Car Wash Orders (LoyaltyPoints)" />
            <Card>
              <CardBody>
                <Row className="mb-2">
                  <Col sm="6">
                    <div className="me-2 mb-2 d-inline-block">
                      <div className="position-relative d-flex">
                        <input
                          id="search-bar-10"
                          type="text"
                          aria-labelledby="search-bar-10-label"
                          className="form-control "
                          placeholder="Search"
                          onChange={e => setSearchValue(e.target.value)}
                        />
                        <div className="ms-2" style={{ width: "20rem" }}>
                          <Select
                            placeholder="All Status"
                            onChange={val => {
                              setSelectedStatus(val)
                            }}
                            isClearable={true}
                            options={[
                              { value: "new", label: "New" },
                              { value: "scheduled", label: "Scheduled" },
                              { value: "completed", label: "Completed" },
                            ]}
                          />
                        </div>
                      </div>
                    </div>
                  </Col>
                </Row>
                <Row>
                  <DataTable
                    columns={carWashColumns}
                    data={loyalityPoints.filter(loyalityPointFilter)}
                    pagination
                  />
                </Row>
              </CardBody>
            </Card>
          </Container>
        </div>
      </React.Fragment>
    )
  } else {
    return <Pages404 />
  }
}

export default withRouter(CarWashRedeem)
