import axios from "axios"
import { del, get, postWeb, put } from "./api_helper"
import * as url from "./url_helper"
const filter = JSON.parse(sessionStorage.getItem("filter"));

const fetchb2bList = (data) => postWeb(`${url.GET_B2B_CUSTOMERS}`, data);
const postAddb2bCustomer = (data) => postWeb(`${url.POST_ADD_B2B}`, data)
const postUpdateb2bCustomer = (data) => postWeb(`${url.POST_UPDATE_B2B}`, data)



export {
  fetchb2bList,
  postAddb2bCustomer,
  postUpdateb2bCustomer
}
