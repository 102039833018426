import PropTypes from "prop-types"
import React, { useEffect, useState, useRef, useContext } from "react"
import MetaTags from "react-meta-tags";
import Pages404 from "pages/Utility/pages-404";
import Map from "./LogisticsMap";
import Switch from "react-switch"
import toastr from "toastr";
import "toastr/build/toastr.min.css";


import {
  Container,
  Row,
  Col,
  Button,
  Card,
  CardBody,
  Input,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Media,
  Table,
  CardTitle,
  Badge,
  CardHeader
} from "reactstrap"

//import action
import {
  getAllOrdersSearch
} from "../../store/actions"
import { useDispatch, useSelector } from "react-redux"
import { searchUsers } from "../../store/actions"

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"

//i18n
import { withTranslation } from "react-i18next"

//redux
// import { useSelector, useDispatch } from "react-redux"
import Skeleton from "react-loading-skeleton";
import useScript from 'react-script-hook';
import SocketContext from "common/socket";
import { myParseFloat } from "helpers/validationHelper";
import { GoogleApiWrapper } from "google-maps-react";
import { mapLibraries } from "constants/app";





const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

const LogisticDashboard = props => {

  const [displayMode , setDisplayMode] = useState(false);

  const [month, setMonth] = useState(0);
  const [year, setYear] = useState(0);
  const [filterLoading, setFilterLoading] = useState(false);
  const [mapScale, SetMapScale] = useState(2000);
  const [dateValues, SetDateValues] = useState(null);
  const [userData, setUserData] = useState({});
  const [pageLoading, setPageLoading] = useState(true);
  const [googleLoaded, setGoogleLoaded] = useState(false);



  const mapRef = useRef(null);
  const socket = useContext(SocketContext);
  const myAudio = new Audio(API_BASE_URL + "/sounds/invoice.mp3");




  const { Allorders, users
  } = useSelector(state => ({
    Allorders: state.Orders.Allorders,
    users: state.Users.users
  }))



  var node = useRef();
  const keyField = "id";


  const loaderColor = "#0097CE";
  const dispatch = useDispatch();

  // const [loading, scriptError] = useScript({
  //   src: "https://maps.googleapis.com/maps/api/js?key=AIzaSyAvZvBwMKrllZNCXdikzvCDOzu8rwqv-0c&v=weekly&language=en&region=us&libraries=drawing",
  //   checkForExisting: true,
  //   onload: () => setGoogleLoaded(true),
  // });

  const Offsymbol = () => {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100%",
          fontSize: 12,
          color: "#fff",
          paddingRight: 2,
        }}
      ></div>
    )
  }
  
  const OnSymbol = () => {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100%",
          fontSize: 12,
          color: "#fff",
          paddingRight: 2,
        }}
      ></div>
    )
  }


  useEffect(() => {
    if (users.length > 0) {
      setUserData(users[0]);
      setPageLoading(false);
    }
  }, [users])



  var data = JSON.parse(localStorage.getItem("authUser"));
  const [fill, setFill] = useState({});
  const { permission } = useSelector(state => ({
    permission: state.permission.permissions
  }))

  const loadMap = () => {
  
    dispatch(getAllOrdersSearch({
      status : [
        "created",
        "picked",
        "in_laundry",
        "washing",
        "ready",
        "out_laundry",
      ],
      category : "online"
    }))
  }

  useEffect(() => {
    if (window?.google) {
      setGoogleLoaded(true);
    }

    dispatch(searchUsers({ webPermissions: true }))

    let today = getFormattedDate(new Date());
    SetDateValues({ start: today });

    loadMap();


  }, [])

  useEffect(() => {
    
    setFilterLoading(false);

  }, [Allorders])

  useEffect(() => {
    if(!displayMode){
      loadMap();
    }
  }, [displayMode])

  const onMapLoad = (map) => {
    mapRef.current = map;
  }



  useEffect(() => {
    const filterProduct = permission?.filter((item) => item?.role === data?.role)
    setFill(filterProduct[0])
  }, [permission])



  

  const getFormattedDate = (dt) => {
    const separator = "-";
    let newDate = dt;
    let date = newDate.getDate();
    let month = newDate.getMonth() + 1;
    let year = newDate.getFullYear();

    return `${year}${separator}${month < 10 ? `0${month}` : `${month}`}${separator}${date}`
  };


  const ApplyMonthlyFilter = () => {
    let _month = myParseFloat(month);
    let _year = myParseFloat(year);
    if(_month == 0){
      toastr.error("Please select month", "Incomplete Data")
      return;
    }
    if(_year == 0){
      toastr.error("Please select year", "Incomplete Data")
      return;
    }

    dispatch(getAllOrdersSearch({
      status : [
        "created",
        "picked",
        "in_laundry",
        "washing",
        "ready",
        "out_laundry",
        "delivered", 
        "accepted"
      ],
      category : "online",
      orderMonth : _month,
      orderYear : _year
    }))

    setFilterLoading(true);


  }




  if (userData?.permissions?.allPermissions == true || userData?.permissions?.logisticDashboard?.read == true) {
    return (
      <div className="page-content">
        <MetaTags>
          <title>Analytics | Kleen Admin Panel</title>
        </MetaTags>
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumbs
            title="Home"
            breadcrumbItem="Logistics Dashboard"
          />

          <Row>
            <Col>
              <span className="mx-2 align-items-center d-flex">
                <span className={!displayMode?"mx-2 text-success fw-bold":"mx-2"}>Ongoing Orders</span>
                <Switch
                  uncheckedIcon={<Offsymbol />}
                  checkedIcon={<OnSymbol />}
                  className="me-1 mb-sm-8"
                  onColor="#626ed4"
                  onChange={e => {
                    setDisplayMode(!displayMode)
                  }}
                  checked={displayMode}
                />
                <span className={displayMode?"mx-2 text-success fw-bold":"mx-2"}>Monthly Orders</span>
                {displayMode?<>
                <span className="mx-3">Select Month</span>
                <select 
                  className="form-control" 
                  value={month}
                  onChange={e => setMonth(e.target.value)}
                  style={{maxWidth:130}}>
                  <option>--Select Month--</option>
                  <option value="1">Jan</option>
                  <option value="2">Feb</option>
                  <option value="3">Mar</option>
                  <option value="4">April</option>
                  <option value="5">May</option>
                  <option value="6">Jun</option>
                  <option value="7">Jul</option>
                  <option value="8">Aug</option>
                  <option value="9">Sep</option>
                  <option value="10">Oct</option>
                  <option value="11">Nov</option>
                  <option value="12">Dec</option>
                </select>

                <span className="mx-3">Select Year</span>
                <select 
                  className="form-control" 
                  value={year}
                  onChange={e => setYear(e.target.value)}
                  style={{maxWidth:130}}>
                  <option>--Select Year--</option>
                  <option value="2021">2021</option>
                  <option value="2022">2022</option>
                  <option value="2023">2023</option>
                  <option value="2024">2024</option>
                </select>
                {filterLoading?
                <Button
                  className="mx-3"
                  color="primary"
                  disabled
                >
                <i className="fas fa fa-spinner fa-spin"></i> Processing...
                </Button>
                :
                <Button
                  className="mx-3"
                  color="primary"
                  onClick={ApplyMonthlyFilter}
                >
                  Apply
                </Button>
                }
                </>: null}
              </span>
            </Col>
          </Row>

          <Row>
            <Col lg="12">
              <Card>
                <CardHeader>
                  <h4></h4>
                </CardHeader>
                <CardBody style={{ padding: 0 }}>
                  <Col lg="12">
                    <Row>
                    <Col style={{height:"500px"}}>
                      {
                        Allorders?.length ? (
                          (<Map
                            loadingElement={<div style={{ height: `100%` }} />}
                            containerElement={<div style={{ height: `700px` }} />}
                            mapElement={<div style={{ height: `100%` }} />}
                            center={{ lat: 22.7857718694954, lng: 45.397431725357045 }}
                            zoom={6}
                            places={Allorders}
                          />

                          )) : null
                      }
                      </Col>
                    </Row>
                  </Col>
                </CardBody>
              </Card>
            </Col>
          </Row>








        </Container>
      </div>
    )
  } else {
    if (pageLoading)
      return (
        <div className="page-content">
          <Container fluid>
            {/* Render Breadcrumb */}
            <Breadcrumbs
            />

            <Skeleton />
          </Container>
        </div>
      )
    else
      return <Pages404 />
  }

}

LogisticDashboard.propTypes = {
  
}

export default 
(GoogleApiWrapper({
  apiKey: "AIzaSyAvZvBwMKrllZNCXdikzvCDOzu8rwqv-0c",
  libraries: mapLibraries
})(LogisticDashboard))
