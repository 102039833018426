import React, { useCallback, useMemo, useState, useContext } from "react"
import { Row, Col, Card, CardBody, Input } from "reactstrap"
import MetaTags from "react-meta-tags"
import PropTypes from "prop-types"

// datatable related plugins
import BootstrapTable from "react-bootstrap-table-next"
import paginationFactory, {
  PaginationProvider,
  PaginationListStandalone,
  SizePerPageDropdownStandalone,
} from "react-bootstrap-table2-paginator"

import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit"
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"
import "../../assets/scss/datatables.scss"
import { useEffect } from "react"
import { useSelector, useDispatch } from "react-redux"
import {
  searchUsers,
  getReasons,
  getReturnedOrders,
} from "../../store/actions"
import { postWeb } from "helpers/api_helper"
import classNames from "classnames"
import Skeleton, { SkeletonTheme } from "react-loading-skeleton"
import "react-loading-skeleton/dist/skeleton.css"
import Pages404 from "pages/Utility/pages-404"
import moment from "moment-timezone"
import toastr from "toastr"
import "toastr/build/toastr.min.css"
import Switch from "react-switch"
import SocketContext from "common/socket"
import { useRef } from "react"

const ReturnedOrders = () => {
  const dispatch = useDispatch()
  const { ReturnedOrders, refundReason, users } = useSelector(state => ({
    ReturnedOrders: state.Orders.ReturnedOrders,
    refundReason: state.Orders.reason,
    users: state.Users.users,
  }))

  const reloadList = useRef()

  const [productData, setProductData] = useState([])
  const [page, setPage] = useState(0)
  const [isLoading, setIsLoading] = useState(true)
  const [userData, setUserData] = useState({})
  const [totalRecords, setTotalRecords] = useState(0)

  const handleApply = () => {
    let apiData = {
      returned: { $exists: true},
      page: page,
      category: ["online"],
      sortBy: "cancelledDateTime",
      sortDir: "desc",
    }
    dispatch(getReturnedOrders(apiData))
  }

  React.useEffect(() => {
    dispatch(getReasons({ type: "return_reasons" }))
  }, [])

  const multiLineDate = useCallback(_date => {
    if (!_date) return ""
    const [dt, tm] = _date.split(" ")
    return dt && tm ? (
      <>
        {dt} <br /> {tm}
      </>
    ) : (
      "NA"
    )
  }, [])

  useEffect(() => {
    setProductData([])
    setIsLoading(true)
    handleApply()
  }, [page])

  useEffect(() => {
    reloadList.current = data => {
      if (productData.length > 0) {
        let orders = productData
        const totalOrders = productData.length

        if (orders?.length && orders?.length > 0) {
          if (orders.length < 20) {
            orders = [data?.orderData, ...orders]
            setProductData(orders)
            setTotalRecords(totalOrders + 1)
          } else {
            orders.pop()
            orders = [data?.orderData, ...orders]
            setProductData(orders)
          }
        } else {
          setProductData([data?.orderData])
          setTotalRecords(1)
        }
      }
    }
  }, [productData])

  useEffect(() => {
    if (users.length > 0) {
      setUserData(users[0])
    }
  }, [users])

  useEffect(() => {
    const orders = ReturnedOrders?.record
    const totalOrders = ReturnedOrders?.total_orders

    if (orders?.length && orders[0]?.status == "cancelled") {
      setProductData(orders)
      setTotalRecords(totalOrders)
    }
    setIsLoading(false)
  }, [ReturnedOrders])

  const columns = [
    {
      dataField: "orderID",
      text: "Order",
      sort: false,
      formatter: (cellContent, row) => {
        if (isLoading) {
          return <Skeleton count={1} />
        }
        return row?.orderID || "N/A"
      },
    },
    {
      dataField: "customer.customerID",
      text: "Customer",
      sort: false,
      formatter: (cellContent, row) => {
        if (isLoading) {
          return <Skeleton count={1} />
        }
        return row?.customer?.customerID || "N/A"
      },
    },
    {
      dataField: "customer.mobile",
      text: "Mobile",
      sort: false,
      formatter: (cellContent, row) => {
        if (isLoading) {
          return <Skeleton count={1} />
        }
        return row?.customer?.mobile || "N/A"
      },
    },
    {
      dataField: "customer.newCustomer",
      text: "Customer Type",
      sort: false,
      formatter: (cellContent, row) => {
        if (isLoading) {
          return <Skeleton count={1} />
        }
        return row.customer?.newCustomer ? "New" : "Old"
      },
    },
    {
      dataField: "residanceType",
      text: "Location Type",
      sort: false,
      formatter: (cellContent, row) => {
        if (isLoading) {
          return <Skeleton count={1} />
        }
        return <span className="text-capitalize">{row.location?.type} </span>
      },
    },
    {
      dataField: "startTime",
      text: "Start Date",
      sort: false,
      formatter: (cellContent, row) => {
        if (isLoading) {
          return <Skeleton count={1} />
        }
        return row?.startTime
      },
    },
    {
      dataField: "cancel date",
      text: "Return Date",
      sort: false,
      formatter: (cellContent, row) => {
        if (isLoading) {
          return <Skeleton count={1} />
        }
        return row.returned?.returnDateTime.split("T").join(" ").slice(0, -5)
      },
    },
    {
      dataField: "reason",
      text: "Return Reason",
      classes: "reason-wrap",
      sort: false,
      formatter: (cellContent, row) => {
        if (isLoading) {
          return <Skeleton count={1} />
        }
        let reasonObject = refundReason.find(
          item => item._id === row?.returned?.returnReason
        )
        const returnReason = row?.returned?.otherReason ? row?.returned?.otherReason: reasonObject?.reasonEn
        return returnReason
        //  ?? row.cancellationReason
      },
    },
  ]

  const defaultSorted = [
    {
      dataField: "id",
      order: "asc",
    },
  ]

  const pageOptions = {
    sizePerPage: 20,
    totalSize: totalRecords, // replace later with size(customers),
    custom: true,
  }

  if (
    userData?.permissions?.allPermissions == true ||
    userData?.permissions?.orders?.read == true
  ) {
    return (
      <React.Fragment>
        <div className="page-content">
          <MetaTags>
            <title>Kleen | Returned Orders list</title>
          </MetaTags>
          <div className="container-fluid">
            <Breadcrumbs title="Orders" breadcrumbItem="Returned orders" />

            <Row id="cancel-order-list">
              <Col className="col-12">
                <Card>
                  <CardBody>
                    {isLoading ? (
                      <div className="row">
                        <div className="col-xl-12">
                          <div className="table-responsive">
                            <div className="react-bootstrap-table">
                              <table className="table table align-middle table-nowrap">
                                <thead className="table-light">
                                  <tr>
                                    <th>Order</th>
                                    <th>Customer</th>
                                    <th>Mobile</th>
                                    <th>Start Date</th>
                                    <th>Cancelled Date</th>
                                    <th>Reason</th>
                                    <th>Total Orders</th>
                                    <th>First Order</th>
                                    <th>Last Order</th>
                                    <th>Registration</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {[...Array(10).keys()].map(k => (
                                    <tr key={k}>
                                      <td>
                                        <Skeleton count={1} />
                                      </td>
                                      <td>
                                        <Skeleton count={1} />
                                      </td>
                                      <td>
                                        <Skeleton count={1} />
                                      </td>
                                      <td>
                                        <Skeleton count={1} />
                                      </td>
                                      <td>
                                        <Skeleton count={1} />
                                      </td>
                                      <td>
                                        <Skeleton count={1} />
                                      </td>
                                      <td>
                                        <Skeleton count={1} />
                                      </td>
                                      <td>
                                        <Skeleton count={1} />
                                      </td>

                                      <td>
                                        <Skeleton count={1} />
                                      </td>
                                      <td>
                                        <Skeleton count={1} />
                                      </td>
                                      <td>
                                        <Skeleton count={1} />
                                      </td>
                                    </tr>
                                  ))}
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                      </div>
                    ) : (
                      <PaginationProvider
                        pagination={paginationFactory(pageOptions)}
                        keyField="id"
                        columns={columns}
                        data={productData}
                      >
                        {({ paginationProps, paginationTableProps }) => (
                          <ToolkitProvider
                            keyField="id"
                            columns={columns}
                            data={productData}
                            search
                          >
                            {toolkitProps => (
                              <React.Fragment>
                                <Row>
                                  <Col xl="12">
                                    <div className="table-responsive">
                                      <BootstrapTable
                                        keyField={"id"}
                                        responsive
                                        bordered={false}
                                        striped={false}
                                        defaultSorted={defaultSorted}
                                        classes={
                                          "table align-middle table-nowrap"
                                        }
                                        headerWrapperClasses={"table-light"}
                                        {...toolkitProps.baseProps}
                                        {...paginationTableProps}
                                      />
                                    </div>
                                  </Col>
                                </Row>
                              </React.Fragment>
                            )}
                          </ToolkitProvider>
                        )}
                      </PaginationProvider>
                    )}
                  </CardBody>
                </Card>
              </Col>
            </Row>
            <div className="align-items-md-center mt-30 row">
              <div className="inner-custom-pagination d-flex col">
                <div className="text-md-right ms-auto">
                  <ul className="pagination react-bootstrap-table-page-btns-ul">
                    {page > 5 ? (
                      <li className="page-item" title="next page">
                        <a onClick={() => setPage(0)} className="page-link">
                          First
                        </a>
                      </li>
                    ) : null}
                    {[...Array(Math.ceil(totalRecords / 20)).keys()].map(
                      element =>
                        page >= element - 5 && page <= element + 5 ? (
                          <li
                            key={element}
                            className={classNames("page-item", {
                              active: element == page,
                            })}
                            title="1"
                          >
                            <a
                              onClick={() => setPage(element)}
                              className="page-link"
                            >
                              {element + 1}
                            </a>
                          </li>
                        ) : null
                    )}
                    {page < Math.ceil(totalRecords / 20) - 6 ? (
                      <li className="page-item" title="next page">
                        <a
                          onClick={() =>
                            setPage(Math.ceil(totalRecords / 20) - 1)
                          }
                          className="page-link"
                        >
                          Last
                        </a>
                      </li>
                    ) : null}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    )
  } else {
    return <Pages404 />
  }
}

export default ReturnedOrders
