import {
    API_SUCCESS,
    API_FAIL,
    GET_USER_LIST,
    GET_USER_DETAILS,
    TOGGLE_USER_ACTIVE,
    TOGGLE_USER_PERM,
    SEARCH_USERS,
    FILTER_USERS
} from "./actionTypes";

export const apiSuccess = (actionType, data) => ({
    type: API_SUCCESS,
    payload: { actionType, data },
});

export const apiFail = (actionType, error) => ({
    type: API_FAIL,
    payload: { actionType, error },
});

export const getUsers = (data) => ({
    type: GET_USER_LIST,
    payload: data
});

export const getUserdetails = (userId) => ({
    type: GET_USER_DETAILS,
    payload: userId
});

export const toggleUserActive = (userDetails) => ({
    type: TOGGLE_USER_ACTIVE,
    payload: userDetails
});

export const toggleUserPermission = (userDetails) => ({
    type: TOGGLE_USER_PERM,
    payload: userDetails
});

// to get permissions of current user 
// TODO : rename action to getUserPermissions

export const searchUsers = (data) => ({
  type: SEARCH_USERS,
  payload: data
});

//go get any other user details 
export const filterUsers = (data) => ({
  type: FILTER_USERS,
  payload: data
});


