import {
  GET_DRIVER_LIST,
  GET_DRIVER_LIST_SUCCESS,
  GET_DRIVER_LIST_FAIL,

  UPDATE_DRIVER,
  UPDATE_DRIVER_SUCCESS,
  UPDATE_DRIVER_FAIL,

  ADD_NEW_DRIVER,
  ADD_NEW_DRIVER_SUCCESS,
  ADD_NEW_DRIVER_FAIL,

  DELETE_DRIVER,
  DELETE_DRIVER_SUCCESS,
  DELETE_DRIVER_FAIL,

  GET_DRIVER_LOCATIONS,
  GET_DRIVER_LOCATIONS_FAIL,
  GET_DRIVER_LOCATIONS_SUCCESS

} from "./actionTypes"

export const getDriverList = (data) => ({
  type: GET_DRIVER_LIST,
  payload: data
})

export const getDriverListSuccess = (data) => ({
  type: GET_DRIVER_LIST_SUCCESS,
  payload: data
})

export const getDriverListFail = (data) => ({
  type: GET_DRIVER_LIST_FAIL,
  payload: data
})

export const addNewDriver = (data) => ({
  type: ADD_NEW_DRIVER,
  payload: data
})

export const addNewDriverSuccess = (data) => ({
  type: GET_DRIVER_LIST,
  payload: {}
})

export const addNewDriverFail = (data) => ({
  type: ADD_NEW_DRIVER_FAIL,
  payload: data
})

export const updateDrivers = (data) => ({
  type: UPDATE_DRIVER,
  payload: data
})

export const updateDriverSuccess = (data) => ({
  type: GET_DRIVER_LIST,
  payload: {}
})

export const updateDriverFail = (data) => ({
  type: UPDATE_DRIVER_FAIL,
  payload: data
})

export const deleteDrivers = (data) => ({
  type: DELETE_DRIVER,
  payload: data
})

export const deleteDriverSuccess = (data) => ({
  type: DELETE_DRIVER_SUCCESS,
  payload: data
})

export const deleteDriverFail = (data) => ({
  type: DELETE_DRIVER_FAIL,
  payload: data
})

export const getDriverLocations = (data) => ({
  type: GET_DRIVER_LOCATIONS,
  payload: data
})

export const getDriverLocationsSuccess = (data) => ({
  type: GET_DRIVER_LOCATIONS_SUCCESS,
  payload: data
})

export const getDriverLocationsFail = (data) => ({
  type: GET_DRIVER_LOCATIONS_FAIL,
  payload: data
})