import React, { useEffect, useState } from "react"
import MetaTags from "react-meta-tags";
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';
import "../../assets/css/Allorders.css"
import { useHistory } from "react-router-dom";
import { Container, Row, Col, Label, Button, Modal, Input, Collapse } from "reactstrap"
import classNames from "classnames"
import { getReasons, refund, getAllOrdersSearch, updateOrdersInvoice, getLaundrylist, getDriverlist, getTimeSlotList, getRecord, getSearchRecord, getPermission, updateCustomers } from "../../store/actions"
import { useSelector, useDispatch } from "react-redux"
import moment from "moment";
import toastr from "toastr";
import "toastr/build/toastr.min.css";
import Flatpickr from "react-flatpickr"
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'

import Breadcrumbs from "components/Common/Breadcrumb"

import Pages404 from "pages/Utility/pages-404";
import Select from "react-select"

const CancelOrders = props => {
  var data = JSON.parse(localStorage.getItem("authUser"));
  const history = useHistory();
  const dispatch = useDispatch();
  const { Allorders, refundReason, refundSuccess, totalRecord, customerUpdate, pickup_waiting_orders, delivery_waiting_orders, laundry_delayed_orders, delivery_inprogress_orders, laundryList, driverList, timeSlots } = useSelector(state => ({
    // Allorders: state.Orders.Allorders,
    Allorders: state.Records.records,
    totalRecord: state.Records.totalRecord,
    customerUpdate: state.Orders.customerUpdate,
    pickup_waiting_orders: state.Orders.pickup_waiting_orders,
    delivery_waiting_orders: state.Orders.delivery_waiting_orders,
    laundry_delayed_orders: state.Orders.laundry_delayed_orders,
    delivery_inprogress_orders: state.Orders.delivery_inprogress_orders,
    refundReason: state.Orders.reason,
    refundSuccess: state.Orders.refund,
    laundryList: state.ecommerce.laudrylist,
    driverList: state.ecommerce.driverlist,
    timeSlots: state.ecommerce.timeSlots
  }))

  const [loader, setLoader] = useState(true);
  const [saveButtonLoader, setSaveButtonLoader] = useState(0);
  const [secondes, setSecondes] = useState(0);
  const [allOrderCount, setAllOrderCount] = useState(0);
  const [cancelOrder, setCancelOrder] = useState(false);
  const [cancelReason, setCancelReason] = useState("");
  const [cancelOrderId, setCancelOrderId] = useState(0);
  const [error, setError] = useState({})
  const [orderNote, setOrderNote] = useState("");
  const [accordianFor, setAccordianFor] = useState([]);
  const [orderLaundryId, setOrderLaundryId] = useState(0)
  const [orderDriverId, setOrderDriverId] = useState(0)
  const [orderDropoffDriverId, setOrderDropoffDriverId] = useState(0)
  const [orderTypeId, setOrderTypeId] = useState("");
  const [editDateModal, setEditDateModal] = useState("");
  const [subEditDateModal, setSubEditDateModal] = useState(false);
  const [timeStamp, setTimeStamp] = useState([]);
  const [selectPickUpDate, setSelectPickUpDate] = useState();
  const [dateData, setDateData] = useState();
  const [timeSlotData, setTimeSlotData] = useState([]);
  const [selectedTimeSlot, setSelectedTimeSlot] = useState({});
  const [refundModalToggel, setRefundModalToggel] = useState(false);
  const [reasonOptions, setReasonOptions] = useState([]);
  const [selectedReasonOption, setSelectedReasonOption] = useState({ label: "Select Reason", value: "" });
  const [reasonRefund, setReasonRefund] = useState("");
  const [refundAmount, setRefundAmount] = useState("");
  const [refundOrderData, setRefundOrderData] = useState({});
  const [page, setPage] = useState(0);
  const [bagNo, setBagNo] = useState("");
  const [editableBag, setEditableBag] = useState(0);
  const [editBagNoLoader, setEditBagNoLoader] = useState(0);
  const [moveButtonLoader, setMoveButtonLoader] = useState(0);
  const [bagDetailModal, setBagDetailModal] = useState(false)
  const [bagDetail, setBagDetail] = useState("");
  const [searchActive, setSearchActive] = useState(false);
  const [selectedSearch, setSelectedSearch] = useState("mobile");
  const [searchVal, setSearchVal] = useState("");

  const [filterVal, setFilterVal] = useState(Allorders);
  const [hideFillter, setHideFillter] = useState(false);
  const [activeTab, setActiveTab] = useState("all-order");
  const [allOrderFilter, setAllOrderFilter] = useState({
    status: ["created", "picked", "in_laundry", "washing", "ready", "out_laundry"],
    paid: false,
    unpaid: true,
    category: ["online"],
    newCustomer: false
  });

  const handleOrderRefund = (item) => {
    dispatch(getReasons({ type: "refund" }))
    setRefundModalToggel(true)
    setRefundOrderData(item)
  }

  useEffect(() => {
    if (refundReason?.length) {
      let reasonArr = refundReason.map((item) => {
        return { label: item.reasonEn, value: item._id }
      })
      setReasonOptions(reasonArr);
    }
  }, [refundReason])

  useEffect(() => {
    setEditableBag(0);
    setEditBagNoLoader(0);
    if (customerUpdate == "Success" && editBagNoLoader) {
      setBagNo("");
      // toastr.success(`Customer data Updated`, `Customer`);
    }
  }, [customerUpdate])

  const handleEditBagNo = (_id) => {
    if (!bagNo) {
      setError({ bagNo: "Bag Number Required" });
      return;
    }
    setEditBagNoLoader(_id);
    let apiData = { _id, bagNo }
    dispatch(updateCustomers(apiData))
  }

  const handleBagDetail = (item) => {
    setBagDetailModal(true);
    setBagDetail(item?.customer?.bagSettings);
  }

  const handleRefund = () => {
    if (refundOrderData?.grandTotal && refundOrderData?.grandTotal < refundAmount) {
      setError({ refundAmt: "Refund Amount can't be greater then total amount" });
      return;
    } else if (!refundOrderData?.grandTotal && refundOrderData?.total && refundOrderData?.total < refundAmount) {
      setError({ refundAmt: "Refund Amount can't be greater then total amount" });
      return;
    }
    if (!refundAmount) {
      setError({ refundAmt: "Refund Amount Required" });
      return;
    }
    if (!selectedReasonOption.value) {
      setError({ refundOpt: "Reason Option Required" });
      return;
    }
    if (selectedReasonOption.label == "Other" && !reasonRefund) {
      setError({ refundCmt: "Refund Reason Required" });
      return;
    }
    let data = {
      _id: refundOrderData._id,
      refund: {
        amount: refundAmount,
        reason: selectedReasonOption.value
      }
    }
    if (reasonRefund) {
      data.refund.otherReason = reasonRefund
    }
    dispatch(refund(data))
  }

  // useEffect(() => {
  //   setRefundModalToggel(false);
  //   if (refundSuccess == "success") {
  //     toastr.success(`Refund Success`, `Your refund is successfully`);
  //   } else if (refundSuccess == "fail") {
  //     toastr.danger(`Refund Fail`, `Your refund is Failed`);
  //   }
  // }, [refundSuccess])

  const handleReasonOption = (item) => {
    setSelectedReasonOption(item);
  }

  const handleAccordian = (orderId) => {
    let newArr = [...accordianFor];
    let indexOfClick = newArr.indexOf(orderId);
    if (indexOfClick !== -1) {
      newArr.splice(indexOfClick, 1);
    } else {
      newArr.push(orderId);
    }
    setAccordianFor(newArr);
  }

  useEffect(() => {
    const Countdown = () => {
      setSecondes(secondes + 1);
    }
    setTimeout(Countdown, 1000);
  }, [secondes])

  const onChangeTabs = (tabsType) => {
    setLoader(true);
    setFilterVal([]);
    setActiveTab(tabsType)
  }

  useEffect(() => {
    if (Allorders?.length) {
      if (activeTab == "all-order") {
        setAllOrderCount(Allorders.length)
      }
      let redOrderArr = [];
      let yellowOrderArr = [];
      let blueOrderArr = [];
      Allorders.map((item) => {
        if ((item?.status == "created" && SecondConditionFunction(item, item?._id) !== "00:00:00") || item?.status == "picked" || (item?.status == "ready" && item?.paid && SecondConditionFunction(item, item?._id) !== "00:00:00") || (item?.status == "out_laundry" && SecondConditionFunction(item, item?._id) !== "00:00:00")) {
          yellowOrderArr.push(item);
        } else if (item?.status == "in_laundry" || (item?.status == "washing" && SecondConditionFunction(item, item?._id) !== "00:00:00") || (item?.status == "ready" && SecondConditionFunction(item, item?._id) !== "00:00:00" && !item?.paid)) {
          blueOrderArr.push(item);
        } else {
          redOrderArr.push(item);
        }
      })
      if (allOrderFilter.newCustomer && activeTab == "all-order") {
        let filteredOrders = [...redOrderArr, ...yellowOrderArr, ...blueOrderArr].filter((data) => data?.customer?.newCustomer)
        setFilterVal(filteredOrders);
      } else {
        setFilterVal([...redOrderArr, ...yellowOrderArr, ...blueOrderArr]);
      }
    }
    setLoader(false);
    setSaveButtonLoader(0);
  }, [Allorders])

  function DropdownItemTagsExample() {
    return (
      <DropdownButton id="dropdown-item-button" title="Filter">
        <Dropdown.Item as="button" onClick={() => { FunAll() }}>All</Dropdown.Item>
        <Dropdown.Item as="button" onClick={() => { FunOnline() }}>Online</Dropdown.Item>
        <Dropdown.Item as="button" onClick={() => { FunOffline() }}>Offline </Dropdown.Item>
      </DropdownButton>
    );
  }

  useEffect(() => {
    if (activeTab == "waiting-pickup") {
      dispatch(getAllOrdersSearch({
        status: ["created"],
        category: "online",
        dashboard: true
      }))
    }
    else if (activeTab == "waiting-delivery") {
      dispatch(getAllOrdersSearch({
        status: ["ready"],
        category: "online",
        paid: true,
        dashboard: true
      }))
    }
    else if (activeTab == "laundry-delay") {
      dispatch(getAllOrdersSearch({
        status: ["washing"],
        category: "online",
        dashboard: true
      }))
    }
    else if (activeTab == "delivery-in-progress") {
      dispatch(getAllOrdersSearch({
        status: ["out_laundry"],
        category: "online",
        dashboard: true
      }))
    }
    else {
      handleApply()
    }
  }, [page]);

  useEffect(() => {
    dispatch(getDriverlist({ "online": true }));
    dispatch(getLaundrylist({ "status": true }));
  }, [])

  const myTimer = (apiTime, t_id, hours, deliveryDate = null, endtime = null) => {
    var countDownDate = new Date(apiTime);
    if (hours) {
      var fourHoursLater = countDownDate.setHours(countDownDate.getHours() + hours);
    } else {
      var ntDate = deliveryDate + " " + endtime + ":00";
      // var ntDate = endtime;
      var countDownDate = new Date(ntDate);
      var fourHoursLater = countDownDate.setHours(countDownDate.getHours());
    }
    var newdate = new Date();
    var diff = newdate.getTimezoneOffset(); // diffrence from UTC time
    var distance = fourHoursLater - newdate - ((diff + 180) * 60 * 1000); // minus ((diff + 180) * 60 * 1000) for saudi arabia time zone
    var hours = Math.floor(distance / (1000 * 60 * 60));
    var minutes = Math.floor((distance / (1000 * 60)) % 60);
    var seconds = Math.floor((distance / 1000) % 60);
    if (distance > 0) {
      return hours + "h " + minutes + "m " + seconds + "s ";
    } else {
      return "00:00:00";
    }
  }

  // function FirstConditionFunction(item, id_key) {
  //   if (item?.category === "online" && item?.status === "created" && item?.pickupTimeslot) {
  //     return 'Pickup Timer';
  //   } else {
  //     return 'Order Timer';
  //   }
  // }

  function SecondConditionFunction(item, key_id) {
    let remainingTime = '00:00:00';
    if (item?.category === "online" && item?.status === "created") {
      if (item.pickupTimeslot?.end) {
        if (item?.customer?.bagNo && item?.type === "clothes" && item.location.type === "apartment") {
          remainingTime = myTimer(item?.startTime, key_id, 4);
        } else {
          remainingTime = myTimer(item?.startTime, key_id, '', item?.pickupDate, item?.pickupTimeslot.end);
        }
      } else {
        remainingTime = myTimer(item?.startTime, key_id, 4);
      }
    } else if (item?.status === "washing") {
      remainingTime = myTimer(item?.invoiceTime, item?._id, 12);
    } else if (item?.status === "picked" || item?.status === "in_laundry" || item?.status === "out_laundry" || item?.status === "ready") {
      if (item?.category === "offline") {
        remainingTime = myTimer(item?.invoiceTime, item?._id, 12);
      } else {
        if (item?.deliveryTimeslot?.end && item?.customer?.bagNo && item?.type === "clothes") {
          remainingTime = myTimer(item?.startTime, item?._id, '', item?.deliveryDate, item?.pickupTimeslot.end);
        } else {
          remainingTime = myTimer(item?.startTime, item?._id, 36);
        }
      }
    }

    return remainingTime;
  }

  const handleCreateInvoice = (id) => {
    history.push(`/create-invoice/${id}`);
  }

  const handleFilterCategory = (status) => {
    let statusData = [...allOrderFilter.status];
    let indexOfCat = statusData.indexOf(status);
    if (indexOfCat !== -1) {
      statusData.splice(indexOfCat, 1);
    } else {
      statusData.push(status)
    }
    setAllOrderFilter({ ...allOrderFilter, status: statusData })
  }

  const handleCategory = (category) => {
    let categoryData = [...allOrderFilter.category];
    let indexOfCat = categoryData.indexOf(category);
    if (indexOfCat !== -1) {
      categoryData.splice(indexOfCat, 1);
    } else {
      categoryData.push(category)
    }
    setAllOrderFilter({ ...allOrderFilter, category: categoryData })
  }

  const handlePaid = () => {
    setAllOrderFilter({ ...allOrderFilter, paid: !allOrderFilter.paid })
  }

  const handleUnPaid = () => {
    setAllOrderFilter({ ...allOrderFilter, unpaid: !allOrderFilter.unpaid })
  }

  const handleApply = () => {
    setSearchVal("");
    setLoader(true);
    setSearchActive(false)
    setSelectedSearch("mobile")
    setFilterVal([]);
    let apiData = { status: ["cancelled"], page: page, category: ["online"] };
    // return;
    // if (allOrderFilter.status.length > 0) {
    //   apiData = { ...apiData, status: allOrderFilter.status }
    // }
    // if (allOrderFilter.paid !== allOrderFilter.unpaid) {
    //   apiData = { ...apiData, paid: allOrderFilter.paid }
    // }
    // if (allOrderFilter.category.length == 1) {
    //   apiData = { ...apiData, category: allOrderFilter.category[0] }
    // }
    dispatch(getRecord(apiData))
  }

  const handleSearchApply = () => {
    setLoader(true);
    setFilterVal([]);
    let apiData = { status: ["cancelled"], category: ["online"] };
    if (searchVal) {
      apiData[selectedSearch] = selectedSearch == "mobile" ? "966" + searchVal : searchVal;
    }
    if (data?.role == "laundry" || data?.role == "partner_laundry") {
      apiData["laundry"] = data?.laundry
    }
    // return;
    // if (allOrderFilter.status.length > 0) {
    //   apiData = { ...apiData, status: allOrderFilter.status }
    // }
    // if (allOrderFilter.paid !== allOrderFilter.unpaid) {
    //   apiData = { ...apiData, paid: allOrderFilter.paid }
    // }
    // if (allOrderFilter.category.length == 1) {
    //   apiData = { ...apiData, category: allOrderFilter.category[0] }
    // }
    dispatch(getSearchRecord(apiData))
  }

  const confirmOrderCancel = (orderId) => {
    setCancelOrderId(orderId);
    setCancelOrder(true);
  }

  useEffect(() => {
    if (!cancelOrder) {
      setError({})
    }
  }, [cancelOrder])

  const handleCancelOrder = () => {
    if (!cancelReason) {
      setError({ cancelReason: "Please enter cancel reason" });
      return;
    }
    let _id = cancelOrderId;
    let status = "cancelled";
    let cancellationReason = cancelReason;
    let apiData = { _id, status, cancellationReason };
    dispatch(updateOrdersInvoice(apiData));
    setCancelOrder(false);
    setTimeout(() => {
      window.location.reload();
    }, 1000);
  }

  const handleMoveToNewOrder = (_id) => {
    setMoveButtonLoader(_id);
    let apiData = {
      _id, status: "created"
    }
    dispatch(updateOrdersInvoice(apiData));
    setTimeout(() => {
      setMoveButtonLoader(0);
      toastr.success("Order Moved", "Order");
      handleApply()
    }, 1000);
  }

  const handleOrderNote = (id) => {
    if (!orderNote) {
      setError({ orderNote: "Please enter a note" });
      return;
    }
    setSaveButtonLoader(id);
    let _id = id;
    let note = orderNote;
    let apiData = { _id };
    if (note) {
      apiData["customer_care_note"] = note;
    }
    dispatch(updateCustomers(apiData));
    // dispatch(updateOrdersInvoice(apiData));
    setTimeout(() => {
      setSaveButtonLoader(0);
      // toastr.success("Order updated", "Order");
      setOrderNote("")
    }, 1000);
  }

  const handleLaundry = (data) => {
    let laundryId = data.target.value;
    setOrderLaundryId(laundryId);
  }

  const handleDriver = (data) => {
    let driverId = data.target.value;
    setOrderDriverId(driverId);
  }

  const handleDropoffDriver = (data) => {
    let driverId = data.target.value;
    setOrderDropoffDriverId(driverId);
  }

  const handleOrderType = (data) => {
    let orderType = data.target.value;
    setOrderTypeId(orderType);
  }

  const handleDateEdit = (item, openFor) => {
    setEditDateModal(openFor);
    setDateData(item);
    let newDatesArr = []
    let editFrom = new Date();
    let originalDate = editFrom.setDate(editFrom.getDate() + 0)
    newDatesArr.push(originalDate);
    for (let index = 0; index < 31; index++) {
      let DateArr = editFrom.setDate(editFrom.getDate() + 1);
      newDatesArr.push(DateArr);
    }
    setTimeStamp(newDatesArr);
  }

  const handleselectDate = (item) => {
    setTimeSlotData([])
    setSelectPickUpDate(item)
    setSubEditDateModal(true)
    let selectedDate = moment(item).format('YYYY-MM-DD');
    const data = {
      date: selectedDate,
      type: dateData.type,
      customerId: dateData.customer.customerID,
      pickupdate: dateData.pickupDate
    }
    dispatch(getTimeSlotList(data));
  }

  useEffect(() => {
    if (timeSlots?.length) {
      setTimeSlotData(timeSlots)
    }
  }, [timeSlots])

  useEffect(() => {
    if (!editDateModal) {
      setSubEditDateModal(false);
      setTimeStamp([]);
      setSelectPickUpDate(null);
      setDateData(null);
      setTimeSlotData([]);
      setSelectedTimeSlot({});
    }
  }, [editDateModal])

  const handleSelectTimeSlot = (item) => {
    setSelectedTimeSlot(item)
  }

  const UpdateDate = () => {
    // setLoader(true);
    let apiData = { _id: dateData._id };
    let selectedDate = moment(selectPickUpDate).format('YYYY-MM-DD');
    let timeslot_id = selectedTimeSlot._id
    if (editDateModal == "delivery") {
      apiData["deliveryDate"] = selectedDate;
      apiData["deliveryTimeslot"] = timeslot_id;
    } else if (editDateModal == "pickup") {
      apiData["pickupDate"] = selectedDate;
      apiData["pickupTimeslot"] = timeslot_id;
    }
    dispatch(updateOrdersInvoice(apiData));
    setEditDateModal("");
    setTimeout(() => {
      handleApply();
      toastr.success(editDateModal + "Date updated", "Order");
    }, 1000);
  }

  const showHandoverTime = (item) => {
    let inLaundryTime = new Date(item.inLaundryTime);
    let invoiceTime = new Date(item.invoiceTime);
    let diff = invoiceTime - inLaundryTime;
    var hours = Math.floor(diff / (1000 * 60 * 60));
    var minutes = Math.floor((diff / (1000 * 60)) % 60);
    var seconds = Math.floor((diff / 1000) % 60);
    return "" + ("0" + hours).slice(-2) + " : " + ("0" + minutes).slice(-2) + " : " + ("0" + seconds).slice(-2) + " Hr"
  }

  const [fill, setFill] = useState({});
  const { permission } = useSelector(state => ({
    permission: state.permission.permissions
  }))

  useEffect(() => {
    dispatch(getPermission({}))
  }, [])

  useEffect(() => {
    const filterProduct = permission?.filter((item) => item?.role === data?.role)
    if(filterProduct?.length)
    setFill(filterProduct[0])
  }, [permission])


  if (data?.role !== "admin" && !fill?.panelPermissions?.orders) {
    return <Pages404 />
  }

  return (
    <div className="page-content">
      <MetaTags>
        <title>Cancelled Orders | Kleen Admin Panel</title>
      </MetaTags>
      <Container fluid>
        {/* Render Breadcrumb */}

        <Breadcrumbs
          title={"Home"}
          breadcrumbItem={"Cancelled Orders"}
        />
        <div className="dashboard-cards-section">
          {/* <div className="filterAllOrder"><DropdownItemTagsExample /></div> */}
          <div className="container cardTop desktop-width">
            <div className={
              classNames("col-md-12", "mt-5", "text-end",
                { "d-none": searchActive }
              )
            }>
              <Button onClick={() => setSearchActive(true)} color="primary"><i className="fas fa-search"></i></Button>
            </div>
            <div className={
              classNames("card", "card-body", "mt-5",
                { "d-none": !searchActive }
              )
            }
            >
              <div className="row">
                <div className="col-md-8 col-lg-8 col-xxl-8 my-auto d-flex">
                  <div className="form-check col mb-2">
                    <input className="form-check-input" name="flexRadioDefault" type="radio" id="orderFliterMobile" onChange={() => handleFilterCategory("created")} checked={selectedSearch == "mobile"} onClick={() => setSelectedSearch("mobile")} />
                    <label className="form-check-label" htmlFor="orderFliterMobile">
                      Mobile
                    </label>
                  </div>
                  <div className="form-check col mb-2">
                    <input className="form-check-input" name="flexRadioDefault" type="radio" id="orderFliterOrderId" onChange={() => handleFilterCategory("picked")} checked={selectedSearch == "orderID"} onClick={() => setSelectedSearch("orderID")} />
                    <label className="form-check-label" htmlFor="orderFliterOrderId">
                      Order ID
                    </label>
                  </div>
                  <div className="form-check col mb-2">
                    <input className="form-check-input" name="flexRadioDefault" type="radio" id="orderFliterBillNo" onChange={() => handleFilterCategory("in_laundry")} checked={selectedSearch == "billNo"} onClick={() => setSelectedSearch("billNo")} />
                    <label className="form-check-label" htmlFor="orderFliterBillNo">
                      Bill No
                    </label>
                  </div>
                  <div className="form-check col mb-2">
                    <input className="form-check-input" name="flexRadioDefault" type="radio" id="orderFliterInvoice" onChange={() => handleFilterCategory("washing")} checked={selectedSearch == "date"} onClick={() => setSelectedSearch("date")} />
                    <label className="form-check-label" htmlFor="orderFliterInvoice">
                      Invoice Date
                    </label>
                  </div>
                  <div className="form-check col mb-2">
                    <input className="form-check-input" name="flexRadioDefault" type="radio" id="orderFliterBag" onChange={() => handleFilterCategory("ready")} checked={selectedSearch == "bagNo"} onClick={() => setSelectedSearch("bagNo")} />
                    <label className="form-check-label" htmlFor="orderFliterBag">
                      Bag no
                    </label>
                  </div>
                  <div className="form-check col mb-2">
                    <input className="form-check-input" name="flexRadioDefault" type="radio" id="orderFliterCustomer" onChange={() => handleFilterCategory("out_laundry")} checked={selectedSearch == "customerID"} onClick={() => setSelectedSearch("customerID")} />
                    <label className="form-check-label" htmlFor="orderFliterCustomer">
                      Customer ID
                    </label>
                  </div>
                </div>
                <div className="col-md-4 col-lg-4 col-xxl-4 d-flex">
                  <div className="col-md-8">
                    {
                      selectedSearch == "date" ? (
                        <Flatpickr
                          className="form-control d-block"
                          placeholder="Select Date"
                          options={{
                            altInput: true,
                            altFormat: "F j, Y",
                            dateFormat: "Y-m-d"
                          }}
                          onChange={(e) => setSearchVal(moment(e[0]).format("YYYY-MM-DD"))}
                        />
                      ) : (
                        <input value={searchVal} className="form-control" onChange={(e) => setSearchVal(e.target.value)} type="text" placeholder="Search" />
                      )
                    }
                  </div>
                  <div className="col-md-3 mx-2 d-flex" onClick={searchVal ? handleSearchApply : handleApply}>
                    <Button color="primary">Search</Button>
                    <Button onClick={handleApply} className="btn-sm closebtns22 btn btn-secondary border">
                      <i className="bx bx-x"></i>
                    </Button>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              {
                loader ?
                  [1, 2, 3, 4].map((item) => {
                    return (<div key={item} className="col-md-6 col-lg-6 col-xxl-6 d-flex">
                      <div className='card card-box, t_card_timer, order_box-card' style={{ width: "100%" }}>
                        <div className="d-flex justify-content-between">
                          <div className="position-btn">
                            <Skeleton count={1} />
                          </div>
                          <div className="mt-1">
                            <h5> <Skeleton count={1} />
                            </h5>
                          </div>
                          <div className="position-btn">
                            <Skeleton count={1} />
                          </div>
                        </div>
                        <Row className="order-min-box">
                          <Col md="6" className="order-id">
                            <Skeleton count={6} />
                          </Col>
                          <Col md="6" className="order-id washing_order">
                            <Skeleton count={6} />
                          </Col>
                        </Row>
                        <div className="d-flex position-absolute bottom-0 start-0">
                          <div className="position-btn">
                            <Skeleton count={1} />
                          </div>
                        </div>
                      </div>
                    </div>)
                  }) :
                  filterVal?.map((item) => {
                    return (activeTab == "laundry-delay" && SecondConditionFunction(item, item?._id) !== "00:00:00") ? null : (
                      <div className="col-md-6 col-lg-6 col-xxl-6 d-flex" key={item?._id}>
                        <div className={classNames('card card-box', 't_card_timer', 'order_box-card',
                          {
                            // 'order_box-card': ((item?.status == "created" && SecondConditionFunction(item, item?._id) == "00:00:00") || (item?.status == "washing" && SecondConditionFunction(item, item?._id) == "00:00:00") || (item?.status == "out_laundry" && SecondConditionFunction(item, item?._id) == "00:00:00")),
                            'order_box-card-yellow': ((item?.status == "created" && SecondConditionFunction(item, item?._id) !== "00:00:00") || item?.status == "picked" || (item?.status == "ready" && item?.paid && SecondConditionFunction(item, item?._id) !== "00:00:00") || (item?.status == "out_laundry" && SecondConditionFunction(item, item?._id) !== "00:00:00")),
                            'order_box-card-blue': (item?.status == "in_laundry" || (item?.status == "washing" && SecondConditionFunction(item, item?._id) !== "00:00:00") || (item?.status == "ready" && SecondConditionFunction(item, item?._id) !== "00:00:00" && !item?.paid))
                          })
                        }
                          id={`new-back-color_${item?._id}`}>
                          <div className="d-flex justify-content-between">
                            <div className="position-btn">
                              {
                                item?.customer?.newCustomer ? (
                                  <a className="btn btn-danger btn-sm">New Customer</a>
                                ) : null
                              }
                            </div>
                            <div className="mt-1">
                              <h5> {item?.category.charAt(0).toUpperCase() + item?.category.slice(1)} Order <span style={{ fontSize: 12, fontWeight: 100 }}><b>(Order ID:{item?.orderID})</b></span> &nbsp;
                                {/* {
                                  ((item?.status == "ready" || item?.status == "washing") && SecondConditionFunction(item, item?._id) == "00:00:00") ? ( */}
                                <span className="btn btn-success btn-sm" style={{ textTransform: 'capitalize' }}>{item?.status}</span>
                                {/* ) : null
                                } */}
                              </h5>
                            </div>
                            <div className="position-btn">
                              {
                                (item?.paid || ((item?.status == "ready" || item?.status == "washing") && SecondConditionFunction(item, item?._id) == "00:00:00")) ? (
                                  <a className={classNames("btn", "btn-sm", {
                                    "btn-danger": !item?.paid,
                                    "btn-success": item?.paid,
                                  })}>{item?.paid ? "Paid" : "Not Paid"}</a>
                                ) : null
                              }
                            </div>
                          </div>
                          <Row className="order-min-box">
                            <Col md="6" className="order-id">
                              <h6>Area : {item?.location?.area?.name ? item?.location?.area?.name : "N/A"}</h6>
                              <h6>Type : {item?.type ? item?.type : "N/A"}</h6>
                              <h6>Start : {item?.startTime ? item?.startTime : "N/A"}</h6>
                              {
                                item?.pickupDate ? (
                                  <h6 className="bgltblue">Pickup : {item?.pickupDate} {item?.pickupTimeslot?.start}-{item?.pickupTimeslot?.end}</h6>
                                ) : null
                              }
                              {
                                ((item?.status === "created" || item?.status === "picked" || item?.status === "in_laundry" || item?.status === "washing" || item?.status === "ready" || item?.status === "out_laundry") && item?.deliveryDate && item?.deliveryTimeslot?.start && item?.deliveryTimeslot?.end) ? (
                                  <h6 className="fw-medium textsucc" style={{ cursor: "pointer" }}>
                                    Delivery : {item?.deliveryDate} {item?.deliveryTimeslot?.start}-{item?.deliveryTimeslot?.end}
                                  </h6>
                                ) : <h6 className="textsucc">
                                  {item?.deliveryDate ? "Delivery :" + item?.deliveryDate + "" + item?.deliveryTimeslot?.start + " - " + item?.deliveryTimeslot?.end : null}</h6>
                              }
                              {
                                item?.inLaundryTime && item?.invoiceTime ? (
                                  <h6 >Handover Time : {
                                    showHandoverTime(item)
                                  }</h6>
                                ) : null
                              }
                              <h6>Driver Name : {item?.dropoffDriver?.name ? item?.dropoffDriver?.name : 'N/A'}</h6>
                              <h6>Laundry :  {item?.laundry?.nameEn ? item?.laundry?.nameEn : "N/A"}</h6>

                            </Col>
                            <Col md="6" className="order-id washing_order">
                              <div className="accordion" id="accordion">
                                <div className="accordion-item">
                                  <h6>
                                    <a className="fw-medium" onClick={() => handleAccordian(item._id)} style={{ cursor: "pointer" }}>
                                      <i className="fas fa-user users121"></i>
                                      Customer ID : {item?.customer?.customerID}
                                      <i className={classNames(
                                        { "fa-angle-up": accordianFor.includes(item._id) },
                                        { "fa-angle-down": !accordianFor.includes(item._id) },
                                        "fas", "down-right-icon"
                                      )}></i>
                                    </a>
                                  </h6>
                                  <Collapse isOpen={accordianFor.includes(item._id)} className="accordion-collapse">
                                    <div className="accordion-body">
                                      <div className="text-muted border-1">
                                        <h6><i className="fas fa-user users121"></i> {item?.customer?.name ? item.customer.name : "N/A"}</h6>
                                        <h6><i className="fas fa-phone"></i> {item?.customer?.mobile ? item.customer.mobile : "N/A"}</h6>
                                        <h6><i className="fas fa-envelope"></i> {item?.customer?.email ? item.customer.email : "N/A"}</h6>
                                      </div>
                                    </div>
                                  </Collapse>
                                </div>
                              </div>
                              {item?.customer?.bagNo || item?.customer?.plan ?
                                editableBag === item._id ? (
                                  <div className="d-flex mb-3"><span className="my-auto w12s"><i className="fas fa-shopping-bag bgltblue"></i> Bag</span>
                                    <Input className="mx-2" type="text"
                                      // defaultValue={item?.customer?.bagNo} 
                                      placeholder="Enter Bag No"
                                      onChange={e => {
                                        setBagNo(e.target.value)
                                      }}
                                    />
                                    <Button onClick={() => handleEditBagNo(item.customer._id)} color="primary" className="btn-sm checkbtns">
                                      {
                                        editBagNoLoader == item.customer._id ? (
                                          <div className="loaderOfSave">
                                            <div className="spinner-border m-1" role="status">
                                              <span className="sr-only">Loading...</span>
                                            </div>
                                          </div>
                                        ) : <i className="bx bx-check"></i>
                                      }
                                    </Button>
                                    <Button onClick={() => setEditableBag(0)} className="btn-sm bg-white closebtns">
                                      <i className="bx bx-x"></i>
                                    </Button>
                                  </div>
                                ) : (
                                  <h6><i className="fas fa-shopping-bag bgltblue"></i> Bag : {item?.customer?.bagNo}&nbsp;
                                    <Button onClick={() => setEditableBag(item._id)} color="primary" className="btn-sm">
                                      Edit
                                    </Button>
                                  </h6>
                                )
                                : <span className="bg-danger d-inline-block p-1 px-2 external-event fc-event text-white text-center mb-2 rounded">No Plan</span>
                              }

                              {
                                error.bagNo && editableBag === item._id ? (
                                  <Label style={{ color: "red" }}>{error.bagNo}</Label>
                                ) : null
                              }

                              <h6>Bill : {(item?.bills[0]?.billNo) ? item?.bills[0]?.billNo : "N/A"}</h6>
                              <h6>Total : {item?.grandTotal ? item?.grandTotal.toFixed(2) + " SR" : item?.total ? item?.total + " SR" : " SR"}</h6>
                              <h6>Customer note</h6>
                              <Input type="textarea" defaultValue={item?.customer?.customer_care_note} id="textarea" rows="3" placeholder="Enter note"
                                onChange={e => {
                                  setOrderNote(e.target.value)
                                }}
                              />
                              {
                                error?.orderNote && saveButtonLoader == item._id && <h6 className="m-0" style={{ color: "red" }}>{error.orderNote}</h6>
                              }
                              <Button
                                type="submit"
                                color="primary"
                                className="btn-sm mt-2 loader-custom save-btn-right"
                                onClick={() => handleOrderNote(item?.customer?._id)}
                              >
                                {
                                  saveButtonLoader == item._id ? (
                                    <div className="loaderOfSave">
                                      <div className="spinner-border m-1" role="status">
                                        <span className="sr-only">Loading...</span>
                                      </div>
                                    </div>
                                  ) : "Save"
                                }
                              </Button>
                            </Col>
                          </Row>
                          <div className="mb-4 text-center">
                            {
                              item?.customer?.bagSettings ? (
                                <Button className="btn-sm" onClick={() => handleBagDetail(item)}>
                                  Bag Detail
                                </Button>
                              ) : null
                            }

                            <Button color="info" onClick={() => handleMoveToNewOrder(item._id)} className="btn-sm ms-2">
                              {
                                moveButtonLoader == item._id ? (
                                  <div className="loaderOfSave">
                                    <div className="spinner-border m-1" role="status">
                                      <span className="sr-only">Loading...</span>
                                    </div>
                                  </div>
                                ) : "Move to New Order"
                              }
                            </Button>
                          </div>
                          {
                            item?.location?.type ? (
                              <div className="d-flex position-absolute bottom-0 start-0">
                                <div className="position-btn">
                                  {
                                    item.location.type == "apartment" ? (
                                      <a className="btn btn-warning btn-sm">Apartment</a>
                                    ) : (
                                      <a className="btn btn-primary btn-sm">Villa</a>
                                    )
                                  }
                                </div>
                              </div>
                            ) : null
                          }
                        </div>
                      </div>
                    )
                  })
              }

              <div className="align-items-md-center row">
                <div className="inner-custom-pagination d-flex col">
                  {/* <div className="d-inline">
                  <span className="react-bs-table-sizePerPage-dropdown dropdown" style={{ visibility: "visible" }}>
                    <button id="pageDropDown" type="button" className="btn btn-default btn-secondary dropdown-toggle" data-toggle="dropdown" aria-expanded="false">
                      10 <span><span className="caret"></span></span>
                    </button>
                    <ul className="dropdown-menu " role="menu" aria-labelledby="pageDropDown">
                      <li role="presentation" className="dropdown-item">
                        <a href="#" tabIndex="-1" role="menuitem" data-page="10">10</a>
                      </li>
                      <li role="presentation" className="dropdown-item">
                        <a href="#" tabIndex="-1" role="menuitem" data-page="25">25</a>
                      </li>
                      <li role="presentation" className="dropdown-item">
                        <a href="#" tabIndex="-1" role="menuitem" data-page="30">30</a>
                      </li>
                      <li role="presentation" className="dropdown-item">
                        <a href="#" tabIndex="-1" role="menuitem" data-page="50">50</a>
                      </li>
                    </ul>
                  </span>
                </div> */}
                  <div className="text-md-right ms-auto">
                    <ul className="pagination react-bootstrap-table-page-btns-ul">
                      {
                        page > 5 ? (
                          <li className="page-item" title="next page">
                            <a href="#" onClick={() => setPage(0)} className="page-link">First</a>
                          </li>
                        ) : null
                      }
                      {
                        [...Array(Math.ceil(totalRecord / 20)).keys()].map(element =>
                          (page >= element - 5 && page <= element + 5) ?
                            (
                              <li key={element} className={classNames("page-item",
                                { "active": element == page }
                              )
                              } title="1">
                                <a href="#" onClick={() => setPage(element)} className="page-link">{element + 1}</a>
                              </li>
                            ) : null
                        )
                      }
                      {
                        page < Math.ceil(totalRecord / 20) - 6 ? (
                          <li className="page-item" title="next page">
                            <a href="#" onClick={() => setPage(Math.ceil(totalRecord / 20) - 1)} className="page-link">Last</a>
                          </li>
                        ) : null
                      }

                      {/* <li className="page-item" title="2">
                      <a href="#" className="page-link">2</a>
                    </li>
                    <li className="page-item" title="3">
                      <a href="#" className="page-link">3</a>
                    </li> */}
                      {/* <li className="page-item" title="next page">
                      <a href="#" className="page-link">&gt;</a>
                    </li> */}
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <Modal
              size="md"
              isOpen={bagDetailModal}
              toggle={() => setBagDetailModal(false)}
              centered={true}
            >
              <div className="modal-header">
                <h5 className="modal-title mt-0" id="myModalLabel">
                  <span >
                    Bag Detail
                  </span>
                </h5>
                <button
                  type="button"
                  onClick={() => setBagDetailModal(false)}
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body">
                <Row>
                  <Col lg="12">
                    <div className="col-sm-12">
                      {bagDetail?.length ? bagDetail.split("\n").map((element, index) => {
                        return (
                          <p key={index} className="text-muted m-b-15 text-center">
                            {element}
                          </p>
                        )
                      }) : null
                      }
                    </div>
                  </Col>
                </Row>
              </div>
            </Modal>
          </div>
        </div >

      </Container >
    </div >
  )

}


export default CancelOrders;




