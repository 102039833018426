/* eslint-disable react/prop-types */
import React from "react"
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Input,
  FormGroup,
  Label,
  InputGroupText,
  InputGroup,
  FormFeedback,
} from "reactstrap"
import PropTypes from "prop-types"
import { useFormik } from "formik"
import Switch from "react-switch"
import * as Yup from "yup"
import { AvField, AvForm } from "availity-reactstrap-validation"

const Offsymbol = () => {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100%",
        fontSize: 12,
        color: "#fff",
        paddingRight: 2,
      }}
    >
      {" "}
      Off
    </div>
  )
}

const OnSymbol = () => {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100%",
        fontSize: 12,
        color: "#fff",
        paddingRight: 2,
      }}
    >
      {" "}
      On
    </div>
  )
}

const ServiceEditModal = props => {
  const onAction = () => {
    props.onAction(formik.values)
    props.toggle()
  }

  const formik = useFormik({
    initialValues: {
      nameEn: "",
      nameAr: "",
      serviceCode: null,
      serviceTime: 0,
      image: null,
      status: true,
    },
    validationSchema: Yup.object({
      nameEn: Yup.string().required("Required"),
      nameAr: Yup.string().required("Required"),
      serviceCode: Yup.string().required("Required"),
      serviceTime: Yup.number().required("Required"),
      file: Yup.mixed(),
      status: Yup.boolean(),
    }),
  })

  React.useEffect(() => {
    if (props.service && props.isEditing) {
      formik.setValues({
        nameEn: props.service?.nameEn,
        nameAr: props.service?.nameAr,
        serviceCode: props.service?.serviceCode,
        serviceTime: props.service?.serviceTime,
        status: props.service?.status,
      })
    } else {
      formik.resetForm()
    }
  }, [props.isOpen])

  return (
    <Modal isOpen={props.isOpen} toggle={props.toggle} {...props.args}>
      <ModalHeader toggle={props.toggle}>
        {props.isEditing ? "Edit Service" : "Add Service"}
      </ModalHeader>

      <ModalBody>
        <AvForm>
          <FormGroup className="mb-3">
            <Label>Name En</Label>
            <Input
              id="nameEn"
              type="text"
              value={formik.values.nameEn}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              invalid={formik.touched.nameEn && formik.errors.nameEn}
            />
            <FormFeedback>{formik.errors.nameEn}</FormFeedback>
          </FormGroup>

          <FormGroup className="mb-3">
            <Label>Name Ar</Label>
            <Input
              id="nameAr"
              type="text"
              value={formik.values.nameAr}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              invalid={formik.touched.nameAr && formik.errors.nameAr}
            />
            <FormFeedback>{formik.errors.nameAr}</FormFeedback>
          </FormGroup>

          <FormGroup className="mb-3">
            <Label>Service Code</Label>
            <Input
              id="serviceCode"
              type="text"
              value={formik.values.serviceCode}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              invalid={formik.touched.serviceCode && formik.errors.serviceCode}
              disabled={props.isEditing}
            />
            <FormFeedback>{formik.errors.serviceCode}</FormFeedback>
          </FormGroup>

          <Label>Service Time</Label>
          <InputGroup className="mb-3">
            <Input
              id="serviceTime"
              type="number"
              value={formik.values.serviceTime}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              invalid={formik.touched.serviceTime && formik.errors.serviceTime}
            />
            <InputGroupText>Hours</InputGroupText>
            <FormFeedback>{formik.errors.serviceTime}</FormFeedback>
          </InputGroup>

          <div className="mb-3">
            <label>Image </label>
            <AvField
              type="file"
              onChange={e => {
                formik.setFieldValue("image", e.target.files[0])
              }}
              className="form-control"
              id="horizontal-firstname-Input"
              placeholder="Image"
              name="image"
              errorMessage="Please Upload Image"
            />
            {props.service?.imageURL ? (
              <img src={props.service?.imageURL} className="mt-2" />
            ) : null}
          </div>

          <Label style={{ marginRight: "10px" }}>Status</Label>
          <Switch
            uncheckedIcon={<Offsymbol />}
            checkedIcon={<OnSymbol />}
            onColor="#626ed4"
            onChange={() =>
              formik.setFieldValue("status", !formik.values.status)
            }
            checked={formik.values.status}
          />
        </AvForm>
      </ModalBody>
      <ModalFooter>
        <Button
          color="primary"
          onClick={onAction}
          disabled={!formik.isValid || !formik.dirty}
        >
          Save
        </Button>
        <Button color="secondary" onClick={props.toggle}>
          Close
        </Button>
        {/* {JSON.stringify(formik)} */}
      </ModalFooter>
    </Modal>
  )
}

ServiceEditModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onAction: PropTypes.func.isRequired,
  toggle: PropTypes.func.isRequired,
  args: PropTypes.object,
  service: PropTypes.object,
  isEditing: PropTypes.bool,
}

export default React.memo(ServiceEditModal)
