import { call, put, takeEvery } from "redux-saga/effects"
import {
  apiSuccess,
  apiFail,
  clearErrorMsg
} from "./actions"
import {
  CREATE_ROLE, CREATE_ROLE_SUCCESS, CREATE_ROLE_FAIL, SEARCH_PERMISSION, ALL_MODULES, 
  UPDATE_PERMISSION, CREATE_MODULE, UPDATE_MODULE, CREATE_MODULE_FAIL, CREATE_MODULE_SUCCESS, 
  UPDATE_MODULE_FAIL, UPDATE_MODULE_SUCCESS, UPDATE_PERMISSION_SUCCESS, 
  UPDATE_PERMISSION_FAIL, 
  CLEAR_ERROR_MSG,
  GET_ALL_PERMISSIONS 
} from "./actionTypes"
//Include Both Helper File with needed methods
import { createRoles, getPermission, getAllModules, editPermission, createModule, editModule, getAllPermission } from "helpers/fakebackend_helper"

function* createRole({ payload: record }) {
  try {
    const response = yield call(createRoles, record)
    if(response.statusCode == 200){
      yield put(apiSuccess(CREATE_ROLE_SUCCESS, response.record))
    }
    else{
      yield put(apiFail(CREATE_ROLE_FAIL, response.msg))
    }
  } catch (error) {
    yield put(apiFail(CREATE_ROLE_FAIL, error?.msg))
  }
}

function* fetchPermission({ payload: record }) {
  try {
    const response = yield call(getPermission, record)
    yield put(apiSuccess(SEARCH_PERMISSION, response.record))
  } catch (error) {
    yield put(apiFail(error))
  }
}

function* fetchAllPermission({ payload: record }) {
  try {
    const response = yield call(getAllPermission, record)
    yield put(apiSuccess(GET_ALL_PERMISSIONS, response.record))
  } catch (error) {
    yield put(apiFail(error))
  }
}

function* fetchAllModules({ payload: record }) {
  try {
    const response = yield call(getAllModules, record)
    yield put(apiSuccess(ALL_MODULES, response.record))
  } catch (error) {
    yield put(apiFail(error))
  }
}

function* updatePermission({ payload: record }) {
  try {
    const response = yield call(editPermission, record)
    if(response.statusCode == 200){
      yield put(apiSuccess(UPDATE_PERMISSION_SUCCESS))
    }
    else if(response.statusCode == 400){
      yield put(apiFail(UPDATE_PERMISSION_FAIL, response.msg))
    }
  } catch (error) {
    yield put(apiFail(UPDATE_PERMISSION_FAIL, error))
  }
}

function* updateModule({ payload: record }) {
  try {
    const response = yield call(editModule, record)
    yield put(apiSuccess(UPDATE_MODULE_SUCCESS, record))
  } catch (error) {
    yield put(apiFail(UPDATE_MODULE_FAIL, error))
  }
}

function* createModules({ payload: record }) {
  try {
    const response = yield call(createModule, record)
    if(response.statusCode == 200){
      yield put(apiSuccess(CREATE_MODULE_SUCCESS, response.record))
    }
    else{
      yield put(apiFail(CREATE_MODULE_FAIL, response.msg))
    }
  } catch (error) {
    yield put(apiFail(CREATE_MODULE_FAIL, error))
  }
}

function* _clearErrorMsg() {
  yield put(clearErrorMsg())
}

function* permissionSaga() {
  yield takeEvery(SEARCH_PERMISSION, fetchPermission)
  yield takeEvery(GET_ALL_PERMISSIONS, fetchAllPermission)
  yield takeEvery(ALL_MODULES, fetchAllModules)
  yield takeEvery(UPDATE_PERMISSION, updatePermission)
  yield takeEvery(UPDATE_MODULE, updateModule)
  yield takeEvery(CREATE_MODULE, createModules)
  yield takeEvery(CREATE_ROLE, createRole)
  yield takeEvery(CLEAR_ERROR_MSG, _clearErrorMsg)
}

export default permissionSaga
