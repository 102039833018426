import { call, put, takeEvery } from "redux-saga/effects"
import { postAddUser } from "helpers/userHelper"
import toastr from "toastr";
import "toastr/build/toastr.min.css";

import {
  GET_DRIVER_LIST,
  GET_DRIVER_LIST_SUCCESS,
  GET_DRIVER_LIST_FAIL,
  ADD_NEW_DRIVER,
  UPDATE_DRIVER,
  DELETE_DRIVER,
  GET_DRIVER_LOCATIONS
} from "./actionTypes"

//Include Both Helper File with needed methods
import {
  getDrivers,
  addDrivers,
  updateDrivers,
  deleteDrivers,
  getDriverLocation
} from "helpers/fakebackend_helper"

import {
  getDriverListSuccess,
  getDriverListFail,
  addNewDriverSuccess,
  addNewDriverFail,
  updateDriverSuccess,
  updateDriverFail,
  deleteDriverSuccess,
  deleteDriverFail,
  getDriverLocationsSuccess,
  getDriverLocationsFail
} from "./actions"


// function* fetchDriverList({ payload: record }) {
//   try {
//     const response = yield call(getDrivers, record)
//     yield put(apiSuccess(GET_DRIVER_LIST_SUCCESS, response.record))
//   } catch (error) {
//     yield put(apiFail(error))
//   }
// }


function* fetchDriverList({ payload: record }) {
  try {
    const response = yield call(getDrivers, record)

    yield put(getDriverListSuccess(response))
  } catch (error) {
    yield put(getDriverListFail(error))
  }
}

function* AddNewDriver({ payload: data }) {
  try {
    const response = yield call(addDrivers, data);
    // let registerApiData = { ...data.registerData, driver: response.record._id };
    // console.log("registerApiData", response);
    // yield call(postAddUser, registerApiData);
    toastr.success("Captain has been added", "Captain");
    yield put(addNewDriverSuccess(response));
  } catch (error) {
    toastr.error("Captain add failed", "Captain");
    yield put(addNewDriverFail(error))
  }
}

function* UpdateNewDriver({ payload: data }) {
  try {
    const response = yield call(updateDrivers, data)
    if (response?.statusCode != "200") {
      toastr.error("Unable to Update Captain", "Captain");
    } else {
      toastr.success("Captain has been Updated", "Captain");
      yield put(updateDriverSuccess(data))
    }
  } catch (error) {
    toastr.error("Unable to Update Captain", "Captain");
    yield put(updateDriverFail(error))
  }
}

function* deleteNewDriver({ payload: data }) {
  try {
    const response = yield call(deleteDrivers, data)
    if (response?.statusCode != "200") {
      toastr.error("Unable to Delete Captain", "Captain not Deleted");
    } else {
      toastr.success("Captain has been Deleted", "Captain Deleted");
      yield put(deleteDriverSuccess(data))
    }
  } catch (error) {
    yield put(deleteDriverFail(error))
  }
}

function* fetchDriverLocations({ payload: record }) {
  try {
    const response = yield call(getDriverLocation, record)
    yield put(getDriverLocationsSuccess(response))
  } catch (error) {
    yield put(getDriverLocationsFail(error))
  }
}

function* driversSaga() {
  yield takeEvery(GET_DRIVER_LIST, fetchDriverList)
  yield takeEvery(ADD_NEW_DRIVER, AddNewDriver)
  yield takeEvery(UPDATE_DRIVER, UpdateNewDriver)
  yield takeEvery(DELETE_DRIVER, deleteNewDriver)
  yield takeEvery(GET_DRIVER_LOCATIONS, fetchDriverLocations)
}

export default driversSaga
