import axios from "axios"
import { del, get, post, put } from "./api_helper"
import * as url from "./url_helper"


const fetchReasonsList = (data) => post(`${url.GET_REASONS}`, data)
const createReason = (data) => post(`${url.CREATE_REASON}`, data)
const updateReason = (data) => post(`${url.UPDATE_REASON}`, data)

export {
    fetchReasonsList,
    createReason,
    updateReason  
}