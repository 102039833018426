import PropTypes from 'prop-types';
import React from 'react';
import Datamaps from 'datamaps/dist/datamaps.sau'
import customMarkers from "./datamaps.markers"

import logoOnline from '../../assets/images/location_blue.png'
import logoOffline from '../../assets/images/location.png'

const MAP_CLEARING_PROPS = [
	'height', 'scope', 'setProjection', 'width'
];

const propChangeRequiresMapClear = (oldProps, newProps) => {
	return MAP_CLEARING_PROPS.some((key) =>
		oldProps[key] !== newProps[key]
	);
};

export default class Datamap extends React.Component {
  

	static propTypes = {
		arc: PropTypes.array,
		arcOptions: PropTypes.object,
		bubbleOptions: PropTypes.object,
		bubbles: PropTypes.array,
		data: PropTypes.object,
		graticule: PropTypes.bool,
		height: PropTypes.any,
		labels: PropTypes.bool,
		responsive: PropTypes.bool,
		style: PropTypes.object,
		updateChoroplethOptions: PropTypes.object,
		width: PropTypes.any
	};

	constructor(props) {
		super(props);
		this.resizeMap = this.resizeMap.bind(this);
    this.container = React.createRef();
	}

	componentDidMount() {
    
    this.clear();
    
		if (this.props.responsive) {
			window.addEventListener('resize', this.resizeMap);
		}
		this.drawMap();
	}

	// componentWillReceiveProps(newProps) {
	// 	if (propChangeRequiresMapClear(this.props, newProps)) {
	// 		this.clear();
	// 	}
	// }

	componentDidUpdate() {
    
    this.clear();
    
		this.drawMap();
	}

	componentWillUnmount() {
		this.clear();
		if (this.props.responsive) {
			window.removeEventListener('resize', this.resizeMap);
		}
	}

	clear() {
		
    if(this.container.current.childNodes !== undefined){
      for (const child of Array.from(this.container.current.childNodes)) {
        this.container.current.removeChild(child);
      }
      delete this.map;
    }
	}

	drawMap() {
		const {
			arc,
			arcOptions,
			bubbles,
			bubbleOptions,
			data,
			graticule,
			labels,
			updateChoroplethOptions,
			...props
		} = this.props;

		let map = this.map;

		if (!map) {
			map = this.map = new Datamaps({
				...props,
				data,
				element: this.container.current
			});
		} else {
			map.updateChoropleth(data, updateChoroplethOptions);
		}

		if (arc) {
			map.arc(arc, arcOptions);
		}

		if (bubbles) {
			map.bubbles(bubbles, bubbleOptions);
		}

		if (graticule) {
			map.graticule();
		}

		if (labels) {
			map.labels();
		}

    map.addPlugin('markers', customMarkers);

    var options = {
      fillOpacity: 1,
      popupOnHover: true,
      icon: {
        url: logoOnline,
        width: 20,
        height: 20
      }
    };
    //25.23058916349024, 43.46098009253602
    if(props.markers){
      map.markers(props.markers, options);
    }

    options.icon.url = logoOffline;
    // if(props.offlineMarkers){
    //   map.markers(props.offlineMarkers, options);
    // }

    

	}

	resizeMap() {
		this.map.resize();
	}

	render() {
		const style = {
			height: '100%',
			position: 'relative',
			width: '100%',
			...this.props.style
		};

		return <div ref={this.container} style={style} />;
	}

}
