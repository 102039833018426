import {
  GET_SLIDER,
  GET_SLIDER_SUCCESS,
  GET_SLIDER_FAIL,
  ADD_NEW_SLIDER,
  ADD_NEW_SLIDER_SUCCESS,
  ADD_NEW_SLIDER_FAIL,
  UPDATE_SLIDER,
  UPDATE_SLIDER_SUCCESS,
  UPDATE_SLIDER_FAIL,
  DELETE_SLIDER,
  DELETE_SLIDER_SUCCESS,
  DELETE_SLIDER_FAIL
} from "./actionTypes"


// GET SLIDER

export const getSlider = (data) => ({
  type: GET_SLIDER,
  payload: data
})

export const getSliderSuccess = data => ({
  type: GET_SLIDER_SUCCESS,
  payload: data,
})

export const getSliderFail = error => ({
  type: GET_SLIDER_FAIL,
  payload: error,
})


// ADD NEW SLIDER

export const addNewSlider = (data) => ({
  type: ADD_NEW_SLIDER,
  payload: data
})

export const addNewSliderSuccess = data => ({
  type: ADD_NEW_SLIDER_SUCCESS,
  payload: data,
})

export const addNewSliderFail = error => ({
  type: ADD_NEW_SLIDER_FAIL,
  payload: error,
})


// UPDATE SLIDER

export const updateSlider = (data) => ({
  type: UPDATE_SLIDER,
  payload: data
})

export const updateSliderSuccess = data => ({
  type: GET_SLIDER,
  payload: {},
})

export const updateSliderFail = error => ({
  type: UPDATE_SLIDER_FAIL,
  payload: error,
})

// DELETE SLIDER

export const deleteSlider = (data) => ({
  type: DELETE_SLIDER,
  payload: data
})

export const deleteSliderSuccess = data => ({
  type: DELETE_SLIDER_SUCCESS,
  payload: data,
})

export const deleteSliderFail = error => ({
  type: DELETE_SLIDER_FAIL,
  payload: error,
})