/* PRODUCTS */
export const GET_PRODUCTS = "GET_PRODUCTS"
export const GET_PRODUCTS_SUCCESS = "GET_PRODUCTS_SUCCESS"
export const GET_PRODUCTS_FAIL = "GET_PRODUCTS_FAIL"

/* PRODUCT DETAILS */
export const GET_PRODUCT_DETAIL = "GET_PRODUCT_DETAIL"
export const GET_PRODUCT_DETAIL_SUCCESS = "GET_PRODUCT_DETAIL_SUCCESS"
export const GET_PRODUCT_DETAIL_FAIL = "GET_PRODUCT_DETAIL_FAIL"

export const GET_PRODUCT_COMMENTS = "GET_PRODUCT_COMMENTS"
export const GET_PRODUCT_COMMENTS_SUCCESS = "GET_PRODUCT_COMMENTS_SUCCESS"
export const GET_PRODUCT_COMMENTS_FAIL = "GET_PRODUCT_COMMENTS_FAIL"

export const ON_LIKE_COMMENT = "ON_LIKE_COMMENT"
export const ON_LIKE_COMMENT_SUCCESS = "ON_LIKE_COMMENT_SUCCESS"
export const ON_LIKE_COMMENT_FAIL = "ON_LIKE_COMMENT_FAIL"

export const ON_LIKE_REPLY = "ON_LIKE_REPLY"
export const ON_LIKE_REPLY_SUCCESS = "ON_LIKE_REPLY_SUCCESS"
export const ON_LIKE_REPLY_FAIL = "ON_LIKE_REPLY_FAIL"

export const ON_ADD_REPLY = "ON_ADD_REPLY"
export const ON_ADD_REPLY_SUCCESS = "ON_ADD_REPLY_SUCCESS"
export const ON_ADD_REPLY_FAIL = "ON_ADD_REPLY_FAIL"

export const ON_ADD_COMMENT = "ON_ADD_COMMENT"
export const ON_ADD_COMMENT_SUCCESS = "ON_ADD_COMMENT_SUCCESS"
export const ON_ADD_COMMENT_FAIL = "ON_ADD_COMMENT_FAIL"

/* ORDERS */
export const GET_ORDERS = "GET_ORDERS"
export const GET_ORDERS_SUCCESS = "GET_ORDERS_SUCCESS"
export const GET_ORDERS_FAIL = "GET_ORDERS_FAIL"


export const GET_CLOTHLIST = "GET_CLOTHLIST"
export const GET_CLOTHLIST_SUCCESS = "GET_CLOTHLIST_SUCCESS"
export const GET_CLOTHLIST_FAIL = "GET_CLOTHLIST_FAIL"

export const ADD_PRICE = "ADD_PRICE"
export const ADD_PRICE_SUCCESS = "ADD_PRICE_SUCCESS"
export const ADD_PRICE_FAIL = "ADD_PRICE_FAIL"

export const UPDATE_PRICE = "UPDATE_PRICE"
export const UPDATE_PRICE_SUCCESS = "UPDATE_PRICE_SUCCESS"
export const UPDATE_PRICE_FAIL = "UPDATE_PRICE_FAIL"

export const BULK_UPDATE_PRICE = "BULK_UPDATE_PRICE"

export const DELETE_PRICE = "DELETE_PRICE"
export const DELETE_PRICE_SUCCESS = "DELETE_PRICE_SUCCESS"
export const DELETE_PRICE_FAIL = "DELETE_PRICE_FAIL"

// Pricelist

export const GET_PRICELIST = "GET_PRICELIST"
export const GET_PRICELIST_SUCCESS = "GET_PRICELIST_SUCCESS"
export const GET_PRICELIST_FAIL = "GET_PRICELIST_FAIL"

//pricelist dropdown list

export const GET_LAUNDRIES = "GET_LAUNDRIES"
export const GET_LAUNDRIES_SUCCESS = "GET_LAUNDRIES_SUCCESS"
export const GET_LAUNDRIES_FAIL = "GET_LAUNDRIES_FAIL"


// pricelist dropdown list area
export const GET_AREA = "GET_AREA"
export const GET_AREA_SUCCESS = "GET_AREA_SUCCESS"
export const GET_AREA_FAIL = "GET_AREA_FAIL"

export const GET_B2B_CUSTOMER = "GET_B2B_CUSTOMER"
export const GET_B2B_CUSTOMER_SUCCESS = "GET_B2B_CUSTOMER_SUCCESS"
export const GET_B2B_CUSTOMER_FAIL = "GET_B2B_CUSTOMER_FAIL"

//add new area
export const ADD_NEW_AREA = "ADD_NEW_AREA"
export const ADD_NEW_AREA_SUCCESS = "ADD_NEW_AREA_SUCCESS"
export const ADD_NEW_AREA_FAIL = "ADD_NEW_AREA_FAIL"

//UPDATE  area
export const UPDATE_AREA = "UPDATE_AREA"
export const UPDATE_AREA_SUCCESS = "UPDATE_AREA_SUCCESS"
export const UPDATE_AREA_FAIL = "UPDATE_AREA_FAIL"



export const GET_UPDATE_AREA = "GET_UPDATE_AREA"




/**
 * add ORDER
 */
export const ADD_NEW_ORDER = "ADD_NEW_ORDER"
export const ADD_ORDER_SUCCESS = "ADD_ORDER_SUCCESS"
export const ADD_ORDER_FAIL = "ADD_ORDER_FAIL"

/**
 * Edit ORDER
 */
export const UPDATE_ORDER = "UPDATE_ORDER"
export const UPDATE_ORDER_SUCCESS = "UPDATE_ORDER_SUCCESS"
export const UPDATE_ORDER_FAIL = "UPDATE_ORDER_FAIL"

/**
 * Delete ORDER
 */
export const DELETE_ORDER = "DELETE_ORDER"
export const DELETE_ORDER_SUCCESS = "DELETE_ORDER_SUCCESS"
export const DELETE_ORDER_FAIL = "DELETE_ORDER_FAIL"

/* CART DATA */
export const GET_CART_DATA = "GET_CART_DATA"
export const GET_CART_DATA_SUCCESS = "GET_CART_DATA_SUCCESS"
export const GET_CART_DATA_FAIL = "GET_CART_DATA_FAIL"

/* CUSTOMERS */
export const GET_CUSTOMERS = "GET_CUSTOMERS"
export const GET_CUSTOMERS_SUCCESS = "GET_CUSTOMERS_SUCCESS"
export const GET_CUSTOMERS_FAIL = "GET_CUSTOMERS_FAIL"

/**
 * add CUSTOMER
 */
export const ADD_NEW_CUSTOMER = "ADD_NEW_CUSTOMER"
export const ADD_CUSTOMER_SUCCESS = "ADD_CUSTOMER_SUCCESS"
export const ADD_CUSTOMER_FAIL = "ADD_CUSTOMER_FAIL"

/**
 * Edit CUSTOMER
 */
export const UPDATE_CUSTOMER = "UPDATE_CUSTOMER"
export const UPDATE_CUSTOMER_SUCCESS = "UPDATE_CUSTOMER_SUCCESS"
export const UPDATE_CUSTOMER_FAIL = "UPDATE_CUSTOMER_FAIL"

/**
 * Delete CUSTOMER
 */
export const DELETE_CUSTOMER = "DELETE_CUSTOMER"
export const DELETE_CUSTOMER_SUCCESS = "DELETE_CUSTOMER_SUCCESS"
export const DELETE_CUSTOMER_FAIL = "DELETE_CUSTOMER_FAIL"

/* SHOPS */
export const GET_SHOPS = "GET_SHOPS"
export const GET_SHOPS_SUCCESS = "GET_SHOPS_SUCCESS"
export const GET_SHOPS_FAIL = "GET_SHOPS_FAIL"

// Driver
export const GET_DRIVER = "GET_DRIVER"
export const GET_DRIVER_SUCCESS = "GET_DRIVER_SUCCESS";
export const GET_DRIVER_FAIL = "GET_DRIVER_FAIL"

export const GET_TIMESLOT = "GET_TIMESLOT"
export const GET_TIMESLOT_FAIL = "GET_TIMESLOT_FAIL"
export const GET_TIMESLOT_SUCCESS = 'GET_TIMESLOT_SUCCESS'
