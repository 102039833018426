import { AvField, AvForm } from 'availity-reactstrap-validation'
import React, { useEffect, useState } from 'react'
import MetaTags from "react-meta-tags";
import Breadcrumbs from "components/Common/Breadcrumb";
import { Card, CardBody, Col, Container, Modal, ModalBody, ModalHeader, Nav, NavItem, NavLink, Row, TabContent, TabPane } from 'reactstrap';
import classnames from "classnames";
import { useDispatch, useSelector } from 'react-redux';
import { getAnnouncement, getArealist } from 'store/actions';
import AnnoucementText from './Annoucement';
import MultiSelect from "react-multiple-select-dropdown-lite";
import Switch from "react-switch"
import { CREATE_ANNOUNCEMENT, UPDATE_ANNOUNCEMENT } from 'helpers/url_helper';
import { postWeb } from 'helpers/api_helper';
import toastr from "toastr";

const Nosymbol = () => {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100%",
        fontSize: 12,
        color: "#fff",
        paddingRight: 2
      }}
    >
      {" "}
      No
    </div>
  )
}

const YesSymbol = () => {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100%",
        fontSize: 12,
        color: "#fff",
        paddingRight: 2
      }}
    >
      {" "}
      Yes
    </div>
  )
}
const Annoucements = () => {
  const dispatch = useDispatch();
  const {Annoucement,areaList,users} = useSelector(state => ({
    users: state.Users.users,
    Annoucement: state.Annoucement.annoucements,
    areaList: state.ecommerce.arealist,
  }));

  useEffect(() => {
    if (users.length > 0) {
      setUserData(users[0]);
    }
  }, [users])
  useEffect(() => {
    const data = {}
    // dispatch(searchUsers({ webPermissions: true }))
    dispatch(getAnnouncement())
    dispatch(getArealist({}));
  }, [])

  useEffect(() => {
    const _customerAnnouncements = [], _captainAnnouncements = [];

    Annoucement?.forEach((annoucement) => {
      if(annoucement?.type == "customer"){
        _customerAnnouncements.push(annoucement);
      }
      else if(annoucement?.type == "driver"){
        _captainAnnouncements.push(annoucement);
      }
    });


    setCustomerAnnoucements(_customerAnnouncements);
    setCaptainAnnoucements(_captainAnnouncements);
    setSelectedArea([])
    setEnTitle("")
    setArTitle("")
    setEnBody("")
    setArBody("")
    setConsumer("")
    setEnabledForAll(false)


  }, [Annoucement])
 
  const toggle = () => {
    setModal(!modal);
    setadd(true)
    setEditableItem({})
  };

  const [modal, setModal] = useState(false);
  const [add, setadd] = useState(false);
  const [selectedArea, setSelectedArea] = useState([]);
  const [enabledForAll, setEnabledForAll] = useState(false);
  const [customIconActiveTab, setcustomIconActiveTab] = useState("1")
  const [notificationType, setNotificationType] = useState("");
  const [enTitle, setEnTitle] = useState("");
  const [arTitle, setArTitle] = useState("");
  const [enBody, setEnBody] = useState("");
  const [arBody, setArBody] = useState("");
  const [enButtonText, setenButtonText] = useState("Explore");
  const [arButtonText, setarButtonText] = useState("يستكشف");
  const [status, setStatus] = useState(true);
  const [consumer, setConsumer] = useState()
  const [isEdit, setIsEdit] = useState(false)
  const [editableItem, setEditableItem] = useState({})
  const [error, setError] = useState({})
  const [userData, setUserData] = useState({});
  const [customerAnnoucements, setCustomerAnnoucements] = useState([]);
  const [captainAnnoucements, setCaptainAnnoucements] = useState([]);

  const toggleIconCustom = tab => {
    if (customIconActiveTab !== tab) {
      setcustomIconActiveTab(tab)
    }
  }
  const handleStatus =(row)=>{
    let data = { ...row}
    postWeb(UPDATE_ANNOUNCEMENT, {
      _id: data?._id, status:data?.status
    }).then((response) => {
      if (response && response.statusCode == 200) {
        toastr.success(`Annoucement Status  Updated Successfully`, `Success`);
        dispatch(getAnnouncement())
      }
      else {
        toastr.error(`Unable to update annoucement Status`, `Error`);
      }
    });
  }
  const submitNotificationText = () => {
    const _id = editableItem && editableItem._id;
    const error ={}
    if(enabledForAll == false && selectedArea.length < 1){
      error['area'] = "Area is required";
      setError(error)
      return 
    }
   
      const data = {
        enTitle: enTitle,
        enableAllArea:enabledForAll,
        enDescription: enBody,
        arTitle: arTitle,
        arDescription: arBody,
        type:consumer,
        areas: selectedArea,
        buttonEnText:enButtonText,
        buttonArText:arButtonText,
        status:status
      };
      if(editableItem && editableItem?.status){
        data['status'] = status
      }
      if(data?.enableAllArea == true){
        data['areas'] = []
      }
   
    if (isEdit && _id && !add) {
      postWeb(UPDATE_ANNOUNCEMENT, {
        _id: _id, ...data
      }).then((response) => {
        if (response && response.statusCode == 200) {
          toastr.success(`Annoucement Text Updated Successfully`, `Success`);
          dispatch(getAnnouncement())
        }
        else {
          toastr.error(`Unable to update annoucement text`, `Error`);
        }
      });
    } else {
      data['status']=status
      postWeb(CREATE_ANNOUNCEMENT, data).then((response) => {
        if(response && response.statusCode == 200){
          toastr.success(`Announcement Text Created Successfully`, `Success`);
          // setadd(false)
          dispatch(getAnnouncement())
        }
        else{
          toastr.error(`Unable to update Announcement text`, `Error`);
        }
      });

    }
    toggle()
  }

  const handleEditAnnouce = (item, type) => {
    setadd(false)
   
    if(type == "edit"){
      setModal(true)
      setIsEdit(true);
      setEditableItem(item)
      setEnabledForAll(item?.enableAllArea)
       setSelectedArea(item?.areas)
       // setNotificationType(item.notificationType)
       setArTitle(item?.arTitle)
       setEnTitle(item?.enTitle)
       setArBody(item?.arDescription)
       setEnBody(item?.enDescription)
       setConsumer(item.type)
       setStatus(item?.status ? item.status : false)
       setenButtonText(item?.buttonEnText ? item.buttonEnText :"Explore" )
       setarButtonText(item?.buttonArText ? item.buttonArText :"يستكشف")
    }
  }
  const handleAreaChange = (val) => {
    setSelectedArea(val ? val.split(",") : [])
  }
  useEffect(() => {
    if (!modal) {
      setError({})
    }
  }, [modal])
  const handleAddClick = ()=>{
    setModal(true)
    setadd(true)
    setSelectedArea([])
    setEnTitle("")
    setArTitle("")
    setEnBody("")
    setArBody("")
    setConsumer("")
    setEnabledForAll(false)
  }
  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Annoucements Texts</title>
        </MetaTags>
        <Container fluid>
          <Breadcrumbs title="Home" breadcrumbItem="Annoucement Texts" />
          <Card>
            <CardBody>
              <Nav className="icon-tab nav-justified">
                <NavItem>
                  <NavLink
                    style={{ cursor: "pointer" }}
                    className={classnames({
                      active: customIconActiveTab === "1",
                    })}
                    onClick={() => {
                      toggleIconCustom("1")
                    }}
                  >
                    <span className="d-none d-sm-block">
                      <i className="fas fa-user"></i> Customer
                    </span>
                    <span className="d-block d-sm-none">
                      <i className="fas fa-home"></i>
                    </span>
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    style={{ cursor: "pointer" }}
                    className={classnames({
                      active: customIconActiveTab === "2",
                    })}
                    onClick={() => {
                      toggleIconCustom("2")
                    }}
                  >
                    <span className="d-none d-sm-block">
                      <i className="fas fa-car-side"></i> Driver
                    </span>
                    <span className="d-block d-sm-none">
                      <i className="fas fa-car-side"></i>
                    </span>
                  </NavLink>
                </NavItem>
              </Nav>
              <TabContent
                activeTab={customIconActiveTab}
                className="p-3 text-muted"
              >
                <TabPane tabId="1">
                  <AnnoucementText
                    notificationData={customerAnnoucements}
                    userData={userData}
                    onViewClick={(item,type) => { handleEditAnnouce(item, type) }}
                    onAddClick={(type)=>{handleAddClick()}}
                    onStatusClick={(row)=>{handleStatus(row)}}
                  />
                </TabPane>
                <TabPane tabId="2">
                <AnnoucementText
                    notificationData={captainAnnoucements}
                    userData={userData}
                    onViewClick={(item,type) => { handleEditAnnouce(item,type) }}
                    onAddClick={(type)=>{handleAddClick()}}
                    onStatusClick={(row)=>{handleStatus(row)}}
                  />
                </TabPane>
              </TabContent>
            </CardBody>
          </Card>
         
          <Modal isOpen={modal} toggle={toggle} size="lg">
            <ModalHeader toggle={toggle} tag="h4">
              {!add ? "Edit Announcement" : "Add Announcement"}
            </ModalHeader>
            <ModalBody>
              <AvForm
                onValidSubmit={submitNotificationText}
              >
                <Row form>
                  <div className="row mb-2">
                    <Col className="col-12">

                    <div className="mb-3">
                        <label>User Type<span className="mandatory_feild">*</span></label>
                        <AvField
                          type="select"
                          name="consumer"
                          value={consumer}
                          errorMessage="Select Consumer"
                          className="form-control"
                          onChange={(e) => setConsumer(e.target.value)}
                          validate={{ required: { value: true } }}
                          helpMessage="">
                          <option value="">Selet User Type</option>
                          <option value="customer" selected>Customer</option>
                          <option value="driver">Driver</option>
                        </AvField>
                      </div>
                      <div className="mb-3">
                        <label style={{marginRight:"12px"}}>EnableAll Area</label>
                        <Switch
                              uncheckedIcon={<Nosymbol />}
                              checkedIcon={<YesSymbol />}
                              className="me-1 mb-sm-8 mb-2 mt-2 ml-4"
                              onColor="#626ed4"
                              onChange={() => setEnabledForAll(!enabledForAll)}
                              checked={enabledForAll}
                              height={24}
                              width={48} 
                              

                            />
                         <MultiSelect
                          defaultValue={selectedArea?.toString()}
                          className="multi-selects form-selects2 form-controlss areas-box"
                          onChange={(e) => { delete error.area; handleAreaChange(e); }}
                          options={
                            areaList?.map((item) => {
                              return { label: item.name, value: item._id }
                            })
                          }
                          disabled={enabledForAll}
                        />
                        {/* {enabledForAll == false && <span className='text-danger mt-2'>Area is requried</span>} */}
                        {
                          error?.area  && enabledForAll == false ? (
                            <span style={{ color: "red" }}>{error?.area}</span>
                          ) : null
                        }
                      </div>
      
                        <Row>
                          <Col className='col-6'>
                          <div className="mb-3">
                        <AvField
                          name="enTitle"
                          label={<div>Announcement Title (English) <span className="mandatory_feild">*</span></div>}
                          type="text"
                          errorMessage="Announcement Title in English is Required"
                          placeHolder ="Announcement Title (English)"
                          validate={{
                            required: { value: true },
                          }}
                          onChange={(e) => { setEnTitle(e.target.value) }}
                          value={enTitle}
                        />
                      </div>

                      <div className="mb-3">
                        <AvField
                          name="enBody"
                          label={<div>Announcement Description (English) <span className="mandatory_feild">*</span></div>}
                          type="text"
                          errorMessage="Announcement Description in English is Required"
                          placeHolder ="Announcement Description (English)"
                          validate={{
                            required: { value: true },
                          }}
                          onChange={(e) => { setEnBody(e.target.value) }}
                          value={enBody}
                        />
                      </div>
                      <div className="mb-3">
                        <AvField
                          name="buttonEnBody"
                          label={<div>Button Text (English)<span className="mandatory_feild">*</span></div>}
                          type="text"
                          errorMessage="Button Text is required"
                          validate={{
                            required: { value: true },
                          }}
                          onChange={(e) => { setenButtonText(e.target.value) }}
                          value={enButtonText}
                        />
                      </div></Col>
                          <Col className='col-6'>
                          <div className="mb-3">
                        <AvField
                          name="arTitle"
                          label={<div>Announcement Title (Arabic) <span className="mandatory_feild">*</span></div>}
                          type="text"
                          placeHolder ="Announcement Title (Arabic)"
                          errorMessage="Announcement Title in Arabic is Required"
                          validate={{
                            required: { value: true },
                          }}
                          style={{ direction: "rtl"}}
                          onChange={(e) => { setArTitle(e.target.value) }}
                          value={arTitle}
                        />
                      </div>

                      <div className="mb-3">
                        <AvField
                          name="arBody"
                          label={<div>Announcement Description (Arabic) <span className="mandatory_feild">*</span></div>}
                          type="text"
                          placeHolder ="Announcement Description (Arabic)"
                          errorMessage="Announcement Description in Arabic is Required"
                          validate={{
                            required: { value: true },
                          }}
                          style={{ direction: "rtl"}}
                          onChange={(e) => { setArBody(e.target.value) }}
                          value={arBody}
                        />
                      </div>
                      <div className="mb-3">
                        <AvField
                          name="buttonArBody"
                          label={<div>Button Text(Arabic) <span className="mandatory_feild">*</span></div>}
                          type="text"
                          errorMessage="Button Text is required"
                          validate={{
                            required: { value: true },
                          }}
                          style={{ direction: "rtl"}}
                          onChange={(e) => { setarButtonText(e.target.value) }}
                          value={arButtonText}
                        />
                      </div>
                          </Col>
                        </Row>   
                    </Col>
                  </div>
                </Row>
                <Row className='flex'>
                <label style={{marginRight:"12px"}}>Status </label>
                        <Switch
                              uncheckedIcon={<Nosymbol />}
                              checkedIcon={<YesSymbol />}
                              className="me-1 mb-sm-8 mb-2 mt-2 ml-4"
                              onColor="#626ed4"
                              onChange={() => setStatus(!status)}
                              checked={status}
                              height={24}
                              width={48} 
                              

                            />
                </Row>
                <Row>
                  <Col>
                    <div className="text-end">
                      <button
                        type="submit"
                        className="btn btn-success save-user"
                      >
                        Save
                      </button>
                    </div>
                  </Col>
                </Row>
              </AvForm>
            </ModalBody>
          </Modal>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default Annoucements