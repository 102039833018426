import {
  GET_REDEEM_PKGS_LIST,
  GET_REDEEM_PKG_DETAIL,
  GET_REDEEM_PKGS_LIST_SUCCESS,
  GET_REDEEM_PKGS_LIST_FAIL,
  GET_REDEEM_PKG_DETAIL_SUCCESS,
  GET_REDEEM_PKG_DETAIL_FAIL,
} from "./actionTypes"

export const getRedeemPkgsList = (data) => ({
  type: GET_REDEEM_PKGS_LIST,
  payload: data
})

export const getRedeemPkgDetail = (data) => ({
  type: GET_REDEEM_PKG_DETAIL,
  payload: data
})

export const getRedeemPkgDetailSuccess = (data) => ({
  type: GET_REDEEM_PKG_DETAIL_SUCCESS,
  payload: data
})

export const getRedeemPkgDetailFail = (data) => ({
  type: GET_REDEEM_PKG_DETAIL_FAIL,
  payload: data
})

export const getRedeemPkgsListSuccess = (data) => ({
  type: GET_REDEEM_PKGS_LIST_SUCCESS,
  payload: data
})

export const getRedeemPkgsListFail = (data) => ({
  type: GET_REDEEM_PKGS_LIST_FAIL,
  payload: data
})

