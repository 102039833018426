import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"
import MetaTags from "react-meta-tags";
import {
  Container,
  Row,
  Col,
  Button,
  Card,
  CardBody,
  Modal,
  Label,
} from "reactstrap"
import { AvForm, AvField, AvGroup, AvInput } from "availity-reactstrap-validation"
import { getSlider, addNewSlider, updateSlider, deleteSlider } from "../../store/actions"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { withTranslation } from "react-i18next"
import { useSelector, useDispatch } from "react-redux"
import { postArea } from "helpers/LaundryHelper"
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import ReactSwitch from "react-switch"
import { Offsymbol, OnSymbol } from "components/Common/Symbol"
import ReactSelect from "react-select";
import { postWeb } from "helpers/api_helper";
import toastr from "toastr" 
import "toastr/build/toastr.min.css"
import { myParseInt } from "helpers/validationHelper";



const Sequence = props => {
  const [label, setLabel] = useState("");
  const [emptyLabel, setEmptyLabel] = useState("");
  const [showLabel, setShowLabel] = useState(true);
  const [selectedItem, setSelectedItem] = useState("");

  
  
  useEffect(() => {
    setSelectedItem(props.row?.sequence);
  }, [props]);

  useEffect(() => {
    setLabel(props.row?.sequence);
    setEmptyLabel("Sequence : " + (props.row?.sequence?props.row?.sequence: 0) + " of " + props.total)  
  }, [props]);

  const showEditable = () => {
    setShowLabel(false);
  }

  const handleClose = () => {
    setShowLabel(true);
  }
  
  const handleSubmit = async () => {
    
      if(!selectedItem || selectedItem.trim() == ""){
        toastr.error("Please enter sequence ", `Required`);
        return;
      }

      if(!props.row?._id){
        toastr.error("Invalid Slider ", `Required`);
        return;
      }

      
      postWeb("slider/update", {
        _id : props.row?._id,
        sequence : selectedItem
      }).then(() => {
        
      });
      props.onUpdate.apply(null, [props.row._id, selectedItem]);
      
      toastr.success(`Sequence updated successfully`, `Sequence updated successfully`);
      
      setShowLabel(true);
      return;

  }

  const onChangeItem = (e) => {
    setSelectedItem(e.target.value);
  }

  return (
    <>
    {showLabel?
    <>
          {label ? 
            <a onClick={showEditable} className="editable editable-click">
              Sequence:  <b className="text-success">{label}</b> of {props.total}
            </a> : 
            <a onClick={showEditable} className="editable editable-click editable-empty text-danger">{emptyLabel}</a>}
        </> :
        <>
          <label className="me-2">Sequence: </label>
          <input 
            type="number"
            min={0}
            max={props?.total}
            onChange={(e) => setSelectedItem(e.target.value)}
            className="form-control"
            value={selectedItem}
            style={{width: "60px"}}
          />
          <label className="ms-2">of <strong className="text-primary">{props?.total}</strong></label>
          <div className="editable-buttons">
            <button type="submit" 
              onClick={handleSubmit}
              className="btn btn-success editable-submit btn-sm waves-effect waves-light">
                <i className="mdi mdi-check">
                </i>
            </button>
            <button 
              type="button"
              onClick={handleClose} 
              className="btn btn-danger editable-cancel btn-sm waves-effect waves-light">
              <i className="mdi mdi-close"></i>
            </button>
          </div>
        </>}
    </>
  );
};

Sequence.propTypes = {
  row: PropTypes.any,
  onUpdate: PropTypes.any,
  total: PropTypes.number
}

const Sliders = props => {
  const dispatch = useDispatch();
  const [realTimeModal, SetRealTimeModal] = useState(false);
  const [areaList, setAreaList] = useState([]);
  const [sliderName, setSliderName] = useState("");
  const [imageEn, setImageEn] = useState("");
  const [imageAr, setImageAr] = useState("");
  const [areaVal, setAreaVal] = useState([]);
  const [status, setStatus] = useState(false);
  const [enableAllAreas, setEnableAllAreas] = useState(false);
  const [SliderList, setSliderList] = useState([]);
  const [SliderListNew, setSliderListNew] = useState([]);
  const [searchArea, setSearchArea] = useState("");
  const [deleteModal, setDeleteModal] = useState(false)
  const [selectedSliderData, setSelectedSliderData] = useState({})
  const [loaderImageAr, setLoaderImageAr] = useState("");
  const [loaderImageEn, setLoaderImageEn] = useState("");
  const [error, setError] = useState("");
  const [shortimageEn, setshortimageEn] = useState("");
  const [shortimageAr, setshortimageAr] = useState("");
  const [userData, setUserData] = useState({});
  const [areaErrormessage, setareaErrormessage] = useState("");

  const [fetchStatusTrue, setFetchStatusTrue] = useState(true)
  const [fetchStatusFalse, setFetchStatusFalse] = useState(true)

  const filterItem = (item) => {
    if(fetchStatusTrue && fetchStatusFalse){
      return true
    } else if(fetchStatusTrue && !fetchStatusFalse){
      return item.status
    } else if(fetchStatusFalse && !fetchStatusTrue){
      return !item.status
    } else {
      return false
    }
  }

  const [selectedArea, setSelectedArea] = React.useState()
  const [areas, setAreas] = React.useState([])

  React.useEffect(async () => {
    const AreaResponse = await postArea({})
    setAreas(
      AreaResponse?.record?.map(item => {
        return { label: item?.name, value: item?._id, ...item }
      })
    )
  }, [])

  const { chartsData, users, realTimeDetails, top4Data, Slider } = useSelector(state => ({
    chartsData: state.Dashboard.chartsData,
    users: state.Users.users,
    realTimeDetails: state.Dashboard.realTimeDetails,
    top4Data: state.Dashboard.top4Data,
    Slider: state.Slider.Sliders.record
  }))


  useEffect(() => {
    setSliderList(Slider);
    reArrange(Slider);
    setLoaderImageEn("")
    setLoaderImageAr("")
  }, [Slider])

  
  useEffect(() => {
    if (users.length > 0) {
      setUserData(users[0]);
    }
  }, [users])

  // useEffect(async () => {
  //   const AreaResponse = await postArea({});
  //   setAreaList(AreaResponse?.record);
  //   // console.log("AreaResponse", AreaResponse);
  // }, [])

  useEffect(() => {
    const data = {}
    dispatch(getSlider(data))
  }, [])

  const ArrayArea = (id) => {
    let newArr = [...areaVal];
    let indexVal = newArr.indexOf(id);
    if (indexVal !== -1) {
      newArr.splice(indexVal, 1);
    } else {
      newArr.push(id);
    }
    setAreaVal(newArr)
    
    if(newArr.length == 0) {
      setError({
        ...error, 
        areamessage : "Please select area"
      })
    }
    else{
      setError({
        ...error, 
        areamessage : ""
      })
    }
  }

  useEffect(() => {
    if (!realTimeModal) {
      setSelectedSliderData({})
      setAreaVal([])
      setSliderName("")
      setImageEn("")
      setImageAr("")
      setshortimageAr("")
      setshortimageEn("")
      setStatus(false)
      setEnableAllAreas(false)
    }
  }, [realTimeModal])

  const AddSlider = () => {
    SetRealTimeModal(!realTimeModal);
    setError({});
  }

  function toggleRTModal() {
    SetRealTimeModal(!realTimeModal);
    removeBodyCss();
  }

  function removeBodyCss() {
    document.body.classList.add("no_padding");
  }

  const handleValidSubmit = (e, v) => {
    const formData = new FormData();
    let valid = true;
    let _err = {...error};
    
    if(error?.imageSizeen || error?.imageSizear){
      valid = false;
    }

    
    if(sliderName==""){
      _err.slidername = "Please enter slider name";
      valid = false;
    }
    else{
      _err.slidername = "";
    }
    if(areaVal.length == 0) {
      _err.areamessage = "Please select Area";
      valid = false;
    }
    else{
      _err.areamessage = "";
    }
  
    //validate images mandatory only in add mode
    if(!selectedSliderData?._id){
      
      if(!imageAr){
        _err.imagear = "Please select image";
        valid = false;
      }
      else{
        _err.imagear = "";
      }
      
      if(!imageEn){
        _err.imageen = "Please select image";
        
        valid = false;
      }
      else{
        _err.imageen = "";
      }
      
    }
    console.log("ERR", _err);
    console.log("Valid", valid);
    setError(_err);

    if(!valid){
      return;
    }
   
    
    formData.append("name", sliderName);
    formData.append("imageEn", imageEn);
    formData.append("imageAr", imageAr);
    formData.append("status", status);
    formData.append("enableAllAreas", enableAllAreas || false);
    areaVal.map((item, index) => {
      formData.append("areas", item);
    })
    if (selectedSliderData?._id) {
      formData.append("_id", selectedSliderData._id);
      dispatch(updateSlider(formData))
    } else {
      dispatch(addNewSlider(formData))
    }
    toggleRTModal();
  }

  const handleStatus = (e, item) => {
    const formData = new FormData();
    formData.append("_id", item?._id);
    formData.append("status", e.target.checked);
    dispatch(updateSlider(formData))
  }

  const handleEnableAll = (e, item) => {
    const formData = new FormData();
    formData.append("_id", item?._id);
    formData.append("enableAllAreas", e.target.checked);
    dispatch(updateSlider(formData))
  }

  const handleImageArChange = (e, item) => {
    if (e?.target?.files[0]) {
      setLoaderImageAr(item?._id)
      setImageAr(e.target.files[0])
      const formData = new FormData();
      formData.append("_id", item?._id);
      formData.append("imageAr", e.target.files[0]);
      dispatch(updateSlider(formData))
    }
  }

  const updateSequence= (_id, sequence) => {
    const _sliders = [...SliderListNew];
    const index = _sliders.findIndex( s => s._id.toString() == _id.toString());
    _sliders[index]["sequence"] = myParseInt(sequence); 
    reArrange(_sliders);

  }

  const handleImageEnChange = (e, item) => {
    if (e?.target?.files[0]) {
      setLoaderImageEn(item?._id)
      setImageEn(e.target.files[0])
      const formData = new FormData();
      formData.append("_id", item?._id);
      formData.append("imageEn", e.target.files[0]);
      dispatch(updateSlider(formData))
    }

  }

  const handleSearchArea = (e) => {
    setSearchArea(e.target.value);
    let newFilterAreaList = areaList.filter(item => (item?.name.toLowerCase()).includes(e.target.value.toLowerCase()))
    let newSliderList = SliderList.map((data) => {
      let areaListIndex = newFilterAreaList.findIndex(data1 => data.areas.includes(data1?._id))
      if (areaListIndex !== -1) {
        return data
      }
    })
    setSliderListNew(newSliderList.filter((data) => data))
  }

  const deleteSliderdata = () => {
    let apiData = {
      _id: selectedSliderData._id
    }
    dispatch(deleteSlider(apiData))
    setTimeout(() => {
      setSelectedSliderData({})
      setDeleteModal(false)
    }, 500);
  }

  const handleDeleteSlider = (item) => {
    setSelectedSliderData(item)
    setDeleteModal(true)
  }

  const handleUpdateSlider = (item) => {
    let strimageEn = imageshortName(item.imageEn);
    let strimageAr = imageshortName(item.imageAr);

    setSelectedSliderData(item);
    setAreaVal(item.areas)
    setSliderName(item.name)
    setImageEn(item.imageEn)
    setImageAr(item.imageAr)
    setStatus(item.status)
    setEnableAllAreas(item.enableAllAreas)
    setshortimageEn(strimageEn)
    setshortimageAr(strimageAr)
    SetRealTimeModal(true)
  }

  const reArrange = (_sliders) => {
    if(_sliders == undefined){
      return;
    }
    _sliders.sort((a, b) => a.sequence - b.sequence);
    setSliderListNew(_sliders);
  }

  function imageshortName(imageName){
    return imageName.substring(imageName.lastIndexOf('/') + 1)
  }
  const validateImage = (e, type) => {

    if(e.target.files?.[0]){
      const file = e.target.files[0];
      
      const fileExtension = file.name.split(".").at(-1);
      const allowedFileTypes = ["jpg", "png", "jpeg", "gif", "bmp"];
      if (!allowedFileTypes.includes(fileExtension)) {
          setError({
            ...error, ["imageSize" + type] : "Only images can be uploaded"
          });
          type=="en"?setImageEn(""):setImageAr("");
          return false;
      }

      if(file.size > 512 * 1024){
        setError({
          ...error, ["imageSize" + type] : "Maximum file size is 512kb"
        });
        type=="en"?setImageEn(""):setImageAr("");
        return;
      }

      setError({
        ...error, ["imageSize" + type]:""
      })

      type=="en"?setImageEn(file):setImageAr(file);
    }
    
  }

  return (
    <React.Fragment>
      <div className="page-content" id="page-content">
        <MetaTags>
          <title>Sliders | Kleen Admin Panel</title>
        </MetaTags>
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumbs
            title={props.t("Home")}
            breadcrumbItem={props.t("Sliders")}
          />

          <Row className="mb-3 d-flex justify-content-between">
            <Col sm="6" className="text-end-home">
              {/* <input className="form-control" type="text" id="searchArea" placeholder="Search" aria-label="Search" value={searchArea} onChange={(e) => { handleSearchArea(e) }} /> */}
              <div className="ms-2" style={{ width: "20rem" }}>
                          <ReactSelect
                            placeholder="All Areas"
                            onChange={val => {
                              setSelectedArea(val)
                            }}
                            isClearable={true}
                            options={areas}
                          />
                        </div>
            </Col>
            <Col sm="6" className="d-flex text-end justify-content-between" >
              <div className="d-flex align-items-center">
            <div className="d-flex align-items-center mx-3">
                        <span className={`me-2 ${fetchStatusTrue? "text-success font-weight-semibold": ""}`}>Show Enabled</span>
                        <div className="form-check form-switch form-switch-lg">
                          <input
                            type="checkbox"
                            className="form-check-input form-slider-color-green"
                            defaultChecked={fetchStatusTrue}
                            onChange={(e) => { setFetchStatusTrue(!fetchStatusTrue) }}
                          />
                        </div>
                        </div>
                        <div className="d-flex align-items-center">
                        <span className={`me-2 ${fetchStatusFalse? "text-success font-weight-semibold": ""}`}>Show Disabled</span>
                        <div className="form-check form-switch form-switch-lg">
                          <input
                            type="checkbox"
                            className="form-check-input form-slider-color-green"
                            defaultChecked={fetchStatusTrue}
                            onChange={(e) => { setFetchStatusFalse(!fetchStatusFalse) }}
                          />
                        </div>
                        </div>
                        </div>
            {
              (userData?.permissions?.allPermissions == true || userData?.permissions?.slider?.create == true) ?
              <button type="button" onClick={AddSlider} className="btn-rounded  mb-2 me-2 btn btn-success">
                <i className="mdi mdi-plus me-1"></i>Add Slider
              </button>
              : null
            }
            </Col>
          </Row>
          <div id="outer_row_div">
            {SliderListNew?.filter((item) => {
                      return filterItem(item)
                    }).filter(slot => {
                      if (!selectedArea) {
                        return true
                      }
                      return slot.areas
                        .includes(selectedArea?.value)
                    }).map((item) => {
              return (
                <div key={item?._id} className="outer_row_div" >
                  <Row>
                    <Col md="6" >
                      <div className="ps-3 pt-3 d-flex align-items-center justify-content-start">
                        <Sequence 
                          row={item}
                          onUpdate={(_id, sequence) => {
                            updateSequence(_id, sequence);
                          }}
                          total={SliderListNew?.length}
                        />
                      </div>
                      <Row className="mrl-10">
                        <Col md="5" className="inner_col_div upload-filess">
                          {
                            loaderImageAr == item?._id ? (
                              <Skeleton count={1} height={130} className="mt-3 mb-4" />
                            ) : (
                              <Card className="mini-stats-wid">
                                {/* <label forHtml="myfile">  +  Select a file</label> */}
                                <div className="mb-3" style={{ backgroundImage: `url(${item?.imageAr})`, width: "190px", height: "80px", backgroundPosition: "center", backgroundRepeat: "no-repeat", backgroundSize: "contain" }}>
                                  {/* <i className="display-4 text-muted bx bxs-cloud-upload" /> */}

                                </div>
                                <div className="change-position-file">
                                  <input
                                    type="file"
                                    id="myfile"
                                    name="myfile"
                                    onChange={(e) => handleImageArChange(e, item)}
                                  // value={item?.imageAr}
                                  >
                                  </input>
                                  {(userData?.permissions?.allPermissions == true || userData?.permissions?.slider?.update == true) ?
                                  <h4>click here to Change.</h4>
                                  : null
                                  }
                                </div>
                              </Card>
                            )
                          }
                          
                          <h4>Slider in Arabic</h4>
                          
                        </Col>

                        <Col md="5" className="inner_col_div upload-filess mx-1">
                          {
                            loaderImageEn == item?._id ? (
                              <Skeleton count={1} height={130} className="mt-3 mb-4" />
                            ) : (
                              <Card className="mini-stats-wid">
                                <div className="mb-3" style={{ backgroundImage: `url(${item?.imageEn})`, width: "190px", height: "80px", backgroundPosition: "center", backgroundRepeat: "no-repeat", backgroundSize: "contain" }}>
                                  {/* <i className="display-4 text-muted bx bxs-cloud-upload" />
                              <input
                                type="file"
                                id="myfile"
                                name="myfile"

                                onChange={(e) => { handleImageEnChange(e, item) }}
                              // value={item?.imageEn}
                              /> */}
                                </div>

                                <div className="change-position-file">
                                  <input
                                    type="file"
                                    id="myfile"
                                    name="myfile"

                                    onChange={(e) => handleImageEnChange(e, item)}
                                  // value={item?.imageEn}
                                  />
                                  {(userData?.permissions?.allPermissions == true || userData?.permissions?.slider?.update == true) ?
                                  <h4>click here to Change.</h4>
                                  : null
                                  }
                                </div>
                              </Card>
                            )
                          }

                          <h4>Slider in English</h4>
                        </Col>
                      </Row>
                    </Col>
                    <Col md="6">
                      <div className="text-end mt-1 me-1">
                        {
                        (userData?.permissions?.allPermissions == true || userData?.permissions?.slider?.update == true) ?
                        <button onClick={() => handleUpdateSlider(item)} className="btn btn-sm btn-warning mx-2">
                          <i className="mdi mdi-pencil font-size-14" id="edittooltip" />
                        </button>
                        : null
                        }
                        {/* <button onClick={() => handleDeleteSlider(item)} className="btn btn-sm btn-danger">
                          <i className="mdi mdi-delete font-size-14" id="deletetooltip" />
                        </button> */}
                      </div>
                      <h5 className="mt-3">{item?.name}</h5>
                      <h6>Areas</h6>
                      <div className="d-flex-div md-6">
                        {item?.areas?.map((item2, index) => {
                          return areas.find(data1 => data1?._id === item2) ? (
                            <Button
                              key={index}
                              color="default"
                              className="btn inner_item_flex"
                            >
                              {
                                areas.find(data1 => data1?._id === item2).name
                              }

                            </Button>
                          ) : null
                        })}
                      </div>
                      <div className="d-flex align-items-start mt-2">
                        
                        <div className="form-check form-switch form-switch-lg mb-3 me-3">
                          <label
                            className="form-check-label"
                            htmlFor="customSwitchsizelg"
                          >
                            Status
                          </label>
                          {(userData?.permissions?.allPermissions == true || userData?.permissions?.slider?.update == true) ?
                          <input
                            type="checkbox"
                            className="form-check-input"
                            id="customSwitchsizelg"
                            defaultChecked={item?.status}
                            // checked={item?.status}
                            // value={item?.status}
                            onChange={(e) => { handleStatus(e, item) }}
                          />
                          : 
                          <span className={item.status?"text-success":"text-warning"}><b>{item.status?" : ON":" : OFF"}</b></span>
                          
                          }
                        </div>
                        
                          <div className="form-check form-switch form-switch-lg mb-3">
                            <label
                              className="form-check-label"
                              htmlFor="customSwitchsizelg"
                            >
                              All Areas
                            </label>
                            {(userData?.permissions?.allPermissions == true || userData?.permissions?.slider?.update == true) ?
                            <input
                              type="checkbox"
                              className="form-check-input"
                              id="customSwitchsizelg"
                              defaultChecked={item?.enableAllAreas}
                              // checked={item?.status}
                              // value={item?.status}
                              onChange={(e) => { handleEnableAll(e, item) }}
                            />
                            : 
                            <span className={item.enableAllAreas?"text-success":"text-warning"}><b>{item.enableAllAreas?" : Enabled":" : NA"}</b></span>
                            
                            }
                          </div>
                        
                      </div>


                    </Col>
                  </Row>
                </div>
              )
            })}
          </div>

          <Modal
            size="sm"
            isOpen={deleteModal}
            toggle={() => {
              setDeleteModal(!deleteModal);
            }}
            centered
          >
            <div className="modal-header">
              <h5 className="modal-title mt-0" id="myModalLabel">
                <span >
                  Delete Slider
                </span>
              </h5>
              <button type="button"
                onClick={() => {
                  setDeleteModal(false);
                }}
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <h5>Are you sure to delete?</h5>
            </div>
            <div className="modal-footer">
              <button onClick={deleteSliderdata} className="btn btn-md btn-danger">
                Yes
              </button>
              <button onClick={() => setDeleteModal(false)} className="btn btn-md btn-secondary">
                No
              </button>
            </div>

          </Modal>

          <Modal
            size="lg"
            isOpen={realTimeModal}
            toggle={() => {
              toggleRTModal();
            }}
          >
            <div className="modal-header">
              <h5 className="modal-title mt-0" id="myModalLabel">
                <span >
                  {selectedSliderData?._id ? "Update slider" : "Add New Slider"}
                </span>
              </h5>
              <button
                type="button"
                onClick={() => {
                  SetRealTimeModal(false);
                }}
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <Row>
                <Col lg="12">
                  <Card className="mb-0">
                    <CardBody>
                      <AvForm
                        onValidSubmit={(e, v) => {
                          handleValidSubmit(e, v)
                        }}
                        encType="multipart/form-data"
                      >
                        <div className="row mb-4">
                          <Label
                            htmlFor="horizontal-firstname-Input"
                            className="col-sm-3 col-form-label"
                          >
                            Slider Name  <span className="mandatory_feild">*</span>
                          </Label>
                          <Col sm={9}>
                            <AvField
                              type="text"
                              onChange={(e) => { setSliderName(e.target.value) }}
                              value={sliderName}
                              className="form-control"
                              id="horizontal-firstname-Input"
                              placeholder="Slider Name"
                              name="name"
                              // validate={{ required: { value: true } }}
                            />
                            {
                              error?.slidername? (
                                <label style={{ color: "red" }}>{error?.slidername}</label>
                              ) : null
                            }
                          </Col>
                          
                        </div>


                        <div className="row mb-4">
                          <Label
                            htmlFor="horizontal-firstname-Input"
                            className="col-sm-3 col-form-label"
                          >
                            Image (English) <span className="mandatory_feild">*</span>
                          </Label>
                          <Col sm={9}>
                            <AvField
                              type="file"
                              onChange={(e) => validateImage(e, 'en')}
                              className="form-control"
                              id="horizontal-firstname-Input"
                              placeholder="Image"
                              name="imageEn"
                              // errorMessage="Please Upload Image"
                              // validate={{ required: { value: !selectedSliderData?._id } }}
                            />
                            {
                              error?.imageen? (
                                <label style={{ color: "red" }}>{error?.imageen}</label>
                              ) : null
                            }
                            {
                              error?.imageSizeen? (
                                <label style={{ color: "red" }}>{error?.imageSizeen}</label>
                              ) : null
                            }
                            <div className="pt-2 text-primary"><b>{shortimageEn}</b></div>
                          </Col>
                          
                        </div>

                        <div className="row mb-4">
                          <Label
                            htmlFor="horizontal-firstname-Input"
                            className="col-sm-3 col-form-label"
                          >
                            Image (Arabic) <span className="mandatory_feild">*</span>
                          </Label>
                          <Col sm={9}>
                            <AvField
                              type="file"
                              onChange={(e) => validateImage(e, 'ar')}
                              className="form-control"
                              id="horizontal-firstname-Input"
                              placeholder="Image"
                              name="imageAr"
                              // errorMessage="Please Upload Image"
                              // validate={{ required: { value: !selectedSliderData?._id } }}
                            />
                            {
                              error?.imagear? (
                                <label style={{ color: "red" }}>{error?.imagear}</label>
                              ) : null
                            }
                            {
                              error?.imageSizear? (
                                <label style={{ color: "red" }}>{error?.imageSizear}</label>
                              ) : null
                            }
                            <div className="pt-2 text-primary"><b>{shortimageAr}</b></div>
                          </Col>
                        </div>
                        <div style={{ paddingLeft: 0 }} className="row d-flex form-check form-switch form-switch-lg mb-3">
                          <label
                            htmlFor="horizontal-status-Input"
                            className="col-sm-3 col-form-label"
                          >Status</label>
                          <Col sm={9} style={{ paddingLeft: 50 }}>
                            <input
                              type="checkbox"
                              className="form-check-input"
                              id="customSwitchsizelg horizontal-status-Input"
                              defaultChecked={status}
                              onChange={(e) => { setStatus(e.target.checked) }}
                            />
                          </Col>

                        </div>


                        <div style={{ paddingLeft: 0 }} className="row d-flex form-check form-switch form-switch-lg mb-3">
                          <label
                            htmlFor="horizontal-status-Input"
                            className="col-sm-3 col-form-label"
                          >All Areas</label>
                          <Col sm={9} style={{ paddingLeft: 50 }}>
                            <input
                              type="checkbox"
                              className="form-check-input"
                              id="customSwitchsizelg horizontal-status-Input"
                              defaultChecked={enableAllAreas}
                              onChange={(e) => { setEnableAllAreas(e.target.checked) }}
                            />
                          </Col>

                        </div>


                        {/* <div className="row mb-4">
                            <Label
                              htmlFor="horizontal-firstname-Input"
                              className="col-sm-3 col-form-label"
                            >
                              Status
                            </Label>
                            <Col sm={9}>
                              <AvField
                                type="checkbox"
                                onChange={(e) => { setStatus(e.target.checked) }}
                                className="form-control"
                          id="customSwitchsizelg"
                                id="horizontal-firstname-Input"
                                placeholder="Image"
                                name="status"
                              />
                            </Col>
                          </div> */}



                        {/* all area selected  */}
                        <div className="row mb-4">
                          <Label
                            htmlFor="horizontal-firstname-Input"
                            className="col-sm-3 col-form-label"
                          >
                            Area <span className="mandatory_feild">*</span>
                          </Label>
                          <Col sm={9}>
                          {
                              error?.areamessage? (
                                <label style={{ color: "red" }}>{error?.areamessage}</label>
                              ) : null
                            }
                            <AvGroup check className="check-box">
                              {areas?.map((item, index) => {
                                return (
                                  <Label key={index} className="col-md-3">
                                    <AvInput
                                      type="checkbox"
                                      name="areas"
                                      onChange={(e) => { ArrayArea(item?._id) }}
                                      defaultChecked={areaVal.includes(item?._id)}
                                     
                                    />
                                    {item?.name}
                                  </Label>
                                )
                              })}
                            </AvGroup>
                          </Col>
                        </div>


                        <div className="row justify-content-end">
                          <Col sm={12} className="col-sm-12 text-end">
                            <div>
                              <Button
                                type="submit"
                                color="primary"
                                className="w-md"

                              >
                                {selectedSliderData?._id ? "Update slider" : "Add Slider"}
                              </Button>

                              <button
                                type="button"
                                onClick={() => {
                                  toggleRTModal();
                                }}
                                className="m-1 btn btn-secondary "
                                data-dismiss="modal"
                              >
                                Close
                              </button>

                            </div>
                          </Col>
                        </div>
                      </AvForm>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </div>

          </Modal>
        </Container>
      </div>
    </React.Fragment>
  )
}

Sliders.propTypes = {
  t: PropTypes.any,
  chartsData: PropTypes.any,
  //onGetChartsData: PropTypes.func,
}

export default withTranslation()(Sliders)
