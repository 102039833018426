import { call, put, takeEvery } from "redux-saga/effects"
import React from "react"
import {
  Spinner,
} from "reactstrap";
import {
  apiSuccess,
  apiFail
} from "./actions"
import {
  REPORT_DATA
} from "./actionTypes"
//Include Both Helper File with needed methods
import { getDataReport } from "helpers/fakebackend_helper"

function* fetchReportData({ payload: record }) {
  try {
    // <Spinner type="grow" className="ms-2" color="primary" />
    const response = yield call(getDataReport, record)
    yield put(apiSuccess(REPORT_DATA, response.records))
  } catch (error) {
    yield put(apiFail(error))
  }
}


function* permissionSaga() {
  yield takeEvery(REPORT_DATA, fetchReportData)
}

export default permissionSaga
