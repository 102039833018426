import React, { useState } from "react"
import { Row, Col, Card, CardBody, Button, Label, Modal, Input } from "reactstrap"
import Select from "react-select"
import MetaTags from 'react-meta-tags'

// datatable related plugins
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory, {
  PaginationProvider, PaginationListStandalone,
  SizePerPageDropdownStandalone
} from 'react-bootstrap-table2-paginator';

import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"
import "../../assets/scss/datatables.scss"
import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux"
import { getRefundOrders, getReasons, getOrders, refund, searchUsers } from "../../store/actions"
import classNames from "classnames"
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import search_Two from "../../assets/images/search-two.png"
import RatingTooltip from "react-rating-tooltip"
import Pages404 from "pages/Utility/pages-404";
import DataTable from "react-data-table-component";
import { myParseFloat } from "helpers/validationHelper";

const RefundsLoader = props => {
  return (
    <React.Fragment>
      <div className="container-fluid">
        <Row>
          <Col xl="12">
            <div className="row">
              <div className="col-xl-12">
                <div className="table-responsive">
                  <div className="react-bootstrap-table">
                    <table className="table table align-middle table-nowrap">
                      <thead className="table-light">
                        <tr>
                          <th>Order</th>
                          <th>Customer</th>
                          <th>Order Date</th>
                          <th>Refund Amount</th>
                          <th>Method</th>
                          <th>Authorized By</th>
                          <th>Comment</th>
                        </tr>
                      </thead>
                      <tbody>
                        {[...Array(10).keys()].map((item, index) => (
                          <tr key={index}>
                            <td><Skeleton count={1} /></td>
                            <td><Skeleton count={1} /></td>
                            <td><Skeleton count={1} /></td>
                            <td><Skeleton count={1} /></td>
                            <td><Skeleton count={1} /></td>
                            <td><Skeleton count={1} /></td>
                            <td><Skeleton count={1} /></td>
                          </tr>
                        )
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </div>

    </React.Fragment>
  )
}

const CompletedOrdersList = () => {
  const dispatch = useDispatch()
  const { refundOrders, totalRecord, refundReason, searchOrder, users } = useSelector(state => ({
    // Allorders: state.Orders.Allorders,
    refundOrders: state.Orders.refundOrders,
    totalRecord: state.Records.totalRecord,
    refundReason: state.Orders.reason,
    users: state.Users.users,
    searchOrder: state.ecommerce.orders
  }))

  const [productData, setProductData] = useState([]);
  const [page, setPage] = useState(0);
  const [error, setError] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [refundModalToggel, setRefundModalToggel] = useState(false)
  const [orderID, setOrderID] = useState("")
  const [searchOrderDetail, setSearchOrderDetail] = useState({})
  const [refundAmount, setRefundAmount] = useState("")
  const [reasonRefund, setReasonRefund] = useState("")
  const [methodRefund, setRefundMethod] = useState("")
  const [customerDetailLoader, setCustomerDetailLoader] = useState(false)
  const [userData, setUserData] = useState({});
  const [addRefundLoader, setAddRefundLoader] = useState(false);
  const [totalRecords, setTotalRecords] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  useEffect(() => {
    setCustomerDetailLoader(false)
    if (searchOrder?.length) {
      setSearchOrderDetail(searchOrder[0])
      if (!searchOrder[0]?.paid) {
        setError({ searchOrder: "Customer has not paid" })
      } else if (searchOrder[0]?.refund) {
        setError({ searchOrder: "Refund is already processed of amount " + searchOrder[0]?.refund?.amount + " SR" })
      } else {
        setError({})
      }
    } else {
      setSearchOrderDetail({})
      setError({ searchOrder: "Please enter a valid order ID" })
    }
  }, [searchOrder])

  const handleApply = () => {
    setProductData([])
    setIsLoading(true);
    let apiData = { page: page, rowsPerPage: rowsPerPage };
    dispatch(getRefundOrders(apiData))
  }

  useEffect(() => {
    let data = JSON.parse(localStorage.getItem("authUser"));
    let userApiData = { _id: data?._id, webPermissions: true }
    dispatch(searchUsers(userApiData));
    dispatch(getReasons({}))
  }, [])

  useEffect(() => {
    handleApply();
  }, [page, rowsPerPage])

  useEffect(() => {
    if (users.length > 0) {
      setUserData(users[0]);
    }
  }, [users])


  useEffect(() => {
    setRefundModalToggel(false)
    console.log("refundOrders::", refundOrders);
    if (refundOrders?.record?.length && refundOrders?.record[0]?.refund) {
      setAddRefundLoader(false);
      setProductData(refundOrders?.record);
      setTotalRecords(refundOrders?.total_orders);
    }
    else {
      setProductData([]);
      setTotalRecords(0);
    }
    setIsLoading(false);
  }, [refundOrders])

  const columns = [
    {
      name: "Order",
      sortable: false,
      selector: row => row.orderID || "N/A",
      wrap: true,
    },
    {
      name: 'Customer',
      selector: row => row?.customer?.customerID || "N/A"
    },
    {
      name: 'Order Date',
      selector: row => row?.startTime?.split(" ")[0] || "N/A"
    },
    {
      name: 'Refund Amount',
      selector: row => row?.refund?.amount
    },
    {
      name: 'Method',
      selector: row => row?.refund?.method
    },
    {
      name: 'Authorized By',
      selector: row => row?.refund?.refundBy?.name
    },
    {
      name: 'Comments',
      selector: row => <span className="reason-wrap">{row?.refund?.comments}</span>,
      wrap: true
    }
  ];

  const defaultSorted = [{
    dataField: 'id',
    order: 'asc'
  }];

  const pageOptions = {
    sizePerPage: 20,
    totalSize: totalRecord, // replace later with size(customers),
    custom: true,
  }

  // Custom Pagination Toggle
  const sizePerPageList = [
    { text: '5', value: 5 },
    { text: '10', value: 10 },
    { text: '15', value: 15 },
    { text: '20', value: 20 },
    { text: '25', value: 25 },
    { text: 'All', value: totalRecord }];


  // Select All Button operation
  const selectRow = {
    mode: 'checkbox'
  }

  const { SearchBar } = Search;

  const handleOrderRefund = (item) => {
    setRefundModalToggel(true)
  }

  useEffect(() => {
    if (!refundModalToggel) {
      setError({})
      setOrderID("")
      setRefundAmount("")
      setReasonRefund("")
      setRefundMethod("")
      setSearchOrderDetail({})
    }
  }, [refundModalToggel])

  const handleOrderSearch = () => {
    if (!orderID) {
      setError({ searchOrder: "Order ID required" })
      setSearchOrderDetail({})
      return;
    }
    setCustomerDetailLoader(true)
    let apiData = {
      orderID
    }
    dispatch(getOrders(apiData))
  }

  const handleRefund = () => {
    const _refundAmount = myParseFloat(refundAmount);
    if (!_refundAmount) {
      setError({ refundAmt: "Refund Amount Required" });
      return;
    }
    if (_refundAmount < 1) {
      setError({ refundAmt: "Please enter valid amount for refund" });
      return;
    }

    userData?.permissions?.allPermissions == true

    if (userData?.permissions?.allPermissions !== true) {
      let maxPercentage = myParseFloat(userData?.permissions?.refund?.validations?.maxRefundPer);
      let allowedAmount = 0;

      if (maxPercentage > 0) {
        allowedAmount = (myParseFloat(searchOrderDetail?.grandTotal) * maxPercentage) / 100;
      }
      // if (methodRefund == 'bank' && _refundAmount > searchOrderDetail?.grandTotal) {
      //   setError({ refundAmt: `Refund Amount can't be greater grand total` });
      // }
      if (methodRefund != 'bank' && _refundAmount > allowedAmount) {
        setError({ refundAmt: `Refund Amount can't be greater maximum allowed refund percentage ${maxPercentage}%` });
        return;
      } else if (_refundAmount <= 0) {
        setError({ refundAmt: "Invalid refund amount" });
        return;
      }
    }
    // if (!selectedReasonOption.value) {
    //   setError({ refundOpt: "Reason Option Required" });
    //   return;
    // }
    if (!reasonRefund) {
      setError({ refundCmt: "Refund Reason Required" });
      return;
    }
    if (!methodRefund) {
      setError({ refundMeth: "Method Required" });
      return;
    }
    setAddRefundLoader(true);
    // return;
    let data = {
      _id: searchOrderDetail._id,
      refund: {
        amount: _refundAmount,
        comments: reasonRefund,
        method: methodRefund
      }
    }
    // if (reasonRefund) {
    //   data.refund.otherReason = reasonRefund
    // }
    dispatch(refund(data))
    setTimeout(() => {
      handleApply()
    }, 500);
  }

  const handlePerRowsChange = (newSize) => {
    setRowsPerPage(newSize)
  }

  const handlePageChange = (pageNum) => {
    if (pageNum >= 1) {
      setPage(pageNum - 1);
    }
  }

  if (userData?.permissions?.allPermissions == true || userData?.permissions?.refund?.read == true) {
    return (
      <React.Fragment>
        <div className="page-content">
          <MetaTags>
            <title>Kleen | Refunds</title>
          </MetaTags>
          <div className="container-fluid">
            <Breadcrumbs title="Orders" breadcrumbItem="Refunds" />

            <Row>
              <Col className="col-12">
                <Card>
                  <CardBody>
                    {/* <CardTitle className="h4">Completed orders </CardTitle> */}
                    {/* <p className="card-title-desc">
                    react-bootstrap-table-next plugin has most features enabled by default,
                    so all you need to do to use it with your own tables is to
                    call the construction function:{" "}
                    <code>react-bootstrap-table-next </code>.
                  </p> */}


                    <React.Fragment>
                      <Row className="mb-2">
                        <Col sm="12">
                          <div className="text-sm-end">
                            {(userData?.permissions?.allPermissions == true || userData?.permissions?.refund?.create == true) ?
                              <Button
                                type="button"
                                color="success"
                                className="mb-2 me-2 btn-lg"
                                onClick={handleOrderRefund}
                              >
                                Refund
                              </Button> : null
                            }
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        <Col xl="12">

                          <DataTable
                            columns={columns}
                            data={productData}
                            progressPending={isLoading}
                            pagination
                            paginationServer
                            paginationTotalRows={totalRecords}
                            onChangeRowsPerPage={handlePerRowsChange}
                            onChangePage={handlePageChange}
                            progressComponent={<RefundsLoader />}
                            sortServer
                          />

                        </Col>
                      </Row>


                    </React.Fragment>


                  </CardBody>
                </Card>
                <Modal
                  size="lg"
                  isOpen={refundModalToggel}
                  toggle={() => setRefundModalToggel(!refundModalToggel)}
                  centered={true}
                >
                  <div className="modal-header">
                    <h5 className="modal-title mt-0" id="myModalLabel">
                      <span >
                        Order Refund
                      </span>
                    </h5>
                    <button
                      type="button"
                      onClick={() => setRefundModalToggel(!refundModalToggel)}
                      className="close"
                      data-dismiss="modal"
                      aria-label="Close"
                    >
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div>
                  <div className="modal-body">
                    <Row>
                      <Col md="6" className="order-ref121 align-self-end">
                        <Label>Order ID <span className="mandatory_feild">*</span></Label>
                        <div className="orderreferel">
                          <Input
                            name="orderID"
                            type="text"
                            className="form-control"
                            placeholder="Enter Order ID"
                            value={orderID}
                            onChange={(e) => setOrderID(e.target.value)}
                          />
                          {
                            customerDetailLoader ? (
                              <Button
                                color="primary"
                                className="search-icons1 searchOrderRefund"
                                type="button"
                                disabled
                              ><i className="bx bx-loader bx-spin font-size-24 align-middle me-2"></i>
                              </Button>
                            ) : (
                              <button className="search-icons1 primary searchOrderRefund" onClick={() => handleOrderSearch()}>
                                <img src={search_Two} alt="" />
                              </button>
                            )
                          }
                        </div>
                        {
                          error.searchOrder ? (
                            <Label style={{ color: "red" }}>{error.searchOrder}</Label>
                          ) : null
                        }

                      </Col>
                      {/* <Col lg="12" className="mt-2">
                      <Label>Select Reason</Label>
                      <Select
                        // value={selectedReasonOption}
                        // onChange={handleReasonOption}
                        options={reasonOptions}
                        classNamePrefix="select2-selection"
                      />
                      {
                        error.refundOpt ? (
                          <Label style={{ color: "red" }}>{error.refundOpt}</Label>
                        ) : null
                      }
                    </Col> */}
                      <Col md="6" className="order-ref121">
                        {
                          customerDetailLoader ? (
                            <Skeleton height={110} count={1} />
                          ) : searchOrderDetail?.customer ? (
                            <div className="custome-orders10">
                              <p>Customer: {searchOrderDetail?.customer?.customerID}</p>
                              <p>Mobile: {searchOrderDetail?.customer?.mobile}</p>
                              <p>Grand Total: {searchOrderDetail?.grandTotal ? searchOrderDetail?.grandTotal + " SR" : searchOrderDetail?.total ? searchOrderDetail?.total + " SR" : 0 + " SR"}</p>
                            </div>
                          ) : null
                        }
                      </Col>

                    </Row>
                    <Row className="mt-2">
                      <Col md="6" className="order-ref121">
                        <Label>Amount <span className="mandatory_feild">*</span></Label>
                        <Input
                          name="refundAmount"
                          type="number"
                          className="form-control"
                          placeholder="Enter Amount"
                          disabled={!searchOrderDetail?.orderID || error?.searchOrder}
                          value={refundAmount}
                          onChange={(val) => {
                            setRefundAmount(val.target.value)
                            setError({})
                          }}
                        />
                        {
                          error.refundAmt ? (
                            <Label style={{ color: "red" }}>{error.refundAmt}</Label>
                          ) : null
                        }
                      </Col>
                      {/* <Col lg="12" className="mt-2">
                      <Label>Select Reason</Label>
                      <Select
                        // value={selectedReasonOption}
                        // onChange={handleReasonOption}
                        options={reasonOptions}
                        classNamePrefix="select2-selection"
                      />
                      {
                        error.refundOpt ? (
                          <Label style={{ color: "red" }}>{error.refundOpt}</Label>
                        ) : null
                      }
                    </Col> */}
                      <Col md="6" className="order-ref121">
                        <Label>Why Refund ? <span className="mandatory_feild">*</span></Label>
                        <Input
                          name="refundResion"
                          type="text"
                          className="form-control"
                          placeholder="Enter reason"
                          disabled={!searchOrderDetail?.orderID || error?.searchOrder}
                          value={reasonRefund}
                          onChange={(val) => setReasonRefund(val.target.value)}
                        />
                        {
                          error.refundCmt ? (
                            <Label style={{ color: "red" }}>{error.refundCmt}</Label>
                          ) : null
                        }
                      </Col>

                    </Row>
                    <Row className="mt-2">
                      <Col md="6" className="order-ref121">
                        <Label>Method <span className="mandatory_feild">*</span></Label>
                        <select
                          name="refundMethod"
                          className="form-control"
                          placeholder="Enter Method"
                          onChange={(e) => {
                            setRefundMethod(e.target.value)
                            setError({})
                          }}
                        >
                          <option value="">Select Method</option>
                          <option value="wallet">Wallet</option>
                          <option value="bank">Bank</option>
                        </select>
                        {
                          error.refundMeth ? (
                            <Label style={{ color: "red" }}>{error.refundMeth}</Label>
                          ) : null
                        }
                      </Col>
                      {/* <Col lg="12" className="mt-2">
                      <Label>Select Reason</Label>
                      <Select
                        // value={selectedReasonOption}
                        // onChange={handleReasonOption}
                        options={reasonOptions}
                        classNamePrefix="select2-selection"
                      />
                      {
                        error.refundOpt ? (
                          <Label style={{ color: "red" }}>{error.refundOpt}</Label>
                        ) : null
                      }
                    </Col> */}
                      <Col md="6" className="order-ref121">
                        <Label>Authorized By <span className="mandatory_feild">*</span></Label>
                        <Input
                          name="refundBy"
                          type="text"
                          className="form-control"
                          placeholder="Enter Authorized By"
                          disabled
                          value={users[0]?.profile?.name}
                        // onChange={(val) => setReasonRefund(val.target.value)}
                        />
                        {
                          error.refundByAuth ? (
                            <Label style={{ color: "red" }}>{error.refundByAuth}</Label>
                          ) : null
                        }
                      </Col>

                    </Row>
                    <Row className="modal_btn">
                      <Col md="6" className="mt-2 d-flex">
                        <Button
                          onClick={() => setRefundModalToggel(!refundModalToggel)}
                          type="submit"
                          // color="primary"
                          className="w-md mx-2"
                        >Cancel</Button>
                        {
                          addRefundLoader ? (
                            <Button
                              color="primary"
                              className="btn-rounded d-flex"
                              type="button"
                              disabled
                            >
                              <i className="bx bx-loader bx-spin font-size-18 align-middle me-2"></i>
                              Processing...
                            </Button>
                          ) : (
                            <Button
                              onClick={() => handleRefund()}
                              type="submit"
                              color="primary"
                              className="w-md"
                              disabled={!searchOrderDetail?.orderID || error?.searchOrder}
                            >Refund</Button>
                          )
                        }
                      </Col>
                    </Row>
                  </div>
                </Modal>
              </Col>
            </Row>
            <div className="align-items-md-center mt-30 row">
              <div className="inner-custom-pagination d-flex col">
                {/* <div className="d-inline">
                  <span className="react-bs-table-sizePerPage-dropdown dropdown" style={{ visibility: "visible" }}>
                    <button id="pageDropDown" type="button" className="btn btn-default btn-secondary dropdown-toggle" data-toggle="dropdown" aria-expanded="false">
                      10 <span><span className="caret"></span></span>
                    </button>
                    <ul className="dropdown-menu " role="menu" aria-labelledby="pageDropDown">
                      <li role="presentation" className="dropdown-item">
                        <a href="#" tabIndex="-1" role="menuitem" data-page="10">10</a>
                      </li>
                      <li role="presentation" className="dropdown-item">
                        <a href="#" tabIndex="-1" role="menuitem" data-page="25">25</a>
                      </li>
                      <li role="presentation" className="dropdown-item">
                        <a href="#" tabIndex="-1" role="menuitem" data-page="30">30</a>
                      </li>
                      <li role="presentation" className="dropdown-item">
                        <a href="#" tabIndex="-1" role="menuitem" data-page="50">50</a>
                      </li>
                    </ul>
                  </span>
                </div> */}
                <div className="text-md-right ms-auto">

                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    )
  }
  else {
    return <Pages404 />
  }

}

export default CompletedOrdersList
