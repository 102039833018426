import PropTypes from "prop-types"
import React, { useEffect, useState, useRef } from "react"
import ReactApexChart from "react-apexcharts"
import MetaTags from "react-meta-tags";
// import Doughnut from '../AllCharts/echart/doughnutchart';
import DonutChart from "../AllCharts/apex/dountchart"
import BarChart from "../AllCharts/apex/barchart";
import { Formik, Field, Form } from 'formik';
import { ThreeDots } from 'react-loader-spinner'
import Pages404 from "pages/Utility/pages-404";
import { socket } from "../../common/socket";


import {
  Container,
  Row,
  Col,
  Button,
  Card,
  CardBody,
  CardTitle,
} from "reactstrap"
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";

import moment from 'moment-timezone';



import { Link } from "react-router-dom"
import { get, postWeb } from "helpers/api_helper"

//import action
import { getRegistrationSummary, searchUsers, getBagCustomers, getOrderCount, getUniqueCustomers } from "../../store/actions"
import { useDispatch, useSelector } from "react-redux"

import Pie from "../AllCharts/echart/piechart"
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"

//i18n
import { withTranslation } from "react-i18next"

//redux
// import { useSelector, useDispatch } from "react-redux"
import { values } from "lodash";

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

const Analytics = props => {
  const [analyticsData, SetAnalyticsData] = useState({})
  const [top4DataModal, SetTop4DataModal] = useState(false);
  const [dateValues, SetDateValues] = useState(null);
  const [loadingCharts, SetLoadingCharts] = useState(false);
  const [userData, setUserData] = useState({});
  const [pageLoading, setPageLoading] = useState(true);

  const { registrationSummary, orderCounts, uniqueCustomers, users, bagCustomers } = useSelector(state => ({
    registrationSummary: state.Analytics.registrationSummary,
    bagCustomers: state.Analytics.bagCustomers,
    orderCounts: state.Analytics.orderCounts,
    uniqueCustomers: state.Analytics.uniqueCustomers,
    users: state.Users.users
  }))



  var node = useRef();
  const keyField = "id";
  const { SearchBar } = Search;




  const options = null;
  const initialData = {
    start: "2022-10-01",
    end: moment().tz("Asia/kuwait").format("YYYY-MM-DD")
  };

  const loaderColor = "#0097CE";



  function toggleTop4Modal() {
    SetTop4DataModal(!top4DataModal);
    removeBodyCss();
  }

  function getStatusColor(status) {
    switch (status) {
      case 'paid':
        return 'success';
      case 'unpaid':
      case 'cancelled':
        return 'warning';
      default:
        return 'primary';
    }
  }

  function removeBodyCss() {
    document.body.classList.add("no_padding");
  }

  const options2 = {
    chart: {
      height: 350,
      type: "area",
      toolbar: {
        show: false,
      },
    },
    colors: ["#556ee6", "#f1b44c"],
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: "smooth",
      width: 2,
    },
    fill: {
      type: "gradient",
      gradient: {
        shadeIntensity: 1,
        inverseColors: false,
        opacityFrom: 0.45,
        opacityTo: 0.05,
        stops: [20, 100, 100, 100],
      },
    },
    xaxis: {
      categories: [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ],
    },

    markers: {
      size: 3,
      strokeWidth: 3,

      hover: {
        size: 4,
        sizeOffset: 2,
      },
    },
    legend: {
      position: "top",
      horizontalAlign: "right",
    },
  }
  const series = [
    {
      name: "Current",
      data: [18, 21, 45, 36, 65, 47, 51, 32, 40, 28, 31, 26],
    },
    {
      name: "Previous",
      data: [30, 11, 22, 18, 32, 23, 58, 45, 30, 36, 15, 34],
    },
  ]



  const getOrdersOnline = (data) => {
    return {
      toolbox: {
        show: false,
      },
      tooltip: {
        trigger: "item",
        formatter: "{a} <br/>{b} : {c} ({d}%)",
      },
      legend: {
        orient: "horizontal",
        left: "left",
        data: ["Online", "Offline"],
        textStyle: {
          color: ["#74788d"],
        },
      },
      color: ["#02a499", "#f8b425"],
      series: [
        {
          name: "Total Orders",
          type: "pie",
          radius: "55%",
          center: ["50%", "60%"],
          data: [
            { value: data?.online, name: "Online" },
            { value: data?.offline, name: "Offline" }
          ],
          itemStyle: {
            emphasis: {
              shadowBlur: 10,
              shadowOffsetX: 0,
              shadowColor: "rgba(0, 0, 0, 0.5)",
            },
          },
        },
      ],
    }
  };


  const getOrdersbyCloth = (data) => {
    return {
      toolbox: {
        show: false,
      },
      tooltip: {
        trigger: "item",
        formatter: "{a} <br/>{b} : {c} ({d}%)",
      },
      legend: {
        orient: "horizontal",
        left: "left",
        data: ["Carpet Online", "Cloth Online"],
        textStyle: {
          color: ["#74788d"],
        },
      },
      color: ["#ec4561", "#38a4f8"],
      series: [
        {
          name: "Total Orders",
          type: "pie",
          radius: "55%",
          center: ["50%", "60%"],
          data: [
            { value: data?.carpet, name: "Carpet Online" },
            { value: data?.clothes, name: "Cloth Online" }
          ],
          itemStyle: {
            emphasis: {
              shadowBlur: 10,
              shadowOffsetX: 0,
              shadowColor: "rgba(0, 0, 0, 0.5)",
            },
          },
        },
      ],
    }
  };


  const getOrdersbyCustomers = (data) => {
    return {
      toolbox: {
        show: false,
      },
      tooltip: {
        trigger: "item",
        formatter: "{a} <br/>{b} : {c} ({d}%)",
      },
      legend: {
        orient: "horizontal",
        left: "left",
        data: ["Online Customers", "Offline Customers"],
        textStyle: {
          color: ["#02a499", "#f8b425"],
        },
      },
      color: ["#02a499", "#f8b425"],
      series: [
        {
          name: "Unique Customers",
          type: "pie",
          radius: "55%",
          center: ["50%", "60%"],
          data: [
            { value: data?.online, name: "Online Customers" },
            { value: data?.offline, name: "Offline Customers" }
          ],
          itemStyle: {
            emphasis: {
              shadowBlur: 10,
              shadowOffsetX: 0,
              shadowColor: "rgba(0, 0, 0, 0.5)",
            },
          },
        },
      ],
    }
  };





  const downloadCustomers = (customerType) => {
    let values = dateValues;
    values.type = customerType;
    postWeb(`dashboard/customers_export`, { values })
      .then(res => {
        if (res.path != undefined) {
          window.open(API_BASE_URL + '/' + res.path);
        }
      });
  }



  const dispatch = useDispatch();

  useEffect(() => {
    SetDateValues(initialData);

    socket.on("__new_order__", data => {
      toastr.success(`New Order Recieved.`, `New Order ID: ${data.orderID}`);
      dispatch(updateOrderList({
        newOrder: true,
        data: data?.orderData
      }));
    });

    socket.on("__order_updated__", data => {
      toastr.success(`Order Status Changed.`, `Order ID: ${data.orderID}`);
      dispatch(updateOrderList({
        newOrder: false,
        data: data?.orderData
      }));
    });

    return () => {
      socket.off('__new_order__');
      socket.off('__order_updated__');
    };

  }, []);


  useEffect(() => {
    if (dateValues != null) {
      SetLoadingCharts(true);
      dispatch(getRegistrationSummary(dateValues))
      dispatch(getBagCustomers(dateValues))
      dispatch(getOrderCount(dateValues))
      dispatch(getUniqueCustomers(dateValues))      
    }
  }, [dateValues]);

  useEffect(() => {
    SetLoadingCharts(false);
  }, [registrationSummary]);


  const onSubmit = async (values) => {
    SetDateValues({
      start: values.start,
      end: values.end
    });
  }

  function validateUsername(value) {
    let error;
    if (value != "") {
      error = 'Nice try!';
    }
    return error;
  }

  var data = JSON.parse(localStorage.getItem("authUser"));



  useEffect(() => {
    dispatch(searchUsers({ webPermissions: true }))
  }, [])

  useEffect(() => {
    if (users.length > 0) {
      setUserData(users[0]);
    }
  }, [users])


  if (userData?.permissions?.allPermissions == true || userData?.permissions?.analytics?.read == true) {
    return (
      <div className="page-content">
        <MetaTags>
          <title>Analytics | Kleen Admin Panel</title>
        </MetaTags>
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumbs
            title={props.t("Home")}
            breadcrumbItem={props.t("Analytics")}
          />


          {/* top form */}
          <Row>
            <Col>
              <Card>
                <Formik
                  initialValues={{
                    start: initialData?.start,
                    end: initialData?.end
                  }}
                  onSubmit={onSubmit}
                >
                  <Form>
                    <CardBody>
                      {/* <CardTitle className="h4">Textual inputs</CardTitle> */}

                      <Row className="mb-3">
                        <label
                          htmlFor="example-date-input"
                          className="col-md-2 col-form-label text-right"
                        >
                          From Date
                        </label>
                        <div className="col-md-2">
                          <Field
                            className="form-control"
                            type="date"
                            defaultValue={initialData?.start}
                            id="start"
                            name="start"
                            value={values.start}
                          />

                        </div>
                        <label
                          htmlFor="example-date-input"
                          className="col-md-2 col-form-label text-right"
                        >
                          To  Date
                        </label>
                        <div className="col-md-2">
                          <Field
                            className="form-control"
                            defaultValue={initialData?.end}
                            type="date"
                            id="end"
                            name="end"
                            value={values.end}
                          />

                        </div>

                        <div className="col-md-2">
                          {loadingCharts ?
                            <Button
                              color="success"
                              className="btn-rounded "
                              type="button"
                              disabled
                            >
                              <i className="bx bx-loader bx-spin font-size-18 align-middle me-2"></i>
                              Processing...
                            </Button> :
                            <Button
                              color="success"
                              className="btn-rounded "
                              type="submit"
                            >
                              Filter Result
                            </Button>}
                        </div>

                        <Col md="3">

                        </Col>

                      </Row>

                    </CardBody>
                  </Form>
                </Formik>
              </Card>
            </Col>
          </Row>


          <Row>

            <Col lg="12">
              <Card>
                <CardBody>
                  <Row>
                    <Col lg="6">
                      <CardTitle>Registrations</CardTitle>
                    </Col>
                    <Col lg="6" className="text-end">
                      <Button color="primary" className="btn-sm" onClick={() => { downloadCustomers('active'); }}>
                        Active Customers &nbsp;  <i className="mdi mdi-cloud-download fs-5" />
                      </Button>
                      &nbsp;
                      &nbsp;

                      <Button color="primary" className="btn-sm" onClick={() => { downloadCustomers('new'); }}>
                        New Customers  &nbsp; <i className="mdi mdi-cloud-download fs-5" />
                      </Button>

                    </Col>
                  </Row>
                  <Row>
                    <Col lg="6">
                      {loadingCharts ?
                        <ThreeDots
                          height="80"
                          width="80"
                          radius="9"
                          color={loaderColor}
                          ariaLabel="three-dots-loading"
                          wrapperStyle={{}}
                          wrapperClassName=""
                          visible={loadingCharts}
                        /> :
                        <div id="pie-chart" className="e-chart">
                          {registrationSummary ?
                            <Pie
                              options={{
                                toolbox: {
                                  show: false,
                                },
                                tooltip: {
                                  trigger: "item",
                                  formatter: "{a} <br/>{b} : {c} ({d}%)",
                                },
                                legend: {
                                  orient: "horizontal",
                                  left: "left",
                                  data: ["Active", "Inactive"],
                                  textStyle: {
                                    color: ["#74788d"],
                                  },
                                },
                                color: ["#02a499", "#f8b425"],
                                series: [
                                  {
                                    name: "Registrations",
                                    type: "pie",
                                    radius: "55%",
                                    center: ["50%", "60%"],
                                    data: [
                                      { value: registrationSummary?.active, name: "Active" },
                                      { value: registrationSummary?.inactive, name: "Inactive" },
                                    ],
                                    itemStyle: {
                                      emphasis: {
                                        shadowBlur: 10,
                                        shadowOffsetX: 0,
                                        shadowColor: "rgba(0, 0, 0, 0.5)",
                                      },
                                    },
                                  },
                                ],
                              }}

                            />

                            : null
                          }
                        </div>
                      }
                    </Col>

                    <Col lg="6">
                      {loadingCharts ?
                        <ThreeDots
                          height="80"
                          width="80"
                          radius="9"
                          color={loaderColor}
                          ariaLabel="three-dots-loading"
                          wrapperStyle={{}}
                          wrapperClassName=""
                          visible={loadingCharts}
                        /> :
                        <div id="pie-chart-bag" className="e-chart">
                          {bagCustomers ?
                            <Pie
                              options={{
                                toolbox: {
                                  show: false,
                                },
                                tooltip: {
                                  trigger: "item",
                                  formatter: "{a} <br/>{b} : {c} ({d}%)",
                                },
                                legend: {
                                  orient: "horizontal",
                                  left: "left",
                                  data: ["Bag Customers", "Non-Bag Customers"],
                                  textStyle: {
                                    color: ["#74788d"],
                                  },
                                },
                                color: ["#02a499", "#f8b425"],
                                series: [
                                  {
                                    name: "Customers",
                                    type: "pie",
                                    radius: "55%",
                                    center: ["50%", "60%"],
                                    data: [
                                      { value: bagCustomers?.bag, name: "Bag Customers" },
                                      { value: bagCustomers?.nonBag, name: "Non-Bag Customers" },
                                    ],
                                    itemStyle: {
                                      emphasis: {
                                        shadowBlur: 10,
                                        shadowOffsetX: 0,
                                        shadowColor: "rgba(0, 0, 0, 0.5)",
                                      },
                                    },
                                  },
                                ],
                              }} />
                            : null
                          }
                        </div>
                      }
                    </Col>

                    {/* <Col lg="6">
                    <h6>Registrations Growth in Last 12 Months</h6>
                    {analyticsData.registrations_growth?
                    <ReactApexChart
                      
                      series={[
                        {
                          name: "Registrations",
                          data: data.map(item => {
                            return item.total
                        }).reverse(),
                        },
                      ]}

                      options={{
                        chart: {
                          toolbar: "false",
                          dropShadow: {
                            enabled: !0,
                            color: "#000",
                            top: 18,
                            left: 7,
                            blur: 8,
                            opacity: 0.2,
                          },
                        },
                        dataLabels: {
                          enabled: !1,
                        },
                        colors: ["#556ee6"],
                        stroke: {
                          curve: "smooth",
                          width: 3,
                        },
                        xaxis: {
                          categories: data.map(item => {
                            return months[item._id-1]
                        }).reverse(),
                          title: { text: "Month" },
                        },
                      }}
                      type="line"
                      height={400}
                      className="apex-charts"
                    />:null}
                  </Col>

                  <Col lg="6">
                    <h6>Bag Retension Chart</h6>
                    {analyticsData.retentions?analyticsData.retentions:null}
                  </Col> */}
                  </Row>

                </CardBody>
              </Card>
            </Col>
          </Row>

          <Row>
            <Col lg="12">
              <Card>
                <CardBody>
                  <CardTitle>Orders</CardTitle>
                  <Row>
                    <Col lg="4">
                      <div id="orders-online" className="e-chart">
                        <Pie 
                          options={{
                            toolbox: {
                              show: false,
                            },
                            tooltip: {
                              trigger: "item",
                              formatter: "{a} <br/>{b} : {c} ({d}%)",
                            },
                            legend: {
                              orient: "horizontal",
                              left: "left",
                              data: ["Online", "Offline"],
                              textStyle: {
                                color: ["#74788d"],
                              },
                            },
                            color: ["#02a499", "#f8b425"],
                            series: [
                              {
                                name: "Total Orders",
                                type: "pie",
                                radius: "55%",
                                center: ["50%", "60%"],
                                data: [
                                  { value: orderCounts.online, name: "Online" },
                                  { value: orderCounts.offline, name: "Offline" }
                                ],
                                itemStyle: {
                                  emphasis: {
                                    shadowBlur: 10,
                                    shadowOffsetX: 0,
                                    shadowColor: "rgba(0, 0, 0, 0.5)",
                                  },
                                },
                              },
                            ],
                          }} 
                          />
                      </div>
                    </Col>
                    <Col lg="4">
                      <div id="orders-online" className="e-chart">
                        <Pie 
                          options={{
                            toolbox: {
                              show: false,
                            },
                            tooltip: {
                              trigger: "item",
                              formatter: "{a} <br/>{b} : {c} ({d}%)",
                            },
                            legend: {
                              orient: "horizontal",
                              left: "left",
                              data: ["Carpet Online", "Cloth Online"],
                              textStyle: {
                                color: ["#74788d"],
                              },
                            },
                            color: [ "#ec4561", "#38a4f8"],
                            series: [
                              {
                                name: "Total Orders",
                                type: "pie",
                                radius: "55%",
                                center: ["50%", "60%"],
                                data: [
                                  { value: orderCounts.carpet, name: "Carpet Online" },
                                  { value: orderCounts.clothes, name: "Cloth Online" }
                                ],
                                itemStyle: {
                                  emphasis: {
                                    shadowBlur: 10,
                                    shadowOffsetX: 0,
                                    shadowColor: "rgba(0, 0, 0, 0.5)",
                                  },
                                },
                              },
                            ],
                            }} 
                          />
                      </div>
                    </Col>
                    <Col lg="4">
                      <div id="orders-online" className="e-chart">
                        <Pie 
                          options={{
                            toolbox: {
                              show: false,
                            },
                            tooltip: {
                              trigger: "item",
                              formatter: "{a} <br/>{b} : {c} ({d}%)",
                            },
                            legend: {
                              orient: "horizontal",
                              left: "left",
                              data: ["Online Customers", "Offline Customers"],
                              textStyle: {
                                color: ["#02a499", "#f8b425"],
                              },
                            },
                            color: ["#02a499", "#f8b425"],
                            series: [
                              {
                                name: "Unique Customers",
                                type: "pie",
                                radius: "55%",
                                center: ["50%", "60%"],
                                data: [
                                  { value: uniqueCustomers.online, name: "Online Customers" },
                                  { value: uniqueCustomers.offline, name: "Offline Customers" }
                                ],
                                itemStyle: {
                                  emphasis: {
                                    shadowBlur: 10,
                                    shadowOffsetX: 0,
                                    shadowColor: "rgba(0, 0, 0, 0.5)",
                                  },
                                },
                              },
                            ],
                            }} />
                      </div>
                    </Col>
                    
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>

        </Container>
      </div>
    )
  } else {

    return <Pages404 />
  }

}

Analytics.propTypes = {
  t: PropTypes.any
}

export default withTranslation()(Analytics)
