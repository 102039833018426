import PropTypes from "prop-types"
import React, { useEffect, useState, useRef } from "react"
import ReactApexChart from "react-apexcharts"
import MetaTags from "react-meta-tags";
// import Doughnut from '../AllCharts/echart/doughnutchart';
import DonutChart from "../AllCharts/apex/dountchart"
import BarChart from "../AllCharts/apex/barchart";

import { ThreeDots } from 'react-loader-spinner'
import Pages404 from "pages/Utility/pages-404";
import { LoadScript, GoogleMap, Marker, DrawingManager, useGoogleMap } from "@react-google-maps/api"; 

import Datamap from  "./datamap";

import {
  Container,
  Row,
  Col,
  Button,
  Card,
  CardBody,
  Input,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Media,
  Table,
  CardTitle,
  Badge,
  CardHeader
} from "reactstrap"

import {
  BarChart as ReBarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  Label
} from "recharts";




import paginationFactory, {
  PaginationListStandalone,
  PaginationProvider,
} from "react-bootstrap-table2-paginator";
import { AvForm, AvField } from "availity-reactstrap-validation";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import BootstrapTable from "react-bootstrap-table-next";
import moment from 'moment-timezone';



import { Link } from "react-router-dom"
import { get, post } from "helpers/api_helper"
import { getWeb, postWeb } from "helpers/api_helper"

import classNames from "classnames"

//import Charts
import StackedColumnChart from "./StackedColumnChart"

//import action
import { appRegistartions, getUniqueCustomerOrders, getMonthlyKleenOrders, 
  getMonthlyLaundryOrders, getLaundrylist,  getMonthlyKleenGMV, getMonthlyLaundryGMV,
  getDailyLaundryData, getDailyOnlineData, getAllOrdersSearch} from "../../store/actions"
import { useDispatch, useSelector } from "react-redux"
import {searchUsers } from "../../store/actions"

import modalimage1 from "../../assets/images/product/img-7.png"
import modalimage2 from "../../assets/images/product/img-4.png"

// Pages Components
import WelcomeComp from "./WelcomeComp"
import MonthlyEarning from "./MonthlyEarning"
import SocialSource from "./SocialSource"
import ActivityComp from "./ActivityComp"
import TopCities from "./TopCities"
import LatestTranaction from "./LatestTranaction"
import CardUser from "../Dashboard-Blog/CardUser";
import Pie from "../AllCharts/echart/piechart"
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"
import io from "socket.io-client";

//i18n
import { withTranslation } from "react-i18next"

//redux
// import { useSelector, useDispatch } from "react-redux"
import { values } from "lodash";
import Zoom from "./datamaps.zoom"



const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

const DailyReports = props => {
  const [loadingAppRegistrations, SetLoadingAppRegistrations] = useState(false);
  const [loadingUniqueOrders, SetLoadingUniqueOrders] = useState(false);
  const [loadingKleenOrders, SetLoadingKleenOrders] = useState(false);
  const [loadingLaundryOrders, SetLoadingLaundryOrders] = useState(false);
  const [orderLaundry, setOrderLaundry] = useState(0);

  const [loadingKleenGMV, SetLoadingKleenGMV] = useState(false);
  const [loadingLaundryGMV, SetLoadingLaundryGMV] = useState(false);

  const [loadingDailyLaundry, SetLoadingDailyLaundry] = useState(false);
  const [loadingDailyOnline, SetLoadingDailyOnline] = useState(false);
  const [onlineMarkers, SetOnlineMarkers] = useState([]);
  const [offlineMarkers, SetOfflineMarkers] = useState([]);
  const [mapScale, SetMapScale] = useState(2000);
  const [dateValues, SetDateValues] = useState(null);
  const [userData, setUserData] = useState({});
  

  const mapRef = useRef(null);


  const { appRegistrationsData, uniqueCustomerOrders, monthlyKleenOrders, 
    monthlyLaundryOrders, laundrylist, monthlyKleenGMV, monthlyLaundryGMV,
    dailyLaundryData, dailyOnlineData, Allorders, users
  } = useSelector(state => ({
    appRegistrationsData: state.Dashboard.appRegistrationsData,
    uniqueCustomerOrders: state.Dashboard.uniqueCustomerOrders,
    monthlyKleenOrders: state.Dashboard.monthlyKleenOrders,
    monthlyLaundryOrders: state.Dashboard.monthlyLaundryOrders,
    laundrylist :state.ecommerce.laudrylist,
    monthlyKleenGMV: state.Dashboard.monthlyKleenGMV,
    monthlyLaundryGMV: state.Dashboard.monthlyLaundryGMV,
    dailyLaundryData: state.Dashboard.dailyLaundryData,
    dailyOnlineData: state.Dashboard.dailyOnlineData,
    Allorders: state.Orders.Allorders,
    users : state.Users.users
  }))



  var node = useRef();
  const keyField = "id";
  const { SearchBar } = Search;

  const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

  const options = null;
  

  const loaderColor = "#0097CE";
 const dispatch = useDispatch();


useEffect(() => {
  if(users.length> 0){
    setUserData(users[0]);
  }
}, [users])


 useEffect(() => {
  SetLoadingAppRegistrations(false);
  }, [appRegistrationsData])

  useEffect(() => {
    SetLoadingUniqueOrders(false);
    }, [uniqueCustomerOrders])

  useEffect(() => {
    SetLoadingKleenOrders(false);
  }, [monthlyKleenOrders])

  useEffect(() => {
    SetLoadingLaundryOrders(false);
  }, [monthlyLaundryOrders])


  useEffect(() => {
    SetLoadingKleenGMV(false);
  }, [monthlyKleenGMV])

  useEffect(() => {
    SetLoadingLaundryGMV(false);
  }, [monthlyLaundryGMV])

  useEffect(() => {
    SetLoadingDailyOnline(false);
  }, [dailyOnlineData])

  useEffect(() => {
    SetLoadingDailyLaundry(false);
  }, [dailyLaundryData])

    
  var data = JSON.parse(localStorage.getItem("authUser"));
  const [fill, setFill] = useState({});
  const { permission } = useSelector(state => ({
    permission: state.permission.permissions
  }))

  // useEffect(() => {
  
  // }, [markers]);

  useEffect(() => {
    dispatch(searchUsers({webPermissions: true}))

    let today =  getFormattedDate(new Date());
    SetDateValues({start: today});

    loadDailyData(today);

  

  }, [])

  const onOrderLaundryChange = (e) => {
    const laundryId = e.target.value;
    setOrderLaundry(laundryId);
    SetLoadingLaundryOrders(true);
    if(laundryId != undefined){
      dispatch(getMonthlyLaundryOrders(laundryId));
    }
  };

  const onGMVLaundryChange = (e) => {
    const laundryId = e.target.value;
    SetLoadingLaundryGMV(true);
    if(laundryId != undefined){
      dispatch(getMonthlyLaundryGMV(laundryId));
    }
  };

  const dailyOnlineReload = () => {
    SetLoadingDailyOnline(true);
    dispatch(getDailyOnlineData({}));
  }

  const dailyLaundryReload = () => {
    SetLoadingDailyLaundry(true);
    dispatch(getDailyLaundryData({}));
  }
  
  const onMapLoad = (map) =>{
    mapRef.current = map;
  }

  

  useEffect(() => {
    const filterProduct = permission?.filter((item) => item?.role === data?.role)
    setFill(filterProduct[0])
  }, [permission])

 

  useEffect(() => {
    let _onlineOrders = [];
    let _offlineOrders = [];

    Allorders.forEach(order => {
        if(order.category == "online"){
          _onlineOrders.push({
            name : "#" + order.orderID,
            radius: 10, 
            latitude: parseFloat(order.location?.latitude), 
            longitude: parseFloat(order.location?.longitude), 
          })
        }

        if(order.category == "offline"){
          _offlineOrders.push({
            name : "#" + order.orderID,
            radius: 10, 
            latitude: parseFloat(order.bills[0]?.laundry?.latitude), 
            longitude: parseFloat(order.bills[0]?.laundry?.longitude), 
          })
        }

    });

   SetOnlineMarkers(_onlineOrders);
   SetOfflineMarkers(_offlineOrders);

  }, [Allorders])

  const zoomIn = () => {
    let currentZoom = mapScale;
    currentZoom+= 100;
    SetMapScale(currentZoom);
  }

  const zoomout = () => {
    let currentZoom = mapScale;
    if(currentZoom > 1100){
      currentZoom-= 100;
      SetMapScale(currentZoom);
    }
  }

  const onSubmit = async (values) => {
    SetDateValues({
      start : values.start
    });
  }

  const filterByDate = (e) => {
    loadDailyData(dateValues?.start);
  };

  const getFormattedDate = (dt) => {
    const separator = "-";
    let newDate = dt;
    let date = newDate.getDate();
    let month = newDate.getMonth() + 1;
    let year = newDate.getFullYear();

    return  `${year}${separator}${month<10?`0${month}`:`${month}`}${separator}${date<10?`0${date}`:`${date}`}`
  };

  const changeDate = (e) => {
    SetDateValues({start : e.target.value});
  }

  const loadDailyData = (dt) => {
    if(dt != null || dt != undefined){
      SetLoadingDailyOnline(true);
      dispatch(getDailyOnlineData({date: dt}));

      SetLoadingDailyLaundry(true);
      dispatch(getDailyLaundryData({date: dt}));
    }
  } 


  

  
  if(userData?.permissions?.allPermissions==true || userData?.permissions?.panelDailyReports?.read==true){
    return(
      <div className="page-content">
      <MetaTags>
        <title>Analytics | Kleen Admin Panel</title>
      </MetaTags>
      <Container fluid>
        {/* Render Breadcrumb */}
        <Breadcrumbs
          title={props.t("Home")}
          breadcrumbItem={props.t("Daily Reports")}
        />







<Row>

<Col lg="12">
  <Card>
    <CardHeader>
  
    </CardHeader>
    <CardBody>
    <Row>
          <Col>
           
                
                  
                    {/* <CardTitle className="h4">Textual inputs</CardTitle> */}

                    <Row className="mb-3">
                      
                      <div className="col-md-2 d-flex align-items-center justify-content-between">
                      <label
                        htmlFor="example-date-input"
                        className="text-left d-flex "
                      >
                        Date
                      </label>
                        <Input
                          className="form-control d-flex ml-2"
                          type="date"
                          defaultValue={dateValues?.start}
                          id="start"
                          name="start"
                          value={dateValues?.start}
                          style={{width:"65%"}}
                          onChange={(e) => {changeDate(e)}}
                        />

                      </div>
                      
                      <div className="col-md-2">
                        {loadingDailyOnline ?
                          <Button
                            color="success"
                            className="btn-rounded "
                            type="button"
                            disabled
                          >
                            <i className="bx bx-loader bx-spin font-size-18 align-middle me-2"></i>
                            Processing...
                          </Button> :
                          <Button
                            color="success"
                            className="btn-rounded "
                            type="button"
                            onClick={filterByDate}
                          >
                            Filter Result
                          </Button>}
                      </div>

                      

                    </Row>

                  
          </Col>
        </Row>
        </CardBody>
      </Card>
    </Col>
    </Row>

    <Row>

      <Col lg="12">
        <Card>
          <CardHeader>
            <h5>Online Orders (Received)
              {/* <button 
                onClick={dailyOnlineReload}
                className="btn btn-link p-0"> <i className="fs-3 mdi mdi-refresh-circle"></i> </button>   */}
            </h5>
          </CardHeader>
          <CardBody>
          
          <Row>
            <Col lg="12">
             
        
          {loadingDailyOnline ?
            <ThreeDots
              height="80"
              width="80"
              radius="9"
              color={loaderColor}
              ariaLabel="three-dots-loading"
              wrapperStyle={{}}
              wrapperClassName=""
              visible={loadingDailyOnline}
            /> :
            <Row>
              <Col lg="6">
                <div className="table-responsive">
                  <Table className="table mb-0">
                    <tbody>
                      <tr>
                        <th scope="row">Total Orders</th>
                        <td>{dailyOnlineData.villa + dailyOnlineData.apartment}</td>
                      </tr>
                      <tr>
                        <th scope="row">Apartment</th>
                        <td>{dailyOnlineData.apartment}</td>
                      </tr>
                      <tr>
                        <th scope="row">Villa</th>
                        <td>{dailyOnlineData.villa}</td>
                      </tr>
                    </tbody>
                  </Table>
                </div>
              </Col>
             
              <Col lg="5">
                <div className="table-responsive">
                  <Table className="table mb-0">
                    <tbody>
                      <tr>
                        <th scope="row">New Customer Orders</th>
                        <td>{dailyOnlineData.villaNew + dailyOnlineData.apartmentNew}</td>
                      </tr>
                      <tr>
                        <th scope="row">New Customers - Apartment</th>
                        <td>{dailyOnlineData.apartmentNew}</td>
                      </tr>
                      <tr>
                        <th scope="row">New Customers - Villa</th>
                        <td>{dailyOnlineData.villaNew}</td>
                      </tr>
                    </tbody>
                  </Table>
                </div>
              </Col>
            </Row>
          }
        </Col>

</Row>
</CardBody>
</Card>
</Col>
</Row>


  
<Row>

<Col lg="12">
  <Card>
    <CardHeader>
      <h5>Daily Laundry Report (Invoiced) 
        {/* <button 
          onClick={dailyOnlineReload}
          className="btn btn-link p-0"> <i className="fs-3 mdi mdi-refresh-circle"></i> </button>   */}
      </h5>
    </CardHeader>
    <CardBody>
    
      <Row className="mt-3">
        <Col lg="10">
        
          {loadingDailyLaundry ?
            <ThreeDots
              height="80"
              width="80"
              radius="9"
              color={loaderColor}
              ariaLabel="three-dots-loading"
              wrapperStyle={{}}
              wrapperClassName=""
              visible={loadingDailyLaundry}
            /> :
            <div className="table-responsive">
                    <Table className="table mb-0">
                      <thead>
                        <tr>
                          <th>Laundry Name</th>
                          <th>Offline Orders</th>
                          <th>Online Orders</th>
                          <th>Offline GMV</th>
                          <th>Online GMV</th>
                          <th>New Customers</th>
                        </tr>
                      </thead>
                      <tbody>
                        {dailyLaundryData.map((row) => {
                          return <tr key={row.name}>
                            <th scope="row">{row.name}</th>
                            <td>{row.offlineCount}</td>
                            <td>{row.onlineCount}</td>
                            <td>{row.offlineGMV}</td>
                            <td>{row.onlineGMV}</td>
                            <td>{row.newCustomers}</td>
                          </tr>
                        })}
                        
                        
                      </tbody>
                    </Table>
                  </div>
          }
        </Col>

</Row>


</CardBody>
</Card>
</Col>
</Row>



      </Container>
    </div> 
    )
  }else{
    return <Pages404/>
  }



  // return (
  
  //   <React.Fragment>
   
   
  
     
  

    

  //     {/* subscribe ModalHeader */}
  //     <Modal
  //       isOpen={subscribemodal}
  //       role="dialog"
  //       autoFocus={true}
  //       centered
  //       data-toggle="modal"
  //       toggle={() => {
  //         setSubscribemodal(!subscribemodal)
  //       }}
  //     >
  //       <div className="modal-content">
  //         <div className="modal-header border-bottom-0">
  //           <ModalHeader
  //             toggle={() => {
  //               setSubscribemodal(!subscribemodal)
  //             }}
  //           ></ModalHeader>
  //         </div>
  //         <div className="modal-body">
  //           <div className="text-center mb-4">
  //             <div className="avatar-md mx-auto mb-4">
  //               <div className="avatar-title bg-light  rounded-circle text-primary h1">
  //                 <i className="mdi mdi-email-open"></i>
  //               </div>
  //             </div>

  //             <div className="row justify-content-center">
  //               <div className="col-xl-10">
  //                 <h4 className="text-primary">Subscribe !</h4>
  //                 <p className="text-muted font-size-14 mb-4">
  //                   Subscribe our newletter and get notification to stay update.
  //                 </p>

  //                 <div
  //                   className="input-group rounded bg-light"
  //                 >
  //                   <Input
  //                     type="email"
  //                     className="form-control bg-transparent border-0"
  //                     placeholder="Enter Email address"
  //                   />
  //                   <Button color="primary" type="button" id="button-addon2">
  //                     <i className="bx bxs-paper-plane"></i>
  //                   </Button>
  //                 </div>
  //               </div>
  //             </div>
  //           </div>
  //         </div>
  //       </div>
  //     </Modal>

  //     <Modal
  //       isOpen={modal}
  //       role="dialog"
  //       autoFocus={true}
  //       centered={true}
  //       className="exampleModal"
  //       tabIndex="-1"
  //       toggle={() => {
  //         setmodal(!modal)
  //       }}
  //     >
  //       <div className="modal-content">
  //         <ModalHeader
  //           toggle={() => {
  //             setmodal(!modal)
  //           }}
  //         >
  //           Order Details
  //         </ModalHeader>
  //         <ModalBody>
  //           <p className="mb-2">
  //             Product id: <span className="text-primary">#SK2540</span>
  //           </p>
  //           <p className="mb-4">
  //             Billing Name: <span className="text-primary">Neal Matthews</span>
  //           </p>

  //           <div className="table-responsive">
  //             <Table className="table table-centered table-nowrap">
  //               <thead>
  //                 <tr>
  //                   <th scope="col">Product</th>
  //                   <th scope="col">Product Name</th>
  //                   <th scope="col">Price</th>
  //                 </tr>
  //               </thead>
  //               <tbody>
  //                 <tr>
  //                   <th scope="row">
  //                     <div>
  //                       <img src={modalimage1} alt="" className="avatar-sm" />
  //                     </div>
  //                   </th>
  //                   <td>
  //                     <div>
  //                       <h5 className="text-truncate font-size-14">
  //                         Wireless Headphone (Black)
  //                       </h5>
  //                       <p className="text-muted mb-0">$ 225 x 1</p>
  //                     </div>
  //                   </td>
  //                   <td>$ 255</td>
  //                 </tr>
  //                 <tr>
  //                   <th scope="row">
  //                     <div>
  //                       <img src={modalimage2} alt="" className="avatar-sm" />
  //                     </div>
  //                   </th>
  //                   <td>
  //                     <div>
  //                       <h5 className="text-truncate font-size-14">
  //                         Hoodie (Blue)
  //                       </h5>
  //                       <p className="text-muted mb-0">$ 145 x 1</p>
  //                     </div>
  //                   </td>
  //                   <td>$ 145</td>
  //                 </tr>
  //                 <tr>
  //                   <td colSpan="2">
  //                     <h6 className="m-0 text-end">Sub Total:</h6>
  //                   </td>
  //                   <td>$ 400</td>
  //                 </tr>
  //                 <tr>
  //                   <td colSpan="2">
  //                     <h6 className="m-0 text-end">Shipping:</h6>
  //                   </td>
  //                   <td>Free</td>
  //                 </tr>
  //                 <tr>
  //                   <td colSpan="2">
  //                     <h6 className="m-0 text-end">Total:</h6>
  //                   </td>
  //                   <td>$ 400</td>
  //                 </tr>
  //               </tbody>
  //             </Table>
  //           </div>
  //         </ModalBody>
  //         <ModalFooter>
  //           <Button
  //             type="button"
  //             color="secondary"
  //             onClick={() => {
  //               setmodal(!modal)
  //             }}
  //           >
  //             Close
  //           </Button>
  //         </ModalFooter>
  //       </div>
  //     </Modal>
  //   </React.Fragment>
  // )
}

DailyReports.propTypes = {
  t: PropTypes.any
}

export default withTranslation()(DailyReports)
