import {
  GET_B2B_LIST,
  GET_B2B_LIST_SUCCESS,
  GET_B2B_LIST_FAIL

} from "./actionTypes"

const INIT_STATE = {
  b2blist: []
}

const b2bCustomers = (state = INIT_STATE, action) => {
  switch (action.type) {

    case GET_B2B_LIST_SUCCESS:
      return {
        ...state,
        b2blist: action.payload.record,
      }

    case GET_B2B_LIST_FAIL:
      return {
        ...state,
        error: action.payload,
      }
  
     
    default:
      return state
  }
}

export default b2bCustomers;
