import {
  GET_REASONS_LIST,
  GET_REASONS_LIST_SUCCESS,
  GET_REASONS_LIST_FAIL,

  UPDATE_REASON,
  UPDATE_REASON_SUCCESS,
  UPDATE_REASON_FAIL,

  ADD_NEW_REASON,
  ADD_NEW_REASON_SUCCESS,
  ADD_NEW_REASON_FAIL,

  DELETE_DRIVER,
  DELETE_REASON_SUCCESS,
  DELETE_REASON_FAIL,

} from "./actionTypes"

export const getReasonsList = (data) => ({
  type: GET_REASONS_LIST,
  payload: data
})

export const getReasonsListSuccess = (data) => ({
  type: GET_REASONS_LIST_SUCCESS,
  payload: data
})

export const getReasonsListFail = (data) => ({
  type: GET_REASONS_LIST_FAIL,
  payload: data
})

export const addNewReason = (data) => ({
  type: ADD_NEW_REASON,
  payload: data
})

export const addNewReasonSuccess = (data) => ({
  type: GET_REASONS_LIST,
  payload: {}
})

export const addNewReasonFail = (data) => ({
  type: ADD_NEW_REASON_FAIL,
  payload: data
})

export const updateReasons = (data) => ({
  type: UPDATE_REASON,
  payload: data
})

export const updateReasonSuccess = (data) => ({
  type: GET_REASONS_LIST,
  payload: {}
})

export const deleteReasonSuccess = (data) => ({
  type: DELETE_REASON_SUCCESS,
  payload: data
})

export const deleteReasonFail = (data) => ({
  type: DELETE_REASON_FAIL,
  payload: data
})

