import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import MetaTags from "react-meta-tags";
import WelcomePage from "pages/Utility/welcome-page";
import driverCar from "../../assets/images/driverCar.svg";
import OffCanvasComponent from "./OffCanvasComponent";
import pickUpLogo from "../../assets/images/pickUpLogo.svg";
import dropOffLogo from "../../assets/images/dropOffLogo.svg";

import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Row,
} from "reactstrap";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
//i18n
import { withTranslation } from "react-i18next";
import { mapLibraries } from "constants/app";
import { GoogleApiWrapper, Map, Marker, InfoWindow } from "google-maps-react";
import Skeleton from "react-loading-skeleton";
import axios from "axios";


const DriverLocations = props => {

  const [drivers, setDrivers] = useState([{}]);
  const [activeMarker, setActiveMarker] = useState(null);
  const [showingInfoWindow, setShowingInfoWindow] = useState(false);
  const [selectedDriver, setSelectedDriver] = useState(null);
  const [mapCenter, setMapCenter] = useState({ lat: 18.600189813903405, lng: 73.7604000068678 });
  const [zoom, setZoom] = useState(10);

  //Canvas states;
  const [isCanvasOpen, setIsCanvasOpen] = useState(false);

  const BASE_URL = process.env.REACT_APP_API_BASE_URL;

  let authUser = localStorage.getItem("authUser");
  authUser = JSON.parse(authUser);

  const fetchData = async () => {
    try {
      const response = await axios.get(BASE_URL + "/apis/drivers/location", {
        headers: {
          "Content-Type": "application/json",
          "Authorization": "Bearer " + authUser.token,
        },
      });
      setMapCenter({lat: response.data[0].location?.latitude, lng: response.data[0].location?.longitude});
      console.log("Driver Response:: ",response.data);
      setDrivers(response.data);
    } catch (error) {
      console.error("Error:", error);
    }
  }

  // To fetch the data on page load.
  useEffect(() => {
    fetchData();
  }, [])

  // To fetch the data every 30 seconds after the page load.
  useEffect(() => {
    const timeOut = setInterval(() => {
      fetchData();
    }, 60000);
    return () => clearTimeout(timeOut);
  }, [drivers]);

  const onMarkerClick = (marker, order) => {
    setActiveMarker(marker);
    setSelectedDriver(order);
    setShowingInfoWindow(true);
  }

  const toggleCanvas = () => {
    setIsCanvasOpen(!isCanvasOpen);
  }

  const [isOpen, setIsOpen] = useState(false);

  const toggle = () => setIsOpen(!isOpen);
  if (true) {
    return (
      <div className="page-content">
        <MetaTags>
          <title>Driver Locaions | Kleen Admin Panel</title>
        </MetaTags>
        <Container fluid>
          <Breadcrumbs
            title={props.t("Driver")}
            breadcrumbItem={props.t("Driver Locations")}
          />
          <Row>
            <Col lg="12">
              <Card>
                <CardHeader style={{ width: '100%', display: 'flex', justifyContent: 'space-between' }}>
                  <h4>Real Time Driver Locations</h4>
                  {
                    drivers.statusCode === 400 ? "" : <Button color="primary" className="btn-sm" onClick={toggle} style={{ marginBottom: '1rem' }}>
                      <span className="mdi mdi-format-list-bulleted"></span> {" "}
                      Driver List
                    </Button>
                  }
                </CardHeader>
                <CardBody style={{ padding: 0 }}>
                  <Col lg="12">
                    <Row style={{ height: '500px', position: 'relative !important', width: '100%', display: 'flex', justifyContent: 'space-between', background: "none !important", overflow:'scroll' }}>

                      {
                        drivers.statusCode === 400 ?
                          <Map
                            google={props.google}
                            zoom={zoom}
                            style={{ width: isOpen ? '72%' : '100%', overflow: 'hidden', height: '100%' }}
                            initialCenter={{ lat: 18.600189813903405, lng: 73.7604000068678 }}
                            center={mapCenter}
                            zoomControl={true}
                            mapTypeControl={true}
                            scaleControl={true}
                          ></Map> 
                          :
                          <Map
                            google={props.google}
                            zoom={zoom}
                            style={{ width: isOpen ? '72%' : '100%', overflow: 'hidden', height: '100%' }}
                            initialCenter={{ lat: 18.600189813903405, lng: 73.7604000068678 }}
                            center={mapCenter}
                            zoomControl={true}
                            mapTypeControl={true}
                            scaleControl={true}
                          >
                            {drivers.map(driver => (
                              <Marker
                                key={driver.driverID}
                                position={{ lat: driver?.location?.latitude, lng: driver?.location?.longitude }}
                                title={driver.driverName}
                                icon={{
                                  // Change the icon to a car icon.
                                  url: driverCar,
                                  scaledSize: new props.google.maps.Size(40, 40)
                                }}
                                label={
                                  {
                                    text: driver.driverName,
                                    color: "white",
                                    className: "customMarker"
                                  }
                                }
                                onClick={(props, marker) => onMarkerClick(marker, driver)}
                              />
                            ))}

                            {/* Pickup Order's Markers */}
                            {drivers?.map((driver, index) => {
                              const pickupOrders = driver?.pickupOrder || [];
                              return pickupOrders.map((pickup) => (
                                <Marker
                                  key={index}
                                  position={{ lat: pickup?.location?.latitude, lng: pickup?.location?.longitude }}
                                  title={"Pickup Order by " + driver.driverName}
                                  icon={{
                                    url: pickUpLogo,
                                    scaledSize: new props.google.maps.Size(40, 40)
                                  }}
                                  label={
                                    {
                                      text: `P: ${pickup?.orderID}`,
                                      color: "white",
                                      className: "customMarkerOrder"
                                    }
                                  }
                                />
                              ));
                            })}

                            {/* Dropoff Order's Markers */}
                            {drivers?.map((driver, index) => {
                              const dropoffOrders = driver?.dropoffOrder || [];
                              return dropoffOrders.map((dropoff) => (
                                <Marker
                                  key={{index}}
                                  position={{ lat: dropoff.location?.latitude, lng: dropoff.location?.longitude }}
                                  title={"Dropoff Order by " + driver.driverName}
                                  icon={{
                                    url: dropOffLogo,
                                    scaledSize: new props.google.maps.Size(40, 40)
                                  }}
                                  label={
                                    {
                                      text: `D: ${dropoff?.orderID}`,
                                      color: "white",
                                      className: "customMarkerOrder"
                                    }
                                  }
                                />
                              ));
                            })}                         

                            {/* Individual Driver when clicked on a marker on the map. */}
                            <InfoWindow
                              marker={activeMarker}
                              visible={showingInfoWindow}
                            >
                              <div>
                                <h6>Name: {selectedDriver?.driverName}</h6>
                                <p>Updated: {timeAgo(selectedDriver?.lastUpdated)}</p>
                              </div>
                            </InfoWindow>
                          </Map>
                      }
                      <OffCanvasComponent
                        isOpen={isOpen}
                        toggleCanvas={toggleCanvas}
                        isCanvasOpen={isCanvasOpen}
                        drivers={drivers}
                        setSelectedDriver={setSelectedDriver}
                        setMapCenter={setMapCenter}
                        setZoom={setZoom}
                        mapCenter={mapCenter}
                      />
                    </Row>
                  </Col>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    )
  } else {
    if (pageLoading)
      return (
        <div className="page-content">
          <Container fluid>
            {/* Render Breadcrumb */}
            <Breadcrumbs
            />

            <Skeleton />
          </Container>
        </div>
      )
    else
      return <WelcomePage />
  }
}

DriverLocations.propTypes = {
  t: PropTypes.any,
  google: PropTypes.any
}

export default withTranslation()(GoogleApiWrapper({
  apiKey: "AIzaSyAvZvBwMKrllZNCXdikzvCDOzu8rwqv-0c",
  libraries: mapLibraries
})(DriverLocations));

// Function to render the date and time in verbal format (E.G: mins ago, hrs ago etc.)
export function timeAgo(timestamp) {
  if (typeof timestamp === 'object' && timestamp.seconds !== undefined && timestamp.nanoseconds !== undefined) {
    // Convert Firestore timestamp to milliseconds
    const milliseconds = (timestamp.seconds * 1000) + (timestamp.nanoseconds / 1000000);

    // Create a Date object from milliseconds
    const date = new Date(milliseconds);

    // Calculate the time difference in seconds
    const secondsDifference = Math.floor((new Date() - date) / 1000);

    if (secondsDifference < 60) {
      return `${secondsDifference} second${secondsDifference !== 1 ? 's' : ''} ago`;
    } else if (secondsDifference < 3600) {
      const minutesDifference = Math.floor(secondsDifference / 60);
      return `${minutesDifference} minute${minutesDifference !== 1 ? 's' : ''} ago`;
    } else if (secondsDifference < 86400) {
      const hoursDifference = Math.floor(secondsDifference / 3600);
      return `${hoursDifference} hour${hoursDifference !== 1 ? 's' : ''} ago`;
    } else {
      const daysDifference = Math.floor(secondsDifference / 86400);
      return `${daysDifference} day${daysDifference !== 1 ? 's' : ''} ago`;
    }
  } else {
    const now = new Date();
    const timestampDate = new Date(timestamp);

    const seconds = Math.floor((now - timestampDate) / 1000);
    const minutes = Math.floor(seconds / 60);
    const hours = Math.floor(minutes / 60);
    const days = Math.floor(hours / 24);

    if (days > 0) {
      return `${days} day${days !== 1 ? 's' : ''} ago`;
    } else if (hours > 0) {
      return `${hours} hour${hours !== 1 ? 's' : ''} ago`;
    } else if (minutes > 0) {
      return `${minutes} minute${minutes !== 1 ? 's' : ''} ago`;
    } else {
      return `${seconds} second${seconds !== 1 ? 's' : ''} ago`;
    }
  }
}

