import React, { useState } from "react"
import MetaTags from 'react-meta-tags';
import { Button, Card, CardBody, Col, Container, Modal, Input, Label, Row } from "reactstrap"
import "@vtaits/react-color-picker/dist/index.css"
import "react-datepicker/dist/react-datepicker.css"
//Import Flatepicker
import "flatpickr/dist/themes/material_blue.css"
import "assets/css/Allorders.css";
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table"

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux"
import {
  getAllModules, createModules, updateModules
} from "store/actions"
import PropTypes from "prop-types";
import toastr from "toastr";
import "toastr/build/toastr.min.css";
import Select from "react-select"
import { getPermission, searchUsers  } from "../../store/actions"
import Pages404 from "pages/Utility/pages-404";

const RolePermission = () => {
  const dispatch = useDispatch();
  const [allModules, setAllModules] = useState([]);
  const [moduleModal, setModuleModal] = useState(false);
  const [moduleName, setModuleName] = useState("");
  const [moduleApis, setModuleApis] = useState("");
  const [error, setError] = useState({});
  const [moduleType, setModuleType] = useState({ label: "Api", value: "api" });
  const [editModuleId, setEditModuleId] = useState(0);
  const [isEdit, setIsEdit] = useState(false);
  const [modulePermission, setModulePermission] = useState([])
  const [apiProcessing, setApiProcessing] = useState(false);
  const [userData, setUserData] = useState({});

  const { permissions, allModule, apiErrorMsg, apiSuccessMsg, users } = useSelector(state => ({
    permissions: state.permission.permissions,
    allModule: state.permission.allModule,
    apiErrorMsg: state.permission.apiErrorMsg,
    apiSuccessMsg: state.permission.apiSuccessMsg,
    users : state.Users.users
  }))

  useEffect(() => {
    dispatch(getAllModules({}))
   if(!users) dispatch(searchUsers({webPermissions: true}))
  }, [])

  useEffect(() => {
    if(users.length> 0){
      setUserData(users[0]);
    }
  }, [users])

  useEffect(() => {
    
    if(apiErrorMsg.msg != "" && apiProcessing){
      toastr.error(apiErrorMsg.msg, "Error Occured");
      setApiProcessing(false);
    }    
  }, [apiErrorMsg])

  useEffect(() => {
    
    if(apiSuccessMsg.msg != "" && apiProcessing){
      toastr.success(apiSuccessMsg.msg, apiSuccessMsg.title);
      setApiProcessing(false);
    }    
  }, [apiSuccessMsg])


  useEffect(() => {
    console.log("allModule", allModule);
    if (allModule?.length) {
      setAllModules(allModule);
    }
  }, [allModule])

  useEffect(() => {
    if (!moduleModal) {
      setModuleApis("");
      setModuleName("");
      setError({});
      setModulePermission([]);
      setModuleType({ label: "Api", value: "api" })
    }
  }, [moduleModal])

  const handleModule = () => {
    if (!moduleName) {
      setError({ moduleName: "Permission Name is Required" })
      return
    }
   
    
    let apiData = {
      name: moduleName
    }
    let msg = "Permission added Successfully"
    setApiProcessing(true);

    if (isEdit) {
      apiData["_id"] = editModuleId;
      dispatch(updateModules(apiData));
      msg = "Module Updated Successfully"
    } else {
      dispatch(createModules(apiData))
    }
    setModuleModal(!moduleModal);
   
  }

  const showEditModulePermission = (item) => {
    setIsEdit(true);
    setEditModuleId(item._id);
    setModuleName(item.moduleName);
    if (item.moduleType == "api") {
      setModuleApis(item.permissions.join(", "))
      setModuleType({ label: "Api", value: "api" })
    } else if (item.moduleType == "admin_panel") {
      setModulePermission(item.permissions)
      setModuleType({ label: "Admin Panel", value: "admin_panel" })
    }
    // setModuleApis(modulePermission.toString())
    setModuleModal(true)
  }

  // const updateModule = () => {
  //   if (!moduleApis) {
  //     setError({ apiName: "Api's Name is Required" })
  //     return
  //   }
  //   let rolePermissionObj = allModules;
  //   let moduleApiArr = rolePermissionObj[moduleName];
  //   let updateObject = { ...rolePermissionObj, [moduleName]: [...moduleApiArr, ...moduleApis.split(",")] }
  //   let fullObject = allModule[0];
  //   fullObject.moduleApis = updateObject;
  //   dispatch(updateModules(fullObject))
  //   setModuleModal(!moduleModal);
  //   toastr.success("Module Updated Successfully", "Module permission");
  //   setTimeout(() => {
  //     window.location.reload();
  //   }, 500);
  // }

  const handleSelectmoduleType = (item) => {
    setModuleType(item)
  }

  const handleModulePermission = (item) => {
    let oldData = [...modulePermission];
    let indexOfItem = oldData.indexOf(item);
    if (indexOfItem !== -1) {
      oldData.splice(indexOfItem, 1);
    } else {
      oldData.push(item)
    }
    setModulePermission(oldData)
  }

  var data = JSON.parse(localStorage.getItem("authUser"));
  const [fill, setFill] = useState({});
  const { permission } = useSelector(state => ({
    permission: state.permission.permissions
  }))


  useEffect(() => {
   if(!permission) dispatch(getPermission({}))
  }, [])

  useEffect(() => {
    const filterProduct = permission?.filter((item) => item?.role === data?.role)
    setFill(filterProduct[0])
  }, [permission])



  
    if(userData?.permissions?.allPermissions==true){
      return(
        <React.Fragment>
        <div className="page-content">
          <MetaTags>
            <title>Module Permission</title>
          </MetaTags>
          <Container fluid={true}>
            <Breadcrumbs title="Home" breadcrumbItem="Module Permission" />
  
            <Row>
              <Col lg="12">
                <Card>
                  <CardBody>
                    <h4 className="card-title">Permissions</h4>
                   
                    {/* <Row>
                      <Col lg="12" className="mt-4" style={{ textAlign: "right" }}>
                        {
                           (data?.role === "admin") ? 
                           <Button className="ml-1" onClick={() => { setIsEdit(false); setModuleModal(true); }}>Add module</Button> : null
                        }
                        
                      </Col>
                    </Row> */}
  
                    <div className="table-rep-plugin mt-4">
                      <div
                        className="table-responsive mb-0"
                        data-pattern="priority-columns"
                      >
                        <Table
                          id="tech-companies-1"
                          className="table table-striped table-bordered"
                        >
                          <Thead>
                            <Tr>
                              <Th>Permission</Th>                             
                            </Tr>
                          </Thead>
                          <Tbody>
                            {
                              allModules.map((item) => {
                                return (
                                  <Tr key={item._id}>
                                    <Td>{item.name}</Td>
                                  </Tr>
                                )
                              })
                            }
  
                          </Tbody>
                        </Table>
                      </div>
                    </div>
  
                    <Modal
                      size="lg"
                      isOpen={moduleModal}
                      toggle={() => setModuleModal(!moduleModal)}
                    >
                      <div className="modal-header">
                        <h5 className="modal-title mt-0" id="myModalLabel">
                          <span >
                            {isEdit ? "Update Permission" : "Add New Permission"}
                          </span>
                        </h5>
                        <button
                          type="button"
                          onClick={() => {
                            setModuleModal(false);
                          }}
                          className="close"
                          data-dismiss="modal"
                          aria-label="Close"
                        >
                          <span aria-hidden="true">&times;</span>
                        </button>
                      </div>
                      <div className="modal-body">
                        <Row>
                          <Col lg="12">
                            <div className="mb-3">
                              <Label>Enter Module</Label>
                              <Input
                                disabled={isEdit}
                                name="moduleName"
                                type="text"
                                className={error.moduleName ? "form-control error" : "form-control"}
                                placeholder="Enter Module name"
                                value={moduleName}
                                onChange={(val) => setModuleName(val.target.value)}
                              />
                              {
                                error.moduleName ? (
                                  <Label style={{ color: "red" }}>{error.moduleName}</Label>
                                ) : null
                              }
                            </div>
                          </Col>
                          
                         
                          <Col lg="12">
                            <Button
                              type="submit"
                              color="primary"
                              className="w-md"
                              onClick={handleModule}
                            >
                              {isEdit ? "Update Module" : "Add Module"}
                            </Button>
                          </Col>
                        </Row>
                      </div>
  
                    </Modal>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
      )
    }else{
      return <Pages404/>
    }
 



}

RolePermission.propTypes = {
  getPermission: PropTypes.func
}


export default RolePermission   
