import {
  GET_HOLIDAY_LIST_SUCCESS,
  GET_HOLIDAY_LIST_FAIL,
  GET_HOLIDAY_DETAIL_SUCCESS,
  GET_HOLIDAY_DETAIL_FAIL
} from "./actionTypes"

const INIT_STATE = {
  Holidays: [],
  HolidayDetails: {}
}

const RedeemPackages = (state = INIT_STATE, action) => {
  switch (action.type) {

    case GET_HOLIDAY_LIST_SUCCESS:
      return {
        ...state,
        Holidays: action.payload.record,
      }
    
    case GET_HOLIDAY_DETAIL_SUCCESS:
      return {
        ...state,
        HolidayDetails: action.payload.record,
      }

    case GET_HOLIDAY_LIST_FAIL:
    case GET_HOLIDAY_DETAIL_FAIL : 
      return {
        ...state,
        error: action.payload,
      }
  

    default:
      return state
  }
}

export default RedeemPackages
