import PropTypes from "prop-types"
import React, { useEffect, useState, useRef } from "react"
import MetaTags from "react-meta-tags";
// import Doughnut from '../AllCharts/echart/doughnutchart';
import DonutChart from "../AllCharts/apex/dountchart"
import BarChart from "../AllCharts/apex/barchart";
import { Formik, Field, Form } from 'formik';

import {
  Container,
  Row,
  Col,
  Button,
  Card,
  CardBody,
  Input,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Media,
  Table,
  CardTitle,
  Badge
} from "reactstrap"

import paginationFactory, {
  PaginationListStandalone,
  PaginationProvider,
} from "react-bootstrap-table2-paginator";
import { AvForm, AvField } from "availity-reactstrap-validation";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import BootstrapTable from "react-bootstrap-table-next";
import * as moment from "moment";


import { Link } from "react-router-dom"
import {get, post} from "helpers/api_helper"

import classNames from "classnames"

//import Charts
import StackedColumnChart from "./StackedColumnChart"

//import action
import { getRealTimeDetails, getTop4Data } from "../../store/actions"

import modalimage1 from "../../assets/images/product/img-7.png"
import modalimage2 from "../../assets/images/product/img-4.png"

// Pages Components
import WelcomeComp from "./WelcomeComp"
import MonthlyEarning from "./MonthlyEarning"
import SocialSource from "./SocialSource"
import ActivityComp from "./ActivityComp"
import TopCities from "./TopCities"
import LatestTranaction from "./LatestTranaction"
import CardUser from "../Dashboard-Blog/CardUser";
import Pie from "../AllCharts/echart/piechart"
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"

//i18n
import { withTranslation } from "react-i18next"

//redux
import { useSelector, useDispatch } from "react-redux"
import { values } from "lodash";

const Dashboard = props => {
  const [modal, setmodal] = useState(false)
  const [subscribemodal, setSubscribemodal] = useState(false)
  const [analyticsData, SetAnalyticsData] = useState({})
  const [realTimeData, SetrealTimeData] = useState({inprocess_orders:0, todays_pickup: 0, todays_dropoff: 0, dropoff_5h: 0, pickup_after5h: 0})
  const [realTimeModal, SetRealTimeModal] = useState(false);
  const [orderListTitle, SetOrderListTitle] = useState("Orders");
  const [top4DataModal, SetTop4DataModal] = useState(false);
  const [top4FullData, SetTop4FullData] = useState({});
  const [top4ModalTitle, SetTop4ModalTitle] = useState("Top Area by Orders");
  const [top4ModalTotal, SetTop4ModalTotal] = useState(0);


  

  const { chartsData, realTimeDetails, top4Data } = useSelector(state => ({
    chartsData: state.Dashboard.chartsData,
    realTimeDetails : state.Dashboard.realTimeDetails,
    top4Data:state.Dashboard.top4Data
  }))

  const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
  
  var node = useRef();
  const keyField = "id";
  const { SearchBar } = Search;
  const sizePerPage = 10;
  const pageOptions = {
    sizePerPage: sizePerPage,
    totalSize: realTimeDetails.length, // replace later with size(users),
    custom: true,
  };
  const defaultSorted = [
    {
      dataField: "id", // if dataField is not match to any column you defined, it will be ignored.
      order: "desc", // desc or asc
    },
  ];

  const selectRow = {
    mode: "checkbox",
  };

  const contactListColumns = [
    {
      text: "id",
      dataField: "id",
      sort: true,
      hidden: true,
      // eslint-disable-next-line react/display-name
      formatter: row => <>{row._id}</>,
    },
    {
      dataField: "orderID",
      text: "order ID",
      sort: true,
    },
    {
      text: "customer ID",
      dataField: "customer.customerID",
      sort: true,
      // eslint-disable-next-line react/display-name
    },
    {
      text: "start Time",
      dataField: "startTime",
      sort: true,
      formatter: field => <>{moment(field).format("DD/MM/YYYY hh:mm:ss A")}</>,
      //;
    },
    {
      text: "Pickup Date",
      dataField: "pickupDate"
    },
    {
      text: "Delivery Date",
      dataField: "deliveryDate"
    },
    {
      text: "Status",
      dataField: "status",
      sort: true,
      formatter: (cell, row) => {
        return <>
          <Badge 
          className={`text-capitalize font-size-12 badge-soft-${getStatusColor(row.status)}`}
          pill
          color="primary">{row.status}</Badge>
          &nbsp;
          <Badge 
          className={`font-size-12 badge-soft-${getStatusColor(row.paid?'paid':'unpaid')}`}
          pill
          color="primary">{row.paid?'Paid':'Unpaid'}</Badge>
        </>
      },
    },
    
  ];



  const options = null;
  const initialData = {
    start :"2021-01-01",
    end : moment().tz("Asia/kuwait").format("YYYY-MM-DD")
   };
  
  useEffect(() => {
    //socket.on("inprogress_orders", data => {console.log("Order Data Updated", data); SetrealTimeData(data)});  

    return () => {
      //socket.off('inprogress_orders');
    };

  });
  

  

  function toggleRTModal() {
    SetRealTimeModal(!realTimeModal);
    removeBodyCss();
  }

  function toggleTop4Modal() {
    SetTop4DataModal(!top4DataModal);
    removeBodyCss();
  }

  function getStatusColor(status){
    switch(status){
      case 'paid':
        return 'success';
      case 'unpaid':
      case 'cancelled':
        return 'warning';
      default:
        return 'primary';
    }
  }

  function getRealTimeModalTitle(chartType){
    
    switch(chartType){
      case 'inprocess_orders':
        return 'Ongoing Orders';
      case 'todays_pickup':
        return 'Pickup Orders';
      case 'todays_dropoff':
        return 'Drop off Orders';
      case 'dropoff_5h':
        return 'Dropoff Orders (Next 5 hr.)';
      case 'pickup_after5h':
        return 'Pick Up Orders (Next 5 hr.)'
    }
  }
  function loadRealTimeDetails(chartType){
    toggleRTModal();
    SetOrderListTitle(getRealTimeModalTitle(chartType));
    dispatch(getRealTimeDetails(chartType));
  }

  function removeBodyCss() {
    document.body.classList.add("no_padding");
  }

 





  const reports = [
    { title: "EMV", iconClass: "bx-copy-alt", description: chartsData.GMVAmount },
    { title: "No. of Orders", iconClass: "bx-archive-in", description: chartsData.NoOfOrders },
    { title: "Total Customer", iconClass: "bx-archive-in", description: chartsData.TotalCustomers},
    { title: "New Customers", iconClass: "bx-archive-in", description: chartsData.NewCustomers},
    { title: "Repeat Customers", iconClass: "bx-archive-in", description: chartsData.RepeatedCustomers },
  ];

  const options2= {
    chart: {
      height: 350,
      type: "area",
      toolbar: {
        show: false,
      },
    },
    colors: ["#556ee6", "#f1b44c"],
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: "smooth",
      width: 2,
    },
    fill: {
      type: "gradient",
      gradient: {
        shadeIntensity: 1,
        inverseColors: false,
        opacityFrom: 0.45,
        opacityTo: 0.05,
        stops: [20, 100, 100, 100],
      },
    },
    xaxis: {
      categories: [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ],
    },

    markers: {
      size: 3,
      strokeWidth: 3,

      hover: {
        size: 4,
        sizeOffset: 2,
      },
    },
    legend: {
      position: "top",
      horizontalAlign: "right",
    },
  }
  const series = [
    {
      name: "Current",
      data: [18, 21, 45, 36, 65, 47, 51, 32, 40, 28, 31, 26],
    },
    {
      name: "Previous",
      data: [30, 11, 22, 18, 32, 23, 58, 45, 30, 36, 15, 34],
    },
  ]
  


  const showFullCityChart = (chartType) => {
    switch(chartType){
      case 'area_order':
        SetTop4FullData(top4Data.area_orders_all);
        SetTop4DataModal(true);
        SetTop4ModalTitle('Top Areas by Orders');
        SetTop4ModalTotal(top4Data.total_orders)
        break;

      case 'area_gmv':
        SetTop4FullData(top4Data.area_gmv_all);
        SetTop4DataModal(true);
        SetTop4ModalTitle('Top Areas by GMV');
        SetTop4ModalTotal(top4Data.total_gmv)
        break;

      case 'laundry_order':
        SetTop4FullData(top4Data.laundry_orders_all);
        SetTop4DataModal(true);
        SetTop4ModalTitle('Top Laundries by Orders');
        SetTop4ModalTotal(top4Data.total_orders)
        break;

      case 'laundry_gmv':
        SetTop4FullData(top4Data.area_gmv_all);
        SetTop4DataModal(true);
        SetTop4ModalTitle('Top Laundries by GMV');
        SetTop4ModalTotal(top4Data.total_gmv)
        break;

      case 'cloth_order':
        SetTop4FullData(top4Data.cloth_orders_all);
        SetTop4DataModal(true);
        SetTop4ModalTitle('Top Cloths by Orders');
        SetTop4ModalTotal(top4Data.total_orders)
        break;

      case 'cloth_gmv':
        SetTop4FullData(top4Data.cloth_gmv_all);
        SetTop4DataModal(true);
        SetTop4ModalTitle('Top Cloths by GMV');
        SetTop4ModalTotal(top4Data.total_gmv)
        break;
    }
  }



  useEffect(() => {
    

    post(`orders/orderstats`).
    then(res => {
      console.log("getDashboardCounts", res);
      SetrealTimeData(res.stats);
    });

  }, [])

  useEffect(() => {
    setTimeout(() => {
      setSubscribemodal(false)
    }, 2000)
  }, [])

  const [periodData, setPeriodData] = useState([])
  const [periodType, setPeriodType] = useState("yearly")

  useEffect(() => {
    setPeriodData(chartsData)
  }, [chartsData])

  const onChangeChartPeriod = pType => {
    setPeriodType(pType)
    //dispatch(onGetChartsData(pType))
  }

  const dispatch = useDispatch();

  useEffect(() => {
    //var data= {"start":"2022-01-01", "end":"2022-02-25"}
    dispatch(getTop4Data({
      start:initialData.start,
      end : initialData.end
    }))
  }, [dispatch]);

   
  const onSubmit = async(values)=>{
    dispatch(loadChartData(values))
    // await new Promise((r) => setTimeout(r, 500));
    // alert(JSON.stringify(values, null, 2));
  }


  
  function validateUsername(value) {
    let error;
    if (value !="") {
      error = 'Nice try!';
    }
    return error;
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Home | Kleen Admin Panel</title>
        </MetaTags>
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumbs
            title={props.t("Dashboard")}
            breadcrumbItem={props.t("Home")}
          />



          <Row>
          
            <Col xl="12">
              <Row>
                {/* Reports Render */}
                
                  <Col md="3" >
                    <Card className="mini-stats-wid">
                      <CardBody>
                        <Media>
                          <Media body>
                            <p className="text-muted fw-medium">
                              Ongoing Orders
                            </p>
                            <h4 className="mb-0"><a href="#" onClick={() => loadRealTimeDetails('inprocess_orders')}>{realTimeData?realTimeData.inprocess_orders:0}</a></h4>
                          </Media>
                          <div className="avatar-sm rounded-circle bg-primary align-self-center mini-stat-icon">
                            <span className="avatar-title rounded-circle bg-primary">
                              <i
                                className={
                                  "bx bx-loader font-size-24"
                                }
                              ></i>
                            </span>
                          </div>
                        </Media>
                      </CardBody>
                    </Card>
                  </Col>

                  <Col md="3" >
                    <Card className="mini-stats-wid">
                      <CardBody>
                        <Media>
                          <Media body>
                            <p className="text-muted fw-medium">
                              Pickup Orders
                            </p>
                            <h4 className="mb-0">
                              <a href="#" onClick={() => loadRealTimeDetails('todays_pickup')}>
                              {realTimeData?realTimeData.todays_pickup:0}</a>
                            </h4>
                          </Media>
                          <div className="avatar-sm rounded-circle bg-primary align-self-center mini-stat-icon">
                            <span className="avatar-title rounded-circle bg-primary">
                              <i
                                className={
                                  "bx bx-shopping-bag font-size-24"
                                }
                              ></i>
                            </span>
                          </div>
                        </Media>
                      </CardBody>
                    </Card>
                  </Col>

                  <Col md="3" >
                    <Card className="mini-stats-wid">
                      <CardBody>
                        <Media>
                          <Media body>
                            <p className="text-muted fw-medium">
                              Drop off Orders
                            </p>
                            <h4 className="mb-0">
                              <a href="#" onClick={() => loadRealTimeDetails('todays_dropoff')}>
                              {realTimeData?realTimeData.todays_dropoff:0}</a>
                            </h4>
                          </Media>
                          <div className="avatar-sm rounded-circle bg-primary align-self-center mini-stat-icon">
                            <span className="avatar-title rounded-circle bg-primary">
                              <i
                                className={
                                  "bx bx-shopping-bag font-size-24"
                                }
                              ></i>
                            </span>
                          </div>
                        </Media>
                      </CardBody>
                    </Card>
                  </Col>

                  <Col md="3" >
                    <Card className="mini-stats-wid">
                      <CardBody>
                        <Media>
                          <Media body>
                            <p className="text-muted fw-medium">
                              Pick Up Orders (Next 5 hr.)
                            </p>
                            <h4 className="mb-0">
                              <a href="#" onClick={() => loadRealTimeDetails('pickup_after5h')}>
                                {realTimeData?realTimeData.pickup_after5h:0}</a>
                            </h4>
                          </Media>
                          <div className="avatar-sm rounded-circle bg-primary align-self-center mini-stat-icon">
                            <span className="avatar-title rounded-circle bg-primary">
                              <i
                                className={
                                  "bx bx-shopping-bag font-size-24"
                                }
                              ></i>
                            </span>
                          </div>
                        </Media>
                      </CardBody>
                    </Card>
                  </Col>

                  <Col md="3" >
                    <Card className="mini-stats-wid">
                      <CardBody>
                        <Media>
                          <Media body>
                            <p className="text-muted fw-medium">
                              Drop Off Orders (Next 5 hr.)
                            </p>
                            <h4 className="mb-0">
                            <a href="#" onClick={() => loadRealTimeDetails('dropoff_5h')}>
                              {realTimeData?realTimeData.dropoff_5h:0}</a>
                            </h4>
                          </Media>
                          <div className="avatar-sm rounded-circle bg-primary align-self-center mini-stat-icon">
                            <span className="avatar-title rounded-circle bg-primary">
                              <i
                                className={
                                  "bx bx-shopping-bag font-size-24"
                                }
                              ></i>
                            </span>
                          </div>
                        </Media>
                      </CardBody>
                    </Card>
                  </Col>




                
              </Row>

           
            </Col>
          </Row>

          <div>

          <Modal
            size="lg"
            isOpen={realTimeModal}
            toggle={() => {
              toggleRTModal();
            }}
          >
            <div className="modal-header">
              <h5 className="modal-title mt-0" id="myModalLabel">
                {orderListTitle}
              </h5>
              <button
                type="button"
                onClick={() => {
                  SetRealTimeModal(false);
                }}
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              
                <div>
                <PaginationProvider
                    pagination={paginationFactory(pageOptions)}
                    keyField={keyField}
                    columns={contactListColumns}
                    data={realTimeDetails}
                  >
                    {({ paginationProps, paginationTableProps }) => {
                      return (
                        <ToolkitProvider
                          keyField={keyField}
                          data={realTimeDetails}
                          columns={contactListColumns}
                          bootstrap4
                          search
                        >
                          {toolkitProps => (
                            <React.Fragment>
                              <Row className="mb-2">
                                <Col sm="4">
                                  <div className="search-box ms-2 mb-2 d-inline-block">
                                    <div className="position-relative">
                                      <SearchBar {...toolkitProps.searchProps} />
                                      <i className="bx bx-search-alt search-icon" />
                                    </div>
                                  </div>
                                </Col>
                               
                              </Row>
                              <Row>
                                <Col xl="12">
                                  <div className="table-responsive">
                                    <BootstrapTable
                                      keyField={keyField}
                                      {...toolkitProps.baseProps}
                                      {...paginationTableProps}
                                      
                                      defaultSorted={defaultSorted}
                                      classes={
                                        "table align-middle table-nowrap table-hover"
                                      }
                                      bordered={false}
                                      striped={false}
                                      responsive
                                      ref={node}
                                    />

                                  </div>
                                </Col>
                              </Row>
                              <Row className="align-items-md-center mt-30">
                                <Col className="pagination pagination-rounded justify-content-end mb-2">
                                  <PaginationListStandalone
                                    {...paginationProps}
                                  />
                                </Col>
                              </Row>
                            </React.Fragment>
                          )}
                        </ToolkitProvider>
                      );
                    }}
                  </PaginationProvider>
                </div>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                onClick={() => {
                  toggleRTModal();
                }}
                className="btn btn-secondary "
                data-dismiss="modal"
              >
                Close
              </button>
          
            </div>
          </Modal>
          </div>

          <div>

          <Modal
            size="lg"
            isOpen={top4DataModal}
            toggle={() => {
              toggleTop4Modal();
            }}
          >
            <div className="modal-header">
              <h5 className="modal-title mt-0" id="myModalLabel">
                {top4ModalTitle}
              </h5>
              <button
                type="button"
                onClick={() => {
                  SetTop4DataModal(false);
                }}
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              
                <div>
                <TopCities 
                      data = {top4FullData}
                      total = {top4ModalTotal}
                    />
                </div>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                onClick={() => {
                  toggleTop4Modal();
                }}
                className="btn btn-secondary "
                data-dismiss="modal"
              >
                Close
              </button>
              
            </div>
          </Modal>
          </div>

          
          <Row>
            {/* <Col xl="4">
              <WelcomeComp />
              <MonthlyEarning />
            </Col> */}
            <Col xl="12">
              <Row>
                {/* Reports Render */}
                {/*reports.map((report, key) => (
                  <Col md="3" key={"_col_" + key}>
                    <Card className="mini-stats-wid">
                      <CardBody>
                        <Media>
                          <Media body>
                            <p className="text-muted fw-medium">
                              {report.title}
                            </p>
                            <h4 className="mb-0">{report.description}356</h4>
                          </Media>
                          <div className="avatar-sm rounded-circle bg-primary align-self-center mini-stat-icon">
                            <span className="avatar-title rounded-circle bg-primary">
                              <i
                                className={
                                  "bx " + report.iconClass + " font-size-24"
                                }
                              ></i>
                            </span>
                          </div>
                        </Media>
                      </CardBody>
                    </Card>
                  </Col>
                              ))*/}
              </Row>

           
            </Col>
          </Row>


      


          
                            
         
          
          
        </Container>
      </div>

      {/* subscribe ModalHeader */}
      <Modal
        isOpen={subscribemodal}
        role="dialog"
        autoFocus={true}
        centered
        data-toggle="modal"
        toggle={() => {
          setSubscribemodal(!subscribemodal)
        }}
      >
        <div className="modal-content">
          <div className="modal-header border-bottom-0">
            <ModalHeader
              toggle={() => {
                setSubscribemodal(!subscribemodal)
              }}
            ></ModalHeader>
          </div>
          <div className="modal-body">
            <div className="text-center mb-4">
              <div className="avatar-md mx-auto mb-4">
                <div className="avatar-title bg-light  rounded-circle text-primary h1">
                  <i className="mdi mdi-email-open"></i>
                </div>
              </div>

              <div className="row justify-content-center">
                <div className="col-xl-10">
                  <h4 className="text-primary">Subscribe !</h4>
                  <p className="text-muted font-size-14 mb-4">
                    Subscribe our newletter and get notification to stay update.
                  </p>

                  <div
                    className="input-group rounded bg-light"
                  >
                    <Input
                      type="email"
                      className="form-control bg-transparent border-0"
                      placeholder="Enter Email address"
                    />
                    <Button color="primary" type="button" id="button-addon2">
                      <i className="bx bxs-paper-plane"></i>
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>

      <Modal
        isOpen={modal}
        role="dialog"
        autoFocus={true}
        centered={true}
        className="exampleModal"
        tabIndex="-1"
        toggle={() => {
          setmodal(!modal)
        }}
      >
        <div className="modal-content">
          <ModalHeader
            toggle={() => {
              setmodal(!modal)
            }}
          >
            Order Details
          </ModalHeader>
          <ModalBody>
            <p className="mb-2">
              Product id: <span className="text-primary">#SK2540</span>
            </p>
            <p className="mb-4">
              Billing Name: <span className="text-primary">Neal Matthews</span>
            </p>

            <div className="table-responsive">
              <Table className="table table-centered table-nowrap">
                <thead>
                  <tr>
                    <th scope="col">Product</th>
                    <th scope="col">Product Name</th>
                    <th scope="col">Price</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <th scope="row">
                      <div>
                        {/* <img src={modalimage1} alt="" className="avatar-sm" /> */}
                      </div>
                    </th>
                    <td>
                      <div>
                        <h5 className="text-truncate font-size-14">
                          Wireless Headphone (Black)
                        </h5>
                        <p className="text-muted mb-0">$ 225 x 1</p>
                      </div>
                    </td>
                    <td>$ 255</td>
                  </tr>
                  <tr>
                    <th scope="row">
                      <div>
                        {/* <img src={modalimage2} alt="" className="avatar-sm" /> */}
                      </div>
                    </th>
                    <td>
                      <div>
                        <h5 className="text-truncate font-size-14">
                          Hoodie (Blue)
                        </h5>
                        <p className="text-muted mb-0">$ 145 x 1</p>
                      </div>
                    </td>
                    <td>$ 145</td>
                  </tr>
                  <tr>
                    <td colSpan="2">
                      <h6 className="m-0 text-end">Sub Total:</h6>
                    </td>
                    <td>$ 400</td>
                  </tr>
                  <tr>
                    <td colSpan="2">
                      <h6 className="m-0 text-end">Shipping:</h6>
                    </td>
                    <td>Free</td>
                  </tr>
                  <tr>
                    <td colSpan="2">
                      <h6 className="m-0 text-end">Total:</h6>
                    </td>
                    <td>$ 400</td>
                  </tr>
                </tbody>
              </Table>
            </div>
          </ModalBody>
          <ModalFooter>
            <Button
              type="button"
              color="secondary"
              onClick={() => {
                setmodal(!modal)
              }}
            >
              Close
            </Button>
          </ModalFooter>
        </div>
      </Modal>
    </React.Fragment>
  )
}

Dashboard.propTypes = {
  t: PropTypes.any,
  chartsData: PropTypes.any,
  //onGetChartsData: PropTypes.func,
}

export default withTranslation()(Dashboard)
