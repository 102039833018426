import {
  GET_B2B_PRODUCT_LIST,
  GET_B2B_PRODUCTS_SUCCESS,
  GET_B2B_PRODUCTS_FAIL

} from "./actionTypes"

const INIT_STATE = {
  b2bProducts: []
}

const b2bProducts = (state = INIT_STATE, action) => {
  switch (action.type) {

    case GET_B2B_PRODUCTS_SUCCESS:
      return {
        ...state,
        b2bProducts: action.payload.record,
      }

    case GET_B2B_PRODUCTS_FAIL:
      return {
        ...state,
        error: action.payload,
      }
  
     
    default:
      return state
  }
}

export default b2bProducts;
