import axios from "axios"
import {postWeb} from "./api_helper"
import * as url from "./url_helper"


const fetchHolidayList = (data) => postWeb(`${url.GET_HOLIDAY_LIST}`, data)
const createHoliday = (data) => postWeb(`${url.CREATE_HOLIDAY}`, data)
const updateHoliday = (data) => postWeb(`${url.UPDATE_HOLIDAY}`, data)

export {
  fetchHolidayList,
  createHoliday,
  updateHoliday  
}