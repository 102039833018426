import {
  GET_NOTIFICATION_TEXT,
  GET_NOTIFICATION_TEXT_SUCCESS,
  UPDATE_NOTIFICATION_TEXT,
  UPDATE_NOTIFICATION_TEXT_SUCCESS,
} from "./actionTypes"

export const getNotificationText = data => ({
  type: GET_NOTIFICATION_TEXT,
  payload: data,
})

export const getNotificationTextSuccess = data => ({
  type: GET_NOTIFICATION_TEXT_SUCCESS,
  payload: data,
})

export const updateNotificationText = data => ({
  type: UPDATE_NOTIFICATION_TEXT,
  payload: data,
})

export const updateNotificationTextSuccess = data => ({
  type: UPDATE_NOTIFICATION_TEXT_SUCCESS,
  payload: data,
})


