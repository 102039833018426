import { postWeb } from "./api_helper"
import * as url from "./url_helper"

const _getAnnouncement = (data) => postWeb(`${url.GET_ANNOUNCEMENT}`, data)
const _createAnnouncement = (data) => postWeb(`${url.CREATE_ANNOUNCEMENT}`, data)
const _updateAnnouncement = (data) => postWeb(`${url.UPDATE_ANNOUNCEMENT}`, data)

export {
  _getAnnouncement,
  _createAnnouncement,
  _updateAnnouncement,
}