import React from "react";
import propTypes from 'prop-types';
import { timeAgo } from "./DriverLocations";

import {
    Button,
    Card,
    CardBody,
    Collapse,
} from "reactstrap";

function OffCanvasComponent(props) {


  const newMapCenter = (lat, lng) => {
      props.setMapCenter({ lat: lat, lng: lng });
      props.setZoom(50);
  }

  return (
      <div className={`offcanvas-panel ${props.isOpen ? 'expanded' : 'offCanvasHidden'}`}>
          <Collapse isOpen={props.isOpen}>
              <Card>
                  <CardBody fluid>
                      {
                          props.drivers?.map((driver, index) => {
                              return (
                                  <div key={index}>
                                    <div className="d-flex gap-2">
                                      <h6>{driver?.driverName}</h6> 
                                      <span className="timeStamp"> (Updated: {timeAgo(driver?.lastUpdated)})</span>
                                    </div>
                                  <Button
                                    onClick={() => { newMapCenter(driver?.location?.latitude, driver?.location?.longitude) }}
                                    color="secondary" className="btn-sm">
                                    <span className="mdi mdi-crosshairs-gps"></span> View on Map
                                  </Button>
                                      <hr />
                                  </div>
                              );
                          })
                      }
                  </CardBody>
              </Card>
          </Collapse>
      </div>
  );
}

OffCanvasComponent.propTypes = {
  drivers: propTypes.array,
  setMapCenter: propTypes.func,
  setZoom: propTypes.func,
  isOpen: propTypes.bool,
};

export default OffCanvasComponent;