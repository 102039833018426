import axios from "axios"
import { del, get, post, postWeb } from "./api_helper"
import * as url from "./url_helper"


const fetchLaundryList = (data) => post(`${url.GET_LAUNDRY}`, data)
const postAddLaundry = (data) => post(`${url.POST_ADD_LAUNDRY}`, data)
const postUpdateLaundry = (data) => post(`${url.POST_UPDATE_LAUNDRY}`, data)
const getLaundry = (LaundryId) => post(`${url.GET_LAUNDRY}`, {_id: LaundryId})
const postArea = (data) => post(`${url.POST_AREA_LIST}`, data)
const fetchAllLaundriesRevenue = (data) => postWeb(`${url.FETCH_ALL_LAUNDRY_REVENUE}`, data)

export {
    fetchLaundryList,
    postAddLaundry, 
    getLaundry,
    postUpdateLaundry,
    postArea,
    fetchAllLaundriesRevenue
}
