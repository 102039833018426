import React, { useEffect, useState, useRef } from "react";
import MetaTags from "react-meta-tags";
import PropTypes from "prop-types";
import { withRouter, Link } from "react-router-dom";
import DataTable from "react-data-table-component";
import MultiSelect from "react-multiple-select-dropdown-lite";
import "react-multiple-select-dropdown-lite/dist/index.css";
import toastr from "toastr";
import "toastr/build/toastr.min.css";
import Switch from "react-switch"

import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Label,
  Input,
  Badge,
  UncontrolledTooltip,
  Modal,
  ModalHeader,
  ModalBody,
} from "reactstrap";

import { getLaundries, searchUsers, getb2bList } from "../../store/actions"

//redux
import { useSelector, useDispatch } from "react-redux";

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb";

import {
  getClothesList,
  getArealist,
  getOrders as onGetOrders,
  addNewOrder as onAddNewOrder,
  updateOrder as onUpdateOrder,
  deleteOrder as onDeleteOrder,
} from "store/actions";
import Pages404 from "pages/Utility/pages-404";
import { postWeb } from "helpers/api_helper";

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

// import EcommerceOrdersModal from "./EcommerceOrdersModal";
const Offsymbol = () => {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100%",
        fontSize: 12,
        color: "#fff",
        paddingRight: 2
      }}
    >
      {" "}
      Off
    </div>
  )
}

const OnSymbol = () => {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100%",
        fontSize: 12,
        color: "#fff",
        paddingRight: 2
      }}
    >
      {" "}
      On
    </div>
  )
}

const MonthlyReports = props => {
  const dispatch = useDispatch();
  const [reportName, setReportName] = useState('');
  const [year, setYear] = useState(0);
  const [month, setMonth] = useState(0);
  const [userData, setUserData] = useState({});
  const [loading, setLoading] = useState(false);
  const [laundry, setLaundry] = useState(null);
  const [customer, setCustomer] = useState(null);
  const [b2bCustomers, setB2BCustomers] = useState([]);

  const { laundryList, clothes, users, b2blist } = useSelector(state => ({
    users: state.Users.users,
    clothes: state.Clothes.clothesList,
    laundryList: state.Laundries.laundryList,
    b2blist: state.b2bCustomers.b2blist
  }));


  useEffect(() => {
    if (!users) dispatch(searchUsers({ webPermissions: true }));
    dispatch(getLaundries())
  }, []);

  useEffect(() => {
    if (users.length > 0) {
      setUserData(users[0]);
    }
  }, [users])

  useEffect(() => {
    setB2BCustomers(b2blist);
  }, [b2blist])

  useEffect(() => {

  }, [b2bCustomers]);


  const downloadReport = () => {
    if (year == 0 || month == 0 || reportName == '') {
      toastr.error("Please select year, month and reports to export", "Invalid Parameters");
      return;

    }

    if (reportName == "b2b" && (!laundry || !customer)) {
      toastr.error("Please select B2B Customer", "Select B2B Customer");
      return;
    }

    const data = {
      month: month,
      year: year,
      type: reportName
    };

    if (reportName == "b2b") {
      data.laundry = laundry;
      data.b2bCustomer = customer;
    }

    setLoading(true);
    postWeb('reports/getMonthlyReport', data).then((data) => {
      setLoading(false);
      if (data.statusCode == 200 && data.path) {
        window.open(API_BASE_URL + "/" + data.path);
      }
      else if (data.statusCode == 400) {
        toastr.error("Error :" + data?.msg, "Error while exporting report");
      }
    });
  }

  const onLaundryChange = e => {
    setCustomer(null);
    setLaundry(e.target.value);
    dispatch(getb2bList({ laundry: e.target.value }))
  }

  if (userData?.permissions?.allPermissions == true || userData?.permissions?.reports?.read == true) {
    return (
      <React.Fragment>
        {/* <EcommerceOrdersModal isOpen={modal1} toggle={toggleViewModal} /> */}
        <div className="page-content">
          <MetaTags>
            <title>Reports</title>
          </MetaTags>
          <Container fluid>
            <Breadcrumbs title="Home" breadcrumbItem="Reports" />
            <Card>
              <CardBody>

                <Row>
                  <Col lg="3">
                    <Row>
                      <Col >
                        <Label className="p-2 pb-0">Select Report</Label>
                      </Col>
                    </Row>
                    <Row>
                      <Col>

                        <div className="p-2">
                          <Button color="info"
                            className="w-100"
                            onClick={() => setReportName('finance')}
                            outline={reportName != 'finance'}
                          >Financial Report</Button>
                        </div>

                        <div className="p-2">
                          <Button color="info"
                            className="w-100"
                            onClick={() => setReportName('data')}
                            outline={reportName != 'data'}
                          >Data Report</Button>
                        </div>

                        <div className="p-2">
                          <Button color="info"
                            className="w-100"
                            onClick={() => setReportName('sales')}
                            outline={reportName != 'sales'}
                          >Sales Report</Button>
                        </div>

                        <div className="p-2">
                          <Button color="info"
                            className="w-100"
                            onClick={() => setReportName('arca')}
                            outline={reportName != 'arca'}
                          >ARCA Sales Report</Button>
                        </div>

                        {/*<div className="p-2">
                          <Button color="info"
                            className="w-100"
                            onClick={() => setReportName('arca_invoicedate')}
                            outline={reportName != 'arca_invoicedate'}
                          >ARCA Sales Report by Invoice Date</Button>
                        </div>*/}

                        <div className="p-2">
                          <Button color="info"
                            className="w-100"
                            onClick={() => setReportName('paymentdate')}
                            outline={reportName != 'paymentdate'}
                          >Payment Date By Month</Button>
                        </div>

                        <div className="p-2">
                          <Button color="info"
                            className="w-100"
                            onClick={() => setReportName('payment_method')}
                            outline={reportName != 'payment_method'}
                          >Payment Method Report</Button>
                        </div>

                        <div className="p-2">
                          <Button color="info"
                            className="w-100"
                            onClick={() => setReportName('salesPaidSameMonth')}
                            outline={reportName != 'salesPaidSameMonth'}
                          >Sales Report (Paid in Same Month)</Button>
                        </div>
                        <div className="p-2">
                          <Button color="info"
                            className="w-100"
                            onClick={() => setReportName('salesPaidNotSameMonth')}
                            outline={reportName != 'salesPaidNotSameMonth'}
                          >Sales Report (NOT paid in same month)</Button>
                        </div>

                        <div className="p-2">
                          <Button color="info"
                            className="w-100"
                            onClick={() => setReportName('refund')}
                            outline={reportName != 'refund'}
                          >Refund Report</Button>
                        </div>

                        <div className="p-2">
                          <Button color="info"
                            className="w-100"
                            onClick={() => setReportName('refundByDateCreated')}
                            outline={reportName != 'refundByDateCreated'}
                          >Refund Report by Order Date</Button>
                        </div>

                        <div className="p-2">
                          <Button color="info"
                            className="w-100"
                            onClick={() => setReportName('b2b')}
                            outline={reportName != 'b2b'}
                          >B2B Report</Button>
                        </div>
                        {reportName == "b2b" ?
                          <div className="p-2">
                            <label>Select Laundry</label>
                            <select
                              onChange={onLaundryChange}
                              className="form-control">
                              <option>Select Laundry</option>
                              {laundryList.map((item) => (
                                <option key={item._id} value={item._id}>{item.shortName ? item.shortName : item.nameEn}</option>
                              ))
                              }
                            </select>
                          </div> : null}

                        {reportName == "b2b" ?
                          <div className="p-2">
                            <label>Select B2B Customer</label>
                            <select
                              onChange={(e) => setCustomer(e.target.value)}
                              className="form-control">
                              <option>Select B2B Customer</option>
                              {b2bCustomers.map((item) => (
                                <option key={item._id} value={item._id}>{item.nameEn}</option>
                              ))
                              }
                            </select>
                          </div> : null}

                        <div className="p-2">
                          <Button color="info"
                            className="w-100"
                            onClick={() => setReportName('clothes')}
                            outline={reportName != 'clothes'}
                          >Clothes Report</Button>
                        </div>


                        <div className="p-2">
                          <Button color="info"
                            className="w-100"
                            onClick={() => setReportName('subscription')}
                            outline={reportName != 'subscription'}
                          >Subscription Report</Button>
                        </div>


                      </Col>
                    </Row>
                  </Col>
                  <Col lg="3">
                    <Row>
                      <Col >
                        <Label className="p-2 ">Select Year</Label>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg="5">
                        <div className="">
                          <Button color="info"
                            className="w-100"
                            onClick={() => setYear('2021')}
                            outline={year != '2021'}
                          >2021</Button>
                        </div>
                      </Col>
                      <Col lg="5">
                        <div className="pb-2">
                          <Button color="info"
                            className="w-100"
                            onClick={() => setYear('2022')}
                            outline={year != '2022'}
                          >2022</Button>
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg="5">
                        <div className="pt-2">
                          <Button color="info"
                            className="w-100"
                            onClick={() => setYear('2023')}
                            outline={year != '2023'}
                          >2023</Button>
                        </div>
                      </Col>
                      <Col lg="5">
                        <div className="pt-2">
                          <Button color="info"
                            className="w-100"
                            onClick={() => setYear('2024')}
                            outline={year != '2024'}
                          >2024</Button>
                        </div>
                      </Col>

                    </Row>

                  </Col>
                  <Col lg="6">
                    <Row>
                      <Col >
                        <Label className="p-2 pb-0">Select Month</Label>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <div className="p-2">
                          <Button color="info"
                            className="w-100"
                            onClick={() => setMonth(1)}
                            outline={month != 1}
                          >Jan</Button>
                        </div>
                      </Col>
                      <Col>
                        <div className="p-2">
                          <Button color="info"
                            className="w-100"
                            onClick={() => setMonth(2)}
                            outline={month != 2}
                          >Feb</Button>
                        </div>
                      </Col>
                      <Col>
                        <div className="p-2">
                          <Button color="info"
                            className="w-100"
                            onClick={() => setMonth(3)}
                            outline={month != 3}
                          >Mar</Button>
                        </div>
                      </Col>
                      <Col>
                        <div className="p-2">
                          <Button color="info"
                            className="w-100"
                            onClick={() => setMonth(4)}
                            outline={month != 4}
                          >Apr</Button>
                        </div>
                      </Col>
                    </Row>

                    <Row>
                      <Col>
                        <div className="p-2">
                          <Button color="info"
                            className="w-100"
                            onClick={() => setMonth(5)}
                            outline={month != 5}
                          >May</Button>
                        </div>
                      </Col>
                      <Col>
                        <div className="p-2">
                          <Button color="info"
                            className="w-100"
                            onClick={() => setMonth(6)}
                            outline={month != 6}
                          >Jun</Button>
                        </div>
                      </Col>
                      <Col>
                        <div className="p-2">
                          <Button color="info"
                            className="w-100"
                            onClick={() => setMonth(7)}
                            outline={month != 7}
                          >Jul</Button>
                        </div>
                      </Col>
                      <Col>
                        <div className="p-2">
                          <Button color="info"
                            className="w-100"
                            onClick={() => setMonth(8)}
                            outline={month != 8}
                          >Aug</Button>
                        </div>
                      </Col>
                    </Row>

                    <Row>
                      <Col>
                        <div className="p-2">
                          <Button color="info"
                            className="w-100"
                            onClick={() => setMonth(9)}
                            outline={month != 9}
                          >Sep</Button>
                        </div>
                      </Col>
                      <Col>
                        <div className="p-2">
                          <Button color="info"
                            className="w-100"
                            onClick={() => setMonth(10)}
                            outline={month != 10}
                          >Oct</Button>
                        </div>
                      </Col>
                      <Col>
                        <div className="p-2">
                          <Button color="info"
                            className="w-100"
                            onClick={() => setMonth(11)}
                            outline={month != 11}
                          >Nov</Button>
                        </div>
                      </Col>
                      <Col>
                        <div className="p-2">
                          <Button color="info"
                            className="w-100"
                            onClick={() => setMonth(12)}
                            outline={month != 12}
                          >Dec</Button>
                        </div>
                      </Col>
                    </Row>


                  </Col>
                </Row>

                <Row className="mb-2">

                  <Col>
                    <div className="p-2 pt-4">
                      <button
                        type="button"
                        className="btn btn-success float-end"
                        onClick={downloadReport}
                        disabled={loading}
                      >
                        {loading ? <i className="fas fa-circle-notch fa-spin"></i> :
                          <i className="mdi mdi-download font-size-16 align-middle me-2"></i>}{" "}
                        {loading ? "Downloading..." : "Download Report"}
                      </button>
                    </div>
                  </Col>

                </Row>

              </CardBody>
            </Card>
          </Container>
        </div>
      </React.Fragment>
    );
  }
  else {
    return <Pages404 />
  }
};

MonthlyReports.propTypes = {

};

export default withRouter(MonthlyReports);
