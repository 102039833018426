import { call, put, takeEvery } from "redux-saga/effects"
import { postAddUser } from "helpers/userHelper"
import toastr from "toastr";
import "toastr/build/toastr.min.css";

import {
  GET_CLOTHES_LIST,
  GET_CLOTHES_LIST_SUCCESS,
  GET_CLOTHES_LIST_FAIL,
  ADD_NEW_CLOTHES,
  UPDATE_CLOTHES,
  DELETE_CLOTHES
} from "./actionTypes"

//Include Both Helper File with needed methods
import {
  fetchClothesList,
  createCloth,
  updateCloth
  
} from "helpers/clothesHelper"

import {
  getClothesListSuccess,
  getClothesListFail,
  addNewClothFail,
  addNewClothSuccess,
  updateClothesSuccess,
  updateClothesFail
} from "./actions"



function* getClothesList({ payload: data }) {
  try {
    const response = yield call(fetchClothesList, data)

    yield put(getClothesListSuccess(response))
  } catch (error) {
    yield put(getClothesListFail(error))
  }
}

function* AddNewCloth({ payload: data }) {
  try {
    const response = yield call(createCloth, data);   
    toastr.success("Cloth has been added", "Cloth");
    yield put(addNewClothSuccess(response));
  } catch (error) {
    toastr.error("Cloth add failed", "Cloth");
    yield put(addNewClothFail(error))
  }
}

function* UpdateCloth({ payload: data }) {
  try {
    const response = yield call(updateCloth, data)
    if (response?.statusCode != "200") {
      toastr.error("Unable to Update Cloth", "Cloth");
    } else {
      toastr.success("Cloth has been Updated", "Cloth");
      yield put(updateClothesSuccess(data))
    }
  } catch (error) {
    toastr.error("Unable to Update Cloth", "Cloth");
    yield put(updateClothesFail(error))
  }
}



function* driversSaga() {
  yield takeEvery(GET_CLOTHES_LIST, getClothesList)
  yield takeEvery(ADD_NEW_CLOTHES, AddNewCloth)
  yield takeEvery(UPDATE_CLOTHES, UpdateCloth)
  
}

export default driversSaga
