import { call, put, takeEvery } from "redux-saga/effects"
import { GET_SERVICES, UPDATE_SERVICE } from "./actionTypes"
import { apiSuccess, apiFail } from "./actions"
import { _updateService, _getServices } from "../../helpers/serviceHelper"
import toastr from "toastr"

function* getServices({ payload: data }) {
  try {
    const response = yield call(_getServices, data)
    yield put(apiSuccess(GET_SERVICES, response.record))
  } catch (error) {
    yield put(apiFail(GET_SERVICES, error))
  }
}

function* updateService({ payload: data }) {
  try {
    const response = yield call(_updateService, data)
    toastr.success("Saved")
    yield put(apiSuccess(UPDATE_SERVICE, response.record))
  } catch (error) {
    yield put(apiFail(UPDATE_SERVICE, error))
  }
}

function* usersSaga() {
  yield takeEvery(GET_SERVICES, getServices)
  yield takeEvery(UPDATE_SERVICE, updateService)
}

export default usersSaga
