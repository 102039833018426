import React, { useEffect, useState } from "react"
import MetaTags from "react-meta-tags"
import PropTypes from "prop-types"
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Input,
  InputGroup,
  Row,
  Table,
  Modal,
  ModalHeader,
  ModalBody
} from "reactstrap"
import classNames from "classnames"

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb"

//Import actions
import {
  getLaundries,
  getPricelist,
  getDriverList,
  searchUsers,
  getOrderDetails, 
  getReasonsList,
  getPermission
} from "store/actions"

//redux
import { useSelector, useDispatch } from "react-redux"
import { useHistory, useLocation } from "react-router-dom";
import toastr from "toastr";
import "toastr/build/toastr.min.css";
import { myParseFloat } from "helpers/validationHelper"
import { updateOrderInvoice } from "helpers/orderHelper"
import { useLayoutEffect } from "react"
import Skeleton from "react-loading-skeleton"
import { postWeb } from "helpers/api_helper"
import Activity from "./Activity"
import Pages404 from "pages/Utility/pages-404"



const EditInvField = props => {
  const [label, setLabel] = useState("");
  const [emptyLabel, setEmptyLabel] = useState("");
  const [showLabel, setShowLabel] = useState(true);
  const [selectedItem, setSelectedItem] = useState("");

  const _setSelectedItem = () => {
    if (props.fieldName == "bagNo") {
      setSelectedItem(props.row.customer?.bagNo);
    }
    else if (props.fieldName == "laundry") {
      setSelectedItem(props.row?.laundry?._id);
    }
    else if (props.fieldName == "pickup") {
      setSelectedItem(props.row?.pickupDriver?._id);
    }
    else if (props.fieldName == "delivery") {
      setSelectedItem(props.row?.dropoffDriver?._id);
    }
    else if (props.fieldName == "note") {
      setSelectedItem(props.row?.note);
    }
  }

  useEffect(() => {
    _setSelectedItem();
  }, []);

  useEffect(() => {

    if (props.fieldName == "laundry") {
      setLabel((props.row.laundry?.shortName) ? (props.row.laundry?.shortName) : (props.row.laundry?.nameEn));
      setEmptyLabel("No Laundry")
      setSelectedItem(props.row?.laundry?._id);

    }
    else if (props.fieldName == "pickup") {
      setLabel(props.row.pickupDriver?.name);
      setEmptyLabel("No Captain")
    }
    else if (props.fieldName == "delivery") {
      setLabel(props.row.dropoffDriver?.name);
      setEmptyLabel("No Captain")
    }
    else if (props.fieldName == "bagNo") {
      setLabel(props.row.customer?.bagNo);
      setEmptyLabel("Add Bag")
    }
    else if (props.fieldName == "note") {
      setLabel(props.row?.note);
      setEmptyLabel("No Note")
    }
  }, [props]);

  const showEditable = () => {
    setShowLabel(false);
  }

  const handleClose = () => {
    setShowLabel(true);
    _setSelectedItem();
  }

  const handleSubmit = async () => {

    if (props.fieldName == "bagNo") {

      if (!selectedItem || selectedItem.trim() == "") {
        toastr.error("Please enter bag No ", `Required`);
        return;
      }

      if (!props.row?.customer?._id) {
        toastr.error("Invalid customer ", `Required`);
        return;
      }


      const response = await postWeb("customers/update", {
        _id: props.row.customer?._id,
        bagNo: selectedItem
      });

      props.onUpdate.apply(null, [props.row._id, "bagNo", selectedItem]);
      toastr.success(`Bag Number Updated Successfully`, `Bag Number Updated`);

      setShowLabel(true);
      return;
    }

    if (!selectedItem) {
      let errMsg;
      if (props.fieldName == "note") {
        errMsg = "Please enter note";
      }
      else {
        errMsg = "Please select " + ((props.fieldName == "laundry") ? (props.fieldName) : (props.fieldName + " captain"));
      }
      toastr.error(errMsg, `Required`);

      return;
    }
    const data = {
      _id: props.row._id
    };
    let objectName = "";
    let key = "";
    let value;
    if (props.itemlist)
      value = props.itemlist.find((item) => item._id == selectedItem);
    else
      value = selectedItem;

    if (props.fieldName == "laundry") {
      data.laundry = selectedItem;
      objectName = "Laundry ";
      key = "laundry";

    }
    else if (props.fieldName == "pickup") {
      objectName = "Pickup Driver ";
      data.pickupDriver = selectedItem;
      key = "pickupDriver";
    }
    else if (props.fieldName == "delivery") {
      objectName = "Dropoff Driver ";
      data.dropoffDriver = selectedItem;
      key = "dropoffDriver";
    }
    else if (props.fieldName == "note") {
      objectName = "Note";
      data.note = selectedItem;
      key = "note";
    }

    const response = await postWeb("orders/update", data);
    props.onUpdate?.apply(null, [response.order._id, key, value]);
    toastr.success(objectName + ` Updated Successfully`, `Order Updated`);

    setShowLabel(true);

  }

  const onChangeItem = (e) => {
    setSelectedItem(e.target.value);
  }

  return (
    <div className={showLabel ? "d-flex justify-content-start" : "d-flex justify-content-start align-items-center"}>
      {showLabel ?

        <>
          {label ? <a onClick={showEditable} className="align-self-start editable editable-click">{label}</a> : <a onClick={showEditable} className="editable editable-click editable-empty text-danger">{emptyLabel}</a>}
        </> :
        <>
          {props.itemlist ?
            <select
              className="form-control mx-2"
              onChange={onChangeItem}
              value={selectedItem}
            >
              <option value="">select {props.fieldName}</option>

              {
                props.itemlist.map((item) => {
                  if (props.fieldName == "laundry") {
                    return <option value={item._id} key={item._id}>{item.shortName ? item.shortName : item.nameEn}</option>
                  }
                  else {
                    return <option value={item._id} key={item._id}>{item.name}</option>
                  }
                })
              }
            </select> :
            <>
              {props.fieldType == "textarea" ?
                <textarea
                  className="form-control"
                  value={selectedItem}
                  onChange={(e) => setSelectedItem(e.target.value)}
                ></textarea>
                :
                <Input
                  type="text"
                  value={selectedItem}
                  onChange={(e) => setSelectedItem(e.target.value)}
                />}
            </>
          }

          <button type="submit"
            onClick={handleSubmit}
            className="btn mx-1 px-3 btn-success editable-submit waves-effect waves-light">
            <i className="mdi mdi-check">
            </i>
          </button>
          <button
            type="button"
            onClick={handleClose}
            className="btn mx-1 px-3 btn-danger editable-cancel waves-effect waves-light">
            <i className="mdi mdi-close"></i>
          </button>

        </>}
    </div>
  );
};

EditInvField.propTypes = {
  fieldName: PropTypes.any,
  fieldType: PropTypes.string,
  row: PropTypes.any,
  itemlist: PropTypes.any,
  onUpdate: PropTypes.any
}



const CreateOrdersInvoice = props => {
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const [laundryType, setLaundryType] = useState("wash");
  const [laundryDropdownOpen, setlaundryDropdownOpen] = useState(false);
  const [washProductList, setwashProductList] = useState(new Map());
  const [washProductLaundryList, setwashProductLaundryList] = useState([]);
  const [washProductAreaList, setwashProductAreaList] = useState([]);
  const [laundry, setLaundry] = useState(0);
  const [laundryData, setLaundryData] = useState({});
  const [subTotal, setSubTotal] = useState(0);
  const [billingTotal, setBillingTotal] = useState(0);
  const [allTotal, setAllTotal] = useState(0);
  const [vatAmt, setVatAmt] = useState(0);
  const [grandTotal, setGrandTotal] = useState(0);

  const [data, setData] = useState({});
  const [orderData, setOrderData] = useState({});
  const [titleInvoice, setTitleInvoice] = useState("Create Invoice");
  const [billNumber, setBillNumber] = useState("");
  const [error, setError] = useState({});
  const [pageLoading, setPageLoading] = useState(true);
  const [orderFetched, setOrderFetched] = useState(false);
  const [editPermission, setEditPermission] = useState(false);
  const [userData, setUserData] = useState({});
  const [cancelProcessing, setCancelProcessing] = useState(false);
  const [otherReason, setOtherReason] = useState({});
  const [showModal, setShowModal] = useState(false);
  const [cancellationReason, setCancellationReason] = useState("");
  const [reasonError, setReasonError] = useState(false);





  const { laundryList, PriceList, orderDetails, products, driverList, reasonList, users } = useSelector(state => ({
    laundryList: state.Laundries.laundryList,
    PriceList: state.ecommerce.pricelist,
    orderDetails: state.Orders.orderDetails,
    // Allorders: state.Records.records,
    products: state.Records.records,
    driverList: state.Drivers.driverList,
    reasonList: state.Reasons.reasonList,
    users: state.Users.users
  }))

  const {
    match: { params },
  } = props

  useEffect(() => {
    dispatch(searchUsers({ webPermissions: true }))
    dispatch(getOrderDetails({ _id: params.id }))
    dispatch(getReasonsList({type:"cancellation", status: true, isOther:true}));
    return () => {
      setOrderData(null)
    }
  }, [])



  useEffect(() => {
    if (users.length > 0) {
      const _userData = users[0];
      setUserData(users[0]);
      if (_userData?.permissions?.allPermissions == true || _userData?.permissions?.orders?.update == true) {
        setEditPermission(true);
      }
      else {
        setEditPermission(false);
      }
    }
  }, [users])


  useEffect(() => {
    console.log("orderDetails", orderDetails);

    if (orderDetails?._id != params.id) {
      setPageLoading(true);
      setOrderFetched(false);
      setTimeout(() => {
        setOrderFetched(true);
      }, 3000);
    }
    else if (orderDetails) {
      setOrderFetched(true);
      //Get Laundry List to get pricelist 
      setOrderData(orderDetails);
      //setData({ ...data, bagfee: objOfOrder.bagFee, deliveryfee: objOfOrder.delFee, billnumber: objOfOrder?.bills[0]?.billNo || "" })
    }
  }, [orderDetails])

  useEffect(() => {
    if (orderData == null) {
      return;
    }
    setPageLoading(false);
    dispatch(getLaundries())
    dispatch(getDriverList())
    console.log("Laundry ID", orderData?.laundry?._id);
    if (orderData?.laundry?._id) {
      setLaundry(orderData?.laundry?._id);
    }

    if (orderData.clothes) {
      const _clothesMap = new Map(washProductList);
      orderData.clothes.map((cloth) => {
        _clothesMap.set(cloth.cloth._id, cloth);
      })

      setwashProductList(_clothesMap);
    }

    if (orderData?.bills?.[0]?.billNo) {
      setBillNumber(orderData?.bills?.[0]?.billNo);
      setTitleInvoice("Update Invoice");
    }
    console.log(orderData.laundry);
  }, [orderData])


  // On laundry change reload clothe list 
  useEffect(() => {
    if (laundry) {
      dispatch(getPricelist({ area: orderData?.location?.area?._id }))
      const _laundryData = laundryList.filter(item => item._id == laundry);
      if (_laundryData && _laundryData.length > 0) {
        setLaundryData(_laundryData[0]);
      }
    }
    else {
      setLaundryData({});
    }
  }, [laundry])

  useEffect(() => {
    if (PriceList[0]?.laundry) {
      setwashProductLaundryList(PriceList)
    } else if (PriceList[0]?.area) {
      if (laundry) {
        dispatch(getPricelist({ laundry: laundry }))
      }
      setwashProductAreaList(PriceList)
    }
  }, [PriceList])

  useEffect(() => {
    if (washProductLaundryList?.length > 0 && washProductAreaList?.length > 0) {
      let newWashProductList = new Map(washProductList);
      washProductAreaList.map(data => {

        let objOfLaundry = washProductLaundryList.find(item => item.cloth._id === data.cloth._id);

        if (objOfLaundry) {
          let dCount = 0;
          let iCount = 0;
          let wCount = 0;

          let newObject = { ...data, dCost: objOfLaundry.dPrice, iCost: objOfLaundry.iPrice, wCost: objOfLaundry.wPrice, dCount, iCount, wCount }
          if (!newWashProductList.has(objOfLaundry.cloth._id)) {
            newWashProductList.set(objOfLaundry.cloth._id, newObject);
          }
        }
      }
      )

      setwashProductList(newWashProductList)
    }
  }, [washProductLaundryList, washProductAreaList])

  const onChangeLaundryType = (type) => {
    setLaundryType(type);
  }

  const selectLaundry = (val) => {
    setlaundryDropdownOpen(prevState => !prevState);
  }

  const countUP = (type, id) => {
    qtyChange(type, id, 1);
  }

  const countDown = (type, id) => {
    qtyChange(type, id, -1);
  }

  const qtyChange = (type, id, step) => {
    let field;
    let costField;
    if (type == "wash") {
      field = "wCount";
      costField = "wCost";
    }
    if (type == "dryclean") {
      field = "dCount";
      costField = "dCost";
    }
    if (type == "iron") {
      field = "iCount";
      costField = "iCost";
    }

    if (field) {
      const products = new Map(washProductList);
      const product = products.get(id);
      const currentValue = (product?.[field] || 0);
      if (step == -1 && currentValue == 0) {
        return;
      }

      if (myParseFloat(product[costField]) <= 0) {
        toastr.error("Laundry Price is not set to the cloth", "Error");
        return;
      }

      product[field] = currentValue + step;
      products.set(id, product);
      setwashProductList(products);
    }
  }


  useEffect(() => {
    if (orderData == null) {
      return;
    }

    let subTotalVal = 0;
    let billingTotalVal = 0;
    let total = 0;
    let _vatAmt = 0;
    const vatPercent = myParseFloat(orderData.vatPercent);
    washProductList.size > 0 && [...washProductList.keys()].map((key, index) => {
      const val = washProductList.get(key);

      subTotalVal += val.wPrice * (val.wCount || 0) + val.dPrice * (val.dCount || 0) + val.iPrice * (val.iCount || 0)
      billingTotalVal += val.wCost * (val.wCount || 0) + val.dCost * (val.dCount || 0) + val.iCost * (val.iCount || 0)
    })
    let totalWithCharge = subTotalVal + (orderData?.delFee || 0) + (orderData?.bagFee || 0)
    setSubTotal(subTotalVal.toFixed(2))
    setBillingTotal(billingTotalVal.toFixed(2))
    if (subTotalVal.toFixed(2) < orderData.minimum && subTotalVal.toFixed(2) != 0.0) {
      total = parseFloat(totalWithCharge.toFixed(2)) + parseFloat(orderData.minimum - subTotalVal.toFixed(2));
      setAllTotal(total)
    } else {
      total = totalWithCharge.toFixed(2);
      setAllTotal(total);
    }

    _vatAmt = (myParseFloat(total) * vatPercent / 100).toFixed(2)
    setVatAmt(_vatAmt);
    setGrandTotal(myParseFloat(total) + myParseFloat(_vatAmt));


  }, [washProductList, orderData])

  const handleChange = (e) => {
    const { name, value } = e.target;
    switch (name) {

      case 'bagfee':
        setOrderData({ ...orderData, bagFee: parseFloat(value) });
        break;
      case 'delFee':
        setOrderData({ ...orderData, delFee: parseFloat(value) });
        break;
    }
  }

  const CreateInvoice = () => {

    //Validate before create invoice
    if (laundryData?.type != "franchise_laundry" && myParseFloat(billNumber) <= 0) {
      setError({ ...error, billNumber: true });
      return;
    }

    if (subTotal <= 0 || billingTotal <= 0) {
      toastr.error("Please select clothes qty", "Inomplete Data");
      return;
    }

    let objOfOrder = orderData;
    let _laundryData = laundryList.length > 0 && laundryList.find((val) => val._id == laundry)
    let clothsArr = []
    clothsArr = [...washProductList.keys()].map((key) => {
      const val = washProductList.get(key);
      return {
        "cloth": val.cloth._id,
        "iCount": val.iCount || 0,
        "wCount": val.wCount || 0,
        "dCount": val.dCount || 0,

        "iPrice": val.iPrice,
        "wPrice": val.wPrice,
        "dPrice": val.dPrice,

        "iCost": val.iCost,
        "wCost": val.wCost,
        "dCost": val.dCost,
      }
    });

    let formData = {
      "_id": params.id,
      "status": "washing",
      "billAmount": billingTotal || 0,
      "subTotal": subTotal,
      "bagFee": orderData.bagFee || 0,
      "delFee": orderData.delFee || 0,
      "total": allTotal,
      "clothes": clothsArr,
      "laundry": laundry,
      "bills": [
        {
          "laundry": laundry,
          "billAmount": billingTotal || 0,
          "billNo": billNumber || 0,
          "commission": _laundryData?.commission
        }
      ]
    }

    updateOrderInvoice(formData, props.history).then(() => {
      toastr.success(orderData?.bills?.length > 0 ? "Invoice updated successfully" : "Invoice Created Successfully", "Success");
      dispatch(getOrderDetails({ _id: params.id }));
    });
  }




  var ldata = JSON.parse(localStorage.getItem("authUser"));
  const [fill, setFill] = useState({});
  const { permission } = useSelector(state => ({
    permission: state.permission.permissions
  }))


  useEffect(() => {
    dispatch(getPermission({}))
  }, [])

  useEffect(() => {
    if (myParseFloat(billNumber) > 0) {
      setError({ ...error, billNumber: false });
    }
    else {
      setError({ ...error, billNumber: true });
    }
  }, [billNumber])

  useEffect(() => {
    if(reasonList && reasonList.length > 0){
      setOtherReason(reasonList[0]);
    }
  }, [reasonList])


  useEffect(() => {
    const filterProduct = permission?.filter((item) => item?.role === ldata?.role)
    setFill(filterProduct[0])
  }, [permission])

  const onChangeLaundry = e => {
    setwashProductAreaList([]);
    setwashProductLaundryList([]);
    setwashProductList(new Map());
    setLaundry(e.target.value)
  }

  const goBack = () => {
    history.push(location.state.prevPath);
  }

  const onUpdate = (_id, key, value) => {
    let orderCopy = { ...orderData };
    if (key == "bagNo") {
      orderCopy["customer"][key] = value;
    }
    else {
      orderCopy[key] = value;
    }
    console.log("orderCopy::", orderCopy);
    setOrderData(orderCopy);
  }

  const cancelOrder = () => {
    if(!validateReason(cancellationReason)){
      return;
    }

    if(!otherReason?._id){
      toastr.error("Other reason not found for cancellation", "Error");
      return;
    }

    setCancelProcessing(true);
    postWeb("orders/update", {
      _id: orderData._id,
      status: "cancelled",
      orderCancellation: {
        reason : otherReason._id, 
        otherReason: cancellationReason
      }
    }).then(() => {
      
      setCancelProcessing(false);
      setShowModal(false);
      setCancellationReason("");
      setOrderData({...orderData, status:"cancelled"});

      toastr.success(`Order Cancelled Successfully`, `Order Cancelled`);
    });
  }

  const toggle = () => {
    setShowModal(!showModal);
  };

  const onReasonChange = (value) => {
    validateReason(value);
    setCancellationReason(value);
  }

  const validateReason = (value) => {
    
    let valid = true;
    if(!value){
      valid = false;
    }
    if(value && value.trim() == ""){
      valid = false;
    }
    setReasonError(!valid);
    return valid;
  }

  const showCancelModel = () => {
    setCancellationReason("");
    setShowModal(true);
  }


  if (orderFetched && orderDetails?._id) {
    if (userData?.permissions?.allPermissions == true || userData?.permissions?.clothes?.read == true)
      return (

        <React.Fragment>
          {/* {invoiceStatus == "200" &&
        <SweetAlert
          success
          title={"Invoice Created Successfull!!"}
          onConfirm={() => {
            setStatus(false)
          }}
        >
        </SweetAlert>
      } */}
          <div className="page-content">
            <MetaTags>
              <title>
                {titleInvoice}
              </title>
            </MetaTags>
            <Container fluid>
              <Breadcrumbs title="All orders" breadcrumbItem={titleInvoice} />
            </Container>
            <Row className="mb-4">
              <Col lg="4">
                <div className="mx-4">
                  <h5>Activity </h5>
                  <Activity
                    data={orderData}
                  />
                </div>
              </Col>
              <Col>
                <Row>
                  <Col>
                    <label>Order Id : {orderData?.orderID || <Skeleton count={1} />}</label>
                  </Col>
                  <Col className="d-flex justify-content-between">
                    <label>Customer Id : {orderData?.customer?.customerID || <Skeleton count={1} />}</label>
                    <Button onClick={() => goBack()}>
                      <i className="fas fa-arrow-alt-circle-left"></i> Back
                    </Button>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <label>Area : {orderData?.location?.area?.name} </label>
                  </Col>
                  <Col>
                    <label>Mobile : {orderData?.customer?.mobile} </label>
                  </Col>
                </Row>

                <Row>
                  <Col>
                    <label style={{ textTransform: "capitalize" }}>Type : {orderData?.type} </label>
                  </Col>
                  <Col>
                    <div className="d-flex justify-content-start">
                      <label className="col-sm-2">Bag No : </label>
                      <div>
                        {((orderData.customer?.plan || orderData.customer?.bagNo) && editPermission == true) ?
                          <EditInvField
                            fieldName="bagNo"
                            row={orderData}
                            onUpdate={onUpdate}
                          /> : <span>{orderData.customer?.bagNo?orderData.customer?.bagNo:<span className="text-secondary">N/A</span>}</span>}
                      </div>
                    </div>
                  </Col>
                </Row>

                <Row>
                  <Col>
                    <label style={{ textTransform: "capitalize" }}>Location Type : {orderData?.location?.type} </label>
                  </Col>
                  <Col>
                        {orderData.status == "cancelled"?
                          <Button
                            color="danger"
                            disabled
                          >
                            Cancelled
                          </Button>
                          :
                          <Button
                          color="primary"
                          disabled={!editPermission || orderData.pickUpTime}
                          onClick={() => showCancelModel()}
                        >
                          Cancel Order
                        </Button>
                        }

                    
                  </Col>
                </Row>

                <Row className="mb-2">
                  <Col>
                    <label>Grand Total : {grandTotal} SR </label>
                  </Col>
                  <Col>

                  </Col>
                </Row>

                <Row className="mb-2">
                  {!orderData.pickUpTime && orderData.status!="cancelled" && !orderData.invoiceTime && editPermission == true ?
                    <Col>
                      <label className="mb-1">Select Pickup Captain </label>
                      <div className="mb-2">
                        <EditInvField
                          itemlist={driverList}
                          fieldName="pickup"
                          row={orderData}
                          onUpdate={onUpdate}
                        />
                      </div>

                    </Col> :
                    <Col className="d-flex justify-content-start">
                      <label className="mb-1">Pickup Captain: </label>
                      <div className="mb-2 ms-2">
                        <div >{orderData.pickupDriver?.name?orderData.pickupDriver?.name:<span className="text-secondary">N/A</span>}</div>
                      </div>
                    </Col>
                  }

                  { orderData.status!="cancelled" 
                    && !orderData.endTime 
                    && editPermission == true ?
                  <Col>
                  {
                    orderData.readyToDeliveryTime 
                    && orderData.paid == true?
                    <>
                      <label>Dropoff Captain </label>
                      <div className="">
                        <EditInvField
                          itemlist={driverList}
                          fieldName="delivery"
                          row={orderData}
                          onUpdate={onUpdate}
                        />
                      </div>
                    </>: 
                    <>
                    <label>Dropoff Captain </label>
                    <div className="text-danger">Order not ready</div>
                    </>
                  }
                  </Col>:
                  <Col className="d-flex justify-content-start">
                      <label >Dropoff Captain:  </label>
                      <div className="ms-2">
                      {orderData.dropoffDriver?.name?orderData.dropoffDriver?.name:<span className="text-secondary">N/A</span>}
                      </div>
                  </Col>
                  }
                </Row>

                <Row>
                    {(!orderData.invoiceTime && orderData.status!="cancelled" && editPermission == true) ?
                      <Col>
                      <label className="mb-1">Select Laundry </label>
                      <div className="">
                      <EditInvField
                        itemlist={laundryList}
                        fieldName="laundry"
                        row={orderData}
                        onUpdate={onUpdate}
                      />
                      </div>
                      </Col>
                        :
                      <Col className="d-flex justify-content-start">
                        <label className="mb-1">Laundry: </label>
                        <div className="ms-2">
                          {(orderData.laundry?.shortName) ? (orderData.laundry?.shortName) : (orderData.laundry?.nameEn)}
                        </div>
                      </Col>
                    }
                  <Col>
                    <label>Note   </label>
                    <div>
                      {editPermission?
                      <EditInvField
                        fieldName="note"
                        fieldType="textarea"
                        row={orderData}
                        onUpdate={onUpdate}
                      />:
                      <div>{orderData.note}</div>
                      }
                    </div>
                  </Col>
                </Row>
              </Col>
            </Row>

            <Row>
              <Col lg="6">
                <Card>
                  <CardBody>

                    <div className="ms-auto mb-3">
                      <ul className="nav nav-pills">
                        <li className="nav-item tab-item">
                          <div
                            // to="#"
                            className={classNames(
                              { active: laundryType === "wash" },
                              "nav-link"
                            )}
                            onClick={() => {
                              onChangeLaundryType("wash")
                            }}
                            id="wash"
                          >
                            Wash
                          </div>{" "}
                        </li>
                        <li className="nav-item tab-item mx-2">
                          <div
                            // to="#"
                            className={classNames(
                              { active: laundryType === "dryclean" },
                              "nav-link"
                            )}
                            onClick={() => {
                              onChangeLaundryType("dryclean")
                            }}
                            id="dryclean"
                          >
                            Dry Clean
                          </div>
                        </li>
                        <li className="nav-item tab-item">
                          <div
                            // to="#"
                            className={classNames(
                              { active: laundryType === "iron" },
                              "nav-link"
                            )}
                            onClick={() => {
                              onChangeLaundryType("iron")
                            }}
                            id="iron"
                          >
                            Iron
                          </div>
                        </li>
                        {/* <li className="nav-item tab-item mx-2">
                      <div
                        // to="#"
                        className={classNames(
                          { active: laundryType === "products" },
                          "nav-link"
                        )}
                        onClick={() => {
                          onChangeLaundryType("products")
                        }}
                        id="products"
                      >
                        Products
                      </div>
                    </li> */}
                      </ul>
                    </div>
                    <div className="table-responsive">
                      <Table className="table align-middle mb-0 table-nowrap">
                        <thead className="table-light">
                          <tr>
                            <th>Cloth</th>
                            <th>Quantity</th>
                            <th>Unit Price</th>
                            <th>Total Price</th>
                            {laundryType === "products" && <th>Price</th>}
                          </tr>
                        </thead>
                        <tbody>
                          {laundryType === "products" ? (
                            <>
                              {/* {
                            orderData.products?.length?
                              orderData.products.map(product => (
                                <tr key={product._id}>
                                  <td>{product?.product?.nameEn}</td>
                                  <td>{product?.qty}</td>
                                  <td>{product?.price} SR</td>
                                </tr>
                              ))
                            : null
                          } */}
                            </>
                          ) : [...washProductList.keys()].map(key => {
                            const product = washProductList.get(key);


                            return (


                              <tr key={product._id}>
                                <td>{product?.cloth?.nameEn}</td>
                                <td>
                                  <InputGroup className="form_control1">
                                    {/* <Button addontype="prepend"> */}
                                    <Button
                                      color="primary"
                                      onClick={() => {
                                        if (laundryType == "wash") {
                                          countDown("wash", product.cloth._id)
                                        }
                                        if (laundryType == "dryclean") {
                                          countDown("dryclean", product.cloth._id)
                                        }
                                        if (laundryType == "iron") {
                                          countDown("iron", product.cloth._id)
                                        }
                                      }}
                                    >
                                      -
                                    </Button>
                                    {/* </Button> */}
                                    <Input
                                      type="text"
                                      value={laundryType == "wash" ? product.wCount || 0 : (laundryType == "dryclean" ? product.dCount || 0 : product.iCount || 0)}
                                      name="demo_vertical"
                                      readOnly
                                    />
                                    {/* <Button addontype="append"> */}
                                    <Button
                                      color="primary"
                                      onClick={() => {
                                        if (laundryType == "wash") {
                                          countUP("wash", product.cloth._id)
                                        }
                                        if (laundryType == "dryclean") {
                                          countUP("dryclean", product.cloth._id)
                                        }
                                        if (laundryType == "iron") {
                                          countUP("iron", product.cloth._id)
                                        }
                                      }}
                                    >
                                      +
                                    </Button>
                                    {/* </Button> */}
                                  </InputGroup>
                                </td>
                                <td>
                                  {
                                    laundryType == "wash" ? product.wPrice : 0 ||
                                      laundryType == "dryclean" ? product.dPrice : 0 ||
                                        laundryType == "iron" ? product.iPrice : 0
                                  }
                                </td>
                                <td>
                                  {
                                    laundryType == "wash" ? product.wPrice * product.wCount : 0 ||
                                      laundryType == "dryclean" ? product.dPrice * product.dCount : 0 ||
                                        laundryType == "iron" ? product.iPrice * product.iCount : 0
                                  }
                                </td>
                              </tr>
                            )
                          })}
                        </tbody>
                      </Table>
                    </div>

                  </CardBody>
                </Card>
              </Col>
              <Col sm="6">
                <Card>
                  <CardBody className="invoice_card">
                    <div className="invoice-form">
                      <div className="d-flex justify-content-between">
                        <h5>Order Id : {orderData?.orderID || <Skeleton count={1} />}</h5>

                      </div>
                      <div className="">
                        <div><label>Bag Fee</label></div>
                        <Input
                          placeholder="Enter Bag fee"
                          type="number"
                          value={orderData?.bagFee || ''}
                          name="bagfee"
                          onChange={handleChange}
                        />
                      </div>
                      <div className="">
                        <div><label>Delivery Fee</label></div>
                        <Input
                          placeholder="Enter Delivery fee"
                          type="number"
                          value={orderData?.delFee || ''}
                          name="delFee"
                          disabled={true}
                          onChange={handleChange}
                        />
                      </div>
                    </div>
                    <div className="invoice-form">
                      {/* <div className="">
                        <div><label>Select Laundry Name </label></div>
                        <select
                          className="form-control"
                          value={laundry}
                          onChange={(e) => onChangeLaundry(e)}
                        >
                          <option value="">Select Laundry</option>
                          {laundryList.map((val) => {
                            return (
                              <option
                                key={val._id}
                                value={val._id}>{val.shortName ? val.shortName : val.nameEn}</option>
                            )
                          })}
                        </select>

                      </div> */}
                      {laundryData?.type != "franchise_laundry" ?
                        <div className={(error?.billNumber) ? 'text-danger' : ''}>
                          <div><label>Bill Number</label></div>
                          <Input
                            placeholder="Enter Bill Number"
                            type="text"
                            value={billNumber}
                            name="billnumber"
                            className={(error?.billNumber) ? 'av-invalid is-invalid' : ''}
                            onChange={(e) => setBillNumber(e.target.value)}
                          />
                          {(error?.billNumber) ?
                            <div className="invalid-feedback">Please enter bill number</div>
                            : null
                          }
                        </div> : null}
                    </div>

                    <div><b>Bill Amount:  {billingTotal} SR</b></div>
                    <div><b>Sub Total: {subTotal} SR </b></div>
                    <div><b>Total Amount:  {allTotal} SR</b></div>
                    <div><b>Vat Amount:  {vatAmt} SR</b></div>
                    <div><b>Grand Total:  {grandTotal} SR</b></div>

                    <div className="border-top border_top">
                      { orderData.inLaundryTime && orderData.paid != true && editPermission && orderData.status != "cancelled" ?
                        <Button
                          className="btn btn-success float-rights"
                          onClick={CreateInvoice}
                        >
                          {titleInvoice}
                        </Button> :
                        <div>
                          <>
                            {orderData.paid == true ?
                              <h3 className="text-success mt-3 me-2">PAID</h3> : null
                            }
                            {orderData.status == "cancelled" ?
                              <h3 className="text-danger mt-3">CANCELLED</h3> : null
                            }
                          </>

                        </div>
                      }

                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </div>

          {/* Modal */}
          <Modal isOpen={showModal} toggle={toggle} size="md">
            <ModalHeader toggle={toggle} tag="h4">
              Cancel Order
            </ModalHeader>
            <ModalBody>
                <Row className="mb-2">
                  <Col>
                    <label>Cancellation Reason : </label>
                      <textarea
                      className={"form-control " + (reasonError?"is-invalid":"")}
                      onChange={(e) => onReasonChange(e.target.value)}
                    >{cancellationReason}</textarea>
                    {(reasonError == true) ?
                        <div className="invalid-feedback">Enter cancellation reason</div>
                        : null
                      }
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <div className="text-end">
                      {cancelProcessing?
                        <button
                          type="button"
                          className="btn btn-danger"
                          disabled
                        >
                          Processing...
                        </button>: 
                        <button
                          type="button"
                          className="btn btn-danger"
                          onClick={cancelOrder}
                        >
                        Cancel Order
                      </button>}
                    </div>
                  </Col>
                </Row>
            </ModalBody>
          </Modal>
          
        </React.Fragment>
      )
    else
      return <Pages404 />
  }


  if (orderFetched && !orderDetails?._id) {
    return (
      <React.Fragment>

        <div className="page-content">
          <MetaTags>
            <title>
              {titleInvoice}
            </title>
          </MetaTags>
          <Container fluid>
            <Breadcrumbs title="Home" breadcrumbItem={titleInvoice} />
          </Container>
          <Row>
            <Col>
              <div className="d-flex justify-content-between">

                <h4 className="text-warning">Unable to load order.</h4>

                <Button onClick={() => goBack()}>
                  <i className="fas fa-arrow-alt-circle-left"></i> Back
                </Button>
              </div>
            </Col>
          </Row>
        </div>

        

      </React.Fragment>
    )
  }

  if (!orderFetched) {
    return (
      <React.Fragment>

        <div className="page-content">
          <MetaTags>
            <title>
              {titleInvoice}
            </title>
          </MetaTags>
          <Container fluid>
            <Breadcrumbs title="Home" breadcrumbItem={titleInvoice} />
          </Container>
          <Row>
            <Col>
              <div className="d-flex justify-content-end">

                <div>&nbsp;</div>

                <Button onClick={() => goBack()}>
                  <i className="fas fa-arrow-alt-circle-left"></i> Back
                </Button>
              </div>
              <Skeleton count={1} height={300} />
            </Col>
          </Row>
        </div>
      </React.Fragment>
    )
  }
}

CreateOrdersInvoice.propTypes = {
  history: PropTypes.any,
  match: PropTypes.any
}

export default CreateOrdersInvoice
