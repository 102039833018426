import { call, put, takeEvery, all, fork } from "redux-saga/effects";

// Crypto Redux States
import { GET_LAUNDRY_DETAILS, GET_LAUNDRY_LIST, TOGGLE_LAUNDRY_ACTIVE, ADD_LAUNDRY_PERM, DELETE_LAUNDRY, UPDATE_LAUNDRY_PERM } from "./actionTypes";
import { apiSuccess, apiFail, deleteLaundrySuccess, deleteLaundryFail } from "./actions";

//Include Both Helper File with needed methods
import { fetchLaundryList, getLaundry, postAddLaundry, postUpdateLaundry } from "../../helpers/LaundryHelper";
import { deleteLaundry } from "helpers/fakebackend_helper";

function* getLaundrys({ payload: data }) {
  try {
    const response = yield call(fetchLaundryList, data);
    yield put(apiSuccess(GET_LAUNDRY_LIST, response.record));
  } catch (error) {
    yield put(apiFail(GET_LAUNDRY_LIST, error));
  }
}


function* addLaundry({ payload: data }) {
  try {
    const response = yield call(postAddLaundry, data);
    yield put(apiSuccess(ADD_LAUNDRY_PERM, response.record));
  } catch (error) {
    yield put(apiFail(ADD_LAUNDRY_PERM, error));
  }
}

function* updateLaundry({ payload: data }) {
  try {
    const response = yield call(postUpdateLaundry, data);
    yield put(apiSuccess(UPDATE_LAUNDRY_PERM, response.record));
  } catch (error) {
    yield put(apiFail(UPDATE_LAUNDRY_PERM, error));
  }
}


function* getLaundrydetails({ payload: data }) {
  try {
    const response = yield call(getLaundry, data);
    yield put(apiSuccess(GET_LAUNDRY_DETAILS, response.record[0]));
  } catch (error) {
    yield put(apiFail(GET_LAUNDRY_DETAILS, error));
  }
}

function* toggleLaundryActive({ payload: data }) {
  try {
    yield put(TOGGLE_LAUNDRY_ACTIVE, data);
  } catch (error) {
    yield put(TOGGLE_LAUNDRY_ACTIVE, error);
  }
}
function* onDeleteLaundry({ payload: data }) {
  try {
    const response = yield call(deleteLaundry, data)
    if (response.statusCode == "200") {
      yield put(deleteLaundrySuccess(data))
    }
  } catch (error) {
    yield put(deleteLaundryFail(error))
  }
}




function* LaundrySaga() {
  yield takeEvery(GET_LAUNDRY_LIST, getLaundrys)
  yield takeEvery(GET_LAUNDRY_DETAILS, getLaundrydetails)
  yield takeEvery(ADD_LAUNDRY_PERM, addLaundry)
  yield takeEvery(UPDATE_LAUNDRY_PERM, updateLaundry)
  yield takeEvery(DELETE_LAUNDRY, onDeleteLaundry)

}

export default LaundrySaga;
