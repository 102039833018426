import { call, put, takeEvery } from "redux-saga/effects"

// Crypto Redux States
import { LIST_TIMESLOTS, CREATE_TIMESLOT, UPDATE_TIMESLOT, GET_PICKUP_TIMESLOTS, GET_DELIVERY_TIMESLOTS } from "./actionTypes"
import { apiSuccess, apiFail } from "./actions"

//Include Both Helper File with needed methods
import {
  fetchTimeslots,
  _createTimeslot,
  _updateTimeslot,
  fetchPickupTimeslots,
  fetchDeliveryTimeslots,
} from "helpers/timeslotsHelper"
import toastr from "toastr"

function* listTimeslots({ payload: data }) {
  try {
    const response = yield call(fetchTimeslots, data)
    yield put(apiSuccess(LIST_TIMESLOTS, response))
  } catch (error) {
    yield put(apiFail(LIST_TIMESLOTS, error))
  }
}

function* listPickupTimeslots({ payload: data }) {
  try {
    const response = yield call(fetchPickupTimeslots, data)
    yield put(apiSuccess(GET_PICKUP_TIMESLOTS, response))
  } catch (error) {
    yield put(apiFail(GET_PICKUP_TIMESLOTS, error))
  }
}

function* listDeliveryTimeslots({ payload: data }) {
  try {
    const response = yield call(fetchDeliveryTimeslots, data)
    yield put(apiSuccess(GET_DELIVERY_TIMESLOTS, response))
  } catch (error) {
    yield put(apiFail(GET_DELIVERY_TIMESLOTS, error))
  }
}



function* createTimeslot({ payload: data }) {
  try {
    const response = yield call(_createTimeslot, data)
    if (response?.statusCode == "200") {
      toastr.success("Timeslot created successfully")
      yield put(apiSuccess(CREATE_TIMESLOT, response.record))
    }
  } catch (error) {
    yield put(apiFail(CREATE_TIMESLOT, error))
  }
}

function* updateTimeslot({ payload: data }) {
  try {
    const response = yield call(_updateTimeslot, data)
    if (response?.statusCode == "200") {
      toastr.success("Timeslot updated successfully")
      yield put(apiSuccess(UPDATE_TIMESLOT, response.record))
    }
    else{
      if(response?.msg){
        toastr.error(response?.msg);
      }
      else{
        toastr.error("Error while updating timeslot");
      }
    }
  } catch (error) {
    console.log("Error ::", error);

    yield put(apiFail(UPDATE_TIMESLOT, error))
    throw error
  }
}

function* timeslotsSaga() {
  yield takeEvery(LIST_TIMESLOTS, listTimeslots)
  yield takeEvery(CREATE_TIMESLOT, createTimeslot)
  yield takeEvery(UPDATE_TIMESLOT, updateTimeslot)
  yield takeEvery(GET_PICKUP_TIMESLOTS, listPickupTimeslots)
  yield takeEvery(GET_DELIVERY_TIMESLOTS, listDeliveryTimeslots)
}

export default timeslotsSaga
