import {
  CREATE_ROLE_SUCCESS, CREATE_ROLE_FAIL, SEARCH_PERMISSION, API_SUCCESS, API_FAIL, ALL_MODULES, CREATE_MODULE_FAIL, CREATE_MODULE_SUCCESS, 
  UPDATE_MODULE_FAIL, UPDATE_MODULE_SUCCESS, UPDATE_PERMISSION_SUCCESS, 
  UPDATE_PERMISSION_FAIL, 
  CLEAR_ERROR_MSG,
  GET_ALL_PERMISSIONS
} from "./actionTypes";

const INIT_STATE = {
  permissions: [],
  allPermissions: [],
  allModule: [],
  apiErrorMsg:{msg:""},
  apiSuccessMsg: {msg :""}
};

const Permission = (state = INIT_STATE, action) => {
  switch (action.type) {
    case CLEAR_ERROR_MSG:
      return {
        ...state,
        apiErrorMsg: ""
      };
    case API_SUCCESS:
      switch (action.payload.actionType) {
        case CREATE_ROLE_SUCCESS:
          return {
            ...state,
            permissions: [...state.permissions, action.payload.data],
            apiSuccessMsg:{
              title : "Success",
              msg : "Role Created Successfully"
            }
          };

       

        case UPDATE_PERMISSION_SUCCESS:
          return {
            ...state,
            apiSuccessMsg: {
              title:"Success",
              msg :"Role permissions updated successfully"
            }
          };
        case SEARCH_PERMISSION:
          return {
            ...state,
            permissions: action.payload.data
          };
        
        case GET_ALL_PERMISSIONS: 
          return {
            ...state,
            allPermissions: action.payload.data
          };

        case ALL_MODULES:
          return {
            ...state,
            allModule: action.payload.data
          };
        case CREATE_MODULE_SUCCESS:
          return {
            ...state,
            allModule: [...state.allModule, action.payload.data],
            apiSuccessMsg:{
              title:"Success",
              msg :"Permission added successfully"
            }
          };
        case UPDATE_MODULE_SUCCESS:
          return {
            ...state,
            allModule: state.allModule.map((item) => {
              if (item._id === action.payload.data._id)
                return action.payload.data
              else
                return item
            })
          };
        default:
          return state;
      }
    case API_FAIL:
      switch (action.payload.actionType) {
        case SEARCH_PERMISSION:
          return {
            ...state,
            permissions: []
          };
        case ALL_MODULES:
          return {
            ...state,
            allModule: []
          };
        
          case CREATE_ROLE_FAIL:
          console.log("action.payload.error", action.payload.error)
          return {
            ...state,
            apiErrorMsg: {key : new Date(), msg: action.payload.error}
          };

          case CREATE_MODULE_FAIL:
          console.log("action.payload.error", action.payload.error)
          return {
            ...state,
            apiErrorMsg: {key : new Date(), msg: action.payload.error}
          };

        default:
          return state;
      }
    default:
      return state;
  }
}


export default Permission;