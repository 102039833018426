import {
  API_SUCCESS,
  API_FAIL,
  GET_COUPON_SUCCESS,
  GET_COUPON_FAIL
} from "./actionTypes";

const INIT_STATE = {
  coupons: [],
  apiError: { msg: "" }
};

const Coupons = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_COUPON_SUCCESS:
      return {
        ...state,
        coupons: action.payload
      };

    default:
      return state;
  }
}


export default Coupons;