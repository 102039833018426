import { API_SUCCESS, GET_RECORD_SUCCESS, GET_RECORD_FAIL, UPDATE_CUSTOMERS_BAG_SUCCESS } from "./actionTypes"

const INIT_STATE = {
  records: [],
  totalRecord: 0,
  error: {},
}

const records = (state = INIT_STATE, action) => {
  switch (action.type) {
    case API_SUCCESS:
      switch (action.payload.actionType) {
        case UPDATE_CUSTOMERS_BAG_SUCCESS:
          return {
            ...state,
            records: state.records.map((item) => {
              if (item?.customer?._id === action.payload.data._id) {
                return { ...item, customer: { ...item.customer, bagNo: action.payload.data.bagNo } }
              } else {
                return item
              }
            })
          };
        default:
          return state;
      }
    case GET_RECORD_SUCCESS:
      return {
        ...state,
        records: action.payload.record,
        totalRecord: action.payload.total_orders
      }

    case GET_RECORD_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    default:
      return state
  }
}

export default records
