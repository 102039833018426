import React, { Component } from "react"
import ReactEcharts from "echarts-for-react"
import PropTypes from 'prop-types';

class Pie extends Component {
 
  render() {
    return (
      <React.Fragment>
        <ReactEcharts style={{ height: "350px" }} option={this.props.options??null} />
      </React.Fragment>
    )
  }
}

Pie.propTypes = {
  options: PropTypes.object,
};


export default Pie
