import React from "react"
import { Card, CardBody, CardTitle, Progress, Alert } from "reactstrap"
import PropTypes from 'prop-types'

const TopCities = (props) => {
  if(props.data == undefined){
    return <Alert color="warning">Data not found.</Alert>
  }

  if(props.data != undefined && props.data.length == 0){
    return <Alert color="warning">Data not found.</Alert>
  }

  return (
    <React.Fragment>
      <Card>
        <CardBody>
          {/* <CardTitle className="mb-4">Top Cities Selling Product</CardTitle> */}
          <div className="text-center">
            <div className="mb-4">
              <i className="bx bx-map-pin text-primary display-4" />
            </div>
            <h3>
              {props.unit?
                <span>{props.data?props.data[0].data.toFixed(2):0}</span>:
                <span>
                  {props.data?props.data[0].data:0}
                </span>
                
              }
              
            </h3>
            <p>{props.data?props.data[0].name + " " + (props.unit?props.unit:''):""}</p>
          </div>

          <div className="table-responsive mt-4">
            <table className="table align-middle table-nowrap">
              <tbody>
                {props.data ?
                  <>
                    {props.data.map((row, i) => (
                      <tr key={`city-${i}`}>
                        <td style={{ width: "30%" }}>
                          <p className="mb-0">{row.name}</p>
                        </td>
                        <td style={{ width: "25%" }}>
                          <h5 className="mb-0">
                          {props.unit?
                            row.data.toFixed(2) + " " + props.unit: row.data
                          }
                          </h5>
                        </td>
                        <td>
                          <Progress
                            value={props.total?(parseInt((row.data/props.total)*100)):0}
                            color="primary"
                            className="bg-transparent progress-sm"
                            size="sm"
                          />
                        </td>
                      </tr>
                    ))}

                  </> : null}
              </tbody>
            </table>
            {props.onShowMore?
            <div className="d-flex flex-row-reverse">
              <div>
                <a href="#" onClick={(chartType) => props.onShowMore(chartType)}>View More...</a>
              </div>
            </div>:null}
          </div>
        </CardBody>
      </Card>
    </React.Fragment>
  )
}

TopCities.propTypes = {
  data: PropTypes.array,
  "data[].data": PropTypes.number,
  "unit":PropTypes.string,
  "total":PropTypes.number,
  onShowMore:PropTypes.func
}

export default TopCities
