import {postWeb} from "./api_helper"
import * as url from "./url_helper"


const _getNotificationTexts = (data) => postWeb(`${url.GET_NOTIFICATION_TEXTS}`, data)
const _updateNotificationTexts = (data) => postWeb(`${url.UPDATE_NOTIFICATION_TEXTS}`, data)

export {
  _getNotificationTexts,
  _updateNotificationTexts
}
