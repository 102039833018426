/* global google */
import React from "react";
import { Map, Marker, GoogleApiWrapper, MarkerLabel, InfoWindow } from "google-maps-react"

import PropTypes from "prop-types"


import { withRouter, Link } from "react-router-dom";
import { useState } from "react";

const Markers = props => {
  const labelSize = { width: 80 };
  const labelPadding = 2;

  const shortenName = (name) => {
    return name.length>20?name.substring(1,20):name
  }
  const[activeMarker, setActiveMarker] = useState();
  const[showingInfoWindow, setShowingInfoWindow] = useState(false);
  const[selectedOrder, setSelectedOrder] = useState(null);
  
  
  
  const onInfoWindowClose = () =>{
    setActiveMarker(null);
    
  }

  const onMarkerClick = (marker, order) => {
    setActiveMarker(marker);
    setSelectedOrder(order); 
    setShowingInfoWindow(true);
  }


  return (
    <Map 
      google={google}
      style={{ width: "100%", height: "1000px" }}
      zoom={props.zoom} 
      initialCenter={props.center}
      >
      {props.places.map(place => {
        if(place?.location?.latitude && place?.location?.longitude){
          const highLight = {};
          if(props.updatedLaundry == place._id.toString()){
            highLight.animation =  "blinker2 1s linear 15"
            highLight.backgroundColor = "yellow"
          }
          
          // infowindow = new google.maps.InfoWindow();
          // var content =
          //   'Location: '  +
          //   '<br/>Lat: '  +
          //   '<br/>Long: '  ;
            
          // google.maps.event.addListener(marker, "click", function (e) {
          //   infowindow.open(map, this);
          // });

          return (
            <Marker
              label={{
                text:`#${place?.orderID} (${place?.grandTotal?.toFixed(2)} SR)`,
                color:"white",
                className :"customMarker"
              }}
              onClick={(props, marker) => onMarkerClick(marker, place)}
              labelStyle={{
                textAlign: "center",
                width: labelSize.width + "px",
                boxSizing:"border-box",
                backgroundColor: "white",
                fontSize: "11px",
                fontWeight: "bold",
                padding: labelPadding + "px",
                border: "1px solid #999",
                color: "#ea4335",
                ...highLight
              }}
              onMouseOver={function(e){
                this.label.labelDiv_.style.display = 'block';
              }}
              onMouseOut={function(e){
                this.label.labelDiv_.style.display = 'none';
              }}
              labelVisible={false}
              labelClass="map-label"
              labelAnchor={{ x: labelSize.width / 2, y: -2 }}
              key={place._id}
              position={{ lat: Number(place?.location?.latitude), lng: Number(place?.location?.longitude) }}
              
            >
              
              
            </Marker>
          );
          }
      })}
      <InfoWindow
        marker={activeMarker}
        onClose={onInfoWindowClose}
        visible={showingInfoWindow}>
        <div>
          <h2>Order ID: {selectedOrder?.orderID}</h2>
          <h5>
            <span className="label-gray">Grand Total :</span> 
            <span className="label-value">{selectedOrder?.grandTotal} SR</span></h5>
          <h5>
            <span className="label-gray">Mobile :</span>
            <span className="label-value">{selectedOrder?.customer?.mobile}</span>
          </h5>
          <h5>
            <span className="label-gray">Laundry :</span>
            <span className="label-value"> {selectedOrder?.laundry?.nameEn}</span>
           
          </h5>
        </div>
      </InfoWindow>

    </Map>
  );
};

Markers.propTypes = {
  center: PropTypes.object,
  zoom: PropTypes.number,
  places: PropTypes.array,
  updatedLaundry: PropTypes.any
}

export default Markers;
