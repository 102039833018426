import {
  GET_CARWASH_ORDERS_SUCCESS,
  UPDATE_CARWASH_ORDERS_SUCCESS,
} from "./actionTypes"

const INIT_STATE = {
  carwashOrders: [],
}

const CarwashOrders = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_CARWASH_ORDERS_SUCCESS:
      console.log("action.payload", action.payload);
      return {
        ...state,
        carwashOrders: action.payload,
      }

    case UPDATE_CARWASH_ORDERS_SUCCESS:
      const updatedCarwashOrders = [...state.carwashOrders]

      const updatedCarwashOrder = action.payload

      const updatedCarwashOrdersIndex = updatedCarwashOrders.findIndex(
        carwashOrders => carwashOrders._id === updatedCarwashOrder._id
      )

      if (updatedCarwashOrdersIndex > -1) {
        updatedCarwashOrders[updatedCarwashOrdersIndex] = updatedCarwashOrder
      } else {
        updatedCarwashOrders.push(updatedCarwashOrder)
      }

      return {
        ...state,
        carwashOrders: updatedCarwashOrders,
      }

    default:
      return state
  }
}

export default CarwashOrders
