import axios from "axios"
import {postWeb} from "./api_helper"
import * as url from "./url_helper"


const fetchRedeemPkgsList = (data) => postWeb(`${url.GET_REDEEM_PACKAGES}`, data)
const createRedeemPackage = (data) => postWeb(`${url.CREATE_REDEEM_PACKAGE}`, data)
const updateRedeemPackage = (data) => postWeb(`${url.UPDATE_REDEEM_PACKAGE}`, data)

export {
  fetchRedeemPkgsList,
  createRedeemPackage,
  updateRedeemPackage  
}