import axios from "axios"
import { del, get, postWeb, put } from "./api_helper"
import * as url from "./url_helper"


const fetchRegistrationSummary = (data) => postWeb(`${url.ANALYTICS_REGISTRATION_SUMMARY}`, data)
const fetchBagCustomers = (data) => postWeb(`${url.ANALYTICS_BAG_CUSTOMERS}`, data)
const fetchOrderCounts = (data) => postWeb(`${url.ANALYTICS_ORDER_COUNTS}`, data)
const fetchUniqueCustomers = (data) => postWeb(`${url.ANALYTICS_UNIQUE_CUSTOMERS}`, data)


export {
  fetchRegistrationSummary,
  fetchBagCustomers,
  fetchOrderCounts,
  fetchUniqueCustomers
}
