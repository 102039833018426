import PropTypes from "prop-types"
import React, { useEffect, useState, useRef } from "react"
import MetaTags from "react-meta-tags";
import { AvForm, AvField, AvGroup, AvInput } from "availity-reactstrap-validation"
import MapsGoogle from "pages/Maps/MapsGoogle";
import MultiSelect from "react-multiple-select-dropdown-lite";
import Pages404 from "pages/Utility/pages-404";
import DataTable from "react-data-table-component";
import {validateEmail, validatePassword} from "../../helpers/validationHelper"
import {
  Container,
  Row,
  Col,
  Modal,
  Button,
  Card,
  CardBody,
  Label,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  CardText,
  Form,
  Input,
  InputGroup, InputGroupText
} from "reactstrap"
import classnames from "classnames"

import paginationFactory, {
  PaginationListStandalone,
  PaginationProvider,
} from "react-bootstrap-table2-paginator";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import BootstrapTable from "react-bootstrap-table-next";
import { postArea } from "helpers/LaundryHelper"
import { createHoliday, updateHoliday } from "helpers/holiday"
import { searchUsers, filterUsers, getHolidaysList, getHolidayDetail } from "../../store/actions";
import toastr from "toastr";
import "toastr/build/toastr.min.css";
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { withTranslation } from "react-i18next"
import { useSelector, useDispatch } from "react-redux"
import { useHistory } from 'react-router-dom';
import google_map_logo from '../../assets/images/google_map_logo.png'
import Switch from "react-switch"
import moment from "moment";
const Offsymbol = () => {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100%",
        fontSize: 12,
        color: "#fff",
        paddingRight: 2
      }}
    >
      {" "}
      Off
    </div>
  )
}

const OnSymbol = () => {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100%",
        fontSize: 12,
        color: "#fff",
        paddingRight: 2
      }}
    >
      {" "}
      On
    </div>
  )
}

const Holidays = props => {
  const [holidayModal, SetHolidayModal] = useState(false)
  const [status, setStatus] = useState(true)
  const [isEdit, setIsEdit] = useState(false)
  const [isEditItem, setIsEditItem] = useState({})
  const [userData, setUserData] = useState({});
  const [listData, setListData] = useState([]);

  const [name, setName] = useState("");
  const [areaVal, setAreaVal] = useState("");
  const [error, setError] = useState({});
  const [startDate, setStartDate] = useState("")
  const [endDate, setEndDate] = useState("");
  const [allAreas, setAllAreas] = useState(false);
  const [enableClothService, setEnableClothService] = useState(false);
  const [enableCarpetService, setEnableCarpetService] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [areaOption, setAreaOption] = useState("");
  const [areaList, setAreaList] = useState([]);
  

  const { HolidayList, HolidayDetails, users, roles, filteredUsers} = useSelector(state => ({
    HolidayList: state.Holidays.Holidays,
    HolidayDetails: state.Holidays.HolidayDetails,
    roles: state.permission.permissions,
    users: state.Users.users,
    filteredUsers: state.Users.filteredUsers
  }))

  useEffect(() => {
    if(!users) dispatch(searchUsers({ webPermissions: true }))
    dispatch(getHolidaysList())
  }, [])

  useEffect(() => {
    if (users.length > 0) {
      setUserData(users[0]);
    }
  }, [users])

  useEffect(() => {
    setListData(HolidayList)
  }, [HolidayList])

  const handleSearch = (val) => { 
    val = val?.toLowerCase()
    let filterBySearch = HolidayList.filter(item => item?.areas?.findIndex((data) => data?.name?.toLowerCase()?.includes(val)) !== -1 || (item?.status && val == "on") || (!item?.status && val == "off") || item?.name?.toLowerCase().includes(val));
    if (!val) {
      setListData(HolidayList)
    } else {
      setListData(filterBySearch)
    }
  }


  function getLocation() {
    setIsEditItem({});
    SetHolidayModal(true)
  }

  function showPosition(position) {
    SetLat(position.coords.latitude);
    SetLng(position.coords.longitude);
  }

  useEffect(() => {
    if (!holidayModal) {
      setName("")
      setStartDate("")
      setEndDate("")
      setAreaVal("")
      setError({})
      setStatus(true)
      setIsEdit(false);
    }
    
  }, [holidayModal])


  useEffect(async () => {
    const AreaResponse = await postArea({});
    setAreaList(AreaResponse?.record);
    setAreaOption(
      AreaResponse?.record?.map((item) => {
        return { label: item?.name, value: item?._id }
      })
    )
  }, [])

  const handleOnchange = (val) => {
    setAreaVal(val ? val.split(",") : [])
  }

  const GET_AREA = async () => {
    getLocation()
  }

  const UPDATE_HOLIDAY = (item) => {
    console.log(item);
    setName(item.name)
    setStartDate(moment(item.startDate).format("YYYY-MM-DD"))
    setEndDate(moment(item.endDate).format("YYYY-MM-DD"))
    setAllAreas(item.allAreas)
    setEnableCarpetService(item.enableCarpetService)
    setEnableClothService(item.enableClothService)
    setAreaVal(item.areas?.map((item2) => item2._id));
    setStatus(item.status)
    setIsEdit(true);
    setIsEditItem(item)
    SetHolidayModal(true)
  }

  var node = useRef();
  const keyField = "id";
  const { SearchBar } = Search;
  const sizePerPage = 10;

  const pageOptions = {
    sizePerPage: sizePerPage,
    totalSize: listData.length, // replace later with size(users),
    custom: true,
  };

  const defaultSorted = [
    {
      dataField: "id", // if dataField is not match to any column you defined, it will be ignored.
      order: "desc", // desc or asc
    },
  ];

  let ListColumns = [
    {
      name: "Name",
      sortable: true,
      selector: row => row.name,
      wrap: true,
    }, {
      name: "Start Date",
      sortable: true,
      selector: row => moment(row.startDate).format("YYYY-MM-DD"),
      center: true,
      wrap: true,
    },
    {
      name: "End Date",
      sortable: true,
      selector: row => moment(row.endDate).format("YYYY-MM-DD"),
      center: true,
      wrap: true,
    },
    {
      name: "All Areas",
      sortable: true,
      selector: row => (
        row.allAreas?"Yes":"No"
      ),
      center: true,
      wrap: true,
    }, {
      name: "Area",
      wrap: true,
      sortable: true,
      center: true,
      selector: row => (
        <div>
          {row?.areas?.map((item2, index) => {
            return `${item2?.name} |`
          })}
        </div>
      )
    },
    //  {
    //   text: "Area",
    //   dataField: "areas",
    //   sortable: true,
    //   formatter: (cell, row, rowIndex, extraData) => (
    //     <div>
    //       {row?.areas?.map((item2, index) => {
    //         return areaList?.find(data1 => data1?._id === item2) ? ` ${areaList?.find(data1 => data1?._id === item2).name} |` : null

    //       })}
    //     </div>
    //   ),
    // },
    {
      name: "Status",
      sortable: true,
      center: true,
      selector: row => (
        <div>
          {row?.status ? "On" : "Off"}
        </div>
        // <div style={{ paddingLeft: 0 }} className="row d-flex form-check form-switch form-switch-lg mb-3">
        //   <Col sm={9} style={{ paddingLeft: 50 }}>
        //     <input
        //       type="checkbox"
        //       className="form-check-input"
        //       id="customSwitchsizelg horizontal-status-Input"
        //       defaultChecked={row?.status}
        //     />
        //   </Col>
        // </div>
      ),

      // eslint-disable-next-line react/display-name
    },

    // {
    //   dataField: "nameAr",
    //   text: "Name (Arabic)",
    //   sort: true,
    // },
    // {
    //   dataField: "commission",
    //   text: "Commission",
    //   sort: true,
    // },
    // {
    //   text: "Type",
    //   dataField: "type",
    //   sort: true,
    //   formatter: (cell, row, rowIndex, extraData) => (
    //     <div>
    //       {row.type == "0" ? "Laundry" : "Partner Laundry"}
    //     </div>
    //   )
    //   // eslint-disable-next-line react/display-name
    // },


  ];

  if (userData?.permissions?.allPermissions == true || userData?.permissions?.holidays?.update == true) {
    ListColumns.push(
      {
        name: "View",
        center: true,
        selector: row => {
          return <>
            <button onClick={() => UPDATE_HOLIDAY(row)} className="btn btn-primary btn-sm" >
              View
            </button>
            {/* <a onClick={() => showEditLaundry(row._id)} >Edit</a>
             &nbsp; &nbsp;
             <a onClick={() => handleDeleteLaundry(row._id)} >Delete</a> */}
            {/* <a href="#" onClick={() => showPerms(row._id)} >Permissions</a> */}
          </>
        }
      });
  }

  function toggleHolidayModal() {
    SetHolidayModal(!holidayModal);
    removeBodyCss();
  }


  function removeBodyCss() {
    document.body.classList.add("no_padding");
  }

  toastr.options = {
    positionClass: "toast-top-right",
    timeOut: 3000,
    extendedTimeOut: 1000,
    closeButton: true,
    debug: true,
    progressBar: true,
    preventDuplicates: true,
    newestOnTop: true,
    showEasing: "swing",
    hideEasing: "linear",
    showMethod: "fadeIn",
    hideMethod: "fadeOut",
    showDuration: 300,
    hideDuration: 1000
  };

  useEffect(() => {
    validateDate();
  }, [startDate, endDate])

  const validateDate = () => {
    if(moment(endDate).isBefore(startDate)){
      setError({
        dateCompare: "End Date must be greater or equal to start date"
      })
      return false;
    }
    else{
      setError({
        dateCompare: ""
      })
      return true;
    }
    
  }

  const handleValidSubmit = async (event, values) => {
    
    if(!validateDate()){
      return;
    }

    if(!allAreas){
      if (areaVal?.length < 1) {
        setError({ area: "Area Required" });
        return
      }
    }
    
    const AddData = {
      name: name,
      startDate: startDate,
      endDate: endDate,
      allAreas: allAreas,
      areas: (allAreas!=true)?areaVal:[],
      enableCarpetService: enableCarpetService,
      enableClothService: enableClothService,
      status: status
    }


    let HolidayResponse;
    let resTitle, resMsg;
    if (isEdit) {
      AddData["_id"] = isEditItem._id;
      HolidayResponse = await updateHoliday(AddData)
      resMsg = HolidayResponse.statusCode == 200 ? "Holiday Updated" : "Unable to Update Holiday";
    } else {
      
      HolidayResponse = await createHoliday(AddData);
      resMsg = HolidayResponse.statusCode == 200 ? "Holiday Added" : "Unable to Add Holiday";
      setIsEdit(true);
    }

    dispatch(getHolidaysList());
    if (HolidayResponse.statusCode == 200) {
      toastr.success(resMsg, "Success");
    } else {
      toastr.error(resMsg, "Error");
    }
    toggleHolidayModal();
  }

  const dispatch = useDispatch();


  useEffect(async () => {
    if (HolidayDetails?.areas?.length > 0) {
      const AreaResponse = await postArea({});
      setAreaList(AreaResponse?.record);
    }
  }, [HolidayDetails]);

  useEffect(() => {
    handleSearch(searchText);
  }, [searchText])


  if (userData?.permissions?.allPermissions == true || userData?.permissions?.holidays?.read == true) {
    return (
      <React.Fragment>
        <div className="page-content">
          <MetaTags>
            <title>Holidays | Kleen Admin Panel</title>
          </MetaTags>
          <Container fluid>
            {/* Render Breadcrumb */}
            <Breadcrumbs
              title={props.t("Home")}
              breadcrumbItem={props.t("Holidays")}
            />
            <Card>
              <CardBody>
                <Row className="mb-2">
                  <Col sm="10">
                    <div className="me-2 mb-2 d-inline-block">
                      <div className="position-relative">
                        <input
                          id="search-bar-10"
                          type="text"
                          aria-labelledby="search-bar-10-label"
                          className="form-control "
                          placeholder="Search"
                          autoComplete={false}
                          value={searchText}
                          onChange={(e) => setSearchText(e.target.value)}
                        />
                      </div>
                    </div>
                  </Col>

                  <Col sm="2" className="text-end">
                    {(userData?.permissions?.allPermissions == true || userData?.permissions?.holidays?.create == true) ?
                      <Button
                        type="button"
                        color="success"
                        className="btn-rounded  "
                        onClick={() => GET_AREA()}
                      >
                        <i className="mdi mdi-plus me-1" />
                        Add Holiday
                      </Button>
                      : null
                    }
                  </Col>
                </Row>
                <Row>
                  <DataTable
                    columns={ListColumns}
                    data={listData}
                    pagination
                  />
                </Row>
              </CardBody>
            </Card>
            <div>
              <Modal
                size="lg"
                isOpen={holidayModal}
                toggle={() => {
                  toggleHolidayModal();
                }}
              >
                <div className="modal-header">
                  <h5 className="modal-title mt-0" id="myModalLabel">
                    <span >{isEdit ? "Update Holiday" : "Create Holiday"}</span>
                  </h5>
                  <button
                    type="button"
                    onClick={() => {
                      SetHolidayModal(false);
                    }}
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                
              <div className="modal-body">
                
                    <Row>
                      <Col sm="12">
                        <CardText className="mb-0">
                          <AvForm
                            onValidSubmit={(e, v) => {
                            handleValidSubmit(e, v)
                            }}
                            >
                            <div className="row mb-2">
                              <Col sm={6}>
                                <Label htmlFor="horizontal-firstname-Input" className="col-sm-4 col-form-label">
                                  Name <span className="mandatory_feild">*</span>
                                </Label>
                                <AvField
                                  type="text"
                                  onChange={(e) => { setName(e.target.value) }}
                                  className="form-control"
                                  id="horizontal-firstname-Input"
                                  placeholder="Enter Name"
                                  name="name"
                                  errorMessage="Please Enter Name"
                                  validate={{ required: { value: true } }}
                                  value={name}
                                />
                              </Col>
                              
                            </div>
                            <div className="row mb-2">
                              <Col sm={6}>
                                <Label htmlFor="horizontal-firstname-Input" className="col-sm-4 col-form-label">
                                  Start Date <span className="mandatory_feild">*</span>
                                </Label>
                                <AvField
                                  type="date"
                                  onChange={(e) => { setStartDate(e.target.value) }}
                                  className="form-control"
                                  id="horizontal-firstname-Input"
                                  placeholder="Enter Start Date"
                                  name="startDate"
                                  errorMessage="Please Enter Start Date"
                                  validate={{ required: { value: true } }}
                                  value={startDate}
                                />
                              </Col>
                              
                            </div>

                            <div className="row mb-2">
                              <Col sm={6}>
                                <Label htmlFor="horizontal-firstname-Input" className="col-sm-4 col-form-label">
                                  End Date <span className="mandatory_feild">*</span>
                                </Label>
                                <AvField
                                  type="date"
                                  onChange={(e) => { setEndDate(e.target.value) }}
                                  className="form-control"
                                  id="horizontal-firstname-Input"
                                  placeholder="Enter Start Date"
                                  name="endDate"
                                  errorMessage="Please Enter End Date"
                                  validate={{ required: { value: true } }}
                                  value={endDate}
                                />

                              {error?.dateCompare?
                                <label style={{ color: "red" }}>{error?.dateCompare}</label>
                              : null}

                              </Col>
                              
                            </div>

                          <div className="row mb-2">
                              <Col sm={6} className="d-flex mt-2">
                                <Label
                                  htmlFor="horizontal-password-Input"
                                  className="col-form-label col-md-3"
                                >
                                  All Areas
                                </Label>
                                <div className="form-check form-switch">
                                  <Switch
                                    uncheckedIcon={<Offsymbol />}
                                    checkedIcon={<OnSymbol />}
                                    className="me-1 mb-sm-8 mb-2"
                                    onColor="#626ed4"
                                    onChange={(e) => setAllAreas(!allAreas)}
                                    checked={allAreas}
                                  />
                                  
                                </div>
                              </Col>
                            </div>                            
                            
                            {allAreas!=true?                            
                            <div className="row mb-2">
                              <Col sm={6}>
                                <Label
                                  htmlFor="horizontal-password-Input"
                                  className="col-sm-3 col-form-label"
                                >
                                  Areas <span className="mandatory_feild">*</span>
                                </Label>
                                <MultiSelect
                                  defaultValue={areaVal.toString()}
                                  className="multi-selects form-selects2 form-controlss areas-box"
                                  onChange={handleOnchange}
                                  options={areaOption}
                                />
                                {
                                  error?.area ? (
                                    <label style={{ color: "red" }}>{error?.area}</label>
                                  ) : null
                                }
                              </Col>
                              </div>:null}



                            <div className="row mb-2">
                              <Col sm={6} className="d-flex mt-2">
                                <Label
                                  htmlFor="horizontal-password-Input"
                                  className="col-form-label col-md-3"
                                >
                                  Cloth Service
                                </Label>
                                <div className="form-check form-switch">
                                  <Switch
                                    uncheckedIcon={<Offsymbol />}
                                    checkedIcon={<OnSymbol />}
                                    className="me-1 mb-sm-8 mb-2"
                                    onColor="#626ed4"
                                    onChange={(e) => setEnableClothService(!enableClothService)}
                                    checked={enableClothService}
                                  />
                                  
                                </div>
                              </Col>
                            </div>

                            <div className="row mb-2">
                              <Col sm={6} className="d-flex mt-2">
                                <Label
                                  htmlFor="horizontal-password-Input"
                                  className="col-form-label col-md-3"
                                >
                                  Carpet Service
                                </Label>
                                <div className="form-check form-switch">
                                  <Switch
                                    uncheckedIcon={<Offsymbol />}
                                    checkedIcon={<OnSymbol />}
                                    className="me-1 mb-sm-8 mb-2"
                                    onColor="#626ed4"
                                    onChange={(e) => setEnableCarpetService(!enableCarpetService)}
                                    checked={enableCarpetService}
                                  />
                                  
                                </div>
                              </Col>
                            </div>


                            <div className="row mb-2">
                              <Col sm={6} className="d-flex mt-2">
                                <Label
                                  htmlFor="horizontal-password-Input"
                                  className="col-form-label col-md-3"
                                >
                                  Status
                                </Label>
                                <div className="form-check form-switch">
                                  <Switch
                                    uncheckedIcon={<Offsymbol />}
                                    checkedIcon={<OnSymbol />}
                                    className="me-1 mb-sm-8 mb-2"
                                    onColor="#626ed4"
                                    onChange={(e) => setStatus(!status)}
                                    checked={status}
                                  />
                                  
                                </div>
                              </Col>
                            </div>
                            <div className="row justify-content-end">
                              <Col sm={6} className="col-sm-6 text-end">
                                <div>
                                  <Button
                                    type="submit"
                                    color="primary"
                                  >
                                    {isEdit ? "Update Holiday" : "Add Holiday"}
                                  </Button>

                                  <button
                                    type="button"
                                    onClick={() => {
                                      toggleHolidayModal();
                                    }}
                                    className="m-1 btn btn-secondary"
                                    data-dismiss="modal"
                                  >
                                    Close
                                  </button>

                                </div>
                              </Col>
                            </div>

                          </AvForm>
                        </CardText>
                      </Col>
                    </Row>
                
              </div>
              </Modal>


            </div>
          </Container>
        </div>

      </React.Fragment>
    )
  } else {

    return <Pages404 />
  }

}
Holidays.propTypes = {
  t: PropTypes.any
}

export default withTranslation()(Holidays)



