    import React, { useEffect, useState } from "react"
    import { Row, Col, Card, CardBody, CardTitle,Button, Modal, Nav,
        NavItem,
        NavLink,TabPane,CardText,
        Table,InputGroup,  Input,
        TabContent} from "reactstrap"
    import MetaTags from 'react-meta-tags';
    import { connect } from "react-redux";
    import { Link, withRouter } from "react-router-dom"

    import classnames from "classnames"
    import PropTypes from "prop-types"
    // datatable related plugins
    import BootstrapTable from 'react-bootstrap-table-next';
    import paginationFactory, {
    PaginationProvider, PaginationListStandalone,
    SizePerPageDropdownStandalone
    } from 'react-bootstrap-table2-paginator';

    import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';

    //Import Breadcrumb
    import Breadcrumbs from "../../components/Common/Breadcrumb"
    import "./datatables.scss"
    import { getRecord } from "store/actions";

 
      
    const Records = props => {
        const productListvar = [
            {
              id: 1,
            
              name: "Half sleeve T-shirt",
              color: "Green",
              price: "450",
              data_attr: 2,
              total: 900,
            },
            {
              id: 2,
         
              name: "Black color T-shirt",
              color: "Black",
              price: "225",
              data_attr: 6,
              total: 225,
            },
            {
              id: 3,
              
              name: "Printed T-shirt",
              color: "Black",
              price: "152",
              data_attr: 2,
              total: 304,
            },
            {
              id: 4,
               
              name: "Smiley Plain T-shirt",
              color: "Blue",
              price: "145",
              data_attr: 2,
              total: 290,
            },
            {
              id: 5,
            
              name: "Full sleeve T-Shirt",
              color: "Light orange",
              price: "138",
              data_attr: 8,
              total: 138,
            },
            {
              id: 6,
            
              name: "Sky blue color T-shirt",
              color: "Green",
              price: "152",
              data_attr: 2,
              total: 304,
            },
          ]
        const {records,onGetRecord} = props
        const [modal_standard, setmodal_standard] = useState(false);
        const [activeTab1, setactiveTab1] = useState("5");
        const [productList, setproductList] = useState(productListvar);
        const [increaseIronState,setincreaseIronState] = useState(1);
    const columns = [{
        dataField: '_id',
        text: 'Id',
        sort: true,
    },{
        dataField: 'startTime',
        text: 'Start Time',
        sort: true
    },
    {
        // dataField: '',
        text: 'View',
        sort: false,
        dataField: '_id',
        formatter: ( ) => (
            <Button
              type="button"
              color="primary"
              className="btn-sm btn-rounded"
            //   onClick={toggleViewModal}
            onClick={() => {
                tog_standard();
              }}
            >
              View Details
            </Button>
          ),
    },
    //  {
    //     dataField: 'position',
    //     text: 'Position',
    //     sort: true
    // }, {
    //     dataField: 'office',
    //     text: 'Office',
    //     sort: true
    // }, {
    //     dataField: 'age',
    //     text: 'Age',
    //     sort: true
    // }, {
    //     dataField: 'startdate',
    //     text: 'Start Date',
    //     sort: true
    // }, {
    //     dataField: 'salary',
    //     text: 'Salary',
    //     sort: true
    // }
];
 
    // Table Data
 
      const productData = [{}
        // { "id": 1, "name": "Airi Satou", "position": "Accountant", "office": "Tokyo", "age": "33", "startdate": "2008/11/28", "salary": "$162,700" }
      ];

    const defaultSorted = [{
        dataField: 'id',
        order: 'asc'
    }];

    const pageOptions = {
        sizePerPage: 10,
        totalSize: productData.length, // replace later with size(customers),
        custom: true,
    }

    // Custom Pagination Toggle
    const sizePerPageList = [
        { text: '5', value: 5 },
        { text: '10', value: 10 },
        { text: '15', value: 15 },
        { text: '20', value: 20 },
        { text: '25', value: 25 },
        { text: 'All', value: (productData).length }];


    // Select All Button operation
    const selectRow = {
        mode: 'checkbox'
    }
    useEffect(() => {
        onGetRecord()
    }, [])
    const { SearchBar } = Search;
    function tog_standard(e) {
        console.log("Ddddddddddd",e)
        setmodal_standard(!modal_standard);
        removeBodyCss();
      }
      function removeBodyCss() {
        document.body.classList.add("no_padding");
      }
    //for toggle tab bar
  
    const toggle1 = tab => {
        if (activeTab1 !== tab) {
          setactiveTab1(tab)
        }
      }

      function countUP(id, prev_data_attr) {
        
        setproductList(
          productList.map(p =>
            p.id === id ? { ...p, data_attr: prev_data_attr + 1 } : p
          )
        )
      }
    
      function countDown(id, prev_data_attr) {
    
        setproductList(
          productList.map(p =>
            p.id === id ? { ...p, data_attr: prev_data_attr - 1 } : p
          )
        )
      }

      const increaseIron =()=>{
        var addComp ={
            id: 1,
            name: "New Row inserted",
            color: "Green",
            price: "450",
            data_attr: 2,
            total: 900,
          }
          productList.push(addComp);
        setproductList(productList);
      }
      useEffect(()=>{
        productList
      },[increaseIronState])
     
    //end toggle tab bar
    return (
        <React.Fragment>
        <div className="page-content">
            <MetaTags>
            <title>Records</title>
            </MetaTags>
            <div className="container-fluid">
            <Breadcrumbs title="Orders" breadcrumbItem="Order Table" />

            <Row>
                <Col className="col-12">
                <Card>
                    <CardBody>
                    {/* <CardTitle className="h4">Default Datatable </CardTitle> */}
                    {/* <p className="card-title-desc">
                        react-bootstrap-table-next plugin has most features enabled by default,
                        so all you need to do to use it with your own tables is to
                        call the construction function:{" "}
                        <code>react-bootstrap-table-next </code>.
                    </p> */}

                    <PaginationProvider
                        pagination={paginationFactory(pageOptions)}
                        keyField='_id'
                        columns={columns}
                        data={records?records:productData}
                    >
                        {({ paginationProps, paginationTableProps }) => (
                        <ToolkitProvider
                            keyField='_id'
                            columns={columns}
                            data={records ? records:productData}
                            search
                        >
                            {toolkitProps => (
                            <React.Fragment>

                                <Row className="mb-2">
                                <Col md="4">
                                    <div className="search-box me-2 mb-2 d-inline-block">
                                    <div className="position-relative">
                                        <SearchBar
                                        {...toolkitProps.searchProps}
                                        />
                                        <i className="bx bx-search-alt search-icon" />
                                    </div>
                                    </div>
                                </Col>
                                </Row>

                                <Row>
                                <Col xl="12">
                                    <div className="table-responsive">
                                    <BootstrapTable
                                        keyField={"id"}
                                        responsive
                                        bordered={false}
                                        striped={false}
                                        defaultSorted={defaultSorted}
                                        selectRow={selectRow}
                                        classes={
                                        "table align-middle table-nowrap"
                                        }
                                        headerWrapperClasses={"thead-light"}
                                        {...toolkitProps.baseProps}
                                        {...paginationTableProps}
                                    />

                                    </div>
                                </Col>
                                </Row>

                                <Row className="align-items-md-center mt-30">
                                <Col className="inner-custom-pagination d-flex">
                                    <div className="d-inline">
                                    <SizePerPageDropdownStandalone
                                        {...paginationProps}
                                    />
                                    </div>
                                    <div className="text-md-right ms-auto">
                                    <PaginationListStandalone
                                        {...paginationProps}
                                    />
                                    </div>
                                </Col>
                                </Row>
                            </React.Fragment>
                            )
                            }
                        </ToolkitProvider>
                        )
                        }</PaginationProvider>

                    </CardBody>
                </Card>
                </Col>
            </Row>
            </div>
        </div>
        <Modal
                      isOpen={modal_standard}
                      toggle={() => {
                        tog_standard();
                      }}
                    >
                      <div className="modal-header">
                        {/* <h5 className="modal-title mt-0" id="myModalLabel">
                          Modal Heading
                        </h5> */}
               
                        <button
                          type="button"
                          onClick={() => {
                            setmodal_standard(false);
                          }}
                          className="close"
                          data-dismiss="modal"
                          aria-label="Close"
                        >
                          <span aria-hidden="true">&times;</span>
                        </button>
                      </div>
                      <div className="modal-body">
                      <Card>
                <CardBody>
                  {/* <CardTitle className="h4">Justify Tabs</CardTitle> */}
                  {/* <p className="card-title-desc">
                  </p> */}
     
                  <Nav pills className="navtab-bg nav-justified">
                    <NavItem>
                      <NavLink
                        style={{ cursor: "pointer"}}
                        className={classnames({
                          active: activeTab1 === "5",
                        }) + " "+"btn-sm btn-rounded"}
                        onClick={() => {
                          toggle1("5")
                        }}
                      >
                        Wash
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        style={{ cursor: "pointer" }}
                        className={classnames({
                          active: activeTab1 === "6",
                        })}
                        onClick={() => {
                          toggle1("6")
                        }}
                      >
                        Dry clean
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        style={{ cursor: "pointer" }}
                        className={classnames({
                          active: activeTab1 === "7",
                        })}
                        onClick={() => {
                          toggle1("7")
                        }}
                      >
                        iron
                      </NavLink>
                    </NavItem>
                  </Nav>
                  <TabContent activeTab={activeTab1} className="p-3 text-muted">
                    <TabPane tabId="5">
                      <Row>
                      <i className="fas fa-plus-circle" onClick={()=>{
                          increaseIron();
                          setincreaseIronState(increaseIronState+1)
                          }}></i> 
                        <Col sm="12">
                          <CardText className="mb-0">
                          <div className="table-responsive">
                    <Table className="table align-middle mb-0 table-nowrap">
                  
                      <tbody>
                        {productList.map(product => (
                          <tr key={product.id}>
                             
                            <td>
                              <h5 className="font-size-14 text-truncate">
                                <Link
                                  to={"/ecommerce-product-detail/" + product.id}
                                  className="text-dark"
                                >
                                  {product.name}
                                </Link>
                              </h5>
                               
                            </td>
                           
                            <td>
                              <div style={{ width: "120px" }}>
                                <InputGroup>
                                  <Button addonType="prepend">
                                    <Button
                                      color="primary"
                                      onClick={() => {
                                        countUP(product.id, product.data_attr)
                                      }}
                                    >
                                      +
                                    </Button>
                                  </Button>
                                  <Input
                                    type="text"
                                    value={product.data_attr}
                                    name="demo_vertical"
                                    readOnly
                                  />
                                  <Button addonType="append">
                                    <Button
                                      color="primary"
                                      onClick={() => {
                                        countDown(product.id, product.data_attr)
                                      }}
                                    >
                                      -
                                    </Button>
                                  </Button>
                                </InputGroup>
                              </div>
                            </td>
                            
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  </div>
                          </CardText>
                        </Col>
                      </Row>
                    </TabPane>
                    <TabPane tabId="6">
                      <Row>
                      <i className="fas fa-plus-circle"></i> 
                      <Col sm="12">
                          <CardText className="mb-0">
                          <div className="table-responsive">
                    <Table className="table align-middle mb-0 table-nowrap">
                  
                      {/* <tbody>
                        {productListt.map(product => (
                          <tr key={product.id}>
                             
                            <td>
                              <h5 className="font-size-14 text-truncate">
                                <Link
                                  to={"/ecommerce-product-detail/" + product.id}
                                  className="text-dark"
                                >
                                  {product.name}
                                </Link>
                              </h5>
                               
                            </td>
                           
                            <td>
                              <div style={{ width: "120px" }}>
                                <InputGroup>
                                  <Button addonType="prepend">
                                    <Button
                                      color="primary"
                                      onClick={() => {
                                        countUP(product.id, product.data_attr)
                                      }}
                                    >
                                      +
                                    </Button>
                                  </Button>
                                  <Input
                                    type="text"
                                    value={product.data_attr}
                                    name="demo_vertical"
                                    readOnly
                                  />
                                  <Button addonType="append">
                                    <Button
                                      color="primary"
                                      onClick={() => {
                                        countDown(product.id, product.data_attr)
                                      }}
                                    >
                                      -
                                    </Button>
                                  </Button>
                                </InputGroup>
                              </div>
                            </td>
                            
                          </tr>
                        ))}
                      </tbody> */}
                    </Table>
                  </div>
                          </CardText>
                        </Col>
                      </Row>
                    </TabPane>
                    <TabPane tabId="7">
{/*                     
                     <Row>
                      <i className="fas fa-plus-circle"></i> 
                        <Col sm="12">
                          <CardText className="mb-0">
                          <div className="table-responsive">
                    <Table className="table align-middle mb-0 table-nowrap">
                  
                      <tbody>
                        {productList.map(product => (
                          <tr key={product.id}>
                             
                            <td>
                              <h5 className="font-size-14 text-truncate">
                                <Link
                                  to={"/ecommerce-product-detail/" + product.id}
                                  className="text-dark"
                                >
                                  {product.name}
                                </Link>
                              </h5>
                               
                            </td>
                           
                            <td>
                              <div style={{ width: "120px" }}>
                                <InputGroup>
                                  <Button addonType="prepend">
                                    <Button
                                      color="primary"
                                      onClick={() => {
                                        countUP(product.id, product.data_attr)
                                      }}
                                    >
                                      +
                                    </Button>
                                  </Button>
                                  <Input
                                    type="text"
                                    value={product.data_attr}
                                    name="demo_vertical"
                                    readOnly
                                  />
                                  <Button addonType="append">
                                    <Button
                                      color="primary"
                                      onClick={() => {
                                        countDown(product.id, product.data_attr)
                                      }}
                                    >
                                      -
                                    </Button>
                                  </Button>
                                </InputGroup>
                              </div>
                            </td>
                            
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  </div>
                          </CardText>
                        </Col>
                      </Row>
                     */}
                    </TabPane>
 
                  </TabContent>
                </CardBody>
              </Card>
                       </div>
                    </Modal>
        </React.Fragment>
    )
    }
    Records.propTypes = {
        records: PropTypes.any,
        onGetRecord: PropTypes.func,
    }
    
    const mapStateToProps = ({ Records }) => ({
        records: Records.records.record,
    })
    
    const mapDispatchToProps = dispatch => ({
        onGetRecord: () => dispatch(getRecord()),
    })
    export default connect(
        mapStateToProps,
        mapDispatchToProps
    )(withRouter(Records));