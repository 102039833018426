import {
  GET_DRIVER_LIST_SUCCESS,
  GET_DRIVER_LIST_FAIL,
  DELETE_DRIVER_SUCCESS,
  DELETE_DRIVER_FAIL,
  UPDATE_DRIVER_SUCCESS,
  UPDATE_DRIVER_FAIL,
  ADD_NEW_DRIVER_SUCCESS,
  ADD_NEW_DRIVER_FAIL,
  GET_DRIVER_LOCATIONS_SUCCESS,
  GET_DRIVER_LOCATIONS_FAIL
} from "./actionTypes"

const INIT_STATE = {
  driverList: []
}

const Drivers = (state = INIT_STATE, action) => {
  switch (action.type) {

    case GET_DRIVER_LIST_SUCCESS:
      return {
        ...state,
        driverList: action.payload.record,
      }

    case GET_DRIVER_LIST_FAIL:
      return {
        ...state,
        error: action.payload,
      }

      // case ADD_NEW_DRIVER_SUCCESS:
      //   return {
      //     ...state,
      //     driverList: [...state.driverList, action.payload.record],
      //   }
  
      case ADD_NEW_DRIVER_FAIL:
        return {
          ...state,
          error: action.payload,
        }

      case UPDATE_DRIVER_SUCCESS:
        return {
          ...state,
          driverList: state.driverList.map((item) => {
            if (item._id === action.payload._id)
              return {...item, ...action.payload}
            else
              return item
          })
        };
  
      case UPDATE_DRIVER_FAIL:
        return {
          ...state,
          error: action.payload,
        }

      case DELETE_DRIVER_SUCCESS:
        return {
          ...state,
          driverList: state.driverList.filter((item) => item._id !== action.payload._id),
        }
  
      case DELETE_DRIVER_FAIL:
        return {
          ...state,
          error: action.payload,
        }

      case GET_DRIVER_LOCATIONS_SUCCESS:
        return {
          ...state,
          driverLocations: action.payload.record,
        }

      case GET_DRIVER_LOCATIONS_FAIL:
        return {
          ...state,
          error: action.payload,
        }

    default:
      return state
  }
}

export default Drivers
