import {
  GET_SLIDER_SUCCESS,
  GET_SLIDER_FAIL,
  ADD_NEW_SLIDER_SUCCESS,
  ADD_NEW_SLIDER_FAIL,
  UPDATE_SLIDER_SUCCESS,
  UPDATE_SLIDER_FAIL,
  DELETE_SLIDER_SUCCESS,
  DELETE_SLIDER_FAIL
} from "./actionTypes"

const INIT_STATE = {
  Sliders: [],
}

const Slider = (state = INIT_STATE, action) => {
  switch (action.type) {

    case GET_SLIDER_SUCCESS:
      return {
        ...state,
        Sliders: action.payload,
      }

    case GET_SLIDER_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case ADD_NEW_SLIDER_SUCCESS:
      return {
        ...state,
        Sliders: { record: [...state.Sliders.record, action.payload] },
      }

    case ADD_NEW_SLIDER_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    // case UPDATE_SLIDER_SUCCESS:
    //   return {
    //     ...state,
    //     Sliders: {
    //       record: state.Sliders.record.map((item) => {
    //         if (item._id === action.payload._id) {
    //           return { ...item, ...action.payload }
    //         } else
    //           return item
    //       })
    //     }
    //   }

    case UPDATE_SLIDER_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case DELETE_SLIDER_SUCCESS:
      return {
        ...state,
        Sliders: {
          record: state.Sliders.record.filter((item) => item._id !== action.payload._id),
        }
      }

    case DELETE_SLIDER_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    default:
      return state
  }
}

export default Slider