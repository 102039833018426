import { call, put, takeEvery } from "redux-saga/effects"
import { postAddUser } from "helpers/userHelper"
import toastr from "toastr";
import "toastr/build/toastr.min.css";

import {
  GET_REDEEM_PKGS_LIST,
} from "./actionTypes"

//Include Both Helper File with needed methods
import {
  fetchRedeemPkgsList  
} from "helpers/redeemPackagesHelper"

import {
  getRedeemPkgsListSuccess,
  getRedeemPkgsListFail,
} from "./actions"



function* getRedeemPackages({ payload: data }) {

  console.log("Called:::");

  try {

    const response = yield call(fetchRedeemPkgsList, data)

    yield put(getRedeemPkgsListSuccess(response))
  } catch (error) {
    console.log("Got err" + error)
    yield put(getRedeemPkgsListFail(error))
  }
}

function* redeemPkgsSaga() {
  yield takeEvery(GET_REDEEM_PKGS_LIST, getRedeemPackages)
  
}

export default redeemPkgsSaga;
