import { call, put, takeEvery, all, fork } from "redux-saga/effects";

// Crypto Redux States
import { GET_LAUNDRY_DETAILS, 
  GET_LAUNDRIES_LIST, 
  TOGGLE_LAUNDRY_ACTIVE,
  ALL_LAUNDRY_REVENUE
} from "./actionTypes";
import { apiSuccess, apiFail } from "./actions";

//Include Both Helper File with needed methods
import {fetchLaundryList, getLaundry, fetchAllLaundriesRevenue} from "../../helpers/LaundryHelper";

function* getLaundries({ }) {
    try {
        const response = yield call(fetchLaundryList);
        yield put(apiSuccess(GET_LAUNDRIES_LIST, response.record));
    } catch (error) {
        yield put(apiFail(GET_LAUNDRIES_LIST, error));
    }
}

function* getLaundrydetails({payload: data}) {
    //let _data = {...data, active:true};
    try {
        const response = yield call(getLaundry, data);
        
        yield put(apiSuccess(GET_LAUNDRY_DETAILS, response.record));
    } catch (error) {
        yield put(apiFail(GET_LAUNDRY_DETAILS, error));
    }
}

function* getAllLaundriesRevenue({payload: data}) {
  try {
      const response = yield call(fetchAllLaundriesRevenue, data);
      
      yield put(apiSuccess(ALL_LAUNDRY_REVENUE, response.record));
  } catch (error) {
      yield put(apiFail(ALL_LAUNDRY_REVENUE, error));
  }
}


function* toggeLaundryActive({payload: data}) {
    try {
        yield put(TOGGLE_LAUNDRY_ACTIVE, data);
    } catch (error) {
        yield put(TOGGLE_LAUNDRY_ACTIVE, error);
    }
}




function* laundrySaga() {
    yield takeEvery(GET_LAUNDRIES_LIST, getLaundries)
    yield takeEvery(GET_LAUNDRY_DETAILS, getLaundrydetails)
    yield takeEvery(ALL_LAUNDRY_REVENUE, getAllLaundriesRevenue)
    
    
}

export default laundrySaga;
