import React, { useEffect, useState } from "react"
import MetaTags from "react-meta-tags"
import classnames from "classnames"
import { Link } from "react-router-dom"
import { Row, Col, Card, CardBody, Button } from "reactstrap"
import { Nav, NavItem, NavLink } from "reactstrap"
import { useSelector, useDispatch } from "react-redux"

import Pages404 from "pages/Utility/pages-404"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import {
  getArealist,
  searchUsers,
  updateService,
  getServices,
} from "../../store/actions"
import ServiceEditModal from "./modals/ServiceEditModal"

const ServiceManagement = () => {
  const dispatch = useDispatch()

  const { areaList, users, services } = useSelector(state => ({
    areaList: state.ecommerce.arealist,
    users: state.Users.users,
    services: state.Services.services,
  }))

  const [areas, SetAreas] = useState([])

  const [activeTab, setactiveTab] = useState()
  const [userData, setUserData] = useState({})

  const [allSelected, setAllSelected] = useState(false)
  const [noneSelected, setNoneSelected] = useState(false)
  const [selectedAreas, setSelectedAreas] = useState()
  const [serviceStatus, setServiceStatus] = useState()

  const [isServiceEditModalOpen, setIsServiceEditModalOpen] = useState(false)
  const [isEditingService, setIsEditingService] = useState(false)

  useEffect(() => {
   if(!users) dispatch(searchUsers({ webPermissions: true }))
    dispatch(getArealist({ status: true }))
    dispatch(getServices())
  }, [])

  useEffect(() => {
    SetAreas(
      areaList.map(item => {
        return item
      })
    )
  }, [areaList])

  useEffect(() => {
    if (users.length > 0) {
      setUserData(users[0])
    }
  }, [users])

  React.useEffect(() => {
    if (!selectedAreas) {
      return
    }
    if (selectedAreas.length == areas.length) {
      setAllSelected(true)
    } else if (selectedAreas.length == 0) {
      setNoneSelected(true)
    }
  }, [selectedAreas])

  React.useEffect(() => {
    if (allSelected) {
      setSelectedAreas(areas)
    }
  }, [allSelected])

  React.useEffect(() => {
    if (noneSelected) {
      setSelectedAreas([])
    }
  }, [noneSelected])

  React.useEffect(() => {
    if (activeTab) {
      const currentService = services.find(
        service => service.serviceCode == activeTab
      )
      setServiceStatus(currentService.status)
      if (currentService.enabledForAll) {
        setAllSelected(true)
        setNoneSelected(false)
        setSelectedAreas(areas)
      } else {
        setAllSelected(false)
        setNoneSelected(false)
        setSelectedAreas(
          areas.filter(area => currentService.enabledAreas.includes(area._id))
        )
      }
    }
  }, [activeTab])

  const saveServiceAreas = () => {
    dispatch(
      updateService({
        enabledForAll: allSelected,
        enabledAreas: selectedAreas,
        serviceCode: activeTab,
        status: serviceStatus,
      })
    )
  }

  useEffect(() => {
    if (!activeTab && services && services.length > 0) {
      const firstServiceCode = services[0].serviceCode
      setactiveTab(firstServiceCode)
    }
  }, [services])

  if (
    userData?.permissions?.allPermissions == true ||
    userData?.permissions?.serviceManagement?.read == true
  ) {
    return (
      <React.Fragment>
        <div className="page-content">
          <MetaTags>
            <title>Service Management / Kleen admin</title>
          </MetaTags>
          <div className="container-fluid">
            <Breadcrumbs title="Home" breadcrumbItem="Service Management" />
            <Row>
              <Col className="col-12">
                <Card>
                  <CardBody>
                    <Row className="mb-2 d-flex justify-content-between">
                      <Col>
                        <Nav pills className="navtab-bg">
                          {services &&
                            services.map(service => {
                              return (
                                <NavItem
                                  key={service.serviceCode}
                                  className="mx-2"
                                >
                                  <NavLink
                                    style={{ cursor: "pointer" }}
                                    className={classnames("border", {
                                      active: activeTab === service.serviceCode,
                                    })}
                                    onClick={() => {
                                      setactiveTab(service.serviceCode)
                                    }}
                                  >
                                    <div className="text-capitalize">
                                      {service.serviceCode}
                                    </div>
                                  </NavLink>
                                </NavItem>
                              )
                            })}
                        </Nav>
                      </Col>
                      <Col>
                        <Row>
                          <Col>
                            {userData?.permissions?.allPermissions == true ||
                            userData?.permissions?.serviceManagement?.create ==
                              true ? (
                              <Link
                                to="#"
                                onClick={() => {
                                  setIsEditingService(false)
                                  setIsServiceEditModalOpen(true)
                                }}
                                style={{ float: "right" }}
                              >
                                <i className="mdi mdi-plus me-1"></i>
                                <span>Add Service</span>
                              </Link>
                            ) : null}
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                    {activeTab && (userData?.permissions?.allPermissions == true || userData?.permissions?.serviceManagement?.update == true) && (
                      <div className="d-flex justify-content-between my-3">
                        <div className="d-flex">
                          <div className="form-check form-switch form-switch-lg">
                            <label className="m-0">Enable All</label>
                            <input
                              type="checkbox"
                              className="form-check-input"
                              checked={allSelected}
                              onChange={e => {
                                if (e.target.checked) {
                                  setNoneSelected(false)
                                  setAllSelected(true)
                                } else {
                                  setAllSelected(false)
                                }
                              }}
                            />
                          </div>

                          <div className="form-check form-switch form-switch-lg mx-4">
                            <label className="m-0">Disable All</label>
                            <input
                              type="checkbox"
                              className="form-check-input"
                              checked={noneSelected}
                              onChange={e => {
                                if (e.target.checked) {
                                  setNoneSelected(true)
                                  setAllSelected(false)
                                } else {
                                  setNoneSelected(false)
                                }
                              }}
                            />
                          </div>

                          <div className="form-check form-switch form-switch-lg mx-4">
                            <label className="m-0">Status</label>
                            <input
                              type="checkbox"
                              className="form-check-input"
                              checked={serviceStatus}
                              onChange={e => {
                                setServiceStatus(e.target.checked)
                              }}
                            />
                          </div>
                        </div>

                        <div>
                          <Button
                            color="info"
                            className="mx-4"
                            onClick={() => {
                              setIsEditingService(true)
                              setIsServiceEditModalOpen(true)
                            }}
                          >
                            <i className="mdi mdi-lead-pencil me-1"></i>
                            Edit Service
                          </Button>

                          <Button
                            color="success"
                            className="mr-6"
                            onClick={saveServiceAreas}
                          >
                            Update
                          </Button>
                        </div>
                      </div>
                    )}

                    {activeTab && selectedAreas ? (
                      <Row>
                        {areas.map(area => {
                          return (
                            <Col lg="3" key={area._id}>
                              <div className="form-check form-check-success mb-3">
                                <input
                                  type="checkbox"
                                  className="form-check-input"
                                  checked={
                                    selectedAreas.includes(area) || allSelected
                                  }
                                  onChange={e => {
                                    if (e.target.checked) {
                                      selectedAreas.includes(area) ||
                                        setSelectedAreas([
                                          ...selectedAreas,
                                          area,
                                        ])
                                      setNoneSelected(false)
                                    } else {
                                      setSelectedAreas(areas => {
                                        return areas.filter(
                                          area_itr => area_itr !== area
                                        )
                                      })
                                      setAllSelected(false)
                                    }
                                  }}
                                  disabled={!(userData?.permissions?.allPermissions == true || userData?.permissions?.serviceManagement?.update == true)}
                                />
                                <label className="form-check-label">
                                  {area.name}
                                </label>
                              </div>
                            </Col>
                          )
                        })}
                      </Row>
                    ) : null}
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </div>
        </div>

        <ServiceEditModal
          isOpen={isServiceEditModalOpen}
          toggle={() => setIsServiceEditModalOpen(!isServiceEditModalOpen)}
          onAction={fields => {
            const formData = new FormData()
            formData.append("nameEn", fields.nameEn)
            formData.append("nameAr", fields.nameAr)
            formData.append("serviceCode", fields.serviceCode)
            formData.append("serviceTime", fields.serviceTime)
            formData.append("image", fields.image)
            formData.append("status", fields.status)
            dispatch(updateService(formData))
          }}
          service={services.find(service => service.serviceCode === activeTab)}
          isEditing={isEditingService}
        />
      </React.Fragment>
    )
  } else {
    return <Pages404 />
  }
}

export default ServiceManagement
