import { call, put, takeEvery } from "redux-saga/effects"
import { GET_SLIDER, ADD_NEW_SLIDER, UPDATE_SLIDER, DELETE_SLIDER } from "./actionTypes"
import { getSliderSuccess, getSliderFail, addNewSliderSuccess, addNewSliderFail, updateSliderSuccess, updateSliderFail, deleteSliderSuccess, deleteSliderFail } from "./actions"
import { getSlider, addNewSlider, updateSlider, deleteSlider } from "helpers/fakebackend_helper"
import toastr from "toastr";
import "toastr/build/toastr.min.css";

function* fetchSliderList({ payload: data }) {
  try {
    const response = yield call(getSlider, data)
    yield put(getSliderSuccess(response))
  } catch (error) {
    if (error == 401) {
      data.payload.history.push("/login")
    } else {
      yield put(getSliderFail(error))
    }
  }
}

function* onAddNewSlider({ payload: data }) {
  try {
    const response = yield call(addNewSlider, data)
    if (response?.statusCode == "200") {
      toastr.success(`Slider added Successfully`, `Sliders`);
      yield put(addNewSliderSuccess(response?.record))
    } else {
      toastr.error(`Slider add failed`, `Slider`);
    }
  } catch (error) {
    toastr.error(`Slider add failed`, `Slider`);
    yield put(addNewSliderFail(error))
  }
}

function* onUpdateSlider({ payload: data }) {
  var object = {};
  data.forEach((value, key) => object[key] = value);
  try {
    // console.log("updated data" , data.values);
    const response = yield call(updateSlider, data)
    if (response?.statusCode == "200") {
      toastr.success(`Slider Updated Successfully`, `Sliders`);
      yield put(updateSliderSuccess(object))
    } else {
      toastr.error(`Slider Updated Failed`, `Sliders`);
    }
  } catch (error) {
    toastr.error(`Slider Updated Failed`, `Sliders`);
    yield put(updateSliderFail(error))
  }
}

function* onDeleteSlider({ payload: data }) {
  try {
    const response = yield call(deleteSlider, data)
    if (response?.statusCode == "200") {
      toastr.success(`Slider Deleted Successfully`, `Sliders`);
      yield put(deleteSliderSuccess(data))
    } else {
      toastr.error(`Slider Delete Failed`, `Sliders`);
    }
  } catch (error) {
    console.log("error", error)
    toastr.error(`Slider Delete Failed`, `Sliders`);
    yield put(deleteSliderFail(error))
  }
}

function* SliderSaga() {
  yield takeEvery(GET_SLIDER, fetchSliderList)
  yield takeEvery(ADD_NEW_SLIDER, onAddNewSlider)
  yield takeEvery(UPDATE_SLIDER, onUpdateSlider)
  yield takeEvery(DELETE_SLIDER, onDeleteSlider)
}

export default SliderSaga