import React, { useEffect, useState, useRef } from "react";
import PropTypes from "prop-types"
import { Row, Col} from "reactstrap";
import DataTable from "react-data-table-component";


const Notifications = props => {

  const [notificationData, setNotificationData] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [online, setOnline] = useState(true);
  const [offline, setOffline] = useState(true);
  
  let TextColumns = [
    /*{
      name: "Notification Type",
      sortable: true,
      selector: row => {
        let notificationType = row.notificationType?.charAt(0)?.toUpperCase() + row.notificationType?.slice(1);

        const pieces = notificationType?.match(/[A-Z][a-z]+/g)
        if(pieces?.length){
          return pieces.join(" ")
        }
        else{
          return row.notificationType;
        }
      },
      wrap: true,
    },*/
    {
      name: "Description",
      sortable: true,
      selector: row => row.displayText,
      wrap: true,
    },
    {
      name: "Category",
      sortable: true,
      selector: row => row.category,
      wrap: true,
    },
    {
      name: "Title (English)",
      sortable: true,
      selector: row => row.enTitle,
      wrap: true,
    },
    {
      name: "Body (English)",
      sortable: true,
      selector: row => row.enBody,
      wrap: true,
    },
    {
      name: "Title (Arabic)",
      sortable: true,
      selector: row => row.arTitle,
      wrap: true,
    },
    {
      name: "Body (Arabic)",
      sortable: true,
      selector: row => row.arBody,
      wrap: true,
    },
    // {
    //   name: "Consumer",
    //   sortable: true,
    //   selector: row => row.consumer,
    //   wrap: true,
    // } 
    
  ];

  if (props.userData?.permissions?.allPermissions == true || props.userData?.permissions?.clothes?.update == true) {
    TextColumns.push({
      name: "View",
      center: true,
      selector: row => {
        return <>
          <button className="btn btn-primary btn-sm" onClick={() => props.onViewClick(row)}>
            View
          </button>
          {/* <a onClick={() => showEditLaundry(row._id)} >Edit</a>
             &nbsp; &nbsp;
             <a onClick={() => handleDeleteLaundry(row._id)} >Delete</a> */}
          {/* <a href="#" onClick={() => showPerms(row._id)} >Permissions</a> */}
        </>
      }
    })
  }


  useEffect(() => {  
      let filterBySearch = filterTexts(searchText);
      setNotificationData(filterBySearch)
  }, [props.notificationData, online, offline])


  const handleSearch = (val) => {
    val = val?.toLowerCase()
    setSearchText(val);
    let filterBySearch = filterTexts(val);
    if (!val) {
      setNotificationData(props.notificationData)
    } else {
      setNotificationData(filterBySearch)
    }
  }

  const filterTexts = (q) => {  
    return props.notificationData.filter((item) => {
      let result = true; 
      if(q != ""){
        result = result && (item?.notificationType?.toLowerCase()?.includes(q) 
          || item?.enTitle?.toLowerCase()?.includes(q)
          || item?.arTitle?.toLowerCase()?.includes(q)
          || item?.enBody?.toLowerCase()?.includes(q)
          || item?.arBody?.toLowerCase()?.includes(q)
        ) 
      }
      result = result && ((online== true && item?.category == "online") || (offline== true && item?.category == "offline"));
      return result;
    })
  }


  return (
    <React.Fragment>
      <Row className="mb-2">
        <Col sm="4">
          <div className="me-2 mb-2 d-inline-block">
            <div className="position-relative">
              <input
                id="search-bar-10"
                type="text"
                aria-labelledby="search-bar-10-label"
                className="form-control "
                placeholder="Search"
                onChange={(e) => handleSearch(e.target.value)}
              />
            </div>
          </div>
        </Col>
        <Col sm="8">
          <div className="d-flex align-items-center">
            <div className="d-flex align-items-center mx-3">
              <span className={`me-2 ${online ? "text-success font-weight-semibold" : ""}`}>Show Online</span>
              <div className="form-check form-switch form-switch-lg">
                <input
                  type="checkbox"
                  className="form-check-input form-slider-color-green"
                  defaultChecked={online}
                  onChange={(e) => { setOnline(!online) }}
                />
              </div>
            </div>
            <div className="d-flex align-items-center">
              <span className={`me-2 ${offline ? "text-success font-weight-semibold" : ""}`}>Show Offline</span>
              <div className="form-check form-switch form-switch-lg">
                <input
                  type="checkbox"
                  className="form-check-input form-slider-color-green"
                  defaultChecked={offline}
                  onChange={(e) => { setOffline(!offline) }}
                />
              </div>
            </div>
          </div>
        </Col>
      </Row>
      <Row>
        <DataTable
          columns={TextColumns}
          data={notificationData}
          pagination
        />
      </Row>
    </React.Fragment>
  );

}

Notifications.propTypes = {
  notificationData: PropTypes.array,
  userData: PropTypes.object,
  onViewClick : PropTypes.func
};

export default Notifications;
