import PropTypes from "prop-types"
import React, { useEffect, useState, useRef, useContext  } from "react"
import ReactApexChart from "react-apexcharts"
import MetaTags from "react-meta-tags";
// import Doughnut from '../AllCharts/echart/doughnutchart';
import DonutChart from "../AllCharts/apex/dountchart"
import BarChart from "../AllCharts/apex/barchart";

import { ThreeDots } from 'react-loader-spinner'

import Maps  from "./Map";


import Datamap from  "./datamap";

import {
  Container,
  Row,
  Col,
  Button,
  Card,
  CardBody,
  Input,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Media,
  Table,
  CardTitle,
  Badge,
  CardHeader
} from "reactstrap"

import {
  BarChart as ReBarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  Label
} from "recharts";

//import action
import { appRegistartions, getUniqueCustomerOrders, getMonthlyKleenOrders, 
  getMonthlyLaundryOrders, getLaundrylist, getArealist, getMonthlyKleenGMV, getMonthlyLaundryGMV,
  getDailyLaundryData, getDailyOnlineData, getAllOrdersSearch, 
  getMonthlyKleenAvgBasket, 
  getMonthlyKleenAvgSubscription,
  getMonthlySubscriber, 
  getMonthlyLaundryAvgBasket, 
  getMonthlySegment, 
  getSalesGrowth, 
  getSalesGrowthLaundry,
  allLaundryRevenue
} from "../../store/actions"
import { useDispatch, useSelector } from "react-redux"
import {searchUsers } from "../../store/actions"

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"

//i18n
import { withTranslation } from "react-i18next"

//redux
// import { useSelector, useDispatch } from "react-redux"
import { values } from "lodash";
import Zoom from "./datamaps.zoom"
import Skeleton from "react-loading-skeleton";
import DatePicker from "react-datepicker";
import useScript from 'react-script-hook';
import moment from "moment-timezone";
import SocketContext from "common/socket";
import { GoogleApiWrapper, Map} from "google-maps-react";
import { mapLibraries } from "constants/app";
import WelcomePage from "pages/Utility/welcome-page";





const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

const AnalyticsHome = props => {
  const [loadingAppRegistrations, SetLoadingAppRegistrations] = useState(false);
  const [loadingUniqueOrders, SetLoadingUniqueOrders] = useState(false);
  const [loadingKleenOrders, SetLoadingKleenOrders] = useState(false);
  const [loadingLaundryOrders, SetLoadingLaundryOrders] = useState(false);
  const [orderLaundry, setOrderLaundry] = useState(0);

  const [loadingKleenGMV, SetLoadingKleenGMV] = useState(false);
  const [loadingLaundryGMV, SetLoadingLaundryGMV] = useState(false);

  const [loadingKleenAvgBasket, SetLoadingKleenAvgBasket] = useState(false);
  const [loadingKleenAvgSubscription, SetLoadingKleenAvgSubscription] = useState(false);
  const [loadingKleenSubscriber, SetLoadingKleenSubscriber] = useState(false);
  const [loadingLaundryAvgBasket, SetLoadingLaundryAvgBasket] = useState(false);

  const [loadingSalesGrowth, SetLoadingSalesGrowth] = useState(false);
  const [loadingSalesGrowthLaundry, SetLoadingSalesGrowthLaundry] = useState(false);

  const [markers, setMarkers] = useState([]);
  const [offlineMarkers, SetOfflineMarkers] = useState([]);
  const [mapScale, SetMapScale] = useState(2000);
  const [dateValues, SetDateValues] = useState(null);
  const [userData, setUserData] = useState({});
  const [loadingSegment, SetLoadingSegment] = useState({});
  const [segmentData, SetSegmentData] = useState({options:{},series:[]});
  const [salesGrowthData, SetSalesGrowthData] = useState({options:{},series:[]});
  const [salesGrowthLaundryData, SetSalesGrowthLaundryData] = useState({options:{},series:[]});
  const [avgBasketData, SetAvgBasketData] = useState({options:{},series:[]});
  const [avgSubscriptionData, SetAvgSubscriptionData] = useState({options:{},series:[]});
  const [areaSubscriberData, SetareaSubscriberData] = useState({options:{},series:[]});
  const [laundryBasketData, SetLaundryBasketData] = useState({options:{},series:[]});
  const [monthlyOrdersData, SetMonthlyOrdersData] = useState({options:{},series:[]});
  const [laundryOrdersData, SetLaundryOrdersData] = useState({options:{},series:[]});
  
  const [monthlyGMVData, SetMonthlyGMVData] = useState({options:{},series:[]});
  const [laundryGMVData, SetLaundryGMVData] = useState({options:{},series:[]});
  const [pageLoading, setPageLoading] =  useState(true);  
  const [currentMonth, setCurrentMonth] = useState(new Date());
  
  const[salesGrowthLaundryId, setSalesGrowthLaundryId] =  useState("");
  const[googleLoaded, setGoogleLoaded] = useState(false);
  const[updatedLaundry, setUpdatedLaundry] = useState({_id:0});
  


  const mapRef = useRef(null);
  const socket = useContext(SocketContext);
  const myAudio = new Audio(API_BASE_URL + "/sounds/invoice.mp3");




  const { appRegistrationsData, uniqueCustomerOrders, monthlyKleenOrders, 
    monthlyLaundryOrders, laundrylist,arealist,
     monthlyKleenGMV, monthlyLaundryGMV,
    monthlyKleenAvgBasket,monthlyKleenAvgSubscription,monthlyKleenSubscriber, monthlyLaundryAvgBasket,
    dailyLaundryData, dailyOnlineData, Allorders, users, 
    monthlySegment, salesGrowth, 
    salesGrowthLaundry, allLaundryGMV
  } = useSelector(state => ({
    appRegistrationsData: state.Dashboard.appRegistrationsData,
    uniqueCustomerOrders: state.Dashboard.uniqueCustomerOrders,
    monthlyKleenOrders: state.Dashboard.monthlyKleenOrders,
    monthlyLaundryOrders: state.Dashboard.monthlyLaundryOrders,
    laundrylist :state.ecommerce.laudrylist,
    arealist: state.ecommerce.arealist,
    monthlyKleenGMV: state.Dashboard.monthlyKleenGMV,
    monthlyLaundryGMV: state.Dashboard.monthlyLaundryGMV,
    monthlyKleenAvgBasket: state.Dashboard.monthlyKleenAvgBasket,
    monthlyKleenAvgSubscription: state.Dashboard.monthlyKleenAvgSubscription,
    monthlyKleenSubscriber: state.Dashboard.monthlyKleenSubscriber,
    monthlyLaundryAvgBasket: state.Dashboard.monthlyLaundryAvgBasket,
    dailyLaundryData: state.Dashboard.dailyLaundryData,
    dailyOnlineData: state.Dashboard.dailyOnlineData,
    Allorders: state.Orders.Allorders,
    users : state.Users.users,
    monthlySegment: state.Dashboard.monthlySegment, 
    salesGrowth: state.Dashboard.salesGrowth,
    salesGrowthLaundry: state.Dashboard.salesGrowthLaundry,
    allLaundryGMV: state.Laundries.allLaundryGMV
  }))
  var node = useRef();
  const keyField = "id";
  
  const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

  const options = null;
  

  const loaderColor = "#0097CE";
 const dispatch = useDispatch();

// const [loading, scriptError] = useScript({
//   src : "https://maps.googleapis.com/maps/api/js?key=AIzaSyAvZvBwMKrllZNCXdikzvCDOzu8rwqv-0c&v=weekly&language=en&region=us&libraries=drawing",
//   checkForExisting: true,
//   onload: () => setGoogleLoaded(true),
// });



useEffect(() => {
  if(users.length> 0){
    setUserData(users[0]);
    setPageLoading(false);
  }
}, [users])


 useEffect(() => {
  SetLoadingAppRegistrations(false);
  }, [appRegistrationsData])

  useEffect(() => {
    SetLoadingUniqueOrders(false);
    }, [uniqueCustomerOrders])

  useEffect(() => {
    SetLoadingKleenOrders(false);
  }, [monthlyKleenOrders])

  useEffect(() => {
    
    let _segmentData={};
    _segmentData.options = {
      chart: { toolbar: { show: false } },
      colors: ["#6C82EA", "#0091FF", "#FA6400", "#6D7278", "#B620E0"],
      dataLabels: { enabled: false },
      // stroke: { curve: "straight" },
      // title: { text: "Page Statistics", align: "left" },
      // markers: { size: 0, hover: { sizeOffset: 6 } },
      xaxis: {
        categories: monthlySegment.months.map(item => {
          return months[item.month-1]
        }).reverse()
      },
      // tooltip: {
      //   y: [
      //     {
      //       title: {
      //         formatter: function (e) {
      //           return e + " (mins)"
      //         },
      //       },
      //     },
      //     {
      //       title: {
      //         formatter: function (e) {
      //           return e + " per session"
      //         },
      //       },
      //     },
      //     {
      //       title: {
      //         formatter: function (e) {
      //           return e
      //         },
      //       },
      //     },
      //   ],
      // },
      grid: { borderColor: "#f1f1f1" },
    };
    _segmentData.series = [{
      name: "0-50",
      data: monthlySegment.segment0_50?monthlySegment.segment0_50.reverse():[]
    },
    {
      name: "50-100",
      data: monthlySegment.segment51_100?monthlySegment.segment51_100.reverse():[]
    },
    {
      name: "100-200",
      data: monthlySegment.segment101_200?monthlySegment.segment101_200.reverse():[]
    },
    {
      name: "200-300",
      data: monthlySegment.segment201_300?monthlySegment.segment201_300.reverse():[]
    },
    {
      name: ">300",
      data: monthlySegment.segment301plus?monthlySegment.segment301plus.reverse():[]
    }
    ];
    SetSegmentData(_segmentData);
    SetLoadingSegment(false);
  }, [monthlySegment])


  useEffect(() => {  
    let _salesGrowthData={};
    _salesGrowthData.options = {
      chart: { toolbar: { show: false } },
      colors: ["#34c38f", "#f8b425", "#556ee6"],
      dataLabels: { enabled: false },
      xaxis: {
        categories: salesGrowth.days
      },
      grid: { borderColor: "#f1f1f1" },
    };
    _salesGrowthData.series = [{
      name: "Online",
      data: salesGrowth.online?salesGrowth.online:[]
    },
    {
      name: "Offline",
      data: salesGrowth.offline?salesGrowth.offline:[]
    },
    {
      name: "All",
      data: salesGrowth.all?salesGrowth.all:[]
    }];
    SetSalesGrowthData(_salesGrowthData);
    SetLoadingSalesGrowth(false);
  }, [salesGrowth])


  useEffect(() => {  
    let _salesGrowthData={};
    _salesGrowthData.options = {
      chart: { toolbar: { show: false } },
      colors: ["#34c38f", "#f8b425", "#556ee6"],
      dataLabels: { enabled: false },
      xaxis: {
        categories: salesGrowthLaundry.days
      },
      grid: { borderColor: "#f1f1f1" },
    };
    _salesGrowthData.series = [{
      name: "Online",
      data: salesGrowthLaundry.online?salesGrowthLaundry.online:[]
    },
    {
      name: "Offline",
      data: salesGrowthLaundry.offline?salesGrowthLaundry.offline:[]
    },
    {
      name: "All",
      data: salesGrowthLaundry.all?salesGrowthLaundry.all:[]
    }];
    SetSalesGrowthLaundryData(_salesGrowthData);
    SetLoadingSalesGrowthLaundry(false);
  }, [salesGrowthLaundry])


  useEffect(() => {
    let _avgBasketData = {
      options : {
        chart: {
          toolbar: {
            show: false,
          },
        },
       
        dataLabels: {
          enabled: false,
        },
        yaxis: {
          title: {
            text: "GMV (SR)",
          },
        },
        stroke: {
          show: true,
          width: 2,
          colors: ["transparent"],
        },
    
        colors: ["#34c38f", "#f8b425", "#556ee6"],
        grid: {
          borderColor: "#f1f1f1",
        },
        xaxis: {
          categories: monthlyKleenAvgBasket.months?.map(item => months[item.month-1]).reverse()
        },
      },
      series : [{
        name: "Online",
        data: monthlyKleenAvgBasket.online?monthlyKleenAvgBasket.online?.reverse():[]
      },
      {
        name: "Offline",
        data: monthlyKleenAvgBasket.offline?monthlyKleenAvgBasket.offline?.reverse():[]
      },
      {
        name: "All",
        data: monthlyKleenAvgBasket.all?monthlyKleenAvgBasket.all?.reverse():[]
      }]
    };

    SetAvgBasketData(_avgBasketData);
    SetLoadingKleenAvgBasket(false);
     
  }, [monthlyKleenAvgBasket]);


  useEffect(() => {
    let _avgSubscriptionData = {
      options : {
        chart: {
          toolbar: {
            show: false,
          },
        },
       
        dataLabels: {
          enabled: false,
        },
        yaxis: {
          title: {
            text: "Monthly Subscription Amount",
          },
        },
        stroke: {
          show: true,
          width: 2,
          colors: ["transparent"],
        },
    
        colors: ["#34c38f"],
        grid: {
          borderColor: "#f1f1f1"
        },
        xaxis: {
          categories: monthlyKleenAvgSubscription.months?.map(item => months[item.month-1]).reverse()
        },
      },
      series : [{
        name: "Subscription",
        data: monthlyKleenAvgSubscription.series?monthlyKleenAvgSubscription.series?.reverse():[]
      }]
      
      
    };

    SetAvgSubscriptionData(_avgSubscriptionData);
    SetLoadingKleenAvgSubscription(false);
     
  }, [monthlyKleenAvgSubscription]);


  
  useEffect(() => {
    let _subscriberData = {
      options : {
        chart: {
          toolbar: {
            show: false,
          },
        },
       
        dataLabels: {
          enabled: false,
        },
        yaxis: {
          title: {
            text: "Monthly Total Subscriber",
          },
        },
        stroke: {
          show: true,
          width: 2,
          colors: ["transparent"],
        },
    
        colors: ["#34c38f"],
        grid: {
          borderColor: "#f1f1f1"
        },
        xaxis: {
         categories: monthlyKleenSubscriber?.months?.map(item => months[item.month-1]).reverse()
        },
      },
      series : [{
        name: "Subscriber",
        data: monthlyKleenSubscriber?.series?monthlyKleenSubscriber.series?.reverse():[]
      }]
      
      
    };

    SetareaSubscriberData(_subscriberData);
    SetLoadingKleenSubscriber(false);
     
  }, [monthlyKleenSubscriber]);

  useEffect(() => {

    let data = {
      options : {
        chart: {
          toolbar: {
            show: false,
          },
        },
       
        dataLabels: {
          enabled: false,
        },
        yaxis: {
          title: {
            text: "GMV",
          },
        },
        stroke: {
          show: true,
          width: 2,
          colors: ["transparent"],
        },
    
        colors: ["#34c38f", "#f8b425", "#556ee6"],
        grid: {
          borderColor: "#f1f1f1",
        },
        xaxis: {
          categories: monthlyLaundryAvgBasket.months?.map(item => {
            return months[item.month-1]
          }).reverse()
        },
      },
      series:[{
        name: "Online",
        data: monthlyLaundryAvgBasket.online?monthlyLaundryAvgBasket.online?.reverse():[]
      },
      {
        name: "Offline",
        data: monthlyLaundryAvgBasket.offline?monthlyLaundryAvgBasket.offline?.reverse():[]
      },
      {
        name: "All",
        data: monthlyLaundryAvgBasket.all?monthlyLaundryAvgBasket.all?.reverse():[]
      }]
    };
    SetLaundryBasketData(data);
    SetLoadingLaundryAvgBasket(false);
  }, [monthlyLaundryAvgBasket]);


  useEffect(() => {
    	let data = {
        options : {
          chart: {
            toolbar: {
              show: false,
            },
          },
         
          dataLabels: {
            enabled: false,
          },
          yaxis: {
            title: {
              text: "GMV (SR)",
            },
          },
          stroke: {
            show: true,
            width: 2,
            colors: ["transparent"],
          },
      
          colors: ["#34c38f", "#f8b425", "#556ee6"],
          grid: {
            borderColor: "#f1f1f1",
          },
          xaxis: {
            categories: monthlyKleenGMV.map((item) => months[item.month-1] + ' ' + item.year ).reverse()
          },
        },
        series : [{
          name: "Online",
          data: monthlyKleenGMV.map((item) => item.online ).reverse()
        },
        {
          name: "Offline",
          data: monthlyKleenGMV.map((item) => item.offline ).reverse()
        },
        {
          name: "All",
          data: monthlyKleenGMV.map((item) => (item.offline+item.online) ).reverse()
        }]
      }

      SetMonthlyGMVData(data);
      SetLoadingKleenGMV(false);

  }, [monthlyKleenGMV]);


  useEffect(() => {
    let data = {
      options: {
        chart: {
          toolbar: {
            show: false,
          },
        },
       
        dataLabels: {
          enabled: false,
        },
        yaxis: {
          title: {
            text: "GMV",
          },
        },
        stroke: {
          show: true,
          width: 2,
          colors: ["transparent"],
        },
    
        colors: ["#34c38f", "#f8b425", "#556ee6"],
        grid: {
          borderColor: "#f1f1f1",
        },
        xaxis: {
          categories: monthlyLaundryGMV.map((item) => months[item.month-1] + ' ' + item.year ).reverse()
        },
      },
      series : [{
        name: "Online",
        data: monthlyLaundryGMV.map((item) => item.online ).reverse()
      },
      {
        name: "Offline",
        data: monthlyLaundryGMV.map((item) => item.offline ).reverse()
      },
      {
        name: "All",
        data: monthlyLaundryGMV.map((item) => (item.offline+item.online) ).reverse()
      }]
    }

    SetLaundryGMVData(data);
    SetLoadingLaundryGMV(false);
  }, [monthlyLaundryGMV]);

  useEffect(() => {
    let data = {
      options : {
        chart: {
          toolbar: {
            show: false,
          },
        },
       
        dataLabels: {
          enabled: false,
        },
        yaxis: {
          title: {
            text: "No. of Orders",
          },
        },
        stroke: {
          show: true,
          width: 2,
          colors: ["transparent"],
        },
    
        colors: ["#34c38f", "#f8b425", "#556ee6"],
        grid: {
          borderColor: "#f1f1f1",
        },
        xaxis: {
          categories: monthlyLaundryOrders.map((item) => months[item.month-1] + ' ' + item.year ).reverse()
        },
      },
      series : [{
        name: "Online",
        data: monthlyLaundryOrders.map((item) => item.online ).reverse()
      },
      {
        name: "Offline",
        data: monthlyLaundryOrders.map((item) => item.offline ).reverse()
      },
      {
        name: "All",
        data: monthlyLaundryOrders.map((item) => (item.offline+item.online) ).reverse()
      }]
    };
    SetLaundryOrdersData(data);
    SetLoadingLaundryOrders(false);

  }, [monthlyLaundryOrders])


  useEffect(() => {
    let data = {
      options : {
        chart: {
          toolbar: {
            show: false,
          },
        },
       
        dataLabels: {
          enabled: false,
        },
        yaxis: {
          title: {
            text: "No. of Orders",
          },
        },
        stroke: {
          show: true,
          width: 2,
          colors: ["transparent"],
        },
    
        colors: ["#34c38f", "#f8b425", "#556ee6"],
        grid: {
          borderColor: "#f1f1f1",
        },
        xaxis: {
          categories: monthlyKleenOrders.map((item) => months[item.month-1] + ' ' + item.year ).reverse()
        },
      }, 
      series : [{
        name: "Online",
        data: monthlyKleenOrders.map((item) => item.online ).reverse()
      },
      {
        name: "Offline",
        data: monthlyKleenOrders.map((item) => item.offline ).reverse()
      },
      {
        name: "All",
        data: monthlyKleenOrders.map((item) => (item.offline+item.online) ).reverse()
      }]
    };

    SetMonthlyOrdersData(data);

  }, [monthlyKleenOrders]);

  
    
  var data = JSON.parse(localStorage.getItem("authUser"));
  const [fill, setFill] = useState({});
  const { permission } = useSelector(state => ({
    permission: state.permission.permissions
  }))

  const loadLaundryMap = () => {
    const [startDate, endDate] = [moment().subtract(1, 'months').format('YYYY-MM-DD'), moment().format('YYYY-MM-DD')];

    dispatch(allLaundryRevenue({
      start : startDate,
      end: endDate
    }))
  }

  useEffect(() => {
    if(window?.google){
      setGoogleLoaded(true);
    }

    dispatch(searchUsers({webPermissions: true}))

    let today =  getFormattedDate(new Date());
    SetDateValues({start: today});

    loadLaundryMap();
    
    

    SetLoadingKleenGMV(true);
    dispatch(getMonthlyKleenGMV({}));


    SetLoadingKleenOrders(true);
    dispatch(getMonthlyKleenOrders({}));

    
    dispatch(getLaundrylist({status : true}));
    dispatch(getArealist({status : true}));

    SetLoadingAppRegistrations(true);
    dispatch(appRegistartions({}));

    SetLoadingUniqueOrders(true);
    dispatch(getUniqueCustomerOrders({}));

    SetLoadingKleenAvgBasket(true);
    dispatch(getMonthlyKleenAvgBasket({}));

    SetLoadingKleenAvgSubscription(true);
    dispatch(getMonthlyKleenAvgSubscription());

    SetLoadingKleenSubscriber(true);
    dispatch(getMonthlySubscriber());
    

    SetLoadingSegment(true);
    dispatch(getMonthlySegment());
    
    
    myAudio.preload = "auto";
    myAudio.load();

    socket.on("__order_updated__", data => {
      myAudio.play();
      console.log("Order Data Updated", data);
      if(data.status == "washing"){
        const laundry = data.laundry;
        
        setUpdatedLaundry({
          _id: laundry
        });
        
        
      }
    });


    return  () => {
      socket.off('__order_updated__');
    }


    //SetOnlineMarkers([{name: 'foo', radius: 10, latitude: 25.23058916349024, longitude: 43.46098009253602}]);
    //SetOfflineMarkers([{name: 'foo1', radius: 10, latitude: 24.832351520010366, longitude: 43.45098009253602}]);
    

  }, [])

  useEffect(() => {
    if(updatedLaundry){
      loadLaundryMap();
    }
  }, [updatedLaundry])

 useEffect(() => {
  let t = setTimeout(() => {
    setUpdatedLaundry("");
  }, 10000)

  // return () => {
  //   clearInterval(t);
  // }
  
 }, [allLaundryGMV]);

  useEffect(() => {
    setTimeout(() => {
      if(currentMonth){
        const month = currentMonth.getMonth() + 1;
        const year = currentMonth.getFullYear();
        if(month && year){
          SetLoadingSalesGrowth(true);
          dispatch(getSalesGrowth({month : month, year : year}));

          if(salesGrowthLaundryId){
            SetLoadingSalesGrowthLaundry(true);
            dispatch(getSalesGrowthLaundry({month : month , year : year , laundry : salesGrowthLaundryId}));
          }
        }
      }
    }, 50);

  }, [currentMonth])

  const onOrderLaundryChange = (e) => {
    const laundryId = e.target.value;
    setOrderLaundry(laundryId);
    SetLoadingLaundryOrders(true);
    if(laundryId != undefined){
      dispatch(getMonthlyLaundryOrders(laundryId));
    }
  };

  const onGMVLaundryChange = (e) => {
    const laundryId = e.target.value;
    SetLoadingLaundryGMV(true);
    if(laundryId != undefined){
      dispatch(getMonthlyLaundryGMV(laundryId));
    }
  };

  const onAvgLaundryChange = (e) => {
    const laundryId = e.target.value;
    SetLoadingLaundryAvgBasket(true);
    if(laundryId != undefined){
      dispatch(getMonthlyLaundryAvgBasket(laundryId));
    }
  };

  const onSubMonthlyChange = (e) => {
    const areaId = e.target.value;
    SetLoadingKleenAvgSubscription(true);
    if(areaId != undefined){
      dispatch(getMonthlyKleenAvgSubscription(areaId));
    }
  };

  const onareaChangeSubscriber = (e) => {
    const areaId = e.target.value;
    SetLoadingKleenSubscriber(true);
    if(areaId != undefined){
      dispatch(getMonthlySubscriber(areaId));
    }
  };

  const onSalesGrowthLaundryChange = (e) => {
    const laundryId = e.target.value;
    
    setSalesGrowthLaundryId(laundryId);
    SetLoadingSalesGrowthLaundry(true);
    if(laundryId != undefined){
      let _currentMonth = currentMonth; 
      if(!_currentMonth) currentMonth = new Date();
      
      const month = currentMonth.getMonth();
      const year = currentMonth.getFullYear();

      dispatch(getSalesGrowthLaundry({month : month + 1, year : year , laundry : laundryId}));
    }
  };

  const dailyOnlineReload = () => {
    SetLoadingDailyOnline(true);
    dispatch(getDailyOnlineData({}));
  }

  const dailyLaundryReload = () => {
    SetLoadingDailyLaundry(true);
    dispatch(getDailyLaundryData({}));
  }
  
  const onMapLoad = (map) =>{
    mapRef.current = map;
  }

  

  useEffect(() => {
    const filterProduct = permission?.filter((item) => item?.role === data?.role)
    setFill(filterProduct[0])
  }, [permission])

 

  useEffect(() => {
    let _laundries = [];
    laundrylist?.forEach(laundry => {
      _laundries.push({
        name : laundry.nameEn,
        radius: 10, 
        latitude: parseFloat(laundry?.latitude), 
        longitude: parseFloat(laundry?.longitude), 
      })
    });

   setMarkers(_laundries);

  }, [laundrylist])

  const zoomIn = () => {
    let currentZoom = mapScale;
    currentZoom+= 100;
    SetMapScale(currentZoom);
  }

  const zoomout = () => {
    let currentZoom = mapScale;
    if(currentZoom > 1100){
      currentZoom-= 100;
      SetMapScale(currentZoom);
    }
  }

  const onSubmit = async (values) => {
    SetDateValues({
      start : values.start
    });
  }

  const filterByDate = (e) => {
    loadDailyData(dateValues?.start);
  };

  const getFormattedDate = (dt) => {
    const separator = "-";
    let newDate = dt;
    let date = newDate.getDate();
    let month = newDate.getMonth() + 1;
    let year = newDate.getFullYear();

    return  `${year}${separator}${month<10?`0${month}`:`${month}`}${separator}${date}`
  };

  const changeDate = (e) => {
    SetDateValues({start : e.target.value});
  }

  const loadDailyData = (dt) => {
    if(dt != null || dt != undefined){
      SetLoadingDailyOnline(true);
      dispatch(getDailyOnlineData({date: dt}));

      SetLoadingDailyLaundry(true);
      dispatch(getDailyLaundryData({date: dt}));
    }
  }

 

  
  if(userData?.permissions?.allPermissions==true || userData?.permissions?.panelHome?.read==true){
    return(
      <div className="page-content">
      <MetaTags>
        <title>Analytics | Kleen Admin Panel</title>
      </MetaTags>
      <Container fluid>
        {/* Render Breadcrumb */}
        <Breadcrumbs
          title={props.t("Home")}
          breadcrumbItem={props.t("Analytics")}
        />

      <Row>
        <Col>
          <Map 
            google={google}
          />
        </Col>
      </Row>

          <Row>
            <Col lg="12">
              <Card>
                <CardHeader>
                  <h4>Real Time Orders</h4>
                </CardHeader>
                <CardBody style={{padding:0}}>
                  <Col lg="12">
                    <Row>
                    
                    {
                      allLaundryGMV?.length && googleLoaded ? (
                          (<Maps
                         loadingElement={<div style={{ height: `100%` }} />}
                         containerElement={<div style={{ height: `700px` }} />}
                         mapElement={<div style={{ height: `100%` }} />}
                         center={{ lat:22.7857718694954, lng: 45.397431725357045 }}
                         zoom={6}
                         places={allLaundryGMV}
                         updatedLaundry={updatedLaundry?._id}
                       />

                      )) : null
                    }
                    </Row>
                  </Col>
                </CardBody>
              </Card>
            </Col>
          </Row>


  {/* <Row>
      <Col lg="12">
      <Card>
    <CardHeader>
      <h4>Real Time Orders</h4>
      </CardHeader>
      <CardBody>
        <Col lg="12">
          <Row>
            <Col>
            <button className="ml-1 zoom-button btn btn-sm btn-primary"  onClick={zoomout} data-zoom="out">-</button> &nbsp;
            <button className="ml-1 zoom-button btn btn-sm btn-primary" onClick={zoomIn} data-zoom="in">+</button> 
            </Col>
          </Row>
      

        <div >
        <Datamap 
          scope="sau"
          height="700"
          popupOnHover={true}
          markers={markers}
          done={(datamap) => {new Zoom({
            $container : datamap.options.element,
            datamap: datamap
          })}}
          geographyConfig={
            {
              popupTemplate: (geography, data) => {   
                return '<div class="hoverinfo"><strong>' + geography.properties.name + '</strong></div>';
              }
            }
          }
          setProjection = {(element) => {
            var projection = d3.geo.mercator()
                .center([41.4577,26.8166]) // SAU
                //.center([73.8945, 18.5532]) // IND
                .scale(mapScale);
            var path = d3.geo.path().projection(projection);
            return { path: path, projection: projection };
        }}
        />
        </div>
        </Col>
        </CardBody>
        </Card>
      </Col>
  </Row> */}





<Row>

<Col lg="12">
  <Card>
    <CardHeader>
    <h4>GMV</h4>
    </CardHeader>
    <CardBody>
      <Row>
        <Col lg="6">
          <CardTitle>Kleen</CardTitle>
        </Col>
        <Col lg="6">
          <CardTitle>Laundry</CardTitle>
        </Col>
      </Row>
      <Row>
        <Col lg="6">
        <div style={{height:"41px"}}></div>
          {loadingKleenGMV ?
            <ThreeDots
              height="80"
              width="80"
              radius="9"
              color={loaderColor}
              ariaLabel="three-dots-loading"
              wrapperStyle={{}}
              wrapperClassName=""
              visible={loadingKleenGMV}
            /> :
            <div id="pie-chart" className="e-chart">
              <ReactApexChart
                  options={monthlyGMVData.options}
                  series={monthlyGMVData.series}
                  type="bar" 
                  height={350}
                />
            </div>
          }
        </Col>

        <Col lg="6">
        <Row>
          <Col>
            <Label>Laundry</Label>
            <select className="form-control" onChange={(e)=>onGMVLaundryChange(e)}>
              <option  value="" > --select--</option>
              {laundrylist ? laundrylist.map((d,i)=>{
                  var dropdownme = ""
                  if(d.shortName !== undefined){
                    return <option key={i} value={d._id}  > {d.shortName}</option>
                  }else{
                    return <option key={i} value={d._id}   > {d.nameEn}</option>
                  }
              }
                  
              ) :""}
            </select>
          </Col>
        </Row>
        {loadingLaundryGMV ?
          <ThreeDots
            height="80"
            width="80"
            radius="9"
            color={loaderColor}
            ariaLabel="three-dots-loading"
            wrapperStyle={{}}
            wrapperClassName=""
            visible={loadingLaundryGMV}
          /> :
          <div id="pie-chart" className="e-chart">
            {monthlyLaundryGMV?.length > 0?
            <ReactApexChart
                  options={laundryGMVData.options}
                  series={laundryGMVData.series}
                  type="bar" 
                  height={350}
                />:null}
          </div>
        }
      </Col>

        
      </Row>
    </CardBody>
  </Card>
</Col>
</Row>


<Row>

<Col lg="12">
  <Card>
    <CardHeader>
    <h4>Average Basket</h4>
    </CardHeader>
    <CardBody>
      <Row>
        <Col lg="6">
          <CardTitle>Kleen</CardTitle>
        </Col>
        <Col lg="6">
          <CardTitle>Laundry</CardTitle>
        </Col>
      </Row>
      <Row>
        <Col lg="6">
        <div style={{height:"41px"}}></div>
          {loadingKleenAvgBasket ?
            <ThreeDots
              height="80"
              width="80"
              radius="9"
              color={loaderColor}
              ariaLabel="three-dots-loading"
              wrapperStyle={{}}
              wrapperClassName=""
              visible={loadingKleenAvgBasket}
            /> :
            <div id="pie-chart" className="e-chart">
              <ReactApexChart
                  options={avgBasketData.options}
                  series={avgBasketData.series}
                  type="bar" 
                  height={350}
                />
            </div>
          }
        </Col>

        <Col lg="6">
        <Row>
          <Col>
            <Label>Laundry</Label>
            <select className="form-control" onChange={(e)=>onAvgLaundryChange(e)}>
              <option  value="" > --select--</option>
              {laundrylist ? laundrylist.map((d,i)=>{
                  var dropdownme = ""
                  if(d.shortName !== undefined){
                    return <option key={i} value={d._id}  > {d.shortName}</option>
                  }else{
                    return <option key={i} value={d._id}   > {d.nameEn}</option>
                  }
              }
                  
              ) :""}
            </select>
          </Col>
        </Row>
        {loadingLaundryAvgBasket ?
          <ThreeDots
            height="80"
            width="80"
            radius="9"
            color={loaderColor}
            ariaLabel="three-dots-loading"
            wrapperStyle={{}}
            wrapperClassName=""
            visible={loadingLaundryAvgBasket}
          /> :
          <div id="pie-chart" className="e-chart">
            
            <ReactApexChart
                  options={laundryBasketData.options}
                  series={laundryBasketData.series}
                  type="bar" 
                  height={350}
                />
          </div>
        }
      </Col>

        
      </Row>
    </CardBody>
  </Card>
</Col>
</Row>


<Row>
<Col lg="12">
  <Card>
    <CardHeader>
    <Row>
      <Col lg="6" className="d-flex align-items-center">
        <h4 className="pe-3">Sales Growth </h4>
        <label className="mt-1 pe-2"> Select Month & Year: </label>
        <div>
          <DatePicker
            className="form-control" 
            selected={currentMonth}
            onChange={(date) => setCurrentMonth(date)}
            dateFormat="MMM/yyyy"
            showMonthYearPicker
          />
        </div>
      </Col>
    </Row>
    </CardHeader>
    <CardBody>
      <Row>
        <Col lg="6">
          <CardTitle>Kleen</CardTitle>
        </Col>
        <Col lg="6">
          <CardTitle>Laundry</CardTitle>
        </Col>
      </Row>
      <Row>
        <Col lg="6">
        <div style={{height:"41px"}}></div>
          {loadingSalesGrowth ?
            <ThreeDots
              height="80"
              width="80"
              radius="9"
              color={loaderColor}
              ariaLabel="three-dots-loading"
              wrapperStyle={{}}
              wrapperClassName=""
              visible={loadingSalesGrowth}
            /> :
            <div id="pie-chart" className="e-chart">
              <ReactApexChart
                  options={salesGrowthData.options}
                  series={salesGrowthData.series}
                  type="line" 
                  height={350}
                />
            </div>
          }
        </Col>

        <Col lg="6">
        <Row>
          <Col>
            <Label>Laundry</Label>
            <select className="form-control" onChange={(e)=>onSalesGrowthLaundryChange(e)}>
              <option  value="" > --select--</option>
              {laundrylist ? laundrylist.map((d,i)=>{
                  var dropdownme = ""
                  if(d.shortName !== undefined){
                    return <option key={i} value={d._id}  > {d.shortName}</option>
                  }else{
                    return <option key={i} value={d._id}   > {d.nameEn}</option>
                  }
              }
                  
              ) :""}
            </select>
          </Col>
        </Row>
        {loadingSalesGrowthLaundry ?
          <ThreeDots
            height="80"
            width="80"
            radius="9"
            color={loaderColor}
            ariaLabel="three-dots-loading"
            wrapperStyle={{}}
            wrapperClassName=""
            visible={loadingSalesGrowthLaundry}
          /> :
          <div id="pie-chart" className="e-chart">
            
            <ReactApexChart
                  options={salesGrowthLaundryData.options}
                  series={salesGrowthLaundryData.series}
                  type="line" 
                  height={350}
                />
          </div>
        }
      </Col>        
      </Row>
    </CardBody>
  </Card>
</Col>
</Row>


<Row>

<Col lg="12">
  <Card>
    <CardHeader>
    <h4>Basket Segment (Online)</h4>
    </CardHeader>
    <CardBody>
      <Row>
        <Col lg="6">
        <div style={{height:"41px"}}></div>
          {loadingSegment ?
            <ThreeDots
              height="80"
              width="80"
              radius="9"
              color={loaderColor}
              ariaLabel="three-dots-loading"
              wrapperStyle={{}}
              wrapperClassName=""
              visible={loadingSegment}
            /> :
            <div  className="e-chart">
              <ReactApexChart
                  options={segmentData.options}
                  series={segmentData.series}
                  type="line" 
                  height={350}
                />
            </div>
          }
        </Col>

        

        
      </Row>
    </CardBody>
  </Card>
</Col>
</Row>



<Row>

<Col lg="12">
  <Card>
    <CardHeader>
    <h4>Orders</h4>
    </CardHeader>
    <CardBody>
      <Row>
        <Col lg="6">
          <CardTitle>Kleen</CardTitle>
        </Col>
        <Col lg="6">
          <CardTitle>Laundry</CardTitle>
        </Col>
      </Row>
      <Row>
        <Col lg="6">
          <div style={{height:"41px"}}>

          </div>
          {loadingKleenOrders ?
            <ThreeDots
              height="80"
              width="80"
              radius="9"
              color={loaderColor}
              ariaLabel="three-dots-loading"
              wrapperStyle={{}}
              wrapperClassName=""
              visible={loadingKleenOrders}
            /> :
            <div id="pie-chart" className="e-chart">
              
              <ReactApexChart
                  options={monthlyOrdersData.options}
                  series={monthlyOrdersData.series}
                  type="bar" 
                  height={350}
                />
            </div>
          }
        </Col>

        <Col lg="6">
        <Row>
          <Col>
            <Label>Laundry</Label>
            <select className="form-control" onChange={(e)=>onOrderLaundryChange(e)}>
              <option  value="" > --select--</option>
              {laundrylist ? laundrylist.map((d,i)=>{
                  var dropdownme = ""
                  if(d.shortName !== undefined){
                    return <option key={i} value={d._id}  > {d.shortName}</option>
                  }else{
                    return <option key={i} value={d._id}   > {d.nameEn}</option>
                  }
              }
                  
              ) :""}
            </select>
          </Col>
        </Row>
        {loadingLaundryOrders ?
          <ThreeDots
            height="80"
            width="80"
            radius="9"
            color={loaderColor}
            ariaLabel="three-dots-loading"
            wrapperStyle={{}}
            wrapperClassName=""
            visible={loadingLaundryOrders}
          /> :
          <div id="pie-chart" className="e-chart">
            {monthlyLaundryOrders?.length > 0?
            <ReactApexChart
                  options={laundryOrdersData.options}
                  series={laundryOrdersData.series}
                  type="bar" 
                  height={350}
                />:null}
          </div>
        }
      </Col>

        
      </Row>
    </CardBody>
  </Card>
</Col>
</Row>



        <Row>

          <Col lg="12">
            <Card>
              <CardHeader>
              <h4>Customers</h4>
              </CardHeader>
              <CardBody>
                <Row>
                  <Col lg="6">
                    <CardTitle>App Registrations</CardTitle>
                  </Col>
                  <Col lg="6">
                    <CardTitle> Online Active Customers</CardTitle>
                  </Col>
                </Row>
                <Row>
                  <Col lg="6">
                  
                    {loadingAppRegistrations ?
                      <ThreeDots
                        height="80"
                        width="80"
                        radius="9"
                        color={loaderColor}
                        ariaLabel="three-dots-loading"
                        wrapperStyle={{}}
                        wrapperClassName=""
                        visible={loadingAppRegistrations}
                      /> :
                      <div id="pie-chart" className="e-chart">
                        <ReactApexChart
                            options={{
                              chart: {
                                toolbar: {
                                  show: false,
                                },
                              },
                             
                              dataLabels: {
                                enabled: false,
                              },
                              yaxis: {
                                title: {
                                  text: "App Registartions",
                                },
                              },
                          
                              colors: ["#34c38f"],
                              grid: {
                                borderColor: "#f1f1f1",
                              },
                              xaxis: {
                                categories: appRegistrationsData.map((item) => months[item.month-1] + ' ' + item.year ).reverse()
                              },
                            }}
                            series={[{
                              name: "Registrations",
                              data: appRegistrationsData.map((item) => item.registrations ).reverse()
                            }]}
                            type="bar" 
                            height={350}
                          />
                      </div>
                    }
                  </Col>

                  <Col lg="6">
                  
                  {loadingUniqueOrders ?
                    <ThreeDots
                      height="80"
                      width="80"
                      radius="9"
                      color={loaderColor}
                      ariaLabel="three-dots-loading"
                      wrapperStyle={{}}
                      wrapperClassName=""
                      visible={loadingUniqueOrders}
                    /> :
                    <div id="pie-chart" className="e-chart">
                      <ReactApexChart
                          options={{
                            chart: {
                              toolbar: {
                                show: false,
                              },
                            },
                           
                            dataLabels: {
                              enabled: false,
                            },
                            yaxis: {
                              title: {
                                text: "Unique Orders",
                              },
                            },
                        
                            colors: ["#34c38f"],
                            grid: {
                              borderColor: "#f1f1f1",
                            },
                            xaxis: {
                              categories: uniqueCustomerOrders.map((item) => months[item.month-1] + ' ' + item.year ).reverse()
                            },
                          }}
                          series={[{
                            name: "Registrations",
                            data: uniqueCustomerOrders.map((item) => item.orders ).reverse()
                          }]}
                          type="bar" 
                          height={350}
                        />
                    </div>
                  }
                </Col>

                  
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>

  <Row>
  <Col lg="12">
  <Card>
    <CardHeader>
    <h4>Subscription Chart</h4>
    </CardHeader>
    <CardBody>
      <Row>
        <Col lg="6">
          <CardTitle>Area</CardTitle>
        </Col>
        <Col lg="6">
          <CardTitle>Area</CardTitle>
        </Col>
      </Row>
      <Row>
        <Col lg="6">
        <Row>
          <Col>
            <Label>Area</Label>
            <select className="form-control" onChange={(e)=>onSubMonthlyChange(e)}>
              <option  value="" > --select--</option>
              {arealist?.map((d,i)=>{
                return <option key={i} value={d._id}  > {d.name}</option>  
              }
              )}
            </select>
          </Col>
        </Row>
        <div style={{height:"41px"}}></div>
          {loadingKleenAvgBasket ?
            <ThreeDots
              height="80"
              width="80"
              radius="9"
              color={loaderColor}
              ariaLabel="three-dots-loading"
              wrapperStyle={{}}
              wrapperClassName=""
              visible={loadingKleenAvgBasket}
            /> :
            <div id="pie-chart" className="e-chart">
              <ReactApexChart
                  options={avgSubscriptionData?.options}
                  series={avgSubscriptionData?.series}
                  type="bar" 
                  height={350}
                />
            </div>
          }
        </Col>

        <Col lg="6">
        <Row>
          <Col>
            <Label>Area</Label>
            <select className="form-control" onChange={(e)=>onareaChangeSubscriber(e)}>
              <option  value="" > --select--</option>
              {arealist?.map((d,i)=>{
                  var dropdownme = ""
                 
                    return <option key={i} value={d._id}  > {d.name}</option>
                
              }
                  
              )}
            </select>
          </Col>
        </Row>
        <div style={{height:"41px"}}></div>
        {loadingKleenAvgBasket ?
          <ThreeDots
            height="80"
            width="80"
            radius="9"
            color={loaderColor}
            ariaLabel="three-dots-loading"
            wrapperStyle={{}}
            wrapperClassName=""
            visible={loadingKleenAvgBasket}
          /> :
          <div id="pie-chart" className="e-chart">
            
            <ReactApexChart
                  options={areaSubscriberData?.options}
                  series={areaSubscriberData?.series}
                  type="bar" 
                  height={350}
                />
          </div>
        }
      </Col>
      </Row>
    </CardBody>
  </Card>
</Col>
</Row>






      </Container>
    </div> 
    )
  }else{
    if(pageLoading)
      return (
        <div className="page-content">
          <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumbs
          />

            <Skeleton />
          </Container>
        </div>
      )
    else
      return <WelcomePage/>
  }



  // return (
  
  //   <React.Fragment>
   
   
  
     
  

    

  //     {/* subscribe ModalHeader */}
  //     <Modal
  //       isOpen={subscribemodal}
  //       role="dialog"
  //       autoFocus={true}
  //       centered
  //       data-toggle="modal"
  //       toggle={() => {
  //         setSubscribemodal(!subscribemodal)
  //       }}
  //     >
  //       <div className="modal-content">
  //         <div className="modal-header border-bottom-0">
  //           <ModalHeader
  //             toggle={() => {
  //               setSubscribemodal(!subscribemodal)
  //             }}
  //           ></ModalHeader>
  //         </div>
  //         <div className="modal-body">
  //           <div className="text-center mb-4">
  //             <div className="avatar-md mx-auto mb-4">
  //               <div className="avatar-title bg-light  rounded-circle text-primary h1">
  //                 <i className="mdi mdi-email-open"></i>
  //               </div>
  //             </div>

  //             <div className="row justify-content-center">
  //               <div className="col-xl-10">
  //                 <h4 className="text-primary">Subscribe !</h4>
  //                 <p className="text-muted font-size-14 mb-4">
  //                   Subscribe our newletter and get notification to stay update.
  //                 </p>

  //                 <div
  //                   className="input-group rounded bg-light"
  //                 >
  //                   <Input
  //                     type="email"
  //                     className="form-control bg-transparent border-0"
  //                     placeholder="Enter Email address"
  //                   />
  //                   <Button color="primary" type="button" id="button-addon2">
  //                     <i className="bx bxs-paper-plane"></i>
  //                   </Button>
  //                 </div>
  //               </div>
  //             </div>
  //           </div>
  //         </div>
  //       </div>
  //     </Modal>

  //     <Modal
  //       isOpen={modal}
  //       role="dialog"
  //       autoFocus={true}
  //       centered={true}
  //       className="exampleModal"
  //       tabIndex="-1"
  //       toggle={() => {
  //         setmodal(!modal)
  //       }}
  //     >
  //       <div className="modal-content">
  //         <ModalHeader
  //           toggle={() => {
  //             setmodal(!modal)
  //           }}
  //         >
  //           Order Details
  //         </ModalHeader>
  //         <ModalBody>
  //           <p className="mb-2">
  //             Product id: <span className="text-primary">#SK2540</span>
  //           </p>
  //           <p className="mb-4">
  //             Billing Name: <span className="text-primary">Neal Matthews</span>
  //           </p>

  //           <div className="table-responsive">
  //             <Table className="table table-centered table-nowrap">
  //               <thead>
  //                 <tr>
  //                   <th scope="col">Product</th>
  //                   <th scope="col">Product Name</th>
  //                   <th scope="col">Price</th>
  //                 </tr>
  //               </thead>
  //               <tbody>
  //                 <tr>
  //                   <th scope="row">
  //                     <div>
  //                       <img src={modalimage1} alt="" className="avatar-sm" />
  //                     </div>
  //                   </th>
  //                   <td>
  //                     <div>
  //                       <h5 className="text-truncate font-size-14">
  //                         Wireless Headphone (Black)
  //                       </h5>
  //                       <p className="text-muted mb-0">$ 225 x 1</p>
  //                     </div>
  //                   </td>
  //                   <td>$ 255</td>
  //                 </tr>
  //                 <tr>
  //                   <th scope="row">
  //                     <div>
  //                       <img src={modalimage2} alt="" className="avatar-sm" />
  //                     </div>
  //                   </th>
  //                   <td>
  //                     <div>
  //                       <h5 className="text-truncate font-size-14">
  //                         Hoodie (Blue)
  //                       </h5>
  //                       <p className="text-muted mb-0">$ 145 x 1</p>
  //                     </div>
  //                   </td>
  //                   <td>$ 145</td>
  //                 </tr>
  //                 <tr>
  //                   <td colSpan="2">
  //                     <h6 className="m-0 text-end">Sub Total:</h6>
  //                   </td>
  //                   <td>$ 400</td>
  //                 </tr>
  //                 <tr>
  //                   <td colSpan="2">
  //                     <h6 className="m-0 text-end">Shipping:</h6>
  //                   </td>
  //                   <td>Free</td>
  //                 </tr>
  //                 <tr>
  //                   <td colSpan="2">
  //                     <h6 className="m-0 text-end">Total:</h6>
  //                   </td>
  //                   <td>$ 400</td>
  //                 </tr>
  //               </tbody>
  //             </Table>
  //           </div>
  //         </ModalBody>
  //         <ModalFooter>
  //           <Button
  //             type="button"
  //             color="secondary"
  //             onClick={() => {
  //               setmodal(!modal)
  //             }}
  //           >
  //             Close
  //           </Button>
  //         </ModalFooter>
  //       </div>
  //     </Modal>
  //   </React.Fragment>
  // )
}

AnalyticsHome.propTypes = {
  t: PropTypes.any
}

export default withTranslation()(GoogleApiWrapper({
  apiKey: "AIzaSyAvZvBwMKrllZNCXdikzvCDOzu8rwqv-0c",
  libraries: mapLibraries
})(AnalyticsHome))
