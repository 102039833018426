
export const API_SUCCESS = "API_SUCCESS";
export const API_FAIL = "API_FAIL";

// VIEW DRIVER 
export const GET_CLOTHES_LIST = "GET_CLOTHES_LIST";
export const GET_CLOTHES_LIST_SUCCESS = "GET_CLOTHES_LIST_SUCCESS";
export const GET_CLOTHES_LIST_FAIL = "GET_CLOTHES_LIST_FAIL";


// EDIT DRIVER 
export const UPDATE_CLOTHES = "UPDATE_CLOTHES_LIST";
export const UPDATE_CLOTHES_SUCCESS = "UPDATE_CLOTHES_LIST_SUCCESS";
export const UPDATE_CLOTHES_FAIL = "UPDATE_CLOTHES_LIST_FAIL";


// ADD NEW dRIVER 
export const ADD_NEW_CLOTHES = "ADD_NEW_CLOTHES";
export const ADD_NEW_CLOTHES_SUCCESS = "ADD_NEW_CLOTHES_SUCCESS";
export const ADD_NEW_CLOTHES_FAIL = "ADD_NEW_CLOTHES_FAIL";


// DELETE DRIVER 
export const DELETE_CLOTHES = "DELETE_CLOTHES_LIST";
export const DELETE_CLOTHES_SUCCESS = "DELETE_CLOTHES_LIST_SUCCESS";
export const DELETE_CLOTHES_FAIL = "DELETE_CLOTHES_LIST_FAIL";