
export const API_SUCCESS = "API_SUCCESS";
export const API_FAIL = "API_FAIL";

// VIEW DRIVER 
export const GET_DRIVER_LIST = "GET_DRIVER_LIST";
export const GET_DRIVER_LIST_SUCCESS = "GET_DRIVER_LIST_SUCCESS";
export const GET_DRIVER_LIST_FAIL = "GET_DRIVER_LIST_FAIL";


// EDIT DRIVER 
export const UPDATE_DRIVER = "UPDATE_DRIVER_LIST";
export const UPDATE_DRIVER_SUCCESS = "UPDATE_DRIVER_LIST_SUCCESS";
export const UPDATE_DRIVER_FAIL = "UPDATE_DRIVER_LIST_FAIL";


// ADD NEW dRIVER 
export const ADD_NEW_DRIVER = "ADD_NEW_DRIVER";
export const ADD_NEW_DRIVER_SUCCESS = "ADD_NEW_DRIVER_SUCCESS";
export const ADD_NEW_DRIVER_FAIL = "ADD_NEW_DRIVER_FAIL";


// DELETE DRIVER 
export const DELETE_DRIVER = "DELETE_DRIVER_LIST";
export const DELETE_DRIVER_SUCCESS = "DELETE_DRIVER_LIST_SUCCESS";
export const DELETE_DRIVER_FAIL = "DELETE_DRIVER_LIST_FAIL";

// Driver Locations
export const GET_DRIVER_LOCATIONS = "GET_DRIVER_LOCATIONS";
export const GET_DRIVER_LOCATIONS_SUCCESS = "GET_DRIVER_LOCATIONS_SUCCESS";
export const GET_DRIVER_LOCATIONS_FAIL = "GET_DRIVER_LOCATIONS_FAIL";