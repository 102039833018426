import {
  GET_PACKAGES_LIST,
  GET_PACKAGE_DETAIL,
  GET_PACKAGES_LIST_SUCCESS,
  GET_PACKAGES_LIST_FAIL,
  GET_PACKAGE_DETAIL_SUCCESS,
  GET_PACKAGE_DETAIL_FAIL,
} from "./actionTypes"

export const getPackagesList = (data) => ({
  type: GET_PACKAGES_LIST,
  payload: data
})

export const getPackageDetail = (data) => ({
  type: GET_PACKAGE_DETAIL,
  payload: data
})

export const getPackageDetailSuccess = (data) => ({
  type: GET_PACKAGE_DETAIL_SUCCESS,
  payload: data
})

export const getPackageDetailFail = (data) => ({
  type: GET_PACKAGE_DETAIL_FAIL,
  payload: data
})

export const getPackagesListSuccess = (data) => ({
  type: GET_PACKAGES_LIST_SUCCESS,
  payload: data
})

export const getPackagesListFail = (data) => ({
  type: GET_PACKAGES_LIST_FAIL,
  payload: data
})

