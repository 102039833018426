const orderStatus = {
  CANCELLED: "cancelled",
  DELIVERED: "delivered",
  ACCEPTED: "accepted",
}

const PACKAGE_TYPES = {
  "ONLINE_PACKAGE" : "order_count"
}
const APP_VERSION = "3.1.7"
const mapLibraries = ["drawing"];

export {
  orderStatus,
  PACKAGE_TYPES,
  APP_VERSION,
  mapLibraries
}
