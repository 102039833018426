/** Get Dashboard Chart data */
export const API_SUCCESS = "API_SUCCESS";
export const API_FAIL = "API_FAIL";
export const GET_CHARTS_DATA = "GET_CHARTS_DATA";
export const GET_CHARTS_DATA_RADIAL ="GET_CHARTS_DATA_RADIAL";
export const GET_REATIME_DETAILS ="GET_REATIME_DETAILS";
export const GET_TOP4_DATA ="GET_TOP4_DATA";
export const SET_LOADING ="SET_LOADING";
export const APP_REGISTRATIONS_MONTHLY = "APP_REGISTRATIONS_MONTHLY";
export const UNIQUE_CUSTOMER_ORDERS = "UNIQUE_CUSTOMER_ORDERS";
export const MONTHLY_KLEEN_ORDERS = "MONTHLY_KLEEN_ORDERS";
export const MONTHLY_LAUNDRY_ORDERS = "MONTHLY_LAUNDRY_ORDERS";
export const MONTHLY_KLEEN_GMV = "MONTHLY_KLEEN_GMV";
export const MONTHLY_LAUNDRY_GMV = "MONTHLY_LAUNDRY_GMV";
export const DAILY_LAUNDRY_DATA = "DAILY_LAUNDRY_DATA";
export const DAILY_ONLINE_DATA = "DAILY_ONLINE_DATA";
export const MONTHLY_KLEEN_AVGBASKET = "MONTHLY_KLEEN_AVGBASKET";
export const MONTHLY_KLEEN_AVGSUBSCRIPTION = "MONTHLY_KLEEN_AVGSUBSCRIPTION";
export const MONTHLY_KLEEN_SUBSCRIBER = "MONTHLY_KLEEN_SUBSCRIBER";
export const MONTHLY_LAUNDRY_AVGBASKET = "MONTHLY_LAUNDRY_AVGBASKET";
export const MONTHLY_SEGMENT = "MONTHLY_SEGMENT";
export const SALES_GROWTH = "SALES_GROWTH";
export const SALES_GROWTH_LAUNDRY = "SALES_GROWTH_LAUNDRY";





