import { postWeb } from "./api_helper"
import * as url from "./url_helper"

const fetchTimeslots = (data) => postWeb(`${url.GET_TIMESLOT_OF_DATE}`, data)
const fetchPickupTimeslots = (data) => postWeb(`${url.FETCH_PICKUP_TIMESLOTS}`, data)
const fetchDeliveryTimeslots = (data) => postWeb(`${url.FETCH_DELIVERY_TIMESLOTS}`, data)
const _createTimeslot = (data) => postWeb(`${url.CREATE_TIMESLOT}`, data)
const _updateTimeslot = (data) => postWeb(`${url.UPDATE_TIMESLOT}`, data)

export {
  fetchTimeslots,
  fetchPickupTimeslots,
  fetchDeliveryTimeslots,
  _createTimeslot,
  _updateTimeslot,
}