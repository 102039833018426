import {
  GET_LOYALITY_POINTS,
  GET_LOYALITY_POINTS_SUCCESS,
  UPDATE_LOYALITY_POINTS,
  UPDATE_LOYALITY_POINTS_SUCCESS,
} from "./actionTypes"

export const getLoyalityPoints = data => ({
  type: GET_LOYALITY_POINTS,
  payload: data,
})

export const getLoyalityPointsSuccess = data => ({
  type: GET_LOYALITY_POINTS_SUCCESS,
  payload: data,
})

export const updateLoyalityPoints = data => ({
  type: UPDATE_LOYALITY_POINTS,
  payload: data,
})

export const updateLoyalityPointsSuccess = data => ({
  type: UPDATE_LOYALITY_POINTS_SUCCESS,
  payload: data,
})
