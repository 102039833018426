import React, { useState } from "react"
import { Row, Col, Card, CardBody, CardTitle, Button } from "reactstrap"
import MetaTags from 'react-meta-tags'
import PropTypes from "prop-types"

// datatable related plugins
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory, {
  PaginationProvider, PaginationListStandalone,
  SizePerPageDropdownStandalone
} from 'react-bootstrap-table2-paginator';

import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"
import "../../assets/scss/datatables.scss"
import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux"
import {searchUsers, getReasons, getCustomersList } from "../../store/actions"
import classNames from "classnames"
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import RatingTooltip from "react-rating-tooltip"
import Pages404 from "pages/Utility/pages-404";
import moment from 'moment-timezone';
import {useHistory} from "react-router-dom";
import Select from "react-select"
import { removeMobilePrefix } from "helpers/common_helper";
import toastr from "toastr";
import "toastr/build/toastr.min.css";
import { postWeb } from "helpers/api_helper";

const EditNote = props => {
  const [label, setLabel] = useState("");
  const [emptyLabel, setEmptyLabel] = useState("");
  const [showLabel, setShowLabel] = useState(true);
  const [selectedItem, setSelectedItem] = useState("");
  
  useEffect(() => {
    setSelectedItem(props.row?.customer_care_note);
  }, [props]);

  useEffect(() => {
    setLabel(props.row?.customer_care_note);
    setEmptyLabel("No Note")  
  }, [props]);

  const showEditable = (e) => {
    e.stopPropagation();
    setShowLabel(false);
  }

  const handleClose = () => {
    setShowLabel(true);
  }
  
  const handleSubmit = async () => {
    
      if(!selectedItem || selectedItem.trim() == ""){
        toastr.error("Please enter customer care note", `Required`);
        return;
      }

      if(!props.row?._id){
        toastr.error("Invalid customer ", `Required`);
        return;
      }
      
      postWeb("customers/update", {
        _id : props.row?._id,
        customer_care_note : selectedItem
      }).then(() => {
        props.onUpdate.apply(null, [props.row._id, selectedItem]);
      });
      
      toastr.success(`Customer Care Note Updated Successfully`, `Customer Care Note Updated`);
      
      setShowLabel(true);
      return;

  }

  const onChangeItem = (e) => {
    setSelectedItem(e.target.value);
  }

  return (
    <>
    {showLabel?
    <>
          {label ? 
            <a onClick={showEditable} className="editable editable-click">{label}</a> : 
            <a onClick={showEditable} className="editable editable-click editable-empty text-danger">{emptyLabel}</a>}
        </> :
        <>
          <textarea 
            onChange={(e) => {
              e.stopPropagation();
              setSelectedItem(e.target.value)
            } }
            className="form-control"
          >{selectedItem}</textarea>
          <div className="editable-buttons">
            <button type="submit" 
              onClick={handleSubmit}
              className="btn btn-success editable-submit btn-sm waves-effect waves-light">
                <i className="mdi mdi-check">
                </i>
            </button>
            <button 
              type="button"
              onClick={handleClose} 
              className="btn btn-danger editable-cancel btn-sm waves-effect waves-light">
              <i className="mdi mdi-close"></i>
            </button>
          </div>
        </>}
    </>
  );
};

EditNote.propTypes = {
  row: PropTypes.any,
  onUpdate: PropTypes.any
}


const Customers = () => {
  const dispatch = useDispatch()
  const history = useHistory();
  const { customers, refundReason, users } = useSelector(state => ({
    // Allorders: state.Orders.Allorders,
    customers: state.Customers.customers,
    refundReason: state.Orders.reason,
    users : state.Users.users
  }))

  const [customerData, setCustomerData] = useState([]);
  const [page, setPage] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [userData, setUserData] = useState({});
  const [totalRecords, setTotalRecords] = useState(0);
  const [orderCount, setOrderCount] = useState(undefined);
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  
  const initialData = {
    start: moment().add(-1, "M").tz("Asia/kuwait").format("YYYY-MM-DD"),
    end: moment().tz("Asia/kuwait").format("YYYY-MM-DD")
  };

  const starStyle = {}

  const addTztoDate = (dt) => {
    const targetTime = new Date(dt);
    return moment(dt).tz("Asia/kuwait").format("YYYY-MM-DD");
  };


  const handleApply = () => {
    
    const data = {
      start: startDate || initialData.start,
      end : endDate || initialData.end
    };

    if(orderCount != undefined){
      data.orders = orderCount;
    }
    
    dispatch(getCustomersList(data))
  }


  useEffect(() => {
    setStartDate(initialData.start);
    setEndDate(initialData.end);
    dispatch(searchUsers({webPermissions: true}))
    dispatch(getReasons({}))

  }, [])

  useEffect(() => {
    if(users.length> 0){
      setUserData(users[0]);
    }
  }, [users])

  useEffect(() => {
    setCustomerData([])
    setIsLoading(true);
    handleApply();
  }, [page])

  useEffect(() => {
    const _customers = customers?.record;
    const _totalCustomers = customers?.totalCustomers;

    if (_customers?.length) {
      setCustomerData(_customers)
      setTotalRecords(_totalCustomers)
    }
    setIsLoading(false);
  }, [customers])

  const columns = [{
    dataField: 'mobile',
    text: 'Mobile Number',
    sort: false,
    formatter: (cellContent, row) => {
      return  removeMobilePrefix(row?.mobile) || "N/A"
    }
  }, {
    dataField: 'location.type',
    text: 'Location Type',
    sort: false,
    formatter: (cellContent, row) => {
      return row?.location?.type || "N/A"
    }
  }, {
    dataField: 'registrationDateTime',
    text: 'Registration Date',
    sort: false,
    formatter: (cellContent, row) => {
      return (row?.registrationDateTime)?addTztoDate(row.registrationDateTime): ""
    }
  }, {
    dataField: 'totalOrders',
    text: 'Total Orders',
    sort: false,
    formatter: (cellContent, row) => {
      return row?.totalOrders || "N/A"
    }
  },
  {
    dataField: 'customer_care_note',
    text: 'Customer Care Note',
    classes: "reason-wrap",
    sort: false,
    formatter: (cellContent, row, i) => {
      if (isLoading) {
        return (<Skeleton count={1} />)
      }
      
      return <>
        
        <EditNote onUpdate={handleApply} row={row} />
      </>
    }
  },

];

  const defaultSorted = [{
    dataField: 'id',
    order: 'asc'
  }];

  const pageOptions = {
    sizePerPage: 20,
    totalSize: totalRecords, // replace later with size(customers),
    custom: true,
  }

  // Custom Pagination Toggle
  const sizePerPageList = [
    { text: '5', value: 5 },
    { text: '10', value: 10 },
    { text: '15', value: 15 },
    { text: '20', value: 20 },
    { text: '25', value: 25 },
    { text: 'All', value: totalRecords }];


    const rowEvents = {
      onClick: (e, row, rowIndex) => {
        
        if(e.target.classList && e.target.classList.value.indexOf("mdi") != -1){
          return;
        }

        if(e.target.type == "textarea" || e.target.type == "button" || e.target.type == "submit"){
          return;
        }

        history.push(`/create-invoice/${row._id}`, {prevPath:'/completed-orders', orderID:row._id});
      }
    };

  const { SearchBar } = Search;
  if(userData?.permissions?.allPermissions==true || userData?.permissions?.orders?.read==true){
  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Kleen | Customers</title>
        </MetaTags>
        <div className="container-fluid">
          <Breadcrumbs title="Customers" breadcrumbItem="Customers" />

          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>

                  {/* <CardTitle className="h4">Completed orders </CardTitle> */}
                  {/* <p className="card-title-desc">
                    react-bootstrap-table-next plugin has most features enabled by default,
                    so all you need to do to use it with your own tables is to
                    call the construction function:{" "}
                    <code>react-bootstrap-table-next </code>.
                  </p> */}
                  <Row className="mb-2">
                    <Col sm="6">
                      <div className="me-2 mb-2 d-inline-block">
                        <div className="position-relative d-flex">
                          
                          <div className="ms-2" style={{ width: "20rem" }}>
                            <Select
                              placeholder="Select Orders"
                              onChange={val => {
                                setOrderCount(val?.value)
                              }}
                              isClearable={true}
                              options={[
                                { value: 0, label: "0" },
                                { value: 1, label: "1" },
                                { value: 2, label: "More than 1" },
                              ]}
                            />
                          </div>
                          <label
                          htmlFor="example-date-input"
                          className="col-md-2 col-form-label text-right"
                        >
                          From Date
                        </label>
                        <div className="col-md-2">
                          <input
                            className="form-control"
                            type="date"
                            defaultValue={initialData?.start}
                            id="start"
                            name="start"
                            onChange={(e) => setStartDate(e.target.value)}
                            value={startDate}
                          />

                        </div>
                        <label
                          htmlFor="example-date-input"
                          className="col-md-2 col-form-label text-right"
                        >
                          To  Date
                        </label>
                        <div className="col-md-2">
                          <input
                            className="form-control"
                            defaultValue={initialData?.end}
                            type="date"
                            id="end"
                            name="end"
                            onChange={(e) => setEndDate(e.target.value)}
                            value={endDate}
                          />

                        </div>

                          <Button
                            className="ms-2"
                            color="primary"
                            onClick={() => {
                              setIsLoading(true);
                              setCustomerData([]);
                              setTimeout(() => {
                                if(page != 0){
                                  setPage(0);
                                }
                                else{
                                  handleApply()
                                }
                              }, 10)
                            }}
                          >Apply</Button>
                        </div>
                      </div>
                    </Col>
                  </Row>
                  {
                    isLoading ? (
                      <div className="row">
                        <div className="col-xl-12">
                          <div className="table-responsive">
                            <div className="react-bootstrap-table">
                              <table className="table table align-middle table-nowrap">
                                <thead className="table-light">
                                  <tr>
                                    <th>Order</th>
                                    <th>Customer</th>
                                    <th>Mobile</th>
                                    <th>Start Date</th>
                                    <th>Grand Total</th>
                                    <th>Rating</th>
                                    <th>Comments</th>
                                    <th>Total Orders</th>

                                    <th>First Order</th>
                                    <th>Last Order</th>
                                    <th>Registration</th>

                                  </tr>
                                </thead>
                                <tbody>
                                  {[...Array(10).keys()].map((k) => (
                                    <tr key={k}>
                                      <td><Skeleton count={1} /></td>
                                      <td><Skeleton count={1} /></td>
                                      <td><Skeleton count={1} /></td>
                                      <td><Skeleton count={1} /></td>
                                      <td><Skeleton count={1} /></td>
                                      <td><Skeleton count={1} /></td>
                                      <td><Skeleton count={1} /></td>
                                      <td><Skeleton count={1} /></td>
                                      
                                      <td><Skeleton count={1} /></td>
                                      <td><Skeleton count={1} /></td>
                                      <td><Skeleton count={1} /></td>
                                  </tr>  
                                  ))}
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                      </div>
                    ) : (
                      <PaginationProvider
                        pagination={paginationFactory(pageOptions)}
                        keyField='id'
                        columns={columns}
                        data={customerData}
                      >
                        {({ paginationProps, paginationTableProps }) => (
                          <ToolkitProvider
                            keyField='id'
                            columns={columns}
                            data={customerData}
                            search
                          >
                            {toolkitProps => (
                              <React.Fragment>

                                {/* <Row className="mb-2">
                              <Col md="4">
                                <div className="search-box me-2 mb-2 d-inline-block">
                                  <div className="position-relative">
                                    <SearchBar
                                      {...toolkitProps.searchProps}
                                    />
                                    <i className="bx bx-search-alt search-icon" />
                                  </div>
                                </div>
                              </Col>
                            </Row> */}

                                <Row>
                                  <Col xl="12">
                                    <div className="table-responsive">
                                      <BootstrapTable
                                        keyField={"id"}
                                        responsive
                                        bordered={false}
                                        striped={false}
                                        defaultSorted={defaultSorted}
                                        rowEvents={rowEvents}
                                        // selectRow={selectRow}
                                        classes={
                                          "table align-middle table-nowrap"
                                        }
                                        headerWrapperClasses={"table-light"}
                                        // headerWrapperClasses={"thead-light"}
                                        {...toolkitProps.baseProps}
                                        {...paginationTableProps}
                                      />

                                    </div>
                                  </Col>
                                </Row>

                                {/* <Row className="align-items-md-center mt-30">
                              <Col className="inner-custom-pagination d-flex">
                                <div className="d-inline">
                                  <SizePerPageDropdownStandalone
                                    {...paginationProps}
                                  />
                                </div>
                                <div className="text-md-right ms-auto">
                                  <PaginationListStandalone
                                    {...paginationProps}
                                  />
                                </div>
                              </Col>
                            </Row> */}
                              </React.Fragment>
                            )
                            }
                          </ToolkitProvider>
                        )
                        }</PaginationProvider>
                    )
                  }

                </CardBody>
              </Card>
            </Col>
          </Row>
          <div className="align-items-md-center mt-30 row">
            <div className="inner-custom-pagination d-flex col">
              {/* <div className="d-inline">
                  <span className="react-bs-table-sizePerPage-dropdown dropdown" style={{ visibility: "visible" }}>
                    <button id="pageDropDown" type="button" className="btn btn-default btn-secondary dropdown-toggle" data-toggle="dropdown" aria-expanded="false">
                      10 <span><span className="caret"></span></span>
                    </button>
                    <ul className="dropdown-menu " role="menu" aria-labelledby="pageDropDown">
                      <li role="presentation" className="dropdown-item">
                        <a href="#" tabIndex="-1" role="menuitem" data-page="10">10</a>
                      </li>
                      <li role="presentation" className="dropdown-item">
                        <a href="#" tabIndex="-1" role="menuitem" data-page="25">25</a>
                      </li>
                      <li role="presentation" className="dropdown-item">
                        <a href="#" tabIndex="-1" role="menuitem" data-page="30">30</a>
                      </li>
                      <li role="presentation" className="dropdown-item">
                        <a href="#" tabIndex="-1" role="menuitem" data-page="50">50</a>
                      </li>
                    </ul>
                  </span>
                </div> */}
              <div className="text-md-right ms-auto">
                <ul className="pagination react-bootstrap-table-page-btns-ul">
                  {
                    page > 5 ? (
                      <li className="page-item" title="next page">
                        <a onClick={() => setPage(0)} className="page-link">First</a>
                      </li>
                    ) : null
                  }
                  {
                    [...Array(Math.ceil(totalRecords / 20)).keys()].map(element =>
                      (page >= element - 5 && page <= element + 5) ?
                        (
                          <li key={element} className={classNames("page-item",
                            { "active": element == page }
                          )
                          } title="1">
                            <a onClick={() => setPage(element)} className="page-link">{element + 1}</a>
                          </li>
                        ) : null
                    )
                  }
                  {
                    page < Math.ceil(totalRecords / 20) - 6 ? (
                      <li className="page-item" title="next page">
                        <a onClick={() => setPage(Math.ceil(totalRecords / 20) - 1)} className="page-link">Last</a>
                      </li>
                    ) : null
                  }

                  {/* <li className="page-item" title="2">
                      <a href="#" className="page-link">2</a>
                    </li>
                    <li className="page-item" title="3">
                      <a href="#" className="page-link">3</a>
                    </li> */}
                  {/* <li className="page-item" title="next page">
                      <a href="#" className="page-link">&gt;</a>
                    </li> */}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  )
  }
  else{
   return <Pages404/> 
  }
}

export default Customers
