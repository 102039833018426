import { call, put, takeEvery } from "redux-saga/effects"

// Crypto Redux States
import { GET_RECORD, GET_SEARCH_RECORD } from "./actionTypes"
import { getRecordSuccess, getRecordFail } from "./actions"

//Include Both Helper File with needed methods
import { getRecords, getSearchRecords } from "helpers/fakebackend_helper"

function* fetchRecords({ payload: record }) {
  try {
    const response = yield call(getRecords, record)
    yield put(getRecordSuccess(response))
  } catch (error) {
    yield put(getRecordFail(error))
  }
}

function* fetchSearchRecords({ payload: record }) {
  try {
    const response = yield call(getSearchRecords, record)
    yield put(getRecordSuccess({ ...response, total_orders: 0 }))
  } catch (error) {
    yield put(getRecordFail(error))
  }
}

function* recordsSaga() {
  yield takeEvery(GET_RECORD, fetchRecords)
  yield takeEvery(GET_SEARCH_RECORD, fetchSearchRecords)
}

export default recordsSaga
