import PropTypes from "prop-types"
import React, { useEffect, useState, useRef } from "react"
import MetaTags from "react-meta-tags";
import { Formik, Field } from 'formik';
import { AvForm, AvField } from "availity-reactstrap-validation"
import { Link } from "react-router-dom"
import Pages404 from "pages/Utility/pages-404";
import {
  Container,
  Row,
  Col,
  Modal,
  Button,
  Card,
  CardBody,
  CardTitle,
  Label,
  Input,
  Form
} from "reactstrap"
import paginationFactory, {
  PaginationListStandalone,
  PaginationProvider,
} from "react-bootstrap-table2-paginator";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import BootstrapTable from "react-bootstrap-table-next";
import * as moment from "moment";
import { addUser, updateUser } from "helpers/userHelper"

//import action
import { getUsers, getUserdetails, toggleUserActive, toggleUserPermission, searchUsers, getPermission as getAllPermission } from "../../store/actions"

import toastr from "toastr";
import "toastr/build/toastr.min.css";
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"

//i18n
import { withTranslation } from "react-i18next"

//redux
import { useSelector, useDispatch } from "react-redux"

const Users = props => {
  const [userModal, SetUserModal] = useState(false)
  const [userData, setUserData] = useState({});
  const [name, setName] = useState("")
  const [email, setEmail] = useState("")
  const [password, setPassword] = useState("")
  const [userRoleOptions, setUserRoleOptions] = useState([])

  const { userList, userDetails, users, roles } = useSelector(state => ({
    userList: state.Users.userList,
    userDetails: state.Users.userDetails,
    roles: state.permission.permissions,
    users: state.Users.users
  }))


  var node = useRef();
  const keyField = "id";
  const { SearchBar } = Search;
  const sizePerPage = 10;
  const pageOptions = {
    sizePerPage: sizePerPage,
    totalSize: userList.length, // replace later with size(users),
    custom: true,
  };
  const defaultSorted = [
    {
      dataField: "id", // if dataField is not match to any column you defined, it will be ignored.
      order: "desc", // desc or asc
    },
  ];

  const userListColumns = [
    {
      text: "id",
      dataField: "id",
      sort: true,
      hidden: true,
      formatter: row => <>{row._id}</>,
    },
    {
      dataField: "name",
      text: "Name",
      sort: true,
    },
    {
      dataField: "role.name",
      text: "Role",
      sort: true,
    },
    {
      text: "Actions",
      formatter: (cellContent, row) => {
        return (
          <div className="d-flex gap-3">
            <Link to="#" className="text-success" onClick={() => handleUserEdit(row)}>
              <i className="bx bx-edit font-size-20"></i>
            </Link>
          </div>
        );
      },
    }
  ];

  function toggleUserModal() {
    SetUserModal(!userModal);
    removeBodyCss();
  }

  function removeBodyCss() {
    document.body.classList.add("no_padding");
  }

  useEffect(() => {
    if (users.length > 0) {
      setUserData(users[0]);
    }
  }, [users])

  useEffect(() => {
    let userRoles = [];
    if (userData?.permissions?.allPermissions) {
      roles?.map((item) => {
        if (item._id != "637cb53f63f5416964433181") {
          userRoles.push({ label: item.name, value: item._id })
        }
      })
    } else if (userData?.permissions?.users?.validations?.createRoles) {
      roles?.map((item) => {
        if (userData?.permissions?.users?.validations?.createRoles?.includes(item._id)) {
          userRoles.push({ label: item.name, value: item._id })
        }
      })
    }
    setUserRoleOptions(userRoles)
  }, [userData])


  toastr.options = {
    positionClass: "toast-top-right",
    timeOut: 3000,
    extendedTimeOut: 1000,
    closeButton: true,
    debug: true,
    progressBar: true,
    preventDuplicates: true,
    newestOnTop: true,
    showEasing: "swing",
    hideEasing: "linear",
    showMethod: "fadeIn",
    hideMethod: "fadeOut",
    showDuration: 300,
    hideDuration: 1000
  };

  const handleValidSubmit = async (event, values) => {
    
    values["web"] = true
    values["status"] = status ? "active" : "inactive"
    const userResponse = await addUser(values);
    if (userResponse.statusCode == 400) {
      toastr.warning(userResponse.msg, "Unable to add User");
    }
    else if (userResponse.statusCode == 200) {
      SetUserModal(false);
      dispatch(getUsers());
      toastr.success("User has been added", "User Added");
    }
  }

  const getPermission = (perm, action) => {
    if (userDetails.permissions && userDetails.permissions[perm] && userDetails.permissions[perm][action] == true) {
      return true;
    }
    else {
      return false;
    }
  }

  const togglePermission = (perm, action) => {
    dispatch(toggleUserPermission({
      user: userDetails,
      perm: perm,
      action: action
    }));
  }

  const [editUser, setEditUser] = useState(false);
  const [editUserDetails, setEditUserDetails] = useState({});
  const [status, setStatus] = useState(true);
  const [statusFilter, setStatusFilter] = useState(true);
  const [filteredUsers, setFilteredUsers] = useState([]);
  const handleUserEdit = (user) => {  
    setEditUser(true);
    setEditUserDetails(user);
    setStatus(user?.status == "active" ? true : false);
  }

  const handleEditUserSubmit = async (event, values) => {
    values["id"] = editUserDetails?._id;
    values["role"] = editUserDetails?.role?._id;
    values["status"] = status ? "active" : "inactive";
    const userResponse = await updateUser(values);
    if (userResponse.statusCode == 400) {
      toastr.warning(userResponse.msg, "Unable to update User");
    }
    else if (userResponse.statusCode == 200) {
      setEditUser(false);
      dispatch(getUsers());
      toastr.success("User has been updated", "User Updated");
    }
  }

  const filterUsers = () => {
    const filteredUsersList = userList.filter((user) => {
      return user.status == (statusFilter ? "active" : "inactive")
    });
    setFilteredUsers(filteredUsersList);
  }

  useEffect(() => {
    filterUsers();
  }, [statusFilter]);

  useEffect(() => {
    setFilteredUsers(userList);
  }, [userList]);

  const PermCheckbox = (props) => {

    return (
      <React.Fragment>
        <tr>
          <td>{props.title}</td>
          <td>
            <input
              type="checkbox"
              checked={getPermission(props.perm, 'create')}
              onChange={() => togglePermission(props.perm, 'create')}
            />
          </td>
          <td>
            <input
              type="checkbox"
              checked={getPermission(props.perm, 'search')}
              onChange={() => togglePermission(props.perm, 'search')}
            />
          </td>
          <td>
            <input
              type="checkbox"
              checked={getPermission(props.perm, 'update')}
              onChange={() => togglePermission(props.perm, 'update')}
            />
          </td>

        </tr>
      </React.Fragment>
    )
  }

  PermCheckbox.propTypes = {
    title: PropTypes.string,
    perm: PropTypes.string
  }

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getUsers())
  }, [dispatch]);

  useEffect(() => {
    dispatch(getAllPermission({}))
  }, []);

  const handleAddUserModal = () => {
    SetUserModal(true)
    setName("")
    setEmail("")
    setPassword("")
  }

  useEffect(() => {
    if(userModal==true){
      setTimeout(() => {
        setName("")
        setEmail("")
        setPassword("")
      }, 1000);
    }
    
  }, [userModal])

  if (userData?.permissions?.allPermissions !== true && userData?.permissions?.users?.read !== true) {
    return <Pages404 />
  }
  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Users | Kleen Admin Panel</title>
        </MetaTags>
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumbs
            title={props.t("Home")}
            breadcrumbItem={props.t("Users")}
          />
          <Row>
            <Col>
              <PaginationProvider
                pagination={paginationFactory(pageOptions)}
                keyField={keyField}
                columns={userListColumns}
                data={userList}
              >
                {({ paginationProps, paginationTableProps }) => {
                  return (
                    <ToolkitProvider
                      keyField={keyField}
                      data={filteredUsers}
                      columns={userListColumns}
                      bootstrap4
                      search
                    >
                      {toolkitProps => (
                        <React.Fragment>
                          <Row className="mb-2">
                            <Col sm="2">
                              <div className="search-box ms-2 mb-2 d-inline-block">
                                <div className="position-relative">
                                  <SearchBar {...toolkitProps.searchProps} />
                                  <i className="bx bx-search-alt search-icon" />
                                </div>
                              </div>
                            </Col>
                            <Col sm="8">
                              <div className="d-flex align-items-center">
                                <div className="d-flex align-items-center mx-3">
                                  <span className={`me-2 ${statusFilter ? "text-success font-weight-semibold" : ""}`}>
                                    Status: {statusFilter ? "Active" : "Inactive"}
                                  </span>
                                  <div className="form-check form-switch form-switch-lg">
                                    <input
                                      type="checkbox"
                                      className="form-check-input form-slider-color-green"
                                      defaultChecked={statusFilter}
                                      name="statusFilter"
                                      onChange={(e) => { setStatusFilter(!statusFilter) }}
                                    />
                                  </div>
                                </div>
                              </div>
                              </Col>
                            {
                              userData?.permissions?.allPermissions === true || userData?.permissions?.users?.create === true ? (
                                <Col sm="2">
                                  <div className="text-sm-end">
                                    <Button
                                      type="button"
                                      color="success"
                                      className="btn-rounded  mb-2 me-2"
                                      onClick={handleAddUserModal}
                                    >
                                      <i className="mdi mdi-plus me-1" />
                                      Add User
                                    </Button>
                                  </div>
                                </Col>
                              ) : null
                            }
                          </Row>
                          <Row>
                            <Col xl="12">
                              <div className="table-responsive">
                                <BootstrapTable
                                  keyField={keyField}
                                  {...toolkitProps.baseProps}
                                  {...paginationTableProps}

                                  defaultSorted={defaultSorted}
                                  classes={
                                    "table align-middle table-nowrap table-hover"
                                  }
                                  bordered={false}
                                  striped={false}
                                  responsive
                                  ref={node}
                                />

                              </div>
                            </Col>
                          </Row>
                          <Row className="align-items-md-center mt-30">
                            <Col className="pagination pagination-rounded justify-content-end mb-2">
                              <PaginationListStandalone
                                {...paginationProps}
                              />
                            </Col>
                          </Row>
                        </React.Fragment>
                      )}
                    </ToolkitProvider>
                  );
                }}
              </PaginationProvider>
            </Col>
          </Row>
          <div>
            <Modal
              size="lg"
              isOpen={userModal}
              toggle={() => {
                toggleUserModal();
              }}
            >
              <div className="modal-header">
                <h5 className="modal-title mt-0" id="myModalLabel">
                  Create User
                </h5>
                <button
                  type="button"
                  onClick={() => {
                    SetUserModal(false);
                  }}
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body">
                <Card>
                  <AvForm
                    onValidSubmit={(e, v) => {
                      handleValidSubmit(e, v)
                    }}
                  >
                    <div className="row">
                      <div className="col-md-6 mb-2">
                        <Label
                          htmlFor="horizontal-firstname-Input"
                          className="col-sm-3 col-form-label"
                        >
                          Name
                        </Label>
                        <AvField
                          type="text"
                          className="form-control"
                          id="horizontal-firstname-Input"
                          placeholder="Display Name"
                          name="name"
                          errorMessage="Please enter display name"
                          value={name}
                          onChange={(e) => { setName(e.target.value) }}
                          validate={{ required: { value: true } }}
                        />
                      </div>
                      <div className="col-md-6 mb-2">
                        <Label
                          htmlFor="horizontal-email-Input"
                          className="col-sm-3 col-form-label"
                        >
                          Email
                        </Label>
                        <AvField
                          type="email"
                          name="email"
                          className="form-control"
                          id="horizontal-email-Input"
                          placeholder="Email"
                          errorMessage="Invalid Email"
                          value={email}
                          onChange={(e) => { setEmail(e.target.value) }}
                          validate={{
                            required: { value: true },
                            email: { value: true },
                          }}
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-6 mb-2">
                        <Label
                          htmlFor="horizontal-password-Input"
                          className="col-sm-3 col-form-label"
                        >
                          Password
                        </Label>
                        <AvField
                          type="password"
                          name="password"
                          className={"form-control "}
                          id="horizontal-password-Input"
                          value={password}
                          onChange={(e) => { setPassword(e.target.value) }}
                          placeholder="Enter Password"
                          errorMessage="Please enter password"
                          validate={{
                            required : {value : true, errorMessage:"Please enter password"},
                            pattern: {
                              value: /^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{8,16}$/, 
                              errorMessage:"Password must be at least 8 chars, 1 capital, 1 small letter, 1 number and 1 sepacial charater"}
                          }}
                         
                        />
                       
                      </div>
                      <div className="col-md-6 mb-2">
                        <Label
                          htmlFor="horizontal-password-Input"
                          className="col-sm-3 col-form-label"
                        >
                          Role
                        </Label>
                        <AvField
                          type="select"
                          name="role"
                          // defaultValue="0"
                          className="form-select"
                          errorMessage="Please select User role"
                          validate={{ required: { value: true } }}
                        // required
                        >
                          <option value="">Select User Role</option>
                          {
                            userRoleOptions?.map((item) => {
                              return <option value={item?.value} key={item?.value}>{item?.label}</option>
                            })
                          }
                          {/* <option value="admin">Admin</option>
                            <option value="operation_manager">Operation Manager</option> */}
                        </AvField >
                      </div>
                    </div>

                    <div className="row">
                    <div className="d-flex align-items-center">
                      <div className="d-flex align-items-center mx-3">
                        <span className={`me-2 ${status ? "text-success font-weight-semibold" : ""}`}>
                          Status: {status ? "Active" : "Inactive"}
                        </span>
                        <div className="form-check form-switch form-switch-lg">
                          <input
                            type="checkbox"
                            className="form-check-input form-slider-color-green"
                            defaultChecked={status}
                            name="status"
                            onChange={(e) => { setStatus(!status) }}
                          />
                        </div>
                      </div>
                    </div>
                      </div>

                    <div className="d-flex mt-4 text-end" style={{ float: "right" }}>
                      <Button
                        type="submit"
                        color="primary"
                      >Add User</Button>
                      <Button
                        type="button"
                        onClick={() => {
                          toggleUserModal();
                        }}
                        className="mx-2"
                      // data-dismiss="modal"
                      >Close</Button>
                    </div>
                  </AvForm>
                </Card>
              </div>
            </Modal>

            <Modal
              size="lg"
              isOpen={editUser}
              toggle={() => {
                setEditUser(!editUser);
              }}
            >
              <div className="modal-header">
                <h5 className="modal-title mt-0" id="myModalLabel">
                  Edit User
                </h5>
                <button
                  type="button"
                  onClick={() => {
                    setEditUser(false);
                  }}
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body">
                <Card>
                  <AvForm
                    onValidSubmit={(e, v) => {
                      handleEditUserSubmit(e, v);
                    }}
                  >
                    <div className="row">
                      <div className="col-md-6 mb-2">
                        <Label
                          htmlFor="horizontal-firstname-Input"
                          className="col-sm-3 col-form-label"
                        >
                          Name
                        </Label>
                        <AvField
                          type="text"
                          className="form-control"
                          id="horizontal-firstname-Input"
                          placeholder="Display Name"
                          name="name"
                          errorMessage="Please enter display name"
                          value={editUserDetails && editUserDetails?.name}
                          validate={{ required: { value: true } }}
                          disabled={true}
                        />
                      </div>
                      <div className="col-md-6 mb-2">
                        <Label
                          htmlFor="horizontal-role-Input"
                          className="col-sm-3 col-form-label"
                        >
                          Role
                        </Label>
                        <AvField
                          type="text"
                          name="role"
                          className="form-control"
                          id="horizontal-role-Input"
                          placeholder="User Rolw"
                          errorMessage="Select user role"
                          value={editUserDetails && editUserDetails?.role?.name}
                          validate={{required: { value: true },}}
                          disabled={true}
                        />
                      </div>
                    </div>

                    <div className="row">
                    <div className="d-flex align-items-center">
                      <div className="d-flex align-items-center mx-3">
                        <span className={`me-2 ${status ? "text-success font-weight-semibold" : ""}`}>
                          Status: {status ? "Active" : "Inactive"}
                        </span>
                        <div className="form-check form-switch form-switch-lg">
                          <input
                            type="checkbox"
                            className="form-check-input form-slider-color-green"
                            defaultChecked={status}
                            name="status"
                            onChange={(e) => { setStatus(!status) }}
                          />
                        </div>
                      </div>
                    </div>
                      </div>                     

                    <div className="d-flex mt-4 text-end" style={{ float: "right" }}>
                      <Button
                        type="submit"
                        color="primary"
                      >Update User</Button>
                      <Button
                        type="button"
                        onClick={() => {
                          setEditUser(false);
                        }}
                        className="mx-2"
                      // data-dismiss="modal"
                      >Close</Button>
                    </div>
                  </AvForm>
                </Card>
              </div>
            </Modal>
          </div >
        </Container >
      </div >

    </React.Fragment >

  )
}

Users.propTypes = {
  t: PropTypes.any
}

export default withTranslation()(Users)
