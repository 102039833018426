import { call, put, takeEvery } from "redux-saga/effects"

import { GET_CARWASH_ORDERS, UPDATE_CARWASH_ORDERS } from "./actionTypes"

import {
  getCarwashOrdersSuccess,
  updateCarwashOrdersSuccess,
} from "./actions"

import {
  _getCarwashOrders,
  _updateCarwashOrders,
} from "helpers/carwashOrdersHelper"

import toastr from "toastr"

function* getCarwashOrders({ payload: data }) {
  try {
    const response = yield call(_getCarwashOrders, data)
    if (response?.statusCode == 200) {
      yield put(getCarwashOrdersSuccess(response.record))
    } else {
      toastr.error(response.msg, "Carwash Orders")
    }
  } catch (error) {
    toastr.error(error.msg, "Carwash Orders")
  }
}

function* updateCarwashOrders({ payload: data }) {
  try {
    const response = yield call(_updateCarwashOrders, data)
    console.log("response", response);

    if (response?.statusCode == 200) {
      yield put(updateCarwashOrdersSuccess(response.record))
      toastr.success("Updated", "Car Wash Order")
    } else {
      toastr.error(response.msg, "Carwash Order")
    }
  } catch (error) {
    toastr.error(error.msg, "Carwash Order")
  }
}

function* ecommerceSaga() {
  yield takeEvery(GET_CARWASH_ORDERS, getCarwashOrders)
  yield takeEvery(UPDATE_CARWASH_ORDERS, updateCarwashOrders)
}

export default ecommerceSaga
