import axios from "axios"
import { del, get, post, postWeb, put } from "./api_helper"
import * as url from "./url_helper"
const filter = JSON.parse(sessionStorage.getItem("filter"));
// const NewUrl = "https://sandboxapi.kleen.sa/api"; 

const realTimeDetails = chartType =>
  post(`${url.REAL_TIME_DETAILS}`, { chartType })

const fetchTop4Data = (data) => post(`${url.TOP4_DETAILS}`, { data })
const fetchOngoingOrders = (data) => post(`${url.All_ORDERS_SEARCH}`, data)
const fetchAllOrders = () => post(`${url.All_ORDERS}`, {  })
const fetchOrdersearch = (data) => post(`${url.All_ORDERS}`, data)
const fetchOrderHistory = () => post(`${url.ORDER_HISTORY}`, {  })
const fetchAllOrdersSearch = (apiData) => post(`${url.All_ORDERS_SEARCH}`, apiData)
const fetchCompletedOrders = (apiData) => post(`${url.COMPLETED_ORDERS}`, apiData)
const fetchReturnedOrders = (apiData) => post(`${url.RETURNED_ORDERS}`, apiData)
const fetchWhatsappOrders = (apiData) => post(`${url.WHATSAPP_ORDERS}`, apiData)
const updateOrderInvoice = (data) => postWeb(`${url.UPDATE_ORDERS_INVOICE}`, data);
const updateCustomersBag = (data) => post(`${url.UPDATE_CUSTOMERS_BAG}`, data);
const _updateOrderPayWithCash = (data) => postWeb(`${url.UPDATE_ORDER_PAY_WITH_CASH}`, data);
const fetchFilteredOrders = (data) => post(`${url.All_ORDERS}`, data);
const fetchRefundOrders = (data) => postWeb(`${url.REFUND_ORDERS}`, data);
const fetchFilterBySearch = (data) => postWeb(`${url.GET_SEARCH_RECORD}`, data);
const _removeBagFee = (data) => postWeb(`${url.REMOVE_BAG_FEE}`, data);
const fetchCancelledOrders = (apiData) => postWeb(`${url.FETCH_CANCELLED_ORDERS}`, apiData)

export {
    realTimeDetails,
    fetchTop4Data,
    fetchOngoingOrders,
    fetchOrderHistory,
    fetchAllOrders,
    fetchOrdersearch,
    fetchAllOrdersSearch,
    updateOrderInvoice,
    updateCustomersBag,
    _updateOrderPayWithCash,
    fetchFilteredOrders,
    fetchRefundOrders,
    fetchFilterBySearch,
    _removeBagFee,
    fetchCancelledOrders,
    fetchCompletedOrders,
    fetchReturnedOrders,
    fetchWhatsappOrders
}
