import PropTypes from "prop-types"
import React, { useEffect, useState, useRef } from "react"
import MetaTags from "react-meta-tags";
import { AvForm, AvField, AvGroup, AvInput } from "availity-reactstrap-validation"
import MapsGoogle from "pages/Maps/MapsGoogle";
import MultiSelect from "react-multiple-select-dropdown-lite";
import Pages404 from "pages/Utility/pages-404";
import DataTable from "react-data-table-component";
import 'react-dropzone-uploader/dist/styles.css'
import Dropzone from 'react-dropzone-uploader'
import {
  Container,
  Row,
  Col,
  Modal,
  ModalHeader,
  ModalBody,
  Button,
  Card,
  CardBody,
  Label,
  Form
} from "reactstrap"
import paginationFactory, {
  PaginationListStandalone,
  PaginationProvider,
} from "react-bootstrap-table2-paginator";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import BootstrapTable from "react-bootstrap-table-next";
import { postAddb2bProduct, postUpdateb2bProduct } from "helpers/b2bProductHelper"
import { searchUsers } from "../../store/actions";
import {getb2bProducts, getLaundries } from "../../store/actions"
import toastr from "toastr";
import "toastr/build/toastr.min.css";
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { withTranslation } from "react-i18next"
import { useSelector, useDispatch } from "react-redux"
import { useHistory } from 'react-router-dom';
import google_map_logo from '../../assets/images/google_map_logo.png'
import Switch from "react-switch"
const Offsymbol = () => {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100%",
        fontSize: 12,
        color: "#fff",
        paddingRight: 2
      }}
    >
      {" "}
      Off
    </div>
  )
}

const OnSymbol = () => {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100%",
        fontSize: 12,
        color: "#fff",
        paddingRight: 2
      }}
    >
      {" "}
      On
    </div>
  )
}

const API_URL = process.env.REACT_APP_API_BASE_URL;

const b2bProducts = props => {
  const [LaundryModal, SetLaundryModal] = useState(false)
  const [permModal, SetPermModal] = useState(false)
  const [b2bProductList, Setb2bProductList] = useState([]);
  const [userData, setUserData] = useState({});
  const [isEdit, setIsEdit] = useState(false);
  const [editableItem, setEditableItem] = useState({})
  const [modal, setModal] = useState(false);
  const [b2bNameEn, setb2bNameEn] = useState('');
  const [b2bNameAr, setb2bNameAr] = useState('');
  const [b2bdescEn, setb2bdescEn] = useState('');
  const [b2bdescAr, setb2bdescAr] = useState('');
  const [b2blongDescEn, setb2blongDescEn] = useState('');
  const [b2blongDescAr, setb2blongDescAr] = useState('');
  const [b2bprice, setb2bprice] = useState('');
  const [b2bvatPercent, setb2bvatPercent] = useState('');
  const [b2bvat, setb2bvat] = useState('');
  const [b2bpriceWithVat, setb2bpriceWithVat] = useState('');
  const [b2bbeforePrice, setb2bbeforePrice] = useState('');
  const [b2bcost, setb2bcost] = useState('');
  const [b2bunit, setb2bunit] = useState('');
  const [b2bLaundry, setb2bLaundry] = useState('');
  const [statusVal, setStatusVal] = useState(false);
  const [b2bID, setb2bID] = useState("");
  const [searchValue, setSearchValue] = useState("");
  const [error, setError] = useState({})
  const [image, setImage] = useState("");
  const [imgPath, setImgPath] = useState([]);
  const [highlight, setHighlight] = useState(false);
  const [imageUploading, setImageUploading] = useState(false);
  const [defaultPath, setDefaultPath] = useState("");
  
  const numberValidation = {
    number: {value: true},
    min:{value: 0}
  };
  
  const dispatch = useDispatch();

  const { b2bProducts, users } = useSelector(state => ({
    b2bProducts: state.b2bProducts.b2bProducts,
    users: state.Users.users
  }))

  useEffect(() => {
   if(!users) dispatch(searchUsers({ webPermissions: true }))
    dispatch(getb2bProducts())
  }, [])

  useEffect(() => {
    if(searchValue == ""){
      Setb2bProductList(b2bProducts);
    }
    else{
      handleSearch(searchValue);
    }
    
  }, [b2bProducts])

  useEffect(() => {
    if (users.length > 0) {
      setUserData(users[0]);
    }
  }, [users])

  useEffect(() => {
    if (image) {
      setDefaultPath(URL.createObjectURL(image));
    }
  }, [image])



  const handleSearch = (val) => {
    val = val?.toLowerCase()
    let filterBySearch = b2bProducts.filter((item) => item?.nameEn?.toLowerCase()?.includes(val) || item?.nameAr?.toLowerCase()?.includes(val));
    if (!val) {
      Setb2bProductList(b2bProducts)
    } else {
      setSearchValue(val);
      Setb2bProductList(filterBySearch)
    }
    // console.log("filterBySearch", filterBySearch);
  }


  
  
  var node = useRef();
  const keyField = "id";
  const { SearchBar } = Search;
  const sizePerPage = 10;


  let b2bProductsColumns = [
    {
      name: "Product Name",
      sortable: true,
      selector: row => row.nameEn,
      wrap: true,
    }, 
    {
      name: "Image",
      selector: row => {
        return <>
         {row?.defaultImage?<img src={row?.defaultImage} width={30} height={30} />: null}
        </>
      },
      wrap: true,
    },
    {
      name: "Cost",
      sortable: true,
      selector: row => row.cost,
      wrap: true,
    },
   
    {
      name: "Price With VAT",
      sortable: true,
      selector: row => row.priceWithVat,
      wrap: true,
    },
    {
      name: "Status",
      sortable: true,
      selector: row => row.status?'Active':'Inactive',
      wrap: true,
    }

  ];

  if ( userData?.permissions?.allPermissions == true || userData?.permissions?.b2bProduct?.update == true) {
    b2bProductsColumns.push(
      {
        name: "View",
        center: true,
        selector: row => {
          return <>
            <button onClick={() => UPDATE_B2B_PRODUCT(row)} className="btn btn-primary btn-sm" >
              View
            </button>
            {/* <a onClick={() => showEditLaundry(row._id)} >Edit</a>
             &nbsp; &nbsp;
             <a onClick={() => handleDeleteLaundry(row._id)} >Delete</a> */}
            {/* <a href="#" onClick={() => showPerms(row._id)} >Permissions</a> */}
          </>
        }
      });
  }

  

  function togglePermModal() {
    SetPermModal(!permModal);
    removeBodyCss();
  }

  function removeBodyCss() {
    document.body.classList.add("no_padding");
  }

  toastr.options = {
    positionClass: "toast-top-right",
    timeOut: 3000,
    extendedTimeOut: 1000,
    closeButton: true,
    debug: true,
    progressBar: true,
    preventDuplicates: true,
    newestOnTop: true,
    showEasing: "swing",
    hideEasing: "linear",
    showMethod: "fadeIn",
    hideMethod: "fadeOut",
    showDuration: 300,
    hideDuration: 1000
  };
 

  

  const toggle = () => {
    setModal(!modal);
  };

  const showAddb2bproduct = () =>{
    setEditableItem("");
    setb2bNameEn("");
    setb2bNameAr("");
    setb2bdescEn("");
    setb2bdescAr("");
    setb2blongDescEn("");
    setb2blongDescAr("");
    setb2bprice("");
    setb2bvatPercent("");
    setb2bvat("");
    setb2bpriceWithVat("");
    setb2bbeforePrice("");
    setb2bcost("");
    setb2bunit("");
    setImgPath([]);
    setb2bID("");
    setImage("");
    setIsEdit(false);
    setDefaultPath("");
    setError({});

    toggle();
  }
  
  const submitB2BProduct = async (event, values) => {
    values.status = statusVal;
    let response;
    
    if(!image && !isEdit){
      if(!error?.image){
        setError({
          image : "Please select valid default image"
        })
      }
      return;
    }

    const formData = new FormData();
    formData.append("nameEn", values.nameEn);
    formData.append("nameAr", values.nameAr);
    formData.append("descEn", values.descEn);
    formData.append("descAr", values.descAr);
    formData.append("longDescEn", values.longDescEn);
    formData.append("longDescAr", values.longDescAr);
    formData.append("price", values.price);
    formData.append("vatPercent", values.vatPercent);
    formData.append("vat", values.vat);
    formData.append("priceWithVat", values.priceWithVat);
    formData.append("beforePrice", values.beforePrice);
    formData.append("cost", values.cost);
    formData.append("unit", values.unit);
    formData.append("status", true);
    if(image){
      formData.append("image", image);
    }
    
    formData.append("imgPathEncoded", JSON.stringify(imgPath));
    
    if(isEdit){
      formData.append("_id",b2bID);
      response = await postUpdateb2bProduct(formData);
    }
    else{

      response = await postAddb2bProduct(formData);
    }
    
    if(response.statusCode == 400){
      toastr.warning(response.msg, "Unable to "+ (isEdit?"update":"add") +" b2bProduct");
    }
    else if(response.statusCode == 200){
      toggle();
      dispatch(getb2bProducts());
      toastr.success("b2bProduct has been " + (isEdit?"updated":"added"), "Product " + (isEdit?"updated":"added"));
    }

  }

  const UPDATE_B2B_PRODUCT = (row) => {
    setEditableItem(row)
    setIsEdit(true);
    setModal(true)
    setb2bNameEn(row.nameEn);
    setb2bNameAr(row.nameAr);
    setb2bdescEn(row.descEn);
    setb2bdescAr(row.descAr);
    setb2blongDescEn(row.longDescEn);
    setb2blongDescAr(row.longDescAr);
    setb2bprice(row.price);
    setb2bvatPercent(row.vatPercent);
    setb2bvat(row.vat);
    setb2bpriceWithVat(row.priceWithVat);
    setb2bbeforePrice(row.beforePrice);
    setb2bcost(row.cost);
    setb2bunit(row.unit);
    setb2bID(row._id);
    setImgPath(row.imgPath);
    setDefaultPath(row.defaultImage);
    setError({});

    toggle();
    
  }

  const getUploadParams = ({ file, meta }) => { 
    
    // specify upload params and url for your files
    const data = new FormData();
    
    data.append('image', file);
    return { url: API_URL + '/webapi/b2bproducts/addimages', data, fields : {_id: b2bID}} 
  }

  const removeImage = (path) => {
    const index = imgPath.indexOf(path);
    if(index >=0){
      const _imgPath = [...imgPath];
      _imgPath.splice(index,1);
      setImgPath(_imgPath);
    }
  }
  // called every time a file's `status` changes
  const handleChangeStatus = ({ meta, file}, status, allFiles) => {
    
    if(status == "uploading" || status == "started" || status == "restarted"){
      setImageUploading(true);
    }

    if(status == "done"){
      if(!highlight){
        setHighlight(true);
      }

      allFiles.forEach(f => {
        if(f?.xhr?.status == 200){
          const response = JSON.parse(f?.xhr?.response);
          if(response?.path){
            setImgPath((prev) => {
              if(prev.indexOf(response?.path) === -1){
                f.remove();
                setImageUploading(false);
                return [...prev, response?.path];
              }
            });
          }
        }  
        
      }); 
      

    }
  }
  
  // receives array of files that are done uploading when submit button is clicked
  const handleSubmit = (files, allFiles) => {
    
  }

  const validateImage = (e) => {
    if(e.target.files?.[0]){
      const file = e.target.files[0];
      
      const fileExtension = file.name.split(".").at(-1);
      const allowedFileTypes = ["jpg", "png", "jpeg", "gif", "bmp"];
      if (!allowedFileTypes.includes(fileExtension)) {
          setError({
            image : "Only images can be uploaded"
          });
          setImage("");
          return false;
      }

      if(file.size > 512 * 1024){
        setError({
          image : "Maximum file size is 512kb"
        });
        setImage("");
        return;
      }

      setError({
        image:""
      })

      setImage(file);
    }
    
  }

  
  if (userData?.permissions?.allPermissions == true || userData?.permissions?.b2bProduct?.read == true) {
    return (
      <React.Fragment>
        <div className="page-content">
          <MetaTags>
            <title>LAUNDRY | Kleen Admin Panel</title>
          </MetaTags>
          <Container fluid>
            {/* Render Breadcrumb */}
            <Breadcrumbs
              title={props.t("Home")}
              breadcrumbItem={props.t("b2bProducts")}
            />
            <Card>
              <CardBody>
                <Row className="mb-2">
                  <Col sm="10">
                    <div className="me-2 mb-2 d-inline-block">
                      <div className="position-relative">
                        <input
                          id="search-bar-10"
                          type="text"
                          aria-labelledby="search-bar-10-label"
                          className="form-control "
                          placeholder="Search"
                          onChange={(e) => handleSearch(e.target.value)}
                        />
                      </div>
                    </div>
                  </Col>

                  <Col sm="2" className="text-end">
                    {(userData?.permissions?.allPermissions == true || userData?.permissions?.b2bProduct?.create == true) ?
                      <Button
                        type="button"
                        color="success"
                        className="btn-rounded  "
                        onClick={() => showAddb2bproduct()}
                      >
                        <i className="mdi mdi-plus me-1" />
                        Add b2bProduct
                      </Button>
                      : null
                    }
                  </Col>
                </Row>
                <Row>
                  <DataTable
                    columns={b2bProductsColumns}
                    data={b2bProductList}
                    pagination
                  />
                </Row>
              </CardBody>
            </Card>
            <div>

              {/*Add b2b*/}
              <Modal isOpen={modal} toggle={toggle} size="lg">
              <ModalHeader toggle={toggle} tag="h4">
                {!!isEdit ? "Edit b2bProduct" : "Add b2bProduct"}
              </ModalHeader>
              <ModalBody>
                <AvForm
                  onValidSubmit={submitB2BProduct}
                  encType="multipart/form-data"
                >
                  
                    <div className="row mb-2">
                      
                      <Row>
                        <Col className="col-6">
                          <div className="mb-3">
                          <AvField
                            name="nameEn"
                            label={<div>Product Name (En) <span className="mandatory_feild">*</span></div>}
                            type="text"
                            errorMessage="Product Name (En) is required"
                            validate={{
                              required: { value: true },
                            }}
                            onChange={(e) => { setb2bNameEn(e.target.value) }}
                            value={b2bNameEn}
                          />
                        </div>
                      </Col>

                      <Col className="col-6">

                        <div className="mb-3">
                          <AvField
                            name="nameAr"
                            label={<div>Product Name (Ar)<span className="mandatory_feild">*</span></div>}
                            type="text"
                            errorMessage="Product Name (Ar) is required"
                            validate={{
                              required: { value: true },
                            }}
                            onChange={(e) => { setb2bNameAr(e.target.value) }}
                            value={b2bNameAr}
                          />
                        </div>
                      </Col>
                    </Row>

                    <Row>
                        <Col className="col-6">
                          <div className="mb-3">
                          <AvField
                            name="descEn"
                            label={<div>Description (En) <span className="mandatory_feild">*</span></div>}
                            type="text"
                            errorMessage="Description (En) is required"
                            validate={{
                              required: { value: true },
                            }}
                            onChange={(e) => { setb2bdescEn(e.target.value) }}
                            value={b2bdescEn}
                          />
                        </div>
                      </Col>

                      <Col className="col-6">

                        <div className="mb-3">
                          <AvField
                            name="descAr"
                            label={<div>Description (Ar)<span className="mandatory_feild">*</span></div>}
                            type="text"
                            errorMessage="Description (En) is required"
                            validate={{
                              required: { value: true },
                            }}
                            onChange={(e) => { setb2bdescAr(e.target.value) }}
                            value={b2bdescAr}
                          />
                        </div>
                      </Col>
                    </Row>
                  
                    <Row>
                        <Col className="col-6">
                          <div className="mb-3">
                          <AvField
                            name="longDescEn"
                            label={<div>Long Description (En) <span className="mandatory_feild">*</span></div>}
                            type="text"
                            errorMessage="Long description (En) is required"
                            validate={{
                              required: { value: true },
                            }}
                            onChange={(e) => { setb2blongDescEn(e.target.value) }}
                            value={b2blongDescEn}
                          />
                        </div>
                      </Col>

                      <Col className="col-6">

                        <div className="mb-3">
                          <AvField
                            name="longDescAr"
                            label={<div>Long Description (Ar)<span className="mandatory_feild">*</span></div>}
                            type="text"
                            errorMessage="Long description (Ar) is required"
                            validate={{
                              required: { value: true },
                            }}
                            onChange={(e) => { setb2blongDescAr(e.target.value) }}
                            value={b2blongDescAr}
                          />
                        </div>
                      </Col>
                    </Row>

                    <Row>
                        <Col className="col-6">
                          <div className="mb-3">
                          <AvField
                            name="price"
                            label={<div>Price <span className="mandatory_feild">*</span></div>}
                            type="text"
                            errorMessage="Price is required"
                            validate={{
                              ...numberValidation,
                              required: { value: true }
                            }}
                            onChange={(e) => { setb2bprice(e.target.value) }}
                            value={b2bprice}
                          />
                        </div>
                      </Col>

                      <Col className="col-6">

                        <div className="mb-3">
                          <AvField
                            name="vatPercent"
                            label={<div>Vat Percent<span className="mandatory_feild">*</span></div>}
                            type="text"
                            errorMessage="Vat percent is required"
                            validate={{
                              ...numberValidation,
                              required: { value: true }
                            }}
                            onChange={(e) => { setb2bvatPercent(e.target.value) }}
                            value={b2bvatPercent}
                          />
                        </div>
                      </Col>
                    </Row>

                    <Row>
                        <Col className="col-6">
                          <div className="mb-3">
                          <AvField
                            name="vat"
                            label={<div>Vat Amount <span className="mandatory_feild">*</span></div>}
                            type="text"
                            errorMessage="Vat amount is required"
                            validate={{
                              ...numberValidation,
                              required: { value: true}
                            }}

                            onChange={(e) => { setb2bvat(e.target.value) }}
                            value={b2bvat}
                          />
                        </div>
                      </Col>

                      <Col className="col-6">

                        <div className="mb-3">
                          <AvField
                            name="priceWithVat"
                            label={<div>Price With Vat<span className="mandatory_feild">*</span></div>}
                            type="text"
                            errorMessage="Price with vat is required"
                            validate={{
                              ...numberValidation,
                              required: { value: true }
                            }}

                            onChange={(e) => { setb2bpriceWithVat(e.target.value) }}
                            value={b2bpriceWithVat}
                          />
                        </div>
                      </Col>
                    </Row>
                              
                    <Row>
                        <Col className="col-6">
                          <div className="mb-3">
                          <AvField
                            name="beforePrice"
                            label={<div>Before Price <span className="mandatory_feild">*</span></div>}
                            type="text"
                            errorMessage="Before price is required"
                            validate={{
                              ...numberValidation,
                              required: { value: true }
                            }}

                            onChange={(e) => { setb2bbeforePrice(e.target.value) }}
                            value={b2bbeforePrice}
                          />
                        </div>
                      </Col>

                      <Col className="col-6">

                        <div className="mb-3">
                          <AvField
                            name="cost"
                            label={<div>Cost<span className="mandatory_feild">*</span></div>}
                            type="text"
                            errorMessage="Cost is required"
                            validate={{
                              ...numberValidation,
                              required: { value: true }
                            }}
                            onChange={(e) => { setb2bcost(e.target.value) }}
                            value={b2bcost}
                          />
                        </div>
                      </Col>
                    </Row>

                    <Row>
                        <Col className="col-6">
                          <div className="mb-3">
                          <AvField
                            name="unit"
                            label={<div>Unit <span className="mandatory_feild">*</span></div>}
                            type="text"
                            errorMessage="Unit is required"
                            validate={{
                              required: { value: true },
                            }}

                            onChange={(e) => { setb2bunit(e.target.value) }}
                            value={b2bunit}
                          />
                        </div>
                      </Col>

                      <Col className="col-6">

                        <div className="mb-3">
                        <AvField
                              type="file"
                              label={<div>Default Image {defaultPath?null:<span className="mandatory_feild">*</span>}</div>}
                              onChange={(e) => { validateImage(e) }}
                              className="form-control"
                              id="horizontal-firstname-Input"
                              placeholder="Image"
                              name="image"
                              errorMessage="Please Upload Image"
                              validate={false}
                              required={defaultPath?false: true}
                              value={""}
                              />
                              {
                                error?.image? (
                                  <label style={{ color: "red" }}>{error?.image}</label>
                                ) : null
                              }
                        </div>
                        {defaultPath?
                        <div className="mb-3">
                          <img src={defaultPath} height="100" width="100" />
                        </div>: null}

                        

                      </Col>
                    </Row>

                




                    </div>
                    
                  <Row className="mb-2">
                    <Col>
                    <Label>Additional Images: </Label>
                    <br />
                    <span className="text-warning">Maximum image size : <strong>512kb</strong>. Maximum 4 images can be added.</span>
                    {imgPath.length < 4?
                    <Dropzone
                        getUploadParams={getUploadParams}
                        onChangeStatus={handleChangeStatus}
                        accept="image/*"
                        maxSizeBytes={512*1024}
                        maxFiles={4}
                      />: 
                      <div>
                        <h4 className="text-warning">Maximum 4 files can uploaded</h4>
                      </div>}
                    </Col>
                  </Row>

                  
                    <Row>
                    
                        {
                          imgPath && imgPath?.map((path, index) => (
                            <Col key={index} lg={3}>
                              <div className={`b2b-image ${(highlight && index==imgPath.length-1)?'added':''}`}>
                                <div>
                                  <img src={path} style={{height:100}} />
                                  <Button 
                                    type="button" 
                                    color="link"
                                    onClick={() => removeImage(path)} 
                                    className="text-danger w-100 p-0 pt-2">
                                    <i className="bx bx-trash-alt" style={{fontSize:20}}></i>
                                  </Button>
                                </div>
                                
                              </div>
                            </Col>
                          ))
                        }
                    
                  </Row>
                  
                  <Row>
                    <Col>
                      <div className="text-end">
                        {imageUploading?
                        <button
                          type="button"
                          disabled
                          className="btn btn-success save-user"
                        >
                         <i className="fa fa-spinner spin"></i> Save
                        </button>
                        :
                        <button
                          type="submit"
                          className="btn btn-success save-user"
                        >
                          Save
                        </button>
                        }
                      </div>
                    </Col>
                  </Row>
                </AvForm>
              </ModalBody>
            </Modal>
              
            </div>
          </Container>
        </div>

      </React.Fragment>
    )
  } else {

    return <Pages404 />
  }

}
b2bProducts.propTypes = {
  t: PropTypes.any
}

export default withTranslation()(b2bProducts)



