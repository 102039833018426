import axios from "axios"
import {postWeb} from "./api_helper"
import * as url from "./url_helper"


const fetchSubscriptionPkgsList = (data) => postWeb(`${url.GET_SUBSCRIPTION_PACKAGES}`, data)
const createSubscriptionPackage = (data) => postWeb(`${url.CREATE_SUBSCRIPTION_PACKAGES}`, data)
const updateSubscriptionPackage = (data) => postWeb(`${url.UPDATE_SUBSCRIPTION_PACKAGES}`, data)

export {
  fetchSubscriptionPkgsList,
  createSubscriptionPackage,
  updateSubscriptionPackage  
}