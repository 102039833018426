import React from "react"
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Input,
} from "reactstrap"
import PropTypes from "prop-types"
import { useDispatch, useSelector } from "react-redux"
import { getLaundrys } from "../../../store/laundry/actions"
import { getArealist, getB2bCustomer } from "../../../store/actions"

const CopyPriceListModal = props => {
  const dispatch = useDispatch()
  const { areaList, laundryList, customersList } = useSelector(state => ({
    areaList: state.ecommerce.arealist,
    customersList: state.ecommerce.b2bCustomers,
    laundryList: state.Laundry.LaundryList,
  }))

  const [searchTerm, setSearchTerm] = React.useState("")
  const [entityId, setEntityId] = React.useState("")
  const [entityType, setEntityType] = React.useState("")

  const toggle = () => {
    props.toggle()
  }

  React.useEffect(() => {
    dispatch(getLaundrys({ status: true }))
    dispatch(getArealist({ status: true }))
    dispatch(getB2bCustomer({ status: true, panel: true }))
  }, [])

  const filteredLaundryList = laundryList.filter(laundry => {
    const laundryName = laundry.shortName || laundry.nameEn
    const regex = new RegExp(searchTerm, "gi")
    const match = laundryName.match(regex)
    return match
  })

  const filteredAreaList = areaList.filter(area => {
    const laundryName = area.name
    const regex = new RegExp(searchTerm, "gi")
    const match = laundryName.match(regex)
    return match
  })

  const filteredCustomerList = customersList.filter(laundry => {
    const laundryName = laundry.shortName || laundry.nameEn
    const regex = new RegExp(searchTerm, "gi")
    const match = laundryName.match(regex)
    return match
  })

  return (
    <Modal isOpen={props.isOpen} toggle={toggle} {...props.args}>
      <ModalHeader toggle={toggle}>Copy pricelist</ModalHeader>
      <ModalBody>
        <Input
          placeholder="Search"
          value={searchTerm}
          onChange={e => setSearchTerm(e.target.value)}
          className="mb-4"
        />
        <div
          style={{
            maxHeight: "15rem",
            overflow: "scroll",
          }}
        >
          {!!filteredLaundryList.length && (
            <div className="mb-2">
              <div className="h5">Laundry</div>

              {filteredLaundryList.map((laundry, index) => {
                return (
                  <div key={index} className="d-flex mb-2">
                    <Input
                      type="radio"
                      checked={entityId === laundry._id}
                      onChange={() => {
                        setEntityId(laundry._id)
                        setEntityType("laundry")
                      }}
                      className="mx-2"
                    />
                    <span>{laundry.shortName || laundry.nameEn}</span>
                  </div>
                )
              })}
            </div>
          )}
          {!!filteredAreaList.length && (
            <div className="mb-2">
              <div className="h5 my-2">Area</div>
              {filteredAreaList.map((area, index) => {
                return (
                  <div key={index} className="d-flex mb-2">
                    <Input
                      type="radio"
                      checked={entityId === area._id}
                      onChange={() => {
                        setEntityId(area._id)
                        setEntityType("area")
                      }}
                      className="mx-2"
                    />
                    <span>{area.name}</span>
                  </div>
                )
              })}
            </div>
          )}
          {!!filteredCustomerList.length && (
            <div className="mb-2">
              <div className="h5 my-2">B2B Customer</div>

              {filteredCustomerList.map((b2bCustomer, index) => {
                return (
                  <div key={index} className="d-flex mb-2">
                    <Input
                      type="radio"
                      checked={entityId === b2bCustomer._id}
                      onChange={() => {
                        setEntityId(b2bCustomer._id)
                        setEntityType("b2bCustomer")
                      }}
                      className="mx-2"
                    />
                    <span>{b2bCustomer.nameEn}</span>
                  </div>
                )
              })}
            </div>
          )}
        </div>
      </ModalBody>
      <ModalFooter>
        <Button
          color="primary"
          onClick={() => {
            props.onAction(entityId, entityType)
          }}
          disabled={!entityId}
        >
          Copy
        </Button>
        <Button color="secondary" onClick={toggle}>
          Close
        </Button>
      </ModalFooter>
    </Modal>
  )
}

CopyPriceListModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onAction: PropTypes.func.isRequired,
  toggle: PropTypes.func.isRequired,
  args: PropTypes.object,
  order: PropTypes.object,
  editPermission: PropTypes.bool,
}

export default React.memo(CopyPriceListModal)
