import {
  GET_B2B_LIST,
  GET_B2B_LIST_SUCCESS,
  GET_B2B_LIST_FAIL

} from "./actionTypes"

export const getb2bList = (data) => ({
  type: GET_B2B_LIST,
  payload: data
})

export const getb2bListSuccess = (data) => ({
  type: GET_B2B_LIST_SUCCESS,
  payload: data
})

export const getb2bListFail = (data) => ({
  type: GET_B2B_LIST_FAIL,
  payload: data
})